

@use '../../../../../../../style/import' as *;




////////////////////
// BannerCarousel //
////////////////////


// Titan colors
// None yet

// Theme colors
$dotColor: $THEME_RULE_COLOR;
$dotBackground: $THEME_BACKGROUND;
$dotColorSelected: $THEME_PRIMARY_COLOR;
$dotColorSelectedContrast: $THEME_PRIMARY_COLOR_HOVER;

// General variables
$dotBorderWidth: 0;
$dotWidth: $MARGIN;


.BannerCarousel {

	position: relative;
	margin: auto;
	--slide-spacing: 0;
	--slide-size: 100%;

	.viewport {
		
		overflow: hidden;

		.container {
			display: flex;
			align-items: flex-start;
			touch-action: pan-y pinch-zoom;
			margin-left: calc(var(--slide-spacing) * -1);
			transition: height $breakTransition;

			.slideWrapper {
				transform: translate3d(0, 0, 0);
				flex: 0 0 var(--slide-size);
				min-width: 0;
				padding-left: var(--slide-spacing);
			}
		}
	}

	.controls {

		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		text-align: center;

		.dots {

			margin: 0 auto $MARGIN auto;

			.dot {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				-webkit-tap-highlight-color: rgba($dotColorSelectedContrast, 0.5);
				-webkit-appearance: none;
				appearance: none;
				background-color: $dotBackground;
				touch-action: manipulation;
				text-decoration: none;
				cursor: pointer;
				border: 0;
				padding: 0;
				margin: 0 $MARGIN_S;
				width: $dotWidth;
				height: $dotWidth;
				border-radius: 50%;

				&:after {
					content: '';

					display: inline-block;
					vertical-align: top;
					box-shadow: inset 0 0 0 $dotBorderWidth $dotColor;
					width: $dotWidth;
					height: $dotWidth;
					border-radius: 50%;					
				}

				&.selected {

					background-color: $dotColorSelected;

					&:after {
						box-shadow: inset 0 0 0 $dotBorderWidth $dotColorSelected;
					}
				}
			}
		}
	}
}













