



@use '../../../../style/import' as *;


/////////////////////
// ViewProductLine //
/////////////////////


// Titan colors
$viewPlAccentColor: $TITAN_PRIMARY_COLOR;
$viewPlAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$viewPlAccentColorText: $TITAN_PRIMARY_COLOR_TEXT;
$viewPlBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$viewPlColor: $TITAN_TEXT_COLOR;
$viewPlColorHover: $TITAN_TEXT_COLOR_HOVER;
$viewPlDisabledColor: $disabledColor;

// Theme colors
// None yet

// General variables
$viewPlMargin: 10px;


.ViewProductLine {

}


////////////////////////////
// ProductLineDetailsView //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductLineDetailsView {
	
}


////////////////////////////
// ProductLineDetailsEdit //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductLineDetailsEdit {
	
}


////////////////////////////
// ProductLinePricingView //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductLinePricingView {
	
	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.productLineViewWrapper {

					.pricingDisabledWrapper {
						margin: 2*$viewPlMargin 0;
						color: $viewPlDisabledColor;
						font-size: 22px;
						font-weight: 300;
						text-align: center;
					}
				}
			}
		}
	}
}


////////////////////////////
// ProductLinePricingEdit //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$viewPlLoadingWidth: 24px;


.ProductLinePricingEdit {
	
	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminForm {

				.pricingWrapper.adminFieldWrapper {

					.loadingDefaults {

						.loadingDefaultsLiner {

							.loadingIcon {

								display: inline-block;
								vertical-align: top;
								width: $viewPlLoadingWidth;
								height: $viewPlLoadingWidth;

								svg {
									fill: $viewPlAccentColor;
								}
							}

							.loadingCopy {
								display: inline-block;
								vertical-align: top;
								width: calc(100% - #{$viewPlLoadingWidth} - #{$viewPlMargin/2});
								margin: 0 0 0 $viewPlMargin/2;
								height: $viewPlLoadingWidth;
								line-height: $viewPlLoadingWidth;
								font-size: 18px;
								font-weight: 300;
							}
						}
					}
				}
			}
		}
	}
}


/////////////////////////////
// ProductLineDefaultField //
/////////////////////////////


// Titan colors
$pldfBorderColor: $TITAN_TEXT_COLOR_LIGHT;
$pldfCancelColor: $TITAN_TEXT_COLOR;
$pldfCancelColorHover: $TITAN_TEXT_COLOR_HOVER;
$pldfSaveIconColor: $TITAN_PRIMARY_COLOR_TEXT;
$pldfCancelBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$pldfCancelWidth: 30px;
$pldfInputHeight: 30px;
$pldfSaveWidth: 50px;
$pldfToggleWidth: 80px;


.ProductLineDefaultField {

	position: relative;
	// height: auto !important;

	.defaultLabel {
		display: inline-block;
		vertical-align: top;
		width: auto;
		margin: 0 $viewPlMargin 0 0;
		height: $pldfInputHeight;
		line-height: $pldfInputHeight;
		font-size: 14px;
	}

	.cfEdit {

		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 60%;

		.cfEditInputWrapper {

			position: relative;

			input.cfEditInput {

				// Use !important to override default input settings

				border-left: 1px solid $pldfBorderColor !important;
				border-top: 1px solid $pldfBorderColor !important;
				border-bottom: 1px solid $pldfBorderColor !important;
				border-right: none !important;
		    width: calc(100% - #{2*$viewPlMargin} - #{$pldfSaveWidth} - #{$pldfCancelWidth} - 1px) !important;
		    font-size: 16px !important; 
		    padding: 0 $viewPlMargin !important;
		    margin: 0 !important;
		    height: $pldfInputHeight - 2px !important;
			}
		}

		.cfEditInputUnitWrapper {
			position: absolute;
			top: 1px;
			height: $pldfInputHeight;
			right: $pldfCancelWidth + $pldfSaveWidth + $viewPlMargin/4;
			width: auto;
			
			.cfUnitSelect {
				padding: 0 $viewPlMargin/2 0 $viewPlMargin/2 !important;
				height: $pldfInputHeight - 2px !important;
				border: none !important;
				appearance: auto !important;
				text-align: right; 
				cursor: pointer;
				font-size: 14px !important;
				color: $pldfBorderColor !important;
			}
		}

		.cfEditSaveAction {

			position: absolute;
			top: 0;
			height: $pldfInputHeight;
			right: $pldfCancelWidth;
			width: $pldfSaveWidth;

			cursor: pointer;
			background: $viewPlAccentColor;
			transition: background $hoverTransition;

			&:hover {
				background: $viewPlAccentColorHover;
			}

			.cfEditSaveActionIconWrapper {

				position: absolute;
				top: 10%;
				bottom: 10%;
				left: 27%;
				width: 46%;

				.cfEditSaveActionIcon {

					svg {
						fill: $pldfSaveIconColor;
					}
				}
			}
		}

		.cfEditCancelAction {

			position: absolute;
			top: 0;
			height: $pldfInputHeight;
			right: 0;
			width: $pldfCancelWidth;

			.cfEditCancelActionIconWrapper {
				cursor: pointer;
				color: $pldfCancelColor;
				font-size: 32px;
				font-weight: 100;
				transition: color $hoverTransition;
					
				&:hover {
					color: $pldfCancelColorHover;
				}
			}
		}
	}

	.adminViewValue.configValue {

		min-height: $pldfInputHeight;
		display: inline-block;
		vertical-align: top;
		width: 50%;

		.adminViewValueData {
			display: inline-block;
			vertical-align: middle;
			max-width: calc(100% - #{$pldfToggleWidth} - #{$viewPlMargin});
		}

		.adminViewValueToggle.noMainValue {
			margin: 0 0 0 0;
		}

		.adminViewValueToggle {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 $viewPlMargin;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			color: $viewPlAccentColor;
			line-height: $pldfInputHeight/2;
			padding: $pldfInputHeight/4 0;
			max-width: $pldfToggleWidth;
			transition: color $hoverTransition;

			&:hover {
				color: $viewPlAccentColorHover;
			}
		}
	}
}










