

@use '../../../../style/import' as *;

@use '../../Admin/_styles/admin-constants' as *;




///////////////
// AdminForm //
///////////////


// Titan colors
$backgroundColor: $TITAN_BACKGROUND;
$errorColor: $errorRed;
$formAccent: $TITAN_PRIMARY_COLOR;
$textColor: $TITAN_TEXT_COLOR;
$textColorHover: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$loadingIconWidth: 60px;
$formMaxWidth: 800px;
$formInputMaxWidth: $ADMIN_FORM_INPUT_WIDTH;


.AdminForm {

	&.inlaid {
		margin: 0;

		.formLiner {
			margin: 0;
		}
	}

	position: relative;
	overflow: auto;
	background: $backgroundColor;
	max-width: $formMaxWidth;
	margin: 0 0 $MARGIN_L 0;

	.formLiner {

		margin: $MARGIN;
		transition: margin $breakTransition;

		@media #{$break768} {
			// margin: $MARGIN;
		}

		.titleWrapper {

			position: relative;
			margin: 0 0 $MARGIN_L 0;

			.titleValue {
				font-size: 22px;
				font-weight: 700;
				text-transform: uppercase;
				padding: 0 0 $MARGIN_S 0;
				border-bottom: 2px solid $formAccent;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 18px;
				}
			}

			.titleAction {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				font-weight: 700;
				padding: $MARGIN_S 0;
				cursor: pointer;
				color: $textColor;
				transition: color $hoverTransition;

				@media #{$break1024} {
					position: relative;
					top: unset;
					right: unset;
					width: auto;

					text-align: center;
					margin: $MARGIN_S 0 0 0;
					color: $textColorHover;
				}
			}
		}

		.formError {

			&.errorPresent {
				height: 1.5*$MARGIN_L;
			}

			color: $errorColor;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: left;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.formBody {

			.formInput {
				max-width: $formInputMaxWidth;
			}
		}

		.formActions {

			text-align: right;
			margin: $MARGIN_L 0 0 0;

			.actionRight {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $MARGIN;				
			}
		}

		.formPending {

			display: none;

			&.pendingActive {
				display: block;
			}

			.pendingScreen {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $backgroundColor;
				opacity: 0.6;
			}

			.pendingWrapper {
				position: absolute;
				top: calc(50% - #{0.5*$loadingIconWidth});
				left: calc(50% - #{0.5*$loadingIconWidth});
				width: $loadingIconWidth;
				height: $loadingIconWidth;

				svg {
					fill: $formAccent;
				}
			}
		}
	}
}













