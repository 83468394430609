

@use '../../../../style/import' as *;
@import './constants/analytics';




/////////////////////////////////////
// BlockAnalyticsOrderStatusCounts //
/////////////////////////////////////


// Titan colors
$loadingIconColor: $TITAN_PRIMARY_COLOR;
$rowBackground: $TITAN_BACKGROUND;
$rowBackgroundAlt: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$loadingIconWidth: 60px;
$rowHeight: 20px;
$rowValueWidth: 50px;


.BlockAnalyticsOrderStatusCounts {

	margin: 0 0 $MARGIN_L 0;

	.headerWrapper {

		height: $analyticsHeaderHeight;

		.titleWrapper {
			line-height: $analyticsHeaderHeight;
			font-size: $analyticsHeaderFontSize;
			font-weight: $analyticsHeaderFontWeight;
			text-align: center;
		}
	}

	.bodyWrapper {

		.loadingWrapper {

			margin: $MARGIN_L 0;

			.iconWrapper {

				margin: 0 auto;
				width: $loadingIconWidth;
				height: $loadingIconWidth;

				svg {
					fill: $loadingIconColor;
				}
			}
		}

		.errorWrapper {
			padding: $MARGIN_XL $MARGIN;
			font-size: 18px;
			font-weight: 300;
			text-align: center;
		}

		.dataWrapper {

			.dataRow {

				background: $rowBackground;

				&:nth-child(even) {
					background: $rowBackgroundAlt;
				}

				&.dataHeader {

					.dataRowLiner {

						.dataLabel {
							font-size: 15px;
							font-weight: 700;
						}

						.dataValue {
							font-size: 15px;
							font-weight: 700;
						}
					}
				}

				.dataRowLiner {

					margin: $MARGIN_S;

					.dataLabel {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$rowValueWidth});
						height: $rowHeight;
						line-height: $rowHeight;
						font-size: 13px;
						font-weight: 400;
					}

					.dataValue {
						display: inline-block;
						vertical-align: top;
						width: $rowValueWidth;
						height: $rowHeight;
						line-height: $rowHeight;
						font-size: 14px;
						font-weight: 700;
						text-align: right;
					}
				}
			}
		}
	}
}













