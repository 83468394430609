

@use '../../../../style/import' as *;
@import './constants/analytics';




///////////////////////////
// BlockAnalyticsTopList //
///////////////////////////


// Titan colors
$dropdownIconColor: $TITAN_PRIMARY_COLOR;
$loadingIconColor: $TITAN_PRIMARY_COLOR;
$rowBackground: $TITAN_BACKGROUND;
$rowBackgroundAlt: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$dropdownIconScale: 0.7;
$durationFontSize: 13px;
$loadingIconWidth: 45px;
$rowHeight: 20px;
$rowValueWidth: 70px;
$rowValueWidthShort: 35px;


.BlockAnalyticsTopList {

	margin: 0 0 $MARGIN_L 0;

	.headerWrapper {

		position: relative;
		width: 100%;
		height: $analyticsHeaderHeight;

		.titleWrapper {
			line-height: 0.5*$analyticsHeaderHeight;
			font-size: $analyticsHeaderFontSizeSmall;
			font-weight: $analyticsHeaderFontWeight;
			text-align: center;
		}
	}

	.bodyWrapper {

		.loadingWrapper {

			margin: $MARGIN_L 0;

			.iconWrapper {

				margin: 0 auto;
				width: $loadingIconWidth;
				height: $loadingIconWidth;

				svg {
					fill: $loadingIconColor;
				}
			}
		}

		.errorWrapper {
			padding: $MARGIN_XL $MARGIN;
			font-size: 18px;
			font-weight: 300;
			text-align: center;
		}

		.dataWrapper {

			text-align: center;

			.dataRow {

				text-align: left;
				background: $rowBackground;

				&:nth-child(even) {
					background: $rowBackgroundAlt;
				}

				&.dataHeader {

					.dataRowLiner {

						.dataLabel {
							font-size: 14px;
							font-weight: 700;
						}

						.dataValue {
							font-size: 14px;
							font-weight: 700;
						}
					}
				}

				&.shortValue {

					.dataRowLiner {

						.dataLabel {
							width: calc(100% - #{$rowValueWidthShort});
						}

						.dataValue {
							width: $rowValueWidthShort;
						}
					}
				}

				.dataRowLiner {

					margin: $MARGIN_S;

					.dataLabel {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$rowValueWidth});
						height: $rowHeight;
						line-height: $rowHeight;
						font-size: 13px;
						font-weight: 300;
					}

					.dataValue {
						display: inline-block;
						vertical-align: top;
						width: $rowValueWidth;
						height: $rowHeight;
						line-height: $rowHeight;
						font-size: 13px;
						font-weight: 400;
						text-align: right;
					}
				}
			}

			.noResultsWrapper {

				margin: $MARGIN_XL $MARGIN;

				.noResults {
					text-align: center;
					font-size: 14px;
					font-weight: 300;
				}
			}

			.durationWrapper {
				display: inline-block;
				vertical-align: top;
				width: auto;

				.durationValue {
					display: inline-block;
					vertical-align: top;
					width: auto;
					line-height: $durationFontSize;
					margin: 0 0.5*$MARGIN_S;
					padding-left: $durationFontSize;
					font-size: $durationFontSize;
				}

				.durationIcon {

					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $durationFontSize;
					height: $durationFontSize;

					.iconWrapper {

						position: absolute;

						top: 0.5 * (1 - $dropdownIconScale) * 100%;
						left: 0.5 * (1 - $dropdownIconScale) * 100%;
						width: $dropdownIconScale * 100%;
						height: $dropdownIconScale * 100%;

						svg {
							fill: $dropdownIconColor;
						}
					}
				}
			}
		}
	}
}
