

@use '../../../../../../style/import' as *;




// Titan colors
// None yet

// Theme colors
$accentColor: $THEME_PRIMARY_COLOR;
$filterElementBorderColor: $THEME_RULE_COLOR;
$filterElementInputBackground: $THEME_BACKGROUND;

// General variables
$filterElementMargin: 10px;

// Component variables
$filterElementInputHeight: 20px;
$filterElementMaxHeight: 400px;

$filterElementCheckboxesInputWidth: 18px;
$filterElementCheckboxesInputSpacing: 2px;
$filterElementCheckboxesLoadingWidth: 40px;
$filterElementCheckboxesRowMargin: 3px;
$filterElementCheckboxesToggleWidth: 20px;


///////////////////
// FilterElement //
///////////////////


.FilterElement {

	.feFilterTitle {
		margin: 0 0 0.5*$filterElementMargin 0;

		.feFilterTitleValue {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$filterElementCheckboxesToggleWidth});
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.feFilterTitleToggle {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $filterElementCheckboxesToggleWidth;
			height: $filterElementCheckboxesToggleWidth;

			.feFilterTitleToggleElement {
				font-size: 26px;
				font-weight: 300;
				cursor: pointer;

				&:hover {
					color: $accentColor;
					font-weight: 400;
				}
			}
		}
	}

	.feFilterBody {
		position: relative;
		overflow: hidden;
		height: auto;
		max-height: 0;
		transition: max-height $slideFastTransition;

		&.open {
			max-height: $filterElementMaxHeight;
			transition: max-height $slideTransition;
		}

		.feFilterBodyLiner {
			overflow: auto;
			position: relative;
			max-height: $filterElementMaxHeight;

			.feFilterBodyLinerScroll {

			}

			.multiQuickSearchInput {

				position: sticky;
				top: 0;
				left: 0;
				padding: 0 0 $filterElementMargin 0;
				background: $filterElementInputBackground;
				z-index: 1;

				input {
					border: 1px solid $filterElementBorderColor;
					font-size: 12px;
					height: $filterElementInputHeight - 2px;
					padding: 0 $filterElementInputHeight 0 $filterElementInputHeight;
					width: calc(100% - #{2*$filterElementInputHeight} - 2px - #{$filterElementMargin});
					text-align: left;
				}

				.searchOverlay {

					$iconMarginPercent: 0.2;

					position: absolute;
					top: $iconMarginPercent * $filterElementInputHeight;
					left: 0.5 * $filterElementMargin;
					width: (1 - 2*$iconMarginPercent) * $filterElementInputHeight;
					bottom: $iconMarginPercent * $filterElementInputHeight + $filterElementMargin;
					line-height: $filterElementInputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					color: $filterElementBorderColor;
					font-size: 15px;
					font-weight: 300;
					text-align: left;

					.searchIcon {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						svg {
							fill: $filterElementBorderColor;
						}
					}
				}

				.clearOverlay {
					position: absolute;
					top: 0;
					right: 1.5 * $filterElementMargin;
					width: auto;
					height: $filterElementInputHeight;
					line-height: $filterElementInputHeight;
					cursor: pointer;
					user-select: none;

					color: $filterElementBorderColor;
					font-size: 18px;
					font-weight: 300;
					text-align: right;
				}
			}
		}

		.feFilterLoading {

			.feLoadingWrapper {

				margin: $filterElementMargin 0;

				.feLoadingIcon {
					margin: 0 auto 0 auto;
					width: $filterElementCheckboxesLoadingWidth;
					height: $filterElementCheckboxesLoadingWidth;

					svg {
						fill: $accentColor;
					}
				}
				
				.feLoadingLabel {
					margin: 0.5*$filterElementMargin 0 0 0;
					text-align: center;
					font-size: 16px;
					font-weight: 300;
				}
			}
		}
	}
}













