

@use '../../../../style/import' as *;

// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$imageHeight: 141.05572%;


///////////////////////
// OnePieceCardImage //
///////////////////////

.OnePieceCardImage {

	position: relative;
	display: inline-block;
	width: 100%;
	padding-bottom: $imageHeight;

	.imageWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img {
			width: 100%;
		}
	}
}


