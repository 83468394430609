
$THEME_PRIMARY_COLOR: #BD2C9C;
$THEME_PRIMARY_COLOR_HOVER: #97237C;
$THEME_PRIMARY_COLOR_TEXT: #FFFFFF;
$THEME_PRIMARY_ULTRALIGHT: #F8E9F5;
$THEME_TEXT_COLOR: #333333;
$THEME_TEXT_COLOR_HOVER: #000000;
$THEME_TEXT_COLOR_LIGHT: #808080;
$THEME_TEXT_COLOR_DISABLED: #888888;
$THEME_RULE_COLOR: #CCCCCC;
$THEME_HEADER_HEIGHT: 80px;
$THEME_HEADER_HEIGHT_MOBILE: 40px;
$THEME_HEADER_COLOR: #333333;
$THEME_HEADER_COLOR_HOVER: #000000;
$THEME_HEADER_BACKGROUND: #FFFFFF;
$THEME_HEADER_BACKGROUND_HOVER: #EFEFEF;
$THEME_HEADER_LOGO_AR: 1.709;
$THEME_BACKGROUND: #FFFFFF;
$THEME_BACKGROUND_HOVER: #EFEFEF;
$THEME_BACKGROUND_CONTRAST: #F8F8F8;
$THEME_BACKGROUND_DISABLED: #E8E8E8;
$THEME_LEFT_MENU_CONTROL_BACKGROUND: #383838;
$THEME_LEFT_MENU_CONTROL_BACKGROUND_HOVER: #222222;
$THEME_LEFT_MENU_CONTROL_BACKGROUND_TEXT: #FFFFFF;
$THEME_LAST_ITEM: true;
