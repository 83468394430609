

@use '../../../../style/import' as *;
@import './constants/analytics';




//////////////////////////////
// BlockAnalyticsSalesGraph //
//////////////////////////////


// Titan colors
$salesGraphPrimaryHover: $TITAN_PRIMARY_COLOR_HOVER;
$salesGraphPrimaryText: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
// None yet

// General variables
$salesGraphMargin: $MARGIN;


.BlockAnalyticsSalesGraph {

	.salesGraphWrapper {

		overflow: hidden;

		.sgHeaderWrapper {

			position: relative;
			height: $analyticsHeaderHeight;
			text-align: right;

			.sgTitleWrapper {
				text-align: left;
				font-size: $analyticsHeaderFontSize;
				font-weight: $analyticsHeaderFontWeight;
				line-height: $analyticsHeaderHeight;
			}

			.sgControlsWrapper {

				position: absolute;
				top: 0;
				right: 0;

				.sgRangeWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 0 0 $salesGraphMargin;

					@media #{$break768} {
						display: none;
					}
				}

				.sgConfigWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 0 0 $salesGraphMargin;
				}
			}
		}

		.sgBodyWrapper {

			.sgErrorWrapper {
				padding: 5*$salesGraphMargin 0;
				text-align: center;
				font-size: 18px;
				font-weight: 300;
			}

			.sgGraphWrapper {

			}
		}
	}
}













