

@use '../../../../style/import' as *;




///////////////////////////////////
// BlockElementHorizontalProduct //
///////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$imageWidth: 80px;
$priceWidth: 80px;


.BlockElementHorizontalProduct {

	margin: 0 0 $MARGIN 0;

	.productWrapper {

		.productLiner {

			.productImgWrapper {

				display: inline-block;
				vertical-align: top;
				width: $imageWidth;
				max-height: 2*$imageWidth;
				margin: 0 $MARGIN 0 0;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			// Could be in either price or body column
			.priceWrapper {
				margin: $MARGIN_S 0 0 0;
				font-size: 14px;
				font-weight: 400;

				.priceFormatSymbol {
					font-weight: 300;
				}
			}

			.productBodyWrapper {

				display: inline-block;
				vertical-align: top;
				width: calc(100% - #{$imageWidth} - #{$priceWidth} - #{2*$MARGIN});
				margin: 0;

				.productTitle {
					font-size: 15px;
					font-weight: 700;
				}

				.productLineWrapper {
					font-size: 14px;
					font-weight: 300;
				}

				.productTitleSupplement {
					font-size: 12px;
					font-weight: 300;
				}
			}

			.productPriceWrapper {
				display: inline-block;
				vertical-align: top;
				width: $priceWidth;
				margin: 0 0 0 $MARGIN;

				.priceWrapper {
					text-align: right;
				}
			}
		}
	}
}
















