

@use '../../../../../../style/import' as *;




//////////////////////////////
// BlockContentImageAndCopy //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
$linkHover: $THEME_PRIMARY_COLOR;

// General variables
// None yet


.BlockContentImageAndCopy {

	position: relative;

	.blockLiner {

		&.reverse {

			.imageSection {
				
				left: 50%;

				@media #{$break768} {
					left: 0;
				}
			}

			.contentSection {
				
				margin: 0 50% 0 0;

				@media #{$break768} {
					margin: 0;
				}
			}
		}

		.imageSection {

			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			
			@media #{$break768} {
				position: relative;
				width: auto;
			}

			.imageElement {
				width: 100%;
				height: 100%;
				object-fit: cover;

				@media #{$break768} {
					height: unset;
				}
			}
		}

		.contentSection {

			position: relative;
			width: 50%;
			margin: 0 0 0 50%;
			min-height: calc(50vw);
			display: flex;
			flex-direction: column;
			justify-content: center;

			@media #{$break768} {
				width: auto;
				margin: 0;
				min-height: 0;
			}

			.contentLiner {

				margin: $MARGIN_XL $MARGIN_L;

				.titleWrapper {
					font-size: 32px;
					font-weight: 700;
					text-align: center;
					text-transform: uppercase;
				}

				.copyWrapper {
					font-size: 20px;
					text-align: left;
					margin: $MARGIN_XL $MARGIN_XL 0 $MARGIN_XL;

					p {
						margin: 0 0 $MARGIN 0;

						a {
							color: inherit;
							text-decoration: underline;
							transition: color $hoverTransition;

							&:hover {
								color: $linkHover;
							}
						}
					}
				}

				.actionWrapper {
					text-align: center;
					margin: $MARGIN_L 0 0 0;
				}
			}
		}
	}
}













