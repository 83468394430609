

@use '../../../../style/import' as *;




///////////////////
// OrderLineItem //
///////////////////


// Titan colors
$orderLineItemAccent: $TITAN_PRIMARY_COLOR;
$orderLineItemAccentColor: $TITAN_PRIMARY_COLOR_TEXT;
$orderLineItemAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$orderLineItemBackground: $TITAN_BACKGROUND;
$orderLineItemColor: $TITAN_TEXT_COLOR;
$orderLineItemInputBorderColor: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$orderLineItemMargin: 10px;


.OrderLineItem {

	overflow: auto;

	.oliLiner {

	}
}


///////////////////////////////
// OrderLineItemRefundReturn //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$olirrCheckboxWidth: 20px;
$olirrHeaderCheckboxWidth: 30px; // These also exist in _vieworder.scss
$olirrHeaderPriceWidth: 80px;
$olirrHeaderQuantityWidth: 80px;
$olirrHeaderReturnWidth: 90px;
$olirrMediaWidth: 60px;
$olirrScrollableHeight: 360px;


.OrderLineItemRefundReturn {

	.oliLiner {

		position: relative;
		margin: 0.5*$orderLineItemMargin 0;

		.checkboxBlock {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderCheckboxWidth;

			.checkboxElement {
				position: absolute;
				top: 0;
				left: calc(50% - #{0.5 * $olirrCheckboxWidth});
				width: $olirrCheckboxWidth;
				height: $olirrCheckboxWidth;
			}
		}

		.itemBlock {

			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$olirrHeaderCheckboxWidth} - #{$olirrHeaderPriceWidth} - #{$olirrHeaderQuantityWidth} - #{$olirrHeaderReturnWidth});

			.itemWrapper {

				position: relative;
				margin: 0 0 0 0.5*$orderLineItemMargin;

				.itemMediaWrapper {
					display: inline-block;
					vertical-align: top;
					width: $olirrMediaWidth;
					user-select: none;

					img {
						width: 100%;
					}
				}

				.itemDescriptionWrapper {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$olirrMediaWidth});

					.itemDescription {
						margin: 0 0 0 $orderLineItemMargin;

						.nameWrapper {
							font-size: 15px;
							font-weight: 700;
						}

						.productLineWrapper {
							font-size: 13px;
							font-weight: 400;
						}
						
						.detailsWrapper {

							margin: 0.5*$orderLineItemMargin 0 0 0;

							.detailText {
								font-size: 14px;
								font-weight: 300;
							}
						}
					}
				}
			}
		}

		.priceBlock {

			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderPriceWidth;
			text-align: center;

			.priceReadOnly {

			}
		}

		.quantityBlock {

			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderQuantityWidth;
			text-align: center;

			.quantityReadOnly {

			}

			.quantityInput {

				text-align: center;

				.quantityInputDropdownWrapper {

					.oliQuantityDropdownSelect {
						border: 1px solid $orderLineItemInputBorderColor;

						.dropdownInput {
							text-align: center;
						}
					}
				}
			}
		}

		.returnBlock {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderReturnWidth;

			.returnCheckboxElement {
				position: absolute;
				top: 0;
				left: calc(50% - #{0.5 * $olirrCheckboxWidth});
				width: $olirrCheckboxWidth;
				height: $olirrCheckboxWidth;
			}
		}
	}
}


/////////////////////////////////
// OrderLineItemShippingRefund //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderLineItemShippingRefund {

	.oliLiner {

		position: relative;
		margin: 0.5*$orderLineItemMargin 0;

		.checkboxBlock {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderCheckboxWidth;

			.checkboxElement {
				position: absolute;
				top: 0;
				left: calc(50% - #{0.5 * $olirrCheckboxWidth});
				width: $olirrCheckboxWidth;
				height: $olirrCheckboxWidth;
			}
		}

		.itemBlock {

			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$olirrHeaderCheckboxWidth} - #{$olirrHeaderPriceWidth} - #{$olirrHeaderQuantityWidth} - #{$olirrHeaderReturnWidth});

			.shippingWrapper {
				margin: 0 0 0 0.5*$orderLineItemMargin;

				.shippingLabel {
					font-size: 12px;
					margin: 0 0 0.5*$orderLineItemMargin 0;
				}

				.shippingValue {
					font-size: 14px;
				}
			}
		}

		.priceBlock {

			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderPriceWidth;
			text-align: center;

			.priceReadOnly {

			}
		}

		.quantityBlock {

			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderQuantityWidth;
			text-align: center;

			.quantityReadOnly {

			}
		}

		.returnBlock {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $olirrHeaderReturnWidth;
		}
	}
}



///////////////////////////
// OrderLineItemGradable //
///////////////////////////


// Titan colors
$oligActionButtonActiveBackground: $TITAN_PRIMARY_ULTRALIGHT;
$oligActionButtonInactiveBackgroundHover: $TITAN_BACKGROUND_HOVER;
$oligDisabledColor: $disabledColor;
$oligInputErrorColor: $errorRed;

// Theme colors
// None yet

// General variables
$oligHeaderEditableWidth: 240px;
$oligHeaderActionWidth: 60px;
$oligDrawerMaxHeight: 200px;
$oligActionButtonWidth: 0.5*$oligHeaderActionWidth;
$oligActionButtonIconScale: 0.6;
$oligInputHeight: 20px;
$oligInputIconScale: 0.7;
$oligLoadingOpacity: 0.2;
$oligSmallLoadingWidth: 15px;
$oligDisabledOpacity: 0.5;


.OrderLineItemGradable {

	.greyed.oliLiner {
		color: $oligDisabledColor;

		.visibleSection {

			.visibleBodyWrapper {

				.itemBlock {

					.itemWrapper {

						.itemMediaWrapper {
							opacity: $oligDisabledOpacity;
						}
					}
				}
			}
		}
	}

	.oliLiner {

		position: relative;
		padding: 0.5*$orderLineItemMargin 0;

		.visibleSection {

			.visibleBodyWrapper {

				display: inline-block;
				vertical-align: top;
				width: calc(100% - #{$oligHeaderActionWidth});

				.itemBlock {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$oligHeaderEditableWidth});
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
						margin: 0 0 $orderLineItemMargin 0;
					}

					.itemWrapper {

						position: relative;
						margin: 0 0 0 0.5*$orderLineItemMargin;

						.itemMediaWrapper {
							display: inline-block;
							vertical-align: top;
							width: $olirrMediaWidth;
							user-select: none;
							opacity: 1.0;
							transition: opacity $toggleTransition;

							img {
								width: 100%;
							}
						}

						.itemDescriptionWrapper {

							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$olirrMediaWidth});

							.itemDescription {
								margin: 0 0 0 $orderLineItemMargin;

								.nameWrapper {
									font-size: 15px;
									font-weight: 700;
								}

								.productLineWrapper {
									font-size: 13px;
									font-weight: 400;
								}
								
								.detailsWrapper {

									margin: 0.5*$orderLineItemMargin 0 0 0;

									.detailText {
										font-size: 14px;
										font-weight: 300;
									}
								}
							}
						}
					}
				}

				.editableBlock {

					display: inline-block;
					vertical-align: top;
					width: $oligHeaderEditableWidth;
					text-align: center;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.editableBlockLiner {

						text-align: center;

						.inputBlockWrapper {

							display: inline-block;
							vertical-align: top;
							width: 50%;
							text-align: left;

							@media #{$break768} {
								max-width: 0.5 * $oligHeaderEditableWidth;
								margin: 0 0 $orderLineItemMargin 0;
								text-align: center;
							}
							
							.inputBlock {

								.blockLabel {
									font-size: 13px;
									font-weight: 700;
									margin: 0 0 0.5*$orderLineItemMargin 0;
								}

								.noneValue.blockValue {
									font-size: 16px;
									font-weight: 300;
								}

								.blockValue {

									.microLoading {
										display: inline-block;
										vertical-align: top;
										width: $oligSmallLoadingWidth;
										height: $oligSmallLoadingWidth;
									}
								}
							}
						}

						.conditionBlockWrapper {

							.conditionBlock {

								.conditionDropdownWrapper {

									margin: 0 $orderLineItemMargin 0 0;

									@media #{$break768} {
										margin: 0 $orderLineItemMargin;
									}

									.conditionDropdown {
										background: none;

										.dropdownButt {
											.dropdownFade {
												background-image: none;
											}
											.dropdownButtBody {
												background: none;
											}
										}
									}
								}

								.conditionDisplayWrapper {
									font-size: 18px;
									font-weight: 400;
								}
							}	
						}

						.priceBlockWrapper {

							.priceBlock {

								.priceInputWrapper {

									margin: 0 $orderLineItemMargin 0 0;

									@media #{$break768} {
										margin: 0 $orderLineItemMargin;
									}

									.editingWrapper {

										.actionIcon {

											cursor: pointer;

											.actionIconLiner {
												svg {
													fill: $orderLineItemAccent;
													transition: fill $hoverTransition;
												}
											}

											&:hover {
												.actionIconLiner {
													svg {
														fill: $orderLineItemAccentHover;
													}
												}
											}
										}
									}

									.fixedWrapper {
										cursor: pointer;
										background: rgba($oligActionButtonActiveBackground, 0.0);
										transition: background $hoverTransition;

										.actionIcon {
											.actionIconLiner {
												svg {
													fill: rgba($orderLineItemAccent, 0.0);
													transition: fill $hoverTransition;
												}
											}
										}

										&:hover {
											background: $oligActionButtonActiveBackground;

											.actionIcon {
												.actionIconLiner {
													svg {
														fill: rgba($orderLineItemAccent, 1.0);
													}
												}
											}
										}
									}

									.editingWrapper,
									.fixedWrapper {

										display: inline-block;
										vertical-align: top;
										width: auto;
										position: relative;
										padding: 0 $oligInputHeight 0 0;

										.error.priceInput {
											border-color: $oligInputErrorColor;
										}

										.priceInput {
											padding: 0.25*$orderLineItemMargin 0.5*$orderLineItemMargin;
											border: 1px solid $orderLineItemInputBorderColor;
											width: calc(100% - #{$orderLineItemMargin} - 2px);
											height: $oligInputHeight - 0.5*$orderLineItemMargin;
											font-size: 14px;
										}

										.pseudoInput {
											display: inline-block;
											padding: 0;
											width: auto;
											max-width: calc(100% - #{$orderLineItemMargin} - 2px);
											height: $oligInputHeight - 0.5*$orderLineItemMargin;
											font-size: 18px;
											transition: border $hoverTransition;

											.priceFormatSymbol {
												font-weight: 300;
											}
										}

										.actionIcon {
											position: absolute;
											top: 0;
											right: 0;
											width: $oligInputHeight;
											height: $oligInputHeight;

											.actionIconLiner {
												position: absolute;
												top: 0.5*(1 - $oligInputIconScale)*$oligInputHeight;
												left: 0.5*(1 - $oligInputIconScale)*$oligInputHeight;
												width: $oligInputIconScale*$oligInputHeight;
												height: $oligInputIconScale*$oligInputHeight;
											}
										}
									}
								}

								.priceDisplayWrapper {

									font-size: 18px;
									font-weight: 400;

									.priceFormatSymbol {
										font-weight: 300;
									}
								}
							}	
						}
					}
				}
			}

			.actionBlock {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $oligHeaderActionWidth;

				.actionBlockLiner {

					.actionLoading {
						position: relative;
						width: $oligHeaderActionWidth;
						height: $oligHeaderActionWidth;

						.actionLoadingIconWrapper {
							position: absolute;
							top: 0.25 * $oligHeaderActionWidth;
							left: 0.25 * $oligHeaderActionWidth;
							width: 0.5 * $oligHeaderActionWidth;
							height: 0.5 * $oligHeaderActionWidth;

							svg {
								fill: $orderLineItemAccent;
							}
						}
					}
					
					.actionIconRow {

						margin: 0 0 0.5*$orderLineItemMargin 0;

						.actionIconWrapper {

							display: inline-block;
							vertical-align: top;
							width: 50%;

							.actionIcon {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: $oligActionButtonWidth;
								height: $oligActionButtonWidth;
								cursor: pointer;
								transition: background $hoverTransition;

								&:hover {

									.actionIconLiner {
										.Icon {
											svg {
												fill: $orderLineItemAccentHover;
											}
										}
									}
								}

								.actionIconLiner {
									position: absolute;
									top: 0.5 * (1 - $oligActionButtonIconScale) * $oligActionButtonWidth;
									left: 0.5 * (1 - $oligActionButtonIconScale) * $oligActionButtonWidth;
									width: $oligActionButtonIconScale * $oligActionButtonWidth;
									height: $oligActionButtonIconScale * $oligActionButtonWidth;

									.Icon {

										top: 0;
										left: 0;
										width: $oligActionButtonIconScale * $oligActionButtonWidth;
										height: $oligActionButtonIconScale * $oligActionButtonWidth;

										svg {
											fill: $orderLineItemAccent;
										}
									}
								}
							}
						}
					}

					.actionSaveRow {

						.actionButtonWrapper {

							margin: 0 0 0.5*$orderLineItemMargin 0;

							.actionButton {
								width: 100%;
								border: none;
								color: $orderLineItemAccentColor;
								background: $orderLineItemAccent;
								cursor: pointer;
								text-transform: uppercase;
								font-size: 13px;
								font-weight: 700;
								padding: 0.5*$orderLineItemMargin 0;
								transition: background $hoverTransition;

								&:hover {
									background: $orderLineItemAccentHover;
								}
							}
						}

						.actionResetWrapper {

							text-align: center;

							.disabled.actionResetLink {
								cursor: default;
								color: $oligDisabledColor;
							}

							.actionResetLink {
								display: inline-block;
								vertical-align: top;
								width: auto;
								cursor: pointer;
								color: $orderLineItemAccent;
								font-size: 13px;
								font-weight: 700;
								transition: color $hoverTransition;

								&:hover {
									color: $orderLineItemAccentHover;
								}
							}
						}
					}
				}
			}
		}

		.open.drawerSection {
			max-height: $oligDrawerMaxHeight;
			transition: max-height $slideTransition;
		}

		.drawerSection {
			position: relative;
			overflow: hidden;
			height: auto;
			max-height: 0;
			transition: max-height $slideFastTransition;
		}

		.active.loadingMask {
			display: block;
		}

		.loadingMask {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($orderLineItemAccent, $oligLoadingOpacity);
		}
	}
}



















