

@use '../../../../style/import' as *;




///////////////////
// EventCalendar //
///////////////////


// Titan colors
$eventCalendarAccent: $TITAN_PRIMARY_COLOR;
$eventCalendarAccentColor: $TITAN_PRIMARY_COLOR_TEXT;
$eventCalendarAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$eventCalendarBackground: $TITAN_BACKGROUND;
$eventCalendarColor: $TITAN_TEXT_COLOR;
$eventCalendarColorLight: $TITAN_TEXT_COLOR_LIGHT;

$eventCalendarBorderColor: $TITAN_RULE_COLOR;

// Theme colors
$eventCalendarThemeAccent: $THEME_PRIMARY_COLOR;
$eventCalendarThemeAccentColor: $THEME_PRIMARY_COLOR_TEXT;
$eventCalendarThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$eventCalendarThemeBackground: $THEME_BACKGROUND;
$eventCalendarThemeColor: $THEME_TEXT_COLOR;
$eventCalendarThemeColorLight: $THEME_TEXT_COLOR_LIGHT;

$eventCalendarThemeBorderColor: $THEME_RULE_COLOR;

// General variables
$eventCalendarAnimationDuration: 250ms;
$eventCalendarBorderRadius: 25px;
$eventCalendarBorderWidth: 1px;
$eventCalencarLoadingIconWidth: 120px;
$eventCalencarLoadingOpacity: 0.8;
$eventCalendarMargin: 10px;
$eventCalendarMonthHeight: 12%;
$eventCalendarWeekHeight: 45%;
$eventCalendarMobalWeekMinHeight: 360px;

$eventCalendarModalHeight: 0.65;
$eventCalendarModalOffset: 50px;


@keyframes openEventModal {

  0% {
    opacity: 0.0;
    top: 0.5*$eventCalendarModalOffset;
  }

  100% {
  	opacity: 1.0;
  	top: $eventCalendarModalOffset;
  }
}

@keyframes openBottomEventModal {

  0% {
    opacity: 0.0;
    bottom: 1.5*$eventCalendarModalOffset;
  }

  100% {
  	opacity: 1.0;
  	bottom: $eventCalendarModalOffset;
  }
}

.EventCalendar.adminTheme {

	color: $eventCalendarColor;

	.ecLiner {

		.ecCalWrapper {

			.ecCalLiner {

				.ecCalWeek.mobileWeek {
					background: $eventCalendarBackground;
				}

				.ecCalWeek {

					.ecCalToday.ecCalDay {
						border-color: $eventCalendarColor;
					}

					.expanded.ecCalDay {
						box-shadow: 0 0 $eventCalendarBorderRadius rgba($eventCalendarColor, 0.35);
					}

					.ecCalDay {
						border-color: $eventCalendarBorderColor;
						background: $eventCalendarBackground;
					}
				}
			}

			.ecLoadingOverlay {
				background: rgba($eventCalendarBackground, $eventCalencarLoadingOpacity);

				.ecLoadingIconWrapper {

					svg {
						fill: $eventCalendarAccent;
					}
				}
			}
		}
	}
}

.EventCalendar {

	color: $eventCalendarThemeColor;

	.ecLiner {

		.ecNavWrapper {
			overflow: auto;
			position: relative;
			background: rgba($eventCalendarThemeBackground, 0.95);
			padding: $eventCalendarMargin $eventCalendarMargin 0 $eventCalendarMargin;
		}

		.ecControlsWrapper {
			overflow: auto;
			position: relative;
			background: rgba($eventCalendarThemeBackground, 0.95);
			padding: 0 $eventCalendarMargin;
		}

		.ecCalWrapper {

			position: relative;

			.ecCalLiner {

				.ecCalWeek.weekView {
					padding-bottom: $eventCalendarWeekHeight;

					&:nth-child(1) {
						padding-bottom: $eventCalendarWeekHeight;
					}
				}

				
				.ecCalWeek.firstWeek {
					.expanded.ecCalDay {
						top: 0;
					}
				}

				.ecCalWeek.finalWeek {

					.firstOfWeek.ecCalDay {
						border-bottom-left-radius: $eventCalendarBorderRadius;
					}

					.lastOfWeek.ecCalDay {
						border-bottom-right-radius: $eventCalendarBorderRadius;
					}

					.expanded.ecCalDay {
						top: -100%;
					}
				}

				.ecCalWeek.mobileWeek {
					margin: 0;
					padding: 0;
					min-height: $eventCalendarMobalWeekMinHeight;
					background: $eventCalendarThemeBackground;
					overflow: auto;

					&:nth-child(1) {
						margin: 0;
						padding: 0;
					}

					.ecCalDay.mobileDay {
						position: relative;
						top: unset;
						width: auto;
						height: auto;
						border: none;
						left: unset !important;
					}

					.noDaysRendered {
						margin: 5*$eventCalendarMargin 0 0 0;
						font-size: 20px;
						font-weight: 300;
						text-align: center;
					}
				}

				.ecCalWeek {

					position: relative;
					margin-top: -1*$eventCalendarBorderWidth;
					padding-bottom: $eventCalendarMonthHeight;
					transition: padding $toggleTransition;

					&:nth-child(1) {
						margin-top: 0;
						padding-bottom: calc(#{$eventCalendarMonthHeight} + #{2*$eventCalendarMargin});
					}

					.ecCalToday.ecCalDay {
						z-index: 1;
						border-color: $eventCalendarThemeColor;
					}

					.topped.ecCalDay, .topped.ecCalToday.ecCalDay {
						z-index: 5;
					}

					.expanded.ecCalDay {
						top: -50%;
						width: calc( (200% - #{8*$eventCalendarBorderWidth}) / 7 );
						height: calc(200% - #{2*$eventCalendarBorderWidth});
						box-shadow: 0 0 $eventCalendarBorderRadius rgba($eventCalendarThemeColor, 0.35);
						transition: top $slideTransition, left $slideTransition, width $slideTransition, height $slideTransition;

						&:nth-child(1) {
							left: 0;
						}

						&:nth-child(2) {
							left: calc((1/14)*100%);
						}

						&:nth-child(3) {
							left: calc((3/14)*100%);
						}

						&:nth-child(4) {
							left: calc((5/14)*100%);
						}

						&:nth-child(5) {
							left: calc((7/14)*100%);
						}

						&:nth-child(6) {
							left: calc((9/14)*100%);
						}

						&:nth-child(7) {
							left: calc((10/14)*100%);
							width: calc( ((200% - #{8*$eventCalendarBorderWidth}) / 7) - #{$eventCalendarBorderWidth} );
						}
					}

					.ecCalDay {

						z-index: 0;

						overflow: hidden;
						position: absolute;
						top: 0;
						width: calc( (100% - #{8*$eventCalendarBorderWidth}) / 7 );
						height: calc(100% - #{2*$eventCalendarBorderWidth});

						border-width: $eventCalendarBorderWidth;
						border-style: solid;
						border-color: $eventCalendarThemeBorderColor;

						background: $eventCalendarThemeBackground;
						transition: top $slideFastTransition, left $slideFastTransition, width $slideFastTransition, height $slideFastTransition;

						&:nth-child(1) {
							left: 0;
						}

						&:nth-child(2) {
							left: calc((1/7)*100%);
						}

						&:nth-child(3) {
							left: calc((2/7)*100%);
						}

						&:nth-child(4) {
							left: calc((3/7)*100%);
						}

						&:nth-child(5) {
							left: calc((4/7)*100%);
						}

						&:nth-child(6) {
							left: calc((5/7)*100%);
						}

						&:nth-child(7) {
							left: calc((6/7)*100%);
							width: calc( ((100% - #{8*$eventCalendarBorderWidth}) / 7) - #{$eventCalendarBorderWidth} );
						}
					}
				}
			}

			.ecLoadingOverlay {
				position: absolute;
				top: 0;
				left: 0;
				right: -1*$eventCalendarBorderWidth;
				bottom: 0;

				z-index: $zMenuCoverContent;
				border-bottom-left-radius: $eventCalendarBorderRadius;
				border-bottom-right-radius: $eventCalendarBorderRadius;

				background: rgba($eventCalendarThemeBackground, $eventCalencarLoadingOpacity);

				.ecLoadingIconWrapper {
					position: absolute;
					top: calc(50% - #{$eventCalencarLoadingIconWidth/2});
					left: calc(50% - #{$eventCalencarLoadingIconWidth/2});
					width: $eventCalencarLoadingIconWidth;
					height: $eventCalencarLoadingIconWidth;

					svg {
						fill: $eventCalendarThemeAccent;
					}
				}
			}

			.ecEventModalWrapper.open {
				display: block;
				top: $eventCalendarModalOffset;
				z-index: 10;

				animation-name: openEventModal;
			  animation-duration: $eventCalendarAnimationDuration;
			}

			.ecEventModalWrapper.openBottom {
				display: block;
				bottom: $eventCalendarModalOffset;
				z-index: 10;

				animation-name: openBottomEventModal;
			  animation-duration: $eventCalendarAnimationDuration;
			}

			.idx0.ecEventModalWrapper {
				left: calc((1/7)*100% + #{$eventCalendarMargin});
			}

			.idx1.ecEventModalWrapper {
				left: calc((2/7)*100% + #{$eventCalendarMargin});
			}

			.idx2.ecEventModalWrapper {
				left: calc((3/7)*100% + #{$eventCalendarMargin});
			}

			.idx3.ecEventModalWrapper {
				left: calc((4/7)*100% + #{$eventCalendarMargin});
			}

			.idx4.ecEventModalWrapper {
				right: calc((3/7)*100% + #{$eventCalendarMargin});
			}

			.idx5.ecEventModalWrapper {
				right: calc((2/7)*100% + #{$eventCalendarMargin});
			}

			.idx6.ecEventModalWrapper {
				right: calc((1/7)*100% + #{$eventCalendarMargin});
			}

			.ecEventModalWrapper {
				display: none;

				position: absolute;
				height: calc(100% * #{$eventCalendarModalHeight});
				width: (2/7)*100%;

				background: $eventCalendarThemeBackground;
				box-shadow: 0 0 $eventCalendarBorderRadius rgba($eventCalendarThemeColor, 0.15);
				border-radius: $eventCalendarBorderRadius/2;
			}
		}
	}
}


/////////////////////////////
// EventCalendarNavigation //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$eventCalendarNavigationTitleHeight: 24px;
$eventCalendarNavigationTitleWidth: 360px;
$eventCalendarNavigationTitleWidthMobile: 300px;


.EventCalendarNavigation.mobileView {

	.ecnLiner {
		height: 2*$eventCalendarNavigationTitleHeight + 2*$eventCalendarMargin;

		.ecnTitleWrapper {
			max-width: $eventCalendarNavigationTitleWidthMobile;

			.ecnWrapper {
				font-size: $eventCalendarNavigationTitleHeight - 2px;
			}
		}
	}
}

.EventCalendarNavigation.adminTheme {

	.ecnLiner {

		border-bottom: 1px solid $eventCalendarColor;

		.ecnNavButton {
			color: $eventCalendarAccentColor;
			background: $eventCalendarAccent;

			&:hover {
				background: $eventCalendarAccentHover;
			}
		}

		.ecnButtonWrapper {

		}

		.ecnTitleWrapper {

			.leftArrow.navArrow {

				&:hover {
					.arrowElement {
						border-right-color: $eventCalendarAccent;
					}
				}

				.arrowElement {
					border-right-color: $eventCalendarColor;
				}
			}

			.rightArrow.navArrow {
				
				&:hover {
					.arrowElement {
						border-left-color: $eventCalendarAccent;
					}
				}

				.arrowElement {
					border-left-color: $eventCalendarColor;
				}
			}

			.navArrow {
				
			}

			.ecnWrapper {

				.ecnMonthWrapper {

				}

				.ecnYearWrapper {
					
				}
			}
		}
	}
}

.EventCalendarNavigation {

	.ecnLiner {

		position: relative;
		margin: 0 0 $eventCalendarMargin 0;
		border-bottom: 1px solid $eventCalendarThemeColor;
		height: $eventCalendarNavigationTitleHeight + $eventCalendarMargin;
		transition: height $breakTransition;

		.ecnNavButton {
			position: relative;
			display: inline-block;
			vertical-align: top;
			height: $eventCalendarNavigationTitleHeight;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			padding: 0 $eventCalendarMargin;
			cursor: pointer;
			border: none;
			color: $eventCalendarThemeAccentColor;
			background: $eventCalendarThemeAccent;
			margin: 0 0 0 $eventCalendarMargin;
			transition: background $hoverTransition;

			&:hover {
				background: $eventCalendarThemeAccentHover;
			}

			div {
				
			}
		}

		.ecnButtonLeftWrapper {
			position: absolute;
			height: $eventCalendarNavigationTitleHeight;
			left: 0;
			bottom: $eventCalendarMargin;
			width: auto;

			.ecnTodayButton {
				margin: 0 $eventCalendarMargin 0 0;
			}
		}	

		.ecnButtonWrapper {
			position: absolute;
			height: $eventCalendarNavigationTitleHeight;
			right: 0;
			bottom: $eventCalendarMargin;
			width: auto;
		}		

		.ecnTitleWrapper {

			position: relative;
			max-width: $eventCalendarNavigationTitleWidth;
			margin: 0 auto $eventCalendarMargin auto;
			text-align: center;
			height: $eventCalendarNavigationTitleHeight;
			line-height: $eventCalendarNavigationTitleHeight;
			transition: max-width $breakTransition;

			.leftArrow.navArrow {
				left: 0;

				&:hover {
					.arrowElement {
						border-right-color: $eventCalendarThemeAccent;
					}
				}

				.arrowElement {
					border-right-width: (3/4)*$eventCalendarNavigationTitleHeight;
					border-right-style: solid;
					border-right-color: $eventCalendarThemeColor;
				}
			}

			.rightArrow.navArrow {
				right: 0;

				&:hover {
					.arrowElement {
						border-left-color: $eventCalendarThemeAccent;
					}
				}

				.arrowElement {
					border-left-width: (3/4)*$eventCalendarNavigationTitleHeight;
					border-left-style: solid;
					border-left-color: $eventCalendarThemeColor;
				}
			}

			.navArrow {
				position: absolute;
				top: 0;
				width: $eventCalendarNavigationTitleHeight;
				height: $eventCalendarNavigationTitleHeight;
				cursor: pointer;

				.arrowElement {
					border-top: $eventCalendarNavigationTitleHeight/2 solid transparent;
					border-bottom: $eventCalendarNavigationTitleHeight/2 solid transparent;
					transition: border $hoverTransition;
				}
			}

			.ecnWrapper {

				font-size: $eventCalendarNavigationTitleHeight;
				font-weight: 700;
				text-transform: uppercase;
				transition: font-size $breakTransition;

				.ecnMonthWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					height: $eventCalendarNavigationTitleHeight;
					line-height: $eventCalendarNavigationTitleHeight;	
				}

				.ecnYearWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 0 0 $eventCalendarMargin;
					height: $eventCalendarNavigationTitleHeight;
					line-height: $eventCalendarNavigationTitleHeight;
				}
			}
		}
	}
}


///////////////////////////
// EventCalendarControls //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$eventCalendarControlsHeight: 26px;


.EventCalendarControls.adminTheme {

	.eccLiner {

		.eccAppliedFilters {

		}

		.eccControlRow {

			.eccFilters {

			}

			.eccViewControls {

				.eccViewDropdownWrapper {

					.eccViewDropdown {
						border: 1px solid $eventCalendarBorderColor;
					}
				}

				.eccViewIconGroup {

					.eccViewIconWrapper {
						
						.selected.viewIcon {

							&:hover {
								svg {
									fill: $eventCalendarColor;
								}
							}

							svg {
								fill: $eventCalendarColor;
							}
						}

						.viewIcon {

							&:hover {
								svg {
									fill: $eventCalendarAccent;
								}
							}

							svg {
								fill: $eventCalendarBorderColor;
							}
						}
					}
				}
			}
		}
	}
}


///////////////////////////
// EventCalendarControls //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$eventCalendarAdditionalFiltersHeight: 200px;
$eventCalendarControlsHeight: 26px;


.EventCalendarControls {

	.eccLiner {

		.eccAppliedFilters {

		}

		.mobileFilters.eccFilters {
				
			.supressed.eccFilterWrapper {
				display: inline-block;
			}

			.eccFilterWrapper {

				display: inline-block;
				vertical-align: top;
				width: calc(50% - #{0.5*$eventCalendarMargin});
				margin: 0 0 $eventCalendarMargin 0;

				&:nth-child(2) {
					margin-left: $eventCalendarMargin;
				}

				.eccFilterLabel {
					display: block;
					height: auto;
					font-size: 13px;
					line-height: 13px;
					margin: 0 0 $eventCalendarMargin/4 0;
				}

				.eccFilterInput {
					display: block;
					margin: 0;
				}
			}

			.eccFilterToggle, .visible.eccFilterToggle {
				display: none;
			}
		}

		.eccFilters, .eccAdditionalFilters {

			.supressed.eccFilterWrapper {
				display: none;
			}

			.eccFilterWrapper {

				display: inline-block;
				margin: 0 $eventCalendarMargin 0 0;

				.eccFilterLabel {
					display: inline-block;
					vertical-align: top;
					height: $eventCalendarControlsHeight;
					line-height: $eventCalendarControlsHeight;
					font-size: 14px;
					font-weight: 700;

					&:after {
						content: ':';
					}
				}

				.eccFilterInput {

					display: inline-block;
					vertical-align: top;
					margin: 0 0 0 $eventCalendarMargin;
					height: $eventCalendarControlsHeight;

					.eccFilterDropdown {
						height: $eventCalendarControlsHeight - 2px;
						border: 1px solid $eventCalendarThemeBorderColor;

						select {
							padding: $eventCalendarMargin/4 4*$eventCalendarMargin 0 $eventCalendarMargin;
						}

						.dropdownButt {

							.dropdownButtBody {
								text-align: right;
							}
						}
					}

					.toggleWrapper {
						height: $eventCalendarControlsHeight;
						margin: 1px 0 0 0;
					}
				}
			}

			.visible.eccFilterToggle {
				display: inline-block;
			}

			.eccFilterToggle {
				display: none;
				cursor: pointer;
				margin: 0 $eventCalendarMargin 0 0;

				&:hover {
					
					.eccFilterIcon {

						svg {
							fill: $eventCalendarAccentHover;
						}
					}

					.eccFilterToggleLabel {
						color: $eventCalendarAccentHover;
					}						
				}

				.eccFilterIcon {
					display: inline-block;
					vertical-align: top;
					height: 0.8*$eventCalendarControlsHeight;
					width: 0.8*$eventCalendarControlsHeight;
					margin: 0.1*$eventCalendarControlsHeight $eventCalendarMargin 0 0;
				}

				.eccFilterToggleLabel {
					display: inline-block;
					vertical-align: top;
					height: $eventCalendarControlsHeight;
					line-height: $eventCalendarControlsHeight;
					font-size: 14px;
					font-weight: 700;
					transition: color $hoverTransition;
				}
			}
		}

		.eccControlRow {

			position: relative;
			margin: 0 0 $eventCalendarMargin 0;
			min-height: $eventCalendarControlsHeight;

			.eccViewControls {

				position: absolute;
				top: 0;
				right: 0;
				height: $eventCalendarControlsHeight;
				text-align: right;

				.eccViewDropdownLabel {
					display: inline-block;
					vertical-align: top;
					height: $eventCalendarControlsHeight;
					line-height: $eventCalendarControlsHeight;
					font-size: 14px;
					font-weight: 700;

					&:after {
						content: ':';
					}
				}

				.eccViewDropdownWrapper {
					display: inline-block;
					vertical-align: top;
					margin: 0 0 0 $eventCalendarMargin;
					height: $eventCalendarControlsHeight;

					.eccViewDropdown {

						height: $eventCalendarControlsHeight - 2px;
						border: 1px solid $eventCalendarThemeBorderColor;

						select {
							padding: $eventCalendarMargin/4 4*$eventCalendarMargin 0 $eventCalendarMargin;
						}
					}
				}

				.eccViewIconGroup {
					display: inline-block;
					vertical-align: top;

					.eccViewIconWrapper {
						display: inline-block;
						vertical-align: top;
						width: $eventCalendarControlsHeight;
						height: $eventCalendarControlsHeight;
						margin: 0 0 0 $eventCalendarMargin;

						.selected.viewIcon {

							cursor: default;

							&:hover {
								svg {
									fill: $eventCalendarThemeColor;
								}
							}

							svg {
								fill: $eventCalendarThemeColor;
							}
						}

						.viewIcon {
							cursor: pointer;
							width: $eventCalendarControlsHeight;
							height: $eventCalendarControlsHeight;

							&:hover {
								svg {
									fill: $eventCalendarThemeAccent;
								}
							}

							svg {
								fill: $eventCalendarThemeBorderColor;
							}
						}
					}
				}
			}
		}

		.expanded.available.eccAdditionalFilters {
			max-height: $eventCalendarAdditionalFiltersHeight;
			transition: max-height $slideTransition;
		}

		.eccAdditionalFilters {
			
			overflow: auto;
			height: auto;
			max-height: 0;
			overflow: hidden;
			transition: max-height $slideFastTransition;

			.additionalLiner {
				margin: 0 0 $eventCalendarMargin 0;

				.additionalTitleWrapper {
					
					.additionalTitle {
						display: inline-block;
						border-bottom: 3px solid $eventCalendarAccent;
						font-size: 16px;
						font-weight: 700;
						margin: 0 0 $eventCalendarMargin/2 0;
						padding: 0 $eventCalendarMargin 0 0;
					}
				}
			}
		}
	}
}


//////////////////////
// EventCalendarDay //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$eventCalendarDayOverFlowIndicatorHeight: 30px;
$eventCalendarDayOverFlowIndicatorIconHeight: 14px;
$eventCalendarDayUnexpandWidth: 20px;


.EventCalendarDay.expanded {

}

.EventCalendarDay.mobileView.adminTheme {

}

.EventCalendarDay.mobileView {

	.ecdLiner {

		overflow: auto;
		position: relative;
		top: unset;
		left: unset;
		right: unset;
		bottom: unset;

		.ecdLinerHidden {

			padding: $eventCalendarMargin/2 $eventCalendarMargin/2;
		}
	}
}

.EventCalendarDay.adminTheme {

	.ecdLiner {

		.ecdLinerHidden {

			.ecDayLabel {

				.offMonth.ecDayDate {
					color: $eventCalendarBorderColor;
				}

				.ecMobileDayHr {
					background: $eventCalendarBorderColor;
				}

				.ecMobileDayLabel {
					background: $eventCalendarBackground;
				}
			}

			.ecDayBodyWrapper {

			}
		}

		.ecdLinerVisible {

			.overflowIndicatorWrapper {

				&:hover {

					.ioIndicatorWrapper {
						background: $eventCalendarAccentHover;
					}
				}

				.oiTransition {
					background-image: linear-gradient(rgba($eventCalendarBackground, 0.0), rgba($eventCalendarBackground, 1.0));
				}

				.oiMain {
					background: $eventCalendarBackground;
				}

				.ioIndicatorWrapper {

					background: $eventCalendarAccent;

					.ioIndicatorIconWrapper {

						.ioIndicatorIcon {

							svg {
								fill: $eventCalendarThemeAccentColor;
							}
						}
					}
				}
			}
		}
	}
}

.EventCalendarDay {

	.ecdLiner {

		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&.expanded {

			.ecdLinerHidden {

				.ecDayBodyWrapper {
					overflow: auto;
				}
			}
		}

		.ecdLinerHidden {

			padding: $eventCalendarMargin/2 $eventCalendarMargin/2;

			.ecDayLabel.ecTodayLabel {

				.ecMobileDayHr {
					background: $eventCalendarThemeColor;
				}

				.ecMobileDayLabel {
					font-weight: 700;
				}
			}

			.ecDayLabel {

				position: relative;
				text-align: center;

				.ecDayUnexpand {

					position: absolute;
					top: 0;
					right: 0;
					width: $eventCalendarDayUnexpandWidth;
					height: $eventCalendarDayUnexpandWidth;
					font-size: $eventCalendarDayUnexpandWidth;
					font-weight: 300;
					cursor: pointer;

					.ecDayX {

					}					
				}

				.ecDayName {
					font-size: 14px;
					font-weight: 700;
					margin: 0 0 $eventCalendarMargin/2 0;
				}

				.offMonth.ecDayDate {
					color: $eventCalendarThemeBorderColor;
				}

				.ecDayDate {

				}

				.ecMobileDayHr {
					position: absolute;
					top: calc((100% - 1px) / 2);
					left: 0;
					width: 100%;
					height: 1px;
					background: $eventCalendarThemeBorderColor;
				}

				.ecMobileDayLabel {
					position: relative;
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					width: auto;
					margin: 0 auto;
					padding: 0 $eventCalendarMargin;
					background: $eventCalendarThemeBackground;
					z-index: 1;
				}
			}

			.ecDayBodyWrapper {
				margin: $eventCalendarMargin/2 0 0 0;

				.ecDayBody {

					.ecDayBodyNotice {
						font-size: 14px;
						font-weight: 300;
						text-align: center;
						margin: 0 0 $eventCalendarMargin/2 0;
					}
				}
			}
		}

		.ecdLinerVisible {

			.overflowIndicatorWrapper {
				cursor: pointer;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: $eventCalendarDayOverFlowIndicatorHeight;

				&:hover {

					.ioIndicatorWrapper {

						top: (1/3)*$eventCalendarDayOverFlowIndicatorHeight + ((2/3)*$eventCalendarDayOverFlowIndicatorHeight - $eventCalendarDayOverFlowIndicatorIconHeight)/2;
						background: $eventCalendarThemeAccentHover;

						.ioIndicatorIconWrapper {

						}
					}
				}

				.oiTransition {
					height: (1/3)*$eventCalendarDayOverFlowIndicatorHeight;
					background-image: linear-gradient(rgba($eventCalendarThemeBackground, 0.0), rgba($eventCalendarThemeBackground, 1.0));
				}

				.oiMain {
					height: (2/3)*$eventCalendarDayOverFlowIndicatorHeight;
					background: $eventCalendarThemeBackground;
				}

				.ioIndicatorWrapper {

					position: absolute;
					top: (1/3)*$eventCalendarDayOverFlowIndicatorHeight;
					left: calc(50% - #{$eventCalendarDayOverFlowIndicatorIconHeight/2});
					width: $eventCalendarDayOverFlowIndicatorIconHeight;
					height: $eventCalendarDayOverFlowIndicatorIconHeight;
					background: $eventCalendarThemeAccent;
					border-radius: 50%;
					transition: background $hoverTransition, top $hoverTransition;

					.ioIndicatorIconWrapper {

						position: absolute;
						top: 15%;
						left: 25%;
						width: 50%;
						height: 50%;

						.ioIndicatorIcon {

							svg {
								fill: $eventCalendarThemeAccentColor;
							}
						}
					}
				}
			}
		}
	}
}


/////////////////////////////
// EventCalendarEventEntry //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$eventCalendarEventEntryLineHeight: 12px;
$eventCalendarEventEntryLineHeightMobile: 14px;
$eventCalendarEventEntryMargin: 3px;
$eventCalendarEventEntryTimeWidth: 35px;
$eventCalendarEventEntryTimeWidthMobile: 45px;
$eventCalendarEventEntrySummaryMaxHeight: 1000px;


.EventCalendarEventEntry.magic {
	
	border: 1px solid $EVENT_MAGIC_BACKGROUND;
	background: $EVENT_MAGIC_BACKGROUND;
	color: $EVENT_MAGIC_COLOR;

	&:hover {
		background: $EVENT_MAGIC_BACKGROUND_HOVER;
		border: 1px solid $EVENT_MAGIC_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.pokemon {
	
	border: 1px solid $EVENT_POKEMON_BACKGROUND;
	background: $EVENT_POKEMON_BACKGROUND;
	color: $EVENT_POKEMON_COLOR;

	&:hover {
		background: $EVENT_POKEMON_BACKGROUND_HOVER;
		border: 1px solid $EVENT_POKEMON_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.lorcana {
	
	border: 1px solid $EVENT_LORCANA_BACKGROUND;
	background: $EVENT_LORCANA_BACKGROUND;
	color: $EVENT_LORCANA_COLOR;

	&:hover {
		background: $EVENT_LORCANA_BACKGROUND_HOVER;
		border: 1px solid $EVENT_LORCANA_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.star-wars-unlimited {
	
	border: 1px solid $EVENT_STARWARS_BACKGROUND;
	background: $EVENT_STARWARS_BACKGROUND;
	color: $EVENT_STARWARS_COLOR;

	&:hover {
		background: $EVENT_STARWARS_BACKGROUND_HOVER;
		border: 1px solid $EVENT_STARWARS_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.one-piece {
	
	border: 1px solid $EVENT_ONEPIECE_BACKGROUND;
	background: $EVENT_ONEPIECE_BACKGROUND;
	color: $EVENT_ONEPIECE_COLOR;

	&:hover {
		background: $EVENT_ONEPIECE_BACKGROUND_HOVER;
		border: 1px solid $EVENT_ONEPIECE_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.warhammer {
	
	border: 1px solid $EVENT_WARHAMMER_BACKGROUND;
	background: $EVENT_WARHAMMER_BACKGROUND;
	color: $EVENT_WARHAMMER_COLOR;

	&:hover {
		background: $EVENT_WARHAMMER_BACKGROUND_HOVER;
		border: 1px solid $EVENT_WARHAMMER_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.yu-gi-oh {
	
	border: 1px solid $EVENT_YUGIOH_BACKGROUND;
	background: $EVENT_YUGIOH_BACKGROUND;
	color: $EVENT_YUGIOH_COLOR;

	&:hover {
		background: $EVENT_YUGIOH_BACKGROUND_HOVER;
		border: 1px solid $EVENT_YUGIOH_BACKGROUND_HOVER;
	}
}

.EventCalendarEventEntry.mobileView {

	.eceeLiner {
		font-size: $eventCalendarEventEntryLineHeightMobile - 1px;

		.eceeLabel {
			width: calc(100% - #{$eventCalendarEventEntryTimeWidthMobile} - #{2*$eventCalendarEventEntryMargin} - 1px);
			font-size: $eventCalendarEventEntryLineHeightMobile - 1px;
			line-height: $eventCalendarEventEntryLineHeightMobile;
			max-height: 2*$eventCalendarEventEntryLineHeightMobile;
		}

		.eceeTimeWrapper {
			width: $eventCalendarEventEntryTimeWidthMobile;
			font-size: $eventCalendarEventEntryLineHeightMobile - 1px;
		}
	}
}

.EventCalendarEventEntry.adminTheme {

	border: 1px solid $eventCalendarAccent;
	background: $eventCalendarAccent;
	color: $eventCalendarAccentColor;

	&:hover {
		background: $eventCalendarAccentHover;
		border: 1px solid $eventCalendarAccentHover;
	}

	.eceeLiner {

		.eceeLabel {
			border-right: 1px solid $eventCalendarAccentColor;
		}

		.eceeSummaryWrapper {

			.eceeSummary {
				background: $eventCalendarBackground;
			}
		}
	}
}

.EventCalendarEventEntry {

	border: 1px solid $eventCalendarThemeAccent;
	border-radius: $eventCalendarEventEntryMargin;
	background: $eventCalendarThemeAccent;
	color: $eventCalendarThemeAccentColor;
	margin: 0 0 $eventCalendarEventEntryMargin 0;
	cursor: pointer;
	transition: background $hoverTransition, border $hoverTransition;

	&:hover {
		background: $eventCalendarThemeAccentHover;
		border: 1px solid $eventCalendarThemeAccentHover;
	}

	.eceeLiner {
		margin: $eventCalendarEventEntryMargin;
		font-size: $eventCalendarEventEntryLineHeight - 1px;

		.eceeLabel {
			
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$eventCalendarEventEntryTimeWidth} - #{2*$eventCalendarEventEntryMargin} - 1px);
			font-size: $eventCalendarEventEntryLineHeight - 1px;
			line-height: $eventCalendarEventEntryLineHeight;
			max-height: 2*$eventCalendarEventEntryLineHeight;
			padding: 0 $eventCalendarEventEntryMargin 0 0;
			border-right: 1px solid $eventCalendarThemeAccentColor;
			overflow: hidden;
		}

		.eceeTimeWrapper {
			display: inline-block;
			vertical-align: top;
			text-align: right;
			width: $eventCalendarEventEntryTimeWidth;
			padding: 0 0 0 $eventCalendarEventEntryMargin;
			font-size: $eventCalendarEventEntryLineHeight - 1px;
		}

		.eceeSummaryWrapper.expanded {
			max-height: $eventCalendarEventEntrySummaryMaxHeight;
		}

		.eceeSummaryWrapper {
			position: relative;
			height: auto;
			max-height: 0;
			overflow: hidden;
			transition: max-height $slideTransition;

			.eceeSummary {
				margin: $eventCalendarEventEntryMargin/2 0;
				background: $eventCalendarThemeBackground;
			}
		}
	}
}


///////////////////////////////
// EventCalendarEventSummary //
///////////////////////////////


// Titan colors
$eventCalendarEventSummaryColor: $TITAN_RULE_COLOR;

// Theme colors
$eventCalendarEventSummaryThemeColor: $THEME_RULE_COLOR;

// General variables
$eventCalendarEventSummaryConfigIconWidth: 20px;
$eventCalendarEventSummaryHeaderHeight: 50px;


.EventCalendarEventSummary.mobileView.adminTheme {
	color: $eventCalendarColor;
}

.EventCalendarEventSummary.mobileView {

	color: $eventCalendarThemeColor;
	font-size: 16px;

	.eceLiner {

		.eceHeaderWrapper {
			display: none;
		}

		.eceFooterWrapper {
			display: none;
		}

		.eceBodyScrollWrapper {
			position: relative;
			top: unset;
			bottom: unset;
			left: unset;
			right: unset;
			max-height: $eventCalendarEventEntrySummaryMaxHeight;
		}
	}
}


.EventCalendarEventSummary.adminTheme {

	.eceLiner {

		.eceHeaderWrapper {
			background: $eventCalendarBackground;
			border-bottom-color: $eventCalendarEventSummaryColor;

			.eceHeader {

				.eceIconWrapper {

					&:hover {

						svg {
							fill: $eventCalendarColor;
						}

						.textWrapper {
							color: $eventCalendarColor;
						}
					}

					svg {
						fill: $eventCalendarEventSummaryColor;
					}

					.textWrapper {
						color: $eventCalendarEventSummaryColor;
					}
				}
			}
		}

		.eceFooterWrapper {
			background: $eventCalendarBackground;
			border-top-color: $eventCalendarEventSummaryColor;
		}

		.eceBodyScrollWrapper {

			.eceBodyWrapper {

				.eceBody {
					
					.titleWrapper {
						
						.nameWrapper {

						}

						.timeWrapper {
							color: $eventCalendarColorLight;
						}			
					}

					.configWrapper {

						.configDataWrapper {

							.configIconWrapper {

								.iconSVGWrapper {

									svg {
										fill: $eventCalendarColor;
									}
								}
							}

							.configLabelWrapper {
								color: $eventCalendarColorLight;
							}
						}
					}

					.descWrapper {
						color: $eventCalendarColorLight;
						border-top: 1px solid $eventCalendarColor;
					}
				}
			}
		}
	}
}

.EventCalendarEventSummary {

	.eceLiner {

		.eceHeaderWrapper {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: $eventCalendarEventSummaryHeaderHeight + 1px;
			background: $eventCalendarThemeBackground;
			border-bottom: 1px solid $eventCalendarEventSummaryThemeColor;

			.eceHeader {

				text-align: right;

				.eceIconWrapper {
					cursor: pointer;
					position: relative;
					display: inline-block;
					vertical-align: top;
					margin: $eventCalendarMargin;
					width: $eventCalendarEventSummaryHeaderHeight - 2*$eventCalendarMargin;
					height: $eventCalendarEventSummaryHeaderHeight - 2*$eventCalendarMargin;

					.eceIcon {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					&:hover {

						svg {
							fill: $eventCalendarThemeColor;
						}

						.textWrapper {
							color: $eventCalendarThemeColor;
						}
					}

					svg {
						fill: $eventCalendarEventSummaryThemeColor;
					}

					.textWrapper {
						font-size: $eventCalendarEventSummaryHeaderHeight - $eventCalendarMargin;
						color: $eventCalendarEventSummaryThemeColor;
						transition: color $hoverTransition;
					}
				}
			}
		}

		.eceFooterWrapper {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: $eventCalendarEventSummaryHeaderHeight + 1px;
			background: $eventCalendarThemeBackground;
			border-top: 1px solid $eventCalendarEventSummaryThemeColor;

			.eceFooter {

			}
		}

		.eceBodyScrollWrapper {

			position: absolute;
			top: $eventCalendarEventSummaryHeaderHeight + 1px;
			bottom: $eventCalendarEventSummaryHeaderHeight + 1px;
			left: 0;
			right: 0;
			overflow: auto;

			.eceBodyWrapper {

				.eceBody {
					
					margin: $eventCalendarMargin;

					.titleWrapper {
						
						margin: 0 0 $eventCalendarMargin 0;

						.nameWrapper {
							font-size: 24px;
							font-weight: 700;
							font-family: $fontLato;
						}

						.timeWrapper {
							color: $eventCalendarThemeColorLight;
						}			
					}

					.configWrapper {
						
						padding: $eventCalendarMargin 0;

						.configDataWrapper {

							position: relative;
							margin: 0 0 $eventCalendarMargin 0;

							.configIconWrapper {
								position: absolute;
								top: 0;
								left: 0;
								width: $eventCalendarEventSummaryConfigIconWidth;
								height: $eventCalendarEventSummaryConfigIconWidth;

								.iconSVGWrapper {

									width: $eventCalendarEventSummaryConfigIconWidth;
									height: $eventCalendarEventSummaryConfigIconWidth;

									svg {
										fill: $eventCalendarColor;
									}
								}
							}

							.configLabelWrapper {
								margin-left: $eventCalendarEventSummaryConfigIconWidth + $eventCalendarMargin;
								color: $eventCalendarThemeColorLight;
								min-height: $eventCalendarEventSummaryConfigIconWidth;
								line-height: $eventCalendarEventSummaryConfigIconWidth;
							}
						}
					}

					.descWrapper {
						font-size: 14px;
						color: $eventCalendarThemeColorLight;
						margin: 0 0 2*$eventCalendarMargin 0;
						padding: $eventCalendarMargin 0 0 0;
						border-top: 1px solid $eventCalendarThemeColor;
					}
				}
			}
		}
	}
}






























