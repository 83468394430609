

@use '../../../../style/import' as *;

// Titan colors
$lorcanaImageFlipButtonColor: $TITAN_TEXT_COLOR;
$lorcanaImageFlipButtonColorHover: $TITAN_TEXT_COLOR_HOVER;
$lorcanaImageFlipButtonBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$lorcanaImageHeight: 139.34426%;


//////////////////
// LorcanaImage //
//////////////////

.LorcanaImage {

	position: relative;
	display: inline-block;
	width: 100%;
	padding-bottom: $lorcanaImageHeight;

	.lorcanaObjImageWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img {
			width: 100%;
		}
	}
}


