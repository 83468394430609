

@use '../../../../../style/import' as *;




////////////////////////
// AutocompleteOption //
////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AutocompleteOption {

	.optionLiner {

		padding: $MARGIN $MARGIN_L;

		.optionLinePrimary {

			font-weight: 400;
			font-size: 15px;

			.suggestStrong {
				font-weight: 700;
			}
		}

		.optionLineSecondary {

			font-weight: 300;
			font-size: 13px;

			.suggestStrong {
				font-weight: 700;
			}

			.secondaryLabel {

				&:after {
					content: ':';
				}

				margin: 0 $MARGIN 0 0;	
			}

			.secondaryValue {

			}
		}
	}
}













