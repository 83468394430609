

@use '../../../../style/import' as *;




///////////
// Graph //
///////////


// Titan colors
$graphAdminPrimary: $TITAN_PRIMARY_COLOR;
$graphAdminPrimaryLight: $TITAN_PRIMARY_ULTRALIGHT;
$graphAdminBorderColor: $TITAN_RULE_COLOR;
$graphAdminPlaceholderBackground: $TITAN_BACKGROUND_CONTRAST;
$graphAdminTextColor: $TITAN_TEXT_COLOR;
$graphAdminTextColorHover: $TITAN_TEXT_COLOR_HOVER;
$graphAdminTextColorDisabled: $TITAN_TEXT_COLOR_DISABLED;

// Theme colors
$graphPrimary: $THEME_PRIMARY_COLOR;
$graphPrimaryLight: $THEME_PRIMARY_ULTRALIGHT;
$graphBorderColor: $THEME_RULE_COLOR;
$graphPlaceholderBackground: $THEME_BACKGROUND_CONTRAST;
$graphTextColor: $THEME_TEXT_COLOR;
$graphTextColorHover: $THEME_TEXT_COLOR_HOVER;
$graphTextColorDisabled: $THEME_TEXT_COLOR_DISABLED;

// General variables
$graphLoadingIconHeight: 60px;
$graphMargin: 10px;


.Graph.titanTheme {

	.graphWrapper {
		.graphLoading {

			background: $graphAdminPlaceholderBackground;

			.graphLoadingIcon {
				svg {
					fill: $graphAdminPrimary;
				}
			}
		}
	}
}


.Graph {

	.graphWrapper {

		position: relative;

		.graphLoading {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $graphPlaceholderBackground;

			.graphLoadingIcon {
				position: absolute;
				top: calc(50% - #{0.5 * $graphLoadingIconHeight});
				left: calc(50% - #{0.5 * $graphLoadingIconHeight});
				width: $graphLoadingIconHeight;
				height: $graphLoadingIconHeight;

				svg {
					fill: $graphPrimary;
				}
			}
		}
	}
}


///////////////
// AreaGraph //
///////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$graphAreaElementWidth: 180px;
$graphAreaLegendPadding: 5%;
$graphAreaLegendElementHeight: 18px;


.AreaGraph.titanTheme {

	.legendWrapper {

		.disabled.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						// color: $graphAdminTextColorDisabled;
					}
				}

				.legendSwatch {
					background: $graphAdminTextColorDisabled;
				}

				.legendLabel {
					color: $graphAdminTextColorDisabled;
				}
			}
		}

		.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						color: $graphAdminTextColorHover;
					}
				}

				.legendSwatch {
					background: $graphAdminPrimary;
				}

				.legendLabel {
					color: $graphAdminTextColor;
				}
			}
		}
	}
}

.AreaGraph {

	.legendWrapper {
		padding: $graphMargin $graphAreaLegendPadding 0 $graphAreaLegendPadding;

		.disabled.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						// color: $graphTextColorDisabled;
					}
				}

				.legendSwatch {
					background: $graphTextColorDisabled;
				}

				.legendLabel {
					color: $graphTextColorDisabled;
				}
			}
		}

		.legendElementWrapper {
			display: inline-block;
			vertical-align: top;
			width: $graphAreaElementWidth;
			margin: 0 0 $graphMargin 0;
			text-align: left;

			.legendElement {

				cursor: pointer;
				margin: 0 0.5*$graphMargin;

				&:hover {
					.legendLabel {
						color: $graphTextColorHover;
						font-weight: 700;
					}
				}

				.legendSwatch {
					display: inline-block;
					vertical-align: top;
					width: $graphAreaLegendElementHeight;
					height: $graphAreaLegendElementHeight;
					background: $graphPrimary;
				}

				.legendLabel {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$graphAreaLegendElementHeight} - #{$graphMargin});
					height: $graphAreaLegendElementHeight;
					line-height: $graphAreaLegendElementHeight;
					color: $graphTextColor;
					font-size: 14px;
					font-weight: 400;
					padding: 0 0 0 $graphMargin;
					user-select: none;
					transition: color $hoverTransition, font-weight $hoverTransition;
				}
			}
		}
	}
}


///////////////
// LineGraph //
///////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$graphAreaElementWidth: 180px;
$graphAreaLegendPadding: 5%;
$graphAreaLegendElementHeight: 18px;


.LineGraph.titanTheme {

	.legendWrapper {

		.disabled.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						// color: $graphAdminTextColorDisabled;
					}
				}

				.legendSwatch {
					background: $graphAdminTextColorDisabled;
				}

				.legendLabel {
					color: $graphAdminTextColorDisabled;
				}
			}
		}

		.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						color: $graphAdminTextColorHover;
					}
				}

				.legendSwatch {
					background: $graphAdminPrimary;
				}

				.legendLabel {
					color: $graphAdminTextColor;
				}
			}
		}
	}
}

.LineGraph {

	.legendWrapper {
		padding: $graphMargin $graphAreaLegendPadding 0 $graphAreaLegendPadding;

		.disabled.legendElementWrapper {

			.legendElement {

				&:hover {
					.legendLabel {
						// color: $graphTextColorDisabled;
					}
				}

				.legendSwatch {
					background: $graphTextColorDisabled;
				}

				.legendLabel {
					color: $graphTextColorDisabled;
				}
			}
		}

		.legendElementWrapper {
			display: inline-block;
			vertical-align: top;
			width: $graphAreaElementWidth;
			margin: 0 0 $graphMargin 0;
			text-align: left;

			.legendElement {

				cursor: pointer;
				margin: 0 0.5*$graphMargin;

				&:hover {
					.legendLabel {
						color: $graphTextColorHover;
						font-weight: 700;
					}
				}

				.legendSwatch {
					display: inline-block;
					vertical-align: top;
					width: $graphAreaLegendElementHeight;
					height: $graphAreaLegendElementHeight;
					background: $graphPrimary;
				}

				.legendLabel {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$graphAreaLegendElementHeight} - #{$graphMargin});
					height: $graphAreaLegendElementHeight;
					line-height: $graphAreaLegendElementHeight;
					color: $graphTextColor;
					font-size: 14px;
					font-weight: 400;
					padding: 0 0 0 $graphMargin;
					user-select: none;
					transition: color $hoverTransition, font-weight $hoverTransition;
				}
			}
		}
	}
}








