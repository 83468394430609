

@use '../../../../style/import' as *;

// Titan colors
$galleryColor: $TITAN_TEXT_COLOR;
$galleryColorHover: $TITAN_TEXT_COLOR_HOVER;

$galleryPrimary: $TITAN_PRIMARY_COLOR;
$galleryPrimaryHover: $TITAN_PRIMARY_COLOR_HOVER;
$galleryPrimaryText: $TITAN_PRIMARY_COLOR_TEXT;

$galleryLoadingIconColor: $TITAN_PRIMARY_COLOR;
$galleryLoadingScreenColor: $TITAN_BACKGROUND;

// Theme colors
$galleryThemeColor: $THEME_TEXT_COLOR;
$galleryThemeColorHover: $THEME_TEXT_COLOR_HOVER;

$galleryThemePrimary: $THEME_PRIMARY_COLOR;
$galleryThemePrimaryHover: $THEME_PRIMARY_COLOR_HOVER;
$galleryThemePrimaryText: $THEME_PRIMARY_COLOR_TEXT;

$galleryThemeLoadingIconColor: $THEME_PRIMARY_COLOR;
$galleryThemeLoadingScreenColor: $THEME_BACKGROUND;

// General variables
$galleryMargin: 10px;
$galleryMinHeight: 320px;

// Component variables
$galleryLoadingIconWidth: 80px;





/////////////
// Gallery //
/////////////




// Assign theme color overrides here
.Gallery.theme {
	
	.galleryLoadingArea {

		.galleryLoading {

			.galleryLoadingScreen {
				background: $galleryThemeLoadingScreenColor;
			}

			.galleryLoadingWrapper {

				svg {
					fill: $galleryThemeLoadingIconColor;
				}
			}
		}
	}
}

// Component-specific overrides here
.Gallery.productSearch {

}

// Main component
.Gallery {

	.galleryLoadingArea {
		position: relative;
		min-height: $galleryMinHeight;

		.galleryBodyNoResults {
			margin: 8 * $galleryMargin 0 0 0;
			text-align: center;
			font-size: 36px;
			font-weight: 100;
			user-select: none;
		}

		.galleryLoading.loading {
			display: block;
		}

		.galleryLoading {
			display: none;
				
			.galleryLoadingScreen {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $galleryLoadingScreenColor;
				opacity: 0.6;
			}

			.galleryLoadingWrapper {
				position: absolute;
				top: calc(50% - #{$galleryLoadingIconWidth}/2);
				left: calc(50% - #{$galleryLoadingIconWidth}/2);
				width: $galleryLoadingIconWidth;
				height: $galleryLoadingIconWidth;

				svg {
					fill: $galleryLoadingIconColor;
				}
			}
		}
	}
}




///////////////////
// GalleryHeader //
///////////////////


// Titan colors
$galleryHeaderDisabledColor: $disabledColor;
$galleryHeaderVrColor: $TITAN_ADMIN_PAGE_BACKGROUND;

// Theme colors
$galleryHeaderThemeDisabledColor: $disabledColor;
$galleryHeaderThemeVrColor: $THEME_BACKGROUND;

// General variables
$galleryHeaderHeight: 24px;

// Component variables
// None yet





// Assign theme color overrides here
.Gallery.theme .GalleryHeader {

	.ghSortWrapper {

		.ghSort {

			.sortDropdownWrapper {
				border-bottom-color: $galleryThemeColor;
			}

			.sortVr {
				background: $galleryHeaderThemeVrColor;
			}

			.sortArrowWrapper {

				&:hover {

					.sortArrow.disabled {

						.upArrow.arrowWrapper {
							border-bottom-color: $galleryHeaderThemeDisabledColor; 
						}

						.arrowWrapper {
							border-top-color: $galleryHeaderThemeDisabledColor; 
						}
					}

					.sortArrow {

						.upArrow.arrowWrapper {
							border-bottom-color: $galleryThemeColorHover; 
						}

						.arrowWrapper {
							border-top-color: $galleryThemeColorHover; 
						}
					}
				}

				.sortArrow.disabled {

					.upArrow.arrowWrapper {
						border-bottom-color: $galleryHeaderThemeDisabledColor; 
					}

					.arrowWrapper {
						border-top-color: $galleryHeaderThemeDisabledColor; 
					}
				}

				.sortArrow {

					.upArrow.arrowWrapper {
						border-bottom-color: $galleryThemeColor;
					}

					.arrowWrapper {
						border-top-color: $galleryThemeColor; 
					}
				}
			}
		}
	}
}


// Component-specific overrides here
.Gallery.productSearch .GalleryHeader {

}


.Gallery .GalleryHeader {

	position: relative;
	height: $galleryHeaderHeight;

	.ghSortWrapper {

		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		height: $galleryHeaderHeight;
		text-align: right;

		.ghSort {

			.sortLabel {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: (2/3)*$galleryHeaderHeight;
				line-height: (2/3) * $galleryHeaderHeight;
				margin: 0 $galleryMargin/2 0 0;
				font-size: 14px;
				font-weight: 300;
			}

			.sortDropdownWrapper {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: $galleryHeaderHeight;
				border-bottom: 1px solid $galleryColor;
				cursor: pointer;

				select {
					border: none;
					background: none;
				}
			}

			.sortVr {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: $galleryHeaderHeight;

				width: 1px;
				margin: 0 $galleryMargin;
				background: $galleryHeaderVrColor;
			}

			.sortArrowWrapper {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: $galleryHeaderHeight;
				height: $galleryHeaderHeight;
				user-select: none;

				&:hover {

					.sortArrow.disabled {

						.upArrow.arrowWrapper {
							border-bottom-color: $galleryHeaderDisabledColor; 
						}

						.arrowWrapper {
							border-top-color: $galleryHeaderDisabledColor; 
						}
					}

					.sortArrow {

						.upArrow.arrowWrapper {
							border-bottom-color: $galleryColorHover; 
						}

						.arrowWrapper {
							border-top-color: $galleryColorHover; 
						}
					}
				}

				.sortArrow.disabled {

					cursor: default;

					.upArrow.arrowWrapper {
						border-bottom-color: $galleryHeaderDisabledColor; 
					}

					.arrowWrapper {
						border-top-color: $galleryHeaderDisabledColor; 
					}
				}

				.sortArrow {

					position: absolute;
					top: 0;
					right: $galleryMargin;
					bottom: 0;
					width: $galleryHeaderHeight / 2;
					cursor: pointer;

					.upArrow.arrowWrapper {
						border-top: none;
						border-bottom: 1.5 * ($galleryHeaderHeight / 3) solid $galleryColor;
					}

					.arrowWrapper {

						position: absolute;
						top: (2/8) * $galleryHeaderHeight;
						left: 0;
						width: 0; 
						height: 0; 

						border-left: ($galleryHeaderHeight / 3) solid transparent;
						border-right: ($galleryHeaderHeight / 3) solid transparent; 
						border-top: 1.5 * ($galleryHeaderHeight / 3) solid $galleryColor; 
						transition: border-color $hoverTransition;
					}
				}
			}
		}
	}

	.ghCountWrapper {

		width: auto;

		.ghCount {

			.ghCountText {
				
				font-size: 18px;
				line-height: $galleryHeaderHeight;

				.ghCountNumber {
					display: inline-block;
					vertical-align: top;
					width: auto;
				}

				.ghCountLabel {
					display: inline-block;
					vertical-align: top;
					width: auto;
				}
			}
		}
	}
}


///////////////////////
// GalleryPagination //
///////////////////////


// Titan colors
$galleryPaginationDisabledColor: $disabledColor;

// Theme colors
$galleryPaginationThemeDisabledColor: $disabledColor;

// General variables
$galleryPaginationHeight: 20px;

$galleryPaginationOffset: 4px;

// Component variables
// None yet



// Assign theme color overrides here
.theme.Gallery .GalleryPagination {

	.gpPagination {

		.gpInfiniteWrapper {

			.gpInfiniteActions {

				.gpInfiniteLoadMoreAction {

					.gpInfiniteLoadMoreActionIcon {

						svg {
							fill: $galleryThemePrimary;
						}
					}

					.gpInfiniteLoadMoreActionButton {
						background: $galleryThemePrimary;
						color: $galleryThemePrimaryText;

						&:hover {
							background: $galleryThemePrimaryHover;
						}
					}
				}
			}
		}

		.gpControls {

			.lhsControls.disabled {

				.backOne {

					&:hover {
						.leftArrow {
							border-right-color: $galleryPaginationThemeDisabledColor;
						}
					}

					.leftArrow {
						border-right-color: $galleryPaginationThemeDisabledColor;
					}
				}

				.backAll {
					border-color: $galleryPaginationThemeDisabledColor;
					cursor: default;

					&:hover {
						border-color: $galleryPaginationThemeDisabledColor;

						.leftArrow {
							border-right-color: $galleryPaginationThemeDisabledColor;
						}
					}

					.leftArrow {
						border-right-color: $galleryPaginationThemeDisabledColor;
					}
				}
			}

			.lhsControls {

				.leftArrow {
					border-right-color: $galleryThemeColor;
				}

				.backAll {
					border-left-color: $galleryThemeColor;

					&:hover {
						border-color: $galleryThemeColorHover;

						.leftArrow {
							border-right-color: $galleryThemeColorHover;
						}
					}
				}

				.backOne {

					&:hover {
						.leftArrow {
							border-right-color: $galleryThemeColorHover;
						}
					}
				}
			}

			.rhsControls.disabled {

				.forwardOne {

					&:hover {
						.rightArrow {
							border-left-color: $galleryPaginationThemeDisabledColor;
						}
					}

					.rightArrow {
						border-left-color: $galleryPaginationThemeDisabledColor;
					}
				}

				.forwardAll {
					border-color: $galleryPaginationThemeDisabledColor;

					&:hover {
						border-color: $galleryPaginationThemeDisabledColor;

						.rightArrow {
							border-left-color: $galleryPaginationThemeDisabledColor;
						}
					}

					.rightArrow {
						border-left-color: $galleryPaginationThemeDisabledColor;
					}
				}
			}

			.rhsControls {

				.rightArrow {
					border-left: 0.75*$galleryPaginationHeight solid $galleryThemeColor;
					
					&:hover {
						border-left-color: $galleryThemeColorHover;
					}
				}

				.forwardAll {
					border-right-color: $galleryThemeColor;

					&:hover {
						border-color: $galleryThemeColorHover;

						.rightArrow {
							border-left-color: $galleryThemeColorHover;
						}
					}
				}

				.forwardOne {

					&:hover {
						border-color: $galleryThemeColorHover;

						.rightArrow {
							border-left-color: $galleryThemeColorHover;
						}
					}
				}
			}	
		}

		.gpSize {

			.gpSizeDropdown {
				border-bottom-color: $galleryThemeColor;
			}
		}
	}
}


// Component-specific overrides here
.Gallery.productSearch .GalleryPagination {

}


.Gallery .GalleryPagination {
	
	.gpPagination {

		text-align: center;

		.gpInfiniteWrapper {

			.gpInfiniteActions {

				text-align: center;

				.gpInfiniteLoadMoreAction {

					display: inline-block;
					vertical-align: top;
					margin: $galleryMargin 0 2*$galleryMargin 0;

					.gpInfiniteLoadMoreActionIcon {

						width: $galleryLoadingIconWidth/2;
						height: $galleryLoadingIconWidth/2;

						svg {
							fill: $galleryPrimary;
						}
					}

					.gpInfiniteLoadMoreActionButton {
						background: $galleryPrimary;
						color: $galleryPrimaryText;
						border: none;
						padding: $galleryMargin 2*$galleryMargin;
						text-transform: uppercase;
						font-size: 14px;
						font-weight: 700;
						cursor: pointer;
						transition: background $hoverTransition;

						&:hover {
							background: $galleryPrimaryHover;
						}
					}
				}
			}
		} 

		.gpControls {

			margin: $galleryPaginationHeight 0 0 0;
			
			.lhsControls.disabled {

				.backOne {

					cursor: default;

					&:hover {
						.leftArrow {
							border-right-color: $galleryPaginationDisabledColor;
						}
					}

					.leftArrow {
						border-right-color: $galleryPaginationDisabledColor;
					}
				}

				.backAll {
					border-color: $galleryPaginationDisabledColor;
					cursor: default;

					&:hover {
						border-color: $galleryPaginationDisabledColor;

						.leftArrow {
							border-right-color: $galleryPaginationDisabledColor;
						}
					}

					.leftArrow {
						border-right-color: $galleryPaginationDisabledColor;
					}
				}
			}

			.lhsControls {

				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 3*$galleryPaginationHeight;
				height: $galleryPaginationHeight;

				.leftArrow {
					position: absolute;
					top: 0;
					left: -1*$galleryPaginationOffset;
					width: 0;
					height: 0;

					border-top: 0.5*$galleryPaginationHeight solid transparent;
					border-bottom: 0.5*$galleryPaginationHeight solid transparent;
					border-right: 0.75*$galleryPaginationHeight solid $galleryColor;
					transition: border $hoverTransition;
				}

				.backAll {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: $galleryPaginationHeight - $galleryPaginationOffset;
					border-left: $galleryPaginationOffset solid $galleryColor;
					transition: border $hoverTransition;

					&:hover {
						border-color: $galleryColorHover;

						.leftArrow {
							border-right-color: $galleryColorHover;
						}
					}
				}

				.backOne {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					left: $galleryPaginationHeight + 2*$galleryPaginationOffset;
					width: $galleryPaginationHeight;
					transition: border $hoverTransition;

					&:hover {
						.leftArrow {
							border-right-color: $galleryColorHover;
						}
					}
				}
			}

			.rhsControls.disabled {

				.forwardOne {
					
					cursor: default;

					&:hover {
						.rightArrow {
							border-left-color: $galleryPaginationDisabledColor;
						}
					}

					.rightArrow {
						border-left-color: $galleryPaginationDisabledColor;
					}
				}

				.forwardAll {
					border-color: $galleryPaginationDisabledColor;
					cursor: default;

					&:hover {
						border-color: $galleryPaginationDisabledColor;

						.rightArrow {
							border-left-color: $galleryPaginationDisabledColor;
						}
					}

					.rightArrow {
						border-left-color: $galleryPaginationDisabledColor;
					}
				}
			}

			.rhsControls {

				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 3*$galleryPaginationHeight;
				height: $galleryPaginationHeight;

				.rightArrow {
					position: absolute;
					top: 0;
					right: -1*$galleryPaginationOffset;
					width: 0;
					height: 0;

					border-top: 0.5*$galleryPaginationHeight solid transparent;
					border-bottom: 0.5*$galleryPaginationHeight solid transparent;
					border-left: 0.75*$galleryPaginationHeight solid $galleryColor;
					transition: border $hoverTransition;

					&:hover {
						border-left-color: $galleryColorHover;
					}
				}

				.forwardAll {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: $galleryPaginationHeight - $galleryPaginationOffset;
					border-right: $galleryPaginationOffset solid $galleryColor;
					transition: border $hoverTransition;

					&:hover {
						border-color: $galleryColorHover;

						.rightArrow {
							border-left-color: $galleryColorHover;
						}
					}
				}

				.forwardOne {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					right: $galleryPaginationHeight + 2*$galleryPaginationOffset;
					width: $galleryPaginationHeight;
					transition: border $hoverTransition;

					&:hover {
						border-color: $galleryColorHover;

						.rightArrow {
							border-left-color: $galleryColorHover;
						}
					}
				}
			}

			.currentPage.loadingPage {
				font-weight: 300;
			}

			.currentPage {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin: 0 2*$galleryPaginationOffset;
				font-weight: 400;

				.pageNumber {
					font-weight: 700;
				}
			}
		}

		.gpSize {

			margin: $galleryPaginationHeight 0 0 0;

			.gpSizeCopy {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				font-weight: 300;
				margin: 0 $galleryPaginationOffset 0 0;
			}

			.gpSizeDropdown {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				border-bottom: 1px solid $galleryColor;
				cursor: pointer;

				select {
					border: none;
					background: none;
				}
			}
		}
	}
}





//////////////////////
// ThumbnailGallery //
//////////////////////

// Thumbnails in gallery intentionally have no horizontal margin;
// Margins, spacing, and hover should be handled in their individual components
// $galleryThumbnailMargin is the basic spacing element

// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$galleryThumbnailMargin: 10px;
$galleryThumbnailDefaultWidth: 200px;
$galleryThumbnailDefaultMaxWidth: 300px;


// Assign theme color overrides here
.Gallery.theme .ThumbnailGallery {

}


// Component-specific overrides here
.Gallery.productSearch .ThumbnailGallery {

}


.Gallery .ThumbnailGallery {
	
	.tile1.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/1);
		}
	}

	.tile2.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/2);
		}
	}

	.tile3.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/3);
		}
	}

	.tile4.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/4);
		}
	}

	.tile5.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/5);
		}
	}

	.tile6.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: calc(100%/6);
		}
	}

	.tileMax.thumbnailGalleryWrapper {
		.thumbnailElementWrapper {
			width: $galleryThumbnailDefaultMaxWidth;
		}
	}

	.thumbnailGalleryWrapper {

		margin: $galleryThumbnailMargin 0;

		.thumbnailElementWrapper {
			display: inline-block;
			vertical-align: top;
			width: $galleryThumbnailDefaultWidth;
			margin: 0 0 0 0;
			transition: width $breakTransition;
		}
	}
}









//////////////////
// TcgThumbnail //
//////////////////


// Titan colors
$tcgThumbSelectButtonColor: $TITAN_PRIMARY_COLOR;
$tcgThumbBackground: $TITAN_BACKGROUND;
$tcgThumbBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
$tcgThumbThemeSelectButtonColor: $THEME_PRIMARY_COLOR;
$tcgThumbThemeBackground: $THEME_BACKGROUND;
$tcgThumbThemeBackgroundHover: $THEME_PRIMARY_ULTRALIGHT;

// General variables
// None yet

// Component variables
$tcgThumbTitleLineHeight: 16px;
$tcgThumbSetLineHeight: 16px;
$tcgThumbSelectButtonHeight: 22px;


// Assign theme color overrides here
.Gallery.theme .ThumbnailGallery .TcgThumbnail {

	background: $tcgThumbThemeBackground;

	&:hover {

		background: $tcgThumbThemeBackgroundHover;

		.mtActionWrapper {
			.mtActionButton {
				color: $tcgThumbThemeBackground;
				background: $tcgThumbThemeSelectButtonColor;
			}
		}
	}

	.mtActionWrapper {

		.mtActionButton {
			color: $tcgThumbThemeSelectButtonColor;
			border: 2px solid $tcgThumbThemeSelectButtonColor;
			background: $tcgThumbThemeBackground;
		}
	}
}


// Component-specific overrides here
.Gallery.productSearch .ThumbnailGallery .TcgThumbnail {

}


.Gallery .ThumbnailGallery .TcgThumbnail.unloaded {
	opacity: 0;
}


.Gallery .ThumbnailGallery .TcgThumbnail {

	cursor: pointer;
	opacity: 1;
	background: $tcgThumbBackground;
	padding: $galleryThumbnailMargin/2 $galleryThumbnailMargin/2 $galleryThumbnailMargin $galleryThumbnailMargin/2;
	transition: opacity $toggleTransition, background $hoverTransition;

	&:hover {

		background: $tcgThumbBackgroundHover;

		.mtActionWrapper {
			.mtActionButton {
				color: $tcgThumbBackground;
				background: $tcgThumbSelectButtonColor;
			}
		}
	}
	
	// Because it wraps a MagicImage directly, equal widths guarantee equal heights
	.mtImageWrapper {

		text-align: center;

		img {
			width: 100%;
			max-height: 100%;
		}
	}

	.mtTitleSection {

		width: 100%;
		height: 2*$tcgThumbTitleLineHeight + 1*$tcgThumbSetLineHeight;

		.mtTitleWrapper {
			overflow: hidden;
			max-height: 2*$tcgThumbTitleLineHeight;
			line-height: $tcgThumbTitleLineHeight;

			font-size: 14px;
			font-weight: 700;

			.titlePrimary {
				display: inline;
				font-size: 13px;
				font-weight: 700;
				margin: 0 $galleryThumbnailMargin/2 0 0;
			}

			.titleSecondary {
				display: inline;
				font-size: 12px;
				font-weight: 400;
			}
		}

		.mtSetWrapper {
			overflow: hidden;
			height: 1*$tcgThumbSetLineHeight;
			line-height: $tcgThumbSetLineHeight;

			font-size: 12px;
			font-weight: 300;
			// text-transform: uppercase;
		}
	}

	.mtActionWrapper {

		margin: $galleryThumbnailMargin/4 0 0 0;
		text-align: center;

		.mtActionButton {
			cursor: pointer;
			height: $tcgThumbSelectButtonHeight;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			color: $tcgThumbSelectButtonColor;
			border: 2px solid $tcgThumbSelectButtonColor;
			padding: 0 $galleryThumbnailMargin;
			background: $tcgThumbBackground;
			transition: color $hoverTransition, background $hoverTransition;
		}
	}
}


//////////////////////
// GeneralThumbnail //
//////////////////////


// Titan colors
// None yet

// Theme colors
$generalThumbThemeBuylistBackground: $THEME_PRIMARY_COLOR;
$generalThumbThemeBuylistColor: $THEME_PRIMARY_COLOR_TEXT;
$generalThumbThemeSelectButtonColor: $THEME_PRIMARY_COLOR;
$generalThumbThemeBackground: $THEME_BACKGROUND;
$generalThumbThemeBackgroundHover: $THEME_PRIMARY_ULTRALIGHT;

// General variables
// None yet

// Component variables
$generalThumbImageHeight: 240px;
$generalThumbTitleLineHeight: 16px;
$generalThumbProductLineHeight: 16px;
$generalThumbSetLineHeight: 16px;
$generalBuylistHeight: 16px;
$generalThumbPriceHeight: 20px;


// No theme color overrides since GeneralThumbnail doesn't have an admin component
.Gallery.theme .ThumbnailGallery .GeneralThumbnail {

}


// Component-specific overrides here
.Gallery.productSearch .ThumbnailGallery .GeneralThumbnail {

}


.Gallery .ThumbnailGallery .GeneralThumbnail.unloaded {
	opacity: 0;
}


.Gallery .ThumbnailGallery .GeneralThumbnail {

	display: block;
	cursor: pointer;
	opacity: 1;
	background: $generalThumbThemeBackground;
	margin: $galleryThumbnailMargin/2 $galleryThumbnailMargin/2 $galleryThumbnailMargin/2 $galleryThumbnailMargin/2;
	padding: $galleryThumbnailMargin 0;
	transition: opacity $toggleTransition, background $hoverTransition;

	&:hover {
		// background: $generalThumbThemeBackgroundHover;
	}
	
	
	.gtImageWrapper {

		margin: 0;
		text-align: center;
		height: $generalThumbImageHeight;

		img {
			max-width: 100%;
			max-height: 100%;
		}

		.gtImageMagicWrapper {
			margin: 0 auto;
			width: $MAGIC_CARD_AR * $generalThumbImageHeight;
			height: $generalThumbImageHeight;
		}
	}

	.gtTitleSection {

		margin: $galleryThumbnailMargin 0 0 0;
		padding: 0 $galleryThumbnailMargin;
		height: 2*$generalThumbTitleLineHeight + $generalThumbSetLineHeight + $generalThumbProductLineHeight + $galleryThumbnailMargin;

		.gtTitleWrapper {
			overflow: hidden;
			max-height: 2*$generalThumbTitleLineHeight;
			line-height: $generalThumbTitleLineHeight;

			font-size: 14px;
			font-weight: 700;
		}

		.gtProductLineWrapper {
			margin: $galleryThumbnailMargin/2 0 0 0;
			height: $generalThumbProductLineHeight;
			font-size: 12px;
		}

		.gtSetWrapper {
			overflow: hidden;
			height: $generalThumbSetLineHeight;
			line-height: $generalThumbSetLineHeight;

			font-size: 12px;
			font-weight: 300;
		}
	}

	.gtTagWrapper {
		height: $generalBuylistHeight;
		margin: 0 0 0.5*$galleryThumbnailMargin 0;
		padding: 0 $galleryThumbnailMargin;

		.tagWrapper {
			display: inline-block;
			vertical-align: top;
			height: $generalBuylistHeight;
			background: $generalThumbThemeBuylistBackground;
			color: $generalThumbThemeBuylistColor;
			border-radius: (1/2)*$generalBuylistHeight;

			.tagLabel {
				padding: (1/8)*$generalBuylistHeight (1/2)*$generalBuylistHeight;
				font-size: (3/4)*$generalBuylistHeight;
				line-height: (3/4)*$generalBuylistHeight;
				font-weight: 400;
			}
		}
	}

	.gtInventorySection {

		position: relative;
		margin: 0 $galleryThumbnailMargin;
		height: $generalThumbPriceHeight;

		.gtPriceWrapper {

			height: $generalThumbPriceHeight;

			.gtPriceElement {

				.priceFormatSymbol {
					vertical-align: top;
					font-size: 16px;
					font-weight: 300;
				}

				.priceFormatDollar {
					vertical-align: top;
					font-size: 20px;
					line-height: 20px;
				}

				.priceFormatDecimal.priceFormatLessThanOne {
					font-size: 20px;
					line-height: 20px;
					display: inline-block;
				}

				.priceFormatDecimal {
					display: none;
				}

				.priceFormatCents.priceFormatLessThanOne {
					font-size: 20px;
					line-height: 20px;
					font-weight: 400;
					margin-top: 0;
				}

				.priceFormatCents {
					vertical-align: top;
					font-size: 10px;
					font-weight: 300;
					margin-top: 10px;
				}
			}
		}

		.gtAddWrapper {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: $generalThumbPriceHeight;
		}

		.gtQtyWrapper {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: $generalThumbPriceHeight;

			.gtQty {

				font-size: 15px;
				font-weight: 300;
				text-align: right;

				.gtQtyLabel {
					display: inline-block;
					vertical-align: baseline;
					width: auto;
					height: $generalThumbPriceHeight;
					line-height: $generalThumbPriceHeight;
					margin: 0 0.5*$galleryMargin 0 0;

					&:after {
						content: ':';
					}
				}

				.gtQtyValue {
					display: inline-block;
					vertical-align: baseline;
					width: auto;
					height: $generalThumbPriceHeight;
					line-height: $generalThumbPriceHeight;
				}
			}
		}
	}
}


/////////////////////////
// GeneralThumbnailAdd //
/////////////////////////


// Titan colors
// None yet

// Theme colors
$generalThumbnailThemeDrawerBorderColor: $THEME_RULE_COLOR;
$generalThumbnailThemeDrawerHoverColor: $THEME_PRIMARY_ULTRALIGHT;
$generalThumbnailThemeAddDisabledColor: $disabledColor;
$generalThumbnailThemeAddDisabledBackground: $disabledBackground;

// General variables
$generalThumbnailAddIconScale: 0.65;
$generalThumbnailAddDrawerHeight: 360px;
$generalThumbnailAddAnimationDistance: 15px;
$generalThumbnailAddAnimationDuration: 2500ms;
$generalThumbnailAddNoticeHeight: 13px;

// Animation definitions

@keyframes gallery-added-notice {
  0% { 
  	bottom: 100%;
  	opacity: 1.0;
  }
  50% {
  	bottom: 100%;
  	opacity: 1.0;
  }
  100% { 
  	bottom: calc(100% + #{$generalThumbnailAddAnimationDistance});
  	opacity: 0.0;
  }
}


.GeneralThumbnailAdd {

	position: relative;
	text-align: right;

	.animate.addSuccess {
		display: block;
		animation-name: gallery-added-notice;
		animation-duration: $generalThumbnailAddAnimationDuration;
	  animation-iteration-count: 1;
	}

	.addSuccess {

		display: none;
		position: absolute;
		right: -0.5*$galleryThumbnailMargin;
		bottom: 100%;
		width: auto;
		height: $generalThumbnailAddNoticeHeight + $galleryThumbnailMargin;
		cursor: default;
		border-radius: 0.5*($generalThumbnailAddNoticeHeight + $galleryThumbnailMargin);
		color: $galleryThemePrimary;
		z-index: 5;
		opacity: 1;
		background: $generalThumbThemeBackground;

		.addSuccessLiner {

			padding: 0.5*$galleryThumbnailMargin $galleryThumbnailMargin;
			height: $generalThumbnailAddNoticeHeight;

			.successIconWrapper {

				display: inline-block;
				vertical-align: top;
				width: $generalThumbnailAddNoticeHeight;
				height: $generalThumbnailAddNoticeHeight;

				.successIcon {
					width: $generalThumbnailAddNoticeHeight;
					height: $generalThumbnailAddNoticeHeight;

					svg {
						fill: $galleryThemePrimary;
					}
				}
			}

			.successValueWrapper {
				display: inline-block;
				vertical-align: top;
				width: auto;
				margin: 0 0 0 0.5*$galleryThumbnailMargin;
				font-size: $generalThumbnailAddNoticeHeight - 1px;
				font-weight: 700;
			}
		}
	}

	.disabled.addButtonLiner {
		cursor: default;

		background: $generalThumbThemeBackground;

		.addButtonIconWrapper {
			.addIcon {
				svg {
					fill: $generalThumbnailThemeAddDisabledColor;
				}
			}
		}

		&:hover {
			background: $generalThumbThemeBackground;

			.addButtonIconWrapper {
				.addIcon {
					svg {
						fill: $generalThumbnailThemeAddDisabledColor;
					}
				}
			}
		}
	}

	.loading.addButtonLiner {

		&:hover {
			background: $generalThumbThemeBackground;

			.addButtonIconWrapper {
				.addIcon {
					svg {
						fill: $galleryThemePrimary;
					}
				}
			}
		}
	}

	.addButtonLiner {

		position: relative;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		background: $generalThumbThemeBackground;
		padding: 0.5*(1 - $generalThumbnailAddIconScale)*$generalThumbPriceHeight 0.5*$galleryThumbnailMargin;
		text-align: center;
		width: auto;
		height: $generalThumbnailAddIconScale * $generalThumbPriceHeight;
		transition: background $hoverTransition;

		&:hover {
			background: $galleryThemePrimary;

			.addButtonIconWrapper {

				.addIcon {

					svg {
						fill: $generalThumbThemeBuylistColor;
					}
				}
			}
		}

		.addButtonIconWrapper {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: auto;
			height: $generalThumbnailAddIconScale * $generalThumbPriceHeight;

			.addIcon {

				position: relative;
				width: $generalThumbnailAddIconScale * $generalThumbPriceHeight;
				height: $generalThumbnailAddIconScale * $generalThumbPriceHeight;

				svg {
					fill: $galleryThemePrimary;
				}
			}
		}
	}

	.open.addDrawerWrapper {
		max-height: $generalThumbnailAddDrawerHeight;
		transition: max-height $slideTransition;
	}

	.addDrawerWrapper {

		overflow: hidden;
		position: absolute;
		top: 100%;
		right: 0;
		width: 100%;
		height: auto;
		max-height: 0;
		z-index: 1;
		transition: max-height $slideFastTransition;

		.addDrawerLiner {

			background: $generalThumbThemeBackground;
			border-left: 1px solid $generalThumbnailThemeDrawerBorderColor;
			border-right: 1px solid $generalThumbnailThemeDrawerBorderColor;
			border-bottom: 1px solid $generalThumbnailThemeDrawerBorderColor;

			.disabled.addDrawerLineWrapper {
				color: $generalThumbnailThemeAddDisabledColor;
				cursor: default;

				.hovered.addDrawerLine {
					background: $generalThumbnailThemeAddDisabledBackground;
				}
			}

			.addDrawerLineWrapper {

				.hovered.addDrawerLine {
					background: $generalThumbnailThemeDrawerHoverColor;
				}

				.addDrawerLine {
					position: relative;
					pointer-events: none;
					white-space: nowrap;
					padding: 0.5*$galleryThumbnailMargin $galleryThumbnailMargin;
					font-size: 13px;
					background: $generalThumbThemeBackground;
					transition: background $hoverTransition;

					.addDrawerLabel {
						font-weight: 400;
						text-align: left;
					}

					.addDrawerPrice {
						position: absolute;
						top: 0.5*$galleryThumbnailMargin;
						right: $galleryThumbnailMargin;
						width: auto;
						height: auto;
						font-weight: 300;
					}
				}
			}
		}
	}
}



























