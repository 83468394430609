

@use '../../../../style/import' as *;


/////////////////
// BulkUploads //
/////////////////


// Titan colors
$bulkUploadAccentColor: $TITAN_PRIMARY_COLOR;
$bulkUploadAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$bulkUploadAccentColorText: $TITAN_PRIMARY_COLOR_TEXT;
$bulkUploadBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$bulkUploadColor: $TITAN_TEXT_COLOR;
$bulkUploadColorHover: $TITAN_TEXT_COLOR_HOVER;
$bulkUploadErrorColor: $errorRed;


// Theme colors
// None yet

// General variables
$bulkUploadMargin: 10px;



.BulkUploads {

	.adminBody {

		.adminSection {

			.buProgressWrapper {
				margin: 0 0 2*$bulkUploadMargin 0;
			}
			
			.buComponentWrapper {

			}
		}
	}
}


//////////////////////
// BulkUploadCreate //
//////////////////////


// Titan colors
// None yet

// Theme colors
$bulkUploadCreateLoadingColor: $TITAN_PRIMARY_COLOR;
$bulkUploadCreateLoadingScreenBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;

// General variables
$bulkUploadCreateLoadingWidth: 60px;
$bulkUploadCreateLoadingScreenOpacity: 0.8;



.BulkUploadCreate {

	.adminBody {

		.adminSection {

			.uploadLoading {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background: rgba($bulkUploadCreateLoadingScreenBackground, $bulkUploadCreateLoadingScreenOpacity);

				.uploadLoadingWrapper {
					position: absolute;
					top: calc(50% - #{$bulkUploadCreateLoadingWidth});
					left: 0;
					width: 100%;
					height: auto;

					.iconWrapper {
						width: $bulkUploadCreateLoadingWidth;
						height: $bulkUploadCreateLoadingWidth;
						margin: 0 auto;

						svg {
							fill: $bulkUploadCreateLoadingColor;
						}
					}

					.labelWrapper {
						font-size: 28px;
						font-weight: 400;
						text-align: center;
						margin: $bulkUploadMargin 0 0 0;
					}
				}
			}

			.buCreateWrapper {
				margin: 2*$bulkUploadMargin 0 0 0;
			}
			
			.buComponentWrapper {

			}
		}
	}
}


////////////////////
// BulkUploadView //
////////////////////


// Titan colors
// None yet


// Theme colors
// None yet

// General variables
// None yet



.BulkUploadView {

	.adminBody {

		.adminSection {

			.adminFormError {
				text-align: center;
				margin: $bulkUploadMargin 0;
				font-weight: 700;
				color: $bulkUploadErrorColor;
			}

			.buProgressWrapper {
				margin: 0 0 2*$bulkUploadMargin 0;
			}
			
			.buComponentWrapper {

			}
		}
	}
}


/////////////////////
// BulkUploadInput //
/////////////////////


// Titan colors
$bulkUploadFileDropzoneAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$bulkUploadFileDropzoneBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$bulkUploadFileDropzoneBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$bulkUploadFileDropzoneBorder: $TITAN_ADMIN_PAGE_BACKGROUND;
$bulkUploadFileDropzoneBorderHover: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$bulkUploadFileDropzoneCloseWidth: 20px;
$bulkUploadFileDropzoneIconWidth: 60px;
$bulkUploadFileDropzoneFileWidth: 360px;
$bulkUploadGuidanceHeight: 20px;
$bulkUploadGuidanceBodyHeight: 300px;
$bulkUploadGuidanceTableNameWidth: 120px;
$bulkUploadGuidanceTableDescription: 120px;
$bulkUploadTemplateHeight: 14px;


.BulkUploadInput {

	.bulkUploadInputForm {

		.uploadGuidanceWrapper {

			margin: -1*$bulkUploadMargin 0 0 0;

			.guidanceHeader {

				.guidanceHeaderLiner {

					display: inline-block;
					cursor: pointer;

					.ghIcon {
						display: inline-block;
						vertical-align: top;
						width: 0.6*$bulkUploadGuidanceHeight;
						height: 0.6*$bulkUploadGuidanceHeight;
						padding: 0.2*$bulkUploadGuidanceHeight 0.4*$bulkUploadGuidanceHeight 0.2*$bulkUploadGuidanceHeight 0;

						.ghIconWrapper.open {
							transform: rotate(0deg);
							transition: transform $slideTransition;
						}

						.ghIconWrapper {
							width: 0.6*$bulkUploadGuidanceHeight;
							height: 0.6*$bulkUploadGuidanceHeight;
							transform: rotate(-90deg);
							transition: transform $slideFastTransition;
						}
					}

					.ghLabel {
						display: inline-block;
						vertical-align: top;
						height: $bulkUploadGuidanceHeight;
						line-height: $bulkUploadGuidanceHeight;
						font-size: 16px;
						font-weight: 400;
					}
				}
			}

			.guidanceBody.open {
				max-height: $bulkUploadGuidanceBodyHeight;
				transition: max-height $slideTransition;
			}

			.guidanceBody {

				overflow: hidden;
				height: auto;
				max-height: 0;
				transition: max-height $slideFastTransition;

				.guidanceBodyLiner {

					.guidanceRowExplanation {

						.schemaTable {

							margin: $bulkUploadMargin 0 0 0;

							.schemaRow {

								font-size: 14px;
								margin: 0 0 $bulkUploadMargin/4 0;

								.headerCell {
									font-size: 16px;
									font-weight: 700;
									margin: 0 0 $bulkUploadMargin/2 0;

									.requiredWrapper {

										font-size: 14px;
										font-weight: 400;
										padding: 0 0 0 $bulkUploadMargin/2;

										&:before {
											content: '*';
											color: $bulkUploadAccentColor;
											font-weight: 700;
										}
									}
								}

								.cellKey {

									display: inline-block;
									vertical-align: top;

									.required.cellKeyWrapper {

										&:before {
											content: '*';
											color: $bulkUploadAccentColor;
											font-weight: 700;
										}
									}

									.cellKeyWrapper {
										display: inline-block;		
									}
								}

								.cellDesc {
									display: inline-block;
									vertical-align: top;
									margin: 0 0 0 $bulkUploadMargin;
									font-weight: 300;
								}
							}
						}
					}

					.templateWrapper {

						display: inline-block;
						cursor: pointer;
						color: $bulkUploadAccentColor;
						margin: $bulkUploadMargin/2 0 0 0;
						transition: color $hoverTransition;

						&:hover {
							color: $bulkUploadAccentColorHover;

							.templateIcon {

								svg {
									fill: $bulkUploadAccentColorHover;
								}
							}
						}

						.templateLabel {
							display: inline-block;
							vertical-align: top;
							margin: 0 $bulkUploadMargin/2 0 0;
							height: $bulkUploadTemplateHeight;
							line-height: $bulkUploadTemplateHeight;
							font-size: $bulkUploadTemplateHeight;
							font-weight: 700;
						}

						.templateIcon {
							display: inline-block;
							vertical-align: top;
							margin: 0;
							height: $bulkUploadTemplateHeight;
							width: $bulkUploadTemplateHeight;

							svg {
								fill: $bulkUploadAccentColor;
							}
						}
					}
				}
			}
		}

		.firstConfigElement.adminFieldWrapper {
			margin-top: $bulkUploadMargin;
		}

		.fileDropzoneWrapper {

			margin: 0 0 $bulkUploadMargin 0;

			.fileError {

			}

			.dropWrapper.hoverFile {
				background: $bulkUploadFileDropzoneBackgroundHover;
				border: 1px solid $bulkUploadFileDropzoneBorderHover;
			}

			.dropWrapper {
				margin: 2*$bulkUploadMargin 0 0 0;
				background: $bulkUploadFileDropzoneBackground;
				border: 1px solid $bulkUploadFileDropzoneBorder;
				padding: 3*$bulkUploadMargin $bulkUploadMargin;
				text-align: center;
				transition: background $hoverTransition, border $hoverTransition;

				.dropPrompt {
					font-size: 28px;
					font-weight: 300;
					margin: 0 0 2*$bulkUploadMargin 0;
				}

				.dropFileDisplay {

					position: relative;
					text-align: center;
					background: $bulkUploadBackground;
					margin: 0 auto;
					padding: $bulkUploadMargin;
					width: $bulkUploadFileDropzoneFileWidth;

					.dropFileIconWrapper {
						display: inline-block;
						vertical-align: top;
						width: $bulkUploadFileDropzoneIconWidth;
						margin: 0 $bulkUploadMargin 0 0;
					}

					.dropFileDetailsWrapper {

						display: inline-block;
						vertical-align: top;
						width: $bulkUploadFileDropzoneFileWidth - $bulkUploadFileDropzoneIconWidth - $bulkUploadMargin;
						text-align: left;

						.nameLabel {
							font-size: 24px;
							font-weight: 400;
						}

						.metaLabel {
							font-size: 16px;
							font-weight: 300;
						}

						.removeLabel {
							display: inline-block;
							vertical-align: top;
							font-size: 12px;
							font-weight: 700;
							text-transform: uppercase;
							margin: $bulkUploadMargin/2 0 0 0;
							cursor: pointer;
							color: $bulkUploadAccentColor;
							transition: color $hoverTransition;

							&:hover {
								color: $bulkUploadAccentColorHover;
							}
						}
					}

					.dropFileCloseWrapper {
						position: absolute;
						top: -0.3*$bulkUploadFileDropzoneCloseWidth;
						right: -0.3*$bulkUploadFileDropzoneCloseWidth;
						width: $bulkUploadFileDropzoneCloseWidth;
						height: $bulkUploadFileDropzoneCloseWidth;

						cursor: pointer;
						color: $bulkUploadAccentColor;
						background: $bulkUploadBackground;
						border: 1px solid $bulkUploadColor;
						border-radius: 50%;
						font-size: $bulkUploadFileDropzoneCloseWidth;
						font-weight: 700;
						transition: color $hoverTransition;

						&:hover {
							color: $bulkUploadAccentColorHover;
						}
					}
				}

				.dropError {
					text-align: center;
					color: $errorRed;
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 2*$bulkUploadMargin 0;
				}

				.dropButton {
					display: inline-block;
					color: $bulkUploadFileDropzoneAccentText;
					background: $bulkUploadAccentColor;
					vertical-align: top;
					cursor: pointer;
					border: none;
					margin: 0 0 0 $bulkUploadMargin;
					padding: $bulkUploadMargin 4*$bulkUploadMargin;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

					@media #{$break1024} {
						padding: 2*$bulkUploadMargin/2 3*$bulkUploadMargin;
					}

					&:hover {
						background: $bulkUploadAccentColorHover;
					}
				}
			}
		}
	}
}


//////////////////////////
// BulkUploadProcessing //
//////////////////////////


// Titan colors
$bulkUploadProcessingBarBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$bulkUploadProcessingBarFillBackground: $TITAN_PRIMARY_ULTRALIGHT;
$bulkUploadProcessingIconInactive: $TITAN_RULE_COLOR;
$bulkUploadProcessingMenuBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$bulkUploadProcessingMenuBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$bulkUploadProcessingBarHeight: 20px;
$bulkUploadProcessingIconWidth: 28px;
$bulkUploadProcessingStatusLineHeight: 25px;
$bulkUploadProcessingStatusMenuWidth: 220px;
$bulkUploadProcessingStatusMenuHeight: 150px;


.BulkUploadProcessing {

	margin: 2*$bulkUploadMargin 0 0 0;

	.bupLiner {

		.statusLabel.uploading {
			
			.statusValue {

				&:after {
					content: ':';
				}
			}
		}

		.statusLabel {
			position: relative;
			min-height: $bulkUploadProcessingStatusLineHeight;
			line-height: $bulkUploadProcessingStatusLineHeight;

			.statusValue {
				display: inline-block;
				vertical-align: top;
				font-size: 24px;
				font-weight: 300;
			}

			.statusPercent {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $bulkUploadMargin;
				font-weight: 400;
				font-size: 24px;
			}

			.statusMenuIconWrapper {
				position: absolute;
				top: 0;
				right: 0;
				width: $bulkUploadProcessingStatusLineHeight;
				height: $bulkUploadProcessingStatusLineHeight;
				cursor: pointer;
				transition: background $hoverTransition;

				&:hover {
					background: $bulkUploadProcessingBarBackground;
				}

				.statusMenuIcon {
					width: $bulkUploadProcessingStatusLineHeight;
					height: $bulkUploadProcessingStatusLineHeight;
				}
			}

			.open.statusMenuWrapper {
				max-height: $bulkUploadProcessingStatusMenuHeight;
				transition: max-height $slideTransition;
			}

			.statusMenuWrapper {

				position: absolute;
				overflow: hidden;
				top: 100%;
				right: 0;
				height: auto;
				max-height: 0;
				width: auto;
				z-index: 1;
				width: $bulkUploadProcessingStatusMenuWidth;
				background: $bulkUploadProcessingMenuBackground;
				transition: max-height $slideFastTransition;

				.statusMenu {

					border-left: 1px solid $bulkUploadProcessingIconInactive;
					border-right: 1px solid $bulkUploadProcessingIconInactive;
					border-bottom: 1px solid $bulkUploadProcessingIconInactive;

					.statusMenuOption {
						padding: $bulkUploadMargin/2 $bulkUploadMargin $bulkUploadMargin/2 $bulkUploadMargin/2;
						font-size: 14px;
						cursor: pointer;
						background: $bulkUploadProcessingMenuBackground;
						text-align: right;
						transition: background $hoverTransition;

						&:hover {
							background: $bulkUploadProcessingMenuBackgroundHover;
						}
					}
				}
			}
		}

		.statusBarWrapper {

			position: relative;
			margin: $bulkUploadMargin 0 0 0;
			height: $bulkUploadProcessingIconWidth;

			.sbBody {

				position: absolute;
				overflow: hidden;
				top: ($bulkUploadProcessingIconWidth - $bulkUploadProcessingBarHeight) / 2;
				left: 0;
				width: 100%;
				height: $bulkUploadProcessingBarHeight;
				background: $bulkUploadProcessingBarBackground;
				border-radius: $bulkUploadProcessingBarHeight/2;

				.sbBodyFill {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 0%;
					background: $bulkUploadProcessingBarFillBackground;
					transition: width $slideTransition;
				}
			}

			.sbIconWrapper {

				position: absolute;
				top: 0;
				left: 0;
				width: $bulkUploadProcessingIconWidth;
				height: $bulkUploadProcessingIconWidth;
				transition: left $slideTransition;

				svg {
					fill: $bulkUploadAccentColor;
				}
			}
		}
	}
}


///////////////////////
// BulkUploadResults //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$bulkUploadResultsItemWidth: 100px;
$bulkUploadResultsMaxWidth: 600px;
$bulkUploadResultsCompleteHeight: 240px;


.BulkUploadResults {

	.burLiner {

		.burSummaryWrapper {

			margin: 0 auto;

			.burSummaryLoading {

				padding: 2*$bulkUploadMargin 0;

				.burSummaryLoadingValue {
					font-size: 24px;
					font-weight: 300;
					text-align: center;
				}
			}

			.burSummary {

				margin: 0 0 2*$bulkUploadMargin 0;
				text-align: center;

				.summaryTitle {
					margin: 2*$bulkUploadMargin 0 2*$bulkUploadMargin 0;
					font-weight: 700;
					font-size: 20px;
					font-family: $fontLato;
				}

				.summaryItem {

					display: inline-block;
					vertical-align: top;
					width: calc(35% - #{$bulkUploadMargin});
					margin: 0 $bulkUploadMargin 0 0;
					font-size: 20px;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition, margin $breakTransition;

					@media #{$break1024} {
						width: calc(40% - #{$bulkUploadMargin});
					}

					@media #{$break900} {
						display: block;
						margin: 0 auto;
						width: 100%;
						font-size: 18px;
					}

					.summaryItemValue {
						display: inline-block;
						vertical-align: top;
						width: $bulkUploadResultsItemWidth;
						font-weight: 400;
						text-align: right;
						transition: width $breakTransition;

						@media #{$break900} {
							width: calc(50% - #{$bulkUploadMargin/2});
						}
					}

					.summaryItemLabel {
						display: inline-block;
						vertical-align: top;
						width: calc(100%  - #{$bulkUploadResultsItemWidth} - #{$bulkUploadMargin});
						font-weight: 300;
						margin: 0 0 0 $bulkUploadMargin;
						text-align: left;
						transition: width $breakTransition;

						@media #{$break900} {
							width: calc(50% - #{$bulkUploadMargin/2});
						}
					}
				}
			}
		}

		.open.burSuccessWrapper {
			max-height: $bulkUploadResultsCompleteHeight;
		}

		.burSuccessWrapper {
			overflow: hidden;
			height: auto;
			max-height: 0;
			transition: max-height $slideTransition;

			.burSuccess {

				width: 90%;
				margin: 0 auto;
				padding: $bulkUploadMargin 0 3*$bulkUploadMargin 0;
				text-align: center;

				.burSuccessTitle {
					font-size: 26px;
					font-weight: 700;
					font-family: $fontLato;
					margin: 0 0 $bulkUploadMargin 0;
				}

				.burSuccessActions {

					.burSuccessLink {
						display: inline-block;
						vertical-align: top;
						text-align: center;
						cursor: pointer;
						padding: $bulkUploadMargin 3*$bulkUploadMargin;
						font-size: 14px;
						font-weight: 700;
						border: none;
						color: $bulkUploadAccentColorText;
						background: $bulkUploadAccentColor;
						transition: background $hoverTransition;

						&:hover {
							background: $bulkUploadAccentColorHover;
						}
					}
				}
			}
		}

		.burDetailWrapper {
			
		}
	}
}


//////////////////////////
// BulkUploadResultList //
//////////////////////////


// Titan colors
$bulkUploadResultsListHeaderBackground: $TITAN_PRIMARY_ULTRALIGHT;
$bulkUploadResultsListInputBorder: $TITAN_RULE_COLOR;
$bulkUploadResultsListLoadingScreen: $TITAN_ADMIN_BLOCK_BACKGROUND;
$bulkUploadResultsListTagBackground: $TITAN_ADMIN_PAGE_BACKGROUND;

// Theme colors
// None yet

// General variables
$bulkUploadResultsListFontSize: 14px;
$bulkUploadResultsListFilterHeight: 20px;
$bulkUploadResultsListLoadingIconWidth: 60px;
$bulkUploadResultsListPageWidthPercent: 0.67;
$bulkUploadResultsListSearchWidth: 300px;
$bulkUploadResultsListTypeMinWidth: 100px;


.BulkUploadResultList {

	.burlLiner {

		.burlPaginationWrapper {
			font-size: $bulkUploadResultsListFontSize;

			.pageArrow {
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $bulkUploadMargin/2;
				width: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize;
				height: $bulkUploadResultsListFontSize;

				&:hover {

					.pageArrowElem.leftArrow {
						border-right: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid $bulkUploadColorHover;
					}

					.pageArrowElem {
						border-left: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid $bulkUploadColorHover;
					}
				}

				.pageArrowElem.noArrow {
					display: none;
				}

				.pageArrowElem.leftArrow {
					left: unset;
					right: 0;
					border-left: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid transparent;
					border-right: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid $bulkUploadColor;
				}

				.pageArrowElem {
					position: absolute;
					top: 2px;
					left: 0;
					width: 0;
					height: 0;

					cursor: pointer;
					border-top: 0.5*$bulkUploadResultsListFontSize solid transparent;
					border-bottom: 0.5*$bulkUploadResultsListFontSize solid transparent;
					border-left: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid $bulkUploadColor;
					border-right: $bulkUploadResultsListPageWidthPercent*$bulkUploadResultsListFontSize solid transparent;
					transition: border $hoverTransition;
				}
			}

			.pageStatus {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $bulkUploadMargin/2;
				height: $bulkUploadResultsListFontSize;
			}
		}

		.burlHeader {

			.appliedFilterRow {

				text-align: right;

				.afTagWrapper {

					.afTagLabel {
						display: inline-block;
						vertical-align: top;
					}

					.afTag {

						display: inline-block;
						vertical-align: top;
						background: $bulkUploadResultsListTagBackground;
						margin: 0 0 $bulkUploadMargin/2 $bulkUploadMargin/2;
						border-radius: $bulkUploadMargin/2;

						.afTagLiner {

							position: relative;
							padding: $bulkUploadMargin/3;
							font-size: 14px;

							.afTagValue {
								display: inline-block;
								vertical-align: top;
								line-height: 14px;
								font-weight: 300;
								padding: 0 $bulkUploadMargin/3 0 0;
								border-right: 1px solid $bulkUploadResultsListInputBorder;
							}

							.afTagAction {
								display: inline-block;
								vertical-align: top;
								line-height: 14px;
								font-weight: 400;
								cursor: pointer;
								padding: 0 0 0 $bulkUploadMargin/3;
								transition: font-weight $hoverTransition;

								&:hover {
									font-weight: 700;
								}
							}
						}
					}
				}
			}

			.filterRow {

				position: relative;
				margin: 0 0 $bulkUploadMargin/2 0;
				height: $bulkUploadResultsListFilterHeight;
				transition: height $breakTransition;

				@media #{$break768} {
					height: 2*$bulkUploadResultsListFilterHeight + $bulkUploadMargin/2;
				}

				.typeWrapper {

					margin: 0;
					transition: margin $breakTransition;

					@media #{$break768} {
						margin: 0 0 $bulkUploadMargin/2 0;
					}

					.filterLabel {
						display: inline-block;
						vertical-align: top;
						height: $bulkUploadResultsListFilterHeight;
						line-height: $bulkUploadResultsListFilterHeight;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;

						&:after {
							content: ':';
						}
					}

					.filterInputWrapper {
						display: inline-block;
						vertical-align: top;
						height: $bulkUploadResultsListFilterHeight;
						margin: 0 0 0 $bulkUploadMargin;
						min-width: $bulkUploadResultsListTypeMinWidth;

						.filterTypeDropdown.Dropdown {
							margin-top: ($bulkUploadResultsListFilterHeight - 2*$bulkUploadMargin)/2;
						}
					}
				}

				.searchWrapper {

					position: absolute;
					top: 0;
					right: 0;
					width: $bulkUploadResultsListSearchWidth;
					height: $bulkUploadResultsListFilterHeight;
					transition: width $breakTransition;

					@media #{$break768} {
						position: relative;
						top: unset;
						right: unset;
						width: 100%;
					}

					.searchInput {
						border-top: none;
						border-left: none;
						border-right: none;
						border-bottom: 1px solid $bulkUploadResultsListInputBorder;

						width: calc(100% - #{$bulkUploadResultsListFilterHeight} - #{$bulkUploadMargin});
						height: $bulkUploadResultsListFontSize - 1px;
						margin-right: $bulkUploadResultsListFilterHeight;
						font-weight: 300;
						padding-top: ($bulkUploadResultsListFilterHeight - $bulkUploadResultsListFontSize)/2;
						padding-bottom: ($bulkUploadResultsListFilterHeight - $bulkUploadResultsListFontSize)/2;
						padding-left: $bulkUploadMargin/2;
						padding-right: $bulkUploadMargin/2;
					}

					.searchIconWrapper {
						cursor: pointer;
						position: absolute;
						top: ($bulkUploadResultsListFilterHeight - $bulkUploadResultsListFontSize)/2;
						right: ($bulkUploadResultsListFilterHeight - $bulkUploadResultsListFontSize)/2;
						width: $bulkUploadResultsListFontSize;
						height: $bulkUploadResultsListFontSize;
					}
				}
			}

			.pageRow {
				
				position: relative;
				padding: $bulkUploadMargin/2;
				background: $bulkUploadResultsListHeaderBackground;

				.countWrapper {
					font-size: $bulkUploadResultsListFontSize;

					.countLoading {
						display: inline-block;
						vertical-align: top;
						width: $bulkUploadResultsListFontSize;
						height: $bulkUploadResultsListFontSize;
						margin: 0 $bulkUploadMargin/2 0 0;
					}

					.countValue {
						display: inline-block;
						vertical-align: top;
						height: $bulkUploadResultsListFontSize;
						line-height: $bulkUploadResultsListFontSize;
					}
				}

				.pageWrapper {
					position: absolute;
					top: $bulkUploadMargin/2;
					right: $bulkUploadMargin/2;
					height: calc(100% - #{$bulkUploadMargin});
					width: auto;
				}
			}
		}

		.burlResultsWrapper {

			.burlResultsLiner {

				position: relative;

				.loadingView {

					padding: $bulkUploadResultsListLoadingIconWidth 0;

					.loadingIconWrapper.emptyIcon {
						svg {
							fill: $bulkUploadColor;
						}
					}

					.loadingIconWrapper {
						width: $bulkUploadResultsListLoadingIconWidth;
						margin: 0 auto;

						svg {
							fill: $bulkUploadAccentColor;
						}
					}

					.loadingCopy {
						text-align: center;
						font-size: 24px;
						font-weight: 300;
						margin: $bulkUploadMargin 0 0 0;
					}
				}

				.noResultsView {
					padding: $bulkUploadResultsListLoadingIconWidth 0;
				}

				.resultsView {

					margin: 0 0 $bulkUploadMargin/2 0;

					.listItemWrapper {
						border-top: 1px solid $bulkUploadResultsListInputBorder;

						&:first-child {
							border-top: none;
						}
					}

					.rowProgressIndicator {

						margin: $bulkUploadResultsListLoadingIconWidth/2 0 $bulkUploadResultsListLoadingIconWidth 0;

						.rowIconWrapper {

							margin: 0 auto;
							width: $bulkUploadResultsListLoadingIconWidth/2;
							height: $bulkUploadResultsListLoadingIconWidth/2;

							svg {
								fill: $bulkUploadAccentColor;
							}
						}
					}
				}

				.burlResultsLoading {

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba($bulkUploadResultsListLoadingScreen, 0.75);
					
					.loadingIconWrapper {
						position: absolute;
						top: calc(50% - #{$bulkUploadResultsListLoadingIconWidth/2});
						left: calc(50% - #{$bulkUploadResultsListLoadingIconWidth/2});
						width: $bulkUploadResultsListLoadingIconWidth;
						height: $bulkUploadResultsListLoadingIconWidth;

						svg {
							fill: $bulkUploadAccentColor;
						}
					}
				}
			}
		}

		.burlPagination {
			
			.paginationWrapper {
				padding: 0 $bulkUploadMargin/2 0 0;
				text-align: center;

				.burlPaginationWrapper {

					.pageArrow.rightArrow {
						margin-right: $bulkUploadMargin/2;
					}
				}
			}

			.paginationSizeWrapper {

				text-align: center;
				margin: $bulkUploadMargin/2 0 0 0;

				.atpSizeCopy {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					font-weight: 300;
					margin: 0 $bulkUploadMargin 0 0;
				}

				.atpSizeDropdown {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					border-bottom: 1px solid $bulkUploadColor;
					cursor: pointer;

					select {
						border: none;
						background: none;
					}
				}
			}
		}
	}
}


//////////////////////////////
// BulkUploadResultListItem //
//////////////////////////////


// Titan colors
$bulkUploadResultBackground: $TITAN_BACKGROUND;
$bulkUploadResultBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$bulkUploadResultListItemDrawerMaxHeight: 1000px;
$bulkUploadResultListItemHeaderMinHeight: 40px;
$bulkUploadResultListItemRowDataWidth: 40%;
$bulkUploadResultListItemStatusWidth: 125px;
$bulkUploadResultListItemToggleWidth: 40px;



.BulkUploadResultListItem {

	background: $bulkUploadResultBackground;

	&:hover {

		.burliLiner {

			.headlineWrapper {
				background: $bulkUploadResultBackgroundHover;

				.statusWrapper {

					.statusLiner {
						border-right: 1px solid $bulkUploadResultsListInputBorder;
					}
				}
			}
		}
	}

	.burliLiner {

		.headlineWrapper {

			cursor: pointer;
			padding: $bulkUploadMargin/2 0;
			transition: background $hoverTransition;

			.statusWrapper {

				display: inline-block;
				vertical-align: top;
				width: $bulkUploadResultListItemStatusWidth;
				min-height: $bulkUploadResultListItemHeaderMinHeight;

				@media #{$break600} {
					width: calc(100% - #{$bulkUploadResultListItemToggleWidth} - #{$bulkUploadMargin});
				}

				.statusLiner {

					margin: 0 0 0 $bulkUploadMargin/2;
					border-right: 1px solid $bulkUploadResultBackgroundHover;
					min-height: $bulkUploadResultListItemHeaderMinHeight;
					transition: border $hoverTransition;

					.statusValue {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: font-size $breakTransition;

						@media #{$break600} {
							font-size: 16px;
						}
					}

					.statusLineNumber {
						font-size: 13px;
						font-weight: 300;
						transition: font-size $breakTransition;

						@media #{$break600} {
							font-size: 14px;
						}
					}
				}
			}

			.messageWrapper {
				display: inline-block;
				vertical-align: top;
				width: calc(100% - #{$bulkUploadResultListItemStatusWidth} - #{$bulkUploadResultListItemToggleWidth} - #{2*$bulkUploadMargin});
				margin: 0 0 0 $bulkUploadMargin;

				@media #{$break600} {
					display: none;
				}

				.messageTitle {

				}

				.messageValue {
					font-size: 13px;
					font-weight: 300;
				}
			}

			.toggleWrapper {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $bulkUploadResultListItemToggleWidth;
				height: $bulkUploadResultListItemToggleWidth;
				margin: 0 0 0 $bulkUploadMargin;

				.toggleElement {
					font-size: $bulkUploadResultListItemToggleWidth;
					font-weight: 100;
					user-select: none;
				}
			}
		}

		.drawerWrapper.expanded {
			max-height: $bulkUploadResultListItemDrawerMaxHeight;
			transition: max-height $slideTransition;
		}

		.drawerWrapper {
			overflow: hidden;
			height: auto;
			max-height: 0;
			transition: max-height $hoverTransition;

			.drawerLiner {

				margin: $bulkUploadMargin 0;

				.rowDataWrapper {

					display: inline-block;
					overflow: hidden;
					vertical-align: top;
					width: $bulkUploadResultListItemRowDataWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.rowDataLiner {

						.rowDataTitle {
							font-size: 20px;
							border-bottom: 2px solid $bulkUploadAccentColor;
							padding: 0 0 0 $bulkUploadMargin/2;
						}

						.rowDataItem {

							padding: $bulkUploadMargin/2;
							font-size: 14px;

							&:nth-child(odd) {
								background: $bulkUploadResultBackgroundHover;
							}

							.rowDataItemKey {
								display: inline-block;
								vertical-align: top;
								width: 50%;
								font-weight: 700;
								user-select: none;
							}

							.rowDataItemValue {
								display: inline-block;
								vertical-align: top;
								width: 50%;
								font-weight: 400;
							}
						}
					}
				}

				.detailWrapper {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$bulkUploadResultListItemRowDataWidth});
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.detailLiner {

						margin: $bulkUploadMargin 2*$bulkUploadMargin;
						transition: margin $breakTransition;

						@media #{$break768} {
							margin: $bulkUploadMargin 0;
						}

						.summaryWrapper {

							margin: 0 0 2*$bulkUploadMargin 0;

							.summaryLine {

								margin: 0 0 $bulkUploadMargin/2 0;

								.summaryLabel {
									margin: 0 $bulkUploadMargin 0 0;
									font-size: 16px;
									font-weight: 700;
									user-select: none;

									&:after {
										content: ':';
									}
								}

								.summaryValue {
									font-size: 16px;
									font-weight: 400;
								}
							}
						}

						.supplementalWrapper {

							.supplementalTitle {
								font-size: 16px;
								font-weight: 700;
								margin: 0 0 $bulkUploadMargin 0;

								&:after {
									content: ':';
								}
							}

							.supplementalNone {
								font-size: 16px;
								font-weight: 300;
								font-style: italic;
							}

							.supplementalDatum {

								margin: 0 0 $bulkUploadMargin/2 0;
								font-size: 12px;

								.supKey {
									font-family: monospace;
									font-weight: 700;
									text-decoration: underline;
								}

								.supValue {

									.supValueKey {

										display: inline;

										&:after {
											content: ': ';
										}
									}

									.supValueValue {
										display: inline;
									}
								}
							}
						}

						.resolutionWrapper {

						}
					}
				}
			}
		}
	}
}



































