

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



///////////////////////
// HeaderElementCart //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

// Animations
@keyframes pulse {
  0% {
		box-shadow: 0 0 0 0 rgba($headerAccentColor, 0.7);
	}

	100% {
		box-shadow: 0 0 0 2*$headerCartBubbleOffset rgba($headerAccentColor, 0);
	}
}


.HeaderElementCart {

	.cartIconWrapper {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		svg {

			fill: $headerColor;

			&:hover {
				fill: $headerAccentColor;
			}
		}
	}

	.cartBubble {
		position: absolute;
		border-radius: 100px; /* Arbitrary large value */
		top: -1*$headerCartBubbleOffset;
		right: -1*$headerCartBubbleOffset;
		padding: 3px 7px;
		color: $headerAccentColorText;
		background: $headerAccentColor;
		font-size: 14px;
		font-weight: 700;
		transition: padding $breakTransition, top $breakTransition, font-size $breakTransition;

		@media #{$break600} {
			top: calc(-0.5*$headerCartBubbleOffset);
			padding: 2px 5px;
			font-size: 12px;
		}

		&.pulse {
			box-shadow: 0 0 0 0 rgba($headerAccentColor, 0);
			animation-name: pulse;
			animation-duration: 1s;
		}
	}
}













