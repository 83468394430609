
@use '../../../../style/import' as *;


// Titan colors
// None yet

// Theme colors
$footerBackground: $THEME_LEFT_MENU_CONTROL_BACKGROUND;
$footerColor: $THEME_LEFT_MENU_CONTROL_BACKGROUND_TEXT;

$footerCopyrightColor: $THEME_RULE_COLOR;
$footerLinkColor: $THEME_LEFT_MENU_CONTROL_BACKGROUND_TEXT;
$footerLinkColorHover: $THEME_PRIMARY_COLOR;
$footerPrimaryColor: $THEME_PRIMARY_COLOR;
$footerRuleColor: $THEME_RULE_COLOR;


// General variables
$footerMargin: 10px;
$footerPoweredLogoHeight: 20px;

$footerIconWidth: 20px;
$footerSocialIconWidth: 25px;





.Footer {

	overflow: auto;
	color: $footerColor;
	background: $footerBackground;

	.footerLiner {

		margin: 2*$footerMargin auto;
		width: $fixedWidth;
		max-width: $fixedMaxWidth;

		// Delete this
		.notReal {
			margin: 10px;
		}

		.blockWrapper {

			border-bottom: 1px solid $footerRuleColor;
			
			.footerBlock {
				display: inline-block;
				vertical-align: top;
				width: 25%;
				margin: $footerMargin 0;
				transition: width $breakTransition;

				@media #{$break1024} {
					width: 50%;
				}

				@media #{$break600} {
					width: 100%;
				}

				.fbLiner {

					.fbTitle {

						margin: 0 0 $footerMargin 0;

						.fbTitleCopy {
							display: inline-block;
							vertical-align: top;
							font-size: 18px;
							font-weight: 700;
							text-transform: uppercase;
							border-bottom: 2px solid $footerPrimaryColor;
						}
					}

					.fbBody {

						.fbBodySection {

							.fbLinkWrapper {

								margin: 0 0 calc($footerMargin/2) 0;

								.fbLink {
									font-size: 14px;
									color: $footerLinkColor;
									transition: color $hoverTransition;

									&:hover {
										color: $footerLinkColorHover;
									}
								}
							}

							.addressElement.iconElementWrapper {
								margin-bottom: 2*$footerMargin;
							}

							.hoursElement.iconElementWrapper {
								
							}

							.iconElementWrapper {

								margin: 0 $footerMargin $footerMargin 0;

								.iconElementLink {

									&:hover {

										.iconContainer {
											.iconComponent {
												svg {
													fill: $footerPrimaryColor;
												}
											}
										}

										.iconElementText {
											color: $footerLinkColorHover;
										}
									}
								}

								.iconContainer {
									display: inline-block;
									vertical-align: top;
									width: $footerIconWidth;

									.iconComponent {
										width: $footerIconWidth;
										height: $footerIconWidth;

										svg {
											fill: $footerColor;
										}
									}
								}

								.iconElementText {
									display: inline-block;
									vertical-align: top;
									max-width: calc(100% - #{$footerIconWidth} - #{$footerMargin});
									margin: 0 0 0 $footerMargin;
									color: $footerLinkColor;
									font-size: 14px;
									transition: color $hoverTransition;

									.iconElementTitle {
										font-size: 14px;
										font-weight: 700;
										text-transform: uppercase;
										margin: 0 0 calc($footerMargin/2) 0;
									}

									.hoursLine {

										margin: 0 0 calc($footerMargin/2) 0;

										.hoursDay {
											font-size: 14px;
											font-weight: 400;
										}

										.hoursValue {
											font-size: 13px;
											font-weight: 300;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.languageWrapper {

			margin: calc($footerMargin/2) 0;

			.selected.languageElement {
				font-weight: 700;	
			}

			.languageElement {
				display: inline-block;
				color: $footerLinkColor;
				padding: 0.25*$footerMargin 0.5*$footerMargin;
				font-weight: 400;
				width: auto;
				border-left: 2px solid $footerPrimaryColor;
				cursor: pointer;
				transition: color $hoverTransition;

				&:first-child {
					border-left: none;
					padding-left: 0;
				}

				&:hover {
					color: $footerLinkColorHover;
				}
			}
		}

		.bottomFooter {

			.copyWrapper {
				margin: $footerMargin 0 0 0;
				text-align: center;
				font-size: 16px;
				color: $footerCopyrightColor;
				font-weight: 300;
			}

			.footerLegalRow {

				text-align: center;
				margin: 0 0 2*$footerMargin 0;

				.legalLink {

					display: inline-block;
					color: $footerCopyrightColor;
					padding: 0 calc($footerMargin/2);
					font-size: 12px;
					font-weight: 400;
					width: auto;
					border-left: 1px solid $footerPrimaryColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:first-child {
						border-left: none;
						padding-left: 0;
					}

					&:hover {
						color: $footerLinkColorHover;
					}
				}
			}
		}

		.poweredByWrapper {
			margin: $footerMargin 0;
			text-align: center;
			color: $footerCopyrightColor;

			.poweredBy {

				margin: 0 0 calc($footerMargin/2) 0;

				.poweredByCopy {
					display: inline-block;
					vertical-align: middle;
					margin: 0 calc($footerMargin/2) 0 0;
					font-size: 14px;
					font-weight: 300;
					color: $footerCopyrightColor;
				}

				.poweredByImg {
					display: inline-block;
					vertical-align: middle;
					height: $footerPoweredLogoHeight;
					width: auto;

					svg {
						height: $footerPoweredLogoHeight;
						fill: $footerCopyrightColor;
					}
				}
			}

			.burritoTime.andBurritos {
				opacity: 1.0;
			}

			.noBurritos.andBurritos {
				opacity: 0.0;
			}

			.andBurritos {
				color: $footerCopyrightColor;
				font-size: 11px;
				font-weight: 300;
				transition: opacity $slideTransition;
			}
		}

		.legalWrapper {

		}
	}
}