

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



/////////////////////
// HeaderHelloUser //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HeaderHelloUser {

	color: $headerColor;

	.helloWrapper {

		&.loadingWrapper {
			font-size: 24px;
			font-weight: 100;
		}

		.helloInnerWrapper {

			.helloName {
				font-size: 15px;
				font-weight: 700;
				width: 100%;
			}

			.helloEmail {
				display: inline-block;
				font-size: 14px;
				font-weight: 300;
				width: 100%;
			}

			.helloLink {
				color: $headerColor;
				transition: color $hoverTransition;

				&:hover {
					color: $headerColorHover;
				}
			}
		}
	}
}













