

@use '../../../../style/import' as *;




///////////////////
// StoreSettings //
///////////////////


// Titan colors
$storeSettingsAccent: $TITAN_PRIMARY_COLOR;
$storeSettingsAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$storeSettingsAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$storeSettingsColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$storeSettingsMargin: 10px;


.StoreSettings {

}


////////////////////////////
// StoreSettingsHoursView //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.StoreSettingsHoursView {

	color: $storeSettingsColor;

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.noHoursWrapper {
					text-align: center;
					margin: 5*$storeSettingsMargin 0;
					font-size: 24px;
					font-weight: 300;
				}
			}
		}
	}
}


////////////////////////////
// StoreSettingsHoursEdit //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.StoreSettingsHoursEdit {

	color: $storeSettingsColor;

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.storeHoursForm {
				margin: $storeSettingsMargin 0;

				.storeHourRowWrapper {
					margin: 0 0 $storeSettingsMargin/2 0;
				}
			}
		}
	}
}



///////////////////////////
// StoreSettingsHourLine //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$storeSettingsHoursDayWidth: 110px;
$storeSettingsRangeWidth: 30px;
$storeSettingsRowHeight: 45px;
$storeSettingsToggleWidth: 30px;


.StoreSettingsHourLine {

	.storeHourRow {

		.shDayLabel {
			display: inline-block;
			vertical-align: top;
			width: $storeSettingsHoursDayWidth;
			margin: 0 $storeSettingsMargin 0 0;
			font-size: 20px;
		}

		.shDayControls {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$storeSettingsHoursDayWidth} - #{$storeSettingsMargin});
			margin: 0 0 0 0;

			.shDayEdit {

				position: relative;

				.adminFieldWrapper {

					.adminInputWrapper.halfWidth {

						width: calc(50% - #{$storeSettingsRangeWidth/2} - #{$storeSettingsToggleWidth/2} - #{$storeSettingsMargin/2});
						margin-right: $storeSettingsRangeWidth/2;

						&:nth-child(2) {
							margin-right: 0;
							margin-left: $storeSettingsRangeWidth/2;
						}

						.adminInputTime {

						}
					}
				}

				.shRange {
					position: absolute;
					top: 0;
					left: calc(50% - #{$storeSettingsRangeWidth/2} - #{$storeSettingsToggleWidth/2} - #{$storeSettingsMargin/2});
					width: $storeSettingsRangeWidth;
					height: 100%;
				}

				.shDayToggle {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $storeSettingsToggleWidth;
					height: $storeSettingsRowHeight;
					margin: 0 0 0 $storeSettingsMargin;
					color: $storeSettingsColor;
					font-size: 36px;
					font-weight: 100;
					cursor: pointer;
					transition: color $hoverTransition, font-weight $hoverTransition;

					&:hover {
						font-weight: 300;
						color: $storeSettingsAccent;
					}
				}
			}

			.shDayClosed {

				.closedLabel {
					display: inline-block;
					vertical-align: middle;
					font-size: 18px;
					font-weight: 300;
					margin: 0 $storeSettingsMargin 0 0;
				}

				.closedToggle {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
					font-weight: 700;
					color: $storeSettingsAccent;
					cursor: pointer;
					transition: color $hoverTransition;

					&:before {
						content: '+ ';
					}

					&:hover {
						color: $storeSettingsAccentHover;
					}
				}
			}
		}
	}
}





