

@use '../../../../style/import' as *;




//////////////////
// ManageEvents //
//////////////////


// Titan colors
$manageEventsAccent: $TITAN_PRIMARY_COLOR;
$manageEventsAccentColor: $TITAN_PRIMARY_COLOR_TEXT;
$manageEventsAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$manageEventsBackground: $TITAN_BACKGROUND;
$manageEventsColor: $TITAN_TEXT_COLOR;
$manageEventsColorLight: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
$manageEventsMargin: 10px;


.ManageEvents {

	color: $manageEventsColor;

	.adminBody {

		.eventsTitle {
			font-size: 20px;
			font-family: $fontLato;
			margin: 0 0 $manageEventsMargin 0;
		}

		.eventTopWrapper {

			margin: 0 0 2*$manageEventsMargin 0;

			.eventSmallBlock {

				display: none;
				// display: inline-block;
				vertical-align: top;
				width: calc(50% - #{$manageEventsMargin});

				&:nth-child(2) {
					margin-left: 2*$manageEventsMargin;
				}

				.sbBody {

				}
			}
		}

		.eventsCalendarBlock {

			margin: 0 0 3*$manageEventsMargin 0;

			.eventsCalendarWrapper {

			}
		}
	}
}


/////////////////////
// RecurringEvents //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.RecurringEvents {

}


/////////////////
// CreateEvent //
/////////////////


// Titan colors
$createEventPlaceholderColor: $placeholderGrey;

// Theme colors
// None yet

// General variables
$createEventRepeatHeight: 200px;


.CreateEvent.AdminPage {

	.adminBody {

		.adminForm {
			overflow: visible;

			.createEventForm {

				.still.expanded.repeatSection {
					overflow: visible;
				}

				.expanded.repeatSection {
					max-height: $createEventRepeatHeight;
					transition: max-height $slideTransition;
				}

				.repeatSection {
					overflow: hidden;
					height: auto;
					max-height: 0;
					transition: max-height $slideFastTransition;

					.adminFieldWrapper .adminInputWrapper select {
						// color: $createEventPlaceholderColor;

						option {
							color: green;
							&:nth-child(2) {
								text-transform: uppercase;
							}
						}
					}
				}

				.adminFieldWrapper {

					.adminInputWrapper {
						.registrationInput {
							text-align: right;
						}
					}
				}
			}
		}
	}
}








