

@use '../../../../../../style/import' as *;




/////////////////////////////
// BlockContentImageInline //
/////////////////////////////


// Titan colors
// None yet

// Theme colors

$lightThemeTextColor: $THEME_TEXT_COLOR;

$darkThemeTextColor: $THEME_BACKGROUND;

$actionColor: $THEME_PRIMARY_COLOR;
$actionColorHover: $THEME_PRIMARY_COLOR_HOVER;
$actionColorText: $THEME_PRIMARY_COLOR_TEXT;


// General variables
// None yet


.BlockContentImageInline {

	.innerWrapper {

		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		.titleWrapper {

			position: absolute;
			top: $MARGIN_L;
			left: $MARGIN_L;
			right: $MARGIN_L;
			height: auto;

			font-size: 32px;
			font-weight: 700;
			text-transform: uppercase;

			// Theme colors
			color: $lightThemeTextColor;

			&.light-0 {
				// Placeholder: Light is default, so no styles here
			}

			&.dark-0 {
				color: $darkThemeTextColor;
			}
		}

		.bodyWrapper {

			// Theme colors
			color: $lightThemeTextColor;

			&.light-0 {
				// Placeholder: Light is default, so no styles here
			}

			&.dark-0 {
				color: $darkThemeTextColor;
			}
		}

		.actionWrapper {

			position: absolute;
			bottom: $MARGIN_L;
			left: $MARGIN_L;
			right: $MARGIN_L;
			height: auto;

			text-align: right;

			.actionButton {

				cursor: pointer;
				display: inline-block;
				vertical-align: bottom;

				color: $actionColorText;
				background: $actionColor;

				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				line-height: 1.0;
				text-align: center;
				padding: $MARGIN 2*$MARGIN;

				transition: background $hoverTransition;

				&:hover {
					background: $actionColorHover;
				}
			}
		}
	}
}













