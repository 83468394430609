

@use '../../../../style/import' as *;


$alertScreenColor: $TITAN_SCREEN_COLOR;
$alertScreenOpacity: $TITAN_SCREEN_OPACITY;

$alertAccent: $TITAN_PRIMARY_COLOR;
$alertAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$alertAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$alertBackground: $TITAN_BACKGROUND;
$alertBackgroundHover: $TITAN_BACKGROUND_HOVER;
$alertColor: $TITAN_TEXT_COLOR;
$alertColorHover: $TITAN_TEXT_COLOR_HOVER;

$alertThemeAccent: $THEME_PRIMARY_COLOR;
$alertThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$alertThemeAccentText: $THEME_PRIMARY_COLOR_TEXT;
$alertThemeBackground: $THEME_BACKGROUND;
$alertThemeBackgroundHover: $THEME_BACKGROUND_HOVER;
$alertThemeColor: $THEME_TEXT_COLOR;
$alertThemeColorHover: $THEME_TEXT_COLOR_HOVER;

$alertMargin: 10px;
$alertSpacing: 15px;
$alertSpacingMobile: 8px;
$alertWidth: 400px;


.Alert.storeTheme {

	.alertModalWrapper {

		.alertModal {

			background: $alertThemeBackground;
			color: $alertThemeColor;

			.alertModalInnerWrapper {

				border: 1px solid $alertThemeAccent;

				.alertModalHr {
					background: $alertThemeAccent;
				}

				.alertModalActions {

					.alertAction.alertActionSave {
						color: $alertThemeAccentText;
						background: $alertThemeAccent;

						&:hover {
							background: $alertThemeAccentHover;
						}
					}
				}
			}
		}
	}
}

.Alert {

	display: none;

	.alertScreen.withOpacity {
		opacity: $alertScreenOpacity;
	}

	.alertScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $alertScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.alertModalWrapper {

		.alertModal {

			position: fixed;
			left: calc(50% - #{$alertWidth}/2);
			width: $alertWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $alertBackground;
			color: $alertColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.alertModalInnerWrapper {

				margin: $alertMargin;
				padding: $alertMargin;
				border: 1px solid $alertAccent;

				.alertModalTitle {
					font-size: 26px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $alertSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 24px;
						margin: $alertSpacingMobile 0;
					}
				}

				.alertModalHr {
					background: $alertAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.alertModalCopy {
					font-size: 18px;
					text-align: center;
					margin: $alertSpacing 0;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 14px;
						margin: $alertSpacingMobile 0;
					}
				}

				.alertModalActions {

					text-align: center;
					margin: $alertSpacing 0 0 0;

					.alertAction.alertActionSave {
						color: $alertAccentText;
						background: $alertAccent;

						&:hover {
							background: $alertAccentHover;
						}
					}

					.alertAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						padding: 0.5*$alertSpacing 2*$alertSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $alertSpacingMobile;
							padding: 0.5*$alertSpacing $alertSpacing;
						}
					}
				}
			}
		}
	}
}
