

@use '../../../../style/import' as *;
@import './constants/analytics';



/////////////////////////////
// AnalyticsGraphDateRange //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables



.AnalyticsGraphDateRange {

	.dateRangeWrapper {
		margin-top: 0.25 * $analyticsHeaderHeight;
	}
}













