

@use '../../../../style/import' as *;




////////////////////
// BlockComponent //
////////////////////


// Titan colors
$loadingTitanColor: $TITAN_PRIMARY_COLOR;
$titleUnderlineTitanColor: $TITAN_PRIMARY_COLOR;
$actionTitanBackground: $TITAN_PRIMARY_COLOR;
$actionTitanBackgroundHover: $TITAN_PRIMARY_COLOR_HOVER;
$actionTitanColor: $TITAN_PRIMARY_COLOR_TEXT;
$backgroundTitanColor: $TITAN_BACKGROUND;

// Theme colors
$loadingThemeColor: $THEME_PRIMARY_COLOR;
$titleUnderlineThemeColor: $THEME_PRIMARY_COLOR;
$actionThemeBackground: $THEME_PRIMARY_COLOR;
$actionThemeBackgroundHover: $THEME_PRIMARY_COLOR_HOVER;
$actionThemeColor: $THEME_PRIMARY_COLOR_TEXT;
$backgroundThemeColor: $THEME_BACKGROUND;

// General variables
$defaultMinHeight: 120px;
$actionHeight: 30px;
$loadingWidth: 60px;
$titleHeight: 36px;


.BlockComponent {

	&.titanTheme {

		.blockLiner {

			.blockTitle {

				.blockTitleButton {

					.blockButton {

					}
				}
			}
		}
	}


	.blockLiner {

		.blockTitle {
			font-family: $fontLato;
			font-size: 24px;
			font-weight: 700;
			margin: 0 0 $MARGIN 0;

			.blockTitleValue {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin: 0 $MARGIN 0 0;
			}

			.blockTitleButton {

				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin: 0 0 0.25*$MARGIN 0;
				
				.blockButton {
					border-width: 1px;
					border-style: solid;
					border-color: $actionThemeBackground;
					background: $backgroundThemeColor;
					color: $actionThemeBackground;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					padding: 0.33*$MARGIN $MARGIN;
					cursor: pointer;
					transition: color $hoverTransition, background $hoverTransition;

					&:hover {
						background: $actionThemeBackground;
						color: $backgroundThemeColor;
					}
				}
			}
		}

		.blockTitleUnderline {
			position: absolute;
			top: $MARGIN;
			left: $MARGIN;
			width: calc(100% - #{2*$MARGIN});
			height: $titleHeight - 2px;
			line-height: 18px;
			border-bottom: 2px solid $titleUnderlineThemeColor;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.blockBodyHorizontal {
			overflow: auto;
			position: relative;
			margin: 0 0 $MARGIN 0;
			min-height: $defaultMinHeight;

			.blockBodyHorizontalLoadingIcon {
				position: absolute;
				top: calc(50% - #{0.5*$loadingWidth});
				left: calc(50% - #{0.5*$loadingWidth});
				width: $loadingWidth;
				height: $loadingWidth;

				svg {
					fill: $loadingThemeColor;
				}
			}

			.blockBodyHorizontalLiner {
				
			}
		}

		.blockBodyVertical {
			overflow: auto;
			position: absolute;
			top: 2*$MARGIN + $titleHeight;
			bottom: 2*$MARGIN + $actionHeight;
			left: $MARGIN;
			right: $MARGIN;

			.verticalBodyIcon {
				position: absolute;
				top: calc(50% - #{0.5*$loadingWidth});
				left: calc(50% - #{0.5*$loadingWidth});
				width: $loadingWidth;
				height: $loadingWidth;

				svg {
					fill: $loadingThemeColor;
				}
			}

			.blockBodyVerticalLiner {
				overflow: auto;
				padding: 0 0 $MARGIN 0;
			}
		}

		.blockFooter {
			position: absolute;
			bottom: $MARGIN;
			left: $MARGIN;
			width: calc(100% - #{2*$MARGIN});
			height: $actionHeight;
			text-align: center;

			.blockAction {
				display: inline-block;
				vertical-align: top;
				width: auto;
				height: $actionHeight;
				line-height: $actionHeight;
				padding: 0 $MARGIN_L;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				cursor: pointer;
				color: $actionThemeColor;
				background: $actionThemeBackground;
				transition: background $hoverTransition;

				&:hover {
					background: $actionThemeBackgroundHover;
				}
			}
		}
	}
}

