

@use '../../../../style/import' as *;




//////////
// Form //
//////////


// Titan colors
$errorColor: $errorRed;
$formAccent: $TITAN_PRIMARY_COLOR;
$textColor: $TITAN_TEXT_COLOR;
$textColorHover: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
// None yet


.Form {

	position: relative;
	overflow: auto;

	.formLiner {

		.formError {

			&.errorPresent {
				height: 1.5*$MARGIN_L;
			}

			color: $errorColor;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: left;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.formBody {

			.formInputWrapper {
				
			}
		}
	}
}













