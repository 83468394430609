

@use '../../../../../style/import' as *;




///////////////////////////////
// ProductPageDetailsLorcana //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
$backgroundColor: $THEME_BACKGROUND;
$highlightColor: $THEME_PRIMARY_ULTRALIGHT;

// General variables
// None yet


.ProductPageDetailsLorcana {

	.detailsWrapper {
	
		@media #{$break768} {
			margin: 0 0 $MARGIN 0;
		}

		.lorcanaWrapper {

			padding: $MARGIN;
			transition: padding $breakTransition;

			@media #{$break768} {
				padding: 0 0 $MARGIN 0;
			}

			.faceWrapper {

				.titleWrapper {

					font-size: 22px;
					font-weight: 700;
					font-family: $fontLato;

					.titleText {

					}
				}

				.typeLine {
					font-size: 16px;
					font-weight: 400;
					margin: 0 0 $MARGIN 0;
				}


				.lorcanaDetails {

					background: $backgroundColor;
					padding: $MARGIN;

					.lorcanaDesc {

						mark {
							background-color: $highlightColor;
						}
					}

					.lorcanaSecondaryDesc {
						font-style: italic;
						font-weight: 300;
					}	
				}
			}
		}
	}
}










