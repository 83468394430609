

@use '../../../../style/import' as *;
@import './customerpermissionsedit';


//////////////////
// ViewCustomer //
//////////////////


// Titan colors
$viewCustomerColor: $TITAN_TEXT_COLOR;
$viewCustomerAccent: $TITAN_PRIMARY_COLOR;
$viewCustomerAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$viewCustomerAccentColor: $TITAN_PRIMARY_COLOR_TEXT;

$viewCustomerIncludedPermission: $TITAN_TEXT_COLOR;
$viewCustomerExcludedPermission: $disabledColor;

// Theme colors
// None yet

// General variables
$viewCustomerMargin: 10px;
$customerBlockMinHeight: 80px;


.ViewCustomer {


	.adminBody {
		.adminView {
			.adminSection {
				max-width: none;
			}
			.adminForm {
				max-width: none;
			}
		}
	}

	.adminActions {
		max-width: none;
	}
}


/////////////////////////
// CustomerDetailsView //
/////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CustomerDetailsView {

	.detailsAccountLocked {
		color: $errorRed;
		text-align: center;
		margin: 2*$viewCustomerMargin 0;
		font-size: 20px;
		font-weight: 700;
	}

	.adminSectionBody {
		.adminSectionBodyWrapper {
			.adminViewWrapper {
				
				.detailBlock {
					display: inline-block;
					vertical-align: top;
					padding: 0 $viewCustomerMargin;
					transition: width $breakTransition, padding $breakTransition;

					@media #{$break1024} {
						display: block;
						width: auto;
						padding: $viewCustomerMargin 0;
					}

					.detailBlockWrapper {
						padding: 0 10%;
						transition: padding $breakTransition;

						@media #{$break1500} {
							padding: 0 8%;
						}

						@media #{$break1200} {
							padding: 0 6%;
						}

						@media #{$break1024} {
							padding: 0;
						}
					}
				}

				.adminViewEditable {
					position: relative;
					width: calc(50% - 2*#{$viewCustomerMargin} + 1px);

					@media #{$break1024} {
						width: auto;
					}
				}

				.adminViewMeta {
					width: calc(50% - 2*#{$viewCustomerMargin} - 1px);

					@media #{$break1024} {
						width: auto;
					}
				}
			}
		}
	}
}


/////////////////////////////
// CustomerPermissionsView //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$viewCustomerPermissionHeight: 50px;


.CustomerPermissionsView {
	
	.noPermissions {
		padding: 25px 0;
		text-align: center;

		.noPermissionsTitle {
			font-size: 24px;
			font-weight: 400;
			text-transform: uppercase;
			margin: 0 0 20px 0;
		}

		.noPermissionsCopy {
			margin: 0 0 20px 0;
			padding: 0 5%;
			font-size: 18px;
			font-weight: 300;
		}

		.noPermissionsButtonWrapper {

		}
	}

	.hasPermissions {

		text-align: left;

		.permissionItem.excluded {
			color: $viewCustomerExcludedPermission;
		}

		.permissionItem {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 2*#{$viewCustomerMargin});
			height: $viewCustomerPermissionHeight;
			margin: 0 $viewCustomerMargin $viewCustomerMargin $viewCustomerMargin;
			transition: width $breakTransition;

			@media #{$break768} {
				width: calc(100% - 2*#{$viewCustomerMargin});
			}

			.permissionCheck {
				position: absolute;
				top: 0;
				left: 0;
				width: $viewCustomerPermissionHeight;
				height: $viewCustomerPermissionHeight;

				.permissionCheckIcon {
					position: absolute;
					top: 15%;
					left: 0;
					right: 20%;
					bottom: 25%;
				}
			}

			.permissionName {
				position: absolute;
				top: 0;
				left: $viewCustomerPermissionHeight;
				right: $viewCustomerMargin;
				height: $viewCustomerPermissionHeight / 2;
				line-height: $viewCustomerPermissionHeight / 2;

				font-size: 18px;
				font-weight: 400;
			}

			.permissionDesc {
				position: absolute;
				left: $viewCustomerPermissionHeight;
				right: $viewCustomerMargin;
				bottom: 0;
				height: $viewCustomerPermissionHeight / 2;
				line-height: $viewCustomerPermissionHeight / 4;

				font-size: 13px;
				font-weight: 300;
			}
		}

		.hasPermissionsButtonWrapper {
			text-align: right;
		}
	}
}


/////////////////////////////
// CustomerStoreCreditView //
/////////////////////////////


// Titan colors
$customerStoreCreditDisabled: $disabledColor;
$customerStoreCreditRowBackgroundColor: $TITAN_PRIMARY_ULTRALIGHT;
$customerStoreCreditRowBackgroundPlain: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
$customerStoreCreditBalanceHeight: 40px;
$customerStoreCreditCellDateWidth: 180px;
$customerStoreCreditCellBalanceWidth: 100px;
$customerStoreCreditCellChangeWidth: 100px;


.CustomerStoreCreditView {
	
	.adminSectionBody {

		.adminSectionBodyWrapper {

			.entryHeader {

				position: relative;
				height: $customerStoreCreditBalanceHeight;
				margin: $viewCustomerMargin 0 0 0;

				.entryBalanceWrapper {

					.balanceLabel {
						display: inline-block;
						vertical-align: top;
						height: $customerStoreCreditBalanceHeight;
						line-height: $customerStoreCreditBalanceHeight;
						font-size: 22px;
						font-weight: 400;
					}

					.balanceValue {
						display: inline-block;
						vertical-align: top;
						height: $customerStoreCreditBalanceHeight;
						line-height: $customerStoreCreditBalanceHeight;
						margin: 0 0 0 $viewCustomerMargin;
						font-size: 22px;
						font-weight: 300;
					}
				}

				.entryActionWrapper {

					position: absolute;
					top: 0;
					right: 0;

					.addStoreCreditButton {

					}
				}
			}

			.entryList {

				margin: $viewCustomerMargin 0 0 0;

				.entryListLiner {

					.dateCell {
						display: inline-block;
						vertical-align: top;
						width: $customerStoreCreditCellDateWidth;

						@media #{$break768} {
							width: calc(100% - #{0.8*$customerStoreCreditCellBalanceWidth} - #{0.8*$customerStoreCreditCellChangeWidth});
						}
					}

					.balanceCell {
						display: inline-block;
						vertical-align: top;
						width: $customerStoreCreditCellBalanceWidth;

						@media #{$break768} {
							width: 0.8*$customerStoreCreditCellBalanceWidth;
						}
					}

					.changeCell {
						display: inline-block;
						vertical-align: top;
						width: $customerStoreCreditCellChangeWidth;

						@media #{$break768} {
							width: 0.8*$customerStoreCreditCellChangeWidth;
						}
					}

					.noteCell {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$customerStoreCreditCellDateWidth} - #{$customerStoreCreditCellBalanceWidth} - #{$customerStoreCreditCellChangeWidth});

						@media #{$break768} {
							display: block;
							width: 100%;
							margin: $viewCustomerMargin/2 0 2*$viewCustomerMargin 0;
						}
					}

					.entryListHeader {

						.entryListHeaderLiner {

							margin: 0 0 $viewCustomerMargin 0;

							.noteCell.headerCell {

								@media #{$break768} {
									display: none;
								}
							}

							.headerCell {
								font-size: 16px;
								font-weight: 700;
							}
						}
					}

					.entryListBody {

						.entryListBodyLiner {

							.entryLine {
								padding: $viewCustomerMargin/2 0;
								font-size: 14px;
								background: $customerStoreCreditRowBackgroundPlain;

								&:nth-child(odd) {
									background: $customerStoreCreditRowBackgroundColor;
								}

								.dateCell {

									.dateLongValue {

										padding: 0 0 0 $viewCustomerMargin/2;

										.dateDate {

										}

										.dateTime {
											font-weight: 300;
										}
									}
								}
							}
						}
					}
				}

				.paginationWrapper {

					.paginationLiner {

						text-align: center;

						.noNav.leftArrow, .noNav.rightArrow {
							color: $customerStoreCreditDisabled;
							cursor: default;

							&:hover {
								font-weight: 400;
								color: $customerStoreCreditDisabled;
							}
						}

						.leftArrow, .rightArrow {
							display: inline-block;
							vertical-align: middle;
							font-size: 22px;
							font-weight: 400;
							color: $viewCustomerColor;
							cursor: pointer;
							user-select: none;
							transition: color $hoverTransition, font-weight $hoverTransition;

							&:hover {
								font-weight: 700;
								color: $viewCustomerAccent;
							}
						}

						.pageStatus {
							display: inline-block;
							vertical-align: middle;
							margin: 0 $viewCustomerMargin;
						}
					}
				}
			}

			.noOrders {
				padding: 5*$viewCustomerMargin 0;
				text-align: center;
				min-height: $customerBlockMinHeight;

				.noOrdersTitle {
					font-size: 24px;
					font-weight: 400;
					text-transform: uppercase;
				}

				.noOrdersCopy {
					margin: $viewCustomerMargin 0 0 0;
					font-size: 20px;
					font-weight: 300;
				}
			}
		}
	}
}


////////////////////////
// CustomerOrdersView //
////////////////////////


// Titan colors
$customerOrdersListRowSelectedBackground: $TITAN_PRIMARY_ULTRALIGHT;
$customerOrdersListRowBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$customerOrdersInfoWidth: 33.3%;
$customerOrdersListRowHeight: 50px;

$customerOrdersViewItemsMaxHeight: 300px;
$customerOrdersViewTableDateWidth: 150px;
$customerOrdersViewTableAmountWidth: 120px;
$customerOrdersViewTableAmountWidthMobile: 80px;
$customerOrdersViewTableQuantityWidth: 100px;
$customerOrdersViewTableQuantityWidthMobile: 70px;
$customerOrdersViewTableActionWidth: 40px;
$customerOrdersViewTableActionWidthMobile: 30px;


.CustomerOrdersView {
	
	.noOrders {
		padding: 5*$viewCustomerMargin 0;
		text-align: center;
		min-height: $customerBlockMinHeight;

		.noOrdersTitle {
			font-size: 24px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.noOrdersCopy {
			margin: $viewCustomerMargin 0 0 0;
			font-size: 20px;
			font-weight: 300;
		}
	}

	.ordersListWrapper {

		margin: $viewCustomerMargin 0 0 0;
		text-align: left;

		.orderList {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$customerOrdersInfoWidth} - #{1.5*$viewCustomerMargin});

			@media #{$break1024} {
				width: 100%;
			}

			.slHeader {

				margin: 0 0 $viewCustomerMargin 0;
				
				.slHeaderCell {
					display: inline-block;
					vertical-align: top;
					font-size: 18px !important;
					font-weight: 700 !important;
					transition: font-size $breakTransition;

					@media #{$break1024} {
						font-size: 16px !important;
					}

					.slHeaderValue {

					}
				}
			}

			.slSelected.slItem {
				cursor: default;
				background: $customerOrdersListRowSelectedBackground;

				@media #{$break1024} {
					background: none;
				}

				&:hover {
					background: $customerOrdersListRowSelectedBackground;

					@media #{$break1024} {
						background: none;
					}
				}

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 100%;
					width: 0;
					height: 0;
					transition: background $hoverTransition;

					border-left: 2*$viewCustomerMargin solid $customerOrdersListRowSelectedBackground;
					border-top: $customerOrdersListRowHeight/2 solid transparent;
					border-bottom: $customerOrdersListRowHeight/2 solid transparent;

					@media #{$break1024} {
						display: none;
					}
				}
			}

			.slItem {

				cursor: pointer;
				min-height: $customerOrdersListRowHeight;
				transition: background $hoverTransition;

				@media #{$break1024} {
					cursor: default;
				}

				&:hover {
					background: $customerOrdersListRowBackgroundHover;

					@media #{$break1024} {
						background: none;
					}
				}

				&:nth-child(even) {

					@media #{$break1024} {
						background: $customerOrdersListRowSelectedBackground;

						&:hover {
							background: $customerOrdersListRowSelectedBackground;
						}
					}
				}

				.slItemCell {
					position: relative;
					display: inline-block;
					vertical-align: top;
					min-height: $customerOrdersListRowHeight;

					.slItemValue {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;

						display: flex;
						align-items: center;
					}
				}
			}

			.slItem, .slHeader {

				position: relative;

				.dateCell {
					width: $customerOrdersViewTableDateWidth;
					margin-left: $viewCustomerMargin;
					font-weight: 700;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: calc(100% - #{4*$viewCustomerMargin} - #{$customerOrdersViewTableAmountWidthMobile} - #{$customerOrdersViewTableQuantityWidthMobile} - #{$customerOrdersViewTableActionWidthMobile});
						font-size: 13px;
					}

					@media #{$break600} {
						width: calc(100% - #{3*$viewCustomerMargin} - #{$customerOrdersViewTableAmountWidthMobile} - #{$customerOrdersViewTableActionWidthMobile});
					}

					.dateDate {
						font-weight: 700;
						font-size: 14px;
					}

					.dateTime {
						font-weight: 300;
						font-size: 15px;
					}
				}

				.numberCell {
					width: calc(100% - #{5*$viewCustomerMargin} - #{$customerOrdersViewTableDateWidth} - #{$customerOrdersViewTableAmountWidth} - #{$customerOrdersViewTableQuantityWidth} - #{$customerOrdersViewTableActionWidth});
					margin-left: $viewCustomerMargin;
					font-weight: 400;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						display: none;
					}
				}

				.amountCell {
					width: $customerOrdersViewTableAmountWidth;
					margin-left: $viewCustomerMargin;
					font-weight: 400;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $customerOrdersViewTableAmountWidthMobile;
						font-size: 13px;
					}
				}

				.quantityCell {
					width: $customerOrdersViewTableQuantityWidth;
					margin-left: $viewCustomerMargin;
					font-weight: 400;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $customerOrdersViewTableQuantityWidthMobile;
						font-size: 13px;
					}

					@media #{$break600} {
						display: none;
					}

					.quantityValue {
						justify-content: center;	
						width: $customerOrdersViewTableQuantityWidth;
						transition: width $breakTransition;

						@media #{$break768} {
							width: $customerOrdersViewTableQuantityWidthMobile;
						}
					}
				}

				.actionCell {
					width: $customerOrdersViewTableActionWidth;
					margin-left: $viewCustomerMargin;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $customerOrdersViewTableActionWidthMobile;
					}

					.noShow.actionIconWrapper {
						display: none;

						@media #{$break1024} {
							display: block;
						}
					}

					.actionIconWrapper {
						position: absolute;
						top: ($customerOrdersListRowHeight - $customerOrdersViewTableActionWidth/2)/2;
						left: $customerOrdersViewTableActionWidth/4;
						width: $customerOrdersViewTableActionWidth/2;
						height: $customerOrdersViewTableActionWidth/2;
						cursor: pointer;

						@media #{$break768} {
							top: ($customerOrdersListRowHeight - $customerOrdersViewTableActionWidthMobile/2)/2;
							left: $customerOrdersViewTableActionWidthMobile/4;
							width: $customerOrdersViewTableActionWidthMobile/2;
							height: $customerOrdersViewTableActionWidthMobile/2;
						}

						&:hover {
							.actionIcon {
								svg {
									fill: $viewCustomerAccentHover;
								}
							}
						}
						
						.actionIcon {
							width: $customerOrdersViewTableActionWidth/2;
							height: $customerOrdersViewTableActionWidth/2;

							@media #{$break768} {
								width: $customerOrdersViewTableActionWidthMobile/2;
								height: $customerOrdersViewTableActionWidthMobile/2;
							}
						}
					}
				}
			}
		}

		.orderInfo {
			display: inline-block;
			vertical-align: top;
			width: calc(#{$customerOrdersInfoWidth} - #{1.5*$viewCustomerMargin});
			margin: 0 0 0 3*$viewCustomerMargin;

			@media #{$break1024} {
				display: none;
			}

			.infoSectionTitle {

				margin: 0 0 $viewCustomerMargin 0;

				.infoSectionTitleValue {
					display: inline-block;
					vertical-align: top;
					font-size: 18px;
					font-weight: 700;
				}

				.infoSectionTitleItems {
					display: inline-block;
					vertical-align: top;
					margin: 1px 0 0 $viewCustomerMargin;
					font-size: 14px;
					font-weight: 300;
				}
			}

			.orderItemsWrapper {
				max-height: $customerOrdersViewItemsMaxHeight;
				overflow-y: scroll;
				padding: 0 $viewCustomerMargin 0 0;
			}
		}
	}

	.paginationWrapper {

		.paginationLiner {

			text-align: center;

			.noNav.leftArrow, .noNav.rightArrow {
				color: $customerStoreCreditDisabled;
				cursor: default;

				&:hover {
					font-weight: 400;
					color: $customerStoreCreditDisabled;
				}
			}

			.leftArrow, .rightArrow {
				display: inline-block;
				vertical-align: middle;
				font-size: 22px;
				font-weight: 400;
				color: $viewCustomerColor;
				cursor: pointer;
				user-select: none;
				transition: color $hoverTransition, font-weight $hoverTransition;

				&:hover {
					font-weight: 700;
					color: $viewCustomerAccent;
				}
			}

			.pageStatus {
				display: inline-block;
				vertical-align: middle;
				margin: 0 $viewCustomerMargin;
			}
		}
	}
}































