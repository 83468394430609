

@use '../../../../style/import' as *;
@import './constants/analytics';




//////////////////////////////
// BlockAnalyticsOrderTotal //
//////////////////////////////


// Titan colors
$loadingIconColor: $TITAN_PRIMARY_COLOR;
$dropdownIconColor: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$loadingIconWidth: 60px;
$dropdownIconScale: 0.7;
$durationFontSize: 13px;



.BlockAnalyticsOrderTotal {

	margin: 0 0 $MARGIN_L 0;

	.headerWrapper {

		height: $analyticsHeaderHeight;

		.titleWrapper {
			line-height: $analyticsHeaderHeight;
			font-size: $analyticsHeaderFontSize;
			font-weight: $analyticsHeaderFontWeight;
			text-align: center;
		}
	}

	.bodyWrapper {

		.loadingWrapper {

			margin: $MARGIN_L 0;

			.iconWrapper {

				margin: 0 auto;
				width: $loadingIconWidth;
				height: $loadingIconWidth;

				svg {
					fill: $loadingIconColor;
				}
			}
		}

		.errorWrapper {
			padding: $MARGIN_XL $MARGIN;
			font-size: 18px;
			font-weight: 300;
			text-align: center;
		}

		.dataWrapper {

			text-align: center;

			.totalValue {
				font-size: 72px;
				font-weight: 700;
			}

			.totalMetric {
				display: none;
				font-size: 18px;
				font-weight: 300;
			}

			.durationWrapper {
				display: inline-block;
				vertical-align: top;
				width: auto;

				.durationValue {
					display: inline-block;
					vertical-align: top;
					width: auto;
					line-height: $durationFontSize;
					margin: 0 0.5*$MARGIN_S;
					padding-left: $durationFontSize;
					font-size: $durationFontSize;
				}

				.durationIcon {

					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $durationFontSize;
					height: $durationFontSize;

					.iconWrapper {

						position: absolute;

						top: 0.5 * (1 - $dropdownIconScale) * 100%;
						left: 0.5 * (1 - $dropdownIconScale) * 100%;
						width: $dropdownIconScale * 100%;
						height: $dropdownIconScale * 100%;

						svg {
							fill: $dropdownIconColor;
						}
					}
				}
			}
		}
	}
}













