

@use '../../../../style/import' as *;

// Titan constants
$productSearchFilterTop: $TITAN_HEADER_HEIGHT;
$productSearchFilterTopMobile: $TITAN_HEADER_HEIGHT_MOBILE;
$productSearchNoticeHeight: $TITAN_HEADER_NOTICE_HEIGHT;
$productSearchNoticeHeightMobile: $TITAN_HEADER_NOTICE_HEIGHT_MOBILE;

// Theme constants
$productSearchAccentColor: $THEME_PRIMARY_COLOR;
$productSearchAccentColorText: $THEME_PRIMARY_COLOR_TEXT;
$productSearchAccentColorHover: $THEME_PRIMARY_COLOR_HOVER;
$productSearchColor: $THEME_TEXT_COLOR;
$productSearchFilterBackground: $THEME_BACKGROUND;
$productSearchBodyBackground: $THEME_BACKGROUND_CONTRAST;

// General variables
$productSearchMargin: 10px;
$productSearchFilterSubheaderHeight: 40px;
$productSearchFilterFooterHeight: 70px;

// Component variables
$productSearchBodyMargin: 25px;
$productSearchBodyMarginMobile: 10px;
$productSearchFilterWidth: 300px;
$productSearchFilterToggleWidth: 20px;






///////////////////
// ProductSearch //
///////////////////


.notified.ProductSearch {

	.productSearchWrapper {

		.psGalleryWrapper {

			.psGalleryMobileFilters {
				top: $productSearchFilterTop + $productSearchNoticeHeight;

				@media #{$break900} {
					top: $productSearchFilterTop + $productSearchNoticeHeightMobile;
				}

				@media #{$break600} {
					top: $productSearchFilterTopMobile + $productSearchNoticeHeightMobile;
				}

				.psmDrawer.open {

					@media #{$break900} {
						height: calc(100vh - #{$productSearchFilterTop + $productSearchNoticeHeightMobile} - #{$productSearchFilterSubheaderHeight});
					}

					@media #{$break600} {
						height: calc(100vh - #{$productSearchFilterTopMobile + $productSearchNoticeHeightMobile} - #{$productSearchFilterSubheaderHeight});
					}
				}

				.psmDrawer {
					top: $productSearchFilterTop + $productSearchNoticeHeightMobile + $productSearchFilterSubheaderHeight;

					@media #{$break600} {
						top: $productSearchFilterTop + $productSearchNoticeHeightMobile + $productSearchFilterSubheaderHeight;
					}

					@media #{$break600} {
						top: $productSearchFilterTopMobile + $productSearchNoticeHeightMobile + $productSearchFilterSubheaderHeight;
					}
				}
			}
		}
	}
}


.ProductSearch {

	color: $productSearchColor;

	.toggled.productSearchWrapper {
		
		&:before {
			left: -1*$productSearchFilterWidth + 2*$productSearchMargin + $productSearchFilterToggleWidth;

			@media #{$break900} {
				left: -1*$productSearchFilterWidth;
			}
		}

		&:after {
			width: calc(100% - #{2*$productSearchMargin + $productSearchFilterToggleWidth});

			@media #{$break900} {
				width: 100%;	
			}
		}
	}

	.productSearchWrapper {

		position: relative;

		&:before {
			content: " ";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: $productSearchFilterWidth;
			height: 100%;
			background: $productSearchFilterBackground;
			transition: left $breakTransition;

			@media #{$break900} {
				left: -1*$productSearchFilterWidth;
			}
		}

		&:after {
			content: " ";
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			width: calc(100% - #{$productSearchFilterWidth});
			height: 100%;
			background: $productSearchBodyBackground;
			transition: width $breakTransition;

			@media #{$break900} {
				width: 100%;	
			}
		}

		.closed.psFiltersWrapper {
			overflow: visible;
			margin-left: -1*$productSearchFilterWidth;
			padding-right: 2*$productSearchMargin + $productSearchFilterToggleWidth;

			@media #{$break900} {
				padding: 0;
			}
		}

		.psFiltersWrapper {

			position: relative;
			overflow: auto;
			display: inline-block;
			vertical-align: top;
			margin: 0;
			width: $productSearchFilterWidth;
			transition: margin $breakTransition, padding $breakTransition;

			@media #{$break900} {
				margin-left: -1*$productSearchFilterWidth;
			}	

			.closed.psFiltersToggle {
				right: $productSearchMargin;
			}

			.psFiltersToggle {
				position: absolute;
				top: $productSearchMargin;
				right: $productSearchBodyMargin;
				width: $productSearchFilterToggleWidth;
				height: $productSearchFilterToggleWidth;
				cursor: pointer;
				transition: right $breakTransition;

				&:hover {
					svg {
						fill: $productSearchAccentColor;
					}
				}

				@media #{$break900} {
					display: none;
				}
			}
		}

		.toggled.psGalleryWrapper {
			width: calc(100% - #{2*$productSearchMargin + $productSearchFilterToggleWidth});

			@media #{$break900} {
				width: 100%;	
			}
		}

		.psGalleryWrapper {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$productSearchFilterWidth});
			transition: width $breakTransition;

			@media #{$break900} {
				width: 100%;
			}

			.psGalleryMobileFilters {
				position: sticky;
				overflow: hidden;
				z-index: $zMenuCoverContent;
				background: $productSearchFilterBackground;
				
				top: $productSearchFilterTop;
				left: 0;
				width: 100%;
				height: 0;
				min-height: 0;
				transition: min-height $breakTransition, top $breakTransition;

				@media #{$break900} {
					overflow: unset;
					min-height: $productSearchFilterSubheaderHeight;
				}

				@media #{$break600} {
					top: $productSearchFilterTopMobile;
				}

				.psmVisible {

					position: relative;
					height: $productSearchFilterSubheaderHeight;

					.psmVisibleFilterToggle {

						position: absolute;
						top: 0.5*$productSearchMargin;
						bottom: 0.5*$productSearchMargin;
						left: $productSearchMargin;
						width: auto;
						cursor: pointer;

						&:hover {

							.psmIcon {

								svg {
									fill: $productSearchAccentColor;
								}
							}

							.psmLabel {
								color: $productSearchAccentColor;
							}
						}

						.psmIcon {
							position: absolute;
							top: 0;
							left: 0;
							width: $productSearchFilterSubheaderHeight - $productSearchMargin;
							height: 100%;
							user-select: none;
						}

						.psmLabel {
							position: relative;
							user-select: none;
							font-size: 20px;
							font-weight: 400;
							margin: 0 0 0 $productSearchFilterSubheaderHeight;
							padding: 0 $productSearchMargin 0 0;
							height: $productSearchFilterSubheaderHeight - $productSearchMargin;
							line-height: $productSearchFilterSubheaderHeight - $productSearchMargin;
							transition: color $hoverTransition;

							@media #{$break600} {
								display: none;
							}
						}
					}

					.psmVisibleApplied {

						position: absolute;
						top: 0.5*$productSearchMargin;
						bottom: 0.5*$productSearchMargin;
						right: 2*$productSearchMargin;
						width: auto;
						height: $productSearchFilterSubheaderHeight - $productSearchMargin;
						line-height: $productSearchFilterSubheaderHeight - $productSearchMargin;
						font-size: 16px;
						font-weight: 300;

						@media #{$break600} {
							display: none;
						}

						.appliedNumeral {
							font-weight: 400;
						}
					}

					.psmVisibleSearch {
						
						display: none;
						position: absolute;

						top: 0.5*$productSearchMargin;
						bottom: 0.5*$productSearchMargin;
						left: $productSearchFilterSubheaderHeight + $productSearchMargin;
						right: $productSearchMargin;

						@media #{$break600} {
							display: block;
						}
					}
				}

				.psmDrawer.open {
					height: 400px;

					@media #{$break900} {
						height: calc(100vh - #{$productSearchFilterTop} - #{$productSearchFilterSubheaderHeight});
					}

					@media #{$break600} {
						height: calc(100vh - #{$productSearchFilterTopMobile} - #{$productSearchFilterSubheaderHeight});
					}
				}

				.psmDrawer {

					display: none;
					overflow: hidden;
					position: fixed;
					top: $productSearchFilterTop + $productSearchFilterSubheaderHeight;
					height: 0;
					left: 0;
					width: 100%;
					background: $productSearchFilterBackground;
					transition: top $breakTransition, height $slideTransition;

					@media #{$break900} {
						display: block;
					}

					@media #{$break600} {
						top: $productSearchFilterTopMobile + $productSearchFilterSubheaderHeight;
					}

					.psmDrawerFilterWrapper {
						overflow: auto;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						height: calc(100% - #{$productSearchFilterFooterHeight});
					}

					.psmDrawerFooterWrapper {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: $productSearchFilterFooterHeight;

						.psmFooterAction {
							cursor: pointer;
							margin: $productSearchMargin;
							height: $productSearchFilterFooterHeight - 2*$productSearchMargin;
							width: calc(100% - #{2*$productSearchMargin});
							color: $productSearchAccentColorText;
							background: $productSearchAccentColor;
							border: none;
							transition: background $hoverTransition;

							&:hover {
								background: $productSearchAccentColorHover;
							}
						}
					}
				}
			}

			.psGallery {
				margin: $productSearchMargin;
			}
		}
	}
}