
@use '../../../../style/import' as *;


$adminMenuColor: $TITAN_ADMIN_BLOCK_BACKGROUND;

$adminMenuLinkBackgroundHover: $TITAN_ADMIN_CONTROL_BACKGROUND_HOVER;
$adminMenuLinkBackgroundSelected: $TITAN_PRIMARY_COLOR;
$adminMenuLinkBackgroundSelectedHover: $TITAN_PRIMARY_COLOR_HOVER_SHADE;

$adminMenuLinkHeight: 40px;
$adminMenuLinkHeightSpacing: 8px;

$adminMenuLinkHeightTablet: 60px;
$adminMenuLinkHeightTabletSpacing: 10px;

$adminMenuHorizontalSpacing: 25px;
$adminMenuHorizontalTabletSpacing: 10px;

$adminPageColor: $TITAN_TEXT_COLOR;
$adminPageColorHover: $TITAN_TEXT_COLOR_HOVER;

$adminPageHorizontalSpacing: 25px;

$adminIconWidth: 150px;
$adminIconWidthMobile: 100px;
$adminIconHeight: 80px;
$adminIconHeightMobile: 60px;

$adminBlockBackground: $TITAN_BACKGROUND;
$adminBlockBackgroundHover: $TITAN_BACKGROUND_HOVER;

$adminFormBorderColor: $TITAN_TEXT_COLOR_LIGHT;
$adminFormMaxWidth: 800px;
$adminFormFieldMaxWidth: 500px;
$adminFormSpacing: 10px;
$adminFormAccent: $TITAN_PRIMARY_COLOR;
$adminFormAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$adminFormAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$adminFormTextareaHeight: 160px;
$adminFormProductLineDropdownHeight: 45px;

$adminDisabledBackground: $disabledBackground;
$adminDisabledBackgroundHover: $disabledBackground;
$adminDisabledColor: $disabledColor;

$adminFormLoadingIconColor: $TITAN_PRIMARY_COLOR;
$adminFormLoadingIconWidth: 60px;

$adminFormSupplementMaxHeight: 240px;

$adminSectionMaxHeight: 2000px;
$adminSectionToggleWidth: 30px;
$adminSectionButtonHeight: 40px;
$adminSectionButtonHeightMobile: 32px;
$adminSectionDropdownHeight: 45px;
$adminSectionRadioHeight: 22px;
$adminSectionRadioSpacing: 3px;

$adminViewVrColor: $TITAN_ADMIN_PAGE_BACKGROUND;

$adminViewItemHeight: 50px;
$adminViewItemHeightTall: 75px;
$adminViewItemLabelHeight: 20px;

$adminAutocompleteHover: $TITAN_PRIMARY_ULTRALIGHT;

$adminTitleOverviewIconBorderWidth: 2px;
$adminTitleOverviewIconHeight: 20px;
$adminTitleOverviewWidth: 300px;



.AdminMenu {

	margin: $adminMenuHorizontalSpacing 0;
	transition: margin $breakTransition;

	@media #{$break1200} {
		margin: $adminMenuHorizontalTabletSpacing 0;
	}

	.adminMenuWrapper {

		.adminMenuTitle {
			font-family: $fontLato;
			color: $adminMenuColor;
			font-size: 24px;
			padding: 0 $adminMenuHorizontalSpacing 20px $adminMenuHorizontalSpacing;
			height: auto;
			max-height: 100px;
			overflow: hidden;
			transition: max-height $breakTransition, padding $breakTransition;

			@media #{$break1200} {
				padding-bottom: 0;
				max-height: 0;
			}
		}

		.selected.adminMenuLink {
			background: $adminMenuLinkBackgroundSelected;

			&:hover {
				background: $adminMenuLinkBackgroundSelectedHover;
			}
		}

		.adminMenuLink {
			position: relative;
			display: block;
			overflow: hidden;
			margin: 0 0;
			padding: 0 $adminMenuHorizontalSpacing;
			height: $adminMenuLinkHeight;
			color: $adminMenuColor;
			transition: background $hoverTransition, height $breakTransition, padding $breakTransition;

			&:hover {
				background: $adminMenuLinkBackgroundHover;
			}

			@media #{$break1200} {
				height: $adminMenuLinkHeightTablet;
				padding: 0 $adminMenuHorizontalTabletSpacing;
			}

			/*@media #{$break600} {
				height: $adminMenuLinkHeightTablet;
				padding: 0 $adminMenuHorizontalTabletSpacing;
			}*/

			.amLinkIconWrapper {

				position: absolute;
				top: $adminMenuLinkHeightSpacing;
				left: $adminMenuHorizontalSpacing;
				width: $adminMenuLinkHeight - ( 2 * $adminMenuLinkHeightSpacing );
				height: $adminMenuLinkHeight - ( 2 * $adminMenuLinkHeightSpacing );
				transition: top $breakTransition, left $breakTransition, width $breakTransition, height $breakTransition;

				@media #{$break1200} {
					top: $adminMenuLinkHeightTabletSpacing;
					left: $adminMenuLinkHeightTabletSpacing;
					width: calc(100% - #{( 2 * $adminMenuLinkHeightTabletSpacing )});
					height: $adminMenuLinkHeightTablet - ( 1 * $adminMenuLinkHeightTabletSpacing );
				}

				@media #{$break1200} {
					top: $adminMenuLinkHeightTabletSpacing;
					left: $adminMenuLinkHeightTabletSpacing;
					width: calc(100% - #{( 2 * $adminMenuLinkHeightTabletSpacing )});
					height: $adminMenuLinkHeightTablet - ( 1 * $adminMenuLinkHeightTabletSpacing );
				}

				@media #{$break600} {
					height: $adminMenuLinkHeightTablet - ( 2 * $adminMenuLinkHeightTabletSpacing );
				}

				.amIcon {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					svg {
						fill: $adminMenuColor;
					}

					.iconSVGWrapper {

						transition: margin $breakTransition, width $breakTransition, height $breakTransition;

						@media #{$break1200} {
							margin: 0 auto;
							width: 60%;
							height: 60%;
						}

						@media #{$break600} {
							width: 100%;
							height: 100%;
						}
					}

					.iconLabel {
						font-size: 10px;

						@media #{$break600} {
							display: none;
						}
					}
				}

				.amIconDesktop {
					display: block;

					@media #{$break1200} {
						display: none;
					}
				}

				.amIconMobile {
					display: none;

					@media #{$break1200} {
						display: block;
					}
				}
			}

			.amLinkLabel {
				margin-left: $adminMenuLinkHeight - ($adminMenuLinkHeightSpacing / 2);
				line-height: $adminMenuLinkHeight;

				@media #{$break1200} {
					display: none;
				}
			}
		}
	}

}

.AdminPage {

	.adminButtonDesktop.adminButton {
		display: inline-block;

		@media #{$break768} {
			display: none;
		}
	}

	.adminButtonMobile.adminButton {
		display: none;

		@media #{$break768} {
			display: inline-block;
		}
	}

	// Should look the same as disabled;
	// Use for "fake disabled" where you still need to grab the click
	.adminButtonDisabled.adminButton {
		cursor: default;
		background: $adminDisabledBackground;
		color: $adminDisabledColor;

		&:hover {
			background: $adminDisabledBackgroundHover;
		}
	}

	.adminButton {
		display: inline-block;
		height: $adminSectionButtonHeight;
		line-height: $adminSectionButtonHeight;
		color: $adminFormAccentText;
		background: $adminFormAccent;
		border: none;
		padding: 0 $adminFormSpacing;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		transition: background $hoverTransition, height $breakTransition, line-height $breakTransition, padding $breakTransition;

		&:hover {
			background: $adminFormAccentHover;
		}

		&:disabled {
			cursor: default;
			background: $adminDisabledBackground;
			color: $adminDisabledColor;

			&:hover {
				background: $adminDisabledBackgroundHover;
			}
		}

		@media #{$break1024} {
			height: $adminSectionButtonHeightMobile;
			line-height: $adminSectionButtonHeightMobile;
			padding: 0 $adminFormSpacing;
		}
	}

	.AdminTitle {

		position: relative;
		color: $adminPageColor;
		margin: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		transition: margin $breakTransition;

		@media #{$break1024} {
			margin: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		}

		@media #{$break768} {
			margin: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		}

		.adminTitleFull {
			
			font-family: $fontLato;
			font-size: 32px;
			transition: font-size $breakTransition;

			@media #{$break1200} {
				font-size: 28px;
			}

			@media #{$break1024} {
				display: none;
			}

			.titleBreadcrumbLink {
				color: $adminPageColor;
			}

			.titleBreadcrumbChevron {
				color: $adminMenuLinkBackgroundSelected;
				font-family: $fontLato;
				font-weight: 300;
				margin: 0 5px;
			}

			.titleMain {

			}
		}

		.adminTitleMobile {

			display: none;
			position: relative;
			font-family: $fontLato;
			font-size: 28px;
			transition: font-size $breakTransition;

			@media #{$break1024} {
				display: block;
			}

			@media #{$break768} {
				font-size: 20px;
			}

			.atBack {

				display: inline-block;
				vertical-align: middle;
				padding: 0 0.5*$adminPageHorizontalSpacing 0 0;

				.atBackArrow {
					display: inline-block;
					vertical-align: middle;
					color: $adminMenuLinkBackgroundSelected;
					font-family: $fontLato;
					font-weight: 300;
					font-size: 36px;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 24px;
					}		
				}

				.atBackLabel {
					display: inline-block;
					vertical-align: middle;
					font-family: $fontLato;
					font-size: 20px;
					text-transform: uppercase;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 16px;
					}
				}
			}

			.atMobileTitle.noBack {
				border-left: none;
				padding: 0;
			}

			.atMobileTitle {
				display: inline-block;
				vertical-align: middle;
				border-left: 1px solid $adminMenuLinkBackgroundSelected;
				padding: 0 0 0 0.5*$adminPageHorizontalSpacing;
			}
		}

		.titleOverview {

			.overviewIconWrapper {

				position: absolute;
				top: calc(50% - #{0.5 * $adminTitleOverviewIconHeight});
				right: 0;
				width: $adminTitleOverviewIconHeight;
				height: $adminTitleOverviewIconHeight;

				cursor: pointer;
				border: $adminTitleOverviewIconBorderWidth solid $adminPageColor;
				border-radius: 50%;

				.overviewIcon {
					font-size: 18px;
					font-weight: 400;
				}
			}

			.overviewDrawerWrapper {

				position: absolute;
				overflow: hidden;
				top: 100%;
				right: 0;
				height: auto;
				max-height: 0;
				max-width: $adminTitleOverviewWidth;
				z-index: 10;
				transition: max-height $slideFastTransition;

				&.open {
					max-height: 500px;
					transition: max-height $slideTransition;
				}

				.overviewDrawerLiner {
					background: $adminBlockBackground;
					border: 1px solid $adminPageColor;
					padding: $MARGIN_L;
				}
			}
		}
	}

	.bottomPadding.adminGenericBlock {
		padding-bottom: $adminPageHorizontalSpacing;

		@media #{$break768} {
			padding-bottom: 0.5*$adminPageHorizontalSpacing;
		}
	}

	.adminGenericBlock {

		overflow: auto;
		margin: 0 0 $adminPageHorizontalSpacing 0;
		padding: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		background: $adminBlockBackground;
		transition: padding $breakTransition;

		@media #{$break768} {
			padding: 0.5*$adminPageHorizontalSpacing 0.5*$adminPageHorizontalSpacing 0 0.5*$adminPageHorizontalSpacing;
		}
	}

	.adminIconRow {

		margin: $adminFormSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		padding: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;
		border: 1px solid $adminPageColor;
		background: $adminBlockBackground;
		text-align: center;
		transition: padding $breakTransition;

		@media #{$break768} {
			padding: 0.5*$adminPageHorizontalSpacing 0.5*$adminPageHorizontalSpacing 0 0.5*$adminPageHorizontalSpacing;
		}

		.adminIconWrapper {

			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $adminIconWidth;
			margin: 0 $adminPageHorizontalSpacing $adminPageHorizontalSpacing 0;
			transition: margin $breakTransition, width $breakTransition;

			@media #{$break768} {
				width: $adminIconWidthMobile;
				margin: 0 0.5*$adminPageHorizontalSpacing 0.5*$adminPageHorizontalSpacing 0;
			}

			&:hover {

				.adminIcon {

					svg {
						fill: $adminPageColorHover;
					}
				}
			}

			.adminIcon {

				position: relative;
				width: 100%;

				svg {
					fill: $adminPageColor;
				}

				.iconSVGWrapper{
					height: $adminIconHeight;
					transition: height $breakTransition;

					@media #{$break768} {
						height: $adminIconHeightMobile;
					}
				}

				.iconLabel {

					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 12px;
					}
				}
			}
		}
	}

	.adminBody {
		margin: $adminFormSpacing $adminPageHorizontalSpacing 0 $adminPageHorizontalSpacing;

		.adminForm {

			position: relative;
			overflow: auto;
			background: $adminBlockBackground;
			max-width: $adminFormMaxWidth - 2*$adminFormSpacing;
			padding: $adminFormSpacing;
			margin: 0 0 3*$adminFormSpacing 0;

			.present.adminFormError {
				height: 3.5*$adminFormSpacing;
			}

			.adminFormError {
				color: $errorRed;
				font-weight: 700;
				font-size: 16px;
				height: 0;
				text-align: left;
				overflow: hidden;
				transition: height $toggleTransition;
			}

			.adminUserLabelWrapper {

				margin: 0 0 $adminPageHorizontalSpacing 0;

				.adminUserLabel {
					display: inline-block;
					vertical-align: top;
					font-size: 20px;
					font-weight: 400;

					.adminUserLabelLink {
						position: relative;
						color: $adminFormAccent;
						cursor: pointer;
						font-size: 12px;
						font-weight: 400;
						transition: color $hoverTransition;

						&:hover {
							color: $adminFormAccentHover;
						}
					}
				}

				.adminUserValue {

					display: inline-block;
					vertical-align: top;
					margin: 0 0 0 $adminFormSpacing;

					.adminUserName {
						font-size: 14px;
						font-weight: 400;
					}

					.adminUserEmail {
						font-size: 12px;
						font-weight: 300;
					}
				}
			}

			.adminFormPending {

				.adminFormPendingScreen {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $adminBlockBackground;
					opacity: 0.6;
				}

				.adminFormPendingWrapper {
					position: absolute;
					top: calc(50% - #{0.5*$adminFormLoadingIconWidth});
					left: calc(50% - #{0.5*$adminFormLoadingIconWidth});
					width: $adminFormLoadingIconWidth;
					height: $adminFormLoadingIconWidth;

					svg {
						fill: $adminFormLoadingIconColor;
					}
				}
			}

			.adminFormTitle {

				position: relative;
				margin: 0 0 2*$adminFormSpacing 0;

				.afTitleWrapper {
					font-size: 22px;
					font-weight: 700;
					text-transform: uppercase;
					padding: 0 0 0.5*$adminFormSpacing 0;
					border-bottom: 2px solid $adminFormAccent;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 18px;
					}
				}

				.afTitleAction {
					position: absolute;
					top: 0;
					right: 0;
					font-size: 14px;
					font-weight: 700;
					padding: 0.5*$adminFormSpacing 0;
					cursor: pointer;
					color: $adminPageColor;
					transition: color $hoverTransition;

					@media #{$break1024} {
						position: relative;
						top: unset;
						right: unset;

						width: auto;
						text-align: center;
						margin: 0.5*$adminFormSpacing 0 0 0;
						color: $adminPageColorHover;
					}
				}
			}

			.adminCollapseable.hidden {
				max-height: 0;
				margin-bottom: 0;
			}

			.adminCollapseable {
				height: auto;
				max-height: 400px;
				overflow: hidden;
				transition: max-height $toggleTransition, margin-bottom $toggleTransition;
			}

			.adminLoadableSection {

				position: relative;

				.adminLoadableLoading.loading {
					display: block;
				}

				.adminLoadableLoading {
					display: none;
						
					.adminLoadableScreen {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: $adminBlockBackground;
						opacity: 0.6;
					}

					.adminLoadableWrapper {
						position: absolute;
						top: calc(50% - #{0.5*$adminFormLoadingIconWidth});
						left: calc(50% - #{0.5*$adminFormLoadingIconWidth});
						width: $adminFormLoadingIconWidth;
						height: $adminFormLoadingIconWidth;

						svg {
							fill: $adminFormLoadingIconColor;
						}
					}
				}
			}

			.opening.adminComponentSupplementWrapper {
				
				

				.adminComponentSupplementBody {

					overflow: hidden;

					.AdminComponentSupplement {
						overflow: hidden;
					}
				}
			}

			.open.adminComponentSupplementWrapper {
				
				max-height: $adminFormSupplementMaxHeight;
				transition: max-height $slideTransition;

				.adminComponentSupplementBody {

					max-height: $adminFormSupplementMaxHeight;
					transition: max-height $slideTransition;

					.AdminComponentSupplement {
						
					}
				}
			}

			.adminComponentSupplementWrapper {
				overflow: hidden;
				position: relative;
				max-width: $adminFormFieldMaxWidth;
				margin: 0 0 2*$adminFormSpacing 0;
				height: auto;
				max-height: 0;
				transition: max-height $slideFastTransition;

				.adminComponentSupplementBody {
					overflow: auto;
					height: auto;
					max-height: 0;
					transition: max-height $slideFastTransition;

					.AdminComponentSupplement {
						
					}
				}
			}

			.adminFieldWrapper {

				position: relative;
				max-width: $adminFormFieldMaxWidth;
				margin: 0 0 2*$adminFormSpacing 0;

				.adminFieldLabel.adminRequired {

					&:after {
						content: '*';
						color: $adminFormAccent;
						font-size: 16px;
					}
				}

				.adminFieldLabel.adminOptional {
					/* Only needed when aligned next to required field */
					&:after {
						content: '*';
						color: $adminFormAccent;
						font-size: 16px;
						opacity: 0;
					}
				}

				.adminFieldLabel {
					color: $adminPageColor;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 0.5*$adminFormSpacing 0;
					user-select: none;
				}

				.adminFieldSmallNotice {
					margin: 3*$adminFormSpacing 0;
					font-size: 20px;
					font-weight: 300;
					text-align: left;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 16px;
					}
				}

				.adminFieldMediumNotice {
					margin: 5*$adminFormSpacing 0;
					font-size: 24px;
					font-weight: 300;
					text-align: center;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 18px;
					}
				}

				.adminInputWrapper.halfWidth {
					display: inline-block;
					vertical-align: top;
					width: calc(50% - #{0.5*$adminFormSpacing});
					margin-right: $adminFormSpacing;

					&:last-child {
						margin-right: 0;
					}
				}

				.adminInputWrapper {

					.rgbWrapper {

						position: relative;

						.rgbOverlay {

							position: absolute;
							top: 0;
							left: 4*$adminFormSpacing;
							height: 100%;
							width: 5*$adminFormSpacing;
							cursor: default;
							user-select: none;
							pointer-events: none;

							display: flex;
							align-items: center;
							justify-content: center;

							font-size: 22px;
							font-weight: 300;
						}

						.error.rgbSwatch {
							border-left: 1px solid $errorRed;
							border-top: 1px solid $errorRed;
							border-bottom: 1px solid $errorRed;
							border-right: 1px solid $adminFormBorderColor;
						}

						.rgbSwatch {
							position: absolute;
							top: 0;
							left: 0;
							width: 4*$adminFormSpacing;
							bottom: 0;
							border: 1px solid $adminFormBorderColor;

							.rgbInputCover {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								height: 100%;

								opacity: 0;
								cursor: pointer;
							}
						}
					}

					.urlWrapper {

						position: relative;

						.urlOverlay {

							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 5*$adminFormSpacing;
							cursor: default;
							user-select: none;
							pointer-events: none;

							display: flex;
							align-items: center;
							justify-content: center;

							font-size: 22px;
							font-weight: 400;
						}
					}

					.currencyWrapper {

						position: relative;

						.currencyOverlay {

							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 5*$adminFormSpacing;
							cursor: default;
							user-select: none;
							pointer-events: none;

							display: flex;
							align-items: center;
							justify-content: center;

							font-size: 22px;
							font-weight: 300;
						}
					}

					.percentWrapper {

						position: relative;

						.percentOverlay {

							position: absolute;
							top: 0;
							right: 0;
							height: 100%;
							width: 4*$adminFormSpacing;
							cursor: default;
							user-select: none;
							pointer-events: none;

							display: flex;
							align-items: center;
							justify-content: center;

							font-size: 22px;
							font-weight: 300;
						}
					}

					.subtleSupplement.fieldSupplement {
						font-weight: 300;
					}					

					.fieldSupplement {

						margin: 0.5*$adminFormSpacing 0;
						
						.plUrlLabel {
							font-weight: 400;
							margin: 0 $adminFormSpacing 0 0;
						}

						.plUrlValue {
							font-weight: 300;
						}

						.productCharacterCount {
							font-weight: 300;
							text-align: right;

							.countNum {
								font-weight: 300;
							}

							.countSlash {
								margin: 0 1px;
							}

							.countCopy {
								margin: 0 0 0 0.25*$adminFormSpacing;
							}
						}
					}

					.adminInputReplacement {
						font-size: 18px;
						font-weight: 300;
					}

					.adminInputButtonWrapper {

						height: $adminSectionButtonHeight;

						.adminInputButton {
							$inRowPaddingHorz: 4*$adminFormSpacing - 2px;
							$inRowPaddingVert: $adminFormSpacing - 2px;

							color: $adminFormAccent;
							background: $adminBlockBackground;
							cursor: pointer;
							margin: 0;
							border: 2px solid $adminFormAccent;
							padding: $inRowPaddingVert $inRowPaddingHorz;
							transition: color $hoverTransition, background $hoverTransition;

							&:hover {
								color: $adminBlockBackground;
								background: $adminFormAccent;
							}
						}
					}

					.adminDropdownWrapper {
						position: relative;

						.adminDropdownSelect {

							height: auto;
							border-bottom: none;

							.dropdownArrowWrapper {
								right: $adminFormSpacing;
								bottom: 1.5*$adminFormSpacing;
							}
						}

						.adminDropdownArrowWrapper {

							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							width: $adminSectionDropdownHeight - 0.5*$adminFormSpacing;

							pointer-events: none;
							background: none;

							.adminArrow {
								position: absolute;
								top: 1.5*$adminFormSpacing;
								left: 0;
								width: 0;
								height: 0;

								border-top: 1.5*0.25*$adminSectionDropdownHeight solid $adminPageColor;
								border-bottom: none;
								border-left: 0.25*$adminSectionDropdownHeight solid transparent;
								border-right: 0.25*$adminSectionDropdownHeight solid transparent;
							}
						}	
					}

					.adminRadioWrapper {

						.adminRadioElementWrapper {

							margin: 0 0 $adminSectionRadioSpacing 0;

							.adminRadioElement {

								position: relative;
								height: $adminSectionRadioHeight;

								.adminRadioInputWrapper {

									position: absolute;
									top: 0;
									left: 0;
									width: $adminSectionRadioHeight;
									height: 100%;
									cursor: pointer;

									.adminRadioInput {
										position: absolute;
										top: $adminSectionRadioSpacing;
										left: $adminSectionRadioSpacing;
										right: $adminSectionRadioSpacing;
										bottom: $adminSectionRadioSpacing;
									}
								}

								.adminRadioLabelWrapper.disabled {
									cursor: default;
								}

								.adminRadioLabelWrapper {

									cursor: pointer;
									margin: 0 0 0 ($adminSectionRadioHeight + 2*$adminSectionRadioSpacing);

									.adminRadioLabel {
										display: inline-block;
										vertical-align: top;
										line-height: $adminSectionRadioHeight;
										font-size: 18px;
									}
								}
							}
						}
					}

					input.currencyInput {
						text-align: right;
					}

					input.quantityInput {
						text-align: right;
					}

					input.percentInput {
						text-align: right;
						width: calc(100% - 6*#{$adminFormSpacing} - 2px);
						padding: $adminFormSpacing 4*$adminFormSpacing $adminFormSpacing 2*$adminFormSpacing;
					}

					input.urlInput {
						width: calc(100% - 5*#{$adminFormSpacing} - 2px);
						padding: $adminFormSpacing 2*$adminFormSpacing $adminFormSpacing 3*$adminFormSpacing;
					}

					input.rgbInput {
						width: calc(100% - 10*#{$adminFormSpacing} - 2px);
						padding: $adminFormSpacing 2*$adminFormSpacing $adminFormSpacing 8*$adminFormSpacing;
					}

					input {
						border: 1px solid $adminFormBorderColor;
						width: calc(100% - 4*#{$adminFormSpacing} - 2px);
						font-size: 20px;
						padding: $adminFormSpacing 2*$adminFormSpacing;
						margin: 0;
					}

					textarea {
						border: 1px solid $adminFormBorderColor;
						width: calc(100% - 4*#{$adminFormSpacing} - 2px);
						height: $adminFormTextareaHeight;
						font-size: 20px;
						padding: $adminFormSpacing 2*$adminFormSpacing;
						margin: 0;
						resize: none; 
					}

					select.error {
						border: 1px solid $errorRed;
					}

					select {
						border: 1px solid $adminFormBorderColor;
					    width: 100%;
					    font-size: 20px;
					    padding: $adminFormSpacing 2*$adminFormSpacing;
					    margin: 0;
					    appearance: none;
					    transition: border $toggleTransition;

					    &:invalid {
					    	color: $placeholderGrey;
					    }

					    &:disabled {
					    	border: 1px solid $disabledColor;
					    }
					}

					.adminInputDate {

						position: relative;

						.error.datePickerWrapper {
							border: 1px solid $errorRed;
						}

						.datePickerWrapper {

							border: 1px solid $adminFormBorderColor;
							transition: border $toggleTransition;

							input {
								border: none;
							    width: calc(100% - 6*#{$adminFormSpacing} - 2px);
							    font-size: 20px;
							    padding: $adminFormSpacing 4*$adminFormSpacing $adminFormSpacing 2*$adminFormSpacing;
							    margin: 0;
							}

							.react-datepicker__day--selected,
							.react-datepicker__day--keyboard-selected {
								color: $adminFormAccentText;
								background: $adminFormAccent;
							}
						}

						.calendarOverlay {
							position: absolute;
							top: $adminFormSpacing;
							right: $adminFormSpacing;
							bottom: $adminFormSpacing;
							width: 2*$adminFormSpacing;
							pointer-events: none;

							svg {
								fill: $adminFormBorderColor;
							}
						}
					}

					.adminInputTime.fieldError {
						border: 1px solid $errorRed;
					}

					.adminInputTime {

						cursor: text;
						border: 1px solid $adminFormBorderColor;
						padding: 0 0 0 $adminFormSpacing;
						transition: border $toggleTransition;

						.aitLiner {

							text-align: right;

							.aitHourWrapper {

							}

							.aitDelimiterWrapper {

								position: relative;
								height: 100%;
								width: 0.5$adminFormSpacing;
								height: 3*$adminFormSpacing;
								font-size: 20px;
								font-weight: 700;

								.delimWrapper {
									bottom: 0.25*$adminFormSpacing;
								}
							}

							.aitMinutesWrapper {

							}

							.aitPostScriptWrapper {

								.aitPostSelect {
									height: unset;
									border-bottom: none;

									select {
										border: none;
										padding-left: 0;
										padding-right: 3*$adminFormSpacing;
									}
								}
							}

							.aitElement {

								display: inline-block;
								vertical-align: middle;

								input {
									border: none;
									width: 2.5*$adminFormSpacing;
									padding: $adminFormSpacing $adminFormSpacing;
									text-align: right;
								}
							}
						}
					}

					.adminInputWeight.fieldError {
						border: 1px solid $errorRed;
					}

					.adminInputWeight {

						cursor: text;
						border: 1px solid $adminFormBorderColor;
						padding: 0 0 0 $adminFormSpacing;
						transition: border $toggleTransition;

						.aiwLiner {

							position: relative;

							.aiwInputElement {

							}

							.aiwPostScriptWrapper {

								position: absolute;
								top: 0;
								right: $adminFormSpacing;
								bottom: 0;
								width: auto;

								.aiwPostSelect {
									height: unset;
									border-bottom: none;

									select {
										color: $adminFormBorderColor;
										border: none;
										padding-left: 0;
										padding-right: 3*$adminFormSpacing;
										text-align: right;
									}
								}
							}

							.aiwElement {

								display: inline-block;
								vertical-align: middle;

								input {
									border: none;
									// width: 2.5*$adminFormSpacing;
									padding: $adminFormSpacing $adminFormSpacing;
									text-align: left;
								}
							}
						}
					}

					.adminInputProductLineWrapper, .adminDropdown {

						position: relative;

						.aiDropdownArrowWrapper {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							width: $adminFormProductLineDropdownHeight - $adminFormSpacing;

							pointer-events: none;
							background: none;

							.aiArrow {
								position: absolute;
								top: 1.5*$adminFormSpacing;
								left: 0;
								width: 0;
								height: 0;

								border-top: (1.5 * 0.25 * $adminFormProductLineDropdownHeight) solid $adminPageColor;
								border-bottom: none;
								border-left: (0.25 * $adminFormProductLineDropdownHeight) solid transparent;
								border-right: (0.25 * $adminFormProductLineDropdownHeight) solid transparent;
							}
						}

						.aiProductLineLoading {

							overflow: auto;
							background: $adminAutocompleteHover;

							.aiProductLineLoadingIcon {
								position: absolute;
								top: $adminFormSpacing;
								left: $adminFormSpacing;
								width: $adminFormProductLineDropdownHeight - 2*$adminFormSpacing;
								height: $adminFormProductLineDropdownHeight - 2*$adminFormSpacing;

								svg {
									fill: $adminFormAccent;
								}
							}

							.aiProductLoadingLabel {
								color: $adminFormAccent;
								height: $adminFormProductLineDropdownHeight;
								line-height: $adminFormProductLineDropdownHeight;
								margin: 0 $adminFormSpacing 0 $adminFormProductLineDropdownHeight;
								text-transform: uppercase;
								font-size: 14px;
								font-weight: 700;
							}
						}

						.aiProductLineEmpty {

							select {
								border-color: $disabledColor;
								color: $disabledColor;
							}

							.aiDropdownArrowWrapper {
								.aiArrow {
									border-top-color: $disabledColor;
								}
							}
						}

						.aiProductLineDropdown {

							position: relative;

							select {
								height: $adminFormProductLineDropdownHeight;
							}
						}
					}
				}

				.adminInputAutocomplete.active {
					max-height: 1000px;
					transition: max-height $slideTransition;
				}

				.adminInputAutocomplete {

					position: absolute;
					overflow: hidden;
					left: 0;
					right: 0;
					top: calc(100% - 1px);
					height: auto;
					max-height: 0;
					z-index: $zUserMenuLayer;
					// No transition intentionally, should close instantly

					border-left: 1px solid $adminPageColor;
					border-right: 1px solid $adminPageColor;
					background: $adminBlockBackground;		

					.adminInputAutocompleteWrapper {
						border-bottom: 1px solid $adminPageColor;

						.autocompleteNoResultsWrapper {

							padding: $adminFormSpacing;
							text-align: center;
							
							.noResultsCopy {
								margin: 0 0 $adminFormSpacing 0;
							}

							.noResultsNeedle {
								margin: 0 0 $adminFormSpacing 0;
								font-size: 18px;
								font-weight: 700;
							}			
						}

						.autocompleteSuggestion.active {
							background: $adminAutocompleteHover;
						}

						.autocompleteSuggestion {
							padding: 0.5*$adminFormSpacing $adminFormSpacing;
							font-size: 18px;
							cursor: pointer;
							transition: background $hoverTransition;

							&:hover {
								background: $adminAutocompleteHover;
							}

							.autocompleteSuggestionValue {

								.suggestStrong {
									font-weight: 700;
								}
							}

							.autocompleteSuggestionValueSecondary {

								font-size: 14px;
								font-weight: 300;

								.suggestStrong {
									font-weight: 400;
								}
							}
						}
					}
				}
			}

			.adminActionRow {

				text-align: right;
				margin: 2*$adminFormSpacing 0 0 0;

				.adminAction.adminLeft {
					float: left;
					margin-left: 0;
				}

				.adminAction.adminActionBack {

					color: $adminFormAccentText;
					background: $adminFormAccent;
					
					&:hover {
						background: $adminFormAccentHover;
					}

					@media #{$break600} {
						display: block;
						padding: 1.5*$adminFormSpacing 0;
						margin: $adminFormSpacing 0 0 0;
						width: 100%;

						border: 2px solid $adminFormAccent;
						color: $adminFormAccent;
						background: $adminMenuColor;

						&:hover {
							color: $adminMenuColor;
							border: 2px solid $adminFormAccentHover;
						}
					}
				}

				.adminAction.adminActionSave {

					color: $adminFormAccentText;
					background: $adminFormAccent;
					
					&:hover {
						background: $adminFormAccentHover;
					}

					@media #{$break600} {
						display: block;
						padding: 1.5*$adminFormSpacing 0;
						margin: 0;
						width: 100%;
					}
				}

				.adminAction.adminActionDelete {
					
					$aaRowPaddingHorz: 4*$adminFormSpacing - 2px;
					$aaRowPaddingVert: $adminFormSpacing - 2px;

					color: $errorRed;
					background: $adminBlockBackground;
					margin: 0;
					border: 2px solid $errorRed;
					float: left;
					padding: $aaRowPaddingVert $aaRowPaddingHorz;
					transition: color $hoverTransition, background $hoverTransition, border $hoverTransition;

					&:hover {
						color: $adminBlockBackground;
						background: $errorRed;
					}

					@media #{$break600} {
						display: none;
					}
				}

				.adminAction.adminActionCancel {

					color: $adminPageColor;
					background: $adminBlockBackground;
					margin: 0;

					&:hover {
						background: $adminBlockBackgroundHover;
					}

					@media #{$break600} {
						display: none;
					}
				}

				.adminAction {
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					border: none;
					margin: 0 0 0 $adminFormSpacing;
					padding: $adminFormSpacing 4*$adminFormSpacing;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

					@media #{$break1024} {
						padding: $adminFormSpacing 3*$adminFormSpacing;
					}

					&:disabled {
						cursor: default;
					}
				}
			}
		}

		.adminSection {
			position: relative;
			background: $adminBlockBackground;
			max-width: $adminFormMaxWidth - 2*$adminFormSpacing;
			padding: $adminFormSpacing;
			margin: 0 0 3*$adminFormSpacing 0;

			.adminForm {
				margin: 0 0 0 0;
				padding: 0 0 0 0;
			}

			.adminSectionTitle {

				position: relative;

				.asTitleWrapper {
					font-size: 22px;
					font-weight: 700;
					text-transform: uppercase;
					padding: 0 5*$adminFormSpacing 0.5*$adminFormSpacing 0;
					border-bottom: 2px solid $adminFormAccent;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 18px;
					}

					.asTitleValue {
						display: inline-block;
						vertical-align: bottom;
					}

					.asTitle {
						display: inline-block;
						vertical-align: bottom;
						font-size: 20px;
						font-weight: 300;
						margin: 0 0 0 $adminFormSpacing;
					}
				}

				.asToggleWrapper {
					position: absolute;
					top: -5px;
					right: 0;
					width: $adminSectionToggleWidth;
					height: $adminSectionToggleWidth;
					cursor: pointer;
					z-index: 1;

					display: flex;
					justify-content: center;
					align-items: center;

					font-size: 36px;
					font-weight: 100;
					user-select: none;
				}

				.asTitleActionsWrapper {

					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					cursor: pointer;
					// z-index: 1;

					.asTitleActions {

						.asTitleActionElement {
							border-top: none;
							border-right: none;
							border-bottom: 1px solid $adminFormBorderColor;
							border-left: none;
						}
					}
				}
			}

			.adminSectionDisabled {
				color: $adminDisabledColor;
				text-align: center;
				margin: 2*$adminFormSpacing 0;
				font-size: 20px;
				font-weight: 700;
			}

			.adminSectionPending {

				.adminSectionPendingScreen {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $adminBlockBackground;
					opacity: 0.6;
				}

				.adminSectionPendingWrapper {
					position: absolute;
					top: calc(50% - #{0.5*$adminFormLoadingIconWidth});
					left: calc(50% - #{0.5*$adminFormLoadingIconWidth});
					width: $adminFormLoadingIconWidth;
					height: $adminFormLoadingIconWidth;

					svg {
						fill: $adminFormLoadingIconColor;
					}
				}
			}

			.adminSectionBody.expandableBody.expanded {
				max-height: $adminSectionMaxHeight;
				transition: max-height $slideTransition;
			}

			.adminSectionBody.expandableBody {
				max-height: 0;
			}

			.adminSectionBody {
				position: relative;
				overflow: hidden;
				height: auto;
				transition: max-height $slideFastTransition;

				.adminSectionBodyWrapper {

					margin: 0.5*$adminFormSpacing 0 0 0;

					.adminViewWrapper {

						text-align: left;
						margin: 2*$adminFormSpacing 0 0 0;

						.adminViewItem.flexHeight {
							height: auto;
						}

						.adminViewItem.tallItem {
							height: $adminViewItemHeightTall;
						}

						.adminViewItem.hidden {
							height: 0;
							margin: 0;
						}

						.adminViewItem.adminDescriptionRow {
							width: calc(100% - 2*#{$adminFormSpacing});
						}

						.adminViewItem {

							position: relative;
							display: inline-block;
							vertical-align: top;
							width: calc(50% - 2*#{$adminFormSpacing});
							height: $adminViewItemHeight;
							margin: 0 $adminFormSpacing $adminFormSpacing $adminFormSpacing;
							transition: width $breakTransition;

							@media #{$break600} {
								display: block;
								width: auto;
								margin: 0 $adminFormSpacing $adminFormSpacing $adminFormSpacing;
							}

							.adminViewLabel.adminRequired {

								&:after {
									content: '*';
									color: $adminFormAccent;
									font-size: 16px;
								}
							}

							.adminViewLabel {
								height: $adminViewItemLabelHeight;
								line-height: $adminViewItemLabelHeight;
								font-size: 15px;
								font-weight: 700;
								text-transform: uppercase;
							}

							.adminViewValue.nullValue {
								font-style: italic;
							}

							.adminViewValue {
								font-size: 18px;
								font-weight: 300;

								.adminViewValueRow {
									margin: 0.5*$adminFormSpacing 0 0 0;
								}

								.adminViewValueDate {

								}

								.adminViewValueTime {
									font-size: 14px;
								}

								.adminViewColorSwatch {
									display: inline-block;
									vertical-align: middle;
									width: 2*$adminFormSpacing;
									height: 2*$adminFormSpacing;
									border: 1px solid $adminFormBorderColor;
									border-radius: 50%;
								}

								.adminViewColorValue {
									display: inline-block;
									vertical-align: middle;
									margin: 0 0 0 $adminFormSpacing;
									line-height: 2*$adminFormSpacing;
								}

								.interactionWrapper {

									.interactionValue {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										margin: 0 $adminFormSpacing 0 0;
									}

									.interactionAction {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										font-size: 14px;
										font-weight: 700;
										cursor: pointer;
										color: $adminFormAccent;
										transition: color $hoverTransition;

										&:hover {
											color: $adminFormAccentHover;
										}
									}
								}
							}
						}

						.adminViewHr {
							margin: $adminFormSpacing auto 3*$adminFormSpacing auto;
							width: 80%;
							height: 2px;
							background: $adminFormAccent;
						}

						.adminViewVr {
							position: absolute;
							top: 10%;
							right: 0;
							width: 2px;
							height: 80%;
							background: $adminViewVrColor;

							@media #{$break1024} {
								top: unset;
								bottom: 0;
								right: 10%;
								width: 80%;
								height: 2px;
							}
						}

						.adminViewButtonWrapper {
							text-align: right;
						}
					}
				}
			}
		}
	}

	.adminActions {

		margin: $adminPageHorizontalSpacing $adminPageHorizontalSpacing 2*$adminPageHorizontalSpacing $adminPageHorizontalSpacing;
		max-width: $adminFormMaxWidth;
		text-align: right;

		.disabled.blockButton, .disabled.deleteButton {
			cursor: default;
			color: $disabledColor;
			background: $disabledBackground;
			border-color: $disabledColor;

			&:hover {
				color: $disabledColor;
				background: $disabledBackground;
				border-color: $disabledColor;
			}
		}

		.deleteButton, .blockButton {
					
			position: relative;
			color: $errorRed;
			background: $adminBlockBackground;
			cursor: pointer;
			margin: 0;
			border: 2px solid $errorRed;
			float: left;
			height: $adminSectionButtonHeight;
			line-height: $adminSectionButtonHeight;
			padding: 0 $adminPageHorizontalSpacing;
			transition: color $hoverTransition, background $hoverTransition, border $hoverTransition;

			&:hover {
				color: $adminBlockBackground;
				background: $errorRed;
			}

			@media #{$break1024} {
				height: $adminSectionButtonHeightMobile;
				line-height: $adminSectionButtonHeightMobile;
				padding: 0 $adminFormSpacing;
			}

			@media #{$break600} {
				display: none;
			}
		}
	}
}