

@use '../../../../style/import' as *;




//////////////////
//   Checkout   //
//////////////////


// Titan colors
$checkoutStickyTop: $TITAN_HEADER_HEIGHT;
$checkoutStickyTopMobile: $TITAN_HEADER_HEIGHT_MOBILE;
$checkoutNoticeHeight: $TITAN_HEADER_NOTICE_HEIGHT;

// Theme colors
$checkoutAccent: $THEME_PRIMARY_COLOR;
$checkoutAccentColor: $THEME_PRIMARY_COLOR_TEXT;
$checkoutAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$checkoutBackground: $THEME_BACKGROUND;
$checkoutColor: $THEME_TEXT_COLOR;
$checkoutColorLight: $THEME_TEXT_COLOR_LIGHT;

// General variables
$checkoutMargin: 10px;
$checkoutMaxWidth: 1200px;
$checkoutSidebarBlockWidth: 360px;
$checkoutLoadingHeight: 60px;


.Checkout {

	position: relative;
	margin: $checkoutMargin auto 2*$checkoutMargin auto;
	width: $fixedWidth;
	max-width: $fixedMaxWidth;

	.checkoutWrapper {

		direction: rtl;

		@media #{$break1024} {
			display: ltr;
		}

		.notified.checkoutSidebarWrapper {
			top: $checkoutStickyTop + $checkoutNoticeHeight;
		}

		.checkoutSidebarWrapper {
			direction: ltr;
			display: inline-block;
			position: sticky;
			top: $checkoutStickyTop;
			width: $checkoutSidebarBlockWidth;
			transition: top $breakTransition;

			@media #{$break1024} {
				display: block;
				position: relative;
				top: unset;
				width: 100%;
			}
		}

		.checkoutBody {
			direction: ltr;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$checkoutSidebarBlockWidth});

			@media #{$break1024} {
				display: block;
				width: 100%;
			}

			.checkoutTitle {
				user-select: none;
				color: $checkoutColor;
				font-family: $fontLato;
				font-weight: 700;
				font-size: 24px;
				margin: 0 $checkoutMargin $checkoutMargin 0;
			}
		}
	}
}


/////////////////////////
//   CheckoutSidebar   //
/////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$checkoutSidebarCartMaxHeight: 300px;
$checkoutSidebarCartTitleHeight: 30px;


.CheckoutSidebar {

	.csBlockHr {
		margin: 2*$checkoutMargin auto;
		height: 2px;
		width: 80%;
		background: $checkoutAccent;

		@media #{$break1024} {
			display: none;
		}
	}

	.csBlock {

		margin: 0 0 $checkoutMargin 0;

		.csBlockTitle {
			position: relative;
			user-select: none;
			color: $checkoutColor;
			font-family: $fontLato;
			font-weight: 700;
			font-size: 24px;
			margin: 0 $checkoutMargin $checkoutMargin $checkoutMargin;
			height: $checkoutSidebarCartTitleHeight;
			transition: margin $breakTransition;

			@media #{$break1024} {
				margin: 0 0 $checkoutMargin 0;
			}
		}

		.csBlockBody {
			padding: 0 $checkoutMargin;
			transition: padding $breakTransition;

			@media #{$break1024} {
				padding: 0 $checkoutMargin 0 0;
			}
		}
	}

	.csBlockCart.csBlock {

		.csBlockTitle {

			.csBlockTitleRhs {
				
				display: none;
				position: absolute;
				cursor: pointer;
				top: 0;
				right: 0;
				width: auto;
				height: 100%;

				@media #{$break1024} {
					display: block;
				}

				&:hover {

					.rhsValue {

					}

					.rhsToggle {
						color: $checkoutAccent;
					}
				}

				.rhsValue {
					position: absolute;
					top: 0;
					right: $checkoutSidebarCartTitleHeight;
					width: auto;
					height: $checkoutSidebarCartTitleHeight;
					line-height: $checkoutSidebarCartTitleHeight;
					white-space: nowrap;

					font-family: $fontLato;
					font-size: 20px;
					font-weight: 300;
				}

				.rhsToggle {
					position: absolute;
					top: 0;
					right: 0;
					width: $checkoutSidebarCartTitleHeight;
					height: $checkoutSidebarCartTitleHeight;

					display: flex;
					justify-content: center;
					align-items: center;

					font-size: 32px;
					font-weight: 300;
					color: $checkoutColor;
					transition: color $hoverTransition;
				}
			}
		}
		
		.open.csBlockBody {
			max-height: $checkoutSidebarCartMaxHeight;	
		}

		.csBlockBody {
			overflow-y: auto;
			max-height: $checkoutSidebarCartMaxHeight;
			transition: max-height $slideTransition;

			@media #{$break1024} {
				max-height: 0;	
			}
		}
	}

	.csBlockSummary.csBlock {

		@media #{$break1024} {
			display: none;
		}
	}

	.csBlockCoupon.csBlock {

		@media #{$break1024} {
			display: none;
		}
	}
}


//////////////////////
//   CheckoutStep   //
//////////////////////


// Titan colors
// None yet

// Theme colors
$checkoutStepLoadingIconColor: $THEME_PRIMARY_COLOR;
$checkoutStepTextLinkColor: $THEME_PRIMARY_COLOR;
$checkoutStepTextLinkColorHover: $THEME_PRIMARY_COLOR_HOVER;

// General variables
$checkoutStepLoadingIconWidth: 30px;
$checkoutStepLoadingScreenOpacity: 0.7;


.CheckoutStep {

	position: relative;

	.checkoutStepLiner {

	}

	.csTextLink {
		display: inline-block;
		cursor: pointer;
		color: $checkoutStepLoadingIconColor;
		font-size: 20px;
		font-weight: 700;
		transition: color $hoverTransition;

		&:hover {
			color: $checkoutStepTextLinkColorHover;
		}
	}

	.csLoading {

		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($checkoutBackground, $checkoutStepLoadingScreenOpacity);

		.csLoadingIconWrapper {
			position: absolute;
			top: calc(50% - #{$checkoutStepLoadingIconWidth}/2);
			left: calc(50% - #{$checkoutStepLoadingIconWidth}/2);
			width: $checkoutStepLoadingIconWidth;
			height: $checkoutStepLoadingIconWidth;

			svg {
				fill: $checkoutStepLoadingIconColor;
			}
		}
	}
}


///////////////////////////////
//   CheckoutStepComponent   //
///////////////////////////////


// Titan colors
$checkoutStepComponentRadioSelectedBackground: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$checkoutStepComponentCheckboxWidth: 20px;
$checkoutStepComponentErrorHeight: 50px;
$checkoutStepComponentRadioWidth: 20px;
$checkoutStepComponentRadioWidthMobile: 16px;
$checkoutStepComponentLoadingOpacity: 0.7;
$checkoutStepComponentLoadingWidth: 60px;


.CheckoutStepComponent {

	.checkoutFormSubheader {
		font-family: $fontLato;
		font-size: 20px;
		font-weight: 700;
		margin: 0 0 $checkoutMargin 0;
	}

	.singleColumn.checkoutStepInputForm {
		
		.checkoutFormInput.halfWidth {
			width: calc(50% - #{0.5*$checkoutMargin});
			margin: 0 0 $checkoutMargin 0;

			& + .checkoutFormInput.halfWidth { 
				margin: 0 0 $checkoutMargin $checkoutMargin;
			}
		}

		.checkoutFormInput.checkoutFormAction {
			margin: 0 0 $checkoutMargin 0;
		}

		.checkoutFormInput {
			width: 100%;
			margin: 0 0 $checkoutMargin 0;
		}
	}

	.checkoutStepInputForm {

		.present.checkoutFormServerError {
			height: $checkoutStepComponentErrorHeight;
			margin-bottom: $checkoutMargin;
		}

		.checkoutFormServerError {
			position: relative;
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			margin: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition, margin $toggleTransition;
		}

		.checkoutFormInput.checkoutFormAction {
			display: block;
			margin: 0 calc(0.5*$checkoutMargin) $checkoutMargin calc(50% + #{0.5*$checkoutMargin});

			@media #{$break768} {
				margin: 0 0 $checkoutMargin 0;
			}
		}

		.checkoutFormInput.halfWidth {
			width: calc(25% - #{$checkoutMargin});
			transition: unset;

			@media #{$break768} {
				width: calc(50% - #{0.5*$checkoutMargin});
			}

			& + .checkoutFormInput.halfWidth { 
				
				@media #{$break768} {
					margin: 0 0 $checkoutMargin $checkoutMargin;
				}
			}
		}

		.checkoutFormInput.fullWidth {
			width: calc(100% - #{$checkoutMargin});
			transition: width $breakTransition;

			@media #{$break768} {
				width: 100%;
			}
		}

		.checkoutFormInput {

			display: inline-block;
			vertical-align: top;
			width: calc(50% - #{$checkoutMargin});
			margin: 0 0.5*$checkoutMargin $checkoutMargin 0.5*$checkoutMargin;
			transition: width $breakTransition, margin $breakTransition;

			@media #{$break768} {
				width: 100%;
				margin: 0 0 $checkoutMargin 0;
			}

			.checkoutFormLabel.required {

				&:after {
					content: '*';
					color: $checkoutAccent;
					font-size: 14px;
				}
			}

			.checkoutFormLabel {
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0 0 0.5*$checkoutMargin 0;
				user-select: none;
			}

			.checkoutTextField {
				margin: 2*$checkoutMargin 0 0 0;
				font-size: 14px;
				transition: margin $breakTransition;

				@media #{$break768} {
					margin: 0 0 $checkoutMargin 0;
				}
			}

			.error.checkoutDropdownSelectWrapper {
				border-color: $errorRed;
			}

			.checkoutDropdownSelectWrapper {
				border: 1px solid $checkoutColorLight;
				padding: 0.5*$checkoutMargin $checkoutMargin;
				height: 2*$checkoutMargin;
				font-size: 18px;

				.checkoutDropdownSelect {
					border: none;
				}
			}

			.checkboxInputWrapper {

				position: relative;

				.checkboxInput {
					position: absolute;
					top: 0;
					left: 0;
					width: $checkoutStepComponentCheckboxWidth;
					height: $checkoutStepComponentCheckboxWidth;
				}

				.checkboxLabel {
					margin-left: $checkoutStepComponentCheckboxWidth + $checkoutMargin;
					font-size: 18px;
					line-height: $checkoutStepComponentCheckboxWidth;
				}
			}

			input {
				border: 1px solid $checkoutColorLight;
				width: calc(100% - 2*#{$checkoutMargin} - 2px);
				font-size: 18px;
				padding: 0.5*$checkoutMargin $checkoutMargin;
				height: 2*$checkoutMargin;
				margin: 0;
			}

			.forgotPasswordWrapper {
				text-align: right;
				margin: 0 0 $checkoutMargin 0;
			}

			.checkoutFormButton {
				width: 100%;
				color: $checkoutAccentColor;
				background: $checkoutAccent;
				border: none;
				padding: $checkoutMargin 0;
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 700;
				text-align: center;
				cursor: pointer;
				transition: background $hoverTransition;

				&:hover {
					background: $checkoutAccentHover;
				}
			}
		}
	}

	.checkoutStepRadioForm {

		.checkoutRadioLoadingWrapper {
			height: $checkoutLoadingHeight;
			text-align: center;

			.iconElement {
				height: $checkoutLoadingHeight;
				width: $checkoutLoadingHeight;
				margin: 0 auto;

				svg {
					fill: $checkoutAccent;
				}
			}
		}

		.radioNoOptions {
			font-size: 18px;
			font-weight: 300;
		}

		.radioOptionsWrapper {

			.radioOptionLabel {
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				margin: $checkoutMargin 0;

				&:first-child {
					margin: 0 0 $checkoutMargin 0;
				}
			}

			.selected.radioOptionElementWrapper {
				background: $checkoutStepComponentRadioSelectedBackground;
			}

			.radioOptionElementWrapper {

				cursor: pointer;
				margin: 0 0 $checkoutMargin 0;
				padding: 0.5*$checkoutMargin;

				.radioInputWrapper {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $checkoutStepComponentRadioWidth;
					height: $checkoutStepComponentRadioWidth;
					margin: 0.25*$checkoutMargin 0 0 0;
					transition: width $breakTransition, height $breakTransition;

					@media #{$break600} {
						width: $checkoutStepComponentRadioWidthMobile;
						height: $checkoutStepComponentRadioWidthMobile;
					}
				}

				.radioLabelWrapper {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$checkoutStepComponentRadioWidth} - #{$checkoutMargin});
					margin: 0 0 0 $checkoutMargin;
					transition: width $breakTransition, margin $breakTransition;

					@media #{$break600} {
						width: calc(100% - #{$checkoutStepComponentRadioWidthMobile} - #{$checkoutMargin});
						margin: calc($checkoutMargin/8) 0 0 0.5*$checkoutMargin;
					}

					.imgTitle.radioTitle {

						.imgTitleWrapper {

							display: inline-block;
							vertical-align: top;
							height: 1.25 * $checkoutStepComponentRadioWidth;

							img {
								display: block;
								height: 100%;
							}
						}
						
						.imgTitleLabel {
							display: inline-block;
							vertical-align: top;
							margin: 0 0 0 0.5*$checkoutMargin;
							line-height: $checkoutStepComponentRadioWidth;
						}
					}

					.radioTitle {
						font-size: 20px;
						transition: font-size $breakTransition;

						@media #{$break600} {
							font-size: 16px;
						}
					}

					.radioSubtitle {
						font-size: 18px;
						font-weight: 300;
						transition: font-size $breakTransition, font-weight $breakTransition;

						@media #{$break600} {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.active.checkoutSubmitScreen {
		display: block;
	}

	.checkoutSubmitScreen {

		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: $zAlert;
		background: rgba($checkoutBackground, $checkoutStepComponentLoadingOpacity);

		.submitScreenLiner {

			.submitIconWrapper {
				position: absolute;
				top: calc(50% - #{$checkoutStepComponentLoadingWidth});
				left: calc(50% - #{$checkoutStepComponentLoadingWidth/2});
				width: $checkoutStepComponentLoadingWidth;
				height: $checkoutStepComponentLoadingWidth;

				svg {
					fill: $checkoutAccent;
				}
			}

			.submitIconLabel {
				position: absolute;
				top: calc(50% + #{$checkoutMargin});
				left: 10%;
				right: 10%;
				height: auto;
				
				text-align: center;
				font-size: 24px;
			}
		}
	}
}


//////////////////////////
//   CheckoutStepAuth   //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$checkoutStepAuthTopMargin: 50px;
$checkoutStepAuthMaxWidth: 800px;
$checkoutStepGuestActionHeight: 20px;
$checkoutStepGuestIconHeight: 60px;


.CheckoutStepAuth {
	width: 90%;
	max-width: $checkoutStepAuthMaxWidth;
	margin: 0 auto;
}

.CheckoutStepAuthInput {

	position: relative;
	margin: $checkoutStepAuthTopMargin 0 0 0;
	text-align: left;

	@media #{$break600} {
		text-align: center;
	}

	.csaAuthBlock {

		display: inline-block;
		vertical-align: top;
		width: calc(50% - 1px);
		max-width: $checkoutStepAuthMaxWidth;
		text-align: left;
		transition: width $breakTransition, max-width $breakTransition;

		@media #{$break600} {
			width: 90%;
			max-width: $checkoutStepAuthMaxWidth/2;
		}

		.csaAuthLogin {
			margin: 0 $checkoutMargin 0 0;
			transition: margin $breakTransition;

			@media #{$break600} {
				margin: 0;
			}
		}

		.csaAuthRegister {
			margin: 0 $checkoutMargin 0 0;
			transition: margin $breakTransition;

			@media #{$break600} {
				margin: 0;
			}
			
			.csaAuthRegisterLiner {
				border-top: 1px solid $checkoutColorLight; 
				margin: $checkoutMargin auto 0 auto;
				padding: $checkoutMargin 0 0 0;
				text-align: center;
				width: 80%;
			}
		}
	}

	.csaGuestInputBlock {

		display: inline-block;
		vertical-align: top;
		width: calc(50% - 1px);
		border-left: 1px solid $checkoutAccent;
		text-align: left;
		transition: width $breakTransition, max-width $breakTransition, margin $breakTransition, padding $breakTransition;

		@media #{$break600} {
			position: relative;
			width: 90%;
			max-width: $checkoutStepAuthMaxWidth/2;
			margin: 2*$checkoutMargin auto 0 auto;
			padding: 2*$checkoutMargin 0 0 0;
			border-left: none;
			border-top: 1px solid $checkoutAccent;
		}

		.csaGuestInputLiner {
			margin: 0 $checkoutMargin;
			transition: margin $breakTransition;

			@media #{$break600} {
				margin: 0;
			}
		}
	}

	.csaGuestBlock {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(50% - 1px);
		height: 100%;
		margin: 0;
		border-left: 1px solid $checkoutAccent;
		transition: width $breakTransition, max-width $breakTransition, margin $breakTransition;

		@media #{$break600} {
			position: relative;
			width: 90%;
			max-width: $checkoutStepAuthMaxWidth/2;
			margin: 2*$checkoutMargin auto 0 auto;
			border-left: none;
			border-top: 1px solid $checkoutAccent;
		}

		.csaGuestLiner {

			position: absolute;
			top: calc(50% - #{$checkoutStepGuestIconHeight}/2 - #{$checkoutMargin}/2 - #{$checkoutStepGuestActionHeight}/2);
			left: 10%;
			width: 80%;
			text-align: center;
			margin: 0 0 0 $checkoutMargin;

			@media #{$break600} {
				position: relative;
				top: unset;
				left: unset;
				margin: 2*$checkoutMargin auto 0 auto;
			}
			
			.csaGuestIconWrapper {
				margin: 0 auto;
				width: $checkoutStepGuestIconHeight;
				height: $checkoutStepGuestIconHeight;

				.guestIcon {
					width: $checkoutStepGuestIconHeight;
					height: $checkoutStepGuestIconHeight;
				}
			}

			.csaGuestActionWrapper {
				margin: $checkoutMargin 0 0 0;
				height: $checkoutStepGuestActionHeight;
			}
		}
	}
}

.CheckoutStepBuylistAuthInput {

	position: relative;
	margin: $checkoutStepAuthTopMargin 0 0 0;
	text-align: left;

	@media #{$break600} {
		text-align: center;
	}

	.csaAuthBlock {

		display: inline-block;
		vertical-align: top;
		width: 100%;
		max-width: $checkoutStepAuthMaxWidth;
		text-align: left;
		transition: width $breakTransition, max-width $breakTransition;

		@media #{$break600} {
			width: 90%;
			max-width: $checkoutStepAuthMaxWidth/2;
		}

		.csaAuthLogin {
			margin: 0 $checkoutMargin 0 0;
			transition: margin $breakTransition;

			@media #{$break600} {
				margin: 0;
			}
		}

		.csaAuthRegister {
			margin: 0 $checkoutMargin 0 0;
			transition: margin $breakTransition;

			@media #{$break600} {
				margin: 0;
			}
			
			.csaAuthRegisterLiner {
				border-top: 1px solid $checkoutColorLight; 
				margin: $checkoutMargin auto 0 auto;
				padding: $checkoutMargin 0 0 0;
				text-align: center;
				width: 80%;
			}
		}
	}
}


/////////////////////////////////////
//   CheckoutStepShippingAddress   //
/////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepShippingAddress {

}

.CheckoutStepShippingAddressInput {

	margin: 2*$checkoutMargin 0 0 0;

	.cssaLiner {

		margin: 0 $checkoutMargin 0 0;

		.savedAddressSection {

			.saLoadingWrapper {
				height: $checkoutLoadingHeight;
				text-align: center;

				.iconElement {
					height: $checkoutLoadingHeight;
					width: $checkoutLoadingHeight;
					margin: 0 auto;

					svg {
						fill: $checkoutAccent;
					}
				}
			}

			.addressOpen.saControlSection {
				margin: 0 $checkoutMargin 2*$checkoutMargin $checkoutMargin;
			}

			.saControlSection {
				margin: 0 0 0 0;

				.saControlDropdownWrapper {
					margin: 0 0 2*$checkoutMargin 0;
				}

				.saControlPrompt {
					margin: 2*$checkoutMargin 0 0 0;
					text-align: center;
					font-size: 24px;
					font-weight: 300;
				}
			}
		}

		.cssaSection {
			margin: 0 0.5*$checkoutMargin;
		}
	}
}

.CheckoutStepShippingAddressComplete {

	margin: 2*$checkoutMargin 0 0 0;

	.cssaLiner {

		margin: 0 $checkoutMargin 0 0;

		.cssAddressWrapper {

			.cssAddressLine {

			}

			.cssAddressToggleWrapper {
				margin: $checkoutMargin 0 0 0;

				button.cssAddressToggleButton {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					cursor: pointer;
					border: none;
					background: none;
					color: $checkoutAccent;
					transition: color $hoverTransition;

					&:hover {
						color: $checkoutAccentHover;
					}
				}
			}
		}
	}
}


/////////////////////////////////////
//   CheckoutStepBuylistAddress   //
/////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepBuylistAddress {

}

.CheckoutStepBuylistAddressInput {

	margin: 2*$checkoutMargin 0 0 0;

	.cssaLiner {

		margin: 0 $checkoutMargin 0 0;

		.savedAddressSection {

			.saLoadingWrapper {
				height: $checkoutLoadingHeight;
				text-align: center;

				.iconElement {
					height: $checkoutLoadingHeight;
					width: $checkoutLoadingHeight;
					margin: 0 auto;

					svg {
						fill: $checkoutAccent;
					}
				}
			}

			.addressOpen.saControlSection {
				margin: 0 $checkoutMargin 2*$checkoutMargin $checkoutMargin;
			}

			.saControlSection {
				margin: 0 0 0 0;

				.saControlDropdownWrapper {
					margin: 0 0 2*$checkoutMargin 0;
				}

				.saControlPrompt {
					margin: 2*$checkoutMargin 0 0 0;
					text-align: center;
					font-size: 24px;
					font-weight: 300;
				}

				.saControlSecondaryPrompt {
					margin: $checkoutMargin 0 0 0;
					text-align: center;
					font-size: 16px;
					font-weight: 300;
				}
			}
		}

		.cssaSection {
			margin: 0 0.5*$checkoutMargin;
		}
	}
}

.CheckoutStepBuylistAddressComplete {

	margin: 2*$checkoutMargin 0 0 0;

	.cssaLiner {

		margin: 0 $checkoutMargin 0 0;

		.cssAddressWrapper {

			.cssAddressLine {

			}

			.cssAddressToggleWrapper {
				margin: $checkoutMargin 0 0 0;

				button.cssAddressToggleButton {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					cursor: pointer;
					border: none;
					background: none;
					color: $checkoutAccent;
					transition: color $hoverTransition;

					&:hover {
						color: $checkoutAccentHover;
					}
				}
			}
		}
	}
}


////////////////////////////////////
//   CheckoutStepShippingMethod   //
////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$checkoutStepShippingMethodInputWidth: 20px;


.CheckoutStepShippingMethod {

}

.CheckoutStepShippingMethodInput {

	margin: 2*$checkoutMargin 0 0 0;

	.cssmLiner {

		.checkoutStepRadioForm {

			.radioOptionsWrapper {

				.radioOptionElementWrapper {

					.radioLabelWrapper {

						.radioTitle {

							.smName {

							}

							.smCost {
								margin: 0 0 0 $checkoutMargin;
								font-weight: 400;

								&:before {
									content: "—";
									font-weight: 700;
									color: $checkoutAccent;
								}

								.priceValue {

									margin: 0 0 0 $checkoutMargin;

									.priceFormatSymbol {
										font-weight: 300;
									}

									.priceFormatDecimal {
										font-weight: 300;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.addressValidationWrapper {

		.addressValidation {

			margin: $checkoutMargin 0 0 0;

			.avP {
				margin: 0 0 $checkoutMargin 0;
			}

			.addressBlock {
				font-size: 14px;
				margin: 0 0 $checkoutMargin $checkoutMargin;	
			}

			.addressForm {

				.formLiner {

					.selected.candidateEntry {
						background: $checkoutStepComponentRadioSelectedBackground;
					}

					.candidateEntry {

						background: $checkoutBackground;
						padding: 0.5*$checkoutMargin;
						transition: background $toggleTransition;

						.candidateInput {

							display: inline-block;
							vertical-align: top;
							width: $checkoutStepShippingMethodInputWidth;
							margin: 0;

							.radioWrapper {
								position: relative;
								width: $checkoutStepShippingMethodInputWidth;
								height: $checkoutStepShippingMethodInputWidth;
							}
						}

						.candidateValue {

							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$checkoutStepShippingMethodInputWidth} - #{$checkoutMargin});
							margin: 0 0 0 $checkoutMargin;

							.cadidateAddressValue {

							}
						}
					}
				}
			}
		}
	}
}



///////////////////////////////////
//   CheckoutStepPaymentMethod   //
///////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepPaymentMethod {

}

.CheckoutStepPaymentMethodInput {

	.cspmLiner {

		.storeCreditWrapper {

			.storeCreditPrompt {
				margin: 0 0 0 0;
				font-size: 16px;
				transition: font-size $breakTransition;

				@media #{$break600} {
					font-size: 14px;
				}

				.balanceEm {
					font-weight: 700;
				}
			}

			.storeCreditToggle {

				cursor: pointer;
				margin: 0 0 $checkoutMargin 0;
				padding: 0.5*$checkoutMargin;

				.toggleInputWrapper {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $checkoutStepComponentRadioWidth;
					height: $checkoutStepComponentRadioWidth;
					margin: 0.25*$checkoutMargin 0 0 0;
					transition: width $breakTransition, height $breakTransition;

					@media #{$break600} {
						width: $checkoutStepComponentRadioWidthMobile;
						height: $checkoutStepComponentRadioWidthMobile;
					}
				}

				.toggleLabelWrapper {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$checkoutStepComponentRadioWidth} - #{$checkoutMargin});
					margin: 0 0 0 $checkoutMargin;
					font-size: 16px;
					height: $checkoutStepComponentRadioWidth + 2px;
					line-height: $checkoutStepComponentRadioWidth + 2px;
					transition: width $breakTransition, margin $breakTransition, font-size $breakTransition;

					@media #{$break600} {
						font-size: 14px;
						width: calc(100% - #{$checkoutStepComponentRadioWidthMobile} - #{$checkoutMargin});
						height: $checkoutStepComponentRadioWidthMobile + 2px;
						line-height: $checkoutStepComponentRadioWidthMobile + 2px;
						margin: calc($checkoutMargin/8) 0 0 0.5*$checkoutMargin;
					}
				}
			}
		}
	}
}


//////////////////////////////////////////
//   CheckoutStepBuylistPaymentMethod   //
//////////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepBuylistPaymentMethod {

}

.CheckoutStepBuylistPaymentMethodInput {
	margin: 2*$checkoutMargin 0 0 0;
}


////////////////////////////////////
//   CheckoutStepPaymentDetails   //
////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepPaymentDetails {

}


.CheckoutStepPaymentDetailsBillingAddressInput {

	.csLiner {

		.savedAddressSection {

			.saLoadingWrapper {
				height: $checkoutLoadingHeight;
				text-align: center;

				.iconElement {
					height: $checkoutLoadingHeight;
					width: $checkoutLoadingHeight;
					margin: 0 auto;

					svg {
						fill: $checkoutAccent;
					}
				}
			}

			.saControlSection {
				margin: 0 0 0 0;

				.checkoutFormInput {

					width: calc(100% - #{$checkoutMargin});

					.saControlDropdownWrapper.checkoutDropdownSelectWrapper {
						border-color: $checkoutBackground;
						padding-left: 0;
						padding-right: 0;
					}
				}

				.saControlPrompt {
					margin: 2*$checkoutMargin 0 4*$checkoutMargin 0;
					text-align: center;
					font-size: 24px;
					font-weight: 300;
				}

				.saAddressDisplayWrapper {

					margin: 0 0 2*$checkoutMargin 0.5*$checkoutMargin;

					.billingAddressLine {

					}
				}
			}
		}

		.checkoutStepInputForm {

			.checkoutFormInput {
				
			}
		}
	}
}




////////////////////////////////////
//   CheckoutStepBuylistConfirm   //
////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckoutStepBuylistConfirm {

}

.CheckoutStepBuylistConfirmInput {
	
	.confirmLiner {
		margin: 3*$checkoutMargin 0 0 0;

		.confirmBuylistForm {

			.stepsWrapper {

				margin: 0 0 2*$checkoutMargin 2*$checkoutMargin;

				.stepElement {

					margin: 0 0 2*$checkoutMargin 0;

					.stepHeader {
						margin: 0 0 $checkoutMargin 0;
						font-size: 16px;
						font-weight: 700;
					}

					.stepUl {
						margin: 0 0 (1/2)*$checkoutMargin 2*$checkoutMargin;
						font-size: 15px;
						font-weight: 400;

						&:before {
							content: '◦ ';
						}
					}

					.stepAddress {

						margin: 0 0 (1/2)*$checkoutMargin 4*$checkoutMargin;
						font-size: 14px;
						font-weight: 300;

						.addressLine {

						}
					}
				}
			}

			.checkoutFormConfirmLine {
				margin: 0 0 2*$checkoutMargin 0;

				.checkboxInputWrapper {

					.error.checkboxLabel {
						color: $errorRed;
					}

					.checkboxLabel {
						transition: color $toggleTransition;
					}
				}
			}
		}
	}
}



































