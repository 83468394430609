

@use '../../../../style/import' as *;

// Titan colors
$starwarsImageFlipButtonColor: $TITAN_TEXT_COLOR;
$starwarsImageFlipButtonColorHover: $TITAN_TEXT_COLOR_HOVER;
$starwarsImageFlipButtonBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$starwarsImageHeight: 141.05572%;


///////////////////////
// StarWarsCardImage //
///////////////////////

.StarWarsCardImage {

	position: relative;
	display: inline-block;
	width: 100%;
	padding-bottom: $starwarsImageHeight;

	.starwarsObjImageWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img {
			width: 100%;
		}
	}
}


