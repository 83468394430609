



@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;




////////////////////
// HeaderFullMenu //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HeaderFullMenu {

	display: none;
	width: $headerFullMenuWidth;
	position: absolute;
	overflow: hidden;
	background: $headerBackground;

	top: 0;
	right: 0;
	height: auto;
	z-index: $zUserMenuLayer;
	transition: top $breakTransition;

	@media #{$break1200} {
		display: block;
	}

	@media #{$break600} {
		top: 0;
		width: 100%;
		height: auto;
	}

	.hfmOuterWrapper {

		position: relative;
		height: 0;
		transition: height $slideTransition;

		.hfmInnerWrapper {

			position: absolute;
			top: $headerHeight;
			left: 0;
			right: 0;
			bottom: 0;
			transition: top $breakTransition;

			@media #{$break600} {
				top: $headerHeightMobile;
			}

			.hfmHeader {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $headerFullMenuHeaderHeight;
				text-align: center;
				transition: height $breakTransition;

				@media #{$break600} {
					height: $headerFullMenuHeaderHeightMobile;
				}

				.iconsWrapper {

					margin: 15px $headerFullMenuHorizontalPadding;
					transition: margin $breakTransition;

					@media #{$break600} {
						margin: 20px $headerFullMenuHorizontalPaddingMobile 10px $headerFullMenuHorizontalPaddingMobile;
					}

					.iconElement {
						display: inline-block;
						vertical-align: top;
						margin: 0 20px;
						width: calc(25% - 40px);
						cursor: pointer;
						transition: width $breakTransition, margin $breakTransition;

						&:hover {
							.iconUnderline {
								width: 100%;
							}

							@media #{$break600} {
								.iconUnderline { width: 0; }
							}
						}

						@media #{$break600} {
							margin: 0 10px;
							width: calc(25% - 20px);
						}

						&.current {

							.iconUnderline {
								width: 100%;
							}

							&:hover {
								@media #{$break600} {
									.iconUnderline { width: 100%; }
								}
							}
						}

						/* H: 70px; */
						.iconSvg {

							height: 70px;
							transition: height $breakTransition;

							@media #{$break600} {
								height: 50px;
							}

							svg {
								fill: $headerColor;
							}
						}

						/* H: 20px; */
						.iconLabel {
							color: $headerColor;
							height: 17px;
							line-height: 17px;
							font-size: 15px;
							transition: font-size $breakTransition;

							@media #{$break600} {
								font-size: 14px;
							}
						}

						/* H: 5px; */
						.iconUnderline {
							margin: 1px auto 0 auto;
							height: 4px;
							background: $headerAccentColor;
							width: 0;
							transition: width $hoverTransition;
						}
					}
				}
			}

			.hfmVariable {
				position: absolute;
				top: $headerFullMenuHeaderHeight;
				left: 0;
				width: 100%;
				height: calc(100% - #{$headerFullMenuHeaderHeight} - #{$headerFullMenuFooterHeight});

				color: $headerColor;
			}

			.hfmFooter {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: $headerFullMenuFooterHeight;

				color: $headerColor;
			}
		}
	}
}

























