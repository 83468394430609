

@use '../../../../style/import' as *;


// Titan colors
$checkboxAdminBorderColor: $TITAN_RULE_COLOR;
$checkboxAdminActiveBackgroundColor: $TITAN_PRIMARY_COLOR;
$checkboxAdminActiveColor: $TITAN_BACKGROUND;
$checkboxAdminErrorColor: $errorRed;

// Theme colors
$checkboxBorderColor: $THEME_TEXT_COLOR;
$checkboxActiveBackgroundColor: $THEME_PRIMARY_COLOR;
$checkboxActiveColor: $THEME_BACKGROUND;
$checkboxErrorColor: $errorRed;

// General variables
$checkboxBorderWidth: 1px;
$checkboxAmbiguousDotSize: 50%;

// Component variables
// None yet


.Checkbox.adminTheme {

  .error.checkbox__control {
    border-color: $checkboxAdminErrorColor;
  }

  .checkbox__control {
    border: $checkboxBorderWidth solid $checkboxAdminBorderColor;
    background: $checkboxAdminActiveColor;

    .ambiguousDotWrapper {

      background: $checkboxAdminActiveColor;
      border: 1px solid $checkboxAdminBorderColor;

      .ambiguousDot {
        position: absolute;
        top: calc(50% - #{$checkboxAmbiguousDotSize/2});
        left: calc(50% - #{$checkboxAmbiguousDotSize/2});
        width: $checkboxAmbiguousDotSize;
        height: $checkboxAmbiguousDotSize;

        background: $checkboxAdminActiveBackgroundColor;
      }
    }

    svg {
      stroke: $checkboxAdminActiveColor;
    }
  }

  .checkbox__input {

    input {

      &:checked + .error.checkbox__control {
        border-color: $checkboxAdminErrorColor;
        background: $checkboxAdminErrorColor;
      }

      &:checked + .checkbox__control {

        border-color: $checkboxAdminActiveBackgroundColor;
        background: $checkboxAdminActiveBackgroundColor;
      }
    }
  }
}


.Checkbox {
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .error.checkbox__control {
    border-color: $checkboxErrorColor;
  }

  .checkbox__control {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2*#{$checkboxBorderWidth});
    height: calc(100% - 2*#{$checkboxBorderWidth});
    border: $checkboxBorderWidth solid $checkboxBorderColor;
    background: $checkboxActiveColor;
    transition: background $toggleTransition, border $toggleTransition;

    .ambiguousDotWrapper {

      position: absolute;
      top: -1*$checkboxBorderWidth;
      left: -1*$checkboxBorderWidth;
      right: -1*$checkboxBorderWidth;
      bottom: -1*$checkboxBorderWidth;

      background: $checkboxActiveColor;
      border: 1px solid $checkboxBorderColor;

      .ambiguousDot {
        position: absolute;
        top: calc(50% - #{$checkboxAmbiguousDotSize/2});
        left: calc(50% - #{$checkboxAmbiguousDotSize/2});
        width: $checkboxAmbiguousDotSize;
        height: $checkboxAmbiguousDotSize;

        background: $checkboxActiveBackgroundColor;
      }
    }

    svg {
      
      transform: scale(0);
      transform-origin: center center;

      stroke: $checkboxActiveColor;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      max-width: 100%;
      max-height: 100%;
      transition: transform $toggleTransition;
    }
  }

  .checkbox__input {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;

      &:focus + .checkbox__control {
        // No focus styles
      }

      &:checked + .error.checkbox__control {
        border-color: $checkboxErrorColor;
        background: $checkboxErrorColor;
      }

      &:checked + .checkbox__control {

        border-color: $checkboxActiveBackgroundColor;
        background: $checkboxActiveBackgroundColor;

        svg {
          transform: scale(1);
        }
      }

      &:disabled + .checkbox__control {
        // No disabled styles yet
      }
    }
  }
}