

@use '../../../../style/import' as *;




////////////////
// AdminTitle //
////////////////


// Titan colors
$backgroundColor: $TITAN_BACKGROUND;
$primaryColor: $TITAN_PRIMARY_COLOR;
$textColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$overviewIconBorderWidth: 2px;
$overviewIconHeight: 20px;
$overviewWidth: 300px;


.AdminTitle {

	position: relative;
	color: $textColor;
	margin: $MARGIN_L $MARGIN_L 0 $MARGIN_L;
	transition: margin $breakTransition;

	.titleFull {
		
		font-family: $fontLato;
		font-size: 32px;
		transition: font-size $breakTransition;

		@media #{$break1200} {
			font-size: 28px;
		}

		@media #{$break1024} {
			display: none;
		}

		.crumbWrapper {

			.crumbLink {
				color: $textColor;
			}

			.crumbChevron {
				color: $primaryColor;
				font-family: $fontLato;
				font-weight: 300;
				margin: 0 5px;
			}

			.pageTitle {

			}
		}
	}

	.titleMobile {

		display: none;
		position: relative;
		font-family: $fontLato;
		font-size: 28px;
		transition: font-size $breakTransition;

		@media #{$break1024} {
			display: block;
		}

		@media #{$break768} {
			font-size: 20px;
		}

		.backWrapper {

			display: inline-block;
			vertical-align: middle;
			padding: 0 $MARGIN 0 0;

			.arrow {
				display: inline-block;
				vertical-align: middle;
				color: $primaryColor;
				font-family: $fontLato;
				font-weight: 300;
				font-size: 36px;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 24px;
				}		
			}

			.label {
				display: inline-block;
				vertical-align: middle;
				font-family: $fontLato;
				font-size: 20px;
				text-transform: uppercase;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}
			}
		}

		.mobileTitle {

			&.noBack {
				border-left: none;
				padding: 0;
			}

			display: inline-block;
			vertical-align: middle;
			border-left: 1px solid $primaryColor;
			padding: 0 0 0 $MARGIN;
		}
	}

	.titleOverview {

		.overviewIconWrapper {

			position: absolute;
			top: calc(50% - #{0.5 * $overviewIconHeight});
			right: 0;
			width: $overviewIconHeight;
			height: $overviewIconHeight;

			cursor: pointer;
			border: $overviewIconBorderWidth solid $textColor;
			border-radius: 50%;

			.overviewIcon {
				font-size: 18px;
				font-weight: 400;
			}
		}

		.overviewDrawerWrapper {

			position: absolute;
			overflow: hidden;
			top: 100%;
			right: 0;
			height: auto;
			max-height: 0;
			max-width: $overviewWidth;
			z-index: 10;
			transition: max-height $slideFastTransition;

			&.open {
				max-height: 500px;
				transition: max-height $slideTransition;
			}

			.overviewDrawerLiner {
				background: $backgroundColor;
				border: 1px solid $textColor;
				padding: $MARGIN_L;
			}
		}
	}
}













