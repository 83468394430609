

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



//////////////////////////
// HeaderFullMenuFooter //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.HeaderFullMenuFooter {

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.hfmfFooterWrapper {

		.hfmfIconWrapper {

			position: absolute;
			top: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterIconWidth) / 2);
			bottom: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterIconWidth) / 2);
			left: $headerFullMenuHorizontalPadding;
			width: $headerFullMenuFooterIconWidth;
			transition: left $breakTransition;

			@media #{$break600} {
				left: $headerFullMenuHorizontalPaddingMobile;
			}

			.iconElement {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				svg {
					fill: $headerColor;
				}
			}
		}

		.hfmfBody {

			position: absolute;
			top: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterIconWidth) / 2);
			bottom: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterIconWidth) / 2);
			left: $headerFullMenuFooterIconWidth + (2 * $headerFullMenuHorizontalPadding);
			right: $headerFullMenuHorizontalPadding;
			transition: left $breakTransition, right $breakTransition;

			@media #{$break600} {
				left: $headerFullMenuFooterIconWidth + (2 * $headerFullMenuHorizontalPaddingMobile);
				right: $headerFullMenuHorizontalPaddingMobile;
			}

			.hfmfBodyWrapper {

				.bodyContent {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: $headerFullMenuFooterActionWidth;

					.helloWrapper {
						position: absolute;
						top: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterHelloHeight) / 2);
						bottom: calc(($headerFullMenuFooterHeight - $headerFullMenuFooterHelloHeight) / 2);
						left: 0;
						right: 0;
					}
				}

				.actionWrapper {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: $headerFullMenuFooterActionWidth;

					.hfmfButton {
						position: absolute;
						top: 10px;
						left: 10px;
						right: 10px;
						bottom: 10px;

						cursor: pointer;
						color: $headerAccentColorText;
						text-transform: uppercase;
						font-size: 14px;
						font-weight: 700;
						background: $headerAccentColor;
						transition: background $hoverTransition;

						&:hover {
							background: $headerAccentColorHover;
						}
					}
				}
			}
		}
	}
}