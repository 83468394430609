

@use '../../../../style/import' as *;




////////////////////
// FeatureWrapper //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.FeatureWrapper {
	display: contents;
}













