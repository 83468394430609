

@use '../../../../style/import' as *;

// Titan colors
// None yet

// Theme colors
$productFilterPrimary: $THEME_PRIMARY_COLOR;
$productFilterTagBackground: $THEME_BACKGROUND_CONTRAST;
$productSearchHrColor: $THEME_TEXT_COLOR_LIGHT;

// General variables
$productFilterSpacing: 25px;
$productFilterMargin: 10px;
$productFilterMaxTagBodyWidth: 200px;
$productFilterMinHeight: 300px;
$productFilterTagActionWidth: 20px;
$productFilterLoadingIconWidth: 50px;

// Component variables
// None yet




////////////////////
// ProductFilters //
////////////////////


.ProductFilters {

	.productFiltersWrapperLoading {

		min-height: $productFilterMinHeight;
		position: relative;

		.filterLoading {

			.filterLoadingWrapper {
				position: absolute;
				top: calc(45% - #{0.5*$productFilterLoadingIconWidth});
				left: calc(50% - #{0.5*$productFilterLoadingIconWidth});
				width: $productFilterLoadingIconWidth;
				height: $productFilterLoadingIconWidth;

				svg {
					fill: $productFilterPrimary;
				}
			}

			.filterLoadingLabel {

				position: absolute;
				top: calc(45% + #{0.5*$productFilterLoadingIconWidth});
				left: 0;
				width: 100%;
				height: auto;

				padding: $productFilterMargin 0 0 0;
				font-size: 18px;
				font-weight: 300;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}

	.productFiltersWrapper {

		margin: $productFilterMargin $productFilterSpacing;
		min-height: $productFilterMinHeight;

		.pfFilterTitle {
			margin: 0 0 $productFilterMargin 0;
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.pfAppliedWrapper {
			margin-bottom: $productFilterMargin;
			padding-bottom: $productFilterMargin;
			border-bottom: 1px solid $productSearchHrColor;

			.pfAppliedBody {

				.pfAppliedElementWrapper {
					margin: 0 0 $productFilterMargin 0;

					.pfAppliedElementTitle {
						font-size: 14px;
						font-weight: 700;
						margin: 0 0 0.5*$productFilterMargin 0;
					}

					.pfAppliedElementBody {

						.pfAppliedItemWrapper {

							display: inline-block;
							vertical-align: top;
							width: auto;
							margin: 0 $productFilterMargin 0.5*$productFilterMargin 0;
							
							.pfAppliedItemInnerWrapper {

								background: $productFilterTagBackground;
								border-radius: 10%;

								.pfAppliedTagLabel {
									display: inline-block;
									vertical-align: top;
									width: auto;

									font-size: 10px;
									line-height: 14px;
									font-weight: 700;
									padding: 0.25*$productFilterMargin 0 0.25*$productFilterMargin 0.5*$productFilterMargin;
								}

								.pfAppliedTagBody {
									display: inline-block;
									vertical-align: top;
									width: auto;
									max-width: $productFilterMaxTagBodyWidth;

									font-size: 14px;
									font-weight: 300;
									padding: 0.25*$productFilterMargin 0.5*$productFilterMargin;
									border-right: 1px solid $productSearchHrColor;
								}

								.pfAppliedTagAction {
									display: inline-block;
									vertical-align: top;
									width: auto;

									position: relative;
									padding: 0.5*$productFilterTagActionWidth;
									font-size: 18px;
									font-weight: 300;
									cursor: pointer;
									transition: font-weight $hoverTransition, color $hoverTransition;

									&:hover {
										color: $productFilterPrimary;
										font-weight: 700;
									}

									.pfTagX {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;

										display: flex;
										justify-content: center;
										align-items: center;
									}					
								}
							}
						}
					}
				}
			}

			.pfAppliedBodyNull {
				margin-bottom: 0.5*$productFilterMargin;
				font-size: 18px;
				font-weight: 300;
			}
		}

		.pfFilters {

			.individualFilterWrapper {
				margin-bottom: $productFilterMargin;
				padding-bottom: $productFilterMargin;
				border-bottom: 1px solid $productSearchHrColor;

				// border-bottom: 1px solid $THEME_PRIMARY_COLOR;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
		}
	}
}


// Titan colors
// None yet

// Theme colors
$filterElementBorderColor: $THEME_RULE_COLOR;
$filterElementInputBackground: $THEME_BACKGROUND;

// General variables
$filterElementMargin: 10px;

// Component variables
$filterElementInputHeight: 20px;
$filterElementMaxHeight: 400px;

$filterElementCheckboxesInputWidth: 18px;
$filterElementCheckboxesInputSpacing: 2px;
$filterElementCheckboxesLoadingWidth: 40px;
$filterElementCheckboxesRowMargin: 3px;
$filterElementCheckboxesToggleWidth: 20px;


///////////////////
// FilterElement //
///////////////////


.FilterElement {

	.feFilterTitle {
		margin: 0 0 0.5*$filterElementMargin 0;

		.feFilterTitleValue {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$filterElementCheckboxesToggleWidth});
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.feFilterTitleToggle {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $filterElementCheckboxesToggleWidth;
			height: $filterElementCheckboxesToggleWidth;

			.feFilterTitleToggleElement {
				font-size: 26px;
				font-weight: 300;
				cursor: pointer;

				&:hover {
					color: $productFilterPrimary;
					font-weight: 400;
				}
			}
		}
	}

	.open.feFilterBody {
		max-height: $filterElementMaxHeight;
		transition: max-height $slideTransition;
	}

	.feFilterBody {
		position: relative;
		overflow: hidden;
		height: auto;
		max-height: 0;
		transition: max-height $slideFastTransition;

		.feFilterBodyLiner {
			overflow: auto;
			position: relative;
			max-height: $filterElementMaxHeight;

			.feFilterBodyLinerScroll {

			}

			.multiQuickSearchInput {

				position: sticky;
				top: 0;
				left: 0;
				padding: 0 0 $filterElementMargin 0;
				background: $filterElementInputBackground;
				z-index: 1;

				input {
					border: 1px solid $filterElementBorderColor;
					font-size: 12px;
					height: $filterElementInputHeight - 2px;
					padding: 0 $filterElementInputHeight 0 $filterElementInputHeight;
					width: calc(100% - #{2*$filterElementInputHeight} - 2px - #{$filterElementMargin});
					text-align: left;
				}

				.searchOverlay {

					$iconMarginPercent: 0.2;

					position: absolute;
					top: $iconMarginPercent * $filterElementInputHeight;
					left: 0.5 * $filterElementMargin;
					width: (1 - 2*$iconMarginPercent) * $filterElementInputHeight;
					bottom: $iconMarginPercent * $filterElementInputHeight + $filterElementMargin;
					line-height: $filterElementInputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					color: $filterElementBorderColor;
					font-size: 15px;
					font-weight: 300;
					text-align: left;

					.searchIcon {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						svg {
							fill: $filterElementBorderColor;
						}
					}
				}

				.clearOverlay {
					position: absolute;
					top: 0;
					right: 1.5 * $filterElementMargin;
					width: auto;
					height: $filterElementInputHeight;
					line-height: $filterElementInputHeight;
					cursor: pointer;
					user-select: none;

					color: $filterElementBorderColor;
					font-size: 18px;
					font-weight: 300;
					text-align: right;
				}
			}
		}

		.feFilterLoading {

			.feLoadingWrapper {

				margin: $filterElementMargin 0;

				.feLoadingIcon {
					margin: 0 auto 0 auto;
					width: $filterElementCheckboxesLoadingWidth;
					height: $filterElementCheckboxesLoadingWidth;

					svg {
						fill: $productFilterPrimary;
					}
				}
				
				.feLoadingLabel {
					margin: 0.5*$filterElementMargin 0 0 0;
					text-align: center;
					font-size: 16px;
					font-weight: 300;
				}
			}
		}
	}
}


.FilterElementCheckboxes {
	
	.feFilterBody {
		
		.feCheckboxWrapper {

			position: relative;
			margin: 0 0 $filterElementCheckboxesRowMargin 0;

			.feCheckboxInputWrapper {
				cursor: pointer;
				position: absolute;
				top: $filterElementCheckboxesInputSpacing;
				left: $filterElementCheckboxesInputSpacing;
				width: $filterElementCheckboxesInputWidth - 2*$filterElementCheckboxesInputSpacing;
				height: $filterElementCheckboxesInputWidth - 2*$filterElementCheckboxesInputSpacing;
			}

			.feCheckboxValueWrapper {
				cursor: pointer;
				display: block;
				margin: 0 0 0 ($filterElementCheckboxesInputWidth + 4*$filterElementCheckboxesInputSpacing);
				line-height: $filterElementCheckboxesInputWidth;
				font-size: 13px;
				font-weight: 300;
			}
		}	
	}
}


.FilterElementDropdown {
	
	.feFilterBody {

		.fedSelect {
			// border-bottom-style: dotted;
		}
	}
}













