

@use '../../../../../../style/import' as *;




////////////////////////////
// ProductCategoryManager //
////////////////////////////


// Titan colors
$primaryColor: $TITAN_PRIMARY_COLOR;
$primaryColorHover: $TITAN_PRIMARY_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$iconWidth: 60px;
$maxWidth: 500px;


.ProductCategoryManager {

	.managerWrapper {

		margin: $MARGIN 0 $MARGIN_L 0;
		
		.loadingWrapper {

			.loadingIconWrapper {
				margin: $iconWidth auto;
				width: $iconWidth;
				height: $iconWidth;

				svg {
					fill: $primaryColor;
				}
			}
		}

		.listWrapper {

			margin: 0 auto;
			max-width: $maxWidth;

			.list {

			}

			.listNoResults {

				margin: $MARGIN 0 0 0;

				.listNoResultsCopy {
					font-size: 24px;
					font-weight: 300;
					text-align: center;
				}
			}

			.addWrapper {

				.addOpen {

				}

				.addClosed {

					margin: $MARGIN 0;
					text-align: center;

					.addLink {
						display: inline-block;
						vertical-align: top;
						width: auto;
						cursor: pointer;
						color: $primaryColor;
						font-size: 18px;
						font-weight: 700;
						transition: color $hoverTransition;

						&:hover {
							color: $primaryColorHover;
						}
					}
				}
			}
		}
	}
}













