
@use '../../generated/theme' as theme;

/************************************************
*****                                       *****
*****   Color Notes:                        *****
*****                                       *****
*****   Colors defined here (_colors.scss)  *****
*****   are fixed for all Talaria instances *****
*****   and cannot / should not be chanaged *****
*****   based on store or theme.            *****
*****                                       *****
*****   Colors that vary based on store or  *****
*****   theme should be defined in the.     *****
*****   _theme.scss file.                   *****
*****                                       *****
************************************************/


/************************************************
*****           Functional colors           *****
************************************************/

/* Error color */
$errorRed: #EB3533;
$errorRedHover: #A42523;
$errorBackground: #FDEAEA;

/* Forms */
$placeholderGrey: #BBBBBB;

$disabledColor: #888888;
$disabledBackground: #E8E8E8;



/* Titan Color Constants; should be same format at theme constants but swap THEME_ for TITAN_ */

$TITAN_PRIMARY_COLOR: #46BED9;
$TITAN_PRIMARY_COLOR_HOVER: #024554;
$TITAN_PRIMARY_COLOR_HOVER_SHADE: #3FABC3;
$TITAN_PRIMARY_COLOR_TEXT: #FFFFFF;
$TITAN_PRIMARY_ULTRALIGHT: #E5F6FA;

$TITAN_TEXT_COLOR: #333333;
$TITAN_TEXT_COLOR_HOVER: #000000;
$TITAN_TEXT_COLOR_LIGHT: #808080;
$TITAN_TEXT_COLOR_DISABLED: $disabledColor;

$TITAN_RULE_COLOR: #CCCCCC; /* Shade of text color for HR/VR elements */

$TITAN_BACKGROUND: #FFFFFF;
$TITAN_BACKGROUND_HOVER: #EFEFEF;
$TITAN_BACKGROUND_CONTRAST: #F8F8F8;
$TITAN_BACKGROUND_DISABLED: $disabledBackground;

$TITAN_HEADER_HEIGHT: theme.$THEME_HEADER_HEIGHT;  // Default 80px
$TITAN_HEADER_HEIGHT_MOBILE: theme.$THEME_HEADER_HEIGHT_MOBILE;  // Default 40px
$TITAN_HEADER_NOTICE_HEIGHT: 30px;  // If you change these, you also need to update HeaderFullMenu.js
$TITAN_HEADER_NOTICE_HEIGHT_MOBILE: 45px;  // If you change these, you also need to update HeaderFullMenu.js

$TITAN_ERROR_BACKGROUND: $errorBackground;
$TITAN_ERROR_COLOR: $errorRed;
$TITAN_ERROR_COLOR_HOVER: $errorRedHover;

/* Admin colors don't have THEME_ equivalents as admin view users TITAN theme always */

$TITAN_ADMIN_BLOCK_BACKGROUND: #FFFFFF;
$TITAN_ADMIN_CONTROL_BACKGROUND: #383838;
$TITAN_ADMIN_CONTROL_BACKGROUND_HOVER: #222222;
$TITAN_ADMIN_PAGE_BACKGROUND: #F2F2F2;
$TITAN_ADMIN_PAGE_BACKGROUND_HOVER: #E2E2E2;
$TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE: #F8F8F8;  /* Used for table row background primarily */

$TITAN_SCREEN_COLOR: #000000;
$TITAN_SCREEN_OPACITY: 0.6;


/* Event accent colors */

$EVENT_LORCANA_COLOR: #FFFFFF;
$EVENT_LORCANA_BACKGROUND: #0694C8;
$EVENT_LORCANA_BACKGROUND_HOVER: #004680;

$EVENT_MAGIC_COLOR: #FFFFFF;
$EVENT_MAGIC_BACKGROUND: #D8643C;
$EVENT_MAGIC_BACKGROUND_HOVER: #C55027;

$EVENT_ONEPIECE_COLOR: #FFFFFF;
$EVENT_ONEPIECE_BACKGROUND: #C25F7F;
$EVENT_ONEPIECE_BACKGROUND_HOVER: #9B4C65;

$EVENT_POKEMON_COLOR: #FFFFFF;
$EVENT_POKEMON_BACKGROUND: #009620;
$EVENT_POKEMON_BACKGROUND_HOVER: #007819;

$EVENT_STARWARS_COLOR: #FFFFFF;
$EVENT_STARWARS_BACKGROUND: #BB6AED;
$EVENT_STARWARS_BACKGROUND_HOVER: #AA5BDF;

$EVENT_WARHAMMER_COLOR: #FFFFFF;
$EVENT_WARHAMMER_BACKGROUND: #936435;
$EVENT_WARHAMMER_BACKGROUND_HOVER: #75502A;

$EVENT_YUGIOH_COLOR: #FFFFFF;
$EVENT_YUGIOH_BACKGROUND: #E7372E;
$EVENT_YUGIOH_BACKGROUND_HOVER: #B82C24;






