

@use '../../../../style/import' as *;




//////////////////////
// SectionComponent //
//////////////////////


// Titan colors
$backgroundTitan: $TITAN_BACKGROUND;
$backgroundTitanAlt: $TITAN_BACKGROUND_CONTRAST;

// Theme colors
$backgroundTheme: $THEME_BACKGROUND;
$backgroundThemeAlt: $THEME_BACKGROUND_CONTRAST;

// General colors
$shadowColor: rgba($TITAN_TEXT_COLOR, 0.05);

// General variables
// None yet

// Animations
$aniSlideUpBlocksDistance: 100px;
$aniSlideUpBlocksDuration: 800ms;
$aniSlideUpBlocksOffset: 400ms;
$aniToggleDuration: 250ms;

// Keyframes

@keyframes section-ani-default {
	0% { opacity: 0%; }
	100% { opacity: 1.0; }
}

@keyframes section-ani-slide-up-blocks {
	0% { 
		opacity: 0%;
		top: $aniSlideUpBlocksDistance; 
	}
	85% { 
		top: -1*$MARGIN; 
		opacity: 1.0;
	}
	100% { 
		top: 0; 
		opacity: 1.0;
	}
}


// Section animation classes


.SectionComponent {

	// Animation classes

	&.ani-default {

		opacity: 0.0;
		
		&.revealed {
			animation-name: section-ani-default;
			animation-duration: $aniToggleDuration;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}

	&.ani-slide-up-blocks {

		opacity: 0.0;

		&.revealed {
			animation-name: section-ani-default;
			animation-duration: $aniToggleDuration;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;

			.sectionLiner {

			  	.sectionBlockWrapper {

			  		animation-name: section-ani-slide-up-blocks;
			  		animation-duration: $aniSlideUpBlocksDuration;
					animation-iteration-count: 1;
					animation-fill-mode: forwards;

					&:nth-child(1) {
						animation-delay: 0*$aniSlideUpBlocksOffset;
					}

					&:nth-child(2) {
						animation-delay: 1*$aniSlideUpBlocksOffset;
					}

					&:nth-child(3) {
						animation-delay: 2*$aniSlideUpBlocksOffset;
					}

					&:nth-child(4) {
						animation-delay: 3*$aniSlideUpBlocksOffset;
					}
			  	}
			}
		}

		.sectionLiner {

			.sectionBlockWrapper {
				opacity: 0.0;
				top: $aniSlideUpBlocksDistance;
			}
		}
	}


	// Effect classes

	&.effect-background-contrast {

		background: $backgroundThemeAlt;

		&.titanTheme {
			background: $backgroundTitanAlt;

			.sectionLiner {
				.sectionBlockWrapper {
					background: $backgroundTitan;
				}
			}
		}

		.sectionLiner {

			.sectionBlockWrapper {
				background: $backgroundTheme;
			}
		}
	}

	&.effect-padded {
		padding: $MARGIN_L 0.5*$MARGIN_L 0 0.5*$MARGIN_L;
	}

	&.effect-block-shadow {

		.sectionLiner {

			.sectionBlockWrapper {
				box-shadow: 0.25*$MARGIN 0.25*$MARGIN $MARGIN 0.25*$MARGIN $shadowColor;
			}
		}
	}


	// Type classes

	&.horizontal-blocks {

		overflow: auto;

		// Effects scoped to type

		&.effect-block-margin-none {

			.sectionLiner {

				.sectionBlockWrapper {

					&.n1 {
						width: calc(33.3%);
					}

					&.n2 {
						width: calc(50.0%);

						@media #{$break768} {
							width: calc(100.0%);
						}
					}

					&.n3 {
						width: calc(33.3%);

						@media #{$break1024} {
							width: calc(50.0%);
						}

						@media #{$break768} {
							width: calc(100.0%);
						}
					}

					&.n4 {
						width: calc(25.0%);

						@media #{$break1024} {
							width: calc(50.0%);
						}

						@media #{$break600} {
							width: calc(100.0%);
						}
					}

					margin: 0 0 0 0;
				}
			}
		}

		&.effect-block-margin-large {

			.sectionLiner {

				.sectionBlockWrapper {

					&.n1 {
						width: calc(33.3% - #{$MARGIN_L});
					}

					&.n2 {
						width: calc(50.0% - #{$MARGIN_L});

						@media #{$break768} {
							width: calc(100.0% - #{$MARGIN_L});
						}
					}

					&.n3 {
						width: calc(33.3% - #{$MARGIN_L});

						@media #{$break1024} {
							width: calc(50.0% - #{$MARGIN_L});
						}

						@media #{$break768} {
							width: calc(100.0% - #{$MARGIN_L});
						}
					}

					&.n4 {
						width: calc(25.0% - #{$MARGIN_L});

						@media #{$break1024} {
							width: calc(50.0% - #{$MARGIN_L});
						}

						@media #{$break600} {
							width: calc(100.0% - #{$MARGIN_L});
						}
					}

					margin: 0 0.5*$MARGIN_L $MARGIN_L 0.5*$MARGIN_L;
				}
			}
		}
		
		.sectionLiner {

			overflow: hidden;
			text-align: center;

			.sectionBlockWrapper {

				&.n1 {
					width: calc(33.3% - #{$MARGIN});
				}

				&.n2 {
					width: calc(50.0% - #{$MARGIN});

					@media #{$break768} {
						width: calc(100.0% - #{$MARGIN});
					}
				}

				&.n3 {
					width: calc(33.3% - #{$MARGIN});

					@media #{$break1024} {
						width: calc(50.0% - #{$MARGIN});
					}

					@media #{$break768} {
						width: calc(100.0% - #{$MARGIN});
					}
				}

				&.n4 {
					width: calc(25.0% - #{$MARGIN});

					@media #{$break1024} {
						width: calc(50.0% - #{$MARGIN});
					}

					@media #{$break600} {
						width: calc(100.0% - #{$MARGIN});
					}
				}

				margin: 0 0.5*$MARGIN $MARGIN 0.5*$MARGIN;

				position: relative;
				display: inline-block;
				vertical-align: top;
				text-align: left;
				transition: width $breakTransition;
			}
		}
	}

	&.full-width-vertical {

		overflow: auto;

		&.effect-block-margin-none {

			.sectionLiner {
				padding: 0;
			}
		}

		.sectionLiner {

			padding: $MARGIN_L;
			transition: padding $breakTransition;

			@media #{$break1024} {
				padding: $MARGIN;
			}
		}
	}

	&.sidebar {

	}

	.sectionLiner {

		overflow: auto;
		position: relative;

		.titleBlockWrapper {

		}

		.sectionBlockWrapper {

		}
	}
}








