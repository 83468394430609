

@use '../../../../../../style/import' as *;




/////////////////////////////
// FilterElementRangeInput //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
$inputBorderColor: $TITAN_RULE_COLOR;

// General variables
$inputHeight: 20px;
$separatorWidth: 20px;


.FilterElementRangeInput {

	.rangeInput {
	
		.minMaxWrapper {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - #{0.5*$separatorWidth});

			&.minWrapper {

			}

			.minMaxInput {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 100%;

				input {
					
					&.error {
						border-color: $errorRed;
					}

					border: 1px solid $inputBorderColor;
					font-size: 14px;
					height: $inputHeight - 2px;
					padding: 0 $MARGIN;
					width: calc(100% - #{2*$MARGIN} - 2px);
					text-align: center;
				}

				.currencyOverlay {
					position: absolute;
					top: 0;
					left: $MARGIN_S;
					width: auto;
					height: $inputHeight;
					line-height: $inputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					color: $inputBorderColor;
					font-size: 15px;
					font-weight: 300;
					text-align: left;
				}

				.calendarOverlay {
					position: absolute;
					top: 0.15*$inputHeight;
					right: $MARGIN_S;
					width: 0.7*$inputHeight;
					height: 0.7*$inputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					.Icon {

						width: 0.7*$inputHeight;
						height: 0.7*$inputHeight;

						svg {
							fill: $inputBorderColor;
						}
					}
				}

				.errorCopy {
					color: $errorRed;
					font-size: 9px;
					font-weight: 700;
				}
			}
		}

		.separatorWrapper {
			display: inline-block;
			vertical-align: top;
			width: $separatorWidth;
			line-height: $inputHeight;
			text-align: center;
		}
	}
}













