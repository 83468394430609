

@use '../../../../style/import' as *;


/////////////////////////
// CustomerBulkUploads //
/////////////////////////


// Titan colors
$customerBulkUploadAccentColor: $TITAN_PRIMARY_COLOR;
$customerBulkUploadAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$customerBulkUploadAccentColorText: $TITAN_PRIMARY_COLOR_TEXT;
$customerBulkUploadBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$customerBulkUploadColor: $TITAN_TEXT_COLOR;
$customerBulkUploadColorHover: $TITAN_TEXT_COLOR_HOVER;
$customerBulkUploadErrorColor: $errorRed;


// Theme colors
// None yet

// General variables
$customerBulkUploadMargin: 10px;



.CustomerBulkUploads {

	.adminBody {

		.adminSection {

		}
	}
}


//////////////////////////////
// CustomerBulkUploadCreate //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
$customerBulkUploadCreateLoadingColor: $TITAN_PRIMARY_COLOR;
$customerBulkUploadCreateLoadingScreenBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;

// General variables
$customerBulkUploadCreateLoadingWidth: 60px;
$customerBulkUploadCreateLoadingScreenOpacity: 0.8;



.CustomerBulkUploadCreate {

	.adminBody {

		.adminSection {

			.uploadLoading {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background: rgba($customerBulkUploadCreateLoadingScreenBackground, $customerBulkUploadCreateLoadingScreenOpacity);

				.uploadLoadingWrapper {
					position: absolute;
					top: calc(50% - #{$customerBulkUploadCreateLoadingWidth});
					left: 0;
					width: 100%;
					height: auto;

					.iconWrapper {
						width: $customerBulkUploadCreateLoadingWidth;
						height: $customerBulkUploadCreateLoadingWidth;
						margin: 0 auto;

						svg {
							fill: $customerBulkUploadCreateLoadingColor;
						}
					}

					.labelWrapper {
						font-size: 28px;
						font-weight: 400;
						text-align: center;
						margin: $customerBulkUploadMargin 0 0 0;
					}
				}
			}

			.buCreateWrapper {
				margin: 2*$customerBulkUploadMargin 0 0 0;
			}
			
			.buComponentWrapper {

			}
		}
	}
}


////////////////////////////
// CustomerBulkUploadView //
////////////////////////////


// Titan colors
// None yet


// Theme colors
// None yet

// General variables
// None yet


.CustomerBulkUploadView {

	.adminBody {

		.adminSection {

			.adminFormError {
				text-align: center;
				margin: $customerBulkUploadMargin 0;
				font-weight: 700;
				color: $customerBulkUploadErrorColor;
			}
			
			.buComponentWrapper {

			}
		}
	}
}


/////////////////////////////
// CustomerBulkUploadInput //
/////////////////////////////


// Titan colors
$customerBulkUploadFileDropzoneAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$customerBulkUploadFileDropzoneBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$customerBulkUploadFileDropzoneBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$customerBulkUploadFileDropzoneBorder: $TITAN_ADMIN_PAGE_BACKGROUND;
$customerBulkUploadFileDropzoneBorderHover: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$customerBulkUploadFileDropzoneCloseWidth: 20px;
$customerBulkUploadFileDropzoneIconWidth: 60px;
$customerBulkUploadFileDropzoneFileWidth: 360px;
$customerBulkUploadGuidanceHeight: 20px;
$customerBulkUploadGuidanceBodyHeight: 300px;
$customerBulkUploadGuidanceTableNameWidth: 120px;
$customerBulkUploadGuidanceTableDescription: 120px;
$customerBulkUploadTemplateHeight: 14px;


.CustomerBulkUploadInput {

	.bulkUploadInputForm {

		.uploadGuidanceWrapper {

			margin: 0 0 0 0;

			.guidanceHeader {

				.guidanceHeaderLiner {

					display: inline-block;
					cursor: pointer;

					.ghIcon {
						display: inline-block;
						vertical-align: top;
						width: 0.6*$customerBulkUploadGuidanceHeight;
						height: 0.6*$customerBulkUploadGuidanceHeight;
						padding: 0.2*$customerBulkUploadGuidanceHeight 0.4*$customerBulkUploadGuidanceHeight 0.2*$customerBulkUploadGuidanceHeight 0;

						.ghIconWrapper.open {
							transform: rotate(0deg);
							transition: transform $slideTransition;
						}

						.ghIconWrapper {
							width: 0.6*$customerBulkUploadGuidanceHeight;
							height: 0.6*$customerBulkUploadGuidanceHeight;
							transform: rotate(-90deg);
							transition: transform $slideFastTransition;
						}
					}

					.ghLabel {
						display: inline-block;
						vertical-align: top;
						height: $customerBulkUploadGuidanceHeight;
						line-height: $customerBulkUploadGuidanceHeight;
						font-size: 16px;
						font-weight: 400;
					}
				}
			}

			.guidanceBody.open {
				max-height: $customerBulkUploadGuidanceBodyHeight;
				transition: max-height $slideTransition;
			}

			.guidanceBody {

				overflow: hidden;
				height: auto;
				max-height: 0;
				transition: max-height $slideFastTransition;

				.guidanceBodyLiner {

					.guidanceRowExplanation {

						.schemaTable {

							margin: $customerBulkUploadMargin 0 0 0;

							.schemaRow {

								font-size: 14px;
								margin: 0 0 $customerBulkUploadMargin/4 0;

								.headerCell {
									font-size: 16px;
									font-weight: 700;
									margin: 0 0 $customerBulkUploadMargin/2 0;

									.requiredWrapper {

										font-size: 14px;
										font-weight: 400;
										padding: 0 0 0 $customerBulkUploadMargin/2;

										&:before {
											content: '*';
											color: $customerBulkUploadAccentColor;
											font-weight: 700;
										}
									}
								}

								.cellKey {

									display: inline-block;
									vertical-align: top;

									.required.cellKeyWrapper {

										&:before {
											content: '*';
											color: $customerBulkUploadAccentColor;
											font-weight: 700;
										}
									}

									.cellKeyWrapper {
										display: inline-block;		
									}
								}

								.cellDesc {
									display: inline-block;
									vertical-align: top;
									margin: 0 0 0 $customerBulkUploadMargin;
									font-weight: 300;
								}
							}
						}
					}

					.templateWrapper {

						display: inline-block;
						cursor: pointer;
						color: $customerBulkUploadAccentColor;
						margin: $customerBulkUploadMargin/2 0 0 0;
						transition: color $hoverTransition;

						&:hover {
							color: $customerBulkUploadAccentColorHover;

							.templateIcon {

								svg {
									fill: $customerBulkUploadAccentColorHover;
								}
							}
						}

						.templateLabel {
							display: inline-block;
							vertical-align: top;
							margin: 0 $customerBulkUploadMargin/2 0 0;
							height: $customerBulkUploadTemplateHeight;
							line-height: $customerBulkUploadTemplateHeight;
							font-size: $customerBulkUploadTemplateHeight;
							font-weight: 700;
						}

						.templateIcon {
							display: inline-block;
							vertical-align: top;
							margin: 0;
							height: $customerBulkUploadTemplateHeight;
							width: $customerBulkUploadTemplateHeight;

							svg {
								fill: $customerBulkUploadAccentColor;
							}
						}
					}
				}
			}
		}

		.firstConfigElement.adminFieldWrapper {
			margin-top: $customerBulkUploadMargin;
		}

		.fileDropzoneWrapper {

			.fileError {

			}

			.dropWrapper.hoverFile {
				background: $customerBulkUploadFileDropzoneBackgroundHover;
				border: 1px solid $customerBulkUploadFileDropzoneBorderHover;
			}

			.dropWrapper {
				margin: 2*$customerBulkUploadMargin 0 0 0;
				background: $customerBulkUploadFileDropzoneBackground;
				border: 1px solid $customerBulkUploadFileDropzoneBorder;
				padding: 3*$customerBulkUploadMargin $customerBulkUploadMargin;
				text-align: center;
				transition: background $hoverTransition, border $hoverTransition;

				.dropPrompt {
					font-size: 28px;
					font-weight: 300;
					margin: 0 0 2*$customerBulkUploadMargin 0;
				}

				.dropFileDisplay {

					position: relative;
					text-align: center;
					background: $customerBulkUploadBackground;
					margin: 0 auto;
					padding: $customerBulkUploadMargin;
					width: $customerBulkUploadFileDropzoneFileWidth;

					.dropFileIconWrapper {
						display: inline-block;
						vertical-align: top;
						width: $customerBulkUploadFileDropzoneIconWidth;
						margin: 0 $customerBulkUploadMargin 0 0;
					}

					.dropFileDetailsWrapper {

						display: inline-block;
						vertical-align: top;
						width: $customerBulkUploadFileDropzoneFileWidth - $customerBulkUploadFileDropzoneIconWidth - $customerBulkUploadMargin;
						text-align: left;

						.nameLabel {
							font-size: 24px;
							font-weight: 400;
						}

						.metaLabel {
							font-size: 16px;
							font-weight: 300;
						}

						.removeLabel {
							display: inline-block;
							vertical-align: top;
							font-size: 12px;
							font-weight: 700;
							text-transform: uppercase;
							margin: $customerBulkUploadMargin/2 0 0 0;
							cursor: pointer;
							color: $customerBulkUploadAccentColor;
							transition: color $hoverTransition;

							&:hover {
								color: $customerBulkUploadAccentColorHover;
							}
						}
					}

					.dropFileCloseWrapper {
						position: absolute;
						top: -0.3*$customerBulkUploadFileDropzoneCloseWidth;
						right: -0.3*$customerBulkUploadFileDropzoneCloseWidth;
						width: $customerBulkUploadFileDropzoneCloseWidth;
						height: $customerBulkUploadFileDropzoneCloseWidth;

						cursor: pointer;
						color: $customerBulkUploadAccentColor;
						background: $customerBulkUploadBackground;
						border: 1px solid $customerBulkUploadColor;
						border-radius: 50%;
						font-size: $customerBulkUploadFileDropzoneCloseWidth;
						font-weight: 700;
						transition: color $hoverTransition;

						&:hover {
							color: $customerBulkUploadAccentColorHover;
						}
					}
				}

				.dropError {
					text-align: center;
					color: $errorRed;
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 2*$customerBulkUploadMargin 0;
				}

				.dropButton {
					display: inline-block;
					color: $customerBulkUploadFileDropzoneAccentText;
					background: $customerBulkUploadAccentColor;
					vertical-align: top;
					cursor: pointer;
					border: none;
					margin: 0 0 0 $customerBulkUploadMargin;
					padding: $customerBulkUploadMargin 4*$customerBulkUploadMargin;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

					@media #{$break1024} {
						padding: 2*$customerBulkUploadMargin/2 3*$customerBulkUploadMargin;
					}

					&:hover {
						background: $customerBulkUploadAccentColorHover;
					}
				}
			}
		}
	}
}


///////////////////////////////
// CustomerBulkUploadResults //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$customerBulkUploadResultsItemWidth: 100px;
$customerBulkUploadResultsMaxWidth: 600px;
$customerBulkUploadResultsCompleteHeight: 240px;


.CustomerBulkUploadResults {

	.burLiner {

		.burSummaryWrapper {

			margin: 0 auto;

			.burSummaryLoading {

				padding: 2*$customerBulkUploadMargin 0;

				.burSummaryLoadingValue {
					font-size: 24px;
					font-weight: 300;
					text-align: center;
				}
			}

			.burSummary {

				margin: 2*$customerBulkUploadMargin 0 2*$customerBulkUploadMargin 0;
				text-align: center;

				.summaryTitle {
					margin: 2*$customerBulkUploadMargin 0 2*$customerBulkUploadMargin 0;
					font-weight: 700;
					font-size: 20px;
					font-family: $fontLato;
				}

				.summaryItem {

					display: inline-block;
					vertical-align: top;
					width: calc(35% - #{$customerBulkUploadMargin});
					margin: 0 $customerBulkUploadMargin 0 0;
					font-size: 20px;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition, margin $breakTransition;

					@media #{$break1024} {
						width: calc(40% - #{$customerBulkUploadMargin});
					}

					@media #{$break900} {
						display: block;
						margin: 0 auto;
						width: 100%;
						font-size: 18px;
					}

					.summaryItemValue {
						display: inline-block;
						vertical-align: top;
						width: $customerBulkUploadResultsItemWidth;
						font-weight: 400;
						text-align: right;
						transition: width $breakTransition;

						@media #{$break900} {
							width: calc(50% - #{$customerBulkUploadMargin/2});
						}
					}

					.summaryItemLabel {
						display: inline-block;
						vertical-align: top;
						width: calc(100%  - #{$customerBulkUploadResultsItemWidth} - #{$customerBulkUploadMargin});
						font-weight: 300;
						margin: 0 0 0 $customerBulkUploadMargin;
						text-align: left;
						transition: width $breakTransition;

						@media #{$break900} {
							width: calc(50% - #{$customerBulkUploadMargin/2});
						}
					}
				}
			}
		}

		.open.burSuccessWrapper {
			max-height: $customerBulkUploadResultsCompleteHeight;
		}

		.burSuccessWrapper {
			overflow: hidden;
			height: auto;
			max-height: 0;
			transition: max-height $slideTransition;

			.burSuccess {

				width: 90%;
				margin: 0 auto;
				padding: $customerBulkUploadMargin 0 3*$customerBulkUploadMargin 0;
				text-align: center;

				.burSuccessTitle {
					font-size: 26px;
					font-weight: 700;
					font-family: $fontLato;
					margin: 0 0 $customerBulkUploadMargin 0;
				}

				.burSuccessActions {

					.burSuccessLink {
						display: inline-block;
						vertical-align: top;
						text-align: center;
						cursor: pointer;
						padding: $customerBulkUploadMargin 3*$customerBulkUploadMargin;
						font-size: 14px;
						font-weight: 700;
						border: none;
						color: $customerBulkUploadAccentColorText;
						background: $customerBulkUploadAccentColor;
						transition: background $hoverTransition;

						&:hover {
							background: $customerBulkUploadAccentColorHover;
						}
					}
				}
			}
		}

		.burDetailWrapper {
			
		}
	}
}


//////////////////////////////////
// CustomerBulkUploadResultList //
//////////////////////////////////


// Titan colors
$customerBulkUploadResultsListHeaderBackground: $TITAN_PRIMARY_ULTRALIGHT;
$customerBulkUploadResultsListInputBorder: $TITAN_RULE_COLOR;
$customerBulkUploadResultsListLoadingScreen: $TITAN_ADMIN_BLOCK_BACKGROUND;
$customerBulkUploadResultsListTagBackground: $TITAN_ADMIN_PAGE_BACKGROUND;

// Theme colors
// None yet

// General variables
$customerBulkUploadResultsListFontSize: 14px;
$customerBulkUploadResultsListFilterHeight: 20px;
$customerBulkUploadResultsListLoadingIconWidth: 60px;
$customerBulkUploadResultsListPageWidthPercent: 0.67;
$customerBulkUploadResultsListSearchWidth: 300px;
$customerBulkUploadResultsListTypeMinWidth: 100px;


.CustomerBulkUploadResultList {

	.burlLiner {

		.burlPaginationWrapper {
			font-size: $customerBulkUploadResultsListFontSize;

			.pageArrow {
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $customerBulkUploadMargin/2;
				width: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize;
				height: $customerBulkUploadResultsListFontSize;

				&:hover {

					.pageArrowElem.leftArrow {
						border-right: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid $customerBulkUploadColorHover;
					}

					.pageArrowElem {
						border-left: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid $customerBulkUploadColorHover;
					}
				}

				.pageArrowElem.noArrow {
					display: none;
				}

				.pageArrowElem.leftArrow {
					left: unset;
					right: 0;
					border-left: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid transparent;
					border-right: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid $customerBulkUploadColor;
				}

				.pageArrowElem {
					position: absolute;
					top: 2px;
					left: 0;
					width: 0;
					height: 0;

					cursor: pointer;
					border-top: 0.5*$customerBulkUploadResultsListFontSize solid transparent;
					border-bottom: 0.5*$customerBulkUploadResultsListFontSize solid transparent;
					border-left: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid $customerBulkUploadColor;
					border-right: $customerBulkUploadResultsListPageWidthPercent*$customerBulkUploadResultsListFontSize solid transparent;
					transition: border $hoverTransition;
				}
			}

			.pageStatus {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $customerBulkUploadMargin/2;
				height: $customerBulkUploadResultsListFontSize;
			}
		}

		.burlHeader {

			.appliedFilterRow {

				text-align: right;

				.afTagWrapper {

					.afTagLabel {
						display: inline-block;
						vertical-align: top;
					}

					.afTag {

						display: inline-block;
						vertical-align: top;
						background: $customerBulkUploadResultsListTagBackground;
						margin: 0 0 $customerBulkUploadMargin/2 $customerBulkUploadMargin/2;
						border-radius: $customerBulkUploadMargin/2;

						.afTagLiner {

							position: relative;
							padding: $customerBulkUploadMargin/3;
							font-size: 14px;

							.afTagValue {
								display: inline-block;
								vertical-align: top;
								line-height: 14px;
								font-weight: 300;
								padding: 0 $customerBulkUploadMargin/3 0 0;
								border-right: 1px solid $customerBulkUploadResultsListInputBorder;
							}

							.afTagAction {
								display: inline-block;
								vertical-align: top;
								line-height: 14px;
								font-weight: 400;
								cursor: pointer;
								padding: 0 0 0 $customerBulkUploadMargin/3;
								transition: font-weight $hoverTransition;

								&:hover {
									font-weight: 700;
								}
							}
						}
					}
				}
			}

			.filterRow {

				position: relative;
				margin: 0 0 $customerBulkUploadMargin/2 0;
				height: $customerBulkUploadResultsListFilterHeight;
				transition: height $breakTransition;

				@media #{$break768} {
					height: 2*$customerBulkUploadResultsListFilterHeight + $customerBulkUploadMargin/2;
				}

				.typeWrapper {

					margin: 0;
					transition: margin $breakTransition;

					@media #{$break768} {
						margin: 0 0 $customerBulkUploadMargin/2 0;
					}

					.filterLabel {
						display: inline-block;
						vertical-align: top;
						height: $customerBulkUploadResultsListFilterHeight;
						line-height: $customerBulkUploadResultsListFilterHeight;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;

						&:after {
							content: ':';
						}
					}

					.filterInputWrapper {
						display: inline-block;
						vertical-align: top;
						height: $customerBulkUploadResultsListFilterHeight;
						margin: 0 0 0 $customerBulkUploadMargin;
						min-width: $customerBulkUploadResultsListTypeMinWidth;

						.filterTypeDropdown.Dropdown {
							margin-top: ($customerBulkUploadResultsListFilterHeight - 2*$customerBulkUploadMargin)/2;
						}
					}
				}

				.searchWrapper {

					position: absolute;
					top: 0;
					right: 0;
					width: $customerBulkUploadResultsListSearchWidth;
					height: $customerBulkUploadResultsListFilterHeight;
					transition: width $breakTransition;

					@media #{$break768} {
						position: relative;
						top: unset;
						right: unset;
						width: 100%;
					}

					.searchInput {
						border-top: none;
						border-left: none;
						border-right: none;
						border-bottom: 1px solid $customerBulkUploadResultsListInputBorder;

						width: calc(100% - #{$customerBulkUploadResultsListFilterHeight} - #{$customerBulkUploadMargin});
						height: $customerBulkUploadResultsListFontSize - 1px;
						margin-right: $customerBulkUploadResultsListFilterHeight;
						font-weight: 300;
						padding-top: ($customerBulkUploadResultsListFilterHeight - $customerBulkUploadResultsListFontSize)/2;
						padding-bottom: ($customerBulkUploadResultsListFilterHeight - $customerBulkUploadResultsListFontSize)/2;
						padding-left: $customerBulkUploadMargin/2;
						padding-right: $customerBulkUploadMargin/2;
					}

					.searchIconWrapper {
						cursor: pointer;
						position: absolute;
						top: ($customerBulkUploadResultsListFilterHeight - $customerBulkUploadResultsListFontSize)/2;
						right: ($customerBulkUploadResultsListFilterHeight - $customerBulkUploadResultsListFontSize)/2;
						width: $customerBulkUploadResultsListFontSize;
						height: $customerBulkUploadResultsListFontSize;
					}
				}
			}

			.pageRow {
				
				position: relative;
				padding: $customerBulkUploadMargin/2;
				background: $customerBulkUploadResultsListHeaderBackground;

				.countWrapper {
					font-size: $customerBulkUploadResultsListFontSize;

					.countLoading {
						display: inline-block;
						vertical-align: top;
						width: $customerBulkUploadResultsListFontSize;
						height: $customerBulkUploadResultsListFontSize;
						margin: 0 $customerBulkUploadMargin/2 0 0;
					}

					.countValue {
						display: inline-block;
						vertical-align: top;
						height: $customerBulkUploadResultsListFontSize;
						line-height: $customerBulkUploadResultsListFontSize;
					}
				}

				.pageWrapper {
					position: absolute;
					top: $customerBulkUploadMargin/2;
					right: $customerBulkUploadMargin/2;
					height: calc(100% - #{$customerBulkUploadMargin});
					width: auto;
				}
			}
		}

		.burlResultsWrapper {

			.burlResultsLiner {

				position: relative;

				.loadingView {

					padding: $customerBulkUploadResultsListLoadingIconWidth 0;

					.loadingIconWrapper.emptyIcon {
						svg {
							fill: $customerBulkUploadColor;
						}
					}

					.loadingIconWrapper {
						width: $customerBulkUploadResultsListLoadingIconWidth;
						margin: 0 auto;

						svg {
							fill: $customerBulkUploadAccentColor;
						}
					}

					.loadingCopy {
						text-align: center;
						font-size: 24px;
						font-weight: 300;
						margin: $customerBulkUploadMargin 0 0 0;
					}
				}

				.noResultsView {
					padding: $customerBulkUploadResultsListLoadingIconWidth 0;
				}

				.resultsView {

					margin: 0 0 $customerBulkUploadMargin/2 0;

					.listItemWrapper {
						border-top: 1px solid $customerBulkUploadResultsListInputBorder;

						&:first-child {
							border-top: none;
						}
					}

					.rowProgressIndicator {

						margin: $customerBulkUploadResultsListLoadingIconWidth/2 0 $customerBulkUploadResultsListLoadingIconWidth 0;

						.rowIconWrapper {

							margin: 0 auto;
							width: $customerBulkUploadResultsListLoadingIconWidth/2;
							height: $customerBulkUploadResultsListLoadingIconWidth/2;

							svg {
								fill: $customerBulkUploadAccentColor;
							}
						}
					}
				}

				.burlResultsLoading {

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba($customerBulkUploadResultsListLoadingScreen, 0.75);
					
					.loadingIconWrapper {
						position: absolute;
						top: calc(50% - #{$customerBulkUploadResultsListLoadingIconWidth/2});
						left: calc(50% - #{$customerBulkUploadResultsListLoadingIconWidth/2});
						width: $customerBulkUploadResultsListLoadingIconWidth;
						height: $customerBulkUploadResultsListLoadingIconWidth;

						svg {
							fill: $customerBulkUploadAccentColor;
						}
					}
				}
			}
		}

		.burlPagination {
			
			.paginationWrapper {
				padding: 0 $customerBulkUploadMargin/2 0 0;
				text-align: center;

				.burlPaginationWrapper {

					.pageArrow.rightArrow {
						margin-right: $customerBulkUploadMargin/2;
					}
				}
			}

			.paginationSizeWrapper {

				text-align: center;
				margin: $customerBulkUploadMargin/2 0 0 0;

				.atpSizeCopy {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					font-weight: 300;
					margin: 0 $customerBulkUploadMargin 0 0;
				}

				.atpSizeDropdown {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					border-bottom: 1px solid $customerBulkUploadColor;
					cursor: pointer;

					select {
						border: none;
						background: none;
					}
				}
			}
		}
	}
}


//////////////////////////////////////
// CustomerBulkUploadResultListItem //
//////////////////////////////////////


// Titan colors
$customerBulkUploadResultBackground: $TITAN_BACKGROUND;
$customerBulkUploadResultBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$customerBulkUploadResultListItemDrawerMaxHeight: 1000px;
$customerBulkUploadResultListItemHeaderMinHeight: 40px;
$customerBulkUploadResultListItemRowDataWidth: 40%;
$customerBulkUploadResultListItemStatusWidth: 125px;
$customerBulkUploadResultListItemToggleWidth: 40px;



.CustomerBulkUploadResultListItem {

	background: $customerBulkUploadResultBackground;

	&:hover {

		.burliLiner {

			.headlineWrapper {
				background: $customerBulkUploadResultBackgroundHover;

				.statusWrapper {

					.statusLiner {
						border-right: 1px solid $customerBulkUploadResultsListInputBorder;
					}
				}
			}
		}
	}

	.burliLiner {

		.headlineWrapper {

			cursor: pointer;
			padding: $customerBulkUploadMargin/2 0;
			transition: background $hoverTransition;

			.statusWrapper {

				display: inline-block;
				vertical-align: top;
				width: $customerBulkUploadResultListItemStatusWidth;
				min-height: $customerBulkUploadResultListItemHeaderMinHeight;

				@media #{$break600} {
					width: calc(100% - #{$customerBulkUploadResultListItemToggleWidth} - #{$customerBulkUploadMargin});
				}

				.statusLiner {

					margin: 0 0 0 $customerBulkUploadMargin/2;
					border-right: 1px solid $customerBulkUploadResultBackgroundHover;
					min-height: $customerBulkUploadResultListItemHeaderMinHeight;
					transition: border $hoverTransition;

					.statusValue {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: font-size $breakTransition;

						@media #{$break600} {
							font-size: 16px;
						}
					}

					.statusLineNumber {
						font-size: 13px;
						font-weight: 300;
						transition: font-size $breakTransition;

						@media #{$break600} {
							font-size: 14px;
						}
					}
				}
			}

			.messageWrapper {
				display: inline-block;
				vertical-align: top;
				width: calc(100% - #{$customerBulkUploadResultListItemStatusWidth} - #{$customerBulkUploadResultListItemToggleWidth} - #{2*$customerBulkUploadMargin});
				margin: 0 0 0 $customerBulkUploadMargin;

				@media #{$break600} {
					display: none;
				}

				.messageTitle {

				}

				.messageValue {
					font-size: 13px;
					font-weight: 300;
				}
			}

			.toggleWrapper {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $customerBulkUploadResultListItemToggleWidth;
				height: $customerBulkUploadResultListItemToggleWidth;
				margin: 0 0 0 $customerBulkUploadMargin;

				.toggleElement {
					font-size: $customerBulkUploadResultListItemToggleWidth;
					font-weight: 100;
					user-select: none;
				}
			}
		}

		.drawerWrapper.expanded {
			max-height: $customerBulkUploadResultListItemDrawerMaxHeight;
			transition: max-height $slideTransition;
		}

		.drawerWrapper {
			overflow: hidden;
			height: auto;
			max-height: 0;
			transition: max-height $hoverTransition;

			.drawerLiner {

				margin: $customerBulkUploadMargin 0;

				.rowDataWrapper {

					display: inline-block;
					overflow: hidden;
					vertical-align: top;
					width: $customerBulkUploadResultListItemRowDataWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.rowDataLiner {

						.rowDataTitle {
							font-size: 20px;
							border-bottom: 2px solid $customerBulkUploadAccentColor;
							padding: 0 0 0 $customerBulkUploadMargin/2;
						}

						.rowDataItem {

							padding: $customerBulkUploadMargin/2;
							font-size: 14px;

							&:nth-child(odd) {
								background: $customerBulkUploadResultBackgroundHover;
							}

							.rowDataItemKey {
								display: inline-block;
								vertical-align: top;
								width: 50%;
								font-weight: 700;
								user-select: none;
							}

							.rowDataItemValue {
								display: inline-block;
								vertical-align: top;
								width: 50%;
								font-weight: 400;
							}
						}
					}
				}

				.detailWrapper {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$customerBulkUploadResultListItemRowDataWidth});
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.detailLiner {

						margin: $customerBulkUploadMargin 2*$customerBulkUploadMargin;
						transition: margin $breakTransition;

						@media #{$break768} {
							margin: $customerBulkUploadMargin 0;
						}

						.summaryWrapper {

							margin: 0 0 2*$customerBulkUploadMargin 0;

							.summaryLine {

								margin: 0 0 $customerBulkUploadMargin/2 0;

								.summaryLabel {
									margin: 0 $customerBulkUploadMargin 0 0;
									font-size: 16px;
									font-weight: 700;
									user-select: none;

									&:after {
										content: ':';
									}
								}

								.summaryValue {
									font-size: 16px;
									font-weight: 400;
								}
							}
						}

						.supplementalWrapper {

							.supplementalTitle {
								font-size: 16px;
								font-weight: 700;
								margin: 0 0 $customerBulkUploadMargin 0;

								&:after {
									content: ':';
								}
							}

							.supplementalNone {
								font-size: 16px;
								font-weight: 300;
								font-style: italic;
							}

							.supplementalDatum {

								margin: 0 0 $customerBulkUploadMargin/2 0;
								font-size: 12px;

								.supKey {
									font-family: monospace;
									font-weight: 700;
									text-decoration: underline;
								}

								.supValue {

									.supValueKey {

										display: inline;

										&:after {
											content: ': ';
										}
									}

									.supValueValue {
										display: inline;
									}
								}
							}
						}

						.resolutionWrapper {

						}
					}
				}
			}
		}
	}
}



































