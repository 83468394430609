

@use '../../../../../style/import' as *;




////////////////
// AdminField //
////////////////


// Titan colors
$primaryColor: $TITAN_PRIMARY_COLOR;
$textColor: $TITAN_TEXT_COLOR;
$borderColor: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
$borderWidth: 1px;
$inputFontSize: 20px;
$inputLineHeight: 23px;

$inputHeight: 2*$borderWidth + 2*$MARGIN + $inputLineHeight;


.AdminField {

	display: inline-block;
	vertical-align: top;
	width: 100%;

	&.half {
		width: 50%;

		@media #{$break768} {
			width: 100%;
		}
	}

	&.quarter {
		width: 25%;

		@media #{$break768} {
			width: 50%;
		}
	}

	.label {

		&.required {
			&:after {
				opacity: 1.0;
			}
		}

		color: $textColor;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 $MARGIN_S 0;
		user-select: none;

		&:after {
			content: '*';
			color: $primaryColor;
			font-size: 16px;
			opacity: 0.0;
		}
	}

	.inputWrapper {

		margin: 0 $MARGIN 2*$MARGIN 0;

		input {
			border: $borderWidth solid $borderColor;
			width: calc(100% - 2*#{$MARGIN_L} - 2*$borderWidth);
			font-size: $inputFontSize;
			line-height: $inputLineHeight;
			padding: $MARGIN $MARGIN_L;
			margin: 0;
		}
	}
}













