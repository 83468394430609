

@use '../../../../../style/import' as *;




///////////////////////
// HeaderElementLogo //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HeaderElementLogo {

	.logoLink {

		.logoElement {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: center center; 
			background-size: contain;
		}
	}
}













