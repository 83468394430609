

@use '../../../../style/import' as *;




///////////////////////
// ProductTagManager //
///////////////////////


// Titan colors
$tagManagerPrimaryColor: $TITAN_PRIMARY_COLOR;
$tagManagerPrimaryColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$tagManagerPrimaryColorText: $TITAN_PRIMARY_COLOR_TEXT;
$tagManagerBackground: $TITAN_BACKGROUND;
$tagManagerTextColor: $TITAN_TEXT_COLOR;
$tagManagerTextColorHover: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$tagManagerMargin: 10px;
$tagManagerMaxWidth: 500px;
$tagManagerIconWidth: 60px;

.ProductTagManager {

	.tagManagerWrapper {

		margin: $tagManagerMargin 0 3*$tagManagerMargin 0;
		
		.loadingWrapper {

			.loadingIconWrapper {
				margin: $tagManagerIconWidth auto;
				width: $tagManagerIconWidth;
				height: $tagManagerIconWidth;

				svg {
					fill: $tagManagerPrimaryColor;
				}
			}
		}

		.tagListWrapper {

			margin: 0 auto;
			max-width: $tagManagerMaxWidth;

			.tagList {

			}

			.tagListNoResults {

				margin: $tagManagerMargin 0 0 0;

				.tagListNoResultsCopy {
					font-size: 24px;
					font-weight: 300;
					text-align: center;
				}
			}

			.addWrapper {

				.addOpen {

				}

				.addClosed {

					margin: $tagManagerMargin 0;
					text-align: center;

					.addLink {
						display: inline-block;
						vertical-align: top;
						width: auto;
						cursor: pointer;
						color: $tagManagerPrimaryColor;
						font-size: 18px;
						font-weight: 700;
						transition: color $hoverTransition;

						&:hover {
							color: $tagManagerPrimaryColorHover;
						}
					}
				}
			}
		}
	}
}


////////////////////
// BulkTagManager //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

.BulkTagManager {

	.tagManagerWrapper {

		margin: $tagManagerMargin 0 3*$tagManagerMargin 0;
		
		.loadingWrapper {

			.loadingIconWrapper {
				margin: $tagManagerIconWidth auto;
				width: $tagManagerIconWidth;
				height: $tagManagerIconWidth;

				svg {
					fill: $tagManagerPrimaryColor;
				}
			}
		}

		.tagListWrapper {

			margin: 0 auto;
			max-width: $tagManagerMaxWidth;

			.tagNotice {
				text-align: center;
				font-size: 14px;
				font-weight: 300;
				text-align: center;
				margin: 0 0 $tagManagerMargin 0;
			}

			.tagList {

			}

			.tagListNoResults {

				margin: $tagManagerMargin 0 0 0;

				.tagListNoResultsCopy {
					font-size: 24px;
					font-weight: 300;
					text-align: center;
				}
			}

			.addWrapper {

				.addOpen {

				}

				.addClosed {

					margin: $tagManagerMargin 0;
					text-align: center;

					.addLink {
						display: inline-block;
						vertical-align: top;
						width: auto;
						cursor: pointer;
						color: $tagManagerPrimaryColor;
						font-size: 18px;
						font-weight: 700;
						transition: color $hoverTransition;

						&:hover {
							color: $tagManagerPrimaryColorHover;
						}
					}
				}
			}
		}
	}
}


/////////////////////
// ProductTagEntry //
/////////////////////


// Titan colors
$tagManagerRowBackgroundAlternate: $TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE;
$tagManagerRowBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$tagManagerRowCloseIconHover: $TITAN_PRIMARY_COLOR_HOVER;
$tagManagerRowDeleteColor: $errorRed;
$tagManagerRowDeleteColorHover: $errorRedHover;
$tagManagerRowInputBorder: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$tagManagerRowControlIconScale: 0.7;
$tagManagerRowHeight: 30px;
$tagManagerRowOverlayOpacity: 0.8;

.ProductTagEntry.overlaid {
	
	cursor: default;

	&:hover {
		background: unset;
	}
}

.ProductTagEntry.active {
	
	cursor: default;
	background: $tagManagerRowBackgroundHover;

	&:nth-child(even) {
		background: $tagManagerRowBackgroundHover;
	}
}

.ProductTagEntry {

	cursor: pointer;
	background: $tagManagerBackground;
	transition: background $hoverTransition;

	&:nth-child(even) {
		background: $tagManagerRowBackgroundAlternate;
	}

	&:hover {
		background: $tagManagerRowBackgroundHover;
	}


	.productTagLiner {

		position: relative;
		padding: 0.5*$tagManagerMargin $tagManagerMargin;

		.primaryWrapper {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$tagManagerRowHeight});
			height: $tagManagerRowHeight;

			.primaryDisplay,
			.primaryEditing {
				margin: 0 $tagManagerMargin 0 0;
			}

			.primaryDisplay {
				height: $tagManagerRowHeight;
				line-height: $tagManagerRowHeight;
				font-size: 20px;
			}

			.primaryEditing {
				
				input.inputTagName {
					border: 1px solid $tagManagerRowInputBorder;
					padding: 0.5*$tagManagerMargin $tagManagerMargin;
					font-size: 18px;
					font-weight: 300;
					width: calc(100% - #{$tagManagerRowHeight} - 2px - 2*#{$tagManagerMargin});
					height: $tagManagerRowHeight - 2px - $tagManagerMargin;
				}

				button.submitTagName {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $tagManagerRowHeight;
					height: $tagManagerRowHeight;
					cursor: pointer;
					border: none;
					border-radius: 0;
					background: $tagManagerPrimaryColor;
					transition: background $hoverTransition;

					&:hover {
						background: $tagManagerPrimaryColorHover;
					}

					.submitIconWrapper {
						margin: 0.5 * (1 - $tagManagerRowControlIconScale) * $tagManagerRowHeight;
						width: $tagManagerRowControlIconScale * $tagManagerRowHeight;
						height: $tagManagerRowControlIconScale * $tagManagerRowHeight;

						.Icon {
							width: $tagManagerRowControlIconScale * $tagManagerRowHeight;
							height: $tagManagerRowControlIconScale * $tagManagerRowHeight;

							svg {
								fill: $tagManagerPrimaryColorText;
							}
						}
					}
				}
			}
		}
		
		.controlWrapper {
			display: inline-block;
			vertical-align: top;
			width: $tagManagerRowHeight;
			height: $tagManagerRowHeight;

			.controlDisplay,
			.controlEditing {
				margin: 0.5 * (1 - $tagManagerRowControlIconScale) * $tagManagerRowHeight;
				width: $tagManagerRowControlIconScale * $tagManagerRowHeight;
				height: $tagManagerRowControlIconScale * $tagManagerRowHeight;

				.Icon {
					width: $tagManagerRowControlIconScale * $tagManagerRowHeight;
					height: $tagManagerRowControlIconScale * $tagManagerRowHeight;
				}
			}

			.controlDisplay {

				&:hover {
					svg {
						fill: $tagManagerRowDeleteColorHover;
					}
				}
			}

			.controlEditing {
				cursor: pointer;

				&:hover {
					svg {
						fill: $tagManagerRowCloseIconHover;
					}
				}
			}
		}

		.rowOverlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: $tagManagerRowHeight + $tagManagerMargin;
			text-align: center;
			// pointer-events: none;
			background: rgba($tagManagerBackground, $tagManagerRowOverlayOpacity);
			font-size: 18px;

			.pendingOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $tagManagerRowHeight + $tagManagerMargin;
				// pointer-events: initial;

				.pendingCopy {
					display: inline-block;
					vertical-align: top;
					height: $tagManagerRowHeight + $tagManagerMargin;
					line-height: $tagManagerRowHeight + $tagManagerMargin;
					font-style: italic;

					&:after {
						content: '...';
					}
				}
			}

			.deleteOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $tagManagerRowHeight + $tagManagerMargin;
				// pointer-events: initial;

				.deleteCopy {
					display: inline-block;
					vertical-align: top;
					height: $tagManagerRowHeight + $tagManagerMargin;
					line-height: $tagManagerRowHeight + $tagManagerMargin;
					margin: 0 0.5*$tagManagerMargin;
				}

				.deleteConfirm {
					display: inline-block;
					vertical-align: top;
					height: $tagManagerRowHeight + $tagManagerMargin;
					line-height: $tagManagerRowHeight + $tagManagerMargin;
					margin: 0 0.5*$tagManagerMargin;
					font-size: 14px;
					font-weight: 700;
					color: $tagManagerRowDeleteColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $tagManagerRowDeleteColorHover;
					}
				}

				.deleteCancel {
					display: inline-block;
					vertical-align: top;
					height: $tagManagerRowHeight + $tagManagerMargin;
					line-height: $tagManagerRowHeight + $tagManagerMargin;
					margin: 0 0.5*$tagManagerMargin;
					font-size: 14px;
					font-weight: 400;
					color: $tagManagerTextColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $tagManagerTextColorHover;
					}
				}
			}
		}
	}
}









