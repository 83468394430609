

@use '../../../../../../../style/import' as *;




/////////////////
// BannerSlide //
/////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.BannerSlide {

	.linkWrapper {
		cursor: pointer;
	}

	.bannerWrapper {

		position: relative;

		img {
			width: 100%;
		}

		.desktop {
			display: block;

			@media #{$break768} {
				display: none;
			}
		}

		.tablet {
			display: none;

			@media #{$break768} {
				display: block;
			}

			@media #{$break768} {
				display: none;
			}
		}

		.mobile {
			display: none;

			@media #{$break768} {
				display: block;
			}
		}
	}
}













