

@use '../../../../style/import' as *;
@use './header-constants' as *;




///////////////////////
// HeaderSubmenuCore //
///////////////////////


// Titan colors
// None yet

// Theme colors
$headerSubmenuLinkBackgroundHover: $THEME_PRIMARY_ULTRALIGHT;

// General variables
$headerSubmenuMargin: 10px;
$headerSubmenuPadding: 25px;
$headerSubmenuPaddingMobile: 10px;



.HeaderSubmenuCore {

	padding: 0 $headerSubmenuPadding;
	transition: padding $breakTransition;

	@media #{$break600} {
		padding: 0 $headerSubmenuPaddingMobile;
	}

	.humSection {

		margin: 0 0 $headerSubmenuPadding 0;

		.humSectionTitle {
			margin: 0 0 $headerSubmenuMargin 0;
			font-family: $fontLato;
			font-weight: 700;
			font-size: 22px;
			color: $headerColor;
			user-select: none;
		}

		.humSectionBody {
			
			.noLinksItem {
				font-weight: 300;
				font-style: italic;
				color: $headerColor;
			}

			a.humSectionLink, .humSectionLink {

				&.withSublinks {
					padding: 0.25*$headerSubmenuMargin $headerSubmenuMargin;
				}

				display: block;
				padding: 0.5*$headerSubmenuMargin $headerSubmenuMargin;
				margin: 0.25*$headerSubmenuMargin 0;
				cursor: pointer;
				color: $headerColor;
				background: $headerBackground;
				font-size: 16px;
				font-weight: 700;
				transition: background $hoverTransition, color $hoverTransition;

				&:hover {
					color: $headerColorHover;
					background: $headerSubmenuLinkBackgroundHover;
				}
			}

			a.humSectionSublink, .humSectionSublink {
				display: block;
				padding: 0.25*$headerSubmenuMargin $headerSubmenuMargin 0.25*$headerSubmenuMargin 2*$headerSubmenuMargin;
				margin: 0.25*$headerSubmenuMargin 0;
				font-weight: 300;
				cursor: pointer;
				color: $headerColor;
				background: $headerBackground;
				transition: background $hoverTransition, color $hoverTransition;

				&:hover {
					color: $headerColorHover;
					background: $headerSubmenuLinkBackgroundHover;
				}
			}
		}
	}
}

.HeaderSubmenuBuy {

}

.HeaderSubmenuSell {

}

.HeaderSubmenuPlay {

}

.HeaderSubmenuUser {

}