

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



////////////////////
// HeaderUserMenu //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$menuMaxHeight: 600px;


.HeaderUserMenu {

	width: $headerAccountWidth + $headerCartWidth + (3 * $MARGIN_L);

	position: absolute;
	overflow: hidden;
	background: $headerBackground;

	top: $headerHeight;
	right: 0;
	height: auto;
	z-index: 1000;

	.humOuterWrapper {

		overflow: auto;
		max-height: 0;
		transition: max-height $slideFastTransition;

		&.open {
			max-height: $menuMaxHeight;
			transition: max-height $slideTransition;

			.humInnerWrapper {
				padding: $MARGIN $MARGIN_L 0 $MARGIN_L;
			}
		}

		.humInnerWrapper {
			padding: 0;
		}
	}
}













