

@use '../../../../style/import' as *;




///////////////
// LegalPage //
///////////////


// Titan colors
// None yet

// Theme colors
$legalPrimaryColor: $THEME_PRIMARY_COLOR;
$legalPrimaryColorHover: $THEME_PRIMARY_COLOR_HOVER;
$legalPrimaryColorText: $THEME_PRIMARY_COLOR_TEXT;
$legalBackground: $THEME_BACKGROUND;
$legalTextColor: $THEME_TEXT_COLOR;

// General variables
$legalMargin: 10px;
$legalLoadingIconWidth: 60px;
$legalBodyWidth: 900px;
$legalHeaderPadding: 120px;


.LegalPage {

	color: $legalTextColor;

	.legalLiner {

		.privacy.legalHeader {
			background-image: url('../img/privacy.jpg');
		}

		.returns.legalHeader {
			background-image: url('../img/returns1.jpg');
		}

		.legalHeader {

			position: relative;
			overflow: auto;
			background-image: url('../img/default.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			.titleWrapper {

				overflow: auto;
				box-shadow: 0 0 $legalMargin $legalMargin/2 $legalTextColor inset;
				background: rgba($legalTextColor, 0.8);

				.pageTitle {

					margin: $legalHeaderPadding 0;
					text-align: center;
					color: $legalBackground;

					.pageTitleCopy {
						display: inline-block;
						vertical-align: middle;
						font-size: 40px;
						font-weight: 400;
					}
				}
			}
		}

		.legalLoading {

			.loadingWrapper {

				margin: $legalLoadingIconWidth 0;

				.loadingIconWrapper {
					width: $legalLoadingIconWidth;
					height: $legalLoadingIconWidth;
					margin: 0 auto $legalMargin auto;

					svg {
						fill: $legalPrimaryColor;
					}
				}

				.loadingLabel {
					text-align: center;
					font-size: 24px;
					font-weight: 300;
				}
			}
		}

		.legalBodyWrapper {

			.legalBody {

				width: 90%;
				max-width: $legalBodyWidth;
				margin: 3*$legalMargin auto;

				.legalTitle {
					margin: 0 0 3*$legalMargin 0;
					text-align: center;
					font-size: 36px;
					font-weight: 700;
				}

				.legalSection {

					margin: 0 0 3*$legalMargin 0;

					.sectionTitle {
						margin: 0 0 2*$legalMargin 0;
						font-size: 22px;
						font-weight: 700;
					}

					.sectionSubtitle {
						margin: 2*$legalMargin 0 $legalMargin 0;
						font-size: 18px;
						font-weight: 700;
						text-decoration: underline;
					}

					.listHeader.sectionP {
						margin: 0;
					}

					.pHeader.sectionP {
						margin: 0;
						text-decoration: underline;
					}

					.sectionP {
						margin: 0 0 $legalMargin 0;
						font-size: 18px;
					}

					.sectionUL {
						margin: 0 0 $legalMargin 5*$legalMargin;
						font-size: 18px;
					}

					.inlineLink {
						color: $legalPrimaryColor;
						font-weight: 700;
						cursor: pointer;
						transition: color $hoverTransition;

						&:hover {
							color: $legalPrimaryColorHover;
						}
					}

					.legalEm {
						font-weight: 700;
					}
				}
			}
		}

		.legalActionWrapper {

			text-align: center;
			margin: 0 0 3*$legalMargin 0;

			.legalPrimaryAction {
				color: $legalPrimaryColorText;
				background: $legalPrimaryColor;
				border: none;
				padding: $legalMargin 2*$legalMargin;
				cursor: pointer;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				transition: background $hoverTransition;

				&:hover {
					background: $legalPrimaryColorHover;
				}
			}
		}
	}
}













