

@use '../../../../style/import' as *;
// @import './header';

// Titan constants
// None yet

// Theme constants
$headerSearchAccentColor: $THEME_PRIMARY_COLOR;
$headerSearchAccentColorHover: $THEME_PRIMARY_COLOR_HOVER;
$headerSearchAccentColorText: $THEME_PRIMARY_COLOR_TEXT;
// $productSearchColor: $THEME_TEXT_COLOR;
// $productSearchFilterBackground: $THEME_BACKGROUND;
// $productSearchBodyBackground: $THEME_BACKGROUND_CONTRAST;

$headerAutocompleteBorderColor: $THEME_TEXT_COLOR_LIGHT;
$headerAutocompleteBackgroundColor: $THEME_BACKGROUND;
$headerAutocompleteBackgroundColorHover: $THEME_PRIMARY_ULTRALIGHT;

// General variables
$headerSearchButtonIconMargin: 6px;
$headerSearchButtonWidth: 100px;
$headerSearchButtonWidthTablet: 60px;
$headerSearchButtonWidthMobile: 40px;
$headerSearchMargin: 10px;

$headerAutocompleteMaxHeight: 800px;

// Component variables
// None yet


.HeaderSearch {


	.searchInnerWrapper {

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background: white;
		border: 1px solid $headerSearchAccentColor;

		.searchInputWrapper {

			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: calc(100% - #{$headerSearchButtonWidth});
			transition: width $breakTransition;

			@media #{$break1200} {
				width: calc(100% - #{$headerSearchButtonWidthTablet});
			}

			@media #{$break600} {
				width: calc(100% - #{$headerSearchButtonWidthMobile});
			}

			input {
				border: 0;
				margin: 0;
				padding: $headerSearchMargin 2*$headerSearchMargin;
				width: calc(100% - #{4*$headerSearchMargin});
				height: calc(100% - #{2*$headerSearchMargin} - 2px);
				transition: width $breakTransition, height $breakTransition, padding $breakTransition;

				@media #{$break600} {
					padding: 0.5*$headerSearchMargin $headerSearchMargin;
					width: calc(100% - #{2*$headerSearchMargin});
					height: calc(100% - #{1*$headerSearchMargin} - 2px);
				}
			}
		}

		.searchButtonWrapper {

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: $headerSearchButtonWidth;

			cursor: pointer;
			background: $headerSearchAccentColor;
			color: $headerSearchAccentColorText;
			transition: background $hoverTransition, width $breakTransition;

			&:hover {
				background: $headerSearchAccentColorHover;
			}

			@media #{$break1200} {
				width: $headerSearchButtonWidthTablet;
			}

			@media #{$break600} {
				width: $headerSearchButtonWidthMobile;
			}

			.searchButtonInnerWrapper {
				
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;

				@media #{$break1200} {
					display: none;
				}
			}

			.searchButtonInnerWrapperTablet {

				display: none;
				
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				@media #{$break1200} {
					display: block;
				}

				.searchTabletIcon {
					position: absolute;
					top: $headerSearchButtonIconMargin;
					right: $headerSearchButtonIconMargin;
					bottom: $headerSearchButtonIconMargin;
					left: $headerSearchButtonIconMargin;

					svg {
						fill: $headerSearchAccentColorText;
					}
				}
			}
		}

		.searchAutocompleteWrapper {
			position: absolute;
			overflow: hidden;
			left: -1px;
			right: -1px;
			top: calc(100% - 1px);
			height: auto;
			max-height: 0;
			z-index: $zUserMenuLayer;
			// No transition intentionally, should close instantly

			border-left: 1px solid $headerAutocompleteBorderColor;
			border-right: 1px solid $headerAutocompleteBorderColor;
			background: $headerAutocompleteBackgroundColor;

			&.open {
				max-height: $headerAutocompleteMaxHeight;
				transition: max-height $slideTransition;
			}

			.optionsLiner {
				border-bottom: 1px solid $headerAutocompleteBorderColor;

				.optionsList {

					.hovered.optionElementWrapper {
						background: $headerAutocompleteBackgroundColorHover;
					}

					.optionElementWrapper {
						cursor: pointer;
						padding: 0.5 * $headerSearchMargin;
						background: $headerAutocompleteBackgroundColor;
						transition: background $hoverTransition;
					}
				}
			}

			.optionsNull {

			}
		}
	}

}