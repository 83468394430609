

@use '../../../../style/import' as *;




///////////////////
// AdminOverview //
///////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AdminOverview {

	.sectionHeader {
		font-size: 15px;
		font-weight: 700;
		margin: $MARGIN 0;

		&:first-child {
			margin-top: 0;
		}
	}

	.sectionCopy {
		font-size: 14px;
		font-weight: 400;
		margin: 0 0 $MARGIN 0;
	}

	.actionWrapper {
		text-align: right;
	}
}













