

@use '../../../../style/import' as *;
@use './header-constants' as *;



//////////////////////
// HeaderCenterLogo //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HeaderCenterLogo {

	.displayWrapper {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $headerBackground;
		z-index: $zHeaderLayer;

		.hamburgerWrapper {

			position: absolute;
			top: calc(0.5 * ($headerHeight - $headerIconWidth));
			bottom: calc(0.5 * ($headerHeight - $headerIconWidth));
			left: $headerMargin;
			width: $headerIconWidth;
			cursor: pointer;

			@media #{$break600} {
				top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				left: $headerMarginMobile;
				width: $headerIconWidthMobile;
			}
		}

		.logoWrapper {
			position: absolute;
			left: calc(50% - #{0.5 * $headerLogoWidth});
			top: calc(0.5 * ($headerHeight - $headerLogoHeight));
			height: $headerLogoHeight;
			width: $headerLogoWidth;
			transition: top $breakTransition, bottom $breakTransition, height $breakTransition;

			@media #{$break600} {
				left: calc(50% - #{0.5 * $headerLogoWidthMobile});
				top: calc(0.5 * ($headerHeightMobile - $headerLogoHeightMobile));
				height: $headerLogoHeightMobile;
				width: $headerLogoWidthMobile;
			}
		}

		// .searchDrawerWrapper {

		// 	display: none;
		// 	position: absolute;
		// 	top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
		// 	bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
		// 	right: $headerIconWidthMobile + (2 * $headerMarginMobile);
		// 	width: $headerIconWidthMobile;

		// 	@media #{$break600} {
		// 		display: block;
		// 	}
		// }

		.accountWrapper {
			position: absolute;
			right: $headerCartWidth + (2 * $headerMargin);
			width: $headerAccountWidth;
			top: calc(0.5 * ($headerHeight - $headerAccountHeight));
			bottom: calc(0.5 * ($headerHeight - $headerAccountHeight));

			@media #{$break1024} {
				width: $headerIconWidth;
			}

			@media #{$break600} {
				right: $headerIconWidthMobile + (2 * $headerMarginMobile);
				width: $headerIconWidthMobile;
				top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
			}
		}

		.cartWrapper {

			position: absolute;
			top: calc(0.5 * ($headerHeight - $headerCartHeight));
			bottom: calc(0.5 * ($headerHeight - $headerCartHeight));
			right: $headerMargin;
			width: $headerCartWidth;
			cursor: pointer;

			@media #{$break600} {
				top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				right: $headerMarginMobile;
				width: $headerIconWidthMobile;
			}
		}

	}
}













