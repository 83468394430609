

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



////////////////////////////
// HeaderElementHamburger //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HeaderElementHamburger {

	.hamburgerIconWrapper {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		svg {

			fill: $headerColor;

			&:hover {
				fill: $headerAccentColor;
			}
		}
	}
}













