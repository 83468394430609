

@use '../../../../style/import' as *;




/////////////////////
// ShippingMethods //
/////////////////////


// Titan colors
$shippingAccent: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$shippingMethodMargin: 10px;


.ShippingMethods {

}


///////////////////////
// AddShippingMethod //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$addShippingLoadingIntegrationWidth: 24px;


.AddShippingMethod {

	.adminBody {

		.adminForm {

			.addShippingMethodForm {

				.adminFieldWrapper {

					.integratonsLoading {

						.loadingWrapping {

							.iconWrapper {

								display: inline-block;
								vertical-align: top;
								width: $addShippingLoadingIntegrationWidth;
								
								.iconLiner {
									width: $addShippingLoadingIntegrationWidth;
									height: $addShippingLoadingIntegrationWidth;

									svg {
										fill: $shippingAccent;
									}
								}
							}

							.copyWrapper {

								display: inline-block;
								vertical-align: top;
								width: calc(100% - #{$addShippingLoadingIntegrationWidth});
								
								.copyLiner {
									position: relative;
									margin: 0 0 0 $shippingMethodMargin;
									height: $addShippingLoadingIntegrationWidth;
									font-size: 14px;
									font-weight: 300;
								}
							}
						}
					}
				}
			}
		}
	}
}


////////////////////////
// ViewShippingMethod //
////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ViewShippingMethod {

}


///////////////////////////////
// ShippingMethodDetailsView //
///////////////////////////////


// Titan colors
$shippingMethodDisabledColor: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet


.ShippingMethodDetailsView {

	.detailsMethodDisabled {
		color: $shippingMethodDisabledColor;
		text-align: center;
		margin: 2*$shippingMethodMargin 0;
		font-size: 20px;
		font-weight: 700;
	}
}













