

@use '../../../../style/import' as *;

$toggleHeight: 24px;
$toggleWidth: 48px;
$toggleSpacing: 2px;

$toggleBackground: $TITAN_BACKGROUND;
$toggleBackgroundDisabled: $TITAN_ADMIN_PAGE_BACKGROUND;

$toggleBackgroundActive: $TITAN_PRIMARY_COLOR;
$toggleBackgroundActiveDisabled: $disabledColor;
$toggleBackgroundInactive: $TITAN_ADMIN_PAGE_BACKGROUND;
$toggleBackgroundInactiveDisabled: $disabledColor;


.Toggle.absolute {

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-align: center;

	.toggleWrapper {

		.toggleLabel {

			input:checked+.toggleSlider {

				&:before {
					left: calc(50% + #{$toggleSpacing});
					transform: none;
				}
			}

			.toggleSlider {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 25% / 50%;

				&:before {
					height: calc(100% - #{2*$toggleSpacing});
					width: calc(50% - #{2*$toggleSpacing});
					border-radius: 50%;
				}
			}
		}
	}
}

.Toggle {

	position: relative;
	cursor: pointer;
	display: inline-block;
	text-align: center;

	.toggleWrapper.disabled {
		.toggleLabel {

			input:checked+.toggleSlider {
				background: $toggleBackgroundActiveDisabled;
			}

			.toggleSlider {
				background: $toggleBackgroundInactiveDisabled;
				&:before {
					background: $toggleBackgroundDisabled;
				}
			}
		}
	}

	.toggleWrapper {

		.toggleLabel {

			.bottomLabel.toggleDisplay {
				display: block;
				margin: $toggleSpacing 0 0 0;
				font-size: 12px;
			}

			.toggleDisplay {
				display: inline-block;
				vertical-align: top;
				height: $toggleHeight;
				line-height: $toggleHeight;
				user-select: none;
				margin: 0 0 0 5*$toggleSpacing;
				font-size: 18px;
				font-weight: 300;
				text-transform: uppercase;
			}

			.toggleCheckbox {
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				visibility: hidden;
			}

			input:checked+.toggleSlider {
				background: $toggleBackgroundActive;

				&:before {
					transform: translateX(#{$toggleWidth - $toggleHeight});
				}
			}

			.toggleSlider {
				position: relative;
				display: inline-block;
				vertical-align: top;
				cursor: pointer;
				background: $toggleBackgroundInactive;
				width: $toggleWidth;
				height: $toggleHeight;
				border-radius: 0.5*$toggleHeight;
				transition: background $toggleTransition;

				&:before {
					content: '';
					position: absolute;
					top: $toggleSpacing;
					left: $toggleSpacing;
					height: $toggleHeight - 2*$toggleSpacing;
					width: $toggleHeight - 2*$toggleSpacing;
					border-radius: 50%;
					background: $toggleBackground;
					transition: transform $toggleTransition;
				}
			}
		}
	}
}