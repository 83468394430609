

@use '../../../../style/import' as *;


// Titan colors
$autocompleteAdminBorderColor: $TITAN_TEXT_COLOR_LIGHT;
$autocompleteAdminPrimaryColor: $TITAN_PRIMARY_COLOR;
$autocompleteAdminBackgroundColor: $TITAN_BACKGROUND;
$autocompleteAdminHover: $TITAN_PRIMARY_ULTRALIGHT;
$autocompleteAdminErrorColor: $errorRed;

// Theme colors
$autocompleteBorderColor: $THEME_TEXT_COLOR_LIGHT;
$autocompletePrimaryColor: $THEME_PRIMARY_COLOR;
$autocompleteBackgroundColor: $THEME_BACKGROUND;
$autocompleteHover: $THEME_PRIMARY_ULTRALIGHT;
$autocompleteErrorColor: $errorRed;

// General variables
$autocompleteBorderWidth: 1px;
$autocompleteMargin: 5px;
$autocompleteMaxHeight: 800px;

// Component variables
// None yet


.AutocompleteField.adminTheme {

	.autocompleteLiner {

		.autocompleteInputWrapper {

			.autocompleteInput {
				border-color: $autocompleteAdminBorderColor;
			}
		}
	}

	.optionsWrapper {
		border-left: 1px solid $autocompleteAdminBorderColor;
		border-right: 1px solid $autocompleteAdminBorderColor;
		background: $autocompleteAdminBackgroundColor;

		.optionsLiner {
			border-bottom: 1px solid $autocompleteAdminBorderColor;

			.optionsList {

				.hovered.optionElementWrapper {
					background: $autocompleteAdminHover;
				}

				.optionElementWrapper {
					background: $autocompleteAdminBackgroundColor;
				}
			}
		}
	}
}


.AutocompleteField {

	position: relative;

	.autocompleteLiner {

		.autocompleteInputWrapper {

			.autocompleteInput {
				border-color: $autocompleteBorderColor;
			}
		}
	}

	.open.optionsWrapper {
		max-height: $autocompleteMaxHeight;
		transition: max-height $slideTransition;
	}

	.optionsWrapper {
		position: absolute;
		overflow: hidden;
		left: 0;
		right: 0;
		top: calc(100% - 1px);
		height: auto;
		max-height: 0;
		z-index: $zUserMenuLayer;
		// No transition intentionally, should close instantly

		border-left: 1px solid $autocompleteBorderColor;
		border-right: 1px solid $autocompleteBorderColor;
		background: $autocompleteBackgroundColor;	

		.optionsLiner {
			border-bottom: 1px solid $autocompleteBorderColor;

			.optionsList {

				.hovered.optionElementWrapper {
					background: $autocompleteHover;
				}

				.optionElementWrapper {
					cursor: pointer;
					background: $autocompleteBackgroundColor;
					transition: background $hoverTransition;
				}
			}
		}

		.optionsNull {

		}
	}
}


//////////////////////
// AutocompleteNull //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$autocompleteNullMargin: 40px;


.AutocompleteNull {

	.nullLiner {

		text-align: center;
		padding: $autocompleteNullMargin $autocompleteMargin;

		.noResultsCopy {
			font-size: 18px;
			font-weight: 300;
		}

		.noResultsNeedle {
			margin: 2*$autocompleteMargin 0 0 0;
			font-size: 16px;
			font-weight: 700;
		}
	}
}

























