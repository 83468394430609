

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



///////////////////////////////
// HeaderElementSearchDrawer //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables

$searchIconScale: 0.8;


.HeaderElementSearchDrawer {

	&.blockedRoute {
		
		// Only blocked on mobile currently

		@media #{$break600} {
			display: none;
		}
	}

	.searchIconWrapper {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;

		.searchIcon {

			position: absolute;
			top: 0.5*(1 - $searchIconScale)*100%;
			left: 0.5*(1 - $searchIconScale)*100%;
			right: 0.5*(1 - $searchIconScale)*100%;
			bottom: 0.5*(1 - $searchIconScale)*100%;

			svg {

				fill: $headerColor;

				&:hover {
					fill: $headerAccentColor;
				}
			}
		}
	}

	.searchDrawer {

		overflow: hidden;

		@media #{$break600} {
			position: fixed;
			top: $headerHeightMobile;
			left: 0;
			width: 100%;
			height: 0;
			background: $headerBackground;
			transition: height $slideFastTransition;
		}

		&.open {

			&.closable {
				overflow: unset;
			}

			height: $headerHeightMobile;
			transition: height $slideTransition;
		}

		.searchWrapper {

			position: absolute;
			top: 0.5 * $headerMarginMobile;
			bottom: 0.5 * $headerMarginMobile;
			left: $headerMarginMobile;
			right: $headerMarginMobile;
			transition: left $breakTransition, right $breakTransition;

			// @media #{$break1200} {
			// 	left: $headerLogoWidth + (2 * $headerMargin);
			// 	right: $headerCartWidth + $headerIconWidth + (3 * $headerMargin);
			// }

			// @media #{$break600} {
			// 	top: 0.5 * $headerMarginMobile;
			// 	bottom: 0.5 * $headerMarginMobile;
			// 	left: $headerMarginMobile;
			// 	right: $headerMarginMobile;
			// }
		}

		.searchCloseWrapper {

			display: none;
			cursor: pointer;
			position: absolute;
			top: 0.5 * $headerMarginMobile;
			right: $headerMarginMobile;
			width: $headerIconWidthMobile;
			height: $headerIconWidthMobile;

			@media #{$break600} {
				// display: block;
			}

			.closeIcon {

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				svg {

					fill: $headerColor;

					&:hover {
						fill: $headerAccentColor;
					}
				}
			}
		}
	}
}













