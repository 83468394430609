

@use '../../../../style/import' as *;


$aueEditIncludedPermission: $TITAN_TEXT_COLOR;
$aueEditExcludedPermission: $disabledColor;

$aueButtonColor: $TITAN_PRIMARY_COLOR_TEXT;
$aueButtonBackground: $TITAN_PRIMARY_COLOR;
$aueButtonBackgroundHover: $TITAN_PRIMARY_COLOR_HOVER;

$aueEditPermissionHeight: 50px;
$aueEditPermissionMargin: 10px;

$aueEditToggleWidth: 60px;



.AdminUsersEdit, .AdminUsersAdd {

	.adminBody {

		.adminForm {

			.customerPermissionsForm {

				margin: $aueEditPermissionMargin 0 0 0;
				padding: $aueEditPermissionMargin 0 0 0;

				.editPermissionWrapper {

					position: relative;
					margin: 0 0 2*$aueEditPermissionMargin 0;

					.editToggleWrapper {

						position: absolute;
						top: 0;
						left: 0;
						width: $aueEditToggleWidth;
						height: 100%;

						.editToggleInputWrapper {

							.Toggle {
								width: $aueEditToggleWidth;
							}
						}
					}

					.editToggleLabelWraper {

						position: relative;
						height: $aueEditPermissionHeight;
						margin-left: $aueEditToggleWidth + 2*$aueEditPermissionMargin;

						.editPermissionTitle {
							position: absolute;
							top: 0;
							left: 0;
							right: $aueEditPermissionMargin;
							height: $aueEditPermissionHeight / 2;
							line-height: $aueEditPermissionHeight / 2;

							font-size: 18px;
							font-weight: 400;
						}

						.editPermissionDesc {
							position: absolute;
							left: 0;
							right: $aueEditPermissionMargin;
							bottom: 0;
							height: $aueEditPermissionHeight / 2;
							line-height: $aueEditPermissionHeight / 4;

							font-size: 13px;
							font-weight: 300;
						}
					}
				}

				.noUserSelected {

					.noUserWrapper {

						padding: $aueEditPermissionMargin 0 3*$aueEditPermissionMargin 0;
						text-align: center;
						margin: 0 auto;
						width: 90%;
						max-width: 600px;

						.noUserCopy {
							margin: 2*$aueEditPermissionMargin 0 0 0;
							font-size: 20px;
							font-weight: 300;
						}

						.noUserHeader {
							margin: 4*$aueEditPermissionMargin 0 0 0;
							font-size: 16px;
							font-weight: 700;
							text-transform: uppercase;
						}

						.noUserButton {
							display: inline-block;
							vertical-align: top;
							color: $aueButtonColor;
							background: $aueButtonBackground;
							cursor: pointer;
							border: none;
							margin: $aueEditPermissionMargin 0 0 0;
							padding: $aueEditPermissionMargin 4*$aueEditPermissionMargin;
							font-size: 14px;
							font-weight: 700;
							text-transform: uppercase;
							transition: background $hoverTransition, padding $breakTransition, width $breakTransition;

							@media #{$break1024} {
								padding: 2*$aueEditPermissionMargin/2 3*$aueEditPermissionMargin;
							}

							&:hover {
								background: $aueButtonBackgroundHover;
							}

							&:disabled {
								cursor: default;
							}
						}
					}
				}
			}
		}
	}
}