

@use '../../../../style/import' as *;

// We use admin variables in here; 
// Just keep breaking off new chunks into modules, and this will simplify
@use '../../Admin/_styles/admin' as *;



///////////////
// ViewOrder //
///////////////


// Titan colors
$viewOrderAccent: $TITAN_PRIMARY_COLOR;
$viewOrderAccentColor: $TITAN_PRIMARY_COLOR_TEXT;
$viewOrderAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$viewOrderBackground: $TITAN_BACKGROUND;
$viewOrderColor: $TITAN_TEXT_COLOR;
$viewOrderColorLight: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
$viewOrderMargin: 10px;
$viewOrderNoticeMaxWidth: 400px;
$viewOrderStatusIndicatorPadding: 5px;
$viewOrderStatusIndicatorWidth: 25px;


$viewOrderStatusColorComplete: #2DC937;
$viewOrderStatusColorNull: $viewOrderColorLight;
$viewOrderStatusColorPending: #99C140;
$viewOrderStatusColorPendingWarning: #E7B416;
$viewOrderStatusColorPendingError: #DB7B2B;
$viewOrderStatusColorError: #CC3232;


.ViewOrder {

	.viewOrderTitleWrapper {

		position: relative;

		.orderActions {

			.oaLiner {

			}
		}

		.orderStatusIndicator {

			position: absolute;
			top: 0;
			right: $adminPageHorizontalSpacing;
			bottom: 0;
			width: auto;
			background: $viewOrderBackground;

			@media #{$break768} {
				display: none;
			}

			.osiLiner {

				position: relative;
				height: 100%;
				margin: 0 $viewOrderStatusIndicatorPadding;

				.osiActions {

					display: none;

					// display: inline-block;
					vertical-align: top;
					margin: $viewOrderStatusIndicatorPadding $viewOrderStatusIndicatorPadding $viewOrderStatusIndicatorPadding 0;
				}

				.osiBody {
					position: relative;
					display: inline-block;
					user-select: none;
					cursor: default;
					vertical-align: top;
					height: calc(100% - #{2*$viewOrderStatusIndicatorPadding});
					font-size: 20px;
					font-weight: 300;
					line-height: calc(100% - #{2*$viewOrderStatusIndicatorPadding});
					padding: 0 $viewOrderStatusIndicatorPadding 0 0;
					margin: $viewOrderStatusIndicatorPadding ($viewOrderStatusIndicatorWidth + $viewOrderStatusIndicatorPadding) $viewOrderStatusIndicatorPadding 0;

					.osiBodyStatusNameWidthPlaceholder {
						visibility: hidden;
					}

					.osiBodyStatusName, .osiBodyStatusNameWidthPlaceholder {
						
						.osiStatusLabel {
							font-weight: 400;
							margin: 0 $viewOrderStatusIndicatorPadding 0 0;

							&:after {
								content: ':';
							}
						}
					}
				}

				.osiIndicator {

					position: absolute;
					top: 0;
					right: 0;
					width: $viewOrderStatusIndicatorWidth;
					bottom: 0;

					.indicatorWrapper {

						position: absolute;
						top: calc(50% - #{$viewOrderStatusIndicatorWidth/2});
						left: 0;
						width: 100%;
						height: $viewOrderStatusIndicatorWidth;

						.shipped.indicatorElement {
							.ieBall { background: $viewOrderStatusColorComplete; }
						}

						.pending.indicatorElement {
							.ieBall { background: $viewOrderStatusColorPending; }
						}

						.pendingWarning.indicatorElement {
							.ieBall { background: $viewOrderStatusColorPendingWarning; }
						}

						.pendingError.indicatorElement {
							.ieBall { background: $viewOrderStatusColorPendingError; }
						}

						.error.indicatorElement {
							.ieBall { background: $viewOrderStatusColorError; }
						}

						.null.indicatorElement {
							.ieBall { background: $viewOrderStatusColorNull; }
						}

						.indicatorElement {

							width: $viewOrderStatusIndicatorWidth;
							height: $viewOrderStatusIndicatorWidth;
							display: inline-block;

							.ieBall {

								display: inline-block;
							  width: $viewOrderStatusIndicatorWidth;
							  height: $viewOrderStatusIndicatorWidth;
							  margin: 0;
							  border-radius: 50%;
							  position: relative;
							  background: $viewOrderStatusColorNull;
							  transition: background $toggleTransition;

							  &:before {
							  	content: '';
								  position: absolute;
								  background: radial-gradient(circle at 50% 120%,  rgba($viewOrderBackground, 0.5), rgba($viewOrderBackground, 0) 70%);
								  border-radius: 50%;
								  bottom: 2.5%;
								  left: 5%;
								  opacity: 0.6;
								  height: 100%;
								  width: 90%;
								  -webkit-filter: blur(5px);
								  z-index: 2;
							  }

							  &:after {
							  	width: $viewOrderStatusIndicatorWidth;
								  height: $viewOrderStatusIndicatorWidth;
								  content: "";
								  position: absolute;
								  top: 5%;
								  right: 10%;
								  border-radius: 50%;
								  background: radial-gradient(circle at 50% 50%,  rgba($viewOrderBackground, 0.8), rgba($viewOrderBackground, 0.8) 14%, rgba($viewOrderBackground, 0) 24%);
								  transform: translateX(8*$viewOrderStatusIndicatorWidth/30) translateY(-9*$viewOrderStatusIndicatorWidth/30) skewX(20deg);
								  filter: blur($viewOrderStatusIndicatorWidth/30);
							  }
							}
						}
					}
				}
			}
		}
	}

	.adminBody {
		.adminView {
			.adminSection {
				max-width: none;
			}
			.adminForm {
				max-width: none;
			}
		}
	}

	.adminActions {
		max-width: none;
	}

	.statusNoticeWrapper {

		position: fixed;
		display: inline-block;
		user-select: none;
		width: auto;
		height: auto;
		max-width: $viewOrderNoticeMaxWidth;
		background: $viewOrderBackground;
		border: 1px solid $viewOrderColor;
		padding: $viewOrderStatusIndicatorPadding;
		transition: top $mouseFollowTransition, right $mouseFollowTransition;

		.statusNotice {

			font-size: 14px;

			.statusNoticeLabel {
				display: inline-block;
				font-weight: 700;
				width: auto;
				margin: 0 0 $viewOrderStatusIndicatorPadding 0;
				border-bottom: 2px solid $viewOrderAccent;
			}

			.statusNoticeValue {

			}
		}
	}
}


//////////////////
// OrderHistory //
//////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderHistory {

	.odvHistoryTable {

		.odvHistoryRow {

			padding: 0.5*$viewOrderMargin 0;
			font-size: 14px;

			.odvHistoryRowKey {
				display: inline-block;
				vertical-align: top;
				width: calc(40% - #{0.5*$viewOrderMargin});
				margin: 0 0 0 0;
				font-weight: 300; 
				user-select: none;
			}	

			.odvHistoryRowValue {
				display: inline-block;
				vertical-align: top;
				width: calc(60% - #{0.5*$viewOrderMargin});
				margin: 0 0 0 $viewOrderMargin;
				text-align: right;

				.odvDateWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
				}

				.odvTimeWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 0 0 0.5*$viewOrderMargin;
					font-weight: 300;
				}
			}	
		}

		.odvHistoryLinkWrapper {

			margin: 0.5*$viewOrderMargin 0;
		
			.odvHistoryLink {

				display: inline-block;
				vertical-align: top;
				cursor: pointer;
				color: $viewOrderAccent;
				font-size: 14px;
				font-weight: 700;
				transition: color $hoverTransition;

				&:hover {
					color: $viewOrderAccentHover;
				}
			}
		}
	}
}


//////////////////////
// OrderDetailsView //
//////////////////////


// Titan colors
$orderDetailsViewRowBackground: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$orderDetailsViewColumnMinHeight: 300px;
$orderDetailsViewTitleHeight: 20px;


.OrderDetailsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.odvWrapper.adminViewWrapper, .odvWrapper.odvFooter {

				position: relative;

				.v1.odvR {
					left: 33.3%;
					transition: left $breakTransition;

					@media #{$break1024} {
						left: 50%;
					}

					@media #{$break768} {
						display: none;
					}
				}

				.v2.odvR {
					right: 33.3%;
					transition: right $breakTransition;

					@media #{$break1024} {
						right: 50%;
						display: none;
					}
				}

				.odvR {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 1px;
					background: $viewOrderColorLight;
				}

				.odvColumnWrapper.historyColumn {

					@media #{$break768} {
						padding: 0;
					}
				}

				.odvColumnWrapper {

					margin: 0 0 0 0;
					padding: 0 $viewOrderMargin 0 $viewOrderMargin;
					transition: padding $breakTransition;

					@media #{$break768} {
						padding: 0 0 2*$viewOrderMargin 0;
					}

					.odvColTitle {
						margin: 0 0 $viewOrderMargin 0;
						height: $orderDetailsViewTitleHeight;

						.odvTitle {
							display: inline-block;
							vertical-align: top;
							font-size: 18px;
							font-weight: 700;
							font-family: $fontLato;
							text-transform: uppercase;
						}

						.odvTitleAddition {
							display: inline-block;
							vertical-align: top;
							margin: 1px 0 0 $viewOrderMargin;
							font-size: 14px;
							font-weight: 300;
						}
					}

					.odvColBody {
						
						margin: 0 0 0 0;
						
						.odvAddress {

							margin: 0 $viewOrderMargin;

							.odvAddressLine {
								font-size: 18px;
								font-weight: 300;
							}
						}

						.odvDetailTable {

							.odvDetailRow {

								padding: 0.5*$viewOrderMargin $viewOrderMargin;
								font-size: 16px;

								&:nth-child(odd) {
									background: $orderDetailsViewRowBackground;
								}

								.odvDetailRowKey {
									display: inline-block;
									vertical-align: top;
									width: calc(40% - #{0.5*$viewOrderMargin});
									margin: 0 0 0 0;
									font-weight: 700; 
									user-select: none;
								}	

								.odvDetailRowValue {
									display: inline-block;
									vertical-align: top;
									width: calc(60% - #{0.5*$viewOrderMargin});
									margin: 0 0 0 $viewOrderMargin;
									text-align: right;

									.odvDateWrapper {

									}

									.odvTimeWrapper {
										font-size: 14px;
										font-weight: 300;
									}
								}	
							}
						}

						.odvCartWrapper {
							overflow-y: auto;
							padding: 0 $viewOrderMargin 0 0;
						}

						.odvCartSummary {

							.cartSummaryLiner {

								.cartSummaryLine {

									margin-bottom: 0.5*$viewOrderMargin;

									.lineLabel {
										font-size: 16px;
									}
									
									.lineValue {
										font-size: 16px;
									}
								}
							}
						}
					}
				}

				.odvColumn.cartColumn {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;

					@media #{$break1024} {
						display: none;
					}

					@media #{$break768} {
						display: block;
						position: relative;
						height: auto;
						right: unset;
					}

					.odvColumnWrapper {

						@media #{$break768} {
							padding: 0 0 $viewOrderMargin 0;
						}

						.odvColBody {

							.odvCartWrapper {
								position: absolute;
								top: 3*$viewOrderMargin;
								left: $viewOrderMargin;
								right: 0;
								bottom: 0;

								@media #{$break768} {
									position: relative;
									left: unset;
									top: unset;
									right: unset;
									bottom: unset;
									max-height: 2*$orderDetailsViewColumnMinHeight;
								}
							}
						}
					}
				}

				.odvColumn {
					display: inline-block;
					vertical-align: top;
					width: 33.3%;
					min-height: $orderDetailsViewColumnMinHeight;
					transition: width $breakTransition, min-height $breakTransition, padding $breakTransition;

					@media #{$break1024} {
						width: 50.0%;
						min-height: 0;
					}

					@media #{$break768} {
						width: 100.0%;
					}

					&:first-child {
						.odvColumnWrapper {
							padding-left: 0;
						}
					}

					&:nth-child(2) {
						.odvColumnWrapper {
							padding-right: 0;
						}
					}

					&:nth-child(3) {
						.odvColumnWrapper {

							@media #{$break1024} {
								padding-right: 0;
							}
						}
					}

					.equal.odvColumnVr {
						margin: 2*$viewOrderMargin auto;
					}

					.odvColumnVr {
						margin: $viewOrderMargin auto 2*$viewOrderMargin auto;
						width: 70%;
						height: 1px;
						background: $viewOrderColorLight;

						@media #{$break768} {
							display: none;
						}
					}
				}
			}

			.odvFooter.odvWrapper {

				margin: 2*$viewOrderMargin 0 0 0;
				max-height: 0;
				transition: max-height $slideTransition;

				@media #{$break1024} {
					max-height: 2*$orderDetailsViewTitleHeight + $orderDetailsViewColumnMinHeight;
				}

				@media #{$break768} {
					display: none;
				}

				.odvColumnWrapper {
					margin: 0 0 0 0;
					padding: 0;

					.odvColBody {

						.odvCartWrapper {
							max-height: $orderDetailsViewColumnMinHeight;
						}
					}
				}
			}
		}
	}
}


//////////////////////////
// OrderShipmentsCreate //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderShipmentsCreate {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.shipmentCreateForm.adminForm {
				margin: 2*$viewOrderMargin 0 0 0;
			}
		}
	}
}


////////////////////////
// OrderShipmentsView //
////////////////////////


// Titan colors
$orderShipmentsListRowSelectedBackground: $TITAN_PRIMARY_ULTRALIGHT;
$orderShipmentsListRowBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$orderShipmentsInfoWidth: 33.3%;
$orderShipmentsListMaxWidth: 600px;
$orderShipmentsViewIconWidth: 80px;
$orderShipmentsListRowHeight: 50px;
$orderShipmentsListRowIconWidth: 14px;
$orderShipmentsViewItemsMaxHeight: 300px;

$orderShipmentsViewTableDateWidth: 140px;
$orderShipmentsViewTableDateWidthMobile: 100px;
$orderShipmentsViewTableCarrierWidth: 160px;
$orderShipmentsViewTableTrackingNumberWidth: 260px;


.OrderShipmentsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.osvLiner {
					text-align: center;

					.noShipmentsWrapper {
						padding: $orderShipmentsViewIconWidth/4 0 ($orderShipmentsViewIconWidth/4 + $viewOrderMargin) 0;

						.shippingIconWrapper {
							width: $orderShipmentsViewIconWidth;
							height: $orderShipmentsViewIconWidth;
							margin: 0 auto;
						}

						.noShipmentsCopy {
							margin: 0;
							font-size: 20px;
							font-weight: 300;
						}

						.noShipmentsAction {
							margin: 2*$viewOrderMargin 0 0 0;
							padding-left: 3*$viewOrderMargin;
							padding-right: 3*$viewOrderMargin;
						}
					}

					.shipmentListWrapper {

						text-align: left;

						.shipmentList {
							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$orderShipmentsInfoWidth} - #{1.5*$viewOrderMargin});

							@media #{$break1024} {
								width: 100%;
							}

							.slHeader {

								margin: 0 0 $viewOrderMargin 0;
								
								.slHeaderCell {
									display: inline-block;
									vertical-align: top;
									font-size: 18px !important;
									font-weight: 700;
									transition: font-size $breakTransition;

									@media #{$break1024} {
										font-size: 16px !important;
									}

									.slHeaderValue {

									}
								}
							}

							.slSelected.slItem {
								cursor: default;
								background: $orderShipmentsListRowSelectedBackground;

								@media #{$break1024} {
									background: none;
								}

								&:hover {
									background: $orderShipmentsListRowSelectedBackground;
								}

								&:after {
									content: '';
									position: absolute;
									top: 0;
									left: 100%;
									width: 0;
									height: 0;
									transition: background $hoverTransition;

									border-left: 2*$viewOrderMargin solid $orderShipmentsListRowSelectedBackground;
									border-top: $orderShipmentsListRowHeight/2 solid transparent;
									border-bottom: $orderShipmentsListRowHeight/2 solid transparent;

									@media #{$break1024} {
										display: none;
									}
								}
							}

							.slItem {

								cursor: pointer;
								height: $orderShipmentsListRowHeight;
								transition: background $hoverTransition;

								@media #{$break1024} {
									cursor: default;
								}

								&:hover {
									background: $orderShipmentsListRowBackgroundHover;

									@media #{$break1024} {
										background: none;
									}
								}

								&:nth-child(even) {

									@media #{$break1024} {
										background: $orderShipmentsListRowSelectedBackground;
									}
								}

								.slItemCell {
									position: relative;
									display: inline-block;
									vertical-align: top;
									height: $orderShipmentsListRowHeight;

									.slItemValue {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;

										display: flex;
										align-items: center;
									}
								}
							}

							.slItem, .slHeader {

								position: relative;

								.dateCell {
									width: $orderShipmentsViewTableDateWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: $orderShipmentsViewTableDateWidth;
									}

									@media #{$break600} {
										width: $orderShipmentsViewTableDateWidthMobile;
										font-size: 13px;
									}
								}

								.carrierCell {
									overflow: hidden;
									width: $orderShipmentsViewTableCarrierWidth;
									margin-left: $viewOrderMargin;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										width: calc(60% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: calc(100% - #{$orderShipmentsViewTableDateWidth} - #{2*$viewOrderMargin});
									}

									@media #{$break600} {
										width: calc(100% - #{$orderShipmentsViewTableDateWidthMobile} - #{2*$viewOrderMargin});
										font-size: 13px;
									}

									.carrierNameOnly {
										display: block;

										@media #{$break1320} {
											display: none;
										}
									}

									.carrierCompoundName {
										display: none;

										@media #{$break1320} {
											display: block;
										}

										.ccnName {

										}

										.ccnTracking {

											.ccnTrackingLink {

												&:hover {
													.ccnTrackingNumber {
														text-decoration: underline;
													}
												}

												.ccnTrackingNumber {
													display: inline-block;
													vertical-align: top;
													font-size: 12px;
													transition: font-size $breakTransition;

													@media #{$break600} {
														font-size: 10px;
													}
												}

												.ccnTrackingIcon {
													display: inline-block;
													vertical-align: top;
													margin: 2px 0 0 $viewOrderMargin;
													width: 0.7*$orderShipmentsListRowIconWidth;
													height: 0.7*$orderShipmentsListRowIconWidth;
													transition: width $breakTransition, height $breakTransition, margin $breakTransition;

													@media #{$break600} {
														margin: 1px 0 0 0.5*$viewOrderMargin;
														width: 0.6*$orderShipmentsListRowIconWidth;
														height: 0.6*$orderShipmentsListRowIconWidth;
													}

													.trackingIcon svg {

													}
												}
											}
										}
									}
								}

								.trackingCell {
									width: $orderShipmentsViewTableTrackingNumberWidth;
									margin-left: $viewOrderMargin;

									@media #{$break1320} {
										display: none;
									}

									.slItemValue.noTrackng {
										font-size: 14px;
										font-weight: 300;
									}

									.slItemValue.hasTrackng {

										font-size: 14px;

										&:hover {
											.slTrackingNumber {
												text-decoration: underline;
											}
										}
										
										.slTrackingNumber {
											display: inline-block;
											vertical-align: top;
										}

										.slTrackingIconWrapper {
											display: inline-block;
											vertical-align: top;
											margin: 2px 0 0 $viewOrderMargin;
											width: $orderShipmentsListRowIconWidth;
											height: $orderShipmentsListRowIconWidth;

											.trackingIcon svg {
												fill: $viewOrderColor;
											}
										}
									}
								}

								.menuCell {
									position: absolute;
									top: calc(50% - #{$orderShipmentsListRowIconWidth});
									right: 0.5*$viewOrderMargin;
									width: 2*$orderShipmentsListRowIconWidth;
									height: 2*$orderShipmentsListRowIconWidth;
									cursor: pointer;

									.menuIconWrapper {
										position: absolute;
										top: 0;
										left: 0;
										width: 2*$orderShipmentsListRowIconWidth;
										height: 2*$orderShipmentsListRowIconWidth;

										.iconSVGWrapper {
											width: 2*$orderShipmentsListRowIconWidth;
											height: 2*$orderShipmentsListRowIconWidth;
										}
									}

									.menuSelect {
										position: absolute;
								    top: 0;
								    left: 0;
								    width: 100%;
								    height: 100%;
								    opacity: 0;
								    cursor: pointer;

								    .hiddenOption {
								    	display: none;
								    }
									}
								}
							}
						}

						.shipmentInfo {
							display: inline-block;
							vertical-align: top;
							width: calc(#{$orderShipmentsInfoWidth} - #{1.5*$viewOrderMargin});
							margin: 0 0 0 3*$viewOrderMargin;

							@media #{$break1024} {
								display: none;
							}

							.infoSectionTitle {

								margin: 0 0 $viewOrderMargin 0;

								.infoSectionTitleValue {
									display: inline-block;
									vertical-align: top;
									font-size: 18px;
									font-weight: 700;
								}

								.infoSectionTitleItems {
									display: inline-block;
									vertical-align: top;
									margin: 1px 0 0 $viewOrderMargin;
									font-size: 14px;
									font-weight: 300;
								}
							}

							.shipmentItemsWrapper {
								max-height: $orderShipmentsViewItemsMaxHeight;
								overflow-y: scroll;
								padding: 0 $viewOrderMargin 0 0;
							}
						}
					}
				}	
			}
		}
	}
}


///////////////////////
// OrderPaymentsView //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$orderPaymentsViewTableActionWidth: 110px;
$orderPaymentsViewTableActionWidthMobile: 100px;
$orderPaymentsViewTableAmountWidth: 120px;
$orderPaymentsViewTableAmountWidthMobile: 100px;


.OrderPaymentsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.opvLiner {
					text-align: center;

					.noPaymentsWrapper {
						padding: $orderShipmentsViewIconWidth/4 0 ($orderShipmentsViewIconWidth/4 + $viewOrderMargin) 0;

						.paymentIconWrapper {
							width: $orderShipmentsViewIconWidth;
							height: $orderShipmentsViewIconWidth;
							margin: 0 auto;

							.paymentIcon {
								width: $orderShipmentsViewIconWidth;
								height: $orderShipmentsViewIconWidth;
							}
						}

						.noPaymentsCopy {
							margin: 0;
							font-size: 20px;
							font-weight: 300;
						}

						.noPaymentsAction {
							margin: 2*$viewOrderMargin 0 0 0;
							padding-left: 3*$viewOrderMargin;
							padding-right: 3*$viewOrderMargin;
						}
					}

					.pmListWrapper {

						text-align: left;

						.pmList {
							
							margin: 0 0 $viewOrderMargin 0;

							.slHeader {

								margin: 0 0 $viewOrderMargin 0;
								
								.slHeaderCell {
									display: inline-block;
									vertical-align: top;
									font-size: 18px !important;
									font-weight: 700 !important;
									transition: font-size $breakTransition;

									@media #{$break1024} {
										font-size: 16px !important;
									}

									.slHeaderValue {

									}
								}
							}

							.slSelected.slItem {
								cursor: default;
								background: $orderShipmentsListRowSelectedBackground;

								@media #{$break1024} {
									background: none;
								}

								&:hover {
									background: $orderShipmentsListRowSelectedBackground;

									@media #{$break1024} {
										background: none;
									}
								}

								&:after {
									content: '';
									position: absolute;
									top: calc(50% - #{$orderShipmentsListRowHeight/2});
									left: 100%;
									width: 0;
									height: 0;
									transition: background $hoverTransition;

									border-left: 2*$viewOrderMargin solid $orderShipmentsListRowSelectedBackground;
									border-top: $orderShipmentsListRowHeight/2 solid transparent;
									border-bottom: $orderShipmentsListRowHeight/2 solid transparent;

									@media #{$break1024} {
										display: none;
									}
								}
							}

							.complete.slItem {

								.pmCell {
									width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth});

									@media #{$break600} {
										width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile});
									}
								}

								.actionCell {
									display: none;
								}
							}

							.slItem {

								min-height: $orderShipmentsListRowHeight;
								transition: background $hoverTransition;

								@media #{$break1024} {
									cursor: default;
								}

								&:nth-child(even) {

									@media #{$break1024} {
										background: $orderShipmentsListRowSelectedBackground;
									}
								}

								.slItemCell {
									position: relative;
									display: inline-block;
									vertical-align: top;
									height: auto;
									min-height: $orderShipmentsListRowHeight;

									.slItemValue {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										height: $orderShipmentsListRowHeight;

										display: flex;
										align-items: center;
									}

									.slNonAbsValue {
										margin: 0.5*$viewOrderMargin 0;
									}
								}
							}

							.slItem, .slHeader {

								position: relative;

								.dateCell {
									width: $orderShipmentsViewTableDateWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break600} {
										width: $orderShipmentsViewTableDateWidthMobile;
										font-size: 14px;
									}

									.dateDate {
										font-weight: 700;
										font-size: 14px;
									}

									.dateTime {
										font-weight: 300;
										font-size: 15px;
									}
								}

								.pmCell {
									width: calc(100% - #{4*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth} - #{$orderPaymentsViewTableAmountWidth} - #{$orderPaymentsViewTableActionWidth});
									margin-left: $viewOrderMargin;
									font-weight: 300;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break600} {
										width: calc(100% - #{4*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile} - #{$orderPaymentsViewTableAmountWidthMobile} - #{$orderPaymentsViewTableActionWidthMobile});
										font-size: 13px;
									}

									.slNonAbsValue {

										.pmBodyWrapper {

											.pmName {
												font-size: 15px;
												font-weight: 400;
											}

											.pmBody {
												font-size: 14px;
												font-weight: 300;

												.prDisplayBrand {
													margin: 0 $viewOrderMargin 0 0;
												}

												.prDisplayNumber {
													margin: 0 $viewOrderMargin 0 0;
												}

												.prDisplayExp {

												}

												.pmBodyLiner {

													.creditLabel {
														display: inline;

														&:after {
															content: ': ';
														}
													}

													.creditValue {
														display: inline;
													}
												}
											}
										}
									}
								}

								.amountCell {
									width: $orderPaymentsViewTableAmountWidth;
									margin-left: $viewOrderMargin;
									font-weight: 300;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break600} {
										width: $orderPaymentsViewTableAmountWidthMobile;
										font-size: 13px;
									}

									.slNonAbsValue {

										.creditWrapper {

											font-size: 12px;
											margin: 0.5*$viewOrderMargin 0 0 0;

											.creditLabel {
												display: inline;

												&:after {
													content: ': ';
												}
											}

											.creditValue {
												display: inline;
											}
										}
									}
								}

								.actionCell {
									width: $orderPaymentsViewTableActionWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									text-align: right;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1024} {
										width: $orderPaymentsViewTableActionWidthMobile;
									}

									@media #{$break600} {
										width: $orderPaymentsViewTableActionWidthMobile;
										font-size: 13px;
									}

									.slNonAbsValue {

										.rowButton {

											cursor: pointer;
											color: $viewOrderAccentColor;
											background: $viewOrderAccent;
											border: none;
											outline: none;
											margin: 0 0.5*$viewOrderMargin 0 0;
											padding: 0.5*$viewOrderMargin 0.5*$viewOrderMargin;
											font-size: 13px;
											font-weight: 700;
											text-transform: uppercase;
											transition: background $hoverTransition;

											&:hover {
												background: $viewOrderAccentHover;
											}
										}
									}
								}
							}
						}
					}
				}	
			}
		}
	}
}


//////////////////////
// OrderPickupsView //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$orderShipmentsViewTableActionWidth: 110px;
$orderShipmentsViewTableActionWidthMobile: 100px;


.OrderPickupsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.osvLiner {
					text-align: center;

					.noShipmentsWrapper {
						padding: $orderShipmentsViewIconWidth/4 0 ($orderShipmentsViewIconWidth/4 + $viewOrderMargin) 0;

						.shippingIconWrapper {
							width: $orderShipmentsViewIconWidth;
							height: $orderShipmentsViewIconWidth;
							margin: 0 auto;
						}

						.noShipmentsCopy {
							margin: 0;
							font-size: 20px;
							font-weight: 300;
						}

						.noShipmentsAction {
							margin: 2*$viewOrderMargin 0 0 0;
							padding-left: 3*$viewOrderMargin;
							padding-right: 3*$viewOrderMargin;
						}
					}

					.shipmentListWrapper {

						text-align: left;

						.shipmentList {
							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$orderShipmentsInfoWidth} - #{1.5*$viewOrderMargin});

							@media #{$break1024} {
								width: 100%;
							}

							.slHeader {

								margin: 0 0 $viewOrderMargin 0;
								
								.slHeaderCell {
									display: inline-block;
									vertical-align: top;
									font-size: 18px !important;
									font-weight: 700 !important;
									transition: font-size $breakTransition;

									@media #{$break1024} {
										font-size: 16px !important;
									}

									.slHeaderValue {

									}
								}
							}

							.slSelected.slItem {
								cursor: default;
								background: $orderShipmentsListRowSelectedBackground;

								@media #{$break1024} {
									background: none;
								}

								&:hover {
									background: $orderShipmentsListRowSelectedBackground;

									@media #{$break1024} {
										background: none;
									}
								}

								&:after {
									content: '';
									position: absolute;
									top: calc(50% - #{$orderShipmentsListRowHeight/2});
									left: 100%;
									width: 0;
									height: 0;
									transition: background $hoverTransition;

									border-left: 2*$viewOrderMargin solid $orderShipmentsListRowSelectedBackground;
									border-top: $orderShipmentsListRowHeight/2 solid transparent;
									border-bottom: $orderShipmentsListRowHeight/2 solid transparent;

									@media #{$break1024} {
										display: none;
									}
								}
							}

							.complete.slItem {

								.noteCell {
									width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth});

									@media #{$break600} {
										width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile});
									}
								}

								.actionCell {
									display: none;
								}
							}

							.slItem {

								cursor: pointer;
								min-height: $orderShipmentsListRowHeight;
								transition: background $hoverTransition;

								@media #{$break1024} {
									cursor: default;
								}

								&:hover {
									background: $orderShipmentsListRowBackgroundHover;

									@media #{$break1024} {
										background: none;
									}
								}

								&:nth-child(even) {

									@media #{$break1024} {
										background: $orderShipmentsListRowSelectedBackground;
									}
								}

								.slItemCell {
									position: relative;
									display: inline-block;
									vertical-align: top;
									height: auto;
									min-height: $orderShipmentsListRowHeight;

									.slItemValue {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										height: $orderShipmentsListRowHeight;

										display: flex;
										align-items: center;
									}

									.slNonAbsValue {
										margin: 0.5*$viewOrderMargin 0;
									}
								}
							}

							.slItem, .slHeader {

								position: relative;

								.dateCell {
									width: $orderShipmentsViewTableDateWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: $orderShipmentsViewTableDateWidth;
									}

									@media #{$break600} {
										width: $orderShipmentsViewTableDateWidthMobile;
										font-size: 13px;
									}

									.dateDate {
										font-weight: 700;
										font-size: 14px;
									}

									.dateTime {
										font-weight: 300;
										font-size: 14px;
									}
								}

								.noteCell {
									width: calc(100% - #{3*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth} - #{$orderShipmentsViewTableActionWidth});
									margin-left: $viewOrderMargin;
									font-weight: 300;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										// width: $orderShipmentsViewTableNoteWidth;
									}

									@media #{$break600} {
										width: calc(100% - #{3*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile} - #{$orderShipmentsViewTableActionWidthMobile});
										font-size: 13px;
									}

									.slNonAbsValue {

										overflow: auto;

										.noteBlock {

											font-size: 14px;
											margin: 0 0 0.5*$viewOrderMargin 0;

											.noteLabel {
												display: inline;
												font-weight: 400;

												&:after {
													content: ': ';
												}
											}

											.noteValue {
												display: inline;
												font-weight: 300;
											}
										}
									}
								}

								.actionCell {
									width: $orderShipmentsViewTableActionWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									text-align: right;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: $orderShipmentsViewTableActionWidth;
									}

									@media #{$break600} {
										width: $orderShipmentsViewTableActionWidthMobile;
										font-size: 13px;
									}

									.slNonAbsValue {

										.rowButton {

											cursor: pointer;
											color: $viewOrderAccentColor;
											background: $viewOrderAccent;
											border: none;
											outline: none;
											margin: 0 0.5*$viewOrderMargin 0 0;
											padding: 0.5*$viewOrderMargin 0.5*$viewOrderMargin;
											font-size: 13px;
											font-weight: 700;
											text-transform: uppercase;
											transition: background $hoverTransition;

											&:hover {
												background: $viewOrderAccentHover;
											}
										}
									}
								}
							}
						}

						.shipmentInfo {
							display: inline-block;
							vertical-align: top;
							width: calc(#{$orderShipmentsInfoWidth} - #{1.5*$viewOrderMargin});
							margin: 0 0 0 3*$viewOrderMargin;

							@media #{$break1024} {
								display: none;
							}

							.infoSectionTitle {

								margin: 0 0 $viewOrderMargin 0;

								.infoSectionTitleValue {
									display: inline-block;
									vertical-align: top;
									font-size: 18px;
									font-weight: 700;
								}

								.infoSectionTitleItems {
									display: inline-block;
									vertical-align: top;
									margin: 1px 0 0 $viewOrderMargin;
									font-size: 14px;
									font-weight: 300;
								}
							}

							.shipmentItemsWrapper {
								max-height: $orderShipmentsViewItemsMaxHeight;
								overflow-y: scroll;
								padding: 0 $viewOrderMargin 0 0;
							}
						}
					}
				}	
			}
		}
	}
}


///////////////////////
// OrderMessagesView //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderMessagesView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.omvLiner {
					text-align: center;

					.noMessagesWrapper {
						padding: $orderShipmentsViewIconWidth/4 0 ($orderShipmentsViewIconWidth/4 + $viewOrderMargin) 0;

						.messageIconWrapper {
							width: $orderShipmentsViewIconWidth;
							height: $orderShipmentsViewIconWidth;
							margin: 0 auto;
						}

						.noMessagesCopy {
							margin: 0;
							font-size: 20px;
							font-weight: 300;
						}

						.noMessagesAction {
							margin: 2*$viewOrderMargin 0 0 0;
							padding-left: 3*$viewOrderMargin;
							padding-right: 3*$viewOrderMargin;
						}
					}

					.messageListWrapper {

						margin: 0 0 $viewOrderMargin 0;
						text-align: left;

						.messageList {
							display: block;
							vertical-align: top;
							// width: calc(100% - #{1.5*$viewOrderMargin});

							@media #{$break1024} {
								width: 100%;
							}

							.slHeader {

								margin: 0 0 $viewOrderMargin 0;
								
								.slHeaderCell {
									display: inline-block;
									vertical-align: top;
									font-size: 18px !important;
									font-weight: 700 !important;
									transition: font-size $breakTransition;

									@media #{$break1024} {
										font-size: 16px !important;
									}

									.slHeaderValue {

									}
								}
							}

							.slSelected.slItem {
								cursor: default;
								background: $orderShipmentsListRowSelectedBackground;

								@media #{$break1024} {
									background: none;
								}

								&:hover {
									background: $orderShipmentsListRowSelectedBackground;

									@media #{$break1024} {
										background: none;
									}
								}

								&:after {
									content: '';
									position: absolute;
									top: calc(50% - #{$orderShipmentsListRowHeight/2});
									left: 100%;
									width: 0;
									height: 0;
									transition: background $hoverTransition;

									border-left: 2*$viewOrderMargin solid $orderShipmentsListRowSelectedBackground;
									border-top: $orderShipmentsListRowHeight/2 solid transparent;
									border-bottom: $orderShipmentsListRowHeight/2 solid transparent;

									@media #{$break1024} {
										display: none;
									}
								}
							}

							.complete.slItem {

								.noteCell {
									width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth});

									@media #{$break600} {
										width: calc(100% - #{2*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile});
									}
								}

								.actionCell {
									display: none;
								}
							}

							.slItem {

								min-height: $orderShipmentsListRowHeight;
								transition: background $hoverTransition;

								@media #{$break1024} {
									cursor: default;
								}

								&:nth-child(even) {
									background: $orderShipmentsListRowSelectedBackground;
								}

								.slItemCell {
									position: relative;
									display: inline-block;
									vertical-align: top;
									height: auto;
									min-height: $orderShipmentsListRowHeight;

									.slItemValue {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										height: $orderShipmentsListRowHeight;

										display: flex;
										align-items: center;
									}

									.slNonAbsValue {
										margin: 0.5*$viewOrderMargin 0;
									}
								}
							}

							.slItem, .slHeader {

								position: relative;

								.dateCell {
									width: $orderShipmentsViewTableDateWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: $orderShipmentsViewTableDateWidth;
									}

									@media #{$break600} {
										width: $orderShipmentsViewTableDateWidthMobile;
										font-size: 13px;
									}

									.dateDate {
										font-weight: 700;
										font-size: 14px;
									}

									.dateTime {
										font-weight: 300;
										font-size: 14px;
									}
								}

								.noteCell {
									width: calc(100% - #{3*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidth});
									margin-left: $viewOrderMargin;
									font-weight: 300;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										// width: $orderShipmentsViewTableNoteWidth;
									}

									@media #{$break600} {
										width: calc(100% - #{3*$viewOrderMargin} - #{$orderShipmentsViewTableDateWidthMobile});
										font-size: 13px;
									}

									.slNonAbsValue {

										overflow: auto;

										.noteBlock {

											font-size: 14px;
											margin: 0 0 0.5*$viewOrderMargin 0;

											.noteLabel {
												display: inline;
												font-weight: 400;

												&:after {
													content: ': ';
												}
											}

											.noteValue {
												display: inline;
												font-weight: 300;
											}
										}
									}
								}

								.actionCell {
									width: $orderShipmentsViewTableActionWidth;
									margin-left: $viewOrderMargin;
									font-weight: 700;
									text-align: right;
									transition: width $breakTransition, font-size $breakTransition;

									@media #{$break1320} {
										// width: calc(40% - #{$viewOrderMargin});
									}

									@media #{$break1024} {
										width: $orderShipmentsViewTableActionWidth;
									}

									@media #{$break600} {
										width: $orderShipmentsViewTableActionWidthMobile;
										font-size: 13px;
									}

									.slNonAbsValue {

										.rowButton {

											cursor: pointer;
											color: $viewOrderAccentColor;
											background: $viewOrderAccent;
											border: none;
											outline: none;
											margin: 0 0.5*$viewOrderMargin 0 0;
											padding: 0.5*$viewOrderMargin 0.5*$viewOrderMargin;
											font-size: 13px;
											font-weight: 700;
											text-transform: uppercase;
											transition: background $hoverTransition;

											&:hover {
												background: $viewOrderAccentHover;
											}
										}
									}
								}
							}
						}
					}
				}	
			}
		}
	}
}



////////////////////
// OrderModalView //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$orderModalFormBorderColor: $TITAN_TEXT_COLOR_LIGHT;
$orderModalFormCheckboxWidth: 18px;


.OrderModalView {

	.omvWrapper {

		.orderModalCopy.centerCopy {
			margin: $viewOrderMargin 0 0 0;
			text-align: center;
		}

		.orderModalCopy {
			margin: 1.5*$viewOrderMargin 0 0 0;
			font-size: 16px;
			font-weight: 300;

			.copyAction {
				display: inline-block;
				vertical-align: top;
				width: auto;
				font-size: 15px;
				font-weight: 700;
				color: $viewOrderAccent;
				cursor: pointer;
				transition: color $hoverTransition;

				&:hover {
					color: $viewOrderAccentHover;
				}
			}
		}

		.orderModalToggleLine {

			font-size: 16px;
			font-weight: 400;
			text-align: center;
			margin: 2*$viewOrderMargin 0;

			.omToggleInputWrapper {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $orderModalFormCheckboxWidth;
				height: $orderModalFormCheckboxWidth;
			}

			.omToggleCopy {
				display: inline-block;
				vertical-align: top;
				width: auto;
				margin: 0 0 0 $viewOrderMargin;
				user-select: none;
				max-width: calc(100% - #{$orderModalFormCheckboxWidth} - #{$viewOrderMargin});
			}
		}

		.orHr {

			margin: $viewOrderMargin 0 0 0;
			text-align: center;

			.orHrLiner {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: auto;
				text-align: center;
				padding: 0 4*$viewOrderMargin;

				.orHrValue {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: auto;
					font-size: 18px;
					font-style: italic;
					background: $viewOrderBackground;
					padding: 0 $viewOrderMargin;
				}

				.orHrBackground {
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					height: 1px;
					background: $viewOrderColor;
				}
			}
		}

		.orderModalForm {

			margin: $viewOrderMargin 0;

			.present.omFormError {
				height: 3.5*$viewOrderMargin;
				text-align: center;
			}

			.omFormError {
				color: $errorRed;
				font-weight: 700;
				font-size: 16px;
				height: 0;
				text-align: left;
				overflow: hidden;
				transition: height $toggleTransition;
			}

			.orderModalCopy {
				margin: 0 0 $viewOrderMargin 0;
				font-size: 18px;
				text-align: center;
			}

			.omFieldWrapper.hidden {
				opacity: 0.0;

				@media #{$break768} {
					display: none !important;
				}
			}

			.omFieldWrapper.halfWidth {
				display: inline-block;
				vertical-align: top;
				width: 50%;

				@media #{$break768} {
					display: block;
					width: 100%;
				}
			}

			.omFieldWrapper {

				margin: 0 auto $viewOrderMargin auto;

				.omFieldLabel.omRequired {

					&:after {
						content: '*';
						color: $viewOrderAccent;
						font-size: 16px;
					}
				}

				.omFieldLabel.omOptional {
					/* Only needed when aligned next to required field */
					&:after {
						content: '*';
						color: $viewOrderAccent;
						font-size: 16px;
						opacity: 0;
					}
				}

				.omFieldLabel {
					color: $viewOrderColor;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 0.5*$viewOrderMargin 0;
					user-select: none;
				}

				.omBalanceWrapper {
					margin: 0.5*$viewOrderMargin 0 $viewOrderMargin 0;

					.omBalanceCopy {
						font-size: 14px;

						.balanceEm {
							font-weight: 700;
						}
					}
				}

				.omInputWrapper {

					position: relative;

					.omDropdownWrapper {

						border: 1px solid $orderModalFormBorderColor;

						.omDropdownSelect {
							margin: $viewOrderMargin;
							border-bottom: none;
						}
					}

					input.currency {
						width: calc(100% - #{5*$viewOrderMargin} - 2px);
						padding: $viewOrderMargin 2*$viewOrderMargin $viewOrderMargin 3*$viewOrderMargin;
						border: 1px solid $orderModalFormBorderColor;
					}

					input {
						width: calc(100% - #{4*$viewOrderMargin} - 2px);
						padding: $viewOrderMargin 2*$viewOrderMargin;
						border: 1px solid $orderModalFormBorderColor;
					}

					textarea.tallText {
						height: 10*$viewOrderMargin;
					}

					textarea {
						width: calc(100% - #{4*$viewOrderMargin} - 2px);
						padding: $viewOrderMargin 2*$viewOrderMargin;
						border: 1px solid $orderModalFormBorderColor;
						border-radius: 0;
						outline: none;
					}

					.currencyOverlay {
						position: absolute;
						top: 0;
						left: 0;
						width: 3*$viewOrderMargin;
						height: 100%;
						font-size: 18px;
						font-weight: 300;
					}
				}
			}
		}
	}
}


////////////////////////////
// OrderModalPickupCreate //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderModalPickupCreate {

	.omvWrapper {

	}
}


/////////////////////////////
// OrderModalPaymentCreate //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderModalPaymentCreate {

	.omvWrapper {

		.paymentCreateForm {

			.paymentHeadlineWrapper, .paymentSubheadlineWrapper {

				font-size: 24px;
				margin: $viewOrderMargin 0 2*$viewOrderMargin 0;
				text-align: center;

				.headlineLabel {
					display: inline-block;
					vertical-align: top;
					font-weight: 400;
					margin: 0 $viewOrderMargin 0 0;

					&:after {
						content: ': ';
					}
				}

				.headlineValue {
					display: inline-block;
					vertical-align: top;
					font-weight: 700;
				}
			}

			.paymentSubheadlineWrapper {
				font-size: 18px;
				margin: -1*$viewOrderMargin 0 2*$viewOrderMargin 0;
			}
		}
	}
}


////////////////////////////////////
// OrderModalBuylistPaymentCreate //
////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderModalBuylistPaymentCreate {

	.omvWrapper {

		.paymentCreateForm {

			.paymentHeadlineWrapper, .paymentSubheadlineWrapper {

				font-size: 24px;
				margin: $viewOrderMargin 0 2*$viewOrderMargin 0;
				text-align: center;

				.headlineLabel {
					display: inline-block;
					vertical-align: top;
					font-weight: 400;
					margin: 0 $viewOrderMargin 0 0;

					&:after {
						content: ': ';
					}
				}

				.headlineValue {
					display: inline-block;
					vertical-align: top;
					font-weight: 700;
				}
			}

			.paymentSubheadlineWrapper.hidden {
				opacity: 0.0;

				@media #{$break768} {
					display: none !important;
				}
			}

			.paymentSubheadlineWrapper {
				font-size: 18px;
				margin: -1*$viewOrderMargin 0 2*$viewOrderMargin 0;
			}
		}
	}
}


////////////////////////////
// OrderModalRefundCreate //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$omrCheckboxWidth: 20px;
$omrReturnCheckboxWidth: 15px;


.OrderModalRefundCreate {

	.omvWrapper {

		.refundCreateForm {

			.omFormError {
				text-align: center;
			}

			.refundTotalWrapper {

				margin: 0 0 $viewOrderMargin 0;
				text-align: center;

				.orderTotalWrapper {
					text-align: center;
				}
				
				.alreadyRefundedWrapper {
					text-align: center;
				}

				.totalWrapper {

					display: inline-block;
					vertical-align: top;
					width: 50%;
					font-size: 20px;

					.totalLabel {
						display: inline-block;
						vertical-align: middle;
						font-weight: 400;

						&:after {
							content: ':';
						}
					}

					.totalValue {
						display: inline-block;
						vertical-align: middle;
						font-weight: 300;
						margin: 0 0 0 $viewOrderMargin;
					}
				}
			}
		}
	}
}


///////////////////////////////////
// OrderModalRefundStepInventory //
///////////////////////////////////


// Titan colors
$omrCheckboxBorder: $TITAN_RULE_COLOR;
$omrRowAlternateBackground: $TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE;
$omrRowSelectedBackground: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$omrHeaderHeight: 26px;
$omrScrollableHeight: 260px;
$omrHeaderCheckboxWidth: 30px;  // These widths also exist in _orderlineitem.scss
$omrHeaderQuantityWidth: 80px;
$omrHeaderPriceWidth: 80px;
$omrHeaderReturnWidth: 90px;
$omrTotalValueWidth: 80px;


.OrderModalRefundStepInventory {

	.omsLiner {

		.refundHeaderWrapper {

			.refundHeader {

				height: $omrHeaderHeight;
				margin: 0 0 0.5*$viewOrderMargin 0;

				.headerCheckbox {

					position: relative;
					display: inline-block;
					vertical-align: top;
					height: $omrHeaderHeight;
					width: $omrHeaderCheckboxWidth;

					.headerCheckboxInputWrapper {
						position: absolute;
						top: calc(50% - #{$omrCheckboxWidth/2});
						left: calc(50% - #{$omrCheckboxWidth/2});
						width: $omrCheckboxWidth;
						height: $omrCheckboxWidth;
						line-height: $omrHeaderHeight/2;
					}
				}

				.headerLabel {
					position: relative;
					display: inline-block;
					vertical-align: top;
					height: $omrHeaderHeight;
					font-size: 12px;
					font-weight: 700;

					.headerLabelValue {
						user-select: none;
					}
				}

				.headerItem {
					width: calc(100% - #{$omrHeaderCheckboxWidth} - #{$omrHeaderQuantityWidth} - #{$omrHeaderPriceWidth} - #{$omrHeaderReturnWidth});

					.headerLabelValue {
						margin: 0 0 0 0.5*$viewOrderMargin;
					}
				}

				.headerQuantity {
					width: $omrHeaderQuantityWidth;
				}

				.headerPrice {
					width: $omrHeaderPriceWidth;
				}

				.headerInventoryReturn {
					width: $omrHeaderReturnWidth;

					.headerLabelToggle {
						position: relative;
						display: inline-block;
						vertical-align: top;
						width: $omrReturnCheckboxWidth;
						height: $omrReturnCheckboxWidth;
						margin: 0.5*($omrHeaderHeight - $omrReturnCheckboxWidth) 0;
					}

					.headerLabelValue {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$omrCheckboxWidth} - #{0.5*$viewOrderMargin});
						height: $omrHeaderHeight;
						margin: 0 0 0 0.5*$viewOrderMargin;
					}
				}
			}
		}

		.refundScrollableWrapper {

			height: auto;
			max-height: $omrScrollableHeight;
			overflow: auto;
			scrollbar-width: none; /* Firefox-specific, reduces width */
    		scrollbar-color: transparent transparent; /* Firefox-specific, makes it transparent */

    		&::-webkit-scrollbar {
    			width: 0; /* Removes the scrollbar space */
    			height: 0;
    		}

    		&::-webkit-scrollbar-thumb {
    			background: transparent;
    		}

    		&::-webkit-scrollbar-track {
    			background: transparent;
    		}

			.refundScrollableElement {

				.refundScrollableLiner {

					position: relative;

					.noItems {

						margin: 3*$viewOrderMargin 0;

						.noItemsNotice {
							text-align: center;
							font-size: 20px;
							font-weight: 300;
						}
					}

					.itemsListWrapper {

						.itemsList {

							.selected.lineItemWrapper {
								background: $omrRowSelectedBackground;

								&:nth-child(odd) {
									background: $omrRowSelectedBackground;
								}
							}

							.lineItemWrapper {
								background: $viewOrderBackground;

								&:nth-child(odd) {
									background: $omrRowAlternateBackground;
								}
							}
						}
					}
				}
			}
		}

		.refundSummaryWrapper {

			margin: $viewOrderMargin 0;

			.refundSummary {

				.totalSummary.summaryLineWrapper {

					margin: 0 0 $viewOrderMargin 0;

					.summaryLabel {
						font-weight: 400;

						.summaryLabelAction {
							font-size: 12px;
							font-weight: 700;
							text-align: right;
							color: $viewOrderAccent;
							cursor: pointer;
							margin: 0 0.5*$viewOrderMargin 0 0;
							transition: color $hoverTransition;

							&:hover {
								color: $viewOrderAccentHover;
							}
						}
					}

					.summaryValue {
						font-weight: 700;

						.summaryInputWrapper {

							position: relative;

							.summaryInput {
								border: 1px solid $omrCheckboxBorder;
								padding: 0.25*$viewOrderMargin $viewOrderMargin;
								font-size: 14px;
								text-align: right;
								width: calc(100% - #{2*$viewOrderMargin} - 2px);
							}

							.summaryCurrencyOverlay {
								position: absolute;
								top: 0;
								left: 0;
								bottom: 0;
								width: 2*$viewOrderMargin;
								font-size: 14px;
								font-weight: 300;
							}
						}
					}
				}

				.summaryLineWrapper {

					margin: 0 0 0.5*$viewOrderMargin 0;
					text-align: right;

					.summaryLabel {
						display: inline-block;
						vertical-align: top;
						width: auto;
						font-size: 18px;
						font-weight: 300;
						margin: 0 0.5*$viewOrderMargin 0 0;

						.summaryLabelValue {

							&:after {
								content: ':';
							}
						}
					}

					.summaryValue {
						display: inline-block;
						vertical-align: top;
						width: $omrTotalValueWidth;
						font-size: 18px;
						font-weight: 400;
						margin: 0 0.5*$viewOrderMargin 0 0;
					}
				}
			}
		}
	}
}


/////////////////////////////////
// OrderModalRefundStepPayment //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.OrderModalRefundStepPayment {

	.omsLiner {
		
		.refundConfigWrapper {

			margin: $viewOrderMargin 0 0 0;

			.refundConfig {

				.refundConfigLine {

					margin: 0 0 $viewOrderMargin 0;

					.configInputWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;
						width: $omrCheckboxWidth;
						height: $omrCheckboxWidth;

						.configCheckboxWrapper {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
						}
					}

					.configLabelWrapper {
						cursor: pointer;
						display: inline-block;
						vertical-align: top;
						width: auto;
						margin: 0 0 0 $viewOrderMargin;
						height: $omrCheckboxWidth;
						line-height: $omrCheckboxWidth;
						user-select: none;
					}
				}
			}
		}
	}
}


//////////////////////////
// OrderModalGradeItems //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$orderModalGradeScrollableHeight: 360px;
$orderModalLoadingIconHeight: 60px;
$orderModalTotalLoadingIconHeight: 18px;
$orderModalLoadingOverlayOpacity: 0.7;


.OrderModalGradeItems {

	.omvWrapper {

		.orderGradingForm {

			position: relative;

			.gradeStep0 {

				.gradingScrollableWrapper {

					overflow: auto;
					max-height: $orderModalGradeScrollableHeight;

					.gradingScrollableLiner {
						padding: 0 $viewOrderMargin 0 0;

						.nullSpacer {
							height: 3*$orderModalLoadingIconHeight;
						}

						.noItems {

							margin: 4*$viewOrderMargin 0;

							.noItemsNotice {
								font-size: 24px;
								font-weight: 300;
								text-align: center;
							}
						}

						.itemsListWrapper {

							.itemsList {

								.lineItemWrapper {
									background: $viewOrderBackground;

									&:nth-child(even) {
										background: $omrRowAlternateBackground;
									}
								}
							}
						}
					}
				}

				.gradingTotalWrapper {

					margin: $viewOrderMargin 0 0 0;

					.gradingTotalLiner {

						font-size: 20px;
						text-align: right;

						.gradingTotalLabel {
							display: inline-block;
							vertical-align: baseline;
							width: auto;
							margin: 0 $viewOrderMargin 0 0;
							font-weight: 700;

							&:after {
								content: ':';
							}
						}

						.gradingTotalValue {
							display: inline-block;
							vertical-align: baseline;
							width: auto;
						}

						.gradingTotalValueLoading {
							display: inline-block;
							vertical-align: baseline;
							width: $orderModalTotalLoadingIconHeight;
							height: $orderModalTotalLoadingIconHeight;
						}
					}
				}
			}

			.gradeStep1 {

				.step1Liner {

					margin: $orderModalLoadingIconHeight 0;
					text-align: center;

					.step1Copy {

						font-size: 22px;
						font-weight: 300;
						padding: 0 2*$viewOrderMargin;

						.storeCredit {
							font-weight: 400;
						}
					}

					.step1ToggleWrapper {
						margin: 2*$viewOrderMargin 3*$viewOrderMargin 0 3*$viewOrderMargin;
						text-align: left;
					}
				}
			}

			.active.loadingOverlay {
				display: block;
			}

			.loadingOverlay {
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($viewOrderBackground, $orderModalLoadingOverlayOpacity);

				.loadingIconWrapper {

					position: absolute;
					top: calc(50% - #{$orderModalLoadingIconHeight/2});
					left: calc(50% - #{$orderModalLoadingIconHeight/2});
					width: $orderModalLoadingIconHeight;
					height: $orderModalLoadingIconHeight;

					svg {
						fill: $viewOrderAccent;
					}
				}
			}
		}
	}
}






















