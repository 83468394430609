

@use '../../../../style/import' as *;




/////////////////////
// LayoutComponent //
/////////////////////


// Titan colors
$loadingTitanColor: $TITAN_PRIMARY_COLOR;

// Theme colors
$loadingThemeColor: $THEME_PRIMARY_COLOR;

// General variables
$loadingHeight: 200px;
$loadingIconWidth: 60px;


.LayoutComponent {

	&.titanTheme {

		.layoutLiner {
			.layoutLoading {
				.loadingIconWrapper {
					svg {
						fill: $loadingTitanColor;
					}
				}
			}
		}
	}

	.layoutLiner {

		.layoutLoading {

			position: relative;
			height: $loadingHeight;

			.loadingIconWrapper {

				position: absolute;
				top: calc(50% - #{0.5*$loadingIconWidth});
				left: calc(50% - #{0.5*$loadingIconWidth});
				width: $loadingIconWidth;
				height: $loadingIconWidth;

				svg {
					fill: $loadingThemeColor;
				}
			}
		}
	}
}


