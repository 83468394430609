

@use '../../../../style/import' as *;




/////////////
// Coupons //
/////////////


// Titan colors
$couponsPrimaryColor: $TITAN_PRIMARY_COLOR;
$couponsPrimaryColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$couponsPrimaryColorText: $TITAN_PRIMARY_COLOR_TEXT;
$couponsBackground: $TITAN_BACKGROUND;
$couponsTextColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$couponsMargin: 10px;


.Coupons {

}



///////////////
// AddCoupon //
///////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddCoupon {

}



////////////////
// ViewCoupon //
////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ViewCoupon {

}


///////////////////////
// CouponDetailsView //
///////////////////////


// Titan colors
$couponDisabledColor: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet

.CouponDetailsView {

	.detailsMethodDisabled {
		color: $couponDisabledColor;
		text-align: center;
		margin: 2*$couponsMargin 0;
		font-size: 20px;
		font-weight: 700;
	}
}




