

@use '../../../../style/import' as *;

// Titan colors
$pokemonImageFlipButtonColor: $TITAN_TEXT_COLOR;
$pokemonImageFlipButtonColorHover: $TITAN_TEXT_COLOR_HOVER;
$pokemonImageFlipButtonBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$pokemonImageHeight: 139.34426%;


//////////////////
// PokemonImage //
//////////////////

.PokemonImage {

	position: relative;
	display: inline-block;
	width: 100%;
	padding-bottom: $pokemonImageHeight;

	.pokemonObjImageWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img {
			width: 100%;
		}
	}
}


