

@use '../../../../style/import' as *;


///////////////
// ContactUs //
///////////////


// Titan colors
// None yet

// Theme colors
$contactPrimaryColor: $THEME_PRIMARY_COLOR;
$contactPrimaryColorHover: $THEME_PRIMARY_COLOR_HOVER;
$contactPrimaryColorText: $THEME_PRIMARY_COLOR_TEXT;
$contactPrimaryColorLight: $THEME_PRIMARY_ULTRALIGHT;
$contactBackground: $THEME_BACKGROUND;
$contactTextColor: $THEME_TEXT_COLOR;

// General variables
$contactHeaderPadding: 100px;
$contactBlockHeight: 80px;
$contactIconWidth: 50px;
$contactMargin: 10px;
$contactBodyMaxWidth: 900px;
$contactBodyMinHeight: 300px;
$contactDigitalMaxWidth: 350px;


.ContactUs {
	
	.contactHeader {

		position: relative;
		overflow: auto;
		background-image: url('../img/contact.jpg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		.titleWrapper {

			overflow: auto;
			box-shadow: 0 0 $contactMargin 0.5*$contactMargin $contactTextColor inset;
			background: rgba($contactTextColor, 0.8);

			.pageTitle {

				margin: $contactHeaderPadding 0;
				text-align: center;
				color: $contactBackground;

				.pageTitleCopy {
					display: inline-block;
					vertical-align: middle;
					font-size: 40px;
					font-weight: 400;
				}
			}
		}
	}

	.contactBody {

		.contactDigital {

			text-align: center;
			margin: 3*$contactMargin auto 5*$contactMargin auto;
			transition: margin $breakTransition;

			@media #{$break1024} {
				text-align: left;
				width: 90%;
				max-width: $contactDigitalMaxWidth;
				margin: 2*$contactMargin auto 3*$contactMargin auto;
			}

			.contactBlockWrapper {

				position: relative;
				overflow: hidden;
				display: inline-block;
				vertical-align: top;
				margin: 0 5*$contactMargin $contactMargin 5*$contactMargin;
				height: $contactBlockHeight;
				border-radius: 0.5*$contactBlockHeight;
				background: $contactBackground;
				cursor: pointer;
				transition: background $hoverTransition, margin $breakTransition, height $breakTransition, border-radius $breakTransition;

				@media #{$break1024} {
					margin: 0 0 2*$contactMargin 0;
					height: 0.5*$contactBlockHeight;
					border-radius: 0.25*$contactBlockHeight;
				}

				&:hover {

					.contactBlock {

						.contactIconWrapper {
							background: $contactPrimaryColorHover;
						}

						.contactBlockBackground {
							width: 100%;
							transition: width $slideTransition;
						}
					}
				}

				.contactBlock {

					.contactIconWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;
						width: $contactBlockHeight;
						height: $contactBlockHeight;
						border-radius: 50%;
						background: $contactPrimaryColor;
						transition: background $hoverTransition, width $breakTransition, height $breakTransition;

						@media #{$break1024} {
							width: 0.5*$contactBlockHeight;
							height: 0.5*$contactBlockHeight;
						}

						.contactIcon {

							position: absolute;
							top: 0.25*$contactBlockHeight;
							left: 0.25*$contactBlockHeight;
							width: 0.5*$contactBlockHeight;
							height: 0.5*$contactBlockHeight;
							transition: top $breakTransition, left $breakTransition, width $breakTransition, height $breakTransition;

							@media #{$break1024} {
								top: 0.125*$contactBlockHeight;
								left: 0.125*$contactBlockHeight;
								width: 0.25*$contactBlockHeight;
								height: 0.25*$contactBlockHeight;
							}
							
							.icon {

								width: 0.5*$contactBlockHeight;
								height: 0.5*$contactBlockHeight;
								transition: width $breakTransition, height $breakTransition;

								@media #{$break1024} {
									width: 0.25*$contactBlockHeight;
									height: 0.25*$contactBlockHeight;
								}

								svg {
									fill: $contactPrimaryColorText;
								}
							}
						}
					}

					.contactValueWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;

						.contactValue {
							margin: 0.25*$contactBlockHeight 0.5*$contactBlockHeight 0.25*$contactBlockHeight 0.25*$contactBlockHeight;
							height: 0.5*$contactBlockHeight;
							line-height: 0.5*$contactBlockHeight;
							font-size: 24px;
							transition: height $breakTransition, line-height $breakTransition, font-size $breakTransition, margin $breakTransition;

							@media #{$break1024} {
								margin: 0.125*$contactBlockHeight 0.25*$contactBlockHeight 0.125*$contactBlockHeight 0.125*$contactBlockHeight;
								height: 0.25*$contactBlockHeight;
								line-height: 0.25*$contactBlockHeight;
								font-size: 18px;
							}
						}
					}

					.contactBlockBackground {
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 100%;
						background: $contactPrimaryColorLight;
						border-radius: 0.5*$contactBlockHeight;
						transition: width $slideFastTransition;
					}
				}
			}
		}

		.contactPhysical {

			width: 90%;
			max-width: $contactBodyMaxWidth;
			margin: 0 auto 5*$contactMargin auto;

			.contactPhysicalTitle {
				font-size: 24px;
				font-weight: 700;
				margin: 0 0 2*$contactMargin 0;

				@media #{$break768} {
					text-align: center;
				}
			}

			.contactPhysicalBody {

				.cpBodyLiner {

					position: relative;
					min-height: $contactBodyMinHeight;

					.cpInfoWrapper {
						display: inline-block;
						vertical-align: top;
						width: 50%;
						transition: width $breakTransition;

						@media #{$break768} {
							display: block;
							width: 100%;
						}

						.cpInfo {

							.addressBlock {

								margin: 0 $contactMargin 3*$contactMargin 0;

								.addressLink {

									&:hover {

										.addressIconWrapper {

											.addressIcon {

												svg {
													fill: $contactPrimaryColor;
												}
											}
										}
									}

									.addressIconWrapper {

										display: inline-block;
										vertical-align: top;
										width: $contactIconWidth;
										margin: 0 $contactMargin 0 0;

										.addressIcon {
											width: $contactIconWidth;
											height: $contactIconWidth;
										}
									}

									.addressTextWrapper {

										display: inline-block;
										vertical-align: top;
										width: auto;
										max-width: calc(100% - #{$contactIconWidth} - #{$contactMargin});

										@media #{$break768} {
											text-align: left;
										}

										.addressLine {

										}
									}
								}
							}

							.hoursBlock {
								margin: 0 0 3*$contactMargin 0;

								.hoursIconWrapper {

									display: inline-block;
									vertical-align: top;
									width: $contactIconWidth;
									margin: 0 $contactMargin 0 0;

									.hoursIcon {
										width: $contactIconWidth;
										height: $contactIconWidth;
									}
								}

								.hoursTextWrapper {

									display: inline-block;
									vertical-align: top;
									width: auto;
									max-width: calc(100% - #{$contactIconWidth} - #{$contactMargin});

									@media #{$break768} {
										text-align: left;
									}

									.hoursTitle {
										margin: 0 0 $contactMargin 0;
										font-size: 18px;
										font-weight: 700;
										text-transform: uppercase;
									}
								}
							}
						}
					}

					.cpMapWrapper {
						position: absolute;
						top: 0;
						left: 50%;
						width: 50%;
						height: 100%;
						transition: width $breakTransition;

						@media #{$break768} {
							position: relative;
							left: unset;
							width: 100%;
							height: $contactBodyMinHeight;
							margin: 0 auto;
						}
						
						.mapIframe {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.contactForm {

		}
	}
}




















