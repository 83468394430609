

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;




/////////////////////
// AccountLoggedIn //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AccountLoggedIn {

	cursor: pointer;
	user-select: none;

	.humWrapper {

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background: $headerBackground;
		transition: background $hoverTransition;

		&:hover {

			background: $headerBackgroundHover;

			.humArrow {
				.arrowWrapper {
					border-top: (0.375 * $headerAccountHeight) solid $headerAccentColor;
				}
			}
		}

		@media #{$break1024} {
			display: none;
		}

		.humBody {
			position: absolute;
			top: 0.1*$headerAccountHeight;
			bottom: 0.1*$headerAccountHeight;
			left: $MARGIN_S;
			right: $MARGIN_S + $headerAccountHeight;
		}

		.humArrow {

			position: absolute;
			top: 0;
			right: $MARGIN_S;
			bottom: 0;
			width: 0.5*$headerAccountHeight;

			.arrowWrapper {

				position: absolute;
				top: 0.375 * $headerAccountHeight;
				left: 0;
				width: 0; 
				height: 0; 

				border-left: 0.25*$headerAccountHeight solid transparent;
				border-right: 0.25*$headerAccountHeight solid transparent; 
				border-top: (0.375 * $headerAccountHeight) solid $headerColor; 
				transition: border-color $hoverTransition;
			}
		}
	}

	.mobileWrapper {

		display: none;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		@media #{$break1024} {
			display: block;
		}

		.iconClass {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&:hover {
				svg {
					fill: $headerAccentColor;
				}
			}
		}
	}
}













