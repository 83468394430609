

@use '../../../../style/import' as *;




////////////////
// StoreHours //
////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$storeHoursMargin: 10px;


.StoreHours {

	.storeHoursWrapper {

		.hoursLine {

			margin: 0 0 $storeHoursMargin 0;

			.hoursDay {
				font-size: 16px;
				font-weight: 400;
			}

			.hoursValue {
				font-size: 15px;
				font-weight: 300;
			}
		}
	}
}

