

@use '../../../../style/import' as *;


// Titan colors
// None yet

// Theme colors
$authColorAccent: $THEME_PRIMARY_COLOR;
$authColorAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$authColorAccentText: $THEME_PRIMARY_COLOR_TEXT;
$authColor: $THEME_TEXT_COLOR;
$authColorHover: $THEME_TEXT_COLOR_HOVER;
$authHrColor: $THEME_RULE_COLOR;
$authFormBackground: $THEME_BACKGROUND_CONTRAST;

// General variables
$authMargin: 10px;
$authSpacing: 25px;

// Component variables
// None yet



.authPage {

	margin: 0 auto $authSpacing auto;
	color: $authColor;
	width: $fixedWidth;
	max-width: $fixedMaxWidth;

	.authPageTitle {
		text-align: center;
		font-family: $fontLato;
		font-size: 32px;
		font-weight: 700;
		margin: $authSpacing 0;
		transition: margin $breakTransition, font-size $breakTransition;

		@media #{$break600} {
			margin: 10px 0;
			font-size: 24px;
		}
	}

	.authPageBody {
		margin: 0 auto;
		width: calc(90% - #{2*$authSpacing});
		max-width: 400px;
		padding: $authSpacing;
		background: $authFormBackground;
		transition: width $breakTransition;

		@media #{$break600} {
			width: calc(100% - #{2*$authSpacing});
		}
	}
}

.Login, 
.ForgotPassword,
.ReAuth,
.Register,
.ResetPassword,
.SetPassword {

	.toggleButton {
		background: $authFormBackground;
		color: $authColorAccent;
		border: 3px solid $authColorAccent;
		width: calc(100% - 0px);
		padding: 15px 0;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		cursor: pointer;
		transition: color $hoverTransition, background $hoverTransition;

		&:hover {
			background: $authColorAccent;
			color: $authColorAccentText;
		}
	}

	.loginWrapper {




		.loginForm {


			.present.loginFormError {
				height: 50px;
			}

			.loginFormError {
				color: $errorRed;
				font-weight: 700;
				font-size: 16px;
				height: 0;
				text-align: center;
				overflow: hidden;
				transition: height $toggleTransition;
			}

			.loginText {
				font-size: 18px;
				font-weight: 300;
				margin: 0 0 2*$authMargin 0;
			}

			.inputFieldWrapper.loginFieldWrapper {
				margin: 0 0 25px 0;
			}

			.toggleButtonWrapper.loginFieldWrapper {
				margin: 25px 0 0 0;
			}

			.hrWrapper.loginFieldWrapper {
				margin: 0;

				.hrBody {
					margin: $authSpacing auto;
					width: 80%;
					height: 0;
					border-top: 1px solid $authHrColor;
					border-bottom: 1px solid $authHrColor;
				}
			}


			.loginFieldWrapper {

				margin: 0;

				.loginFieldLabel {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 5px 0;
				}

				.loginValue {
					font-size: 18px;
					font-weight: 300;
				}

				input {
					border: 1px solid $authColor;
					width: calc(100% - 50px - 2px);
					font-size: 20px;
					padding: 10px 25px;
					margin: 0;
				}

				.forgotPasswordWrapper {
					
					text-align: right;
					margin: 0 0 25px 0;
					transition: color $hoverTransition;

					a {
						color: $authColor;
						transition: color $hoverTransition;

						&:hover {
							color: $authColorHover;
						}
					}
				}

				.loginSubmitButton {
					width: 100%;
					color: $authColorAccentText;
					background: $authColorAccent;
					border: none;
					padding: 15px 0;
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 700;
					text-align: center;
					cursor: pointer;
					transition: background $hoverTransition;

					&:hover {
						background: $authColorAccentHover;
					}
				}
			}
		}

		.registerToggleTop.registerToggle {
			margin: 0 0 0 0;
		}

		.registerToggle {

			margin: 50px 0 0 0;

			.toggleHeader {
				font-size: 24px;
				font-weight: 700;
				font-family: $fontLato;
			}

			.toggleHr {
				margin: 0;

				.hrBody {
					margin: $authSpacing auto;
					width: 80%;
					height: 0;
					border-top: 1px solid $authHrColor;
					border-bottom: 1px solid $authHrColor;
				}
			}

			.toggleCopy {
				margin: $authMargin 0 $authSpacing 0;
			}

			.soloButton.toggleButton {
				margin: 2*$authMargin 0 $authMargin 0;
			}
		}
	}
}

.ForgotPassword {
	.loginWrapper {

		.forgotSuccess {



			.forgotSuccessTitle {
				font-size: 24px;
				font-family: $fontLato;
				font-weight: 700;
				margin: 0 0 2*$authMargin 0;
			}

			.forgotSuccessText {
				font-size: 18px;
				font-weight: 300;
				margin: 0 0 2*$authMargin 0;
			}
		}

		.registerToggle {
			.toggleHeader {
				margin-bottom: $authSpacing;
			}
		}
	}
}

.ReAuth {
	.loginWrapper {

		.registerToggle {
			margin-top: $authSpacing;
		}
	}
}