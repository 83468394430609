

@use '../../../../style/import' as *;




///////////////////////
//   OrderComplete   //
///////////////////////


// Titan colors
// None yet

// Theme colors
$completeAccent: $THEME_PRIMARY_COLOR;
$completeAccentColor: $THEME_PRIMARY_COLOR_TEXT;
$completeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$completeBackground: $THEME_BACKGROUND;
$completeColor: $THEME_TEXT_COLOR;

// General variables
$completeButtonHeight: 40px;
$completeButtonWidth: 200px;
$completeDetailsMaxWidth: 800px;
$completeLoadingHeight: 60px;
$completeMargin: 10px;
$completeMaxWidth: 1200px;
$completeMethodMinHeight: 80px;
$completeMethodMaxWidthMobile: 200px;


.OrderComplete {

	color: $completeColor;
	width: 90%;
	max-width: $completeMaxWidth;
	margin: 4*$completeMargin auto 2*$completeMargin auto;

	.ocLiner {

		.ocHeaderWrapper {

			margin: 0 0 $completeMargin 0;
			text-align: center;

			.ochTitle {
				font-size: 32px;
				font-weight: 700;
				font-family: $fontLato;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 24px;
				}
			}

			.ochCopy {
				width: 80%;
				margin: $completeMargin auto 0 auto;
				font-size: 18px;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}
			}
		}

		.ocDetailsWrapper {

			margin: 0 auto;
			max-width: $completeDetailsMaxWidth;

			.orderLoading {

				position: relative;
				height: 5*$completeLoadingHeight;

				.loadingIconWrapper {
					position: absolute;
					top: calc(50% - #{$completeLoadingHeight}/2);
					left: calc(50% - #{$completeLoadingHeight}/2);
					width: $completeLoadingHeight;
					height: $completeLoadingHeight;

					svg {
						fill: $completeAccent;
					}
				}
			}

			.ocDetailsData {

				.ocdMethodsWrapper {

					position: relative;
					text-align: center;
					margin: 2*$completeMargin 0 $completeMargin 0;

					.ocdMethodsVr {
						position: absolute;
						top: 0;
						bottom: 0;
						width: 2px;
						left: calc(50% - 1px);
						background: $completeAccent;

						@media #{$break768} {
							display: none;
						}
					}

					.ocdMethodsTitle {
						display: inline-block;
						vertical-align: top;
						font-size: 18px;
						font-family: $fontLato;
						font-weight: 700;
						margin: 0 0 $completeMargin 0;
						border-bottom: 2px solid $completeAccent;
						transition: margin $breakTransition;

						@media #{$break480} {
							margin: 0 0 $completeMargin/2 0;
						}
					}

					.ocdPaymentMethodWrapper {
						margin-left: 4*$completeMargin;
					}

					.ocdShippingMethodWrapper, .ocdPaymentMethodWrapper {
						display: inline-block;
						vertical-align: top;
						width: calc(50% - #{2*$completeMargin});
						text-align: left;
						transition: width $breakTransition;

						@media #{$break768} {
							width: $completeMethodMaxWidthMobile;
							margin: 0 auto;
						}

						.methodBlockWrapper {
							height: auto;
							min-height: $completeMethodMinHeight;
							margin: 0 0 2*$completeMargin 0;
							transition: min-height $breakTransition, margin $breakTransition;

							@media #{$break768} {
								margin: 0 0 2*$completeMargin 0;
							}

							@media #{$break480} {
								min-height: 0;
							}

							.methodName {
								font-size: 16px;

								.amountEm {
									font-weight: 700;
								}

								.prDisplayBrand {
									font-weight: 700;
								}

								.prDisplayNumber {
									margin: 0 0 0 1.5*$completeMargin;
								}

								.prDisplayExp {
									margin: 0 0 0 1.5*$completeMargin;
									font-weight: 300;
								}
							}

							.methodDescription {
								font-weight: 300;
							}
						}

						.addressBlockWrapper {

							margin: 0 0 0 0;

							@media #{$break768} {
								margin: 0 0 $completeMargin 0;
							}

							.addressNote {
								font-weight: 300;
							}
						}
					}					
				}

				.ocdOrderDetailsWrapper {

					margin: 2*$completeMargin 0 0 0;

					.ocdDetailsTitle {
							
						.ocdDetailsTitleValue {
							display: inline-block;
							vertical-align: top;
							font-size: 22px;
							font-family: $fontLato;
							font-weight: 700;
							margin: 0 0 $completeMargin 0;
							border-bottom: 2px solid $completeAccent;
						}
					}

					.ocdDetailsCart {
						margin: $completeMargin 0 0 0;
					}
				}

				.ocdOrderSummaryWrapper {
					border-top: 2px solid $completeAccent;
					margin: 0 0 2*$completeMargin calc(50% + #{2*$completeMargin});
					padding: 2*$completeMargin 0 0 0;
					width: calc(50% - #{2*$completeMargin});
					transition: margin $breakTransition, width $breakTransition, padding $breakTransition;

					@media #{$break480} {
						margin: 0 0 2*$completeMargin 0;
						padding: $completeMargin 0 0 0;
						width: 100%;
					}
				}
			}
		}

		.ocOptionsWrapper {

			text-align: center;

			a {

				&:nth-child(even) {

					.optionButton {

						@media #{$break600} {
							margin-left: $completeMargin;
						}
					}
				}

				.optionButton {
					width: $completeButtonWidth;
					height: $completeButtonHeight;
					padding: 0 $completeMargin;
					margin: 0 $completeMargin $completeMargin $completeMargin;
					color: $completeAccentColor;
					background: $completeAccent;
					border: none;
					text-align: center;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					cursor: pointer;
					transition: background $hoverTransition, height $breakTransition, width $breakTransition, margin $breakTransition;

					@media #{$break600} {
						padding: $completeMargin/2;
						width: calc(50% - #{$completeMargin/2});
						height: $completeButtonHeight + $completeMargin;
						margin: 0 0 $completeMargin 0;
					}

					&:hover {
						background: $completeAccentHover;
					}
				}
			}
		}
	}
}