

@use '../../../../style/import' as *;




//////////////
// ViewCart //
//////////////


// Titan colors
$viewCartAccent: $TITAN_PRIMARY_COLOR;
$viewCartAccentColor: $TITAN_PRIMARY_COLOR_TEXT;
$viewCartAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$viewCartBackground: $TITAN_BACKGROUND;
$viewCartColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$viewCartMargin: 10px;


.ViewCart {

}


/////////////////////
// CartDetailsView //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CartDetailsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

			}
		}
	}
}


///////////////////
// CartItemsView //
///////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$cartItemsViewBorderWidth: 2px;


.CartItemsView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.itemsMinicartWrapper {
				margin: $viewCartMargin 0 0 0;
			}

			.itemsSubtotalWrapper {

				text-align: right;

				.itemsSubtotalLiner {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 0 $viewCartMargin 0;
					padding: $viewCartMargin 0 0 5*$viewCartMargin;
					border-top: $cartItemsViewBorderWidth solid $viewCartAccent;

					.itemsSubtotalValue {
						font-size: 20px;
					}
				}
			}
		}
	}
}


///////////////////
// CartModalView //
///////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$cartModalFormBorderColor: $TITAN_TEXT_COLOR_LIGHT;


.CartModalView {

	.cmvWrapper {

		.orderModalCopy {
			margin: 1.5*$viewCartMargin 0 0 0;
			font-size: 16px;
			font-weight: 300;
		}

		.cartModalForm {

			margin: $viewCartMargin 0;

			.present.cmFormError {
				height: 3.5*$viewCartMargin;
			}

			.cmFormError {
				color: $errorRed;
				font-weight: 700;
				font-size: 16px;
				height: 0;
				text-align: left;
				overflow: hidden;
				transition: height $toggleTransition;
			}

			.cartModalCopy {
				margin: 0 0 $viewCartMargin 0;
				font-size: 18px;
				text-align: center;
			}

			.cmFieldWrapper {

				margin: 0 auto $viewCartMargin auto;

				.cmFieldLabel.cmRequired {

					&:after {
						content: '*';
						color: $viewCartAccent;
						font-size: 16px;
					}
				}

				.cmFieldLabel.cmOptional {
					/* Only needed when aligned next to required field */
					&:after {
						content: '*';
						color: $viewCartAccent;
						font-size: 16px;
						opacity: 0;
					}
				}

				.cmFieldLabel {
					color: $viewCartColor;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 0.5*$viewCartMargin 0;
					user-select: none;
				}

				.cmInputWrapper {

					.cmDropdownWrapper {

						border: 1px solid $cartModalFormBorderColor;

						.cmDropdownSelect {
							margin: $viewCartMargin 0.5*$viewCartMargin $viewCartMargin 2*$viewCartMargin;
							border-bottom: none;
						}
					}

					input {
						width: calc(100% - #{4*$viewCartMargin} - 2px);
						padding: $viewCartMargin 2*$viewCartMargin;
						border: 1px solid $cartModalFormBorderColor;
					}

					textarea.tallText {
						height: 10*$viewCartMargin;
					}

					textarea {
						width: calc(100% - #{4*$viewCartMargin} - 2px);
						padding: $viewCartMargin 2*$viewCartMargin;
						border: 1px solid $cartModalFormBorderColor;
						border-radius: 0;
						outline: none;
					}
				}
			}
		}
	}
}


/////////////////////////////
// CartModalChangeCustomer //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$cartModalChangeCustomerIconWidth: 30px;
$cartModalChangeCustomerLoadingIconScale: 0.6;
$cartModalChangeCustomerMinicartMaxHeight: 240px;


.CartModalChangeCustomer {

	.cmvWrapper {

		.changeCustomerForm {

			.cmFormError {
				text-align: center;
			}

			.selectedUserSection {

				margin: 0 0 $viewCartMargin 0;

				.cartUserWrapper {

					display: inline-block;
					vertical-align: top;
					width: 50%;
					margin: $viewCartMargin 0 $viewCartMargin 0;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.cartUserIconWrapper {

						display: inline-block;
						vertical-align: top;
						width: $cartModalChangeCustomerIconWidth;
						height: $cartModalChangeCustomerIconWidth;

						.cartUserIcon {
							position: relative;
							width: $cartModalChangeCustomerIconWidth;
							height: $cartModalChangeCustomerIconWidth;
						}
					}

					.cartUserBodyWrapper {

						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$cartModalChangeCustomerIconWidth} - #{$viewCartMargin});

						.userNameWrapper {

							margin: 0 0 0 $viewCartMargin;

							.userNameValue {
								display: inline-block;
								vertical-align: baseline;
								width: auto;
								font-size: 16px;
							}

							.userAction {
								display: inline-block;
								vertical-align: baseline;
								width: auto;
								margin: 0 0 0 $viewCartMargin;
								cursor: pointer;
								font-size: 14px;
								font-weight: 700;
								color: $viewCartAccent;
								transition: color $hoverTransition;

								&:hover {
									color: $viewCartAccentHover;
								}
							}
						}

						.userEmailWrapper {
							margin: 0 0 0 $viewCartMargin;
							font-size: 14px;
							font-weight: 300;
						}
					}
				}

				.existingCartWrapper {

					display: inline-block;
					vertical-align: top;
					width: 50%;
					margin: $viewCartMargin 0 $viewCartMargin 0;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.existingCartIconWrapper {

						display: inline-block;
						vertical-align: top;
						width: $cartModalChangeCustomerIconWidth;
						height: $cartModalChangeCustomerIconWidth;

						.existingCartIcon {
							position: relative;
							width: $cartModalChangeCustomerIconWidth;
							height: $cartModalChangeCustomerIconWidth;
						}
					}

					.existingCartBodyWrapper {

						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$cartModalChangeCustomerIconWidth} - #{$viewCartMargin});

						.cartLoadingWrapper {

							margin: 0 0 0 $viewCartMargin;

							.loadingIconWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: $cartModalChangeCustomerLoadingIconScale*$cartModalChangeCustomerIconWidth;
								height: $cartModalChangeCustomerIconWidth;

								.loadingIcon {
									position: absolute;
									top: 0.5*(1 - $cartModalChangeCustomerLoadingIconScale)*$cartModalChangeCustomerIconWidth;
									left: 0;
									width: $cartModalChangeCustomerLoadingIconScale*$cartModalChangeCustomerIconWidth;
									height: $cartModalChangeCustomerLoadingIconScale*$cartModalChangeCustomerIconWidth;

									svg {
										fill: $viewCartAccent;
									}
								}
							}

							.loadingCopyWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: auto;
								height: $cartModalChangeCustomerIconWidth;
								margin: 0 0 0 0.5*$viewCartMargin;

								.loadingCopy {
									font-size: 18px;
									font-weight: 300;
									height: $cartModalChangeCustomerIconWidth;
									line-height: $cartModalChangeCustomerIconWidth;
								}
							}
						}

						.cartLoadedWrapper {

							margin: 0 0 0 $viewCartMargin;

							.emptyCartWrapper {

								.emptyCartLabel {
									display: inline-block;
									vertical-align: baseline;
									width: auto;
									font-size: 16px;
									font-weight: 400;
									height: $cartModalChangeCustomerIconWidth;
									line-height: $cartModalChangeCustomerIconWidth;

									&:after {
										content: ':';
									}
								}

								.emptyCartValue {
									display: inline-block;
									vertical-align: baseline;
									width: auto;
									margin: 0 0 0 0.5*$viewCartMargin;
									font-size: 16px;
									font-weight: 300;
									height: $cartModalChangeCustomerIconWidth;
									line-height: $cartModalChangeCustomerIconWidth;
								}
							}

							.activeCartWrapper {

								.activeCartStatus {

									font-size: 16px;

									.statusLabel {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										font-weight: 400;

										&:after {
											content: ':';
										}
									}

									.statusValue {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										margin: 0 0 0 0.5*$viewCartMargin;
										font-weight: 300;
									}
								}

								.activeCartUpdate {

									font-size: 13px;

									.updateLabel {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										font-weight: 400;

										&:after {
											content: ':';
										}
									}

									.updateValue {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										margin: 0 0 0 0.5*$viewCartMargin;
										font-weight: 300;
									}
								}
							}
						}
					}
				}
			}

			.cartControlWrapper {

				.cartControlMinicartWrapper {

					.cartControlMinicartTitleWrapper {

						margin: 0 0 0.5*$viewCartMargin 0;

						.titleValue {
							display: inline-block;
							vertical-align: baseline;
							font-size: 18px;
							font-weight: 700;
						}

						.titleCount {
							display: inline-block;
							vertical-align: baseline;
							margin: 0 0 0 0.5*$viewCartMargin;
							font-size: 16px;
							font-weight: 300;
						}
					}

					.cartControlMinicartBodyWrapper {

						overflow: auto;
						height: auto;
						max-height: $cartModalChangeCustomerMinicartMaxHeight;

						.cartControlMinicartLiner {
							padding: 0 $viewCartMargin 0 0;
						}
					}
				}

				.mergeControlWrapper {

					margin: $viewCartMargin 0 0 0;

					.mergeCartCopy {
						font-size: 14px;
					}
				}
			}
		}
	}
}



////////////////////////////////////////
// CartModalChangeCustomerNullResults //
////////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CartModalChangeCustomerNullResults {

	.cartNullLiner {

		text-align: center;
		padding: 3*$viewCartMargin $viewCartMargin;

		.nullCopyLine {

			.noResultsCopy {
				display: inline-block;
				vertical-align: baseline;
				font-size: 18px;
				font-weight: 400;
			}

			.noResultsNeedle {
				display: inline-block;
				vertical-align: baseline;
				font-size: 18px;
				font-weight: 700;
			}
		}

		.nullActionWrapper {

			margin: $viewCartMargin 0 0 0;

			.nullAction {
				display: inline-block;
				cursor: pointer;
				background: $viewCartAccent;
				color: $viewCartAccentColor;
				border: none;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				padding: $viewCartMargin 2*$viewCartMargin;
				transition: background $hoverTransition;

				&:hover {
					background: $viewCartAccentHover;
				}
			}
		}
	}
}



//////////////////////////
// CartModalCreateOrder //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$cartModalCreateOrderLoadingWidth: 30px;


.CartModalCreateOrder {

	.cmvWrapper {

		.createOrderForm {

			.cmFormError {
				text-align: center;
			}

			.cartHeaderSection {

				margin: 0 0 $viewCartMargin 0;

				.cartUserWrapper {

					display: inline-block;
					vertical-align: top;
					width: 50%;
					margin: $viewCartMargin 0 $viewCartMargin 0;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.cartUserIconWrapper {

						display: inline-block;
						vertical-align: top;
						width: $cartModalChangeCustomerIconWidth;
						height: $cartModalChangeCustomerIconWidth;

						.cartUserIcon {
							position: relative;
							width: $cartModalChangeCustomerIconWidth;
							height: $cartModalChangeCustomerIconWidth;
						}
					}

					.cartUserBodyWrapper {

						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$cartModalChangeCustomerIconWidth} - #{$viewCartMargin});

						.userNameWrapper {

							margin: 0 0 0 $viewCartMargin;

							.userNameValue {
								display: inline-block;
								vertical-align: baseline;
								width: auto;
								font-size: 16px;
							}

							.userGuestValue {
								font-size: 20px;
								font-weight: 300;
								height: $cartModalChangeCustomerIconWidth;
								line-height: $cartModalChangeCustomerIconWidth;
							}
						}

						.userEmailWrapper {
							margin: 0 0 0 $viewCartMargin;
							font-size: 14px;
							font-weight: 300;
						}
					}
				}

				.cartStatusWrapper {

					display: inline-block;
					vertical-align: top;
					width: 50%;
					margin: $viewCartMargin 0 $viewCartMargin 0;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 100%;
					}

					.cartStatusIconWrapper {

						display: inline-block;
						vertical-align: top;
						width: $cartModalChangeCustomerIconWidth;
						height: $cartModalChangeCustomerIconWidth;

						.cartStatusIcon {
							position: relative;
							width: $cartModalChangeCustomerIconWidth;
							height: $cartModalChangeCustomerIconWidth;
						}
					}

					.cartStatusBodyWrapper {

						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$cartModalChangeCustomerIconWidth} - #{$viewCartMargin});

						.cartLoadedWrapper {

							margin: 0 0 0 $viewCartMargin;

							.emptyCartWrapper {

								.emptyCartLabel {
									display: inline-block;
									vertical-align: baseline;
									width: auto;
									font-size: 16px;
									font-weight: 400;
									height: $cartModalChangeCustomerIconWidth;
									line-height: $cartModalChangeCustomerIconWidth;

									&:after {
										content: ':';
									}
								}
							}

							.activeCartWrapper {

								.activeCartStatus {

									font-size: 16px;

									.statusLabel {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										font-weight: 400;

										&:after {
											content: ':';
										}
									}

									.statusValue {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										margin: 0 0 0 0.5*$viewCartMargin;
										font-weight: 300;
									}
								}

								.activeCartUpdate {

									font-size: 13px;

									.updateLabel {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										font-weight: 400;

										&:after {
											content: ':';
										}
									}

									.updateValue {
										display: inline-block;
										vertical-align: baseline;
										width: auto;
										margin: 0 0 0 0.5*$viewCartMargin;
										font-weight: 300;
									}
								}
							}
						}
					}
				}
			}

			.cartStatusError {

				text-align: center;

				.cartStatusValue {
					margin: 3*$viewCartMargin auto;
					font-size: 20px;
					font-weight: 300;
				}
			}

			.cartStatusValid {

				.cartOrderMinicartWrapper {

					.cartOrderMinicartTitleWrapper {

						margin: 0 0 0.5*$viewCartMargin 0;

						.titleValue {
							display: inline-block;
							vertical-align: baseline;
							font-size: 18px;
							font-weight: 700;
						}

						.titleCount {
							display: inline-block;
							vertical-align: baseline;
							margin: 0 0 0 0.5*$viewCartMargin;
							font-size: 16px;
							font-weight: 300;
						}
					}

					.cartOrderMinicartBodyWrapper {

						overflow: auto;
						height: auto;
						max-height: $cartModalChangeCustomerMinicartMaxHeight;

						.cartOrderMinicartLiner {
							padding: 0 $viewCartMargin 0 0;
						}
					}
				}

				.cartPaymentMethodWrapper {

					.pmLabel {
						font-size: 14px;
						font-weight: 700;
						margin: 0 0 0.5*$viewCartMargin 0;
					}

					.pmLoadingWrapper {

						.pmIconWrapper {
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: $cartModalCreateOrderLoadingWidth;
							height: $cartModalCreateOrderLoadingWidth;
						}

						.pmCopyWrapper {
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$cartModalCreateOrderLoadingWidth} - #{$viewCartMargin});
							height: $cartModalCreateOrderLoadingWidth;
							margin: 0 0 0 $viewCartMargin;

							.pmCopyLiner {
								font-size: 18px;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
}























