

@use '../../../../style/import' as *;


$customerPermissionsEditIncludedPermission: $TITAN_TEXT_COLOR;
$customerPermissionsEditExcludedPermission: $disabledColor;

$customerPermissionsEditPermissionHeight: 50px;
$customerPermissionsEditPermissionMargin: 10px;

$customerPermissionsEditToggleWidth: 60px;



.CustomerPermissionsEdit {




	.adminSectionBody {



		.adminSectionBodyWrapper {



			.customerPermissionsForm.adminForm {

				margin: $customerPermissionsEditPermissionMargin 0 0 0;
				padding: $customerPermissionsEditPermissionMargin 0 0 0;

				.editPermissionWrapper {

					position: relative;
					margin: 0 0 2*$customerPermissionsEditPermissionMargin 0;

					.editToggleWrapper {

						position: absolute;
						top: 0;
						left: 0;
						width: $customerPermissionsEditToggleWidth;
						height: 100%;

						.editToggleInputWrapper {



							.Toggle {
								width: $customerPermissionsEditToggleWidth;
							}
						}
					}

					.editToggleLabelWraper {

						position: relative;
						height: $customerPermissionsEditPermissionHeight;
						margin-left: $customerPermissionsEditToggleWidth + 2*$customerPermissionsEditPermissionMargin;

						.editPermissionTitle {
							position: absolute;
							top: 0;
							left: 0;
							right: $customerPermissionsEditPermissionMargin;
							height: $customerPermissionsEditPermissionHeight / 2;
							line-height: $customerPermissionsEditPermissionHeight / 2;

							font-size: 18px;
							font-weight: 400;
						}

						.editPermissionDesc {
							position: absolute;
							left: 0;
							right: $customerPermissionsEditPermissionMargin;
							bottom: 0;
							height: $customerPermissionsEditPermissionHeight / 2;
							line-height: $customerPermissionsEditPermissionHeight / 4;

							font-size: 13px;
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}