

@use '../../../../style/import' as *;




///////////////////
// WarningButton //
///////////////////


// Titan colors
$warningColor: $TITAN_ERROR_COLOR;
$warningBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
$borderWidth: 2px;


.WarningButton {

	// Size controls

	&.small {
		font-size: 12px;
		padding: $MARGIN_S 1.5*$MARGIN;
	}

	cursor: pointer;
	border-width: $borderWidth;
	border-style: solid;
	border-color: $warningColor;
	color: $warningColor;
	background: $warningBackground;
	padding: ($MARGIN - $borderWidth) $MARGIN_L;
	font-size: 14px;
	font-weight: 400;
	transition: color $hoverTransition, background $hoverTransition;

	&:hover {
		color: $warningBackground;
		background: $warningColor;
	}

	&:disabled {
		cursor: default;
	}
}













