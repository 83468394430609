

@use '../../../../../../style/import' as *;




/////////////////////
// FieldSupplement //
/////////////////////


// Titan colors
$textColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
// None yet


.FieldSupplement {

	&.subtleSupplement {
		font-weight: 300;
	}				

	margin: $MARGIN_S 0;
	font-size: 14px;
	font-weight: 700;
	color: $textColor;
						
	.urlLabel {
		font-weight: 400;
		margin: 0 $MARGIN 0 0;
	}

	.urlValue {
		font-weight: 300;
	}

	.characterCount {
		font-weight: 300;
		text-align: right;

		.countNum {
			font-weight: 300;
		}

		.countSlash {
			margin: 0 1px;
		}

		.countCopy {
			margin: 0 0 0 0.5*$MARGIN_S;
		}
	}
}













