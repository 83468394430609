

@use '../../../../style/import' as *;


/////////////////
// ProductPage //
/////////////////


// Titan colors
// None yet

// Theme colors
$productPageAccent: $THEME_PRIMARY_COLOR;
$productPageAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$productPageAccentText: $THEME_PRIMARY_COLOR_TEXT;

$productPageColor: $THEME_TEXT_COLOR;
$productPageColorLight: $THEME_TEXT_COLOR_LIGHT;
$productPageColorHover: $THEME_TEXT_COLOR_HOVER;

$productPageBackground: $THEME_BACKGROUND;
$productPageBackgroundHover: $THEME_BACKGROUND_HOVER;
$productPageBackgroundContrast: $THEME_BACKGROUND_CONTRAST;
$productLightOutline: $THEME_RULE_COLOR;

// General variables
$productPageMargin: 10px;
$productPageLoadingIconWidth: 70px;
$productPageLoadingPadding: 150px;

$productMediaDefaultWidth: 33.3%;
$productMediaTcgWidth: 25%;

$productPageMediaMaxWidth: 720px;
$productPageMediaMagicMaxWidth: 320px;
$productPageInventoryWidth: 360px;

$pp2ColWidthDefault: 95%;
$pp2ColMediaWidth: 50%;
$pp2ColMediaWidthMobile: 480px;



.ProductPage {

	color: $productPageColor;

	.productPageWrapper {

		margin: 3*$productPageMargin auto 0 auto;
		width: $fixedWidth;
		max-width: $fixedMaxWidth;

		.productPageLoading {
			padding: $productPageLoadingPadding 0;

			.loadingIcon {
				margin: 0 auto;
				width: $productPageLoadingIconWidth;
			}

			.loadingLabel {
				margin: $productPageMargin 0 0 0;
				font-size: 20px;
				font-weight: 300;
				text-align: center;
			}
		}

		.productPageError {
			padding: $productPageLoadingPadding 0;

			.errorIcon {
				margin: 0 auto;
				width: $productPageLoadingIconWidth;
			}

			.errorLabel {
				margin: $productPageMargin 0 0 0;
				font-size: 22px;
				font-weight: 400;
				text-align: center;
			}

			.errorCopy {
				margin: $productPageMargin 0 0 0;
				font-size: 18px;
				font-weight: 300;
				text-align: center;
			}

			.errorAction {

				margin: 2*$productPageMargin 0 0 0;
				text-align: center;

				.errorActionButton {
					cursor: pointer;
					border: none;
					background: $productPageAccent;
					color: $productPageAccentText;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					padding: $productPageMargin 2*$productPageMargin;
					transition: background $hoverTransition;

					&:hover {
						background: $productPageAccentHover;
					}
				}
			}
		}

		.productTcg.productPageBody {

			.ppTopSectionWrapper {

				.ppMediaWrapper {
					width: $productMediaTcgWidth;
					margin: 0 0 0 0;

					@media #{$break1200} {
						display: inline-block;
						width: $productMediaTcgWidth;
					}

					@media #{$break1024} {
						display: block;
						width: auto;
						max-width: $productPageMediaMagicMaxWidth;
						margin: 0 auto 2*$productPageMargin auto;
					}
				}

				.ppDetailsTcgWrapper {
					width: calc(100% - #{$productMediaTcgWidth} - #{$productPageInventoryWidth});

					@media #{$break1024} {
						width: calc(100% - #{$productPageInventoryWidth});
					}

					@media #{$break768} {
						width: 100%;
					}
				}

				.ppInventoryWrapper {

					@media #{$break1024} {
						position: relative;
						top: unset;
						right: unset;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}

		.productPageBody {

			.ppTwoColWrapper {

				.pp2TopWrapper {

					.pp2TopColMedia {

						display: inline-block;
						vertical-align: top;
						width: $pp2ColMediaWidth;
						margin: 0;

						@media #{$break1024} {
							display: block;
							width: $pp2ColWidthDefault;
							max-width: $pp2ColMediaWidthMobile;
							margin: 0 auto $MARGIN auto;
						}

						.pp2TitleMobileWrapper {
							display: none;

							@media #{$break1024} {
								display: block;
								margin: 0 0 $MARGIN 0;
							}
						}

						.pp2MediaWrapper {

						}
					}

					.pp2TopColMain {

						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$pp2ColMediaWidth});
						margin: 0;

						@media #{$break1024} {
							display: block;
							width: $pp2ColWidthDefault;
							margin: 0 auto $MARGIN auto;
						}

						.pp2TitleWrapper {
							display: block;
							margin: 0 0 $MARGIN_L $MARGIN_L;

							@media #{$break1024} {
								display: none;
							}
						}

						.pp2InventoryWrapper {
							margin: 0 0 $MARGIN_L $MARGIN_L;
							transition: margin $breakTransition;

							@media #{$break1024} {
								margin: 0 0 $MARGIN 0;
							}
						}

						.pp2DetailsWrapper {
							margin: 0 0 $MARGIN_L $MARGIN_L;
							transition: margin $breakTransition;

							@media #{$break1024} {
								margin: 0 0 $MARGIN 0;
							}
						}
					}
				}
			}

			.ppTopSectionWrapper {
				position: relative;
				margin: 2*$productPageMargin 0 2*$productPageMargin 0;

				.ppMediaWrapper {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$productPageInventoryWidth} - #{$productPageMargin});
					margin: 0 $productPageMargin $productPageMargin 0;
					transition: width $breakTransition;

					@media #{$break768} {
						display: block;
						width: 100%;
						max-width: $productPageMediaMaxWidth;
						margin: 0 auto 2*$productPageMargin auto;
					}
				}

				.ppDetailsWrapper {
					display: inline-block;
					vertical-align: top;
					max-width: calc(100% - #{$productPageInventoryWidth} - #{$productPageMargin});
					transition: width $breakTransition;

					@media #{$break768} {
						max-width: none;
					}
				}

				.ppDetailsGeneralWrapper {
					display: block;
					// width: calc(100% - #{$productMediaDefaultWidth} - #{$productPageInventoryWidth});

					@media #{$break1200} {
						// width: calc(100% - #{$productPageInventoryWidth});
					}

					@media #{$break768} {
						display: block;
						width: auto;
						margin: 0 auto 2*$productPageMargin auto;
					}
				}

				.ppInventoryWrapper {
					position: absolute;
					top: 0;
					right: 0;
					width: $productPageInventoryWidth;

					@media #{$break768} {
						display: block;
				    position: relative;
				    width: 100%;
				    top: unset;
				    right: unset;
						margin: 0 auto 2*$productPageMargin auto;
					}
				}
			}
		}
	}
}


//////////////////////
// ProductPageTitle //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductPageTitle {

	.ppTitleWrapper {

		.ppTitleMain {
			font-size: 32px;
			font-family: $fontLato;
			font-weight: 700;
			transition: font-size $breakTransition;

			@media #{$break600} {
				font-size: 28px;
			}
		}

		.ppSubTitleWrapper {

			.ppTitleProductLine {
				width: auto;
				font-size: 24px;
				font-weight: 400;
				transition: font-size $breakTransition;

				a {
					color: $productPageColorLight;
					transition: color $hoverTransition;

					&:hover {
						color: $productPageAccent;
					}
				}

				@media #{$break600} {
					font-size: 20px;
				}
			}

			.ppTitleSetName {
				margin: 0.5*$productPageMargin 0 0 0;
				font-size: 18px;
				font-weight: 400;
			}
		}
	}
}


//////////////////////
// ProductPageMedia //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$productPageMediaPrimaryMaxHeight: 550px;
$productPageMediaPrimaryMinHeight: 240px;
$productPageMediaThumbWidth: 40px;
$productPageMediaThumbBorderWidth: 2px;


.ProductPageMedia {

	.ppMediaInnerWrapper {

		.ppMediaPrimary {

			text-align: center;

			.ppMediaPrimaryWrapper {

				position: relative;

				img {
					max-width: 100%;
					max-height: 100%;
				}

				.hidden.primaryMediaItemWrapper {
					opacity: 0.0;
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
				}

				.primaryMediaItemWrapper {
					overflow: hidden;
					opacity: 1.0;
					width: 100%;
					min-height: $productPageMediaPrimaryMinHeight;
					max-height: $productPageMediaPrimaryMaxHeight;
					transition: min-height $breakTransition;

					@media #{$break600} {
						min-height: 0;
					}

					img {
						max-width: 100%;
						max-height: $productPageMediaPrimaryMaxHeight;
					}
				}
			}
		}

		.ppMediaThumbs {

			margin: $productPageMargin 0 0 0;
			text-align: center;

			.current.ppThumbWrapper {
				border: $productPageMediaThumbBorderWidth solid $productPageAccent;
			}

			.ppThumbWrapper {
				display: inline-block;
				cursor: pointer;
				vertical-align: top;
				width: $productPageMediaThumbWidth;
				height: $productPageMediaThumbWidth;
				margin: 0 $productPageMargin/2 $productPageMargin $productPageMargin/2;

				border: $productPageMediaThumbBorderWidth solid $productLightOutline;

				img {
					width: $productPageMediaThumbWidth;
					height: $productPageMediaThumbWidth;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}


//////////////////////////
// ProductPageInventory //
//////////////////////////


// Titan colors
$productPageInventoryOptionButtonDisabledColor: $placeholderGrey;

// Theme colors
// None yet

// General variables
$productPageInventoryActionButtonHeight: 40px;
$productPageInventoryActionButtonWidthCorrection: 1.2;
$productPageInventoryBuylistTagHeight: 22px;
$productPageInventoryBuylistTagLineHeight: 16px;
$productPageInventoryOptionButtonHeight: 25px;
$productPageInventoryOptionButtonMinWidth: 40px;
$productPageInventoryQtyInputHeight: 30px;
$productPageInventoryQtyInputWidth: 60px;
$productPageInventoryResetButtonHeight: 20px;


.ProductPageInventory {

	.productPageInventoryWrapper {
		
		background: $productPageBackgroundContrast;
		padding: $productPageMargin;

		.ppiConfigWrapper {
			position: relative;

			.ppConfigResetLink {
				cursor: pointer;
				user-select: none;
				position: absolute;
				top: 0;
				right: 0;
				width: auto;
				height: $productPageInventoryResetButtonHeight;
				line-height: $productPageInventoryResetButtonHeight;
				color: $productPageColor;
				padding: 0 0 0 $productPageMargin;
				transition: color $hoverTransition;

				&:hover {
					color: $productPageAccent;
				}
			}
		}

		.ppiPriceWrapper {

		}

		.ppiQuantityWrapper {

		}

		.ppiNoticeWrapper {

			margin: 2*$productPageMargin 0 0 0;

			.ppiEmHr {
				margin: 0 auto $productPageMargin auto;
				width: 33%;
				height: 2px;
				background: $productPageAccent;
			}

			.ppiNotice.chiefNotice {
				font-weight: 400;
				text-align: center;
			}

			.ppiNotice {
				margin: 0 0 $productPageMargin 0;
				font-size: 14px;
				font-weight: 300;

				.noticeLabel {
					display: inline-block;
					vertical-align: top;
					font-weight: 400;
					margin: 0 $productPageMargin/2 0 0;

					&:after {
						content: ':';
					}
				}

				.noticeValue {
					display: inline-block;
					vertical-align: top;
				}
			}
		}

		.ppiActionWrapper {

			margin: 3*$productPageMargin 0 0 0;

			.ppiActionWorkingWrapper {

				position: relative;
				width: 100%;
				height: $productPageInventoryActionButtonHeight;
				background: $productPageAccentHover;

				.ppiActionWorkingWrapperIconWrapper {

					position: absolute;
					top: $productPageMargin/2;
					bottom: $productPageMargin/2;
					left: calc(50% - #{$productPageInventoryActionButtonHeight - $productPageInventoryActionButtonWidthCorrection*$productPageMargin}/2);
					width: $productPageInventoryActionButtonHeight - $productPageInventoryActionButtonWidthCorrection*$productPageMargin;

					svg {
						fill: $productPageAccentText;
					}
				}
			}

			button.ppiAddToCart {
				cursor: pointer;
				width: 100%;
				height: $productPageInventoryActionButtonHeight;
				line-height: $productPageInventoryActionButtonHeight;
				color: $productPageAccentText;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				border: none;
				background: $productPageAccent;
				transition: background $hoverTransition;

				&:hover {
					background: $productPageAccentHover;
				}
			}
		}
	}

	.ppBackWrapper {

		margin: $productPageMargin 0 0 0;
		text-align: center;

		.ppBack {
			display: inline-block;
			vertical-align: top;
			color: $productPageAccent;
			background: $productPageBackground;
			border: none;
			font-weight: 700;
			font-size: 16px;
			text-transform: uppercase;
			cursor: pointer;
			user-select: none;
			padding: $productPageMargin/2 $productPageMargin;
			transition: color $hoverTransition, background $hoverTransition;

			&:before {
				content: '< ';
			}

			&:hover {
				color: $productPageAccentHover;
				background: $productPageBackgroundHover;
			}
		}
	}
}


////////////////////////////////
// ProductPageInventoryConfig //
////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductPageInventoryConfig {

	margin: 0 0 2*$productPageMargin 0;

	.ppiConfigElementWrapper {

		.ppiConfigLabel {
			font-size: 16px;
			font-weight: 700;

			&:after {
				content: ':';
			}
		}

		.ppiConfigSelectable {

			.ppiConfigInlineLabel {

				.ppiConfigLabel {
					display: inline-block;
					vertical-align: text-bottom;
					width: auto;
					margin: 0 $productPageMargin 0 0;
				}

				.ppiSelectedValue {
					display: inline-block;
					vertical-align: text-bottom;
					width: auto;

					font-size: 16px;
					font-weight: 300;
				}
			}

			.ppiConfigSelectWrapper.ppiConfigOptionSelect {
				padding-bottom: 0;	
			}

			.ppiConfigSelectWrapper {

				margin: $productPageMargin 0 0 0;
				padding: $productPageMargin;
				background: $productPageBackground;
				text-align: center;

				.selected.optionButton {
					cursor: default;
					color: $productPageAccentText;
					border: 1px solid $productPageAccent;
					background: $productPageAccent;
				}

				.disabled.optionButton {
					cursor: default;
					color: $productPageInventoryOptionButtonDisabledColor;
					border: 1px solid $productPageInventoryOptionButtonDisabledColor;
				}

				.optionButton {
					cursor: pointer;
					user-select: none;
					margin: 0 $productPageMargin/2 $productPageMargin $productPageMargin/2;
					padding: 0 $productPageMargin/2;
					min-width: $productPageInventoryOptionButtonMinWidth;
					height: $productPageInventoryOptionButtonHeight;
					
					color: $productPageColor;
					border: 1px solid $productPageColor;
					background: $productPageBackground;

					font-size: 14px;
					font-weight: 700;
				}

				.ppiConfigSelect {
					border: none;
				}
			}
		}

		.ppiConfigStatic {

			.ppiConfigLabel {
				display: inline-block;
				vertical-align: text-bottom;
				width: auto;
				margin: 0 $productPageMargin 0 0;
			}

			.ppiStaticValue {
				display: inline-block;
				vertical-align: text-bottom;
				width: auto;

				font-size: 16px;
				font-weight: 300;
			}
		}
	}
}


///////////////////////////////
// ProductPageInventoryPrice //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductPageInventoryPrice.readOnly {
	margin: $productPageMargin 0 0 0;
}

.ProductPageInventoryPrice {

	margin: 3*$productPageMargin 0 0 0;
	font-size: 36px;
	text-align: center;

	.ppiTagWrapper {

		height: $productPageInventoryBuylistTagHeight;
		margin: 0 0 0 0;

		.ppiTag {

			display: inline-block;
			vertical-align: top;
			background: $productPageAccent;
			color: $productPageAccentText;
			height: $productPageInventoryBuylistTagHeight;
			border-radius: $productPageInventoryBuylistTagHeight/2;

			.ppiTagLiner {
				padding: ($productPageInventoryBuylistTagHeight - $productPageInventoryBuylistTagLineHeight)/2 $productPageInventoryBuylistTagLineHeight;
				font-size: $productPageInventoryBuylistTagLineHeight;
				line-height: $productPageInventoryBuylistTagLineHeight;
			}
		}
	}

	.ppiPriceBlockWrapper {

		.ppiPriceSymbol {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			font-size: 32px;
			font-weight: 300;
		}

		.ppiPriceValue {
			display: inline-block;
			vertical-align: middle;
			width: auto;
		}

		.ppiOutOfStock {
			font-size: 20px;
		}
	}
}


//////////////////////////////////
// ProductPageInventoryQuantity //
//////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductPageInventoryQuantity {

	margin: $productPageMargin 0 0 0;

	.productPageInventoryQuantityWrapper {

		.ppiQuantityInput {

			text-align: center;

			.incPlus.ppiIncWrapper {
				border-radius: 0 50% 50% 0;
			}

			.incMinus.ppiIncWrapper {
				border-radius: 50% 0 0 50%;
			}

			.disabledInc.ppiIncWrapper {
				cursor: default;
				color: $productPageInventoryOptionButtonDisabledColor;

				&:hover {
					color: $productPageInventoryOptionButtonDisabledColor;
				}
			}

			.ppiIncWrapper {
				position: relative;
				display: inline-block;
				cursor: pointer;
				user-select: none;
				vertical-align: top;
				width: $productPageInventoryQtyInputHeight;
				height: $productPageInventoryQtyInputHeight;
				background: $productPageBackground;
				font-size: 24px;
				font-weight: 400;
				color: $productPageColor;
				transition: color $hoverTransition;

				&:hover {
					color: $productPageAccent;
				}
			}

			.ppiInput {
				display: inline-block;
				vertical-align: top;
				width: $productPageInventoryQtyInputWidth;
				height: $productPageInventoryQtyInputHeight;

				input {
					width: calc(100% - 2px);
					height: calc(#{$productPageInventoryQtyInputHeight} - 2px);
					border: 1px solid white;
					text-align: center;
					font-size: 24px;
				}
			}
		}

		.ppiQuantityLimit {
			font-size: 14px;
			font-weight: 300;
			text-align: center;
			margin: $productPageMargin/2 0 0 0;
		}

		.ppiCartQuantity {
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			margin: $productPageMargin/4 0 0 0;
		}
	}
}
























































