

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;



////////////////////
// HeaderShopMenu //
////////////////////


// Titan colors
// None yet

// Theme colors
$menuFooterColor: $THEME_PRIMARY_COLOR;

// General variables
$menuWidth: 300px;
$menuMaxHeight: 800px;
$menuFooterHeight: 5px;


.HeaderShopMenu {

	position: absolute;
	top: $headerHeight;
	left: 0;
	width: 100%;
	max-width: $menuWidth;
	height: auto;
	background: $headerBackground;
	z-index: $zUserMenuLayer;
	overflow: hidden;
	
	transition: top $breakTransition;

	@media #{$break600} {
		top: $headerHeightMobile;
	}	

	.outerWrapper {

		overflow: hidden;
		max-height: 0;
		// transition: max-height $slideFastTransition;
		// transition: max-height 10s linear 0s;

		&.open {
			max-height: $menuMaxHeight;
			transition: max-height $slideTransition;
			// transition: max-height 10s linear 0s;

			.innerWrapper {
				padding: $MARGIN $MARGIN_L 0 $MARGIN_L;
			}
		}

		.innerWrapper {
			padding: 0;
		}

		.menuTitleWrapper {
			padding: 0.5*$headerMargin 1.5*$headerMargin;
			background: $headerMenuDropdownTitleBackground;
			margin: 0;
			font-weight: 700;
			font-size: 20px;
			user-select: none;
			text-transform: uppercase;

			.menuTitle {
				display: inline-block;
				vertical-align: top;
				width: auto;
			}
		}

		.menuFooter {
			height: $menuFooterHeight;
			background: $menuFooterColor;
		}
	}
}













