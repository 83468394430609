

/* BREAKS */

$break4000: "(max-width : 4000px)";
$break2400: "(max-width : 2400px)";
$break1800: "(max-width : 1800px)";
$break1500: "(max-width : 1500px)";
$break1320: "(max-width : 1320px)";
$break1200: "(max-width : 1200px)";
$break1024: "(max-width : 1024px)";
$break900: "(max-width : 900px)";
$break768: "(max-width : 768px)";
$break600: "(max-width : 600px)";
$break480: "(max-width : 480px)";
$break360: "(max-width : 360px)";


/* TRANSITIONS */

$breakTransition: 350ms linear 0ms;
$hoverTransition: 250ms ease-in-out 0ms;
$imageTransition: 1000ms ease-in-out 0ms;
$flipFrontTransition: 500ms linear 0ms;
$flipBackTransition: 300ms linear 0ms;
$mouseFollowTransition: 25ms linear 0ms;
$slideTransition: 400ms ease-in-out 0ms;
$slideFastTransition: 200ms ease-in-out 0ms;
$toggleTransition: 100ms ease-in-out 0ms;


/* PAGE FIXED WIDTH HELPERS */

$fixedWidth: 90%;
$fixedMaxWidth: 1200px;



/* LAYERS */
/* Anything set not using constants should be below 100 */

/* General (any) */
$zLayerMax: 2147483647;

/* Alerts (1,000,000 - 2,000,000) */
$zAlert: 1000000;

/* Header (100,000 - 200,000) */
$zHeaderLayer: 100000;

/* Elements (20,000 - 90,000) */
$zCartLayer: 20000;
$zUserMenuLayer: 90000;

/* Screens (10,000 - 5,000) */
$zMenuScreen: 10000;

/* Content components */
$zMenuCoverContent: 1000;

