

@use '../../../../style/import' as *;




////////////////////////////
// MaintenanceOasisLaunch //
////////////////////////////


// Titan colors
// None yet

// Theme colors
$maintenanceOasisLaunchBorderColor: #949494;
$maintenanceOasisLaunchBoxBackground: #000000;
$maintenanceOasisLaunchLinkColor: #E9C11E;
$maintenanceOasisLaunchTextColor: #FFFFFF;

// General variables
$maintenanceOasisLaunchBoxWidth: 600px;
$maintenanceOasisLaunchMargin: 10px;


.MaintenanceOasisLaunch {

	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-image: url('../custom/oasis/img/launch/bg.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@media #{$break768} {
		background-image: url('../custom/oasis/img/launch/bg_m.jpg');
	}

	.mainLiner {

		position: absolute;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		.mainBoxWrapper {

			width: 90%;
			max-width: $maintenanceOasisLaunchBoxWidth;
			background: rgba($maintenanceOasisLaunchBoxBackground, 0.4);

			.mainBox {

				margin: $maintenanceOasisLaunchMargin;
				border: 1px solid $maintenanceOasisLaunchBorderColor;
				padding: 8*$maintenanceOasisLaunchMargin;

				@media #{$break768} {
					padding: 4*$maintenanceOasisLaunchMargin;
				}

				.mainBoxLiner {

					.mainTitleWrapper {

						margin: 0 0 2*$maintenanceOasisLaunchMargin 0;

						.mainTitle {

							text-align: center;

							.titleImg {
								width: 100%;
								display: inline-block;

								@media #{$break768} {
									display: none;
								}
							}

							.titleImgMobile {
								width: 70%;
								display: none;

								@media #{$break768} {
									display: inline-block;
								}
							}
						}
					}

					.mainCopyWrapper {

						.mainP {

							color: $maintenanceOasisLaunchTextColor;
							font-size: 18px;
							font-weight: 400;
							margin: 0 0 2*$maintenanceOasisLaunchMargin 0;

							.mainEm {
								font-weight: 700;
							}

							.mainLink {
								cursor: pointer;
								color: $maintenanceOasisLaunchLinkColor;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
}

