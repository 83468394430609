

@use '../../../../../style/import' as *;




//////////////////////////////
// ProductPageDetailsYuGiOh //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
$backgroundColor: $THEME_BACKGROUND;

// General variables
// None yet


.ProductPageDetailsYuGiOh {

	.detailsWrapper {
	
		@media #{$break768} {
			margin: 0 0 $MARGIN 0;
		}

		.yugiohWrapper {

			padding: $MARGIN;
			transition: padding $breakTransition;

			@media #{$break768} {
				padding: 0 0 $MARGIN 0;
			}

			.faceWrapper {

				.titleWrapper {

					font-size: 22px;
					font-weight: 700;
					font-family: $fontLato;

					.titleText {

					}
				}

				.typeLine {
					font-size: 16px;
					font-weight: 400;
					margin: 0 0 $MARGIN 0;
				}

				.yugiohDetails {

					background: $backgroundColor;
					padding: $MARGIN;

					.yugiohDesc {
						
					}

					.yugiohSecondaryDesc {
						font-style: italic;
						font-weight: 300;
					}	
				}
			}
		}
	}
}












