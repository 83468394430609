

@use '../../../../style/import' as *;


////////////////
// AddProduct //
////////////////


// Titan colors
$addProductAccent: $TITAN_PRIMARY_COLOR;
$addProductAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$addProductAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$addProductBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$addProductColor: $TITAN_TEXT_COLOR;
$addProductDropdownBackgroundLoading: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$addProductMargin: 10px;
$addProductSeparation: 25px;
$addProductSeparationSmaller: 10px;
$addProductSpacing: 10px;
$addProductSearchWidth: 45px;

$addProductSectionMaxWidth: 825px;  /* 825 for smooth transition from left to right alignment */

$addProductSidebarWidth: 300px;
$addProductSidebarWidthSmaller: 240px;

$addProductDropdownHeight: 45px;


.AddProduct {

	color: $addProductColor;

	.aiTitleWrapper {
		
	}

	.aiBreadcrumbWrapper {

		margin: $addProductSeparation $addProductSeparation $addProductSeparation 0;
		width: $addProductSectionMaxWidth;
		transition: margin $breakTransition;

		@media #{$break1500} {
			width: calc(100% - #{$addProductSeparation} - #{$addProductSidebarWidth});	
		}

		@media #{$break1024} {
			transition: width $breakTransition;
		}

		@media #{$break900} {
			width: calc(100% - #{$addProductSeparationSmaller} - #{$addProductSidebarWidthSmaller});
			margin-bottom: $addProductSeparationSmaller;
		}

		@media #{$break768} {
			width: 100%;
		}
	}

	.adminBody {

		position: relative;

		.adminSection {
			/*max-width: none;*/
		}

		.addInventoryWrapper {

			position: relative;
			margin: 0 0 $addProductSeparation 0;

			.aiFormWrapper {

				background: $addProductBackground;
				width: $addProductSectionMaxWidth;
				transition: width none;  /* No transition to move in tandem with sidebar */

				/* Estimate based on empty height of product sidebar; is managed in JS */
				min-height: 300px;

				@media #{$break1500} {
					width: calc(100% - #{$addProductSeparation} - #{$addProductSidebarWidth});		
				}

				@media #{$break1024} {
					transition: width $breakTransition;
				}

				@media #{$break900} {
					width: calc(100% - #{$addProductSeparationSmaller} - #{$addProductSidebarWidthSmaller});
				}

				@media #{$break768} {
					width: 100%;
				}

				.aiNotices {

					.aiNotice.aiNoticeVisible {
						display: block;
					}

					.aiNotice.aiNoticeOr {
						font-size: 24px;
						font-weight: 100;
					}

					.aiNotice {
						display: none;

						font-size: 28px;
						font-weight: 300;
						margin: 0 0 2*$addProductSpacing 0;
						text-align: center;
					}

					.aiNoticeButton.aiNoticeButtonSecondary {
						border: 2px solid $addProductAccent;
						color: $addProductAccent;
						background: none;

						&:hover {
							color: $addProductBackground;
							background: $addProductAccent;
						}
					}

					.aiNoticeButton {

						color: $addProductBackground;
						background: $addProductAccent;
						border: none;
						cursor: pointer;
						margin: 0 0 0 $addProductSpacing;
						padding: $addProductSpacing 4*$addProductSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

						@media #{$break1024} {
							padding: 2*$addProductSpacing/2 3*$addProductSpacing;
						}

						&:hover {
							background: $addProductAccentHover;
						}
					}
				}

				.adminForm {
					
					margin-bottom: 0;
					max-width: $addProductSectionMaxWidth;

					.adminFieldWrapper {

						.adminInputWrapper.searchWrapper {

							position: relative;

							.searchIcon {
								position: absolute;
								top: 1px;
								right: 1px;
								width: $addProductSearchWidth;
								height: calc(100% - 2px);

								cursor: pointer;
								background: $addProductAccent;
								transition: background $hoverTransition;

								&:hover {
									background: $addProductAccentHover;
								}

								.searchIconWrapper {
									position: absolute;
									top: $addProductSpacing;
									left: $addProductSpacing;
									right: $addProductSpacing;
									bottom: $addProductSpacing;

									svg {
										fill: $addProductAccentText;
									}
								}
							}
						}

						.adminInputWrapper {

							.adminInputProductLineWrapper, .adminDropdown {

								position: relative;

								.aiDropdownArrowWrapper {
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									width: $addProductDropdownHeight - $addProductSpacing;

									pointer-events: none;
									background: none;

									.aiArrow {
										position: absolute;
										top: 1.5*$addProductSpacing;
										left: 0;
										width: 0;
										height: 0;

										border-top: (1.5 * 0.25 * $addProductDropdownHeight) solid $addProductColor;
										border-bottom: none;
										border-left: (0.25 * $addProductDropdownHeight) solid transparent;
										border-right: (0.25 * $addProductDropdownHeight) solid transparent;
									}
								}

								.aiProductLineLoading {

									overflow: auto;
									background: $addProductDropdownBackgroundLoading;

									.aiProductLineLoadingIcon {
										position: absolute;
										top: $addProductSpacing;
										left: $addProductSpacing;
										width: $addProductDropdownHeight - 2*$addProductSpacing;
										height: $addProductDropdownHeight - 2*$addProductSpacing;

										svg {
											fill: $addProductAccent;
										}
									}

									.aiProductLoadingLabel {
										color: $addProductAccent;
										height: $addProductDropdownHeight;
										line-height: $addProductDropdownHeight;
										margin: 0 $addProductSpacing 0 $addProductDropdownHeight;
										text-transform: uppercase;
										font-size: 14px;
										font-weight: 700;
									}
								}

								.aiProductLineEmpty {

									select {
										border-color: $disabledColor;
										color: $disabledColor;
									}

									.aiDropdownArrowWrapper {
										.aiArrow {
											border-top-color: $disabledColor;
										}
									}
								}

								.aiProductLineDropdown {

									position: relative;

									select {
										height: $addProductDropdownHeight;
									}
								}
							}
						}
					}
				}
			}	
		}	

		.aiSidebarWrapper {

			position: absolute;
			top: 0;
			left: $addProductSectionMaxWidth + $addProductSeparation;
			width: $addProductSidebarWidth;
			height: auto;
			background: $addProductBackground;
			transition: width $breakTransition;

			@media #{$break1500} {
				left: unset;
				right: 0;
			}

			@media #{$break900} {
				width: $addProductSidebarWidthSmaller;
			}

			@media #{$break768} {

				/* TODO: revisit if we want preview view on mobile; should be a banner at top underneath */
				display: none;

				position: relative;
				width: 100%;

				min-height: 50px;

				height: auto;
				margin: 0 0 $addProductSeparation 0;
			}
		}
	}
}


///////////////////////
// AddProductSidebar //
///////////////////////


// Titan colors
$addProductSidebarColor: $TITAN_TEXT_COLOR;
$addProductSidebarColorLight: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
$addProductSidebarSpacing: 10px;
$addProductSidebarImageMaxHeight: $addProductSidebarWidth - 2*$addProductSidebarSpacing;


.AddProductSidebar {

	color: $addProductSidebarColor;

	.sidebarImage {

		.genericProduct {

			margin: $addProductSidebarSpacing;
			text-align: center;

			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
				max-height: $addProductSidebarImageMaxHeight;
			}
		}

		.foreignProduct {

			margin: $addProductSidebarSpacing;
			text-align: center;

			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
				// max-height: $addProductSidebarImageMaxHeight;
			}
		}
	}

	.sbTitleRow {
		margin: $addProductSidebarSpacing/2 $addProductSidebarSpacing;
		font-size: 20px;
		font-weight: 700;

		.titleElement0 {
			display: inline;
			margin: 0 $addProductSidebarSpacing/2 0 0;
		}

		.titleElement1 {
			display: inline;
			font-size: 18px;
			font-weight: 400;
		}
	}

	.sbSubtitleRow {
		margin: 0 $addProductSidebarSpacing $addProductSidebarSpacing $addProductSidebarSpacing;
		font-size: 16px;

		.skuElement {
			text-align: left;
			font-size: 14px;
			font-weight: 300;
			color: $addProductSidebarColorLight;
		}

		
	}

	.conditionsWrapper {

		margin: 0 $addProductSidebarSpacing $addProductSidebarSpacing $addProductSidebarSpacing;

		.condRow {
			position: relative;

			.conditionElem {
				width: 50%;
			}

			.qtyElem {
				width: 80px;
				overflow: visible;
			}

			.priceElem {
				position: absolute;
				top: 0;
				right: 0;
				width: auto;
				height: auto;
			}
		}

		.sbConditionsHeader {
			margin: 0 0 $addProductSidebarSpacing/2 0;

			.headerElem {
				display: inline-block;
				vertical-align: top;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				user-select: none;
			}
		}

		.sbPriceRow {



			.bodyElem {
				display: inline-block;
				vertical-align: top;
				user-select: none;
			}

			.conditionElem {
				font-size: 14px;
				font-weight: 400;
				padding: 2px 0 0 0;
			}

			.qtyElem {
				font-size: 16px;
				font-weight: 300;
				max-width: 80px;
				overflow: hidden;
			}

			.priceElem {
				text-align: right;

				.currencyMark {
					display: inline-block;
					vertical-align: middle;
					font-size: 16px;
					font-weight: 300;
				}

				.priceValue {
					display: inline-block;
					vertical-align: middle;
					font-size: 16px;
				}
			}
		}
	}

	.actionsWrapper {
		margin: 0 $addProductSidebarSpacing $addProductSidebarSpacing $addProductSidebarSpacing;
		text-align: right;

		.sbAction {
			display: inline-block;
			color: $addProductAccentText;
			background: $addProductAccent;
			vertical-align: top;
			cursor: pointer;
			border: none;
			margin: 0 0 0 $addProductSidebarSpacing;
			padding: $addProductSidebarSpacing 4*$addProductSidebarSpacing;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

			@media #{$break1024} {
				padding: 2*$addProductSidebarSpacing/2 3*$addProductSidebarSpacing;
			}

			&:hover {
				background: $addProductAccentHover;
			}

			&:disabled {
				cursor: default;
			}
		}
	}
}


/////////////////////
// AddProductStep1 //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddProductStep1 {
	.adminForm {
		overflow: visible !important;
	}
}


////////////////////////////
// AddProductMediaGeneric //
////////////////////////////


// Titan colors
$addProductFileDropzoneBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$addProductFileDropzoneBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$addProductFileDropzoneBorder: $TITAN_ADMIN_PAGE_BACKGROUND;
$addProductFileDropzoneBorderHover: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
// None yet


.AddProductMediaGeneric {



	.imagesPreviewWrapper {
		
	}

	.fileDropzoneWrapper {

		.fileError {

		}

		.dropWrapper.hoverFile {
			background: $addProductFileDropzoneBackgroundHover;
			border: 1px solid $addProductFileDropzoneBorderHover;
		}

		.dropWrapper {
			margin: $addProductSeparation 0 0 0;
			background: $addProductFileDropzoneBackground;
			border: 1px solid $addProductFileDropzoneBorder;
			padding: 3*$addProductSpacing $addProductSpacing;
			text-align: center;
			transition: background $hoverTransition, border $hoverTransition;

			.dropPrompt {
				font-size: 28px;
				font-weight: 300;
				margin: 0 0 2*$addProductSpacing 0;
			}

			.dropError {
				text-align: center;
				color: $errorRed;
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 $addProductSeparation 0;
			}

			.dropButton {
				display: inline-block;
				color: $addProductAccentText;
				background: $addProductAccent;
				vertical-align: top;
				cursor: pointer;
				border: none;
				margin: 0 0 0 $addProductSidebarSpacing;
				padding: $addProductSidebarSpacing 4*$addProductSidebarSpacing;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

				@media #{$break1024} {
					padding: 2*$addProductSidebarSpacing/2 3*$addProductSidebarSpacing;
				}

				&:hover {
					background: $addProductAccentHover;
				}
			}
		}
	}
}


///////////////////////////////////
// AddProductDetailsSyncedSearch //
///////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddProductDetailsSyncedSearch {

	.adminInputAutocomplete {

		.autocompleteNoResultsWrapper {

			padding: $addProductSpacing;
			text-align: center;

			.noResultsCopy {
				margin: 0 0 $addProductSpacing 0;
			}

			.noResultsNeedle {
				margin: 0 0 $addProductSpacing 0;
				font-size: 18px;
				font-weight: 700;
			}
		}
	}

	.aiNotices {

		margin: 0 0 $addProductSeparation 0;

		.detailsSearchButtonWrapper {
			text-align: center;
		}

		.noResultsLastSearch {
			font-size: 24px;
			font-weight: 400;
			text-align: center;
			margin: 0 0 $addProductSeparation 0;
		}
	}

	.ssResultsWrapper {

		.ssResults {

			.ssResultsHeader {

			}

			.ssResultsBody {
				
			}
		}
	}
}


//////////////////////////////
// AddProductDetailsGeneric //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddProductDetailsGeneric {

	.adminFieldWrapper {

		.selectedSet {

			.selectedSetLiner {

				.setName {
					display: inline-block;
					vertical-align: baseline;
					font-size: 20px;
					font-weight: 300;
				}
				
				.setClear {
					display: inline-block;
					vertical-align: baseline;
					margin: 0 0 0 $addProductMargin;
					font-size: 14px;
					color: $addProductAccent;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $addProductAccentHover;
					}
				}
			}
		}
	}
}




