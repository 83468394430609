

@use '../../../../style/import' as *;


////////////
// Header //
////////////


// Titan colors
// None yet

// Theme colors
$headerColor: $THEME_HEADER_COLOR;
$headerColorHover: $THEME_HEADER_COLOR_HOVER;
$headerAccentColor: $THEME_PRIMARY_COLOR;
$headerAccentColorHover: $THEME_PRIMARY_COLOR_HOVER;
$headerAccentColorText: $THEME_PRIMARY_COLOR_TEXT;
$headerBackground: $THEME_HEADER_BACKGROUND;
$headerBackgroundHover: $THEME_HEADER_BACKGROUND_HOVER;


// General variables
$headerHeight: $TITAN_HEADER_HEIGHT;
$headerHeightMobile: $TITAN_HEADER_HEIGHT_MOBILE;
$headerNoticeHeight: $TITAN_HEADER_NOTICE_HEIGHT;
$headerNoticeHeightMobile: $TITAN_HEADER_NOTICE_HEIGHT_MOBILE;

$headerSpacing: 25px;
$headerSpacingMobile: 10px;



.notified.Header {

	.headerSpacer {
		padding-bottom: $headerHeight + $headerNoticeHeight;
		
		@media #{$break900} {
			padding-bottom: $headerHeight + $headerNoticeHeightMobile;
		}

		@media #{$break600} {
			padding-bottom: $headerHeightMobile + $headerNoticeHeightMobile;
		}
	}

	.headerFloatWrapper {

		.headerNotice {
			height: $headerNoticeHeight;

			@media #{$break900} {
				height: $headerNoticeHeightMobile;
			}
		}
	}
}


.Header {
	
	.headerSpacer {
		position: relative;
		padding-bottom: $headerHeight;
		transition: padding $breakTransition;

		@media #{$break600} {
			padding-bottom: $headerHeightMobile;
		}
	}

	.headerFloatWrapper {

		position: fixed;
		top: 0;
		width: 100%;
		height: $headerHeight;
		z-index: $zHeaderLayer;
		background: $headerBackground;
		transition: height $breakTransition;

		@media #{$break600} {
			height: $headerHeightMobile;
		}

		.headerNotice {

			position: relative;
			height: 0;
			overflow: hidden;
			color: $headerAccentColorText;
			background: $headerAccentColor;
			z-index: $zHeaderLayer;
			transition: height $breakTransition;

			.headerNoticeLink {
				color: $headerAccentColorText;
				cursor: pointer;
			}

			.headerNoticeLiner {

				position: absolute;
				top: 0;
				left: 0;
				right: 2*$headerSpacing;
				bottom: 0;

				padding-left: 2*$headerSpacing;
				overflow: hidden;
				font-size: 14px;
				font-weight: 400;
				text-align: center;
				line-height: 16px;
				transition: font-size $breakTransition, line-height $breakTransition;

				@media #{$break600} {
					font-size: 12px;
					line-height: 0.333 * $headerNoticeHeightMobile;
				}

				.headerNoticeValue {
					left: 2*$headerSpacing;
					user-select: none;
				}
			}

			.headerCloseWrapper {

				position: absolute;
				top: 0;
				right: 0;
				width: 2*$headerSpacing;
				height: 100%;

				.headerClose {
					font-size: 24px;
					font-weight: 300;
					cursor: pointer;
					transition: font-weight $hoverTransition;

					&:hover {
						font-weight: 400;
					}
				}
			}
		}

		.headerWrapper {

			position: relative;
			width: 100%;
			height: $headerHeight;
			z-index: $zHeaderLayer;
			background: $headerBackground;
			transition: height $breakTransition;

			@media #{$break600} {
				height: $headerHeightMobile;
			}
		}
	}
}

















