

@use '../../../../style/import' as *;

/* Theme variables */

$iconColor: $THEME_TEXT_COLOR;
/*$iconColorHover: $THEME_TEXT_COLOR_HOVER;*/
/*$iconAccentColor: $THEME_PRIMARY_COLOR;*/
/*$iconAccentColorHover: $THEME_PRIMARY_COLOR_HOVER;*/


/* Constants */

/*$headerHeight: 80px;
$headerHeightMobile: 40px;*/


@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(210deg);
  }
    
  50% {
    transform: rotate(180deg);
  }

  80% {
  	transform: rotate(390deg);
  }

  100% {
  	transform: rotate(360deg);
  }
}


.TitanIcon {

	.iconSVGWrapper {

		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;

		svg {
			fill: $iconColor;
			margin: auto;
			width: 100%;
	    height: 100%;
	    object-fit: cover;
	    overflow: hidden;
			max-width: 100%;
			max-height: 100%;
			transition: fill $hoverTransition;
		}
	}

	.iconLabel {
		text-align: center;
		font-weight: 300;
		text-transform: uppercase;
		margin: 3px 0 0 0;
	}
}

.LoadingIcon {

	.iconSVGWrapper {
		animation: 2s ease infinite loader;
	}
}

.IconSearch {

}
