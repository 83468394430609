

@use '../../../../../style/import' as *;




/////////////////////////////
// ProductPageDetailsMagic //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
$backgroundColor: $THEME_BACKGROUND;

// General variables
// None yet


.ProductPageDetailsMagic {

	.detailsWrapper {
	
		@media #{$break768} {
			margin: 0 0 $MARGIN 0;
		}

		.magicWrapper {

			padding: $MARGIN;
			transition: padding $breakTransition;

			@media #{$break768} {
				padding: 0 0 $MARGIN 0;
			}

			.faceWrapper {

				&.face2 {
					margin: $MARGIN_L 0 0 0;
					padding: $MARGIN_L 0 0 0;
				}

				.titleWrapper {

					font-size: 22px;
					font-weight: 700;
					font-family: $fontLato;

					&.faceTwoTitleWrapper {

					}

					.titleText {

					}

					.titleCost {
						display: inline-block;
						vertical-align: top;
						width: auto;
						font-size: 19px;
						margin: 0 0 0 $MARGIN;
					}
				}

				.typeLine {
					font-size: 16px;
					font-weight: 400;
					margin: 0 0 $MARGIN 0;
				}

				.magicDetails {

					background: $backgroundColor;
					padding: $MARGIN;

					.magicDesc {

					}

					.magicSecondaryDesc {
						font-style: italic;
						font-weight: 300;
					}	
				}
			}
		}
	}
}

