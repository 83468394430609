

@use '../../../../style/import' as *;




/////////////////////////
// BlockEventsUpcoming //
/////////////////////////


// Titan colors
// None yet

// Theme colors
$blockEventAccent: $THEME_PRIMARY_COLOR;
$blockEventBackground: $THEME_BACKGROUND;
$blockEventBackgroundHover: $THEME_BACKGROUND_HOVER;

// General variables
$blockEventTimeWidth: 40px;


.BlockEventsUpcoming {

	.beuLiner {
		
		margin: $MARGIN;

		.dayWrapper {

			margin:  $MARGIN_S 0 $MARGIN 0;

			.dateLabelWrapper {
				text-align: center;
				font-size: 16px;

				&.today {
					font-weight: 700;
				}

				.dateLabel {
					display: inline-block;
					vertical-align: top;
					margin: $MARGIN_S $MARGIN 0.25*$MARGIN 0;
					border-bottom: 1px solid $blockEventAccent;
				}
			}

			.eventWrapper {

				display: block;
				
				padding: $MARGIN_S;
				background: $blockEventBackground;
				font-size: 14px;
				transition: background $hoverTransition;

				&:hover {
					background: $blockEventBackgroundHover;
				}

				.eventTime {
					display: inline-block;
					vertical-align: top;
					width: $blockEventTimeWidth;
					margin: 0 $MARGIN 0 0;
					font-weight: 300;
				}

				.eventLabel {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$blockEventTimeWidth} - #{$MARGIN});
					font-weight: 400;
				}
			}
		}

		.noDaysRendered {
			margin: 5*$MARGIN 0 0 0;
			font-size: 24px;
			font-weight: 300;
			text-align: center;
		}
	}
}



















