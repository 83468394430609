

@use '../../../../../style/import' as *;




///////////////////////////////
// ProductPageDetailsPokemon //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
$backgroundColor: $THEME_BACKGROUND;
$primaryColor: $THEME_PRIMARY_COLOR;

// General variables
// None yet


.ProductPageDetailsPokemon {

	.detailsWrapper {
	
		@media #{$break768} {
			margin: 0 0 $MARGIN 0;
		}

		.pokemonWrapper {

			padding: $MARGIN;
			transition: padding $breakTransition;

			@media #{$break768} {
				padding: 0 0 $MARGIN 0;
			}

			.faceWrapper {

				.titleWrapper {

					font-size: 22px;
					font-weight: 700;
					font-family: $fontLato;

					.titleText {

					}
				}

				.typeLine {
					font-size: 16px;
					font-weight: 400;
					margin: 0 0 $MARGIN 0;
				}

				.attributeBlock {

					margin: 0 0 $MARGIN 0;

					.attrTitleWrapper {

						margin: 0 0 $MARGIN_S 0;

						.attrTitleValue {
							display: inline-block;
							vertical-align: top;
							border-bottom: 1px solid $primaryColor;
						}
					}

					.attackWrapper {

						margin: 0 0 $MARGIN_S 0;

						.attackTitle {

							position: relative;

							.titleValue {
								font-size: 18px;
								font-weight: 400;
							}

							.titleDamage {
								position: absolute;
								top: 0;
								bottom: 0;
								right: 0;
								width: auto;
							}
						}

						.attackText {
							font-size: 15px;
							font-weight: 300;
						}
					}
				}

				.pokemonDetails {

					background: $backgroundColor;
					padding: $MARGIN;

					.pokemonDesc {
						margin: $MARGIN 0 0 0;

						&:first-child {
							margin: 0;
						}
					}

					.pokemonSecondaryDesc {
						font-style: italic;
						font-weight: 300;
					}	
				}
			}

			.collectorNumberWrapper {

				margin: $MARGIN 0 0 0;

				.collectorNumber {

					text-align: left;
					font-size: 18px;

					.numberLabel {
						display: inline-block;
						vertical-align: baseline;
						width: auto;
						margin: 0 $MARGIN 0 0;
						font-weight: 400;

						&:after {
							content: ':';
						}
					}

					.numberValue {
						display: inline-block;
						vertical-align: baseline;
						width: auto;
						font-weight: 300;
					}
				}
			}
		}
	}
}











