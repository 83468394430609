

@use '../../../../style/import' as *;


$savePromptScreenColor: $TITAN_SCREEN_COLOR;
$savePromptScreenOpacity: $TITAN_SCREEN_OPACITY;

$savePromptAccent: $TITAN_PRIMARY_COLOR;
$savePromptAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$savePromptAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$savePromptBackground: $TITAN_BACKGROUND;
$savePromptBackgroundHover: $TITAN_BACKGROUND_HOVER;
$savePromptColor: $TITAN_TEXT_COLOR;
$savePromptColorHover: $TITAN_TEXT_COLOR_HOVER;

$savePromptMargin: 10px;
$savePromptSpacing: 15px;
$savePromptSpacingMobile: 8px;
$savePromptWidth: 600px;


.SavePrompt, 
.DeletePrompt, 
.SetPasswordModal, 
.BulkUploadDownloadResultsModal,
.AdminTableBulkActionModal,
.OrderControlModal,
.GeneralModal {

	display: none;

	.spScreen.withOpacity {
		opacity: $savePromptScreenOpacity;
	}

	.spScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $savePromptScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.spModalWrapper {

		.spModal {

			position: fixed;
			left: calc(50% - #{$savePromptWidth}/2);
			width: $savePromptWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $savePromptBackground;
			color: $savePromptColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.spModalInnerWrapper {

				margin: $savePromptMargin;
				padding: $savePromptMargin;
				border: 1px solid $savePromptAccent;

				.spClose {
					position: absolute;
					top: 1.5*$savePromptMargin;
					right: 1.5*$savePromptMargin;
					width: 2.5*$savePromptMargin;
					height: 2.5*$savePromptMargin;

					cursor: pointer;
					color: $savePromptColor;
					font-size: 40px;
					font-weight: 100;
					user-select: none;

					display: flex;
					justify-content: center;
					align-items: center;
					transition: color $hoverTransition;

					&:hover {
						color: $savePromptColorHover;
					}
				}

				.spModalTitle {
					font-size: 36px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $savePromptSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 28px;
						margin: $savePromptSpacingMobile 0;
					}
				}

				.spModalHr {
					background: $savePromptAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.spModalCopy {
					font-size: 20px;
					text-align: center;
					margin: $savePromptSpacing 0;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 16px;
						margin: $savePromptSpacingMobile 0;
					}
				}

				.spModalActions {

					position: relative;
					text-align: right;
					margin: $savePromptSpacing 0 0 0;

					.spAction.spActionSupplemental {
						position: absolute;
						top: 0;
						left: 0;
						margin: 0;
					}

					.spAction.spActionSave {
						color: $savePromptAccentText;
						background: $savePromptAccent;

						&:hover {
							background: $savePromptAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $savePromptColor;
						background: $savePromptBackground;
						margin: 0;

						&:hover {
							color: $savePromptColorHover;
							background: $savePromptBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $savePromptBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}

					.spAction.shortCopy {

						display: none;

						@media #{$break1200} {
							display: inline-block;
						}
					}

					.spAction.longCopy {
						
						display: inline-block;

						@media #{$break1200} {
							display: none;
						}
					}

					.spAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						margin: 0 0 0 $savePromptSpacing;
						padding: $savePromptSpacing/2 2*$savePromptSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $savePromptSpacingMobile;
							padding: $savePromptSpacing/2 $savePromptSpacing;
						}
					}
				}
			}
		}
	}
}



.DeletePrompt {

	.spModalWrapper {
		.spModal {
			.spModalInnerWrapper {
				
				.spModalTitle {
					font-size: 28px;
				}

				.spModalCopy {
					font-size: 16px;
				}

				.spModalInput {

					width: 60%;
					margin: 0 auto $savePromptSpacing auto;

					input {
						border: 1px solid $savePromptColor;
				    width: calc(100% - 4*#{$savePromptSpacing} - 2px);
				    font-size: 18px;
				    padding: $savePromptSpacing/2 2*$savePromptSpacing;
				    margin: 0;
				    text-align: center;
					}
				}
			}
		}
	}
}


$setPasswordAccent: $THEME_PRIMARY_COLOR;
$setPasswordAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$setPasswordAccentText: $THEME_PRIMARY_COLOR_TEXT;
$setPasswordBackground: $THEME_BACKGROUND;
$setPasswordBackgroundHover: $THEME_BACKGROUND_HOVER;
$setPasswordColor: $THEME_TEXT_COLOR;
$setPasswordColorHover: $THEME_TEXT_COLOR_HOVER;


.SetPasswordModal {

	.spModalWrapper {
		.spModal {

			background: $setPasswordBackground;

			.spModalInnerWrapper {

				border: 1px solid $setPasswordAccent;

				.spModalHr {
					background: $setPasswordAccent;
					margin: 0 auto 2*$savePromptMargin auto;
				}

				.spModalInput {

					width: 60%;
					margin: 0 auto $savePromptSpacing auto;

					.spFieldLabel {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						margin: 0 0 5px 0;
					}

					input {
						border: 1px solid $savePromptColor;
				    width: calc(100% - 4*#{$savePromptSpacing} - 2px);
				    font-size: 18px;
				    padding: $savePromptSpacing/2 2*$savePromptSpacing;
				    margin: 0;
				    text-align: center;
					}
				}

				.spModalActions {

					.spAction.spActionSave {
						color: $setPasswordAccentText;
						background: $setPasswordAccent;

						&:hover {
							background: $setPasswordAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $setPasswordColor;
						background: $setPasswordBackground;
						margin: 0;

						&:hover {
							color: $setPasswordColorHover;
							background: $setPasswordBackgroundHover;
						}
					}
				}

			}
		}
	}
}


$bulkUploadModalLoadingHeight: 60px;

.BulkUploadDownloadResultsModal {

	.spModalWrapper {

		.spModal {

			.spModalInnerWrapper {

				.loadingBody {
					text-align: center;
					padding: 2*$savePromptMargin 0;

					.loadingIconLabel {
						font-size: 24px;
						font-weight: 300;
						margin: 0 0 $savePromptMargin 0;
					}

					.loadingIconWrapper {
						margin: 0 auto;
						width: $bulkUploadModalLoadingHeight;
						height: $bulkUploadModalLoadingHeight;
					}
				}

				.completeBody {
					text-align: center;
					padding: 2*$savePromptMargin 0;

					.completeLabel {
						font-size: 24px;
						font-weight: 300;
						margin: 0 0 $savePromptMargin 0;
					}

					.completeAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						padding: $savePromptSpacing/2 2*$savePromptSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						color: $savePromptAccentText;
						background: $savePromptAccent;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							padding: $savePromptSpacing/2 $savePromptSpacing;
						}

						&:hover {
							background: $savePromptAccentHover;
						}
					}
				}
			}
		}
	}
}

















