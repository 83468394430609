

@use '../../../../style/import' as *;



////////////////
// AdminTable //
////////////////


// Titan colors
$adminTableAccentColor: $TITAN_PRIMARY_COLOR;
$adminTableAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$adminTableAccentTextColor: $TITAN_PRIMARY_COLOR_TEXT;
$adminTableTextButtonBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$adminTableTextButtonBackgroundHover: $TITAN_ADMIN_PAGE_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$adminTableFiltersMaxHeight: 300px;
$adminTableMargin: 10px;


.AdminTable {
	margin: 0 0 3*$adminTableMargin 0;

	.open.atFilterWrapper {
		max-height: $adminTableFiltersMaxHeight;

		@media #{$break1024} {
			max-height: 2*$adminTableFiltersMaxHeight;
		}

		@media #{$break600} {
			max-height: 3*$adminTableFiltersMaxHeight;
		}
	}

	.atFilterWrapper {
		height: auto;
		max-height: 0;
		overflow: hidden;
		transition: max-height $slideTransition;
	}
}


///////////////////////
// AdminTableFilters //
///////////////////////


// Titan colors
$adminTableFiltersPrimary: $TITAN_PRIMARY_COLOR;
$adminTableFiltersTagBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$adminTableFiltersHrColor: $THEME_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
$adminTableFiltersMinHeight: 45px;
$adminTableFiltersTagActionWidth: 20px;
$adminTableFiltersTagSectionMaxWidth: 320px;


.AdminTableFilters {

	margin: 0 0 $adminTableMargin 0;
	
	.atfLiner {

		.atfTitle {
			
			margin: 0 0 $adminTableMargin 0;

			.atfTitleValue {
				display: inline-block;
				vertical-align: middle;
				font-size: 18px;
				font-weight: 700;
				text-transform: uppercase;
			}

			.atfTitleClear {
				display: inline-block;
				vertical-align: middle;
				font-size: 12px;
				font-weight: 400;
				cursor: pointer;
				color: $adminTableAccentColor;
				margin: 0 0 0 0.5*$adminTableMargin;
				transition: color $hoverTransition;

				&:hover {
					color: $adminTableAccentColorHover;
					text-decoration: underline;
				}
			}
		}

		.atfBody {
			margin: 0;
			min-height: $adminTableFiltersMinHeight;

			.atfNoFiltersLabel {
				margin: $adminTableMargin 0 0 0;
				font-size: 16px;
				font-weight: 300;
			}

			.atfBodyLiner {

				.atfItemWrapper {

					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 $adminTableMargin 0 0;
					max-width: $adminTableFiltersTagSectionMaxWidth;

					.atfItem {

						.atfItemTitle {
							font-size: 14px;
							font-weight: 700;
							margin: 0 0 0.5*$adminTableMargin 0;
						}

						.atfItemBody {

							.atfItemTag {

								display: inline-block;
								vertical-align: top;
								background: $adminTableFiltersTagBackground;
								border-radius: 10%;
								margin: 0 $adminTableMargin 0.5*$adminTableMargin 0;

								.atfItemTagLabel {
									display: inline-block;
									vertical-align: top;
									width: auto;

									font-size: 10px;
									line-height: 14px;
									font-weight: 700;
									padding: 0.25*$adminTableMargin 0 0.25*$adminTableMargin 0.5*$adminTableMargin;
								}

								.atfItemTagBody {
									display: inline-block;
									vertical-align: top;
									width: auto;

									font-size: 14px;
									line-height: 14px;
									font-weight: 300;
									padding: 0.25*$adminTableMargin 0.5*$adminTableMargin;
									border-right: 1px solid $adminTableFiltersHrColor;
								}

								.atfItemTagAction {
									display: inline-block;
									vertical-align: top;
									width: auto;

									position: relative;
									padding: 0.5*$adminTableFiltersTagActionWidth;
									font-size: 18px;
									font-weight: 300;
									cursor: pointer;
									transition: font-weight $hoverTransition, color $hoverTransition;

									&:hover {
										color: $adminTableFiltersPrimary;
										font-weight: 700;
									}

									.atfItemTagActionX {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;

										display: flex;
										justify-content: center;
										align-items: center;
									}					
								}
							}
						}
					}
				}

				.pfAppliedElementWrapper {
					margin: 0 0 $adminTableMargin 0;

					

					.pfAppliedElementBody {

						.pfAppliedItemWrapper {

							display: inline-block;
							vertical-align: top;
							width: auto;
							margin: 0 $adminTableMargin 0.5*$adminTableMargin 0;	
						}
					}
				}
			}
		}
	}
}


////////////////////////
// AdminTableControls //
////////////////////////


// Titan colors
$adminTableControlsFilterBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$adminTableControlsInputBorder: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$adminTableControlsHeight: 40px;
$adminTableControlsHeightMobile: 32px;

$adminTableControlButtonHeight: 40px;
$adminTableControlButtonHeightMobile: 32px;
$adminTableControlButtonWidth: 50px;
$adminTableControlButtonWidthMobile: 40px;

$adminTableControlButtonSpacing: 20px;
$adminTableControlButtonSpacingMobile: 10px;

$adminTableControlsFilterElementMaxWidth: 360px;
$adminTableControlsFilterMaxHeight: 1800px;


.AdminTableControls {
	
	position: relative;
	height: auto;
	min-height: $adminTableControlsHeight;
	text-align: right;
	transition: min-height $breakTransition;

	@media #{$break1024} {
		min-height: $adminTableControlsHeightMobile;
	}

	.quickSearchWrapper {

		position: absolute;
		top: 0;
		left: 0;
		right: 50%;
		height: $adminTableControlsHeight;
		transition: height $breakTransition;

		@media #{$break1024} {
			height: $adminTableControlsHeightMobile;
		}

		@media #{$break768} {
			position: relative;
			right: unset;
			width: 100%;
		}

		.qsInnerWrapper {

			position: relative;
			background: white;
			border: 1px solid $adminTableAccentColor;
			height: $adminTableControlsHeight - 2px;
			transition: height $breakTransition;

			@media #{$break1024} {
				height: $adminTableControlsHeightMobile - 2px;
			}

			.qsInputWrapper {

				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: calc(100% - #{$adminTableControlButtonWidth});
				transition: width $breakTransition;

				@media #{$break1024} {
					width: calc(100% - #{$adminTableControlButtonWidthMobile});
				}

				input {
					border: 0;
					margin: 0;
					padding: 0.5*$adminTableControlButtonSpacing $adminTableControlButtonSpacing;
					width: calc(100% - 2*#{$adminTableControlButtonSpacing});
					height: $adminTableControlsHeight - $adminTableControlButtonSpacing - 2px;
					transition: padding $breakTransition, height $breakTransition, width $breakTransition;

					@media #{$break1024} {
						padding: 0.5*$adminTableControlButtonSpacingMobile $adminTableControlButtonSpacingMobile;
						width: calc(100% - 2*#{$adminTableControlButtonSpacingMobile});
						height: $adminTableControlButtonHeightMobile - $adminTableControlButtonSpacingMobile - 2px;
					}
				}
			}

			.qsButtonWrapper {

				position: absolute;
				top: -1px;
				right: -1px;
				bottom: -1px;
				width: $adminTableControlButtonWidth + 1px;

				cursor: pointer;
				background: $adminTableAccentColor;
				color: $adminTableAccentTextColor;
				transition: background $hoverTransition, width $breakTransition;

				&:hover {
					background: $adminTableAccentColorHover;
				}

				@media #{$break1024} {
					width: $adminTableControlButtonWidthMobile;
				}

				.qsButtonInnerWrapper {
					
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					.qsIcon {
						position: absolute;
						top: 0.5*$adminTableMargin;
						right: 0.5*$adminTableMargin;
						bottom: 0.5*$adminTableMargin;
						left: 0.5*$adminTableMargin;
						transition: top $hoverTransition, right $breakTransition, left $hoverTransition, bottom $breakTransition;

						@media #{$break1024} {
							top: 0.8*$adminTableMargin;
							right: 0.8*$adminTableMargin;
							bottom: 0.8*$adminTableMargin;
							left: 0.8*$adminTableMargin;
						}

						svg {
							fill: $adminTableAccentTextColor;
						}
					}
				}
			}
		}
	}

	.noButtons.atcButtonsWrapper {
		display: none;
	}

	.atcButtonsWrapper {

		display: inline-block;
		vertical-align: top;
		width: auto;

		@media #{$break768} {
			position: relative;
			display: block;
			width: 100%;
			height: $adminTableControlsHeightMobile;
			margin: 0.5*$adminTableMargin 0 0 0;
		}

		.filterLinkWrapper {

			position: relative;
			display: inline-block;
			vertical-align: top;
			float: none;
			margin: 0.5*($adminTableControlsHeight - $adminTableControlButtonHeight) 0 0.5*($adminTableControlsHeight - $adminTableControlButtonHeight) $adminTableControlButtonSpacing;
			transition: float $breakTransition, margin $breakTransition;

			@media #{$break768} {
				float: left;
				margin-left: 0;
			}

			.open.filterTabExt {
				height: 0.5*$adminTableMargin;
				transition: height $slideTransition;
			}

			.filterTabExt {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 0;
				background: $adminTableControlsFilterBackground;
				transition: height $slideFastTransition;
			}

			.open.filterButton {
				color: $adminTableAccentColorHover;
				background: $adminTableControlsFilterBackground;

				@media #{$break768} {
					color: $adminTableAccentColor;
					background: $adminTableTextButtonBackground;
				}

				&:hover {
					color: $adminTableAccentColorHover;
					background: $adminTableTextButtonBackgroundHover;

					@media #{$break768} {
						color: $adminTableAccentColor;
						background: $adminTableTextButtonBackground;
					}

					.filterButtonIcon {

						.filterIcon {

							svg {
								fill: $adminTableAccentColorHover;

								@media #{$break768} {
									fill: $adminTableAccentColor;
								}
							}
						}
					}
				}

				.filterButtonIcon {

					.filterIcon {

						transform: rotate(180deg);

						svg {
							fill: $adminTableAccentColorHover;

							@media #{$break768} {
								fill: $adminTableAccentColor;
							}
						}
					}
				}
			}

			.filterButton {
				height: $adminTableControlButtonHeight;
				color: $adminTableAccentColor;
				background: $adminTableTextButtonBackground;
				border: none;
				padding: 0 $adminTableControlButtonSpacing;
				cursor: pointer;
				font-size: 20px;
				transition: color $hoverTransition, background $hoverTransition, height $breakTransition, padding $breakTransition, font-size $breakTransition;

				&:hover {
					color: $adminTableAccentColorHover;
					background: $adminTableTextButtonBackgroundHover;

					.filterButtonIcon {

						.filterIcon {

							svg {
								fill: $adminTableAccentColorHover;
							}
						}
					}
				}

				@media #{$break1024} {
					height: $adminTableControlButtonHeightMobile;
					padding: 0 $adminTableControlButtonSpacingMobile;
					font-size: 18px;
				}

				@media #{$break768} {
					padding: 0;

					&:hover {
						background: $adminTableTextButtonBackground;
					}
				}

				.filterButtonIcon {
					position: relative;
					display: inline-block;
					vertical-align: top;
					margin: 0 $adminTableMargin 0 0;
					height: 0.6*$adminTableControlButtonHeight;
					width: 0.6*$adminTableControlButtonHeight;

					.filterIcon {
						height: 0.6*$adminTableControlButtonHeight;
						width: 0.6*$adminTableControlButtonHeight;

						svg {
							fill: $adminTableAccentColor;
						}
					}
				}

				.filterButtonCopy {
					display: inline-block;
					vertical-align: top;
				}
			}
		}

		.addButtonWrapper {

			display: inline-block;
			vertical-align: top;
			margin: 0.5*($adminTableControlsHeight - $adminTableControlButtonHeight) 0 0.5*($adminTableControlsHeight - $adminTableControlButtonHeight) $adminTableControlButtonSpacing;

			.addButtonDesktop.addButton {
				display: inline-block;

				@media #{$break768} {
					display: none;
				}
			}

			.addButtonMobile.addButton {
				display: none;

				@media #{$break768} {
					display: inline-block;
				}
			}

			.addButton {
				height: $adminTableControlButtonHeight;
				line-height: $adminTableControlButtonHeight;
				color: $adminTableAccentTextColor;
				background: $adminTableAccentColor;
				border: none;
				padding: 0 $adminTableControlButtonSpacing;
				cursor: pointer;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700;
				transition: background $hoverTransition, height $breakTransition, line-height $breakTransition, padding $breakTransition;

				&:hover {
					background: $adminTableAccentColorHover;
				}

				@media #{$break1024} {
					height: $adminTableControlButtonHeightMobile;
					line-height: $adminTableControlButtonHeightMobile;
					padding: 0 $adminTableControlButtonSpacing;
				}
			}
		}
	}

	.transitioning.filterToggleContainer {
		overflow: hidden !important;
	}

	.open.filterToggleContainer {
		overflow: visible;
		max-height: $adminTableControlsFilterMaxHeight;
		transition: max-height $slideTransition;
	}

	.filterToggleContainer {
		overflow: hidden;
		height: auto;
		max-height: 0;
		text-align: left;
		transition: max-height $slideFastTransition;

		.filterContainerLiner {
			margin: 0.5*$adminTableMargin 0 0 0;
			padding: $adminTableMargin 0 0 $adminTableMargin;
			background: $adminTableControlsFilterBackground;

			.defaultBlock.filterBlock {

			}
			
			.open.extBlock.filterBlock {
				max-height: 0.5*$adminTableControlsFilterMaxHeight;
				margin: 0 0 $adminTableMargin 0;
				transition: max-height $slideTransition, margin $slideTransition;;
			}

			.extBlock.filterBlock {
				overflow: hidden;
				height: auto;
				max-height: 0;
				margin: 0 0 0 0;
				transition: max-height $slideFastTransition, margin $slideFastTransition;
			}

			.filterBlock {
				
				.filterBlockLiner {

					.filterBlockTitle {

						margin: 0 0 $adminTableMargin 0;

						.filterBlockTitleValue {
							font-size: 18px;
							font-weight: 700;
							text-transform: uppercase;
							display: inline-block;
							vertical-align: middle;
							border-bottom: 2px solid $adminTableAccentColor;
						}

						.filterBlockTitleClear {
							display: inline-block;
							vertical-align: middle;
							font-size: 12px;
							font-weight: 400;
							cursor: pointer;
							color: $adminTableAccentColor;
							margin: 0 0 0 0.5*$adminTableMargin;
							transition: color $hoverTransition;

							&:hover {
								color: $adminTableAccentColorHover;
								text-decoration: underline;
							}
						}
					}

					.filterBlockBody {

						.filterElement {

							display: inline-block;
							vertical-align: top;
							width: calc(25% - #{$adminTableMargin});
							max-width: $adminTableControlsFilterElementMaxWidth;
							margin: 0 $adminTableMargin 2*$adminTableMargin 0;
							transition: width $breakTransition, margin $breakTransition;

							@media #{$break1500} {
								width: calc(33.3% - #{$adminTableMargin});
								max-width: unset;
							}

							@media #{$break1024} {
								width: calc(50.0% - #{$adminTableMargin});
							}

							@media #{$break600} {
								width: calc(100.0% - #{$adminTableMargin});
								margin: 0 $adminTableMargin $adminTableMargin 0;
							}

							.filterElementLiner {

							}
						}
					}
				}
			}
		}
	}
}


//////////////////////
// AdminTableFilter //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$atFilterInputHeight: 20px;
$atFilterInputSeparatorWidth: 20px;


.AdminTableFilter {

	.filterElementTitle {

		margin: 0 0 $adminTableMargin 0;

		.filterElementTitleValue {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
		
		.filterElementClearLink {
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			font-weight: 400;
			cursor: pointer;
			color: $adminTableAccentColor;
			margin: 0 0 0 0.5*$adminTableMargin;
			transition: color $hoverTransition;

			&:hover {
				color: $adminTableAccentColorHover;
				text-decoration: underline;
			}
		}
	}

	.filterElementBody {
		
		.elementLoading {

			height: $atFilterInputHeight;

			.loadingIconWrapper {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $atFilterInputHeight;
				height: $atFilterInputHeight;

				.loadingIcon {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					svg {
						fill: $adminTableAccentColor;
					}
				}
			}

			.loadingValue {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 0.5*$adminTableMargin;
				font-size: 18px;
				font-weight: 300;
				height: $atFilterInputHeight;
				line-height: $atFilterInputHeight;
			}
		}

		.singleInput {
			
			.singleInputWrapper {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 100%;

				input {
					border: 1px solid $adminTableControlsInputBorder;
					font-size: 14px;
					height: $atFilterInputHeight - 2px;
					padding: 0 $adminTableMargin;
					width: calc(100% - #{2*$adminTableMargin} - 2px);
					text-align: center;
				}

				.currencyOverlay {
					position: absolute;
					top: 0;
					left: 0.5*$adminTableMargin;
					width: auto;
					height: $atFilterInputHeight;
					line-height: $atFilterInputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					color: $adminTableControlsInputBorder;
					font-size: 15px;
					font-weight: 300;
					text-align: left;
				}
			}

			.singleInputDropdown {
				height: $atFilterInputHeight - 1px;
			}
		}

		.rangeInput {
			
			.minWrapper.minMaxWrapper {

			}

			.minMaxWrapper {
				display: inline-block;
				vertical-align: top;
				width: calc(50% - #{0.5*$atFilterInputSeparatorWidth});
				height: $atFilterInputHeight;

				.minMaxInput {

					position: relative;
					display: inline-block;
					vertical-align: top;
					width: 100%;

					input {
						border: 1px solid $adminTableControlsInputBorder;
						font-size: 14px;
						height: $atFilterInputHeight - 2px;
						padding: 0 $adminTableMargin;
						width: calc(100% - #{2*$adminTableMargin} - 2px);
						text-align: center;
					}

					.currencyOverlay {
						position: absolute;
						top: 0;
						left: 0.5*$adminTableMargin;
						width: auto;
						height: $atFilterInputHeight;
						line-height: $atFilterInputHeight;
						cursor: default;
						user-select: none;
						pointer-events: none;

						color: $adminTableControlsInputBorder;
						font-size: 15px;
						font-weight: 300;
						text-align: left;
					}

					.calendarOverlay {
						position: absolute;
						top: 0.15*$atFilterInputHeight;
						right: $adminTableMargin/2;
						width: 0.7*$atFilterInputHeight;
						height: 0.7*$atFilterInputHeight;
						cursor: default;
						user-select: none;
						pointer-events: none;

						.Icon {

							width: 0.7*$atFilterInputHeight;
							height: 0.7*$atFilterInputHeight;

							svg {
								fill: $adminTableControlsInputBorder;
							}
						}
					}
				}
			}

			.separatorWrapper {
				display: inline-block;
				vertical-align: top;
				width: $atFilterInputSeparatorWidth;
				line-height: $atFilterInputHeight;
				text-align: center;
			}
		}
	}
}



///////////////////////
// FilterMultiselect //
///////////////////////


// Titan colors
$atFilterMultiselectLineSelected: $TITAN_PRIMARY_ULTRALIGHT;
$atFilterMultiselectLoadingColor: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$atFilterMultiselectHeight: 140px;
$atFilterMultiselectLineHeight: 14px;
$atFilterMultiselectLoadingWidth: 30px;


.FilterMultiselect {

	.filterElementBody {

		.multiWrapper {

			.multiInputWrapper {

				display: inline-block;
				vertical-align: top;
				width: calc(50% - #{$atFilterInputSeparatorWidth/2});

				.multiInput {

					.multiInputLiner {

						.multiQuickSearchInput {

							position: relative;

							input {
								border-top: 1px solid $adminTableControlsInputBorder;
								border-left: 1px solid $adminTableControlsInputBorder;
								border-right: 1px solid $adminTableControlsInputBorder;
								border-bottom: none;
								font-size: 12px;
								height: $atFilterInputHeight - 2px;
								padding: 0 $atFilterInputHeight 0 $atFilterInputHeight;
								width: calc(100% - #{2*$atFilterInputHeight} - 2px);
								text-align: left;
							}

							.searchOverlay {

								$iconMarginPercent: 0.2;

								position: absolute;
								top: $iconMarginPercent * $atFilterInputHeight;
								left: $adminTableMargin/2;
								width: (1 - 2*$iconMarginPercent) * $atFilterInputHeight;
								bottom: $iconMarginPercent * $atFilterInputHeight;
								line-height: $atFilterInputHeight;
								cursor: default;
								user-select: none;
								pointer-events: none;

								color: $adminTableControlsInputBorder;
								font-size: 15px;
								font-weight: 300;
								text-align: left;

								.searchIcon {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;

									svg {
										fill: $adminTableControlsInputBorder;
									}
								}
							}

							.clearOverlay {
								position: absolute;
								top: 0;
								right: $adminTableMargin/2;
								width: auto;
								height: $atFilterInputHeight;
								line-height: $atFilterInputHeight;
								cursor: pointer;
								user-select: none;

								color: $adminTableControlsInputBorder;
								font-size: 18px;
								font-weight: 300;
								text-align: right;
							}
						}

						.noSearch.multiElements {
							height: $atFilterMultiselectHeight + $atFilterInputHeight - 1px;
						}

						.multiElements {
							position: relative;
							border: 1px solid $adminTableControlsInputBorder;
							padding: 0 0 calc($adminTableMargin/2) 0;
							height: $atFilterMultiselectHeight;
							overflow: auto;

							.loadingOptions {

								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								background: $atFilterMultiselectLineSelected;

								.loadingIconWrapper {
									position: absolute;
									top: $atFilterMultiselectLoadingWidth;
									left: calc(50% - #{$atFilterMultiselectLoadingWidth/2});
									width: $atFilterMultiselectLoadingWidth;
									height: $atFilterMultiselectLoadingWidth;

									svg {
										fill: $atFilterMultiselectLoadingColor;
									}
								}

								.loadingLabel {
									position: absolute;
									top: 2*$atFilterMultiselectLoadingWidth + $adminTableMargin;
									left: 0;
									width: 100%;
									height: auto;

									font-size: 14px;
									color: $atFilterMultiselectLoadingColor;
									text-align: center;
								}
							}

							.noneFound {
								margin: 2*$adminTableMargin 0 0 0;
								font-size: 16px;
								font-weight: 300;
								text-align: center;
							}

							.selected.elementWrapper {
								background: $atFilterMultiselectLineSelected;
							}

							.elementWrapper {

								overflow-x: hidden;
								overflow-y: auto;
								cursor: pointer;
								background: $adminTableControlsFilterBackground;
								transition: background $hoverTransition;

								&:hover {
									background: $atFilterMultiselectLineSelected;
								}

								.elementSelectLine {

									position: relative;
									margin: $adminTableMargin/2 $adminTableMargin/2 $adminTableMargin/2 $adminTableMargin/2;

									.elementCheckboxWrapper {

										position: absolute;
										top: 0;
										left: 0;

										width: $atFilterMultiselectLineHeight;
										height: $atFilterMultiselectLineHeight;
										line-height: $atFilterMultiselectLineHeight;

										.elementCheckboxLiner {
											pointer-events: none;
										}
									}

									.elementDisplayWrapper {

										line-height: $atFilterMultiselectLineHeight;
										margin: 0 0 0 $atFilterMultiselectLineHeight;

										.elementDisplayLiner {
											padding: 0 0 0 calc($adminTableMargin/2);
											font-size: 13px;
											line-height: 13px;
											user-select: none;
										}
									}
								}
							}
						}
					}
				}
			}

			.multiResultsWrapper {

				display: inline-block;
				vertical-align: top;
				width: calc(50% - #{$atFilterInputSeparatorWidth/2});
				margin: 0 0 0 $atFilterInputSeparatorWidth;

				.multiResults {

					.multiTitle {

						margin: 0 0 calc($adminTableMargin/2) 0;

						.multiTitleValue {
							display: inline-block;
							vertical-align: top;
							width: auto;
							font-size: 14px;
							font-weight: 400;
							padding: 0 calc($adminTableMargin/2) 0 0;
							border-bottom: 1px solid $adminTableAccentColor;
							height: $atFilterInputHeight - 1px;
							line-height: $atFilterMultiselectLineHeight;
						}

						.multiTitleAction {
							display: inline-block;
							vertical-align: top;
							width: auto;
							font-size: 12px;
							font-weight: 400;
							cursor: pointer;
							color: $adminTableAccentColor;
							margin: 0 0 0 calc($adminTableMargin/2);
							height: $atFilterInputHeight - 1px;
							line-height: $atFilterMultiselectLineHeight;
							transition: color $hoverTransition;

							&:hover {
								color: $adminTableAccentColorHover;
								text-decoration: underline;
							}
						}
					}

					.multiResultsBody {

						.noneFound {
							margin: $adminTableMargin 0 0 0;
							font-size: 16px;
							font-weight: 300;
							text-align: left;
						}

						.resultRow {

							margin: 0 0 calc($adminTableMargin/2) 0;

							.removeActionWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: $atFilterMultiselectLineHeight;
								height: $atFilterMultiselectLineHeight;
								cursor: pointer;
								font-size: 18px;
								line-height: 16px;
								font-weight: 400;
								transition: color $hoverTransition, font-size $hoverTransition, font-weight $hoverTransition;

								&:hover {
									font-size: 20px;
									line-height: 16px;
									font-weight: 700;
									color: $adminTableAccentColor;
								}

								.removeX {
									
								}
							}

							.resultNameWrapper {

								display: inline-block;
								vertical-align: top;
								width: calc(100% - #{$atFilterMultiselectLineHeight});

								.resultNameValue {
									margin: 0 0 0 $adminTableMargin/2;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}



///////////////////////
// AdminTableResults //
///////////////////////


// Titan colors
$adminTableResultsViewIconColor: $TITAN_TEXT_COLOR;
$adminTableResultsViewIconColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$adminTableResultsViewIconColorInactive: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$adminTableResultsHeight: 24px;
$adminTableResultsHeightMobile: 16px;


.AdminTableResults {

	margin: $adminTableMargin 0 0 0;

	.atrLiner {

		position: relative;
		height: $adminTableResultsHeight;
		transition: height $breakTransition;

		@media #{$break1024} {
			height: $adminTableResultsHeightMobile;
		}

		.atrResultsValue {

			.viewActionsWrapper {
				display: inline-block;
				vertical-align: top;
				height: $adminTableResultsHeight;
				line-height: $adminTableResultsHeight;
				margin: 0 0 0 0;

				@media #{$break1024} {
					display: none;
				}

				.viewActionsDropdownWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					height: $adminTableResultsHeight;
					border-bottom: 1px solid $adminTableResultsViewIconColor;
					cursor: pointer;

					select {
						border: none;
						background: none;
						font-size: 14px;
					}
				}

				.viewSortVr {
					display: inline-block;
					vertical-align: top;
					margin: 0 $adminTableMargin;
					height: $adminTableResultsHeight;
					width: 1px;
					background: $adminTableResultsViewIconColor;
				}
			}

			.atrResultsCount {
				display: inline-block;
				vertical-align: top;
				font-size: 18px;
				line-height: $adminTableResultsHeight;
				transition: font-size $breakTransition, line-height $breakTransition;

				@media #{$break1024} {
					font-size: 14px;
					line-height: $adminTableResultsHeightMobile;
				}
			}
		}

		.atrViewWrapper {

			display: block;
			position: absolute;
			top: 0;
			right: 0;

			@media #{$break1024} {
				display: none;
			}

			.viewSortWrapper {
				display: inline-block;
				vertical-align: top;
				height: $adminTableResultsHeight;
				line-height: $adminTableResultsHeight;
				margin: 0 $adminTableMargin 0 0;

				@media #{$break1024} {
					height: $adminTableResultsHeightMobile;
					line-height: $adminTableResultsHeightMobile;
				}

				.viewSortLabel {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					font-weight: 300;
					margin: 0 $adminTableMargin/2 0 0;

					&:after {
						content: ':';
					}
				}

				.viewSortDropdownWrapper {
					display: inline-block;
					vertical-align: top;
					width: auto;
					height: $adminTableResultsHeight;
					border-bottom: 1px solid $adminTableResultsViewIconColor;
					cursor: pointer;

					select {
						border: none;
						background: none;
						font-size: 14px;
					}
				}

				.light.viewSortVr {
					background: $adminTableResultsViewIconColorInactive;
				}

				.viewSortVr {
					display: inline-block;
					vertical-align: top;
					margin: 0 $adminTableMargin/2;
					height: $adminTableResultsHeight;
					width: 1px;
					background: $adminTableResultsViewIconColor;
				}

				.viewSortArrowWrapper {

					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $adminTableResultsHeight;
					height: $adminTableResultsHeight;
					user-select: none;

					&:hover {

						.sortArrow.disabled {

							.upArrow.arrowWrapper {
								border-bottom-color: $adminTableResultsViewIconColorInactive; 
							}

							.arrowWrapper {
								border-top-color: $adminTableResultsViewIconColorInactive; 
							}
						}

						.sortArrow {

							.upArrow.arrowWrapper {
								border-bottom-color: $adminTableAccentColor; 
							}

							.arrowWrapper {
								border-top-color: $adminTableAccentColor; 
							}
						}
					}

					.sortArrow.disabled {

						cursor: default;

						.upArrow.arrowWrapper {
							border-bottom-color: $adminTableResultsViewIconColorInactive; 
						}

						.arrowWrapper {
							border-top-color: $adminTableResultsViewIconColorInactive; 
						}
					}

					.sortArrow {

						position: absolute;
						top: 0;
						right: $adminTableMargin;
						bottom: 0;
						width: $adminTableResultsHeight / 2;
						cursor: pointer;

						.upArrow.arrowWrapper {
							border-top: none;
							border-bottom: 1.5 * ($adminTableResultsHeight / 3) solid $adminTableResultsViewIconColor;
						}

						.arrowWrapper {

							position: absolute;
							top: (2/8) * $adminTableResultsHeight;
							left: 0;
							width: 0; 
							height: 0; 

							border-left: ($adminTableResultsHeight / 3) solid transparent;
							border-right: ($adminTableResultsHeight / 3) solid transparent; 
							border-top: 1.5 * ($adminTableResultsHeight / 3) solid $adminTableResultsViewIconColor; 
							transition: border-color $hoverTransition;
						}
					}
				}
			}

			.viewLabelWrapper {
				display: inline-block;
				vertical-align: top;
				height: $adminTableResultsHeight;
				line-height: $adminTableResultsHeight;
				font-size: 14px;
				font-weight: 300;
				text-transform: uppercase;
				transition: height $breakTransition, line-height $breakTransition;

				@media #{$break1024} {
					height: $adminTableResultsHeightMobile;
					line-height: $adminTableResultsHeightMobile;
				}
			}

			.viewIconWrapper {

				position: relative;
				display: inline-block;
				vertical-align: top;
				text-align: right;
				margin: 0 0 0 $adminTableMargin;
				height: $adminTableResultsHeight;
				width: $adminTableResultsHeight;
				transition: height $breakTransition, width $breakTransition;

				@media #{$break1024} {
					height: $adminTableResultsHeightMobile;
					width: $adminTableResultsHeightMobile;
				}

				.selected.viewIcon {

					cursor: default;

					&:hover {
						svg {
							fill: $adminTableResultsViewIconColor;
						}
					}

					svg {
						fill: $adminTableResultsViewIconColor;
					}
				}

				.viewIcon {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					cursor: pointer;

					&:hover {
						svg {
							fill: $adminTableResultsViewIconColorHover;
						}
					}

					svg {
						fill: $adminTableResultsViewIconColorInactive;
					}
				}
			}
		}
	}
}



//////////////////////
// AdminTableHeader //
//////////////////////


// Titan colors
$adminTableHeaderColor: $TITAN_ADMIN_BLOCK_BACKGROUND;
$adminTableHeaderBackground: $TITAN_ADMIN_CONTROL_BACKGROUND;
$adminTableHeaderBackgroundHover: $TITAN_ADMIN_CONTROL_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables

$adminTableHeaderArrowHeight: 30px;
$adminTableHeaderArrowHeightMobile: 24px;
$adminTableHeaderHeight: 50px;
$adminTableHeaderHeightMobile: 40px;

$adminTableHeaderHorizontalSpacing: 8px;
$adminTableHeaderCheckboxWidth: 20px;
$adminTableHeaderSortWidth: 16px;


.AdminTableHeader.singleValue {
	height: 0.6*$adminTableHeaderHeight;

	@media #{$break1024} {
		height: 0.6*$adminTableHeaderHeightMobile;
	}
}


.AdminTableHeader {

	position: relative;
	width: 100%;
	height: $adminTableHeaderHeight;
	margin: $adminTableMargin/2 0 0 0;
	transition: height $breakTransition;

	@media #{$break1024} {
		height: $adminTableHeaderHeightMobile;
	}

	.athWrapper {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border-left: 1px solid $adminTableHeaderBackground;
		border-right: 1px solid $adminTableHeaderBackground;

		.noSort.athElement {

			cursor: default;

			&:hover {
				background: $adminTableHeaderBackground;
			}

			.athElementWrapper {

				.athLabel {
					right: $adminTableHeaderSortWidth;
				}

				.athSort {
					display: none;
				}
			}
		}

		.athElement {

			position: absolute;
			top: 0;
			bottom: 0;

			cursor: pointer;
			color: $adminTableHeaderColor;
			background: $adminTableHeaderBackground;
			font-size: 14px;
			font-weight: 700;
			transition: background $hoverTransition, font-size $breakTransition;

			&:last-of-type {

				.athElementWrapper {
					border-right-color: $adminTableHeaderBackground;
				}
			}

			&:hover {
				background: $adminTableHeaderBackgroundHover;
			}

			@media #{$break1024} {
				font-size: 12px;
			}

			.athElementWrapper {

				position: absolute;
				top: 0;
				left: 0;
				right: 1px;
				bottom: 0;
				border-right: 1px solid $adminTableHeaderColor;

				.athCheckboxSmall.athCheckboxWrapper {

					$shinkFactor: 0.7;

					position: absolute;
					top: calc(50% - #{$shinkFactor * 0.5 * $adminTableHeaderCheckboxWidth});
					left: calc(50% - #{$shinkFactor * 0.5 * $adminTableHeaderCheckboxWidth});
					width: $shinkFactor * $adminTableHeaderCheckboxWidth;
					height: $shinkFactor * $adminTableHeaderCheckboxWidth;
				}

				.athCheckboxWrapper {

					position: absolute;
					top: calc(50% - #{0.5 * $adminTableHeaderCheckboxWidth});
					left: calc(50% - #{0.5 * $adminTableHeaderCheckboxWidth});
					width: $adminTableHeaderCheckboxWidth;
					height: $adminTableHeaderCheckboxWidth;
				}

				.athLabel {
					
					display: flex;
					align-items: center;

					position: absolute;
					user-select: none;
					top: 0.5*$adminTableMargin;
					left: $adminTableHeaderHorizontalSpacing;
					right: $adminTableHeaderSortWidth + $adminTableHeaderHorizontalSpacing;
					bottom: 0.5*$adminTableMargin;
					line-height: 0.5*($adminTableHeaderHeight - $adminTableMargin);
					text-transform: uppercase;
					transition: line-height $breakTransition;

					@media #{$break1024} {
						line-height: 0.5*($adminTableHeaderHeightMobile - $adminTableMargin);
					}
				}

				.athSort {

					position: absolute;
					user-select: none;
					top: 0.5*$adminTableMargin;
					right: $adminTableHeaderHorizontalSpacing;
					width: $adminTableHeaderSortWidth;
					bottom: 0.5*$adminTableMargin;

					.upArrow.athSortArrow {
						border-top: none;
						border-bottom: 1.5 * (0.25 * $adminTableHeaderArrowHeight) solid $adminTableHeaderColor;
						border-left: (0.25 * $adminTableHeaderArrowHeight) solid transparent;
						border-right: (0.25 * $adminTableHeaderArrowHeight) solid transparent;

						@media #{$break1024} {
							border-bottom: 1.5 * (0.25 * $adminTableHeaderArrowHeightMobile) solid $adminTableHeaderColor;
							border-left: (0.25 * $adminTableHeaderArrowHeightMobile) solid transparent;
							border-right: (0.25 * $adminTableHeaderArrowHeightMobile) solid transparent;
						}
					}

					.athSortArrow {

						position: absolute;
						top: (5/16) * $adminTableHeaderHeight;
						left: 0;
						width: 0; 
						height: 0; 

						border-top: 1.5 * (0.25 * $adminTableHeaderArrowHeight) solid $adminTableHeaderColor;
						border-bottom: none;
						border-left: (0.25 * $adminTableHeaderArrowHeight) solid transparent;
						border-right: (0.25 * $adminTableHeaderArrowHeight) solid transparent;

						@media #{$break1024} {
							top: (5/16) * $adminTableHeaderArrowHeightMobile;
							border-top: 1.5 * (0.25 * $adminTableHeaderArrowHeightMobile) solid $adminTableHeaderColor;
							border-left: (0.25 * $adminTableHeaderArrowHeightMobile) solid transparent;
							border-right: (0.25 * $adminTableHeaderArrowHeightMobile) solid transparent;
						}
					}

					.noArrow {

					}
				}
			}
		}
	}
}


////////////////////
// AdminTableBody //
////////////////////


// Titan colors
$adminTableBodyColor: $TITAN_TEXT_COLOR;
$adminTableBodyBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$adminTableBodyBackgroundOdd: $adminTableBodyBackground;
$adminTableBodyBackgroundEven: $TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE;
$adminTableBodyBackgroundSelected: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$adminTableBodyRowHeight: 66px;
$adminTableBodyRowHeightMobile: 54px;


.AdminTableBody {

	.adminTableBody {

		.atBodyWrapper {

			overflow: auto;
			background: $adminTableBodyBackground;
			border-left: 1px solid $adminTableHeaderBackground;
			border-right: 1px solid $adminTableHeaderBackground;
			border-bottom: 1px solid $adminTableHeaderBackground;

			.atbLoadingWrapper {

				margin: 100px 0;
				text-align: center;

				.atbLoadingIconWrapper {
					display: inline-block;
					vertical-align: top;
					width: 80px;
				}
			}

			.atbNoResultsWrapper {

				margin: 50px 0;
				text-align: center;

				.atbNoResultsIconWrapper {
					width: 60px;
					margin: 0 auto 5px auto;
				}

				.atbNoResultsLabel {
					font-size: 20px;
					font-weight: 300;
					text-transform: uppercase;
					user-select: none;
				}

				.addButtonDesktop.atbAddButton {
					display: inline-block;

					@media #{$break768} {
						display: none;
					}
				}

				.addButtonMobile.atbAddButton {
					display: none;

					@media #{$break768} {
						display: inline-block;
					}
				}

				.atbAddButton {
					height: $adminTableControlButtonHeight;
					line-height: $adminTableControlButtonHeight;
					color: $adminTableAccentTextColor;
					background: $adminTableAccentColor;
					border: none;
					margin: 20px 0 0 0;
					padding: 0 $adminTableControlButtonSpacing;
					cursor: pointer;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					transition: background $hoverTransition, height $breakTransition, line-height $breakTransition, padding $breakTransition;

					&:hover {
						background: $adminTableAccentColorHover;
					}

					@media #{$break1024} {
						height: $adminTableControlButtonHeightMobile;
						line-height: $adminTableControlButtonHeightMobile;
						padding: 0 $adminTableControlButtonSpacing;
					}
				}
			}

			.atCustomLine.selected {

				background: $adminTableBodyBackgroundSelected;

				&:nth-child(even) {
					background: $adminTableBodyBackgroundSelected;
				}

				@media #{$break1024} {
					background: $adminTableBodyBackgroundOdd;

					&:nth-child(even) {
						background: $adminTableBodyBackgroundEven;
					}
				}
			}

			.atCustomLine {

				position: relative;
				background: $adminTableBodyBackgroundOdd;
				transition: background $hoverTransition;

				&:nth-child(even) {
					background: $adminTableBodyBackgroundEven;
				}

				.atActionWrapper {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					// Width set programatically

					.atActionElement {

					}
				}

				.atCustomLineLiner {
					// Margin-left set programatically
				}
			}

			.atLine.selected {

				background: $adminTableBodyBackgroundSelected;

				&:nth-child(even) {
					background: $adminTableBodyBackgroundSelected;
				}

				@media #{$break1024} {
					background: $adminTableBodyBackgroundOdd;

					&:nth-child(even) {
						background: $adminTableBodyBackgroundEven;
					}
				}
			}

			.atLine {

				position: relative;
				height: $adminTableBodyRowHeight;
				background: $adminTableBodyBackgroundOdd;
				transition: height $breakTransition, background $hoverTransition;

				@media #{$break1024} {
					height: $adminTableBodyRowHeightMobile;

					&:hover {
						background: $adminTableBodyBackgroundSelected;
					}

					&:nth-child(even):hover {
						background: $adminTableBodyBackgroundSelected;
					}
				}

				&:nth-child(even) {
					background: $adminTableBodyBackgroundEven;
				}

				.atLineWrapper {

					.atbOverlayLink {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}

					.atbElement {

						position: absolute;
						top: 0;
						bottom: 0;
						overflow: hidden;
						color: $adminTableBodyColor;
						font-size: 16px;
						transition: font-size $breakTransition;

						@media #{$break1024} {
							font-size: 14px;
						}

						&:last-of-type {

							.atbElementWrapper {
								right: 0;
								border-right: none;
							}
						}

						.atbElementWrapper {

							position: absolute;
							top: 0;
							left: 0;
							right: 1px;
							bottom: 0;
							border-right: 1px dotted $adminTableHeaderBackground;

							.atbElementContentWrapper {

								/* For fucks sake, stop the CSS / Inception type hell going on here and use the formal elements (ActionElement, GenericElement) below */

								position: absolute;
								overflow: hidden;
								top: $adminTableHeaderHorizontalSpacing / 2;
								bottom: $adminTableHeaderHorizontalSpacing / 2;
								left: $adminTableHeaderHorizontalSpacing;
								right: $adminTableHeaderHorizontalSpacing;
							}
						}
					}
				}
			}
		}
	}
}

//////////////////////////
// AdminTablePagination //
//////////////////////////


// Titan colors
$adminTablePaginationColor: $TITAN_TEXT_COLOR;
$adminTablePaginationColorHover: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$adminTablePaginationRowHeight: 20px;
$adminTablePaginationOffset: 4px;


.AdminTablePagination {



	.adminTablePagination {

		text-align: center;

		.atpControls {

			margin: $adminTablePaginationRowHeight 0 0 0;
			
			.lhsControls.disabled {

				.backOne {

					cursor: default;

					&:hover {
						.leftArrow {
							border-right-color: $disabledColor;
						}
					}

					.leftArrow {
						border-right-color: $disabledColor;
					}
				}

				.backAll {
					border-color: $disabledColor;
					cursor: default;

					&:hover {
						border-color: $disabledColor;

						.leftArrow {
							border-right-color: $disabledColor;
						}
					}

					.leftArrow {
						border-right-color: $disabledColor;
					}
				}
			}

			.lhsControls {

				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 3*$adminTablePaginationRowHeight;
				height: $adminTablePaginationRowHeight;

				.leftArrow {
					position: absolute;
					top: 0;
					left: -1*$adminTablePaginationOffset;
					width: 0;
					height: 0;

					border-top: 0.5*$adminTablePaginationRowHeight solid transparent;
					border-bottom: 0.5*$adminTablePaginationRowHeight solid transparent;
					border-right: 0.75*$adminTablePaginationRowHeight solid $adminTablePaginationColor;
					transition: border $hoverTransition;
				}

				.backAll {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: $adminTablePaginationRowHeight - $adminTablePaginationOffset;
					border-left: $adminTablePaginationOffset solid $adminTablePaginationColor;
					transition: border $hoverTransition;

					&:hover {
						border-color: $adminTablePaginationColorHover;

						.leftArrow {
							border-right-color: $adminTablePaginationColorHover;
						}
					}
				}

				.backOne {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					left: $adminTablePaginationRowHeight + 2*$adminTablePaginationOffset;
					width: $adminTablePaginationRowHeight;
					transition: border $hoverTransition;

					&:hover {
						.leftArrow {
							border-right-color: $adminTablePaginationColorHover;
						}
					}
				}
			}

			.rhsControls.disabled {

				.forwardOne {
					
					cursor: default;

					&:hover {
						.rightArrow {
							border-left-color: $disabledColor;
						}
					}

					.rightArrow {
						border-left-color: $disabledColor;
					}
				}

				.forwardAll {
					border-color: $disabledColor;
					cursor: default;

					&:hover {
						border-color: $disabledColor;

						.rightArrow {
							border-left-color: $disabledColor;
						}
					}

					.rightArrow {
						border-left-color: $disabledColor;
					}
				}
			}

			.rhsControls {

				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 3*$adminTablePaginationRowHeight;
				height: $adminTablePaginationRowHeight;

				.rightArrow {
					position: absolute;
					top: 0;
					right: -1*$adminTablePaginationOffset;;
					width: 0;
					height: 0;

					border-top: 0.5*$adminTablePaginationRowHeight solid transparent;
					border-bottom: 0.5*$adminTablePaginationRowHeight solid transparent;
					border-left: 0.75*$adminTablePaginationRowHeight solid $adminTablePaginationColor;
					transition: border $hoverTransition;

					&:hover {
						border-left-color: $adminTablePaginationColorHover;
					}
				}

				.forwardAll {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: $adminTablePaginationRowHeight - $adminTablePaginationOffset;
					border-right: $adminTablePaginationOffset solid $adminTablePaginationColor;
					transition: border $hoverTransition;

					&:hover {
						border-color: $adminTablePaginationColorHover;

						.rightArrow {
							border-left-color: $adminTablePaginationColorHover;
						}
					}
				}

				.forwardOne {
					cursor: pointer;
					position: absolute;
					top: 0;
					bottom: 0;
					right: $adminTablePaginationRowHeight + 2*$adminTablePaginationOffset;
					width: $adminTablePaginationRowHeight;
					transition: border $hoverTransition;

					&:hover {
						border-color: $adminTablePaginationColorHover;

						.rightArrow {
							border-left-color: $adminTablePaginationColorHover;
						}
					}
				}
			}

			.currentPage.loadingPage {
				font-weight: 300;
			}

			.currentPage {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin: 0 2*$adminTablePaginationOffset;
				font-weight: 400;

				.pageNumber {
					font-weight: 700;
				}
			}
		}

		.atpSize {

			margin: $adminTablePaginationRowHeight 0 0 0;

			.atpSizeCopy {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				font-size: 13px;
				font-weight: 300;
				margin: 0 $adminTablePaginationOffset 0 0;
			}

			.atpSizeDropdown {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				border-bottom: 1px solid $adminTablePaginationColor;
				cursor: pointer;

				select {
					border: none;
					background: none;
					font-size: 13px;
				}
			}
		}
	}
}


///////////////////////////////
// AdminTableBulkActionModal //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$bulkActionModalLoadingHeight: 60px;



.AdminTableBulkActionModal {

	.spModalWrapper {

		.spModal {

			.spModalInnerWrapper {

			}
		}
	}
}


/////////////////////
// BulkActionModal //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.BulkActionModal {

	.baWrapper {

		.present.baFormError {
			height: 3.5*$adminTableMargin;
		}

		.baFormError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.errorSelection {

			.errorBody {
				text-align: center;
				padding: 2*$adminTableMargin 0;

				.errorLabel {
					font-size: 24px;
					font-weight: 300;
					margin: 0 0 $adminTableMargin 0;
				}

				.errorData {

					text-align: center;
					margin: 0 0 2*$adminTableMargin 0;

					.errorDatum {
						font-size: 14px;
					}
				}

				.errorAction {
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					border: none;
					outline: none;
					padding: $adminTableMargin 2*$adminTableMargin;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					color: $adminTableAccentTextColor;
					background: $adminTableAccentColor;
					transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

					@media #{$break768} {
						padding: $adminTableMargin/2 $adminTableMargin;
					}

					&:hover {
						background: $adminTableAccentColorHover;
					}
				}
			}
		}

		.selectionSection {

			text-align: center;

			.exportBlock {

				display: inline-block;
				vertical-align: top;
				width: 50%;

				.exportBlockLiner {

					text-align: center;
					margin: 2*$adminTableMargin $adminTableMargin;

					.exportButton {
						border: none;
						outline: none;
						cursor: pointer;
						padding: $adminTableMargin 2*$adminTableMargin;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						color: $adminTableAccentTextColor;
						background: $adminTableAccentColor;
						transition: background $hoverTransition;

						&:hover {
							background: $adminTableAccentColorHover;
						}
					}

					.exportResultsWrapper {
						margin: $adminTableMargin/2 0 0 0;
						font-size: 14px;
						font-weight: 300;
					}
				}
			}
		}

		.pendingSection {

			.loadingBody {
				text-align: center;
				padding: 2*$adminTableMargin 0;

				.loadingIconLabel {
					font-size: 24px;
					font-weight: 300;
					margin: 0 0 $adminTableMargin 0;

					.percentWrapper {
						display: inline;
						margin: 0 0 0 $adminTableMargin;
					}
				}

				.loadingIconWrapper {
					margin: 0 auto;
					width: $bulkActionModalLoadingHeight;
					height: $bulkActionModalLoadingHeight;
				}
			}
		}

		.availableSection {

			.completeBody {
				text-align: center;
				padding: 2*$adminTableMargin 0;

				.completeLabel {
					font-size: 24px;
					font-weight: 300;
					margin: 0 0 $adminTableMargin 0;
				}

				.completeAction {
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					border: none;
					outline: none;
					padding: $adminTableMargin 2*$adminTableMargin;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					color: $adminTableAccentTextColor;
					background: $adminTableAccentColor;
					transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

					@media #{$break768} {
						padding: $adminTableMargin/2 $adminTableMargin;
					}

					&:hover {
						background: $adminTableAccentColorHover;
					}
				}
			}
		}

		.bulkActionAdminForm.adminForm {

			.adminFieldWrapper {
				max-width: unset;
			}

			.adminActionRow {
				text-align: center;
			}
		}

		.requestPending {

			.loadingBody {
				text-align: center;
				padding: 2*$adminTableMargin 0;

				.loadingIconLabel {
					font-size: 24px;
					font-weight: 300;
					margin: 0 0 $adminTableMargin 0;
				}

				.loadingIconWrapper {
					margin: 0 auto;
					width: $bulkActionModalLoadingHeight;
					height: $bulkActionModalLoadingHeight;
				}
			}
		}
	}
}


/////////////////////////
// BulkActionChangeSet //
/////////////////////////


// Titan colors
$bulkActionChangeSetRuleColor: $TITAN_RULE_COLOR;
$bulkActionChangeSetLoadingBackgroundColor: $TITAN_ADMIN_BLOCK_BACKGROUND;

// Theme colors
// None yet

// General variables
$bulkActionChangeSetBodyWidth: 80%;
$bulkActionChangeSetLoadingBackgroundOpacity: 0.8;
$bulkActionChangeSetLoadingIconWidth: 60px;



.BulkActionChangeSet {

	.baWrapper {

		.changeSetWrapper {

			margin: 0 auto;
			width: $bulkActionChangeSetBodyWidth;

			.setsHr {
				margin: 2*$adminTableMargin auto;
				width: $bulkActionChangeSetBodyWidth;
				height: 1px;
				background: $bulkActionChangeSetRuleColor;
			}

			.currentSetsWrapper {

				.currentSetsTitle {
					margin: 2*$adminTableMargin 0 0.5*$adminTableMargin 0;
					font-size: 18px;
					font-weight: 700;
				}

				.currentSetsNone {
					font-size: 16px;
					font-style: italic;
				}

				.currentSetsList {

					.currentSetName {
						font-size: 15px;
						font-weight: 300;
					}
				}
			}

			.newSetWrapper {
				overflow: visible;

				.adminFieldWrapper {

					.setFieldAction {
						position: absolute;
						top: 0.25*$adminTableMargin;
						right: 0;
						width: auto;
						height: auto;
						cursor: pointer;
						font-size: 14px;
						font-weight: 700;
						color: $adminTableAccentColor;
						transition: color $hoverTransition;

						&:hover {
							color: $adminTableAccentColorHover;
						}
					}

					.selectedSet {

						.selectedSetLiner {

							.setName {
								display: inline-block;
								vertical-align: baseline;
								font-size: 20px;
								font-weight: 300;
							}
							
							.setClear {
								display: inline-block;
								vertical-align: baseline;
								margin: 0 0 0 $adminTableMargin;
								font-size: 14px;
								color: $adminTableAccentColor;
								cursor: pointer;
								transition: color $hoverTransition;

								&:hover {
									color: $adminTableAccentColorHover;
								}
							}
						}
					}
				}
			}
		}

		.savingOverlay {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($bulkActionChangeSetLoadingBackgroundColor, $bulkActionChangeSetLoadingBackgroundOpacity);

			.savingIconWrapper {
				position: absolute;
				top: calc(50% - #{0.5*$bulkActionChangeSetLoadingIconWidth});
				left: calc(50% - #{0.5*$bulkActionChangeSetLoadingIconWidth});
				width: $bulkActionChangeSetLoadingIconWidth;
				height: $bulkActionChangeSetLoadingIconWidth;

				svg {
					fill: $adminTableAccentColor;
				}
			}

			.savingCopy {
				position: absolute;
				top: calc(50% + #{0.5*$bulkActionChangeSetLoadingIconWidth} + #{$adminTableMargin});
				left: 0;
				width: 100%;
				font-size: 22px;
				font-weight: 300;
				text-align: center;
			}
		}
	}	
}


////////////////////////////
// BulkActionExportOrders //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.BulkActionExportOrders {

	.baWrapper {

	}	
}


///////////////////////////////
// BulkActionExportInventory //
///////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.BulkActionExportInventory {

	.baWrapper {

	}	
}


//////////////////////////
// BulkActionShipOrders //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.BulkActionShipOrders {

	.baWrapper {

	}	
}


//////////////////////////
// BulkActionManageTags //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.BulkActionManageTags {

	.baWrapper {
		padding-top: 2*$adminTableMargin;
	}
}



////////////////////
// GenericElement //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.GenericElement {



	.genericElementWrapper {

	}
}


////////////////////
// AddressElement //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddressElement {
	
	.aeWrapper {

		font-size: 12px;

		.aeLine {

		}
	}
}


///////////////////
// BinaryElement //
///////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.BinaryElement {
	


	.binaryElementWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.binaryElementIconWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.binaryElementIcon {
			position: absolute;
			top: 15%;
			left: 0;
			height: 70%;
			width: 100%;
		}
	}
}


/////////////////////
// CheckboxElement //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CheckboxElement {

	.checkboxElementWrapper {
		position: absolute;
		top: calc(50% - #{$adminTableHeaderCheckboxWidth/2});
		left: calc(50% - #{$adminTableHeaderCheckboxWidth/2});
		width: $adminTableHeaderCheckboxWidth;
		height: $adminTableHeaderCheckboxWidth;
	}
}


/////////////////////////////
// CombinedIdentityElement //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CombinedIdentityElement {



	.combinedIdentityElementWrapper {

		.cieName {
			font-size: 16px;
			font-weight: 400;
			transition: font-size $breakTransition;

			@media #{$break1024} {
				font-size: 14px;
			}
		}

		.cieEmail {
			font-size: 14px;
			font-weight: 300;
			transition: font-size $breakTransition;

			@media #{$break1024} {
				font-size: 12px;
			}
		}
	}
}


////////////////////////////
// CompoundGenericElement //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.CompoundGenericElement {



	.genericElementWrapper {

	}

	.subElementWrapper {
		font-size: 14px;
		font-weight: 300;
		transition: font-size $breakTransition;

		@media #{$break1024} {
			font-size: 12px;
		}
	}
}


/////////////////
// DateElement //
/////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.DateElement {



	.dateElementWrapper {

		.deDate {
			font-size: 16px;
			font-weight: 400;
			transition: font-size $breakTransition;

			@media #{$break1024} {
				font-size: 14px;
			}
		}

		.deTime {
			font-size: 14px;
			font-weight: 300;
			transition: font-size $breakTransition;

			@media #{$break1024} {
				font-size: 12px;
			}
		}
	}
}


/////////////////
// LinkElement //
/////////////////


// Titan colors
$linkElementHoverColor: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$linkElementIconWidth: 22px;
$linkElementIconWidthMobile: 18px;


.LinkElement {
	
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.linkElementWrapper {

		a.leLink {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			&:hover {

				.leIconWrapper {
					.leIcon {
						svg {
							fill: $linkElementHoverColor;
						}
					}
				}
			}

			.leIconWrapper {


				.leIcon {
					position: absolute;
					top: ($adminTableBodyRowHeight - $linkElementIconWidth) / 2;
					left: calc(50% - #{$linkElementIconWidth}/2);
					width: $linkElementIconWidth;
					height: $linkElementIconWidth;
					transition: top $breakTransition, left $breakTransition, width $breakTransition, height $breakTransition;

					@media #{$break1024} {
						top: ($adminTableBodyRowHeightMobile - $linkElementIconWidthMobile) / 2;
						left: calc(50% - #{$linkElementIconWidthMobile}/2);
						width: $linkElementIconWidthMobile;
						height: $linkElementIconWidthMobile;
					}

					svg {
						fill: $adminTableBodyColor;
					}
				}
			}
		}
	}
}


///////////////////////////
// SimpleCurrencyElement //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.SimpleCurrencyElement {



	.simpleCurrencyElementWrapper {

		.priceFormatSymbol {
			font-weight: 300;
		}
	}
}


////////////////////
// NumericElement //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.NumericElement {



	.numericElementLiner {

		text-align: right;
	}
}



















