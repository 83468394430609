


@use '../../../../style/import' as *;




/////////////////
// CartSummary //
/////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$cartSummaryCostHeight: 25px;
$cartSummaryCostWidth: 120px;
$cartSummaryMargin: 10px;


.CartSummary {

	.cartSummaryLiner {

		.absent.cartSummaryLine {
			display: none;
		}

		.cartSummaryLine {

			position: relative;
			height: $cartSummaryCostHeight;
			margin: $cartSummaryMargin 0 0 0;
			transition: margin $breakTransition;

			@media #{$break768} {
				margin: 0.5*$cartSummaryMargin 0 0 0;
			}

			.lineLabel {
				margin: 0 $cartSummaryCostWidth 0 0;
				font-size: 20px;
				font-weight: 300;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}
			}

			.lineValue {
				position: absolute;
				top: 0;
				right: 0;
				width: $cartSummaryCostWidth;
				height: 100%;
				overflow: hidden;
				text-align: right;
				font-size: 20px;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}

				.priceFormatSymbol {
					vertical-align: top;
					font-size: 14px;
					font-weight: 300;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 12px;
					}
				}

				.priceFormatFree {
					font-weight: 300;
				}
			}
		}
	}
}