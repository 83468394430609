

@use '../../../../style/import' as *;




//////////////////
// ActionButton //
//////////////////


// Titan colors
$adminBackground: $TITAN_PRIMARY_COLOR;
$adminBackgroundHover: $TITAN_PRIMARY_COLOR_HOVER;
$adminBackgroundColor: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
$storeBackground: $THEME_PRIMARY_COLOR;
$storeBackgroundHover: $THEME_PRIMARY_COLOR_HOVER;
$storeBackgroundColor: $THEME_PRIMARY_COLOR_TEXT;

// General variables
// None yet


.ActionButton {
	

	// Color controls

	&.adminTheme {
		color: $adminBackgroundColor;
		background: $adminBackground;

		&:hover {
			background: $adminBackgroundHover;
		}
	}


	// Size controls

	&.small {
		font-size: 12px;
		font-weight: 400;
		padding: $MARGIN_S 1.5*$MARGIN;
	}

	cursor: pointer;
	border: none;
	color: $storeBackgroundColor;
	background: $storeBackground;
	padding: $MARGIN $MARGIN_L;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	transition: background $hoverTransition;

	&:hover {
		background: $storeBackgroundHover;
	}

	&:disabled {
		cursor: default;
	}
}













