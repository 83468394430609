

@use '../../../../../style/import' as *;

@use '../../../Admin/_styles/admin-constants' as *;




/////////////////////////
// CategoryDetailsEdit //
/////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$formInputMaxWidth: $ADMIN_FORM_INPUT_WIDTH;


.CategoryDetailsEdit {

	.formInputWrapper {
		margin-right: -1*$MARGIN;
		max-width: $formInputMaxWidth;
	}
}













