

@use '../../../../style/import' as *;


// Titan colors
$radioTitanBorderColor: $TITAN_TEXT_COLOR;
$radioTitanActiveBackgroundColor: $TITAN_PRIMARY_COLOR;
$radioTitanActiveColor: $TITAN_BACKGROUND;

// Theme colors
$radioBorderColor: $THEME_TEXT_COLOR;
$radioActiveBackgroundColor: $THEME_PRIMARY_COLOR;
$radioActiveColor: $THEME_BACKGROUND;

// General variables
$radioBorderWidth: 1px;
$radioDisabledColor: $disabledColor;
$radioDisabledBackground: $disabledBackground;

// Component variables
// None yet


.Radio.error {

  .radio__control {
    border-color: $errorRed !important;
  }

  .radio__input {
    input {
      &:checked + .radio__control {
        border-color: $errorRed !important;
      }
    }
  }
}

.Radio.titanTheme {

  .radio__control {
    border-color: $radioTitanBorderColor;
    background: $radioTitanActiveColor;
  }

  .radio__input {
    input {
      &:checked + .radio__control {
        border-color: $radioTitanActiveBackgroundColor;
        background: $radioTitanActiveBackgroundColor;
      }
    }
  }
}

.Radio {
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .radio__control {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2*#{$radioBorderWidth});
    height: calc(100% - 2*#{$radioBorderWidth});
    border: $radioBorderWidth solid $radioBorderColor;
    border-radius: 50%;
    background: $radioActiveColor;
    transition: background $toggleTransition, border $toggleTransition;
  }

  .radio__input {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;

      &:focus + .radio__control {
        // No focus styles
      }

      &:checked + .radio__control {
        border-color: $radioActiveBackgroundColor;
        background: $radioActiveBackgroundColor;
      }

      &:disabled + .radio__control {
        cursor: default;
        border-color: $radioDisabledColor;
        background: $radioDisabledBackground;
      }

      &:disabled:checked + .radio__control {
        border-color: $radioDisabledColor;
        background: $radioDisabledColor;
      }
    }
  }
}