

// Constants for analytics blocks


$analyticsHeaderHeight: 40px;
$analyticsHeaderFontSize: 20px;
$analyticsHeaderFontSizeSmall: 15px;
$analyticsHeaderFontWeight: 700;


