

@use '../../../../../../style/import' as *;

@use '../../../_styles/admin-constants' as *;




//////////////////////
// AdminPageSection //
//////////////////////


// Titan colors
$backgroundColor: $TITAN_BACKGROUND;
$primaryColor: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$bodyMinHeight: 80px;
$iconWidth: 60px;
$loadingOpacity: 0.6;
$sectionMaxHeight: 2000px;
$sectionWidth: $ADMIN_SECTION_WIDTH;
$toggleWidth: 30px;


.AdminPageSection {

	position: relative;
	background: $backgroundColor;
	max-width: $sectionWidth;
	
	margin: 0 auto 3*$MARGIN 0;

	.sectionLiner {
		padding: $MARGIN;

		.sectionHeader {

			position: relative;
			margin: 0 0 $MARGIN_L 0;

			.titleWrapper {
				font-size: 22px;
				font-weight: 700;
				text-transform: uppercase;
				padding: 0 5*$MARGIN $MARGIN_S 0;
				border-bottom: 2px solid $primaryColor;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 18px;
				}

				.titleValue {
					display: inline-block;
					vertical-align: bottom;
				}

				.titleSupplement {
					display: inline-block;
					vertical-align: bottom;
					font-size: 20px;
					font-weight: 300;
					margin: 0 0 0 $MARGIN;
				}
			}

			.toggleWrapper {
				position: absolute;
				top: -1*$MARGIN_S;
				right: 0;
				width: $toggleWidth;
				height: $toggleWidth;
				cursor: pointer;
				z-index: 1;

				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 36px;
				font-weight: 100;
				user-select: none;
			}
		}

		.sectionBody {

			&.collapsible {

				overflow: hidden;
				min-height: 0;
				max-height: 0;
				transition: max-height $slideFastTransition, min-height $slideFastTransition;

				&.open {
					min-height: $bodyMinHeight;
					max-height: $sectionMaxHeight;
					transition: max-height $slideTransition, min-height $slideTransition;
				}
			}

			position: relative;
			height: auto;
			min-height: $bodyMinHeight;

			.contentWrapper {
				margin: $MARGIN_S 0 0 0;
			}

			.sectionLoading {

				&.active {
					display: block;
				}

				display: none;

				.screen {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $backgroundColor;
					opacity: $loadingOpacity;
				}

				.iconWrapper {
					position: absolute;
					top: calc(50% - #{0.5*$iconWidth});
					left: calc(50% - #{0.5*$iconWidth});
					width: $iconWidth;
					height: $iconWidth;

					svg {
						fill: $primaryColor;
					}
				}
			}

			// .adminSectionBodyWrapper {

			// 	margin: 0.5*$adminFormSpacing 0 0 0;

			// 	.adminViewWrapper {

			// 		text-align: left;
			// 		margin: 2*$adminFormSpacing 0 0 0;

			// 		.adminViewItem.flexHeight {
			// 			height: auto;
			// 		}

			// 		.adminViewItem.tallItem {
			// 			height: $adminViewItemHeightTall;
			// 		}

			// 		.adminViewItem.hidden {
			// 			height: 0;
			// 			margin: 0;
			// 		}

			// 		.adminViewItem.adminDescriptionRow {
			// 			width: calc(100% - 2*#{$adminFormSpacing});
			// 		}

			// 		.adminViewItem {

			// 			position: relative;
			// 			display: inline-block;
			// 			vertical-align: top;
			// 			width: calc(50% - 2*#{$adminFormSpacing});
			// 			height: $adminViewItemHeight;
			// 			margin: 0 $adminFormSpacing $adminFormSpacing $adminFormSpacing;
			// 			transition: width $breakTransition;

			// 			@media #{$break600} {
			// 				display: block;
			// 				width: auto;
			// 				margin: 0 $adminFormSpacing $adminFormSpacing $adminFormSpacing;
			// 			}

			// 			.adminViewLabel.adminRequired {

			// 				&:after {
			// 					content: '*';
			// 					color: $adminFormAccent;
			// 					font-size: 16px;
			// 				}
			// 			}

			// 			.adminViewLabel {
			// 				height: $adminViewItemLabelHeight;
			// 				line-height: $adminViewItemLabelHeight;
			// 				font-size: 15px;
			// 				font-weight: 700;
			// 				text-transform: uppercase;
			// 			}

			// 			.adminViewValue.nullValue {
			// 				font-style: italic;
			// 			}

			// 			.adminViewValue {
			// 				font-size: 18px;
			// 				font-weight: 300;

			// 				.adminViewValueRow {
			// 					margin: 0.5*$adminFormSpacing 0 0 0;
			// 				}

			// 				.adminViewValueDate {

			// 				}

			// 				.adminViewValueTime {
			// 					font-size: 14px;
			// 				}

			// 				.adminViewColorSwatch {
			// 					display: inline-block;
			// 					vertical-align: middle;
			// 					width: 2*$adminFormSpacing;
			// 					height: 2*$adminFormSpacing;
			// 					border: 1px solid $adminFormBorderColor;
			// 					border-radius: 50%;
			// 				}

			// 				.adminViewColorValue {
			// 					display: inline-block;
			// 					vertical-align: middle;
			// 					margin: 0 0 0 $adminFormSpacing;
			// 					line-height: 2*$adminFormSpacing;
			// 				}

			// 				.interactionWrapper {

			// 					.interactionValue {
			// 						display: inline-block;
			// 						vertical-align: baseline;
			// 						width: auto;
			// 						margin: 0 $adminFormSpacing 0 0;
			// 					}

			// 					.interactionAction {
			// 						display: inline-block;
			// 						vertical-align: baseline;
			// 						width: auto;
			// 						font-size: 14px;
			// 						font-weight: 700;
			// 						cursor: pointer;
			// 						color: $adminFormAccent;
			// 						transition: color $hoverTransition;

			// 						&:hover {
			// 							color: $adminFormAccentHover;
			// 						}
			// 					}
			// 				}
			// 			}
			// 		}

			// 		.adminViewHr {
			// 			margin: $adminFormSpacing auto 3*$adminFormSpacing auto;
			// 			width: 80%;
			// 			height: 2px;
			// 			background: $adminFormAccent;
			// 		}

			// 		.adminViewVr {
			// 			position: absolute;
			// 			top: 10%;
			// 			right: 0;
			// 			width: 2px;
			// 			height: 80%;
			// 			background: $adminViewVrColor;

			// 			@media #{$break1024} {
			// 				top: unset;
			// 				bottom: 0;
			// 				right: 10%;
			// 				width: 80%;
			// 				height: 2px;
			// 			}
			// 		}

			// 		.adminViewButtonWrapper {
			// 			text-align: right;
			// 		}
			// 	}
			// }
		}

			

		// .adminSectionDisabled {
		// 	color: $adminDisabledColor;
		// 	text-align: center;
		// 	margin: 2*$adminFormSpacing 0;
		// 	font-size: 20px;
		// 	font-weight: 700;
		// }

		// .adminSectionPending {

		// 	.adminSectionPendingScreen {
		
		// 	}

		// 	.adminSectionPendingWrapper {
		// 		
		// 	}
		// }


		// .adminSectionBody {

		// }
	}
}













