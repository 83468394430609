
@use '../../../../style/import' as *;


$appAccountMenuBackground: $THEME_LEFT_MENU_CONTROL_BACKGROUND;
$appAccountComponentBackground: $THEME_BACKGROUND_CONTRAST;

$appAdminMenuBackground: $TITAN_ADMIN_CONTROL_BACKGROUND;
$appAdminComponentBackground: $TITAN_ADMIN_PAGE_BACKGROUND;

$appAccountMenuWidth: 300px;
$appAccountMenuWidthTablet: 90px;
$appAccountMenuWidthMobile: 50px;

$appAdminMenuWidth: 300px;
$appAdminMenuWidthTablet: 90px;
$appAdminMenuWidthMobile: 50px;


.App {

	.appWrapper {

		.adminWrapper {

			position: relative;

			&:before {
				content: " ";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: $appAdminMenuWidth;
				height: 100%;
				background: $appAdminMenuBackground;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: $appAdminMenuWidthTablet;
				}

				@media #{$break600} {
					width: $appAdminMenuWidthMobile;
				}
			}

			&:after {
				content: " ";
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				width: calc(100% - #{$appAdminMenuWidth});
				height: 100%;
				background: $appAdminComponentBackground;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: calc(100% - #{$appAdminMenuWidthTablet});
				}

				@media #{$break600} {
					width: calc(100% - #{$appAdminMenuWidthMobile});
				}
			}

			.adminWrapperMenu {

				position: relative;
				overflow: auto;

				display: inline-block;
				vertical-align: top;

				width: $appAdminMenuWidth;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: $appAdminMenuWidthTablet;
				}

				@media #{$break600} {
					width: $appAdminMenuWidthMobile;
				}
			}

			.adminWrapperComponent {

				position: relative;

				display: inline-block;
				vertical-align: top;

				width: calc(100% - #{$appAdminMenuWidth});
				transition: width $breakTransition;

				@media #{$break1200} {
					width: calc(100% - #{$appAdminMenuWidthTablet});
				}

				@media #{$break600} {
					width: calc(100% - #{$appAdminMenuWidthMobile});
				}
			}
		}

		.accountWrapper {

			position: relative;

			&:before {
				content: " ";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: $appAccountMenuWidth;
				height: 100%;
				background: $appAccountMenuBackground;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: $appAccountMenuWidthTablet;
				}

				@media #{$break600} {
					width: $appAccountMenuWidthMobile;
				}
			}

			&:after {
				content: " ";
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				width: calc(100% - #{$appAccountMenuWidth});
				height: 100%;
				background: $appAccountComponentBackground;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: calc(100% - #{$appAccountMenuWidthTablet});
				}

				@media #{$break600} {
					width: calc(100% - #{$appAccountMenuWidthMobile});
				}
			}

			.accountWrapperMenu {

				position: relative;
				overflow: auto;

				display: inline-block;
				vertical-align: top;

				width: $appAccountMenuWidth;
				transition: width $breakTransition;

				@media #{$break1200} {
					width: $appAccountMenuWidthTablet;
				}

				@media #{$break600} {
					width: $appAccountMenuWidthMobile;
				}
			}

			.accountWrapperComponent {

				position: relative;

				display: inline-block;
				vertical-align: top;

				width: calc(100% - #{$appAccountMenuWidth});
				transition: width $breakTransition;

				@media #{$break1200} {
					width: calc(100% - #{$appAccountMenuWidthTablet});
				}

				@media #{$break600} {
					width: calc(100% - #{$appAccountMenuWidthMobile});
				}
			}
		}
	}
}