

@use '../../../../style/import' as *;




////////////////
// TextButton //
////////////////


// Titan colors
$adminPrimaryColor: $TITAN_PRIMARY_COLOR;
$adminTextColor: $TITAN_TEXT_COLOR;

// Theme colors
$storePrimaryColor: $THEME_PRIMARY_COLOR;
$storeTextColor: $THEME_TEXT_COLOR;

// General variables
$hoverOpacity: 0.2;


.TextButton {
	

	// Color controls

	&.adminTheme {

		&.primary {
			color: $adminPrimaryColor;
			background: rgba($adminPrimaryColor, 0.0);

			&:hover {
				background: rgba($adminPrimaryColor, $hoverOpacity);
			}
		}

		color: $adminTextColor;
		background: rgba($adminTextColor, 0.0);

		&:hover {
			background: rgba($adminTextColor, $hoverOpacity);
		}
	}

	&.primary {
		color: $storePrimaryColor;
		background: rgba($storePrimaryColor, 0.0);

		&:hover {
			background: rgba($storePrimaryColor, $hoverOpacity);
		}
	}


	// Size controls

	&.small {
		font-size: 12px;
		font-weight: 400;
		padding: $MARGIN_S 1.5*$MARGIN;
	}

	cursor: pointer;
	border: none;
	color: $storeTextColor;
	background: rgba($storeTextColor, 0.0);
	padding: $MARGIN $MARGIN_L;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	transition: background $hoverTransition;

	&:hover {
		background: rgba($storeTextColor, $hoverOpacity);
	}

	&:disabled {
		cursor: default;
	}
}













