

@use '../../../../style/import' as *;




//////////////////////////
// CreateShipmentStamps //
//////////////////////////


// Titan colors
$icAccent: $TITAN_PRIMARY_COLOR;
$icAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$icAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$icDisabled: $disabledColor;
$icBackground: $TITAN_BACKGROUND;
$icBackgroundAlt: $TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE;
$icRowSelected: $TITAN_PRIMARY_ULTRALIGHT;
$icErrorColor: $errorRed;
$icInputBorderColor: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$icLoadingHeight: 40px;
$icMargin: 10px;
$icRadioWidth: 18px;
$icRadioWidthMobile: 16px;
$icToggleIconWidth: 14px;
$icOptionsMaxHeight: 260px;
$icOptionExtensionMaxHeight: 100px;
$icRatesMaxHeight: 300px;
$icRatesMinHeight: 100px;
$icRateRowRadioWidth: 30px;
$icRateRowPriceWidth: 100px;
$icLoadingBackgroundTransparency: 0.8;


.CreateShipmentStamps {

	.omvWrapper{

		.shipmentCreateFormStamps.orderModalForm {

			.omFormError {
				text-align: center;
			}

			.optionsWrapper {

				.open.optionsTitleWrapper {

					.optionsTitleIcon {
						transform: rotate(0);
						transition: transform $slideTransition;
					}
				}

				.optionsTitleWrapper {

					display: inline-block;
					vertical-align: top;
					cursor: pointer;

					.optionsTitleIcon {
						display: inline-block;
						vertical-align: top;
						width: $icToggleIconWidth;
						height: $icToggleIconWidth + 2px;
						transform: rotate(-90deg);
						transition: transform $slideFastTransition;
					}

					.optionsTitleValue {
						display: inline-block;
						vertical-align: top;
						height: $icToggleIconWidth;
						font-size: $icToggleIconWidth + 2px;
						font-weight: 400;
						margin: 0 0 0 $icMargin/2;
					}
				}

				.open.optionsBodyWrapper {
					max-height: $icOptionsMaxHeight;
					transition: max-height $slideTransition;
				}

				.optionsBodyWrapper {

					overflow: hidden;
					max-height: 0;
					transition: max-height $slideFastTransition;

					.optionLineWrapper {

						cursor: pointer;
						margin: $icMargin/2 0 $icMargin 0;

						.optionInputWrapper {

							position: relative;
							display: inline-block;
							vertical-align: top;
							width: $icRadioWidth;
							height: $icRadioWidth;
							margin: $icMargin/4 0 0 0;
							transition: width $breakTransition, height $breakTransition;

							@media #{$break600} {
								width: $icRadioWidthMobile;
								height: $icRadioWidthMobile;
							}

							.cbInput {

							}
						}

						.optionLabelWrapper {

							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$icRadioWidth} - #{$icMargin});
							margin: 0 0 0 $icMargin;
							font-size: 16px;
							height: $icRadioWidth + 2px;
							line-height: $icRadioWidth + 2px;
							transition: width $breakTransition, margin $breakTransition, font-size $breakTransition;

							@media #{$break600} {
								font-size: 14px;
								width: calc(100% - #{$icRadioWidthMobile} - #{$icMargin});
								height: $icRadioWidthMobile + 2px;
								line-height: $icRadioWidthMobile + 2px;
								margin: $icMargin/8 0 0 $icMargin/2;
							}

							.optionValue {

							}
						}
					}

					.open.optionLineExtension {
						max-height: $icOptionExtensionMaxHeight;
						transition: max-height $slideTransition;
					}

					.optionLineExtension {
						overflow: hidden;
						height: auto;
						max-height: 0;
						transition: max-height $slideFastTransition;

						.extensionLiner {
							margin-top: $icMargin/2;
							margin-bottom: $icMargin/2;
							margin-left: $icRadioWidth + $icMargin;
							margin-right: 0;

							.extensionInputWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: 50%;
								transition: width $breakTransition, margin $breakTransition;

								@media #{$break768} {
									width: 100%;
									margin: 0 0 $icMargin 0;
								}

								.extensionInputLabel {
									font-size: 13px;
									font-weight: 400;

									.labelLink {
										font-weight: 400;
										cursor: pointer;
										margin: 0 0 0 0.5*$icMargin;
										color: $icAccent;
										transition: color $hoverTransition;

										&:hover {
											color: $icAccentHover;
										}
									}
								}

								.extensionInputElementWrapper {

									position: relative;

									.error.extensionInput {
										border-color: $icErrorColor;
									}

									.extensionInput {
										border-width: 1px;
										border-style: solid;
										border-color: $icInputBorderColor;
										padding: 0.5*$icMargin 1.5*$icMargin;
										width: calc(100% - #{4*$icMargin} - 2px);
										text-align: right;
									}

									.currencyIndication {
										position: absolute;
										top: 0;
										left: 0;
										width: 2*$icMargin;
										bottom: 0;
										font-size: 14px;
										font-weight: 300;
										user-select: none;
										pointer-events: none;
										text-align: center;
									}
								}

								.extensionError {
									margin: 0.5*$icMargin 0;
									color: $icErrorColor;
									font-size: 14px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}

			.ratesBlockWrapper {

				.ratesBlock {

					margin: $icMargin 0 0 0;

					.ratesHeaderWrapper {

						.ratesHeader {

							margin: 0 0 $icMargin/2 0;
							text-align: right;

							.costLabel {
								display: inline-block;
								vertical-align: middle;
								font-size: 16px;
								font-weight: 400;
								margin: 0 $icMargin/2 0 0;

								&:after {
									content: ':';
								}
							}

							.costValue {
								display: inline-block;
								vertical-align: middle;
								font-size: 18px;
								font-weight: 700;
							}
						}
					}
					
					.ratesBodyWrapper {

						position: relative;

						.ratesBodyNull {
							height: $icRatesMinHeight;
						}
						
						.ratesBody {
							
							overflow: auto;
							max-height: $icRatesMaxHeight;

							.ratesBodyLiner {

								.ratesNoResults {
									margin: 3*$icMargin 2*$icMargin;
									text-align: center;
									font-size: 20px;
									font-weight: 300;
								}

								.ratesList {

									.selected.rateElement {
										background: $icRowSelected;

										&:nth-child(odd) {
											background: $icRowSelected;
										}
									}

									.rateElement {

										overflow: auto;
										background: $icBackground;

										&:nth-child(odd) {
											background: $icBackgroundAlt;
										}

										.rateElementLiner {

											margin: $icMargin/2 0;

											.rateSelectionWrapper {
												display: inline-block;
												vertical-align: top;
												width: $icRateRowRadioWidth;
												text-align: center;

												.rateRadioWrapper {
													position: relative;
													display: inline-block;
													vertical-align: top;
													width: $icRadioWidth;
													height: $icRadioWidth;
													margin: $icMargin/8 0 0 0;
												}
											}

											.rateDescriptionWrapper {
												display: inline-block;
												vertical-align: top;
												width: calc(100% - #{$icRateRowRadioWidth} - #{$icRateRowPriceWidth});

												.rateTitleWrapper {
													font-size: 18px;
												}

												.rateDurationWrapper {

													.rateDurationLabel {
														display: inline-block;
														vertical-align: middle;
														font-size: 14px;
														font-weight: 400;

														&:after {
															content: ':';
														}
													}

													.rateDurationValue {
														display: inline-block;
														vertical-align: middle;
														font-size: 14px;
														font-weight: 700;
														margin: 0 0 0 $icMargin/2;
													}
												}

												.rateTrackingWrapper {
													
													.rateTrackingValue {
														font-size: 12px;
														font-weight: 300;
													}
												}
											}

											.ratePriceWrapper {
												display: inline-block;
												vertical-align: top;
												width: $icRateRowPriceWidth;
												text-align: right;

												.over.ratePrice {
													color: $icErrorColor;
												}

												.ratePrice {
													margin: 0 $icMargin 0 0;
													font-size: 18px;
													font-weight: 400;

													.priceFormatSymbol {
														font-weight: 300;
													}
												}
											}
										}
									}
								}
							}
						}

						.ratesLoadingScreen {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba($icBackground, $icLoadingBackgroundTransparency);
						
							.ratesLoadingIconWrapper {
								position: absolute;
								top: calc(50% - #{$icLoadingHeight/2});
								left: calc(50% - #{$icLoadingHeight/2});
								width: $icLoadingHeight;
								height: $icLoadingHeight;

								svg {
									fill: $icAccent;
								}
							}
						}
					}
				}
			}
		}
	}
}































