

@use '../../../../style/import' as *;


$menuScreenColor: $TITAN_SCREEN_COLOR;
$menuScreenOpacity: $TITAN_SCREEN_OPACITY;

.MenuScreen {

	display: none;
    opacity: 0;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $menuScreenColor;
	z-index: $zMenuScreen;
	transition: opacity $toggleTransition;
}

.withOp.MenuScreen {
	opacity: $menuScreenOpacity;
}

.disp.MenuScreen {
	display: block;
}