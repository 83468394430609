

@use '../../../../style/import' as *;




//////////////////////////////
// TransbankPaymentComplete //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
$transbankAccent: $THEME_PRIMARY_COLOR;
$transbankAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$transbankAccentText: $THEME_PRIMARY_COLOR_TEXT;
$transbankColor: $THEME_TEXT_COLOR;
$transbankBackground: $THEME_BACKGROUND;

// General variables
$transbankBlockWidth: 300px;
$transbankIconWidth: 60px;
$transbankMargin: 10px;
$transbankMinHeight: 600px;
$transbankScreenOpacity: 0.7;


.TransbankPaymentComplete {

	min-height: $transbankMinHeight;

	.noNavScreen {

		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba($transbankBackground, $transbankScreenOpacity);
		z-index: $zAlert;

		.block {
			position: absolute;
			top: calc(50% - #{$transbankBlockWidth/2});
			left: calc(50% - #{$transbankBlockWidth/2});
			width: $transbankBlockWidth;
			height: auto;

			.blockLiner {

			}
		}

		.loadingBlock {

			.blockLiner {

				text-align: center;

				.loadingIconWrapper {
					margin: $transbankMargin auto;
					width: $transbankIconWidth;

					svg {
						fill: $transbankAccent;
					}
				}

				.loadingLabel {
					font-size: 20px;
					margin: 0 0 $transbankMargin 0;
				}

				.loadingCopy {
					font-size: 16px;
					font-weight: 300;
					margin: 0 0 $transbankMargin 0;
				}
			}
		}

		.errorBlock {
			
			.errorCopy {
				font-size: 18px;
				margin: 0 0 $transbankMargin 0;
			}

			.errorLabel {
				font-size: 14px;
				font-weight: 700;

				&:after {
					content: ':';
				}
			}

			.errorBody {
				font-size: 14px;
				font-weight: 300;
				margin: 0 0 2*$transbankMargin 0;
			}

			.errorActions {

				text-align: center;

				.errorAction {
					display: inline-block;
					vertical-align: top;
					margin: 0 $transbankMargin/2 $transbankMargin $transbankMargin/2;
					background: $transbankAccent;
					color: $transbankAccentText;
					padding: $transbankMargin 2*$transbankMargin;
				}
			}
		}
	}
}





