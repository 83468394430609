

@use '../../../../style/import' as *;


$addProductLineColor: $TITAN_TEXT_COLOR;
$addProductLineSpacing: 10px; /* Same as adminFormSpacing */

$addProductLineSyncBackground: $TITAN_BACKGROUND;
$addProductLineSyncBackgroundDisabled: $disabledBackground;
$addProductLineSyncBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$addProductLineSyncBorder: $TITAN_TEXT_COLOR;
$addProductLineSyncColorDisabled: $disabledColor;
$addProductLineSyncHeight: 45px;
$addProductLineSyncSpacing: 5px;

$addProductLineOptionHeight: 30px;
$addProductLineOptionSpacing: 3px;

$addProductLineSyncLabelHeightPercent: 0.60;



.AddProductLine, .EditProductLine {



	.adminBody {



		.adminForm {



			.addProductLineForm {



				.adminFieldWrapper {



					.adminInputWrapper {

						.syncSelect.InputError {
							border-color: $errorRed;

							.ssBody {
								.ssOptionsWrapper {
									border-color: $errorRed;
								}
							}
						}

						.syncSelect.disabled {

							cursor: default;
							border-color: $addProductLineSyncColorDisabled;
							background: $addProductLineSyncBackgroundDisabled;
							color: $addProductLineSyncColorDisabled;

							.ssHead {

								.ssHeadArrow {
									.ddArrow {
										border-top-color: $addProductLineSyncColorDisabled;
									}
								}
							}
						}

						.syncSelect {

							position: relative;
							cursor: pointer;
							border: 1px solid $addProductLineSyncBorder;
							width: calc(100% - 2px);
							height: $addProductLineSyncHeight - 2px;
							transition: border $toggleTransition;

							.ssHead {

								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;

								.placeholder.ssHeadLabel {
									color: $placeholderGrey;
								}

								.ssHeadLabel {
									font-size: 20px;
					    			padding: $addProductLineSpacing 2*$addProductLineSpacing;
								}

								.ssHeadArrow {
									position: absolute;
									top: 1.5*$addProductLineSpacing;
									right: 2*$addProductLineSpacing;
									width: 2*$addProductLineSpacing;
									bottom: 1.5*$addProductLineSpacing;

									.ddArrow {
										border-top: ($addProductLineSyncHeight - 3*$addProductLineSpacing) solid $addProductLineColor;
										border-bottom: none;
										border-left: $addProductLineSpacing solid transparent;
										border-right: $addProductLineSpacing solid transparent;
									}
								}
							}

							.ssBody.open {
								max-height: 250px;
							}

							.ssBody {

								position: absolute;
								top: 100%;
								left: -1px;
								right: -1px;
								height: auto;
								z-index: $zUserMenuLayer;

								overflow: hidden;
								max-height: 0;
								transition: max-height $slideTransition;


								.ssOptionsWrapper {

										background: $addProductLineSyncBackground;
										overflow-y: auto;
										border-left: 1px solid $addProductLineSyncBorder;
										border-right: 1px solid $addProductLineSyncBorder;
										border-bottom: 1px solid $addProductLineSyncBorder;

										.ssOption.disabled {
											color: $disabledColor;
											/*background: $disabledBackground;*/
											cursor: not-allowed;

											&:hover {
												background: $disabledBackground;
											}
										}

										.ssOption {

											position: relative;
											height: $addProductLineOptionHeight;
											transition: background $hoverTransition;

											&:hover {
												background: $addProductLineSyncBackgroundHover;
											}

											.ssOptionCheck {
												position: absolute;
												top: 0;
												left: 0;
												width: $addProductLineOptionHeight;
												height: $addProductLineOptionHeight;

												.checkWrapper {
													position: absolute;
													top: $addProductLineOptionSpacing;
													left: $addProductLineOptionSpacing;
													right: $addProductLineOptionSpacing;
													bottom: $addProductLineOptionSpacing;

													display: flex;
													justify-content: center;
													align-items: center;
													font-size: 22px;
												}
											}

											.ssOptionLabel {

												position: absolute;
												top: $addProductLineOptionSpacing;
												left: $addProductLineOptionHeight + $addProductLineOptionSpacing;
												right: $addProductLineOptionSpacing;
												height: $addProductLineSyncLabelHeightPercent * ($addProductLineOptionHeight - 2*$addProductLineOptionSpacing);
												line-height: $addProductLineSyncLabelHeightPercent * ($addProductLineOptionHeight - 2*$addProductLineOptionSpacing);

												font-size: 14px;
												font-weight: 400;
											}

											.ssOptionDescription {

												position: absolute;
												bottom: $addProductLineOptionSpacing;
												left: $addProductLineOptionHeight + $addProductLineOptionSpacing;
												right: $addProductLineOptionSpacing;
												height: (1 - $addProductLineSyncLabelHeightPercent) * ($addProductLineOptionHeight - 2*$addProductLineOptionSpacing);
												line-height: (1 - $addProductLineSyncLabelHeightPercent) * ($addProductLineOptionHeight - 2*$addProductLineOptionSpacing);

												font-size: 10px;
												font-weight: 300;
											}
										}
									}
							}
						}
					}
				}
			}
		}
	}
}

