

@use '../../../../style/import' as *;




/////////////////////////////////
// BlockElementVerticalProduct //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
$tagBackground: $THEME_PRIMARY_COLOR;
$tagColor: $THEME_PRIMARY_COLOR_TEXT;

// General variables
$verticalImgRatio: 140.0%;
$verticalTagHeight: 18px;


.BlockElementVerticalProduct {

	.productWrapper {

		.productLiner {

			.productImgWrapper {

				position: relative;
				padding-bottom: $verticalImgRatio;

				.productImg {

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
			}

			.productTitle {
				font-size: 15px;
				font-weight: 700;
			}

			.productLineWrapper {
				font-size: 14px;
				font-weight: 300;
			}

			.productTitleSupplement {
				font-size: 12px;
				font-weight: 300;
			}

			.productTagWrapper {

				.productTag {

					position: relative;
					display: inline-block;
					background: $tagBackground;
					max-width: 100%;
					height: auto;
					min-height: $verticalTagHeight;
					margin: 0 $MARGIN_S 0 0;
					border-radius: 0.5*$verticalTagHeight;

					.productTagValue {
						color: $tagColor;
						text-align: center;
						font-size: 0.6*$verticalTagHeight;
						line-height: 0.6*$verticalTagHeight;
						padding: 0.2*$verticalTagHeight 0.5*$verticalTagHeight;
					}
				}
			}

			.priceWrapper {
				margin: $MARGIN_S 0 0 0;
				font-size: 14px;
				font-weight: 400;

				.priceFormatSymbol {
					font-weight: 300;
				}
			}
		}
	}
}
















