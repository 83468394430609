

@use '../../../../style/import' as *;



// Theme colors

$headerColor: $THEME_HEADER_COLOR;
$headerColorHover: $THEME_HEADER_COLOR_HOVER;
$headerAccentColor: $THEME_PRIMARY_COLOR;
$headerAccentColorHover: $THEME_PRIMARY_COLOR_HOVER;
$headerAccentColorText: $THEME_PRIMARY_COLOR_TEXT;
$headerTextColor: $THEME_TEXT_COLOR;
$headerTextColorHover: $THEME_TEXT_COLOR_HOVER;
$headerBackground: $THEME_HEADER_BACKGROUND;
$headerBackgroundHover: $THEME_HEADER_BACKGROUND_HOVER;

$headerMenuDropdownTitleBackground: $THEME_BACKGROUND_HOVER;

// General variables

$headerMargin: $MARGIN_L;
$headerMarginMobile: $MARGIN;

$headerHeight: $TITAN_HEADER_HEIGHT;
$headerHeightMobile: $TITAN_HEADER_HEIGHT_MOBILE;
$headerNoticeHeight: $TITAN_HEADER_NOTICE_HEIGHT;
$headerNoticeHeightMobile: $TITAN_HEADER_NOTICE_HEIGHT_MOBILE;

$headerLogoAr: $THEME_HEADER_LOGO_AR;

$headerLogoHeight: 60px;
$headerLogoHeightMobile: 34px;
$headerLogoWidth: $headerLogoAr * $headerLogoHeight;
$headerLogoWidthMobile: $headerLogoAr * $headerLogoHeightMobile;

$headerMenuWidth: 350px;
$headerMenuHeight: 20px;

$headerMenuDropdownWidth: 320px;
$headerMenuDropdownHeight: 400px;
$headerMenuDropdownFooterHeight: 5px;

$headerSearchHeight: 40px;

$headerAccountWidth: 180px;
$headerAccountHeight: 40px;

$headerCartWidth: 40px;
$headerCartHeight: 40px;
$headerCartBubbleOffset: 5px;

$headerIconWidth: 40px;
$headerIconWidthMobile: 30px;


// Full menu variables

$headerFullMenuWidth: 600px;

$headerFullMenuHeaderHeight: 125px;
$headerFullMenuHeaderHeightMobile: 120px;
$headerFullMenuFooterHeight: 60px;
$headerFullMenuFooterHeightMobile: 40px;

$headerFullMenuHorizontalPadding: 25px;
$headerFullMenuHorizontalPaddingMobile: 10px;

$headerFullMenuFooterIconWidth: 50px;
$headerFullMenuFooterActionWidth: 160px;
$headerFullMenuFooterActionHeight: 50px;
$headerFullMenuFooterHelloHeight: 50px;

