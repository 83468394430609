

@use '../../../../style/import' as *;




///////////////////////
//   Cart   //
///////////////////////


// Titan colors
// None yet

// Theme colors
$cartAccent: $THEME_PRIMARY_COLOR;
$cartAccentColor: $THEME_PRIMARY_COLOR_TEXT;
$cartAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$cartBackground: $THEME_BACKGROUND;
$cartColor: $THEME_TEXT_COLOR;

// General variables
$cartButtonHeight: 50px;
$cartLoadingHeight: 60px;
$cartLoadingIconColor: $cartAccent;
$cartLoadingScreenOpacity: 0.7;
$cartMargin: 10px;
$cartMaxWidth: 800px;
$cartMinHeight: 100px;
$cartPriceWidth: 80px;


.Cart {

	color: $cartColor;
	width: 90%;
	max-width: $cartMaxWidth;
	margin: $cartMargin auto 2*$cartMargin auto;

	.cartLiner {

		position: relative;

		.cartBody {

			min-height: $cartMinHeight;

			.cartTitle {
				font-family: $fontLato;
				font-weight: 700;
				font-size: 24px;
				margin: 0 0 $cartMargin 0;
			}

			.cartBodyWrapper {

			}

			.cartFooter {
				border-top: 2px solid $cartAccent;
				margin: 0 0 0 50%;
				padding: $cartMargin 0 0 0;
				width: 50%;
				transition: padding $breakTransition, width $breakTransition, margin $breakTransition;

				@media #{$break600} {
					margin: 0 0 0 0%;
					width: 100%;
					padding: $cartMargin/2 0 0 0;
				}

				.cartFooterLiner {

					.cartTotalLine {

						position: relative;
						margin: $cartMargin/2 0;

						.cartTotalLabelWrapper {

							width: calc(100% - #{$cartPriceWidth});

							.cartTotalLabel {
								display: inline-block;
								vertical-align: middle;
								width: auto;
								font-size: 20px;
								font-weight: 400;
							}

							.cartTotalCount {
								display: inline-block;
								vertical-align: middle;
								width: auto;
								margin-left: $cartMargin/4;
								font-size: 16px;
								font-weight: 300;
							}
						}

						.cartTotalPriceWrapper {

							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							width: $cartPriceWidth;

							.cartTotalPrice {

								text-align: right;

								.cartCurrencySymbol {
									display: inline-block;
									vertical-align: top;
									width: auto;
									font-size: 12px;
									font-weight: 300;
								}

								.cartPriceValue {
									display: inline-block;
									vertical-align: top;
									width: auto;
									margin-left: $cartMargin/4;
									font-size: 18px;
									font-weight: 400;
								}
							}
						}
					}

					.cartCheckoutAction {
						position: relative;
						height: $cartButtonHeight;
						background: $cartAccent;
						cursor: pointer;
						margin: $cartMargin 0 0 0;
						transition: background $hoverTransition;

						&:hover {
							background: $cartAccentHover;
						}

						.cartCheckoutActionLabel {
							color: $cartAccentColor;
							font-size: 18px;
							font-weight: 700;
						}
					}
				}
			}
		}

		.cartLoadingWrapper {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($cartBackground, $cartLoadingScreenOpacity);

			.cartIconWrapper {
			
				position: absolute;
				width: $cartLoadingHeight;
				height: $cartLoadingHeight;
				top: calc(40% - #{$cartLoadingHeight/2});
				left: calc(50% - #{$cartLoadingHeight/2});

				svg {
					fill: $cartLoadingIconColor;
				}
			}
		}
	}
}