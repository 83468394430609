

@use '../../../../../../style/import' as *;




///////////////////////
// BlockTitleDefault //
///////////////////////


// Titan colors
// None yet

// Theme colors
$textColor: $THEME_TEXT_COLOR;
$lightTextColor: $THEME_TEXT_COLOR_LIGHT;

// General variables
$titleMaxWidth: 600px;
$subTitleMaxWidth: 768px;
$copyMaxWidth: 768px;


.BlockTitleDefault {

	.blockLiner {

		margin: $MARGIN_XL 0;
		text-align: center;

		.titleWrapper {

			color: $textColor;
			font-size: 32px;
			font-weight: 700;
			text-transform: uppercase;
			width: 90%;
			max-width: $titleMaxWidth;
			margin: 0 auto;
			transition: font-size $breakTransition;

			@media #{$break768} {
				font-size: 24px;
			}
		}

		.subTitleWrapper {

			color: $textColor;
			font-size: 20px;
			font-weight: 400;
			font-style: italic;
			width: 90%;
			max-width: $subTitleMaxWidth;
			margin: $MARGIN auto 0 auto;
			transition: font-size $breakTransition;

			@media #{$break768} {
				font-size: 18px;
			}
		}

		.copyWrapper {

			color: $lightTextColor;
			font-size: 18px;
			font-weight: 300;
			width: 90%;
			max-width: $copyMaxWidth;
			margin: $MARGIN_L auto 0 auto;
			transition: font-size $breakTransition;

			@media #{$break768} {
				font-size: 16px;
			}
		}		
	}
}













