


@use '../../../../style/import' as *;


$productMediaSortableSpacing: 10px;

$productMediaSortableItemWidth: 150px;
$productMediaSortableSectionMinHeight: 80px;
$productMediaSortableItemBorder: $TITAN_ADMIN_PAGE_BACKGROUND;

$productMediaSortableItemIconColor: $TITAN_TEXT_COLOR;
$productMediaSortableItemOverlayBorder: $TITAN_PRIMARY_COLOR;
$productMediaSortableItemOverlayBackground: $TITAN_PRIMARY_ULTRALIGHT;



.ProductMediaSortableGrid {

	min-height: $productMediaSortableSectionMinHeight;

	.noImagesWrapper {
		margin: 3*$productMediaSortableSpacing 0 0 0;
		font-size: 28px;
		font-weight: 300;
		text-align: center;
	}
}

.ProductSortableItem.inMotion {

	cursor: grabbing;

	.psiMediaWrapper {
		.localImageWrapper, .objImageWrapper {
			border: none !important;
		}
		.psiRemoveX {
			display: none;
		}
	}
}

.ProductSortableItem {

	display: inline-block;
	vertical-align: top;
	margin: 0 $productMediaSortableSpacing $productMediaSortableSpacing 0;
	width: $productMediaSortableItemWidth;
	user-select: none;
	cursor: grab;

	&:first-child {
		.psiMediaWrapper {
			.localImageWrapper, .objImageWrapper {
				border: 1px solid $productMediaSortableItemOverlayBorder;
			}
		}
	}
	
	.psiDragOverlay {
		border: 1px solid $productMediaSortableItemOverlayBorder;
		background: $productMediaSortableItemOverlayBackground;
		width: $productMediaSortableItemWidth;
		height: $productMediaSortableItemWidth;
	}

	.psiMediaWrapper {

		position: relative;

		&:hover {
			.psiRemoveX {
				opacity: 1.0;
			}
		}

		.localImageWrapper, .objImageWrapper {
			position: relative;
			display: flex;
			justify-content: center;
			border: 1px solid $productMediaSortableItemBorder;
			height: $productMediaSortableItemWidth - 2px;

			img {
				object-fit: cover;
				object-position: center;
				max-width: 100%;
				max-height: 100%;
				text-align: center;
			}
		}

		.psiMediaLabel {
			height: 2*$productMediaSortableSpacing;
			line-height: 2*$productMediaSortableSpacing;
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			color: $productMediaSortableItemOverlayBorder;
		}

		.psiRemoveX {
			position: absolute;
			top: 1px;
			right: 1px;
			width: 3*$productMediaSortableSpacing;
			height: 3*$productMediaSortableSpacing;

			color: $productMediaSortableItemIconColor;
			background: white;
			font-size: 20px;
			font-weight: 400;
			opacity: 0.0;
			cursor: pointer;
			transition: opacity $hoverTransition, font-size $hoverTransition, font-weight $hoverTransition;

			&:hover {
				color: $productMediaSortableItemOverlayBorder;
				font-size: 32px;
				font-weight: 300;
			}

			div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}