

@use '../../../../style/import' as *;
@import './constants/analytics';




//////////////////////////////
// AnalyticsGraphConfigMenu //
//////////////////////////////


// Titan colors
$salesGraphBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$salesGraphBorderColor: $TITAN_RULE_COLOR;
$salesGraphConfigBackgroundHover: $TITAN_ADMIN_PAGE_BACKGROUND_HOVER;
$salesGraphDisabledColor: $TITAN_TEXT_COLOR_DISABLED;
$salesGraphPrimary: $TITAN_PRIMARY_COLOR;
$salesGraphTextColor: $TITAN_TEXT_COLOR;
$salesGraphTextColorHover: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$salesGraphConfigMenuIconScale: 0.7;
$salesGraphConfigMenuMaxHeight: 600px;
$salesGraphConfigMenuToggleHeight: 30px;
$salesGraphConfigMenuCheckboxHeight: 20px;
$salesGraphConfigMenuWidth: 300px;
$salesGraphConfigTitieBorderWidth: 2px;
$salesGraphMargin: $MARGIN;


.AnalyticsGraphConfigMenu {

	text-align: left;

	.configMenuWrapper {

		.disabled.menuToggleWrapper {

			cursor: default;

			&:hover {
				background: unset;
				.menuIconWrapper {
					svg {
						fill: $salesGraphDisabledColor;
					}
				}
			}

			.menuIconWrapper {

				svg {
					fill: $salesGraphDisabledColor;
				}
			}
		}

		.menuToggleWrapper {

			position: relative;
			cursor: pointer;
			width: $analyticsHeaderHeight;
			height: $analyticsHeaderHeight;
			background: $salesGraphBackground;
			transition: background $hoverTransition;

			&:hover {
				background: $salesGraphConfigBackgroundHover;

				.menuIconWrapper {
					svg {
						fill: $salesGraphTextColorHover;
					}
				}
			}

			.menuIconWrapper {
				position: absolute;
				top: calc(0.5 * (1 - $salesGraphConfigMenuIconScale) * $analyticsHeaderHeight);
				left: calc(0.5 * (1 - $salesGraphConfigMenuIconScale) * $analyticsHeaderHeight);
				width: $salesGraphConfigMenuIconScale * $analyticsHeaderHeight;
				height: $salesGraphConfigMenuIconScale * $analyticsHeaderHeight;

				svg {
					fill: $salesGraphTextColor;
				}
			}
		}

		.open.menuBodyWrapper {
			max-height: $salesGraphConfigMenuMaxHeight;
			transition: max-height $slideTransition;
		}

		.menuBodyWrapper {

			z-index: 10;
			position: absolute;
			top: 100%;
			right: 0;
			overflow: hidden;
			width: $salesGraphConfigMenuWidth;
			height: auto;
			max-height: 0;
			background: $salesGraphBackground;
			transition: max-height $slideFastTransition;

			.menuBodyLiner {

				border-left: 1px solid $salesGraphBorderColor;
				border-right: 1px solid $salesGraphBorderColor;
				border-bottom: 1px solid $salesGraphBorderColor;
				padding: $salesGraphMargin $salesGraphMargin 0 $salesGraphMargin;

				
				.mobileSection.configSection {
					display: none;

					@media #{$break768} {
						display: block;
					}
				}

				.configSection {

					.configSectionTitle {

						display: inline-block;
						vertical-align: top;
						width: auto;
						margin: 0 0 0.5*$salesGraphMargin 0;
						padding: 0 $salesGraphMargin 0 0;
						border-bottom: $salesGraphConfigTitieBorderWidth solid $salesGraphPrimary;

						.configSectionTitleValue {
							display: inline-block;
							vertical-align: top;
							width: auto;
							font-size: 14px;
							font-weight: 700;
						}
					}

					.configSectionBody {
						margin: 0 0 2*$salesGraphMargin 0;
					}
				}

				.configModeWrapper {

					.configSectionBody {

					}
				}

				.configMetricWrapper {

					.configSectionBody {

					}
				}

				.configGeneralWrapper {

					.configSectionBody {

						margin: 0 0 $salesGraphMargin 0;

						.toggleLine {

							margin: 0 0 $salesGraphMargin 0;

							&:last-child {
								margin: 0;
							}

							.inputWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: $salesGraphConfigMenuToggleHeight;
								height: $salesGraphConfigMenuToggleHeight;

								.inputElement {
									position: absolute;
									top: 0.5*($salesGraphConfigMenuToggleHeight - $salesGraphConfigMenuCheckboxHeight);
									left: 0;
									width: $salesGraphConfigMenuCheckboxHeight;
									height: $salesGraphConfigMenuCheckboxHeight;
								}
							}

							.labelWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: calc(100% - #{$salesGraphConfigMenuToggleHeight});
								margin: 0.5*($salesGraphConfigMenuToggleHeight - $salesGraphConfigMenuCheckboxHeight) 0;
								min-height: $salesGraphConfigMenuToggleHeight - $salesGraphMargin;

								.labelValue {
									cursor: pointer;
									user-select: none;
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}













