

@use '../../../../style/import' as *;


/////////////
// Confirm //
/////////////


// Titan colors
$confirmScreenColor: $TITAN_SCREEN_COLOR;
$confirmScreenOpacity: $TITAN_SCREEN_OPACITY;

$confirmAccent: $TITAN_PRIMARY_COLOR;
$confirmAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$confirmAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$confirmBackground: $TITAN_BACKGROUND;
$confirmBackgroundHover: $TITAN_BACKGROUND_HOVER;
$confirmColor: $TITAN_TEXT_COLOR;
$confirmColorHover: $TITAN_TEXT_COLOR_HOVER;


// Theme colors
$confirmThemeAccent: $THEME_PRIMARY_COLOR;
$confirmThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$confirmThemeAccentText: $THEME_PRIMARY_COLOR_TEXT;
$confirmThemeBackground: $THEME_BACKGROUND;
$confirmThemeBackgroundHover: $THEME_BACKGROUND_HOVER;
$confirmThemeColor: $THEME_TEXT_COLOR;
$confirmThemeColorHover: $THEME_TEXT_COLOR_HOVER;


// General variables
$confirmMargin: 10px;
$confirmSpacing: 15px;
$confirmSpacingMobile: 8px;
$confirmWidth: 600px;



.Confirm.storeTheme {

	.spModalWrapper {

		.spModal {

			background: $confirmThemeBackground;
			color: $confirmThemeColor;

			.spModalInnerWrapper {

				border: 1px solid $confirmThemeAccent;

				.spClose {
					color: $confirmThemeColor;

					&:hover {
						color: $confirmThemeColorHover;
					}
				}

				.spModalHr {
					background: $confirmThemeAccent;
				}

				.spModalActions {

					.spAction.spActionSave {
						color: $confirmThemeAccentText;
						background: $confirmThemeAccent;

						&:hover {
							background: $confirmThemeAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $confirmThemeColor;
						background: $confirmThemeBackground;

						&:hover {
							color: $confirmThemeColorHover;
							background: $confirmThemeBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $confirmThemeBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}
				}
			}
		}
	}
}


.Confirm {

	display: none;

	.spScreen.withOpacity {
		opacity: $confirmScreenOpacity;
	}

	.spScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $confirmScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.spModalWrapper {

		.spModal {

			position: fixed;
			left: calc(50% - #{$confirmWidth}/2);
			width: $confirmWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $confirmBackground;
			color: $confirmColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.spModalInnerWrapper {

				margin: $confirmMargin;
				padding: $confirmMargin;
				border: 1px solid $confirmAccent;

				.spClose {
					position: absolute;
					top: 1.5*$confirmMargin;
					right: 1.5*$confirmMargin;
					width: 2.5*$confirmMargin;
					height: 2.5*$confirmMargin;

					cursor: pointer;
					color: $confirmColor;
					font-size: 40px;
					font-weight: 100;
					user-select: none;

					display: flex;
					justify-content: center;
					align-items: center;
					transition: color $hoverTransition;

					&:hover {
						color: $confirmColorHover;
					}
				}

				.spModalTitle {
					font-size: 36px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $confirmSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 28px;
						margin: $confirmSpacingMobile 0;
					}
				}

				.spModalHr {
					background: $confirmAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.spModalCopy {
					font-size: 20px;
					text-align: center;
					margin: $confirmSpacing 0 2*$confirmSpacing 0;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 16px;
						margin: $confirmSpacingMobile 0 2*$confirmSpacingMobile 0;
					}
				}

				.spModalActions {

					text-align: right;

					.spAction.spActionSave {
						color: $confirmAccentText;
						background: $confirmAccent;

						&:hover {
							background: $confirmAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $confirmColor;
						background: $confirmBackground;
						margin: 0;

						&:hover {
							color: $confirmColorHover;
							background: $confirmBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $confirmBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}

					.spAction.shortCopy {

						display: none;

						@media #{$break1200} {
							display: inline-block;
						}
					}

					.spAction.longCopy {
						
						display: inline-block;

						@media #{$break1200} {
							display: none;
						}
					}

					.spAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						margin: 0 0 0 $confirmSpacing;
						padding: 0.5*$confirmSpacing 2*$confirmSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $confirmSpacingMobile;
							padding: 0.5*$confirmSpacing $confirmSpacing;
						}
					}
				}
			}
		}
	}
}


.Choice {

	display: none;

	.spScreen.withOpacity {
		opacity: $confirmScreenOpacity;
	}

	.spScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $confirmScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.spModalWrapper {

		.spModal {

			position: fixed;
			left: calc(50% - #{$confirmWidth}/2);
			width: $confirmWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $confirmBackground;
			color: $confirmColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.spModalInnerWrapper {

				margin: $confirmMargin;
				padding: $confirmMargin;
				border: 1px solid $confirmAccent;

				.spClose {
					position: absolute;
					top: 1.5*$confirmMargin;
					right: 1.5*$confirmMargin;
					width: 2.5*$confirmMargin;
					height: 2.5*$confirmMargin;

					cursor: pointer;
					color: $confirmColor;
					font-size: 40px;
					font-weight: 100;
					user-select: none;

					display: flex;
					justify-content: center;
					align-items: center;
					transition: color $hoverTransition;

					&:hover {
						color: $confirmColorHover;
					}
				}

				.spModalTitle {
					font-size: 36px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $confirmSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 28px;
						margin: $confirmSpacingMobile 0;
					}
				}

				.spModalHr {
					background: $confirmAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.spModalCopy {
					font-size: 20px;
					text-align: center;
					margin: $confirmSpacing 0 2*$confirmSpacing 0;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 16px;
						margin: $confirmSpacingMobile 0 2*$confirmSpacingMobile 0;
					}
				}

				.spModalActions {

					text-align: center;

					.spAction.spActionPrimary {
						color: $confirmAccentText;
						background: $confirmAccent;

						&:hover {
							background: $confirmAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $confirmColor;
						background: $confirmBackground;
						margin: 0;

						&:hover {
							color: $confirmColorHover;
							background: $confirmBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $confirmBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}

					.spAction.shortCopy {

						display: none;

						@media #{$break1200} {
							display: inline-block;
						}
					}

					.spAction.longCopy {
						
						display: inline-block;

						@media #{$break1200} {
							display: none;
						}
					}

					.spAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						margin: 0 0 0 $confirmSpacing;
						padding: 0.5*$confirmSpacing 2*$confirmSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $confirmSpacingMobile;
							padding: 0.5*$confirmSpacing $confirmSpacing;
						}
					}
				}
			}
		}
	}
}


