

@use '../../../../style/import' as *;



/////////////////
// ViewProduct //
/////////////////


// Titan colors
$viewProductIncludedPermission: $TITAN_TEXT_COLOR;
$viewProductExcludedPermission: $disabledColor;
$viewProductBlockBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$viewProductTextColor: $TITAN_TEXT_COLOR;
$viewProductAccentColor: $TITAN_PRIMARY_COLOR;
$viewProductAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$viewProductDetailsLightColor: $TITAN_TEXT_COLOR_LIGHT;
$viewProductTagBackground: $TITAN_RULE_COLOR;
$viewProductInventoryTableRowBackgroundColor: $TITAN_PRIMARY_ULTRALIGHT;
$viewProductInventoryTableRowBackgroundPlain: $TITAN_BACKGROUND;
$viewProductFileDropzoneAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$viewProductFileDropzoneBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$viewProductFileDropzoneBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$viewProductFileDropzoneBorder: $TITAN_ADMIN_PAGE_BACKGROUND;
$viewProductFileDropzoneBorderHover: $TITAN_PRIMARY_COLOR;

// Theme colors
// None yet

// General variables
$viewProductMargin: 10px;
$viewProductPermissionHeight: 50px;
$viewProductSpacing: 10px;
$viewProductDetailsDescriptionHeight: 100px;
$viewProductDetailsImageMaxWidth: 200px;
$viewProductDetailsLinkHeight: 20px;
$viewProductDetailsLinkWidth: 100px;
$viewProductDetailsDescriptionWidth: 400px;
$viewProductLoadingHeight: 50px;
$viewProductImageWidth: 240px;
$viewProductTagHeight: 16px;
$viewProductInventoryTableRowHeight: 25px;
$viewProductDropdownHeight: 45px;




.ViewProduct {


	.adminBody {
		.adminView {
			.adminSection {
				max-width: none;
			}
			.adminForm {
				max-width: none;
			}
		}
	}

	.adminActions {
		max-width: none;
	}
}


////////////////////////
// ProductDetailsView //
////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductDetailsView {

	.adminSectionBody {
		.adminSectionBodyWrapper {
			.adminViewWrapper {



				.productViewWrapper {

					.productViewImageSection {
						display: inline-block;
						vertical-align: top;
						width: $viewProductDetailsImageMaxWidth;
						transition: width $breakTransition;

						@media #{$break600} {
							display: block;
							width: 100%;
						}

						.componentImg.productViewImageWrapper {
							height: auto;
						}

						.productViewImageWrapper {

							display: block;
							margin: 0 auto;
							text-align: center;
							height: $viewProductDetailsImageMaxWidth;

							img {
								object-fit: contain;
								object-position: center;
								max-width: 100%;
								max-height: 100%;
							}
						}
					}

					.productViewDetailSection {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{$viewProductDetailsImageMaxWidth});
						transition: width $breakTransition;

						@media #{$break600} {
							display: block;
							width: 100%;
						}

						.pdvWrapper {

							position: relative;
							padding: 0 0 0 $viewProductMargin;

							.pdvTitle {
								
								font-family: $fontLato;
								font-size: 32px;
								width: calc(100% - #{$viewProductDetailsLinkWidth});
								transition: width $breakTransition, font-size $breakTransition;

								@media #{$break768} {
									width: 100%;
								}

								@media #{$break600} {
									font-size: 24px;
								}

								.pdvTitleCost {
									display: inline-block;
									vertical-align: top;
									width: auto;
									font-size: 28px;
									margin: 0 0 0 $viewProductMargin;

									.MagicSymbol {
										// margin: 0 0.1em;
									}
								}
							}

							.pdvProductLine {
								font-size: 20px;
								color: $viewProductDetailsLightColor;
							}

							.pdvSku {
								font-size: 16px;
								font-weight: 300;
							}

							.pdvLink {
								position: absolute;
								top: 0.5*$viewProductMargin;
								right: 0;
								width: auto;
								height: auto;

								@media #{$break768} {
									display: none;
								}

								.pdvLinkWrapper.disabled {
									&:hover {

										.pdvLinkCopy {
											color: $viewProductDetailsLightColor;
										}

										.pdvLinkIcon {
											svg {
												fill: $viewProductDetailsLightColor;
											}
										}
									}
								}

								.pdvLinkWrapper {

									&:hover {

										.pdvLinkCopy {
											color: $viewProductAccentColorHover;
										}

										.pdvLinkIcon {
											svg {
												fill: $viewProductAccentColorHover;
											}
										}
									}

									.pdvLinkCopy {
										display: inline-block;
										vertical-align: top;
										color: $viewProductDetailsLightColor;
										height: $viewProductDetailsLinkHeight;
										line-height: $viewProductDetailsLinkHeight;
										margin: 0 $viewProductSpacing 0 0;
										transition: color $hoverTransition;
									}

									.pdvLinkIcon {
										display: inline-block;
										vertical-align: top;
										width: $viewProductDetailsLinkHeight;
										height: $viewProductDetailsLinkHeight;

										svg {
											fill: $viewProductDetailsLightColor;
										}
									}
								}
							}

							.pdvKeyValue {

								.kvRow {

									margin: 0 0 $viewProductSpacing/4 0;
									font-size: 14px;

									&:first-child {
										margin-top: $viewProductSpacing;
									}

									&:last-child {
										margin-bottom: $viewProductSpacing;
									}

									.kvKey {
										display: inline-block;
										vertical-align: top;
										width: auto;
										font-weight: 400;
										margin: 0 $viewProductSpacing/2 0 0;

										&:after {
											content: ':';
										}
									}

									.kvValue {
										display: inline-block;
										vertical-align: top;
										width: auto;
										font-weight: 300;

										.kvPrimary {
											display: inline-block;
											vertical-align: top;
											margin: 0 $viewProductSpacing/2 0 0;
										}

										.kvDefaultTag {
											
											display: inline-block;
											vertical-align: top;
											background: $viewProductTagBackground;
											margin: 0 $viewProductSpacing/2 0 0;
											height: $viewProductTagHeight;
											border-radius: $viewProductSpacing/2;

											.kvDefaultTagValue {
												color: $viewProductBlockBackground;
												text-align: center;
												font-size: 0.6*$viewProductTagHeight;
												font-weight: 700;
												line-height: 0.6*$viewProductTagHeight;
												padding: 0.2*$viewProductTagHeight 0.5*$viewProductTagHeight;
											}
										}

										.kvNone {
											font-style: italic;
										}
									}
								}
							}

							.pdvDescriptionWrapper.flipDescription {
								border-top: 1px solid $viewProductDetailsLightColor;
								padding-top: $viewProductMargin;
							}

							.pdvDescriptionWrapper.unlimited {
								overflow: auto;
								height: auto;
								max-width: $viewProductDetailsDescriptionWidth;

								.pdvDescriptionGradient {
									display: none;
								}
							}

							.pdvDescriptionWrapper {

								position: relative;
								margin: $viewProductMargin 0 0 0;
								height: $viewProductDetailsDescriptionHeight;
								overflow: hidden;

								.pdvDescription {
									
								}

								.pdvDescriptionGradient {
									position: absolute;
									left: 0;
									bottom: 0;
									height: $viewProductMargin;
									width: 100%;
									background: linear-gradient(to bottom, rgba($viewProductBlockBackground, 0) 0%, rgba($viewProductBlockBackground, 1) 100%);
								}					
							}

							.pdvSecondaryDescription {
								font-style: italic;
								margin: $viewProductMargin 0 0 0;
								max-width: $viewProductDetailsDescriptionWidth;
							}
						}
					}
				}

				.adminViewEditable {
					position: relative;
					width: calc(50% - 2*#{$viewProductMargin} + 1px);

					@media #{$break1024} {
						width: auto;
					}
				}

				.adminViewMeta {
					width: calc(50% - 2*#{$viewProductMargin} - 1px);

					@media #{$break1024} {
						width: auto;
					}
				}
			}
		}
	}
}


////////////////////////
// ProductDetailsEdit //
////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductDetailsEdit {

	.adminFieldWrapper {

		.selectedSet {

			.selectedSetLiner {

				.setName {
					display: inline-block;
					vertical-align: baseline;
					font-size: 20px;
					font-weight: 300;
				}
				
				.setClear {
					display: inline-block;
					vertical-align: baseline;
					margin: 0 0 0 $viewProductMargin;
					font-size: 14px;
					color: $viewProductAccentColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $viewProductAccentColorHover;
					}
				}
			}
		}
	}
}


//////////////////////////
// ProductInventoryView //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductInventoryView {

	.productViewWrapper {

		.inventoryHeadline {

			margin: 0 0 $viewProductMargin 0;

			.headlineSection {

				display: inline-block;
				vertical-align: top;
				width: 50%;

				.headlineElement {

					display: inline-block;
					vertical-align: top;

					.headlineLabel {
						display: inline-block;
						vertical-align: text-bottom;
						font-size: 18px;
						font-weight: 400;
						text-transform: uppercase;
						margin: 0 $viewProductMargin 0 0;
					}

					.headlineValue {
						display: inline-block;
						vertical-align: text-bottom;
						font-size: 18px;
						font-weight: 300;
					}
				}
			}

			.qtySection {
				text-align: left;
			}

			.priceSection {
				text-align: right;
			}
		}

		.inventoryTable {

			margin: 2*$viewProductMargin 0;

			.inventoryTableHeader {

				position: relative;
				margin: 0 0 $viewProductMargin 0;

				.itHeaderCell {
					display: inline-block;
					vertical-align: top;

					.headerCellWrapper {
						display: inline-block;
						width: auto;
						border-bottom: 2px solid $viewProductAccentColor;
						font-size: 16px;
						font-weight: 700;
						text-transform: uppercase;
					}
				}
			}

			.inventoryTableBody {

				.itBodyEmpty {
					padding: 5*$viewProductMargin 0;
					text-align: center;
					font-size: 24px;
					font-weight: 300;
				}

				.itBodyRow {

					position: relative;
					height: $viewProductInventoryTableRowHeight;
					background: $viewProductInventoryTableRowBackgroundPlain;

					&:nth-child(odd) {
						background: $viewProductInventoryTableRowBackgroundColor;
					}

					.itBodyCell {
						display: inline-block;
						vertical-align: top;
						height: $viewProductInventoryTableRowHeight;
						line-height: $viewProductInventoryTableRowHeight;
					}
				}
			}

			$itQtyWidth: 120px;
			$itPriceWidth: 180px;
			$itEditWidth: 100px;

			.typeCell {
				// width: $itConditionWidth;

				.typeCellContent {
					padding: 0 0 0 $viewProductMargin;
				}
			}

			.qtyCell {
				position: absolute;
				display: block;

				top: 0;
				right: $itPriceWidth + $itEditWidth;
				width: $itQtyWidth;
				height: 100%;

				@media #{$break900} {
					display: none !important;
				}
			}

			.priceCell {
				position: absolute;
				display: block;

				top: 0;
				right: $itEditWidth;
				width: $itPriceWidth;
				height: 100%;

				@media #{$break768} {
					display: none !important;
				}
			}

			.editCell {
				position: absolute;
				display: block;
				text-align: right;

				top: 0;
				right: 0;
				width: $itEditWidth;
				height: 100%;

				.editCellLink {
					display: inline-block;
					vertical-align: top;
					height: $viewProductInventoryTableRowHeight;
					padding: 0 $viewProductMargin;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					color: $viewProductAccentColor;
					cursor: pointer;
					border: none;
					background: none;
					transition: color $hoverTransition;

					&:hover {
						color: $viewProductAccentColorHover;
					}
				}
			}
		}
	}
}


//////////////////////////
// ProductInventoryEdit //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductInventoryEdit {

	.adminSectionBody {
		.adminSectionBodyWrapper {

			.productInventoryForm {
				
				margin: 2*$viewProductMargin 0 0 0;

				.pieTitle {
					margin: 0 0 2*$viewProductMargin 0;
					font-size: 24px;

					.pieTitleLabel {
						margin: 0 $viewProductMargin 0 0;
						font-weight: 400;

						@media #{$break768} {
							display: block;
						}

						&:after {
							content: ":";
						}
					}

					.pieTitleValue {
						font-weight: 300;

						@media #{$break768} {
							display: block;
						}
					}
				}

				.adminDropdown {

					position: relative;

					.adDropdownArrowWrapper {

						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						width: $viewProductDropdownHeight - $viewProductMargin;

						pointer-events: none;
						background: none;

						.adArrow {
							position: absolute;
							top: 1.5*$viewProductMargin;
							left: 0;
							width: 0;
							height: 0;

							border-top: (1.5 * 0.25 * $viewProductDropdownHeight) solid $viewProductTextColor;
							border-bottom: none;
							border-left: (0.25 * $viewProductDropdownHeight) solid transparent;
							border-right: (0.25 * $viewProductDropdownHeight) solid transparent;
						}
					}

					select:disabled+.adDropdownArrowWrapper {
						.adArrow {
							border-top-color: $disabledColor;
						}
					}
				}
			}
		}
	}
}


//////////////////////
// ProductMediaView //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductMediaView {

	.productMediaViewWrapper {

		.pviWrapper {

			.pviSingleImgWrapper {

				display: inline-block;
				vertical-align: top;
				width: $viewProductImageWidth;
				height: $viewProductImageWidth;
				margin: 0 $viewProductMargin $viewProductMargin 0;
				text-align: center;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;

					object-fit: contain;
				}
			}
		}
	}
}


//////////////////////
// ProductMediaEdit //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductMediaEdit {

	.productMediaForm {

		.imagesPreviewWrapper {
			margin: $viewProductMargin 0;
		}

		.fileDropzoneWrapper {

			.fileError {

			}

			.dropWrapper.hoverFile {
				background: $viewProductFileDropzoneBackgroundHover;
				border: 1px solid $viewProductFileDropzoneBorderHover;
			}

			.dropWrapper {
				margin: 2*$viewProductMargin 0 0 0;
				background: $viewProductFileDropzoneBackground;
				border: 1px solid $viewProductFileDropzoneBorder;
				padding: 3*$viewProductSpacing $viewProductSpacing;
				text-align: center;
				transition: background $hoverTransition, border $hoverTransition;

				.dropPrompt {
					font-size: 28px;
					font-weight: 300;
					margin: 0 0 2*$viewProductSpacing 0;
				}

				.dropError {
					text-align: center;
					color: $errorRed;
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 2*$viewProductMargin 0;
				}

				.dropButton {
					display: inline-block;
					color: $viewProductFileDropzoneAccentText;
					background: $viewProductAccentColor;
					vertical-align: top;
					cursor: pointer;
					border: none;
					margin: 0 0 0 $viewProductMargin;
					padding: $viewProductMargin 4*$viewProductMargin;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition, color $hoverTransition, padding $breakTransition, width $breakTransition;

					@media #{$break1024} {
						padding: 2*$viewProductMargin 3*$viewProductMargin;
					}

					&:hover {
						background: $viewProductAccentColorHover;
					}
				}
			}
		}
	}
}



/////////////////////////////
// ProductRecentOrdersView //
/////////////////////////////


// Titan colors
$productOrdersDisabledColor: $disabledColor;
$productOrdersListRowSelectedBackground: $TITAN_PRIMARY_ULTRALIGHT;
$productOrdersListRowBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
// None yet

// General variables
$productOrdersInfoWidth: 33.3%;
$productOrdersListRowHeight: 50px;

$productOrdersViewItemsMaxHeight: 300px;
$productOrdersViewTableDateWidth: 150px;
$productOrdersViewTableAmountWidth: 120px;
$productOrdersViewTableAmountWidthMobile: 80px;
$productOrdersViewTableQuantityWidth: 100px;
$productOrdersViewTableQuantityWidthMobile: 70px;
$productOrdersViewTableActionWidth: 40px;
$productOrdersViewTableActionWidthMobile: 30px;


.ProductRecentOrdersView {
	
	.noOrders {
		padding: 5*$viewProductMargin 0;
		text-align: center;
		min-height: $viewProductLoadingHeight;

		.noOrdersTitle {
			font-size: 24px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.noOrdersCopy {
			margin: $viewProductMargin 0 0 0;
			font-size: 20px;
			font-weight: 300;
		}
	}

	.ordersListWrapper {

		margin: $viewProductMargin 0 0 0;
		text-align: left;

		.orderList {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$productOrdersInfoWidth} - #{1.5*$viewProductMargin});

			@media #{$break1024} {
				width: 100%;
			}

			.slHeader {

				margin: 0 0 $viewProductMargin 0;
				
				.slHeaderCell {
					display: inline-block;
					vertical-align: top;
					font-size: 18px !important;
					font-weight: 700 !important;
					transition: font-size $breakTransition;

					@media #{$break1024} {
						font-size: 16px !important;
					}

					.slHeaderValue {

					}
				}
			}

			.slSelected.slItem {
				cursor: default;
				background: $productOrdersListRowSelectedBackground;

				@media #{$break1024} {
					background: none;
				}

				&:hover {
					background: $productOrdersListRowSelectedBackground;

					@media #{$break1024} {
						background: none;
					}
				}

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 100%;
					width: 0;
					height: 0;
					transition: background $hoverTransition;

					border-left: 2*$viewProductMargin solid $productOrdersListRowSelectedBackground;
					border-top: $productOrdersListRowHeight/2 solid transparent;
					border-bottom: $productOrdersListRowHeight/2 solid transparent;

					@media #{$break1024} {
						display: none;
					}
				}
			}

			.slItem {

				cursor: pointer;
				min-height: $productOrdersListRowHeight;
				transition: background $hoverTransition;

				@media #{$break1024} {
					cursor: default;
				}

				&:hover {
					background: $productOrdersListRowBackgroundHover;

					@media #{$break1024} {
						background: none;
					}
				}

				&:nth-child(even) {

					@media #{$break1024} {
						background: $productOrdersListRowSelectedBackground;

						&:hover {
							background: $productOrdersListRowSelectedBackground;
						}
					}
				}

				.slItemCell {
					position: relative;
					display: inline-block;
					vertical-align: top;
					min-height: $productOrdersListRowHeight;

					.slItemValue {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;

						display: flex;
						align-items: center;
					}
				}
			}

			.slItem, .slHeader {

				position: relative;

				.dateCell {
					width: $productOrdersViewTableDateWidth;
					margin-left: $viewProductMargin;
					font-weight: 700;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: calc(100% - #{4*$viewProductMargin} - #{$productOrdersViewTableAmountWidthMobile} - #{$productOrdersViewTableQuantityWidthMobile} - #{$productOrdersViewTableActionWidthMobile});
						font-size: 13px;
					}

					@media #{$break600} {
						width: calc(100% - #{3*$viewProductMargin} - #{$productOrdersViewTableAmountWidthMobile} - #{$productOrdersViewTableActionWidthMobile});
					}

					.dateDate {
						font-weight: 700;
						font-size: 14px;
					}

					.dateTime {
						font-weight: 300;
						font-size: 15px;
					}
				}

				.numberCell {
					width: calc(100% - #{5*$viewProductMargin} - #{$productOrdersViewTableDateWidth} - #{$productOrdersViewTableAmountWidth} - #{$productOrdersViewTableQuantityWidth} - #{$productOrdersViewTableActionWidth});
					margin-left: $viewProductMargin;
					font-weight: 400;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						display: none;
					}
				}

				.amountCell {
					width: $productOrdersViewTableAmountWidth;
					margin-left: $viewProductMargin;
					font-weight: 400;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $productOrdersViewTableAmountWidthMobile;
						font-size: 13px;
					}
				}

				.quantityCell {
					width: $productOrdersViewTableQuantityWidth;
					margin-left: $viewProductMargin;
					font-weight: 400;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $productOrdersViewTableQuantityWidthMobile;
						font-size: 13px;
					}

					@media #{$break600} {
						display: none;
					}

					.quantityValue {
						justify-content: center;	
						width: $productOrdersViewTableQuantityWidth;
						transition: width $breakTransition;

						@media #{$break768} {
							width: $productOrdersViewTableQuantityWidthMobile;
						}
					}
				}

				.actionCell {
					width: $productOrdersViewTableActionWidth;
					margin-left: $viewProductMargin;
					text-align: center;
					transition: width $breakTransition, font-size $breakTransition;

					@media #{$break768} {
						width: $productOrdersViewTableActionWidthMobile;
					}

					.noShow.actionIconWrapper {
						display: none;

						@media #{$break1024} {
							display: block;
						}
					}

					.actionIconWrapper {
						position: absolute;
						top: ($productOrdersListRowHeight - $productOrdersViewTableActionWidth/2)/2;
						left: $productOrdersViewTableActionWidth/4;
						width: $productOrdersViewTableActionWidth/2;
						height: $productOrdersViewTableActionWidth/2;
						cursor: pointer;

						@media #{$break768} {
							top: ($productOrdersListRowHeight - $productOrdersViewTableActionWidthMobile/2)/2;
							left: $productOrdersViewTableActionWidthMobile/4;
							width: $productOrdersViewTableActionWidthMobile/2;
							height: $productOrdersViewTableActionWidthMobile/2;
						}

						&:hover {
							.actionIcon {
								svg {
									fill: $viewProductAccentColorHover;
								}
							}
						}
						
						.actionIcon {
							width: $productOrdersViewTableActionWidth/2;
							height: $productOrdersViewTableActionWidth/2;

							@media #{$break768} {
								width: $productOrdersViewTableActionWidthMobile/2;
								height: $productOrdersViewTableActionWidthMobile/2;
							}
						}
					}
				}
			}
		}

		.orderInfo {
			display: inline-block;
			vertical-align: top;
			width: calc(#{$productOrdersInfoWidth} - #{1.5*$viewProductMargin});
			margin: 0 0 0 3*$viewProductMargin;

			@media #{$break1024} {
				display: none;
			}

			.infoSectionTitle {

				margin: 0 0 $viewProductMargin 0;

				.infoSectionTitleValue {
					display: inline-block;
					vertical-align: top;
					font-size: 18px;
					font-weight: 700;
				}

				.infoSectionTitleItems {
					display: inline-block;
					vertical-align: top;
					margin: 1px 0 0 $viewProductMargin;
					font-size: 14px;
					font-weight: 300;
				}
			}

			.orderItemsWrapper {
				max-height: $productOrdersViewItemsMaxHeight;
				overflow-y: scroll;
				padding: 0 $viewProductMargin 0 0;
			}
		}
	}

	.paginationWrapper {

		.paginationLiner {

			text-align: center;

			.noNav.leftArrow, .noNav.rightArrow {
				color: $productOrdersDisabledColor;
				cursor: default;

				&:hover {
					font-weight: 400;
					color: $productOrdersDisabledColor;
				}
			}

			.leftArrow, .rightArrow {
				display: inline-block;
				vertical-align: middle;
				font-size: 22px;
				font-weight: 400;
				color: $viewProductTextColor;
				cursor: pointer;
				user-select: none;
				transition: color $hoverTransition, font-weight $hoverTransition;

				&:hover {
					font-weight: 700;
					color: $viewProductAccentColor;
				}
			}

			.pageStatus {
				display: inline-block;
				vertical-align: middle;
				margin: 0 $viewProductMargin;
			}
		}
	}
}


//////////////////////
// ProductModalView //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$productModalFormBorderColor: $TITAN_TEXT_COLOR_LIGHT;


.ProductModalView {

	.pmvWrapper {

		.productModalForm {

			margin: $viewProductMargin 0;

			.present.pmFormError {
				height: 3.5*$viewProductMargin;
			}

			.pmFormError {
				color: $errorRed;
				font-weight: 700;
				font-size: 16px;
				height: 0;
				text-align: center;
				overflow: hidden;
				transition: height $toggleTransition;
			}

			.pmFieldWrapper {

				position: relative;
				margin: 0 auto $viewProductMargin auto;

				.pmFieldAction {
					position: absolute;
					top: 0.25*$viewProductMargin;
					right: 0;
					width: auto;
					height: auto;
					cursor: pointer;
					font-size: 14px;
					font-weight: 700;
					color: $viewProductAccentColor;
					transition: color $hoverTransition;

					&:hover {
						color: $viewProductAccentColorHover;
					}
				}

				.pmFieldLabel.pmRequired {

					&:after {
						content: '*';
						color: $viewProductAccentColor;
						font-size: 16px;
					}
				}

				.pmFieldLabel.pmOptional {
					/* Only needed when aligned next to required field */
					&:after {
						content: '*';
						color: $viewProductAccentColor;
						font-size: 16px;
						opacity: 0;
					}
				}

				.pmFieldLabel {
					color: $viewProductTextColor;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin: 0 0 0.5*$viewProductMargin 0;
					user-select: none;
				}

				.pmInputWrapper {

					.pmError {

					}

					.pmDropdownWrapper {

						border: 1px solid $productModalFormBorderColor;

						.pmDropdownSelect {
							margin: $viewProductMargin 0.5*$viewProductMargin $viewProductMargin 2*$viewProductMargin;
							border-bottom: none;
						}
					}

					input {
						width: calc(100% - #{4*$viewProductMargin} - 2px);
						padding: $viewProductMargin 2*$viewProductMargin;
						border: 1px solid $productModalFormBorderColor;
					}

					textarea.tallText {
						height: 10*$viewProductMargin;
					}

					textarea {
						width: calc(100% - #{4*$viewProductMargin} - 2px);
						padding: $viewProductMargin 2*$viewProductMargin;
						border: 1px solid $productModalFormBorderColor;
						border-radius: 0;
						outline: none;
					}
				}

				.selectedSet {

					.selectedSetLiner {

						.setName {
							display: inline-block;
							vertical-align: baseline;
							font-size: 20px;
							font-weight: 300;
						}
						
						.setClear {
							display: inline-block;
							vertical-align: baseline;
							margin: 0 0 0 $viewProductMargin;
							font-size: 14px;
							color: $viewProductAccentColor;
							cursor: pointer;
							transition: color $hoverTransition;

							&:hover {
								color: $viewProductAccentColorHover;
							}
						}
					}
				}
			}
		}
	}
}


///////////////////////////
// ProductModalChangeSet //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$productModalChangeSetWidth: 80%;


.ProductModalChangeSet {

	.pmvWrapper {
		
		.changeSetForm {
			margin: 2*$viewProductMargin auto;
			width: $productModalChangeSetWidth;

			.pmFieldWrapper {

				.pmInputWrapper {

					.pmDropdownWrapper {

						border: none;

						.pmDropdownSelect {
							margin: 0;
						}
					}
				}
			}
		}
	}
}




















