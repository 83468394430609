

@use '../../../../../../style/import' as *;




/////////////////////////////////
// AdminPageSectionViewElement //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$defaultHeight: 50px;
$labelLineHeight: 20px;


.AdminPageSectionViewElement {

	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	min-height: $defaultHeight;
	transition: width $breakTransition;

	@media #{$break600} {
		display: block;
		width: auto;
	}

	.viewWrapper {

		margin: 0 $MARGIN $MARGIN $MARGIN;

		.label {
			line-height: $labelLineHeight;
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.value {

			&.null {
				font-style: italic;
			}

			font-size: 18px;
			font-weight: 300;
		}
	}
}













