

@use 'import' as *;


@font-face {
  font-family: 'PhyrexianHorizontal';
  font-weight: normal;
  font-style: normal;
  // src: url(https://assets.scryfall.com/assets/phyrexian-horizontal/regular-a88d6ad81d65325049a44415f9f9ea0dc03017e7c5d5d4337caa7c285faa30f8.woff2) format("woff2"),url(https://assets.scryfall.com/assets/phyrexian-horizontal/regular-9cb3c791e59f4a3b5e16b19339a5d384af29bcdfbcf78db57ea7f197e1b0b863.woff) format("woff");
  src: url(https://static.talaria.shop/font/ph.woff2) format("woff2");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}

body {
	box-sizing: border-box;
	color: $THEME_TEXT_COLOR;
	font-family: $fontLato;
	/*height: 100%;	*/

	input:focus { outline: none; }
	select:focus { outline: none; }
	textarea:focus { outline: none; }

	/* Remove arrows in number type text input */
	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}
	input[type=number] {
	  -moz-appearance: textfield;
	}

	input, textarea {
		transition: border-color $toggleTransition;
		@include placeholder {
			color: $placeholderGrey;
		}
		&:disabled {
			color: $disabledColor !important;
			border-color: $disabledColor !important;
		}
	}

	.FieldError {
		margin: 2px 0;
		font-size: 14px;
		font-weight: 700;
		color: $errorRed;
	}

	.FieldNotice {
		margin: 2px 0;
		font-size: 14px;
		font-weight: 700;
		color: $THEME_TEXT_COLOR;
	}

	input.InputError, 
	textarea.InputError,
	select.InputError {
		border-color: $errorRed !important;
	}

	button {
		transition: color $toggleTransition, background $toggleTransition border-color $toggleTransition;
		&:disabled {
			color: $disabledColor !important;
			background: $disabledBackground !important;
			border-color: $disabledColor !important;
		}
	}

	.EllipsisElement {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.FlexCenter {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.FlexCenterLeft {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: left;
	}

	.FlexCenterRight {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: center;
	}

	.MagicSymbol {
		transform: scale(0.85);
		vertical-align: text-bottom;
	}

	[lang='ph'] {
		font-family: PhyrexianHorizontal, monospace !important;
    // font-size: 14px !important;
    word-wrap: break-word !important;
    font-weight: normal !important;
    font-style: normal !important;
    // line-height: 15px;
	}
}


/* Firefox required red shadow bug */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow:none;
}

a {
	text-decoration: none;
	color: $THEME_TEXT_COLOR;
}

