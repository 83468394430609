

@use '../../../../style/import' as *;




////////////////////
// PrimaryNavLink //
////////////////////


// Titan colors
// None yet

// Theme colors
$textColor: $THEME_PRIMARY_COLOR_TEXT;
$backgroundColor: $THEME_PRIMARY_COLOR;
$backgroundColorHover: $THEME_PRIMARY_COLOR_HOVER;

// General variables
// None yet


.PrimaryNavLink {

	display: inline-block;
	text-align: center;
	margin: 0;
	padding: 0;

	.linkElement {

		display: block;
		user-select: none;
		cursor: pointer;
		color: $textColor;
		background: $backgroundColor;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
		padding: $MARGIN $MARGIN_L;
		transition: background $hoverTransition;

		&:hover {
			background: $backgroundColorHover;
		}
	}
}













