

@use '../../../../style/import' as *;


///////////
// Modal //
///////////


// Titan colors
$modalScreenColor: $TITAN_SCREEN_COLOR;
$modalScreenOpacity: $TITAN_SCREEN_OPACITY;

$modalAccent: $TITAN_PRIMARY_COLOR;
$modalAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$modalAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$modalBackground: $TITAN_BACKGROUND;
$modalBackgroundHover: $TITAN_BACKGROUND_HOVER;
$modalColor: $TITAN_TEXT_COLOR;
$modalColorHover: $TITAN_TEXT_COLOR_HOVER;


// Theme colors
$modalThemeAccent: $THEME_PRIMARY_COLOR;
$modalThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$modalThemeAccentText: $THEME_PRIMARY_COLOR_TEXT;
$modalThemeBackground: $THEME_BACKGROUND;
$modalThemeBackgroundHover: $THEME_BACKGROUND_HOVER;
$modalThemeColor: $THEME_TEXT_COLOR;
$modalThemeColorHover: $THEME_TEXT_COLOR_HOVER;


// General variables
$modalMargin: 10px;
$modalSpacing: 15px;
$modalSpacingMobile: 8px;
$modalWidth: 600px;



.Modal.storeTheme {

	.spModalWrapper {

		.spModal {

			background: $modalThemeBackground;
			color: $modalThemeColor;

			.spModalInnerWrapper {

				border: 1px solid $modalThemeAccent;

				.spClose {
					color: $modalThemeColor;

					&:hover {
						color: $modalThemeColorHover;
					}
				}

				.spModalHr {
					background: $modalThemeAccent;
				}

				.spModalActions {

					.spAction.spActionSave {
						color: $modalThemeAccentText;
						background: $modalThemeAccent;

						&:hover {
							background: $modalThemeAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $modalThemeColor;
						background: $modalThemeBackground;

						&:hover {
							color: $modalThemeColorHover;
							background: $modalThemeBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $modalThemeBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}
				}
			}
		}
	}
}


.Modal {

	display: none;

	.spScreen.withOpacity {
		opacity: $modalScreenOpacity;
	}

	.spScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $modalScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.spModalWrapper {

		.spModal {

			position: fixed;
			left: calc(50% - #{$modalWidth}/2);
			width: $modalWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $modalBackground;
			color: $modalColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.spModalInnerWrapper {

				margin: $modalMargin;
				padding: $modalMargin;
				border: 1px solid $modalAccent;

				.spClose {
					position: absolute;
					top: 1.5*$modalMargin;
					right: 1.5*$modalMargin;
					width: 2.5*$modalMargin;
					height: 2.5*$modalMargin;

					cursor: pointer;
					color: $modalColor;
					font-size: 40px;
					font-weight: 100;
					user-select: none;

					display: flex;
					justify-content: center;
					align-items: center;
					transition: color $hoverTransition;

					&:hover {
						color: $modalColorHover;
					}
				}

				.spModalTitle {
					font-size: 36px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $modalSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 28px;
						margin: $modalSpacingMobile 0;
					}
				}

				.spModalHr {
					background: $modalAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.spModalBody {

				}

				.spModalActions {

					text-align: right;

					.spAction.spActionSave {
						color: $modalAccentText;
						background: $modalAccent;

						&:hover {
							background: $modalAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $modalColor;
						background: $modalBackground;
						margin: 0;

						&:hover {
							color: $modalColorHover;
							background: $modalBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $modalBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}

					.spAction.shortCopy {

						display: none;

						@media #{$break1200} {
							display: inline-block;
						}
					}

					.spAction.longCopy {
						
						display: inline-block;

						@media #{$break1200} {
							display: none;
						}
					}

					.spAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						margin: 0 0 0 $modalSpacing;
						padding: 0.5*$modalSpacing 2*$modalSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $modalSpacingMobile;
							padding: 0.5*$modalSpacing $modalSpacing;
						}
					}
				}
			}
		}
	}
}


.Choice {

	display: none;

	.spScreen.withOpacity {
		opacity: $modalScreenOpacity;
	}

	.spScreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: $modalScreenColor;
		z-index: $zAlert;
		transition: opacity $toggleTransition;
	}

	.spModalWrapper {

		.spModal {

			position: fixed;
			left: calc(50% - #{$modalWidth}/2);
			width: $modalWidth;
			height: auto;
			opacity: 0;
			z-index: $zAlert;

			background: $modalBackground;
			color: $modalColor;
			transition: opacity $slideTransition, top $slideTransition, left $breakTransition, width $breakTransition;

			@media #{$break768} {
				left: 10%;
				width: 80%;
			}

			.spModalInnerWrapper {

				margin: $modalMargin;
				padding: $modalMargin;
				border: 1px solid $modalAccent;

				.spClose {
					position: absolute;
					top: 1.5*$modalMargin;
					right: 1.5*$modalMargin;
					width: 2.5*$modalMargin;
					height: 2.5*$modalMargin;

					cursor: pointer;
					color: $modalColor;
					font-size: 40px;
					font-weight: 100;
					user-select: none;

					display: flex;
					justify-content: center;
					align-items: center;
					transition: color $hoverTransition;

					&:hover {
						color: $modalColorHover;
					}
				}

				.spModalTitle {
					font-size: 36px;
					font-weight: 700;
					font-family: $fontLato;
					text-align: center;
					margin: $modalSpacing 0;
					transition: font-size $breakTransition, margin $breakTransition;

					@media #{$break768} {
						font-size: 28px;
						margin: $modalSpacingMobile 0;
					}
				}

				.spModalHr {
					background: $modalAccent;
					width: 80%;
					height: 2px;
					margin: 0 auto;
				}

				.spModalBody {

				}

				.spModalActions {

					text-align: center;

					.spAction.spActionPrimary {
						color: $modalAccentText;
						background: $modalAccent;

						&:hover {
							background: $modalAccentHover;
						}
					}

					.spAction.spActionCancel {
						color: $modalColor;
						background: $modalBackground;
						margin: 0;

						&:hover {
							color: $modalColorHover;
							background: $modalBackgroundHover;
						}
					}

					.spAction.spActionDelete {
						color: $modalBackground;
						background: $errorRed;

						&:hover {
							background: $errorRedHover;
						}
					}

					.spAction.shortCopy {

						display: none;

						@media #{$break1200} {
							display: inline-block;
						}
					}

					.spAction.longCopy {
						
						display: inline-block;

						@media #{$break1200} {
							display: none;
						}
					}

					.spAction {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
						border: none;
						margin: 0 0 0 $modalSpacing;
						padding: 0.5*$modalSpacing 2*$modalSpacing;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, margin $breakTransition, padding $breakTransition;

						@media #{$break768} {
							margin: 0 0 0 $modalSpacingMobile;
							padding: 0.5*$modalSpacing $modalSpacing;
						}
					}
				}
			}
		}
	}
}


