

@use '../../../../style/import' as *;




////////////////////////////
// LayoutTypeSidebarRight //
////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$sidebarWidth: 240px;


.LayoutTypeSidebarRight {

	.main {

		display: inline-block;
		vertical-align: top;
		width: calc(100% - #{$sidebarWidth});
		// No transition intentionally

		@media #{$break1024} {
			display: block;
			width: 100%;
		}

		.mainSectionWrapper {

		}
	}

	.sidebar {

		display: inline-block;
		vertical-align: top;
		width: $sidebarWidth;

		@media #{$break1024} {
			display: none;
		}

		.sidebarSectionWrapper {

		}
	}
}













