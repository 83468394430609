

@use '../../../../../style/import' as *;
@use '../../_styles/header-constants' as *;




//////////////////////
// AccountLoggedOut //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AccountLoggedOut {

	cursor: pointer;
	user-select: none;

	.desktopWrapper {

		&:hover {
			.accountCopyWrapper {
				.loggedOutWrapper {
					border-bottom: $MARGIN_S solid $headerAccentColor;
				}
			}
		}

		@media #{$break1024} {
			display: none;
		}

		.accountIconWrapper {

			position: absolute;
			top: 0;
			left: 0;
			width: $headerAccountHeight;
			bottom: 0;

			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;

			svg {
				fill: $headerColor;
			}
		}

		.accountCopyWrapper {

			position: absolute;
			top: 0;
			bottom: 0;
			left: $headerAccountHeight + (2 * $MARGIN_S);
			right: 0;

			.loggedOutWrapper {
				
				position: absolute;
				left: 0;
				width: auto;
				color: $headerColor;
				user-select: none;
				top: 0.5*($headerAccountHeight - $headerMenuHeight);
				height: $headerMenuHeight;
				padding-left: 2 * $MARGIN_S;
				padding-right: 2 * $MARGIN_S;

				text-align: left;
				line-height: $headerMenuHeight;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				border-bottom: $MARGIN_S solid $headerBackground;
				transition: border $hoverTransition;
			}
		}
	}

	.mobileWrapper {

		display: none;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@media #{$break1024} {
			display: block;
		}

		.iconClass {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&:hover {
				svg {
					fill: $headerAccentColor;
				}
			}
		}
	}
}













