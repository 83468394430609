

@use '../../../../style/import' as *;




/////////////////////////////////
// ProductLinePricingRulesView //
/////////////////////////////////


// Titan colors
$pricingRuleAccent: $TITAN_PRIMARY_COLOR;
$pricingRuleAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$pricingRuleAccentText: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
$pricingRuleThemeAccent: $THEME_PRIMARY_COLOR;
$pricingRuleThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$pricingRuleThemeColor: $THEME_TEXT_COLOR;
$pricingRuleThemeColorLight: $THEME_TEXT_COLOR_LIGHT;

// General variables
$pricingRuleLoadingHeight: 40px;
$pricingRuleMargin: 10px;


.ProductLinePricingRulesView {

}


//////////////////////////////////
// ProductLinePricingMatrixEdit //
//////////////////////////////////


// Titan colors
$prrTitleBackground: $TITAN_ADMIN_PAGE_BACKGROUND;
$prrRowBackgroundAlternate: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$prrRowInputHeight: 20px;

$prrInputLanguageWidth: 100px;
$prrInputConditionWidth: 100px;
$prrInputActionWidth: 4*$prrRowInputHeight;

$prrInputLanguageWidthMobile: 80px;
$prrInputConditionWidthMobile: 70px;
$prrInputActionWidthMobile: 4*$prrRowInputHeight;

$prrHeaderHeight: 28px;


.ProductLinePricingMatrixEdit {

	.editRulesLiner {

		.priceRulesNullWrapper {

			.priceRulesNull {

				.priceRulesNullValue {
					font-size: 18px;
					font-weight: 300;
				}
			}
		}

		.priceRulesAddWrapper {

			@media #{$break480} {
				display: none;
			}

			.priceRulesAdd {

				text-align: center;

				.priceRulesToggle {

					cursor: pointer;
					display: inline-block;
					vertical-align: middle;
					margin: 0.5*$pricingRuleMargin 0 0 0;
					font-size: 15px;
					line-height: 18px;
					font-weight: 700;
					color: $pricingRuleAccent;
					transition: color $hoverTransition;

					&:hover {
						color: $pricingRuleAccentHover;						
					}

					&:before {
						content: '+ ';
					}
				}
			}
		}

		.priceRulesTitleWrapper {
			
			position: relative;
			overflow: auto;
			height: $prrHeaderHeight;

			.priceRulesTitle {
				color: $pricingRuleThemeColor;
				background: $prrTitleBackground;
				font-size: 18px;
				font-weight: 700;
			}
		}
		
		.priceRulesHeaderWrapper {

			overflow: auto;

			.priceRulesHeader {

				margin: 0.5*$pricingRuleMargin 0;
				transition: margin $breakTransition;

				@media #{$break768} {
					margin: 0.5*$pricingRuleMargin 0;
				}

				.headerLabel {
					display: inline-block;
					vertical-align: top;

					.headerValueWrapper {
						position: relative;
						height: $prrHeaderHeight;
						font-size: 14px;
						font-weight: 700;
					}
				}

				.languageLabel.headerLabel {
					width: $prrInputLanguageWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: $prrInputLanguageWidthMobile;
					}

					.headerValueWrapper {
						margin: 0 $pricingRuleMargin 0 0.5*$pricingRuleMargin;
						transition: width $breakTransition;
					}
				}

				.conditionLabel.headerLabel {
					width: $prrInputConditionWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: $prrInputConditionWidthMobile;
					}

					.headerValueWrapper {
						transition: width $breakTransition;

						.headerValue {

							@media #{$break480} {
								justify-content: flex-start;
							}
						}
					}
				}

				.priceLabel.headerLabel {
					width: calc(50% - #{0.5*$prrInputLanguageWidth} - #{0.5*$prrInputConditionWidth} - #{0.5*$prrInputActionWidth});
					transition: width $breakTransition;

					@media #{$break768} {
						width: calc(50% - #{0.5*$prrInputLanguageWidthMobile} - #{0.5*$prrInputConditionWidthMobile} - #{0.5*$prrInputActionWidthMobile});
					}

					@media #{$break480} {
						display: none;
					}

					.headerValueWrapper {
						transition: width $breakTransition;

						.headerValue {

							@media #{$break480} {
								justify-content: flex-start;
							}
						}
					}
				}

				.actionsLabel.headerLabel {
					width: $prrInputActionWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: $prrInputActionWidthMobile;
					}

					.headerValueWrapper {
						width: $prrInputActionWidth;
						transition: width $breakTransition;

						@media #{$break768} {
							width: $prrInputActionWidthMobile;
						}
					}
				}
			}
		}

		.priceRuleWrapper {
			padding: 0.25*$pricingRuleMargin 0;

			&:nth-child(even) {
				background: $prrRowBackgroundAlternate;
			}
		}
	}
}


//////////////////////////////////////
// ProductLinePricingMatrixRuleEdit //
//////////////////////////////////////


// Titan colors
$prrRowInputBorder: $TITAN_TEXT_COLOR_LIGHT;
$prrRowInputErrorColor: $errorRed;
$prrRowLoadingBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
$prrRowActionsWidth: 120px;
$prrRowActionsWidthMobile: 120px;
$prrRowInputLabelHeight: 12px;
$prrRowLoadingIconHeight: 18px;


.ProductLinePricingMatrixRuleEdit {

	.priceRuleWrapper {

		position: relative;

		.rowLoadingWrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background: rgba($prrRowLoadingBackground, 0.7);

			.loadingIconWrapper {
				position: absolute;
				top: calc(50% - #{0.5*$prrRowLoadingIconHeight});
				left: calc(50% - #{0.5*$prrRowLoadingIconHeight});
				width: $prrRowLoadingIconHeight;
				height: $prrRowLoadingIconHeight;

				svg {
					fill: $pricingRuleAccent;
				}
			}
		}

		.languageWrapper {

			display: inline-block;
			vertical-align: top;
			width: $prrInputLanguageWidth;
			transition: width $breakTransition;

			@media #{$break768} {
				width: $prrInputLanguageWidthMobile;
			}

			.languageValue {
				display: inline-block;
				vertical-align: middle;
				padding: 0 0 0 0.5*$pricingRuleMargin;
			}

			.languageInput {

				.ruleInputDropdown {
					margin: 0 0.5*$pricingRuleMargin 0 0;

					option {
						font-size: 14px;
					}
				}
			}
		}

		.conditionWrapper {
			display: inline-block;
			vertical-align: top;
			width: $prrInputConditionWidth;
			text-align: center;
			transition: width $breakTransition;

			@media #{$break768} {
				width: $prrInputConditionWidthMobile;
			}

			.conditionValue {
				display: inline-block;
				vertical-align: middle;
				width: auto;
			}

			.conditionInput {

				.ruleInputDropdown {
					margin: 0 0.5*$pricingRuleMargin 0 0;

					select {
						font-size: 14px;
					}
				}
			}
		}

		.priceWrapper {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - #{0.5*$prrInputLanguageWidth} - #{0.5*$prrInputConditionWidth} - #{0.5*$prrInputActionWidth});
			text-align: center;
			transition: width $breakTransition;

			@media #{$break768} {
				width: calc(50% - #{0.5*$prrInputLanguageWidthMobile} - #{0.5*$prrInputConditionWidthMobile} - #{0.5*$prrInputActionWidthMobile});
			}

			.priceValue {
				display: inline-block;
				vertical-align: middle;
				width: auto;

				@media #{$break480} {
					display: none;
				}

				.priceNumber {
					font-weight: 400;
				}

				.pricePercent {
					font-weight: 300;
				}
			}

			.priceInput {

				position: relative;
				text-align: center;

				.priceInputLabel {
					display: none;
					font-size: 10px;
					font-weight: 700;
					text-align: left;
					padding: 0 0 0.5*$pricingRuleMargin 0;
					padding: 0 0.5*$pricingRuleMargin;

					@media #{$break480} {
						display: block;
					}
				}

				input {
					border: 1px solid $prrRowInputBorder;
					border-radius: 0;
					padding: 0 0.5*$pricingRuleMargin;
					font-size: 12px;
					width: calc(100% - #{2*$pricingRuleMargin});
					height: $prrRowInputHeight - 2px;
					text-align: center;
				}

				.percentOverlay {

					position: absolute;
					bottom: 0;
					right: 0;
					height: $prrRowInputHeight;
					width: 1.5*$prrRowInputHeight;
					cursor: default;
					user-select: none;
					pointer-events: none;

					display: flex;
					align-items: center;
					justify-content: center;

					font-size: 14px;
					font-weight: 300;
				}
			}
		}

		.actionsWrapper {
			display: inline-block;
			vertical-align: top;
			width: $prrInputActionWidth;
			transition: width $breakTransition;

			@media #{$break768} {
				width: $prrInputActionWidthMobile;
			}

			.actionsLiner {

				text-align: right;

				.editButton {
					cursor: pointer;
					vertical-align: top;
					border: none;
					outline: none;
					padding: 0 0.5*$prrRowInputHeight;
					color: $pricingRuleAccentText;
					background: $pricingRuleAccent;
					width: 2*$prrRowInputHeight;
					height: $prrRowInputHeight;
					font-size: 12px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition;

					&:hover {
						background: $pricingRuleAccentHover;
					}

					.buttonIcon {
						width: 1.0*$prrRowInputHeight;
						height: 0.7*$prrRowInputHeight;

						svg {
							fill: $pricingRuleAccentText;
						}
					}
				}

				.deleteButton {
					cursor: pointer;
					vertical-align: top;
					background: none;
					border: none;
					outline: none;
					padding: 0 0.5*$pricingRuleMargin;
					width: $prrRowInputHeight;
					margin: 0 0 0 0.5*$pricingRuleMargin;
					font-size: $prrRowInputHeight;
					font-weight: 300;
					height: $prrRowInputHeight;
					line-height: $prrRowInputHeight;
					transition: font-weight $hoverTransition;

					&:hover {
						font-weight: 700;
					}
				}

				.deleteSpacer {
					display: inline-block;
					vertical-align: top;
					width: $prrRowInputHeight + 0.5*$pricingRuleMargin;
					height: $prrRowInputHeight;
				}
			}
		}

		.rowOpenWrapper {

			font-size: 14px;

			.rowWrapper {

				.languageWrapper {

					@media #{$break480} {
						display: none;
					}
				}

				.conditionWrapper {

					@media #{$break480} {
						display: none;
					}
				}

				.priceWrapper {

					@media #{$break480} {
						width: calc(50% - #{0.5*$prrInputActionWidthMobile});
					}
				}

				.rowError {
					color: $prrRowInputErrorColor;
					font-size: 14px;
					font-weight: 700;
					padding: 0.5*$pricingRuleMargin 0;
				}
			}
		}
		
		.rowClosedWrapper {
			height: $prrRowInputHeight;
			font-size: 14px;
		}
	}
}















