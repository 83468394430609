

@use '../../../../style/import' as *;




/////////////
// Account //
/////////////


// Titan colors
// None yet

// Theme colors
$accountPrimary: $THEME_PRIMARY_COLOR;
$accountPrimaryHover: $THEME_PRIMARY_COLOR_HOVER;
$accountPrimaryText: $THEME_PRIMARY_COLOR_TEXT;

$accountColor: $THEME_TEXT_COLOR;
$accountColorHover: $THEME_TEXT_COLOR_HOVER;

$accountBlockBackground: $THEME_BACKGROUND;

// General variables
$accountFormMaxWidth: 800px;
$accountMargin: 10px;
$accountSpacing: 25px;


.Account {

}


/////////////////
// AccountPage //
/////////////////


// Titan colors
// None yet

// Theme colors
$accountLoadingIconColor: $THEME_PRIMARY_COLOR;


// General variables
$accountLoadingIconWidth: 60px;
$accountErrorMaxHeight: 60px;
$accountPaginationRowHeight: 20px;
$accountPaginationOffset: 4px;


.AccountPage {

	.accountBody {

		margin: $accountMargin $accountSpacing $accountSpacing $accountSpacing;
		
		.accountBlock {

			position: relative;
			overflow: auto;
			margin: 0 0 $accountSpacing 0;
			background: $accountBlockBackground;

			.accountBlockLiner {
				margin: $accountMargin;

				.accountBlockTitle {
					font-size: 22px;
			    font-weight: 700;
			    text-transform: uppercase;
			    padding: 0 0 $accountMargin/2 0;
			    border-bottom: 2px solid $accountPrimary;
			    transition: font-size $breakTransition;
				}

				.accountBlockBody {
					margin: 2*$accountMargin 0 0 0;

					.accountForm {
						width: 100%;
						max-width: $accountFormMaxWidth;

						.present.accountFormError {
							max-height: $accountErrorMaxHeight;
							transition: max-height $slideTransition;
						}

						.accountFormError {
							overflow: hidden;
							height: auto;
							max-height: 0;
							text-align: center;
							transition: max-height $slideFastTransition;

							.errorWrapper {
								color: $errorRed;
								font-size: 16px;
								font-weight: 700;
								padding: 0 0 2*$accountMargin 0;
							}
						}

						.accountFormFieldWrapper {
							display: inline-block;
							vertical-align: top;
							width: calc(50% - #{$accountMargin});
							margin: 0 $accountMargin $accountMargin 0;
						}
					}
				}
			}

			.accountBlockPending {

				.accountBlockPendingScreen {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $accountBlockBackground;
					opacity: 0.6;
				}

				.accountBlockPendingWrapper {
					position: absolute;
					top: calc(50% - #{$accountLoadingIconWidth}/2);
					left: calc(50% - #{$accountLoadingIconWidth}/2);
					width: $accountLoadingIconWidth;
					height: $accountLoadingIconWidth;

					svg {
						fill: $accountLoadingIconColor;
					}
				}
			}
		}

		.accountPagination {

			text-align: center;

			.atpControls {

				margin: $accountPaginationRowHeight 0 0 0;
				
				.lhsControls.disabled {

					.backOne {

						cursor: default;

						&:hover {
							.leftArrow {
								border-right-color: $disabledColor;
							}
						}

						.leftArrow {
							border-right-color: $disabledColor;
						}
					}

					.backAll {
						border-color: $disabledColor;
						cursor: default;

						&:hover {
							border-color: $disabledColor;

							.leftArrow {
								border-right-color: $disabledColor;
							}
						}

						.leftArrow {
							border-right-color: $disabledColor;
						}
					}
				}

				.lhsControls {

					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: 3*$accountPaginationRowHeight;
					height: $accountPaginationRowHeight;

					.leftArrow {
						position: absolute;
						top: 0;
						left: -1*$accountPaginationOffset;
						width: 0;
						height: 0;

						border-top: 0.5*$accountPaginationRowHeight solid transparent;
						border-bottom: 0.5*$accountPaginationRowHeight solid transparent;
						border-right: 0.75*$accountPaginationRowHeight solid $accountColor;
						transition: border $hoverTransition;
					}

					.backAll {
						cursor: pointer;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						width: $accountPaginationRowHeight - $accountPaginationOffset;
						border-left: $accountPaginationOffset solid $accountColor;
						transition: border $hoverTransition;

						&:hover {
							border-color: $accountColorHover;

							.leftArrow {
								border-right-color: $accountColorHover;
							}
						}
					}

					.backOne {
						cursor: pointer;
						position: absolute;
						top: 0;
						bottom: 0;
						left: $accountPaginationRowHeight + 2*$accountPaginationOffset;
						width: $accountPaginationRowHeight;
						transition: border $hoverTransition;

						&:hover {
							.leftArrow {
								border-right-color: $accountColorHover;
							}
						}
					}
				}

				.rhsControls.disabled {

					.forwardOne {
						
						cursor: default;

						&:hover {
							.rightArrow {
								border-left-color: $disabledColor;
							}
						}

						.rightArrow {
							border-left-color: $disabledColor;
						}
					}

					.forwardAll {
						border-color: $disabledColor;
						cursor: default;

						&:hover {
							border-color: $disabledColor;

							.rightArrow {
								border-left-color: $disabledColor;
							}
						}

						.rightArrow {
							border-left-color: $disabledColor;
						}
					}
				}

				.rhsControls {

					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: 3*$accountPaginationRowHeight;
					height: $accountPaginationRowHeight;

					.rightArrow {
						position: absolute;
						top: 0;
						right: -1*$accountPaginationOffset;;
						width: 0;
						height: 0;

						border-top: 0.5*$accountPaginationRowHeight solid transparent;
						border-bottom: 0.5*$accountPaginationRowHeight solid transparent;
						border-left: 0.75*$accountPaginationRowHeight solid $accountColor;
						transition: border $hoverTransition;

						&:hover {
							border-left-color: $accountColorHover;
						}
					}

					.forwardAll {
						cursor: pointer;
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: $accountPaginationRowHeight - $accountPaginationOffset;
						border-right: $accountPaginationOffset solid $accountColor;
						transition: border $hoverTransition;

						&:hover {
							border-color: $accountColorHover;

							.rightArrow {
								border-left-color: $accountColorHover;
							}
						}
					}

					.forwardOne {
						cursor: pointer;
						position: absolute;
						top: 0;
						bottom: 0;
						right: $accountPaginationRowHeight + 2*$accountPaginationOffset;
						width: $accountPaginationRowHeight;
						transition: border $hoverTransition;

						&:hover {
							border-color: $accountColorHover;

							.rightArrow {
								border-left-color: $accountColorHover;
							}
						}
					}
				}

				.currentPage.loadingPage {
					font-weight: 300;
				}

				.currentPage {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					margin: 0 2*$accountPaginationOffset;
					font-weight: 400;

					.pageNumber {
						font-weight: 700;
					}
				}
			}

			.atpSize {

				margin: $accountPaginationRowHeight 0 0 0;

				.atpSizeCopy {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					font-size: 13px;
					font-weight: 300;
					margin: 0 $accountPaginationOffset 0 0;
				}

				.atpSizeDropdown {
					display: inline-block;
					vertical-align: middle;
					width: auto;
					border-bottom: 1px solid $accountColor;
					cursor: pointer;

					select {
						border: none;
						background: none;
						font-size: 13px;
					}
				}
			}
		}
	}
}


//////////////////
// AccountTitle //
//////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AccountTitle {

	color: $accountColor;

	.accountTitleFull {
		margin: $accountSpacing $accountSpacing 0 $accountSpacing;
		font-family: $fontLato;
		font-size: 32px;
		transition: font-size $breakTransition;

		@media #{$break1200} {
			font-size: 28px;
		}

		@media #{$break1024} {
			display: none;
		}

		.titleBreadcrumbLink {
			color: $accountColor;
		}

		.titleBreadcrumbChevron {
			color: $accountPrimary;
			font-family: $fontLato;
			font-weight: 300;
			margin: 0 5px;
		}

		.titleMain {

		}
	}

	.accountTitleMobile {

		display: none;
		position: relative;
		margin: $accountSpacing $accountSpacing 0 $accountSpacing;
		font-family: $fontLato;
		font-size: 28px;
		transition: font-size $breakTransition, margin $breakTransition;

		@media #{$break1024} {
			display: block;
		}

		@media #{$break768} {
			margin: $accountSpacing $accountSpacing/2 0 $accountSpacing;
			font-size: 20px;
		}

		.atBack {

			display: inline-block;
			vertical-align: middle;
			padding: 0 $accountSpacing/2 0 0;

			.atBackArrow {
				display: inline-block;
				vertical-align: middle;
				color: $accountPrimary;
				font-family: $fontLato;
				font-weight: 300;
				font-size: 36px;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 24px;
				}		
			}

			.atBackLabel {
				display: inline-block;
				vertical-align: middle;
				font-family: $fontLato;
				font-size: 20px;
				text-transform: uppercase;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}
			}
		}

		.atMobileTitle.noBack {
			border-left: none;
			padding: 0;
		}

		.atMobileTitle {
			display: inline-block;
			vertical-align: middle;
			border-left: 1px solid $accountPrimary;
			padding: 0 0 0 $accountSpacing/2;
		}
	}
}



/////////////////
// AccountMenu //
/////////////////


// Titan colors
// None yet

// Theme colors
$accountMenuBackground: $THEME_LEFT_MENU_CONTROL_BACKGROUND;
$accountMenuBackgroundHover: $THEME_LEFT_MENU_CONTROL_BACKGROUND_HOVER;
$accountMenuColor: $THEME_LEFT_MENU_CONTROL_BACKGROUND_TEXT;

// General variables
$accountMenuHorizontalSpacing: 25px;
$accountMenuHorizontalTabletSpacing: 10px;

$accountMenuLinkHeight: 40px;
$accountMenuLinkHeightSpacing: 8px;

$accountMenuLinkHeightTablet: 60px;
$accountMenuLinkHeightTabletSpacing: 10px;


.AccountMenu {

	margin: $accountMenuHorizontalSpacing 0;
	transition: margin $breakTransition;

	@media #{$break1200} {
		margin: $accountMenuHorizontalTabletSpacing 0;
	}

	.adminMenuWrapper {

		padding: 0 0 2*$accountMenuHorizontalSpacing 0;

		.adminMenuTitle {
			font-family: $fontLato;
			color: $accountMenuColor;
			font-size: 24px;
			padding: 0 $accountMenuHorizontalSpacing 20px $accountMenuHorizontalSpacing;
			height: auto;
			max-height: 100px;
			overflow: hidden;
			transition: max-height $breakTransition, padding $breakTransition;

			@media #{$break1200} {
				padding-bottom: 0;
				max-height: 0;
			}
		}

		.selected.adminMenuLink {
			background: $accountPrimary;

			&:hover {
				background: $accountPrimaryHover;
			}
		}

		.adminMenuLink {
			position: relative;
			display: block;
			overflow: hidden;
			margin: 0 0;
			padding: 0 $accountMenuHorizontalSpacing;
			height: $accountMenuLinkHeight;
			color: $accountMenuColor;
			transition: background $hoverTransition, height $breakTransition, padding $breakTransition;

			&:hover {
				background: $accountMenuBackgroundHover;
			}

			@media #{$break1200} {
				height: $accountMenuLinkHeightTablet;
				padding: 0 $accountMenuHorizontalTabletSpacing;
			}

			/*@media #{$break600} {
				height: $accountMenuLinkHeightTablet;
				padding: 0 $accountMenuHorizontalTabletSpacing;
			}*/

			.amLinkIconWrapper {

				position: absolute;
				top: $accountMenuLinkHeightSpacing;
				left: $accountMenuHorizontalSpacing;
				width: $accountMenuLinkHeight - ( 2 * $accountMenuLinkHeightSpacing );
				height: $accountMenuLinkHeight - ( 2 * $accountMenuLinkHeightSpacing );
				transition: top $breakTransition, left $breakTransition, width $breakTransition, height $breakTransition;

				@media #{$break1200} {
					top: $accountMenuLinkHeightTabletSpacing;
					left: $accountMenuLinkHeightTabletSpacing;
					width: calc(100% - #{( 2 * $accountMenuLinkHeightTabletSpacing )});
					height: $accountMenuLinkHeightTablet - ( 1 * $accountMenuLinkHeightTabletSpacing );
				}

				@media #{$break1200} {
					top: $accountMenuLinkHeightTabletSpacing;
					left: $accountMenuLinkHeightTabletSpacing;
					width: calc(100% - #{( 2 * $accountMenuLinkHeightTabletSpacing )});
					height: $accountMenuLinkHeightTablet - ( 1 * $accountMenuLinkHeightTabletSpacing );
				}

				@media #{$break600} {
					height: $accountMenuLinkHeightTablet - ( 2 * $accountMenuLinkHeightTabletSpacing );
				}

				.amIcon {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					svg {
						fill: $accountMenuColor;
					}

					.iconSVGWrapper {

						transition: margin $breakTransition, width $breakTransition, height $breakTransition;

						@media #{$break1200} {
							margin: 0 auto;
							width: 60%;
							height: 60%;
						}

						@media #{$break600} {
							width: 100%;
							height: 100%;
						}
					}

					.iconLabel {
						font-size: 10px;

						@media #{$break600} {
							display: none;
						}
					}
				}

				.amIconDesktop {
					display: block;

					@media #{$break1200} {
						display: none;
					}
				}

				.amIconMobile {
					display: none;

					@media #{$break1200} {
						display: block;
					}
				}
			}

			.amLinkLabel {
				margin-left: $accountMenuLinkHeight - ($accountMenuLinkHeightSpacing / 2);
				line-height: $accountMenuLinkHeight;

				@media #{$break1200} {
					display: none;
				}
			}
		}
	}
}


///////////////////////
// AccountInputField //
///////////////////////


// Titan colors
// None yet

// Theme colors
$accountInputCancelColor: $TITAN_TEXT_COLOR;
$accountInputCancelColorHover: $TITAN_TEXT_COLOR_HOVER;
$accountInputColor: $THEME_TEXT_COLOR;
$accountInputSaveIconColor: $TITAN_PRIMARY_COLOR_TEXT;

// General variables
$accountInputCancelWidth: 30px;
$accountInputHeight: 30px;
$accountInputLabelHeight: 20px;
$accountInputSaveWidth: 50px;
$accountInputToggleWidth: 80px;


.AccountInputField {

	height: auto !important;

	.accountInputLabel {
		height: $accountInputLabelHeight;
		line-height: $accountInputLabelHeight;
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
	}

	.aiEdit {

		position: relative;

		.aiEditInputWrapper {

			.aiEditInput {
				border-left: 1px solid $accountInputColor;
				border-top: 1px solid $accountInputColor;
				border-bottom: 1px solid $accountInputColor;
				border-right: none;
		    width: calc(100% - 2*#{$accountMargin} - #{$accountInputSaveWidth} - #{$accountInputCancelWidth} - 1px);
		    font-size: 16px;
		    padding: 0 $accountMargin;
		    margin: 0;
		    height: $accountInputHeight - 2px;
			}
		}

		.aiEditSaveAction {

			position: absolute;
			top: 0;
			height: $accountInputHeight;
			right: $accountInputCancelWidth;
			width: $accountInputSaveWidth;

			cursor: pointer;
			background: $accountPrimary;
			transition: background $hoverTransition;

			&:hover {
				background: $accountPrimaryHover;
			}

			.aiEditSaveActionIconWrapper {

				position: absolute;
				top: 10%;
				bottom: 10%;
				left: 27%;
				width: 46%;

				.aiEditSaveActionIcon {

					svg {
						fill: $accountInputSaveIconColor;
					}
				}
			}
		}

		.aiEditCancelAction {

			position: absolute;
			top: 0;
			height: $accountInputHeight;
			right: 0;
			width: $accountInputCancelWidth;

			.aiEditCancelActionIconWrapper {
				cursor: pointer;
				color: $accountInputCancelColor;
				font-size: 32px;
				font-weight: 100;
				transition: color $hoverTransition;
					
				&:hover {
					color: $accountInputCancelColorHover;
				}
			}
		}
	}

	.adminViewValue.configValue {

		min-height: $accountInputHeight;

		.adminViewValueData {
			display: inline-block;
			vertical-align: middle;
			max-width: calc(100% - #{$accountInputToggleWidth} - #{$accountMargin});
		}

		.adminViewValueToggle.noMainValue {
			margin: 0 0 0 0;
		}

		.adminViewValueToggle {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 $accountMargin;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			color: $accountPrimary;
			line-height: $accountInputHeight/2;
			padding: $accountInputHeight/4 0;
			max-width: $accountInputToggleWidth;
			transition: color $hoverTransition;

			&:hover {
				color: $accountPrimaryHover;
			}
		}
	}
}


///////////////////
// AccountOrders //
///////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$accountOrdersIconWidth: 60px;
$accountOrdersMinHeight: 300px;
$accountOrdersSearchHeight: 40px;
$accountOrdersSearchHeightMobile: 32px;
$accountOrdersSearchButtonWidth: 50px;
$accountOrdersSearchButtonWidthMobile: 40px;


.AccountOrders {

	.accountBody {

		.orderControlWrapper {

			position: relative;
			height: $accountOrdersSearchHeight;
			margin: 0 0 2*$accountMargin 0;
			transition: height $breakTransition, margin $breakTransition;

			@media #{$break1024} {
				height: $accountOrdersSearchHeightMobile;
				margin: 0 0 $accountMargin 0;
			}

			@media #{$break768} {
				position: relative;
				right: unset;
				width: 100%;
			}

			.qsInnerWrapper {

				position: relative;
				background: white;
				border: 1px solid $accountPrimary;
				height: $accountOrdersSearchHeight - 2px;
				transition: height $breakTransition;

				@media #{$break1024} {
					height: $accountOrdersSearchHeightMobile - 2px;
				}

				.qsInputWrapper {

					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: calc(100% - #{$accountOrdersSearchButtonWidth});
					transition: width $breakTransition;

					@media #{$break1024} {
						width: calc(100% - #{$accountOrdersSearchButtonWidthMobile});
					}

					input {
						border: 0;
						margin: 0;
						padding: $accountMargin 2*$accountMargin;
						width: calc(100% - 4*#{$accountMargin});
						height: $accountOrdersSearchHeight - 2*$accountMargin - 2px;
						transition: padding $breakTransition, height $breakTransition, width $breakTransition;

						@media #{$break1024} {
							padding: $accountMargin/2 $accountMargin;
							width: calc(100% - 2*#{$accountMargin});
							height: $accountOrdersSearchHeightMobile - $accountMargin - 2px;
						}
					}
				}

				.qsButtonWrapper {

					position: absolute;
					top: -1px;
					right: -1px;
					bottom: -1px;
					width: $accountOrdersSearchButtonWidth + 1px;

					cursor: pointer;
					background: $accountPrimary;
					color: $accountPrimaryText;
					transition: background $hoverTransition, width $breakTransition;

					&:hover {
						background: $accountPrimaryHover;
					}

					@media #{$break1024} {
						width: $accountOrdersSearchButtonWidthMobile;
					}

					.qsButtonInnerWrapper {
						
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;

						.qsIcon {
							position: absolute;
							top: $accountMargin/2;
							right: $accountMargin/2;
							bottom: $accountMargin/2;
							left: $accountMargin/2;
							transition: top $hoverTransition, right $breakTransition, left $hoverTransition, bottom $breakTransition;

							@media #{$break1024} {
								top: 0.8*$accountMargin;
								right: 0.8*$accountMargin;
								bottom: 0.8*$accountMargin;
								left: 0.8*$accountMargin;
							}

							svg {
								fill: $accountPrimaryText;
							}
						}
					}
				}
			}
		}

		.accountBlock.orderBlock {
			min-height: $accountOrdersMinHeight;

			.ordersListWrapper {

				.ordersListEmpty {

					.ordersEmptyIconWrapper {
						margin: 8*$accountMargin auto $accountMargin auto;
						width: $accountOrdersIconWidth;
						height: $accountOrdersIconWidth;
					}

					.ordersEmptyLabel {
						text-align: center;
						font-size: 24px;
						font-weight: 300;
					}
				}

				.ordersList {

				}
			}
		}
	}
}


///////////////////////////
// AccountOrdersLineItem //
///////////////////////////


// Titan colors
// None yet

// Theme colors
$accountOrdersLineItemActionBackground: $THEME_BACKGROUND;
$accountOrdersLineItemActionBackgroundHover: $THEME_PRIMARY_ULTRALIGHT;
$accountOrdersLineItemHeaderBackground: $THEME_BACKGROUND_CONTRAST;
$accountOrdersLineItemHeaderBorder: $THEME_RULE_COLOR;

// General variables
$accountOrdersLineItemActionHeight: 26px;
$accountOrdersLineItemDateBlockMinWidth: 120px;
$accountOrdersLineItemSubheaderActionWidth: 160px;


.AccountOrdersLineItem {

	.aoLiner {

		margin: 0 0 4*$accountMargin 0;

		.aoHeader {

			overflow: auto;
			background: $accountOrdersLineItemHeaderBackground;
			border-bottom: 1px solid $accountOrdersLineItemHeaderBorder;

			.aoHeaderLiner {
				position: relative;
				margin: $accountMargin $accountMargin 0 $accountMargin;
				padding: 0 6*$accountMargin 0 0;

				.fixed.headerBlock {
					position: absolute;
					display: block;
					top: 0;
					right: 0;
					margin: 0;
					text-align: right;
				}

				.dateBlock.headerBlock {
					min-width: $accountOrdersLineItemDateBlockMinWidth;
				}

				.headerBlock {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 2*$accountMargin $accountMargin 0;

					.headerTitle {
						font-size: 16px;
						font-weight: 700;
						text-transform: uppercase;
						margin: 0 0 $accountMargin/2 0;
					}

					.headerValue {
						font-size: 16px;
						font-weight: 300;
					}
				}
			}
		}

		.aoSubheader {

			overflow: auto;

			.aoSubheaderLiner {
				position: relative;
				margin: $accountMargin 0 $accountMargin $accountMargin;

				.subheaderDetails {

					display: inline-block;
					vertical-align: top;
					width: calc(100% - #{$accountOrdersLineItemSubheaderActionWidth});

					.subheaderBlock {
						display: inline-block;
						vertical-align: top;
						width: auto;
						margin: 0 2*$accountMargin $accountMargin 0;

						.subheaderTitle {
							font-size: 14px;
							font-weight: 700;
							text-transform: uppercase;
							margin: 0 0 $accountMargin/2 0;
						}

						.subheaderValue {
							font-size: 14px;
							font-weight: 300;
						}
					}
				}

				.subheaderActions {
					display: inline-block;
					vertical-align: top;
					width: $accountOrdersLineItemSubheaderActionWidth;

					.actionElement {
						position: relative;
						display: block;
						width: calc(100% - 4px);
						height: $accountOrdersLineItemActionHeight;
						border: 2px solid $accountPrimary;
						color: $accountPrimary;
						background: $accountOrdersLineItemActionBackground;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition, border $hoverTransition;

						&:hover {
							background: $accountOrdersLineItemActionBackgroundHover;
						}
					}
				}
			}
		}

		.aoCartWrapper {

			.aoCartTitleWrapper {

				margin: 0 0 $accountMargin/2 0;

				.aoCartTitleValue {
					display: inline-block;
					vertical-align: baseline;
					font-size: 18px;
					font-weight: 700;
				}

				.aoCartTitleCount {
					display: inline-block;
					vertical-align: baseline;
					margin: 0 0 0 $accountMargin/2;
					font-size: 16px;
					font-weight: 300;
				}
			}

			.aoCart {

				.MiniCart {

					.MiniCartItem {

						.miniCartItemWraper {

							.mciPriceWrapper {

								.priceWrapper {
									font-size: 16px;
								}

								.quantityReadOnlyWrapper {

									.quantRoValue {
										font-size: 16px;
									}
								}
							}
						}
					}
				}

				.seeAllWrapper {

					text-align: center;

					.seeAllButton {
						position: relative;
						display: inline-block;
						border: 2px solid $accountPrimary;
						color: $accountPrimary;
						background: $accountOrdersLineItemActionBackground;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						padding: 0.5*$accountMargin 2*$accountMargin;
						transition: background $hoverTransition, border $hoverTransition;

						&:hover {
							background: $accountOrdersLineItemActionBackgroundHover;
						}
					}
				}
			}
		}
	}
}


//////////////////////
// AccountOrderView //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$accountOrderInlineIconWidth: 12px;
$accountOrderViewBlockMinHeight: 200px;
$accountOrderViewSummaryWidth: 300px;


.AccountOrderView {

	.accountBody {

		.detailsBlock.accountBlock {
			min-height: $accountOrderViewBlockMinHeight;

			.accountBlockLiner {

				.accountBlockBody {

					.detailsWrapper {

						.detailInnerBlock {

							display: inline-block;
							vertical-align: top;
							margin: 0 $accountMargin 0 0;
							width: calc(33.3% - #{$accountMargin});
							transition: width $breakTransition, margin $breakTransition;

							@media #{$break1024} {
								width: calc(50% - #{$accountMargin});
							}

							@media #{$break600} {
								margin: 0;
								width: 100%;
							}

							.detailBlockTitle {
								margin: 0 0 $accountMargin/4 0;
								font-size: 18px;
								font-weight: 700;
							}

							.detailBlockBody {
								margin: 0 0 2*$accountMargin 0;

								.paymentRecordLine {

									.prDisplayBrand {
										font-weight: 700;
									}

									.prDisplayNumber {
										margin: 0 0 0 1.5*$accountMargin;
									}

									.prDisplayExp {
										margin: 0 0 0 1.5*$accountMargin;
										font-weight: 300;
									}
								}

								.methodName {

								}

								.methodDescription {
									font-weight: 300;
									margin: 0 0 $accountMargin/2 0;
								}

								.methodDataBlock {

									.methodDataLabel {
										font-size: 12px;
										font-weight: 700;

										&:after {
											content: ':';
										}
									}

									.methodDataValueWrapper {

										font-size: $accountOrderInlineIconWidth + 2px;

										.methodDataValue {
											font-weight: 400;
										}
										
										.methodDataValueIcon {
											display: inline-block;
											vertical-align: top;
										}

										.methodDataIcon {
											display: inline-block;
											vertical-align: top;
											width: $accountOrderInlineIconWidth;
											height: $accountOrderInlineIconWidth;
											margin: $accountMargin/8 0 0 $accountMargin/2;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.itemsBlock.accountBlock {
			min-height: $accountOrderViewBlockMinHeight;

			.accountBlockLiner {

				.accountBlockBody {

					.itemsWrapper {

						.cartViewWrapper.cartReviewViewWrapper {

						}

						.cartViewWrapper {
							display: inline-block;
							vertical-align: top;
							width: calc(100% - #{$accountOrderViewSummaryWidth} - #{2*$accountMargin});
							margin: 0 2*$accountMargin 0 0;
							transition: width $breakTransition, margin $breakTransition;

							@media #{$break900} {
								width: 100%;
								margin: 0;
							}
						}

						.summaryViewWrapper.summaryReviewViewWrapper {

						}

						.summaryViewWrapper {
							display: inline-block;
							vertical-align: top;
							width: $accountOrderViewSummaryWidth;
							transition: width $breakTransition;

							@media #{$break900} {
								width: 100%;
							}

							.summaryTitle {
								
								margin: 0 0 $accountMargin 0;
								transition: margin $breakTransition;								

								@media #{$break900} {
									margin: $accountMargin 0;
								}

								.summaryTitleValue {
									display: inline-block;
									vertical-align: top;
									width: auto;
									border-bottom: 2px solid $accountPrimary;
									font-size: 20px;
									font-weight: 700;

									@media #{$break768} {
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}



////////////////////////
// AccountStoreCredit //
////////////////////////


// Titan colors
// None yet

// Theme colors
$accountStoreCreditHistoryRowBackgroundAlt: $TITAN_PRIMARY_ULTRALIGHT;

// General variables
$accountStoreCreditHistoryMinHeight: 240px;
$accountStoreCreditTotalHeight: 28px;
$accountStoreCreditFieldDateWidth: 200px;
$accountStoreCreditFieldChangeWidth: 150px;
$accountStoreCreditFieldBalanceWidth: 150px;
$accountStoreCreditFieldMobileShrinkage: 0.9;
$accountStoreCreditHeaderHeight: 30px;


.AccountStoreCredit {

	.accountBody {
		
		.totalBlock {

			.accountBlockBody {

				position: relative;
				overflow: auto;

				.accountTotalWrapper {

					.scLabel {
						display: inline-block;
						vertical-align: top;
						font-size: 24px;
						font-weight: 300;
						height: $accountStoreCreditTotalHeight;
						line-height: $accountStoreCreditTotalHeight;

						@media #{$break600} {
							display: none;
						}
					}

					.scValue {
						display: inline-block;
						vertical-align: top;
						font-size: 20px;
						font-weight: 700;
						margin: 0 0 0 $accountMargin;
						height: $accountStoreCreditTotalHeight;
						line-height: $accountStoreCreditTotalHeight;
						transition: margin $breakTransition;

						@media #{$break600} {
							margin: 0;
						}
					}
				}

				.accountActionWrapper {

					position: absolute;
					top: 0;
					right: 0;

					@media #{$break600} {
						display: none;
					}

					.totalAction {

						display: inline-block;
						vertical-align: top;
						padding: 0 $accountMargin;
						width: auto;
						height: $accountStoreCreditTotalHeight;
						line-height: $accountStoreCreditTotalHeight;
						color: $accountPrimaryText;
						background: $accountPrimary;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition;

						&:hover {
							background: $accountPrimaryHover;
						}
					}
				}
			}
		}

		.historyBlock {

			min-height: $accountStoreCreditHistoryMinHeight;

			.accountBlockLiner {

				.accountBlockBody {

					.historyListWrapper {

						.historyListEmpty {

							.historyEmptyIconWrapper {
								margin: 4*$accountMargin auto $accountMargin auto;
								width: $accountOrdersIconWidth;
								height: $accountOrdersIconWidth;
							}

							.historyEmptyLabel {
								text-align: center;
								font-size: 24px;
								font-weight: 300;
								margin: 0 0 4*$accountMargin 0;
							}
						}

						.historyList {

							.historyHeader {

								.headerCell {
									position: relative;
									display: inline-block;
									vertical-align: top;
									height: $accountStoreCreditHeaderHeight;
									font-weight: 700;
								}

								.dateHeader {
									width: $accountStoreCreditFieldDateWidth;

									@media #{$break1024} {
										width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth;
									}

									@media #{$break600} {
										width: 50%;
									}
								}

								.descriptionHeader {
									width: calc(100% - #{$accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldChangeWidth} - #{$accountStoreCreditFieldBalanceWidth});

									@media #{$break1024} {
										width: calc(100% - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldBalanceWidth});
									}

									@media #{$break768} {
										width: calc(100% - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth});
									}

									@media #{$break600} {
										display: none;
									}
								}

								.changeHeader {
									width: $accountStoreCreditFieldChangeWidth;

									@media #{$break1024} {
										width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth;
									}

									@media #{$break600} {
										width: 50%;
									}

									.changeHeaderValue {
										margin: 0 0 0 0;

										@media #{$break768} {
											margin: 0 $accountMargin/2 0 0;
										}
									}
								}

								.balanceHeader {
									width: $accountStoreCreditFieldBalanceWidth;

									@media #{$break1024} {
										width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldBalanceWidth;
									}

									@media #{$break768} {
										display: none;
									}

									.balanceHeaderValue {
										margin: 0 $accountMargin/2 0 0;
									}
								}
							}
						}

						.historyListItem {
							background: $accountBlockBackground;

							&:nth-child(even) {
								background: $accountStoreCreditHistoryRowBackgroundAlt;
							}
						}
					}
				}
			}
		}
	}
}






////////////////////////////////
// AccountStoreCreditLineItem //
////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AccountStoreCreditLineItem {

	.ascLiner {

		.rowWrapper {

			padding: $accountMargin/2 0;

			.rowCell {
				position: relative;
				display: inline-block;
				vertical-align: top;
				font-size: 16px;
				transition: font-size $breakTransition;

				@media #{$break1024} {
					font-size: 14px;
				}
			}

			.dateCell {
				width: $accountStoreCreditFieldDateWidth;

				@media #{$break1024} {
					width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth;
				}

				.dateWrapper {

					padding: 0 $accountMargin/2;

					.dateDate {

					}

					.dateTime {
						font-weight: 300;
					}
				}
			}

			.descriptionCell {
				width: calc(100% - #{$accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldChangeWidth} - #{$accountStoreCreditFieldBalanceWidth});

				@media #{$break1024} {
					width: calc(100% - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldBalanceWidth});
				}

				@media #{$break768} {
					width: calc(100% - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldDateWidth} - #{$accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth});
				}

				.descriptionValue {

					@media #{$break600} {
						display: none;
					}
				}
			}

			.changeCell {
				width: $accountStoreCreditFieldChangeWidth;

				@media #{$break1024} {
					width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldChangeWidth;
				}

				.changeValue {
					text-align: right;
					padding: 0;
					transition: padding $breakTransition;

					@media #{$break768} {
						padding: 0 $accountMargin/2;
					}
				}
			}

			.balanceCell {
				width: $accountStoreCreditFieldBalanceWidth;

				@media #{$break1024} {
					width: $accountStoreCreditFieldMobileShrinkage * $accountStoreCreditFieldBalanceWidth;
				}

				@media #{$break768} {
					display: none;
				}

				.balanceValue {
					text-align: right;
					padding: 0 $accountMargin/2;
				}
			}
		}
	}
}








































