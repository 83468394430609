

@use '../../../../style/import' as *;




//////////////////
// Integrations //
//////////////////


// Titan colors
$integrationAccent: $TITAN_PRIMARY_COLOR;
$integrationAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$integrationAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$integrationDisabled: $disabledColor;
$integrationErrorColor: $errorRed;
$integrationTextColor: $TITAN_TEXT_COLOR;
$integrationBackground: $TITAN_BACKGROUND;
$integrationBackgroundHover: $TITAN_BACKGROUND_HOVER;

// Theme colors
$integrationThemeAccent: $THEME_PRIMARY_COLOR;
$integrationThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$integrationThemeColor: $THEME_TEXT_COLOR;
$integrationThemeColorLight: $THEME_TEXT_COLOR_LIGHT;

// General variables
$integrationLoadingHeight: 40px;
$integrationMargin: 10px;


.Integrations {

}



////////////////////
// AddIntegration //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AddIntegration {

	.adminBody {

		.adminForm {

			.addPaymentMethodForm {

				.noMethodNotice {
					margin: 2*$integrationMargin 0;
					text-align: center;
					font-size: 20px;
					font-weight: 300;
				}

				.adminFieldWrapper {

					.paymentInputLoading {

						overflow: auto;
						position: relative;
						background: rgba($integrationAccent, 0.1);

						.paymentInputLoadingIcon {
							position: absolute;
							top: $integrationMargin;
							left: $integrationMargin;
							width: $integrationLoadingHeight - 2*$integrationMargin;
							height: $integrationLoadingHeight - 2*$integrationMargin;

							svg {
								fill: $integrationAccent;
							}
						}

						.paymentInputLoadingLabel {
							color: $integrationAccent;
							height: $integrationLoadingHeight;
							line-height: $integrationLoadingHeight;
							margin: 0 $integrationMargin 0 $integrationLoadingHeight;
							text-transform: uppercase;
							font-size: 14px;
							font-weight: 700;
						}
					}

					.adminInputWrapper {

						.adminDropdownWrapper {

							.adminDropdownSelect {

								height: auto;

								.dropdownArrowWrapper {
									right: $integrationMargin;
									bottom: 1.5*$integrationMargin;
								}
							}
						}
					}

					.fieldSupplement {

						.addPaymentMethodNote {
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}



/////////////////////
// ViewIntegration //
/////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ViewIntegration {

}



///////////////////////////
// IntegrationCommonView //
///////////////////////////


// Titan colors
$integrationDisabledColor: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet


.IntegrationCommonView {

	.detailsMethodDisabled {
		color: $integrationDisabledColor;
		text-align: center;
		margin: 2*$integrationMargin 0;
		font-size: 20px;
		font-weight: 700;
	}
}


///////////////////////////
// IntegrationCommonEdit //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.IntegrationCommonEdit {

}


///////////////////////////
// IntegrationConfigView //
///////////////////////////


// Titan colors
$integrationConfigHr: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet


.IntegrationConfigView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.detailBlock {

					.detailBlockWrapper {

						.present.configError {
							height: 3.5*$integrationMargin;
						}

						.configError {
							color: $errorRed;
							font-weight: 700;
							font-size: 16px;
							height: 0;
							text-align: center;
							overflow: hidden;
							transition: height $toggleTransition;
						}

						.fullWidthConfigs {

							.adminViewItem {
								width: auto;
								height: auto;

								.adminViewValue.urlValue {
									font-size: 15px;
								}

								.adminViewComponent {
									margin: $integrationMargin/2 0 0 0;
								}
							}
						}

						.configHr {
							background: $integrationConfigHr;
							width: 80%;
							height: 1px;
							margin: $integrationMargin auto 2*$integrationMargin auto;
						}

						.halfWidthConfigs {

							.adminViewItem {

							}
						}

						.noConfigMethod {
							font-size: 20px;
							font-weight: 300;
							margin: 3*$integrationMargin 0 2*$integrationMargin 0;
							text-align: center;
						}
					}
				}
			}
		}
	}
}


///////////////////////////////////
// IntegrationShippingConfigView //
///////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.IntegrationShippingConfigView {

}


////////////////////////////
// IntegrationConfigField //
////////////////////////////


// Titan colors
$icfBorderColor: $TITAN_TEXT_COLOR;
$icfCancelColor: $TITAN_TEXT_COLOR;
$icfCancelColorHover: $TITAN_TEXT_COLOR_HOVER;
$icfSaveIconColor: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
// None yet

// General variables
$icfCancelWidth: 30px;
$icfInputHeight: 30px;
$icfSaveWidth: 50px;
$icfToggleWidth: 80px;


.IntegrationConfigField {

	height: auto !important;

	.cfEdit {

		position: relative;

		.cfEditInputWrapper {

			.cfEditInput {
				border-left: 1px solid $icfBorderColor;
				border-top: 1px solid $icfBorderColor;
				border-bottom: 1px solid $icfBorderColor;
				border-right: none;
		    width: calc(100% - 2*#{$integrationMargin} - #{$icfSaveWidth} - #{$icfCancelWidth} - 1px);
		    font-size: 16px;
		    padding: 0 $integrationMargin;
		    margin: 0;
		    height: $icfInputHeight - 2px;
			}
		}

		.cfEditSaveAction {

			position: absolute;
			top: 0;
			height: $icfInputHeight;
			right: $icfCancelWidth;
			width: $icfSaveWidth;

			cursor: pointer;
			background: $integrationAccent;
			transition: background $hoverTransition;

			&:hover {
				background: $integrationAccentHover;
			}

			.cfEditSaveActionIconWrapper {

				position: absolute;
				top: 10%;
				bottom: 10%;
				left: 27%;
				width: 46%;

				.cfEditSaveActionIcon {

					svg {
						fill: $icfSaveIconColor;
					}
				}
			}
		}

		.cfEditCancelAction {

			position: absolute;
			top: 0;
			height: $icfInputHeight;
			right: 0;
			width: $icfCancelWidth;

			.cfEditCancelActionIconWrapper {
				cursor: pointer;
				color: $icfCancelColor;
				font-size: 32px;
				font-weight: 100;
				transition: color $hoverTransition;
					
				&:hover {
					color: $icfCancelColorHover;
				}
			}
		}
	}

	.adminViewValue.configValue {

		min-height: $icfInputHeight;

		.adminViewValueData {
			display: inline-block;
			vertical-align: middle;
			max-width: calc(100% - #{$icfToggleWidth} - #{$integrationMargin});
		}

		.adminViewValueToggle.disabled {
			
			color: $integrationDisabled;
			cursor: default;

			&:hover {
				color: $integrationDisabled;
			}
		}

		.adminViewValueToggle.noMainValue {
			margin: 0 0 0 0;
		}

		.adminViewValueToggle {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 $integrationMargin;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			color: $integrationAccent;
			line-height: $icfInputHeight/2;
			padding: $icfInputHeight/4 0;
			max-width: $icfToggleWidth;
			transition: color $hoverTransition;

			&:hover {
				color: $integrationAccentHover;
			}
		}

		.adminViewValueBooleanToggle {
			display: inline-block;
			vertical-align: middle;
		}
	}
}



/////////////////////////////////
// IntegrationConfigParcelSize //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$icpInputWidth: 130px;
$icpInputWidthMobile: 90px;
$icpHeaderHeight: 28px;


.IntegrationConfigParcelSize {

	.parcelSizeLiner {

		.parcelSizeNullWrapper {

			.parcelSizeNull {

				.parcelSizeNullValue {
					font-size: 18px;
					font-weight: 300;
				}
			}
		}

		.parcelAddWrapper {

			.parcelAdd {

				.parcelToggle {

					cursor: pointer;
					display: inline-block;
					vertical-align: middle;
					margin: $integrationMargin/2 0 0 0;
					font-size: 15px;
					line-height: 18px;
					font-weight: 700;
					color: $integrationAccent;
					transition: color $hoverTransition;

					&:hover {
						color: $integrationAccentHover;						
					}

					&:before {
						content: '+ ';
					}
				}
			}
		}

		.parcelCopy {
			margin: $integrationMargin 0 0 0;
			font-size: 13px;
		}

		.parcelSizeHeaderWrapper {

			.parcelSizeHeader {

				margin: $integrationMargin 0;
				transition: margin $breakTransition;

				@media #{$break768} {
					margin: $integrationMargin 0 2*$integrationMargin 0;
				}

				.headerLabel {
					display: inline-block;
					vertical-align: top;

					.headerValueWrapper {
						position: relative;
						height: $icpHeaderHeight;
						font-size: 12px;
						font-weight: 700;
					}
				}

				.weightLabel.headerLabel {
					width: 1*$icpInputWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 1*$icpInputWidthMobile;
					}

					@media #{$break480} {
						display: none;
					}

					.headerValueWrapper {
						width: 1*$icpInputWidth - $integrationMargin;
						margin: 0 $integrationMargin 0 0;
						transition: width $breakTransition;

						@media #{$break768} {
							width: 1*$icpInputWidthMobile - $integrationMargin;
						}
					}
				}

				.dimentionsLabel.headerLabel {
					width: 1.5*$icpInputWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 1.5*$icpInputWidthMobile;
					}

					.headerValueWrapper {
						width: 1.5*$icpInputWidth;
						transition: width $breakTransition;

						@media #{$break768} {
							width: 1.5*$icpInputWidthMobile;
						}

						.headerValue {

							@media #{$break480} {
								justify-content: flex-start;
							}
						}
					}
				}

				.actionsLabel.headerLabel {
					width: 1*$icpInputWidth;
					transition: width $breakTransition;

					@media #{$break768} {
						width: 1*$icpInputWidthMobile;
					}

					.headerValueWrapper {
						width: 1*$icpInputWidth;
						transition: width $breakTransition;

						@media #{$break768} {
							width: 1*$icpInputWidthMobile;
						}
					}
				}
			}
		}

		.parcelWrapper {
			margin: 0 0 $integrationMargin/2 0;
		}
	}
}


////////////////////////////////////
// IntegrationConfigParcelSizeRow //
////////////////////////////////////


// Titan colors
$icpRowInputBorder: $TITAN_TEXT_COLOR_LIGHT;
$icpRowInputErrorColor: $errorRed;

// Theme colors
// None yet

// General variables
$icpRowActionsWidth: 120px;
$icpRowInputHeight: 20px;
$icpRowInputLabelHeight: 12px;


.IntegrationConfigParcelSizeRow {

	.parcelRowWrapper {

		position: relative;

		.rowOpenWrapper {

			.rowWrapper {

				.rowElements {

					display: inline-block;
					vertical-align: top;
					width: auto;
					max-width: calc(100% - #{$icpRowActionsWidth});
					transition: max-width $breakTransition;

					@media #{$break480} {
						max-width: 1.5*$icpInputWidthMobile;
					}

					.rowElementWrapper {

						display: inline-block;
						vertical-align: top;
						width: $icpInputWidth;
						margin: 0 0 $integrationMargin/2 0;

						@media #{$break768} {
							width: 1*$icpInputWidth;
						}

						.rowElement {

							margin: 0 $integrationMargin 0 0;

							.elementLabel {
								font-size: $icpRowInputLabelHeight;
								font-weight: 700;
								height: $icpRowInputLabelHeight;
								line-height: $icpRowInputLabelHeight;
								margin: 0 0 $integrationMargin/2 0;
							}

							.elementValue {
								font-size: 16px;
								line-height: $icpRowInputHeight;
								font-weight: 300;
							}

							.elementInput {

								input {
									border: 1px solid $icpRowInputBorder;
									border-radius: 0;
									outline: none;
									width: calc(100% - #{2*$integrationMargin} - 2px);
									height: $icpRowInputHeight - $integrationMargin/2 - 2px;
									padding: $integrationMargin/4 $integrationMargin;
									font-size: 14px;
									font-weight: 300;
									text-align: center;
								}
							}
						}
					}
				}

				.rowActions {

					display: inline-block;
					vertical-align: top;
					width: $icpRowActionsWidth;

					@media #{$break768} {
						max-width: calc(100% - #{1.5*$icpInputWidthMobile});
					}

					.rowActionsLiner {

						margin-top: $icpRowInputLabelHeight + $integrationMargin/2;

						.rowSaveAction {

							cursor: pointer;
							vertical-align: top;
							border: none;
							outline: none;
							padding: 0 $integrationMargin;
							color: $integrationAccentText;
							background: $integrationAccent;
							width: auto;
							height: $icpRowInputHeight;
							font-size: 12px;
							font-weight: 700;
							text-transform: uppercase;
							transition: background $hoverTransition;

							&:hover {
								background: $integrationAccentHover;
							}
						}

						.rowSaveCancel {
							cursor: pointer;
							vertical-align: top;
							background: none;
							border: none;
							outline: none;
							padding: 0 $integrationMargin/2;
							width: auto;
							margin: 0 0 0 $integrationMargin/2;
							font-size: $icpRowInputHeight;
							font-weight: 300;
							height: $icpRowInputHeight;
							line-height: $icpRowInputHeight;
							transition: font-weight $hoverTransition;

							&:hover {
								font-weight: 700;
							}
						}
					}
				}

				.rowError {
					color: $icpRowInputErrorColor;
					font-size: 14px;
					font-weight: 700;
					padding: $integrationMargin/2 0;
				}
			}
		}
		
		.rowClosedWrapper {

			height: $icpRowInputHeight;
			font-size: 14px;

			.weightWrapper {

				display: inline-block;
				vertical-align: top;
				width: 1*$icpInputWidth;
				transition: width $breakTransition;

				@media #{$break768} {
					width: 1*$icpInputWidthMobile;
				}

				@media #{$break480} {
					display: none;
				}

				.weightDefault {
					font-weight: 300;
					line-height: $icpRowInputHeight;
				}

				.weightValue {
					display: inline-block;
					vertical-align: middle;
				}

				.weightUnit {
					display: none;
				}
			}

			.dimensionsWrapper {
				display: inline-block;
				vertical-align: top;
				width: 1.5*$icpInputWidth;
				text-align: center;
				transition: width $breakTransition;

				@media #{$break768} {
					width: 1.5*$icpInputWidthMobile;
				}

				@media #{$break480} {
					text-align: left;
				}

				.dimensionsValue {
					display: inline-block;
					vertical-align: middle;
					width: auto;

					.dimensionX {
						display: inline-block;
						vertical-align: middle;
						width: auto;
						margin: 0 $integrationMargin/2;
						font-size: 12px;
						font-weight: 300;
						line-height: $icpRowInputHeight;
					}

					.dimension {
						display: inline-block;
						vertical-align: middle;
						width: auto;
						line-height: $icpRowInputHeight;
					}
				}
			}

			.actionsWrapper {
				display: inline-block;
				vertical-align: top;
				width: 1*$icpInputWidth;
				transition: width $breakTransition;

				@media #{$break768} {
					width: 1*$icpInputWidthMobile;
				}

				.actionsLiner {

					.editButton {
						cursor: pointer;
						vertical-align: top;
						border: none;
						outline: none;
						padding: 0 $integrationMargin;
						color: $integrationAccentText;
						background: $integrationAccent;
						width: auto;
						height: $icpRowInputHeight;
						font-size: 12px;
						font-weight: 700;
						text-transform: uppercase;
						transition: background $hoverTransition;

						&:hover {
							background: $integrationAccentHover;
						}
					}

					.deleteButton {
						cursor: pointer;
						vertical-align: top;
						background: none;
						border: none;
						outline: none;
						padding: 0 $integrationMargin/2;
						width: auto;
						margin: 0 0 0 $integrationMargin/2;
						font-size: $icpRowInputHeight;
						font-weight: 300;
						height: $icpRowInputHeight;
						line-height: $icpRowInputHeight;
						transition: font-weight $hoverTransition;

						&:hover {
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}


////////////////////////////
// IntegrationConfigOauth //
////////////////////////////


// Titan colors
$integrationOAuthRedirectBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
$integrationOAuthRedirectBlockMaxWidth: 420px;
$integrationOAuthRedirectIconWidth: 60px;


.IntegrationConfigOauthStampsAuthorize { 

	.oauthInitWrapper {

		.oauthCopy {
			margin: 0 0 $integrationMargin 0;
			text-align: center;
			font-size: 18px;
			font-weight: 300;
		}

		.oauthActionWrapper {

			margin: 0 0 $integrationMargin 0;
			text-align: center;

			.oauthAction {
				display: inline-block;
				vertical-align: top;
				color: $integrationAccentText;
				background: $integrationAccent;
				border: none;
				text-align: center;
				outline: none;
				font-size: 14px;
				font-weight: 700;
				cursor: pointer;
				text-transform: uppercase;
				padding: $integrationMargin 2*$integrationMargin;
				transition: background $hoverTransition;

				&:hover {
					background: $integrationAccentHover;
				}
			}
		}
	}
}

.IntegrationConfigOauthStampsRedirect {
	
	.loadingBlockWrapper {

		margin: 5*$integrationMargin auto;
		width: 90%;
		overflow: auto;
		max-width: $integrationOAuthRedirectBlockMaxWidth;
		background: $integrationOAuthRedirectBackground;

		.loadingBlock {

			padding: 3*$integrationMargin $integrationMargin;

			.iconWrapper {

				margin: 0 auto 2*$integrationMargin auto;
				width: $integrationOAuthRedirectIconWidth;
				height: $integrationOAuthRedirectIconWidth;

				svg {
					fill: $integrationAccent;
				}
			}

			.labelBlock {
				font-size: 24px;
				font-weight: 100;
				text-align: center;
			}
		}

		.errorBlock {

			padding: 2*$integrationMargin $integrationMargin;

			.errorLabel {
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 $integrationMargin 0;
			}

			.errorValue {
				font-weight: 300;
				margin: 0 0 2*$integrationMargin 0;
			}

			.errorAction {

				text-align: center;

				.backButton {
					display: inline-block;
					vertical-align: top;
					width: auto;
					padding: $integrationMargin 2*$integrationMargin;
					text-decoration: none;
					color: $integrationAccentText;
					background: $integrationAccent;
					transition: background $hoverTransition;

					&:hover {
						background: $integrationAccentHover;
					}
				}
			}
		}
	}
}


///////////////////////////////////////
// IntegrationDisplayStampsTestPrint //
///////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.IntegrationDisplayStampsTestPrint {

	.testPrintButton {
		margin: 0.5*$integrationMargin 0 0 0;
		border: none;
		color: $integrationAccentText;
		background: $integrationAccent;
		cursor: pointer;
		padding: 0.5*$integrationMargin 1*$integrationMargin;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		transition: background $hoverTransition;

		&:hover {
			background: $integrationAccentHover;
		}
	}

	.testPrintError {
		color: $integrationErrorColor;
		font-size: 14px;
		font-weight: 700;
	}
}


//////////////////////////////////////////////
// IntegrationDisplayStampsParcelGuardModal //
//////////////////////////////////////////////


// Titan colors
$integrationStampsParcelGuardRuleColor: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$integrationStampsParcelGuardModalHeight: 260px;


.IntegrationDisplayStampsParcelGuardModal {

	.pgTermsWrapper {

		text-align: center;
		margin: $integrationMargin 0 0 0;

		iframe.pgTerms {
			border: none;
			width: 100%;
			height: $integrationStampsParcelGuardModalHeight;
		}

		.pgHr {
			height: 1px;
			width: 80%;
			margin: $integrationMargin auto;
			background: $integrationStampsParcelGuardRuleColor;
		}

		.pgTermsCopy {
			margin: $integrationMargin 0;
			font-size: 14px;
			font-weight: 400;

			a.pgTermsLink {
				display: inline;
				color: $integrationAccent;
				cursor: pointer;
				margin: 0 0 0 0.5*$integrationMargin;
				transition: color $hoverTransition;

				&:hover {
					color: $integrationAccentHover;
				}
			}
		}
	}
}


//////////////////////////////////////////
// IntegrationConfigStampsBalanceRefill //
//////////////////////////////////////////


// Titan colors
$integrationStampsBalanceRefillInputBorder: $TITAN_RULE_COLOR;

// Theme colors
// None yet

// General variables
$integrationStampsBalanceRefillFormHeight: 200px;
$integrationStampsBalanceRefillFormMaxWidth: 600px;


.IntegrationConfigStampsBalanceRefill {

	.refillLiner {

		width: auto;
		max-width: $integrationStampsBalanceRefillFormMaxWidth;

		.editingWrapper {

			.editForm {

				.editFormLiner {

					.enabledWrapper {

					}

					.open.settingsWrapper {
						max-height: $integrationStampsBalanceRefillFormHeight;
						transition: max-height $slideTransition;
					}

					.settingsWrapper {
						overflow: hidden;
						height: auto;
						max-height: 0;
						transition: max-height $slideFastTransition;

						.settingsLiner {
							margin: $integrationMargin 0;

							.refillInputWrapper {

								position: relative;
								display: inline-block;
								vertical-align: top;
								width: 50%;
								transition: width $breakTransition, margin $breakTransition;

								@media #{$break768} {
									width: 100%;
									margin: 0 0 $integrationMargin 0;
								}

								.refillInputLabel {
									font-size: 13px;
									font-weight: 400;
								}

								.refillInputElementWrapper {

									position: relative;

									.error.refillInput {
										border-color: $integrationErrorColor;
									}

									.refillInput {
										border-width: 1px;
										border-style: solid;
										border-color: $integrationStampsBalanceRefillInputBorder;
										padding: 0.5*$integrationMargin 1.5*$integrationMargin;
										width: calc(100% - #{4*$integrationMargin} - 2px);
										text-align: right;
									}

									.currencyIndication {
										position: absolute;
										top: 0;
										left: 0;
										width: 2*$integrationMargin;
										bottom: 0;
										font-size: 14px;
										font-weight: 300;
										user-select: none;
										pointer-events: none;
										text-align: center;
									}
								}

								.refillError {
									margin: 0.5*$integrationMargin 0;
									color: $integrationErrorColor;
									font-size: 14px;
									font-weight: 700;
								}
							}
						}
					}

					.actionsWrapper {

						margin: $integrationMargin 0;
						text-align: right;
						transition: margin $breakTransition;

						@media #{$break768} {
							margin: 0 0 $integrationMargin 0;
						}

						.refillButton {
							margin: 0 $integrationMargin 0 0;
							padding: 0.5*$integrationMargin 2*$integrationMargin;
							border: none;
							font-size: 14px;
							font-weight: 700;
							text-transform: uppercase;
							cursor: pointer;
							transition: background $hoverTransition;
						}

						.cancelButton {
							color: $integrationTextColor;
							background: $integrationBackground;

							&:hover {
								background: $integrationBackgroundHover;
							}
						}

						.saveButton {
							color: $integrationAccentText;
							background: $integrationAccent;

							&:hover {
								background: $integrationAccentHover;
							}
						}
					}
				}
			}
		}
		
		.viewingWrapper {

			.viewHeaderWrapper {

				.statusWrapper {

					display: inline-block;
					vertical-align: text-bottom;
					width: auto;

					.statusValue {
						font-size: 18px;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
				
				.toggleWrapper {

					display: inline-block;
					vertical-align: text-bottom;
					width: auto;
					margin: 0 0 0 $integrationMargin;

					.toggleValue {
						font-size: 14px;
						font-weight: 700;
						color: $integrationAccent;
						cursor: pointer;
						transition: color $hoverTransition;

						&:hover {
							color: $integrationAccentHover;
						}
					}
				}
			}
		}

		.refillCopy {
			margin: $integrationMargin 0 0 0;
			font-size: 13px;

			.em {
				font-weight: 700;
			}
		}
	}
}






























