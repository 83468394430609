

@use '../../../../style/import' as *;

// Titan colors
$magicImageFlipButtonColor: $TITAN_TEXT_COLOR;
$magicImageFlipButtonColorHover: $TITAN_TEXT_COLOR_HOVER;
$magicImageFlipButtonBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
// None yet

// Component variables
$magicImageHeight: 139.34426%;
$magicImageFlipPerspective: 1000px;
$magicImageFlipButtonWidth: 20%;


////////////////
// MagicImage //
////////////////

.MagicImage {

	position: relative;
	display: inline-block;
	width: 100%;
	padding-bottom: $magicImageHeight;

	.magicImageWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		perspective: $magicImageFlipPerspective;

		img {
			width: 100%;
		}

		.flipped.magicImageFlipBody {
			transition: transform $flipFrontTransition;
			transform: rotateY(-180deg);
		}

		.magicImageFlipBody {

			position: relative;
		  width: 100%;
		  height: 100%;
		  transition: transform $flipBackTransition;
		  transform-style: preserve-3d;

			.magicImageFlipFace {
				position: absolute;
				width: 100%;
				height: 100%;
				backface-visibility: hidden;
			}

			.magicImageFlipFront {

			}

			.magicImageFlipBack {
				transform: rotateY(-180deg);
			}
		}

		.magicImageFlipButton {

			position: absolute;
			top: 25%;
			right: 10%;
			width: $magicImageFlipButtonWidth;
			padding-bottom: $magicImageFlipButtonWidth;
			background: rgba($magicImageFlipButtonBackground, 0.5);
			border: 2px solid $magicImageFlipButtonColor;
			border-radius: 50%;
			cursor: pointer;
			transition: background $hoverTransition, border $hoverTransition;

			&:hover {
				border: 2px solid $magicImageFlipButtonColorHover;
				background: $magicImageFlipButtonBackground;
			}

			.magicImageFlipButtonIcon {
				position: absolute;
				top: 20%;
				left: 20%;
				width: 60%;
				height: 60%;
			}
		}
	}
}


/////////////////
// MagicSymbol //
/////////////////

// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

// Component variables
// None yet

.MagicSymbol {

}