


@use '../../../../style/import' as *;




/////////////////////
// CartApplyCoupon //
/////////////////////


// Titan colors
// None yet

// Theme colors
$cartCouponThemeAccent: $THEME_PRIMARY_COLOR;
$cartCouponThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$cartCouponThemeAccentText: $THEME_PRIMARY_COLOR_TEXT;
$cartCouponThemeColor: $THEME_TEXT_COLOR;
$cartCouponBackground: $THEME_BACKGROUND;
$cartCouponInputBorderColor: $THEME_RULE_COLOR;
$cartCouponErrorColor: $errorRed;

// General variables
$cartCouponMargin: 10px;
$cartCouponHeight: 30px;
$cartCouponActionWidth: 40px;


.CartApplyCoupon {

	.cartCouponLiner {

		.cartCouponClosed {

			.noCoupon {

				.applyNewCoupon {
					display: inline-block;
					vertical-align: top;
					font-size: 15px;
					font-weight: 700;
					color: $cartCouponThemeAccent;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $cartCouponThemeAccentHover;
					}

					&:before {
						content: '+';
					}
				}
			}

			.removing.appliedCoupon {
				.appliedCode {
					color: $cartCouponInputBorderColor;
				}

				.appliedRemove {

					color: $cartCouponInputBorderColor;
					cursor: default;

					&:hover {
						color: $cartCouponInputBorderColor;
					}
				}
			}

			.appliedCoupon {
				
				.appliedCode {
					display: inline-block;
					vertical-align: middle;
					font-size: 15px;
					font-weight: 400;
					text-transform: uppercase;
				}

				.appliedRemove {
					display: inline-block;
					vertical-align: middle;
					font-size: 13px;
					font-weight: 700;
					margin: 0.125*$cartCouponMargin 0 0 0.5*$cartCouponMargin;
					cursor: pointer;
					color: $cartCouponThemeAccent;

					&:hover {
						color: $cartCouponThemeAccentHover;
					}
				}
			}
		}

		.cartCouponEditing {
			
			.editingInputWrapper {

				position: relative;
				height: $cartCouponHeight;

				.applyCouponForm {

					.inputWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;
						width: calc(100% - #{2*$cartCouponActionWidth});
						height: $cartCouponHeight;

						.couponInput {
							border-top: 1px solid $cartCouponInputBorderColor;
							border-left: 1px solid $cartCouponInputBorderColor;
							border-bottom: 1px solid $cartCouponInputBorderColor;
							border-right: 1px solid $cartCouponBackground !important;

							font-size: 18px;
							padding: 0.5*$cartCouponMargin $cartCouponMargin;
							width: calc(100% - #{2*$cartCouponMargin} - 2px);
							height: $cartCouponHeight - $cartCouponMargin - 2px;
						}
					}

					.submitWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;
						width: $cartCouponActionWidth;
						height: $cartCouponHeight;
						background: $cartCouponThemeAccent;
						cursor: pointer;
						transition: background $hoverTransition;

						&:hover {
							background: $cartCouponThemeAccentHover;
						}

						.submitIconWrapper {

							position: absolute;
							top: 0.2*$cartCouponHeight;
							left: calc((100% - #{0.6*$cartCouponHeight})/2);
							width: 0.6*$cartCouponHeight;
							height: 0.6*$cartCouponHeight;

							svg {
								fill: $cartCouponThemeAccentText;
							}
						}
					}

					.cancelWrapper {

						position: relative;
						display: inline-block;
						vertical-align: top;
						width: $cartCouponActionWidth;
						height: $cartCouponHeight;


						.cancelX {

							position: absolute;
							top: 0.1*$cartCouponHeight;
							left: calc((100% - #{0.8*$cartCouponHeight})/2);
							width: 0.8*$cartCouponHeight;
							height: 0.8*$cartCouponHeight;
							line-height: 0.8*$cartCouponHeight;
							font-size: 24px;
							font-weight: 100;
							color: $cartCouponThemeColor;
							text-align: center;
							cursor: pointer;
							transition: color $hoverTransition, font-weight $hoverTransition;

							&:hover {
								color: $cartCouponThemeAccent;
								font-weight: 300;
							}
						}
					}
				}
			}

			.present.couponErrorWrapper {
				max-height: $cartCouponHeight;
				transition: max-height $slideTransition;
			}

			.couponErrorWrapper {
				position: relative;
				overflow: hidden;
				height: auto;
				max-height: 0;
				transition: max-height $slideFastTransition;

				.couponError {
					margin: 0.5*$cartCouponMargin 0 0 0;
					font-size: 14px;
					font-weight: 700;
					color: $cartCouponErrorColor;
				}
			}
		}
	}
}

























