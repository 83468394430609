

@use '../../../../style/import' as *;




/////////////
// Notices //
/////////////


// Titan colors
$noticesPrimaryColor: $THEME_PRIMARY_COLOR;
$noticesPrimaryColorHover: $THEME_PRIMARY_COLOR_HOVER;
$noticesPrimaryColorText: $THEME_PRIMARY_COLOR_TEXT;
$noticesBackground: $THEME_BACKGROUND;
$noticesTextColor: $THEME_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$noticesMargin: 10px;


.Notices {

}


////////////////
// NoticeView //
////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.NoticeView {

	position: relative;

	.invisibleSwatch {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;

		color: $noticesPrimaryColorText;
		background: $noticesPrimaryColor;
	}
}


////////////////
// NoticeEdit //
////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.NoticeEdit {

	position: relative;

	.invisibleSwatch {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;

		color: $noticesPrimaryColorText;
		background: $noticesPrimaryColor;
	}
}






















