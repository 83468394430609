

@use '../../../../style/import' as *;




//////////////////
//   MiniCart   //
//////////////////


// Titan colors
// None yet

// Theme colors
$miniCartAccent: $THEME_PRIMARY_COLOR;
$miniCartAccentText: $THEME_PRIMARY_COLOR_TEXT;
$miniCartBackground: $THEME_BACKGROUND;

// General variables
$miniCartIconWidth: 60px;
$miniCartMargin: 10px;


.MiniCart {

	.mcBody {

		.mcBodyWrapper {

			.cartLoading {

				margin: 8*$miniCartMargin 0 0 0;

				.loadingIcon {
					margin: 0 auto;
					width: $miniCartIconWidth;
				}

				.loadingLabel {
					margin: $miniCartMargin 0 0 0;
					text-align: center;
					font-size: 20px;
					font-weight: 300;
				}
			}

			.cartEmpty {

				margin: 8*$miniCartMargin 0 8*$miniCartMargin 0;

				.emptyIcon {
					margin: 0 auto;
					width: $miniCartIconWidth;
				}

				.emptyLabel {
					margin: $miniCartMargin 0 0 0;
					text-align: center;
					font-size: 20px;
					font-weight: 300;
				}
			}

			.cartBody {

				.cartBodyLiner {

					position: relative;

					.cartBodyNoticeWrapper {
						position: sticky;
						overflow: auto;
						top: 0;
						padding: 0 0 $miniCartMargin 0;
						margin: 0 0 $miniCartMargin 0;
						background: $miniCartBackground;
						z-index: 10;

						.cartBodyNoticeBlock {
							margin: 0 0 $miniCartMargin 0;
							font-size: 14px;
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}




// Titan colors
// None yet

// Theme colors
$miniCartItemInputBorderColor: $THEME_RULE_COLOR;
$miniCartItemQuantityArrowColor: $THEME_TEXT_COLOR_LIGHT;

// General variables
$miniCartItemBuylistMinHeight: 20px;
$miniCartItemDisabledOpacity: 0.6;
$miniCartItemImageWidth: 80px;
$miniCartItemImageWidthMobile: 50px;
$miniCartItemPriceWidth: 100px;
$miniCartItemPriceWidthMobile: 80px;
$miniCartItemQuantityWidth: 50px;
$miniCartItemRoQuantityHeight: 20px;



//////////////////////
//   MiniCartItem   //
//////////////////////


.MiniCartItem {

	margin: 0 0 $miniCartMargin 0;

	.miniCartItemWraper.greyed {

		.miniCartItemDisabledScreen {
			display: block;
		}
	}

	.miniCartItemWraper {

		position: relative;

		.miniCartItemDisabledScreen {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($miniCartBackground, $miniCartItemDisabledOpacity);
		}

		.mciImageWrapper {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: $miniCartItemImageWidth;
			min-height: $miniCartItemImageWidth;
			max-height: 2*$miniCartItemImageWidth;
			transition: min-height $breakTransition, width $breakTransition;
			
			@media #{$break768} {
				width: $miniCartItemImageWidthMobile;
				min-height: $miniCartItemImageWidthMobile;
			}

			.mciImage {

				text-align: center;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		.mciBodyWrapper {

			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$miniCartItemImageWidth} - #{$miniCartItemPriceWidth} - #{$miniCartMargin});
			margin: 0 0 0 $miniCartMargin;
			transition: width $breakTransition;
			
			@media #{$break768} {
				width: calc(100% - #{$miniCartItemImageWidthMobile} - #{$miniCartItemPriceWidthMobile} - #{$miniCartMargin});
			}

			.mciNameWrapper {

				margin: 0 0 0.5*$miniCartMargin 0;

				.nameWrapper {
					font-size: 16px;
					font-weight: 400;
				}

				.productLineWrapper {
					font-size: 13px;
					font-weight: 300;
				}
			}

			.mciPreorderWrapper {

				.preorderLiner {

					margin: 0 0 0 0;

					.preorderLabelWrapper {
						display: inline-block;
						vertical-align: top;
						width: auto;
						margin: 0 $miniCartMargin 0 0;

						.preorderLabel {
							
							position: relative;
							display: inline-block;
							background: $miniCartAccent;
							max-width: 100%;
							height: auto;
							min-height: $miniCartItemBuylistMinHeight;
							margin: 0 0 $miniCartMargin 0;
							border-radius: 0.5*$miniCartItemBuylistMinHeight;

							.preorderLabelValue {
								color: $miniCartAccentText;
								text-align: center;
								font-size: 0.6*$miniCartItemBuylistMinHeight;
								line-height: 0.6*$miniCartItemBuylistMinHeight;
								padding: 0.2*$miniCartItemBuylistMinHeight 0.5*$miniCartItemBuylistMinHeight;
							}
						}
					}

					.preorderReleaseWrapper {

						display: inline-block;
						vertical-align: top;
						width: auto;
						margin: 0 0.5*$miniCartMargin 0 0;

						.preorderRelease {

							.releaseLabel {
								display: inline-block;
								vertical-align: top;
								width: auto;
								margin: 0 0.5*$miniCartMargin 0 0;
								min-height: $miniCartItemBuylistMinHeight;
								line-height: $miniCartItemBuylistMinHeight;
								font-size: 15px;
								font-weight: 400;

								&:after {
									content: ':';
								}
							}

							.releaseValue {
								display: inline-block;
								vertical-align: top;
								width: auto;
								min-height: $miniCartItemBuylistMinHeight;
								line-height: $miniCartItemBuylistMinHeight;
								font-size: 15px;
								font-weight: 300;
							}
						}
					}
				}
			}

			.mciDetailsWrapper {

				.mciDetailText {
					font-size: 13px;
					font-weight: 400;
					// font-family: $fontSerif;
					transition: font-size $breakTransition;

					// color: $THEME_PRIMARY_COLOR;

					@media #{$break768} {
						font-size: 13px;
					}
				}
			}
		}

		.buylistWrapper {
			position: relative;
			display: inline-block;
			background: $miniCartAccent;
			max-width: 100%;
			height: auto;
			min-height: $miniCartItemBuylistMinHeight;
			margin: 0 0 $miniCartMargin 0;
			border-radius: 0.5*$miniCartItemBuylistMinHeight;

			.buylistLabel {
				color: $miniCartAccentText;
				text-align: center;
				font-size: 0.6*$miniCartItemBuylistMinHeight;
				line-height: 0.6*$miniCartItemBuylistMinHeight;
				padding: 0.2*$miniCartItemBuylistMinHeight 0.5*$miniCartItemBuylistMinHeight;
			}
		}

		.tagWrapper.aboveOverlay {
			z-index: 5;
		}

		.tagWrapper {
			position: relative;
			display: inline-block;
			background: $miniCartAccent;
			max-width: 100%;
			height: auto;
			min-height: $miniCartItemBuylistMinHeight;
			margin: 0 0 $miniCartMargin 0;
			border-radius: 0.5*$miniCartItemBuylistMinHeight;

			.tagLabel {
				color: $miniCartAccentText;
				text-align: center;
				font-size: 0.6*$miniCartItemBuylistMinHeight;
				line-height: 0.6*$miniCartItemBuylistMinHeight;
				padding: 0.2*$miniCartItemBuylistMinHeight 0.5*$miniCartItemBuylistMinHeight;
			}
		}

		.mciPriceWrapper {
			overflow: hidden;
			display: inline-block;
			vertical-align: top;
			width: $miniCartItemPriceWidth;
			text-align: right;
			transition: width $breakTransition;

			@media #{$break768} {
				width: $miniCartItemPriceWidthMobile;
			}

			.priceWrapper {

				font-size: 20px;
				transition: font-size $breakTransition;

				@media #{$break768} {
					font-size: 16px;
				}

				.priceFormatSymbol {
					vertical-align: top;
					font-size: 14px;
					font-weight: 300;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 12px;
					}
				}
			}

			.quantityReadOnlyWrapper {

				margin: 0.5*$miniCartMargin 0 0 0;
				text-align: right;
				height: $miniCartItemRoQuantityHeight;
				transition: margin $breakTransition;

				@media #{$break768} {
					margin: 0 0 0 0;
				}
				
				.quantRoX {
					display: inline-block;
					vertical-align: text-bottom;
					width: auto;
					font-size: 16px;
					font-weight: 100;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 12px;
					}
				}	

				.quantRoValue {
					display: inline-block;
					vertical-align: text-bottom;
					width: auto;
					font-size: 18px;
					font-weight: 300;
					transition: font-size $breakTransition;

					@media #{$break768} {
						font-size: 14px;
					}
				}
			}

			.quantityWrapper {

				margin: $miniCartMargin 0 0 0;

				.quantityLabel {

				}

				.quantityDropdown {

					width: $miniCartItemQuantityWidth;
					margin-left: calc(100% - #{$miniCartItemQuantityWidth});

					.mciDropdownSelect {

						text-align: left;

						select {
							text-align: center;
							padding-right: $miniCartMargin;
						}

						.dropdownArrowWrapper {
							
							width: $miniCartMargin;
							height: $miniCartMargin;
							bottom: 1.5*$miniCartMargin;
							text-align: center;

							.dropdownArrow {

								&:before {
									top: 0;
									left: 0;
									width: 0.5*$miniCartMargin;
									height: 0.5*$miniCartMargin;
									border-color: $miniCartItemQuantityArrowColor;
									border-width: 1px 1px 0 0;
								}
							}
						}
					}
				}

				.quantityInput {

					width: $miniCartItemQuantityWidth;
					margin-left: calc(100% - #{$miniCartItemQuantityWidth});

					.inputElement {
						border-top: none;
						border-left: none;
						border-right: none;
						border-bottom: 1px solid $miniCartItemInputBorderColor;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}







