

@use '../../../../../../style/import' as *;




//////////////////////////
// ProductCategoryEntry //
//////////////////////////


// Titan colors
$backgroundColor: $TITAN_BACKGROUND;
$deleteColor: $TITAN_ERROR_COLOR;
$deleteColorHover: $TITAN_ERROR_COLOR_HOVER;
$primaryColor: $TITAN_PRIMARY_COLOR;
$primaryColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$primaryColorText: $TITAN_PRIMARY_COLOR_TEXT;
$rowBackgroundAlternate: $TITAN_ADMIN_PAGE_BACKGROUND_ALTERNATE;
$rowBackgroundHover: $TITAN_PRIMARY_ULTRALIGHT;
$textColor: $TITAN_TEXT_COLOR;
$textColorHover: $TITAN_TEXT_COLOR_HOVER;

// Theme colors
// None yet

// General variables
$rowControlIconScale: 0.5;
$rowSubmitIconScale: 0.7;
$rowHeight: 30px;
$rowOverlayOpacity: 0.8;



.ProductCategoryEntry {

	cursor: pointer;
	background: $backgroundColor;
	transition: background $hoverTransition;

	&.overlaid {

		cursor: default;

		&:hover {
			background: unset;
		}
	}

	&.active {

		cursor: default;
		background: $rowBackgroundHover;

		&:nth-child(even) {
			background: $rowBackgroundHover;
		}
	}

	&:nth-child(even) {
		background: $rowBackgroundAlternate;
	}

	&:hover {
		background: $rowBackgroundHover;
	}

	.categoryLiner {

		position: relative;
		padding: $MARGIN_S $MARGIN;

		.primaryWrapper {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$rowHeight});
			height: $rowHeight;

			.primaryDisplay,
			.primaryEditing {
				margin: 0 $MARGIN 0 0;
			}

			.primaryDisplay {
				height: $rowHeight;
				line-height: $rowHeight;
				font-size: 20px;
			}

			.primaryEditing {

				.categoryAutocomplete {
					display: inline-block;
					width: calc(100% - #{$rowHeight});
				}
				
				input {
					border: 1px solid $primaryColor;
					padding: $MARGIN_S $MARGIN;
					font-size: 18px;
					font-weight: 300;
					width: calc(100% - 2px - 2*#{$MARGIN});
					height: $rowHeight - 2px - $MARGIN;
				}

				button.submitCategoryName {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: $rowHeight;
					height: $rowHeight;
					cursor: pointer;
					border: none;
					border-radius: 0;
					background: $primaryColor;
					transition: background $hoverTransition;

					&:hover {
						background: $primaryColorHover;
					}

					.submitIconWrapper {
						margin: 0.5 * (1 - $rowSubmitIconScale) * $rowHeight;
						width: $rowSubmitIconScale * $rowHeight;
						height: $rowSubmitIconScale * $rowHeight;

						svg {
							fill: $primaryColorText;
						}
					}
				}
			}
		}
		
		.controlWrapper {
			display: inline-block;
			vertical-align: top;
			width: $rowHeight;
			height: $rowHeight;

			.controlDisplay,
			.controlEditing {
				display: flex;
				position: relative;
				margin: 0.5 * (1 - $rowControlIconScale) * $rowHeight;
				width: $rowControlIconScale * $rowHeight;
				height: $rowControlIconScale * $rowHeight;
				align-items: center;
			}

			.controlDisplay {

				&:hover {
					svg {
						fill: $deleteColorHover;
					}
				}
			}

			.controlEditing {
				cursor: pointer;

				&:hover {
					svg {
						fill: $primaryColorHover;
					}
				}
			}
		}

		.rowOverlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: $rowHeight + $MARGIN;
			text-align: center;
			// pointer-events: none;
			background: rgba($backgroundColor, $rowOverlayOpacity);
			font-size: 18px;

			.pendingOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $rowHeight + $MARGIN;
				// pointer-events: initial;

				.pendingCopy {
					display: inline-block;
					vertical-align: top;
					height: $rowHeight + $MARGIN;
					line-height: $rowHeight + $MARGIN;
					font-style: italic;

					&:after {
						content: '...';
					}
				}
			}

			.deleteOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $rowHeight + $MARGIN;
				// pointer-events: initial;

				.deleteCopy {
					display: inline-block;
					vertical-align: top;
					height: $rowHeight + $MARGIN;
					line-height: $rowHeight + $MARGIN;
					margin: 0 $MARGIN_S;
				}

				.deleteConfirm {
					display: inline-block;
					vertical-align: top;
					height: $rowHeight + $MARGIN;
					line-height: $rowHeight + $MARGIN;
					margin: 0 $MARGIN_S;
					font-size: 14px;
					font-weight: 700;
					color: $deleteColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $deleteColorHover;
					}
				}

				.deleteCancel {
					display: inline-block;
					vertical-align: top;
					height: $rowHeight + $MARGIN;
					line-height: $rowHeight + $MARGIN;
					margin: 0 $MARGIN_S;
					font-size: 14px;
					font-weight: 400;
					color: $textColor;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $textColorHover;
					}
				}
			}
		}
	}
}













