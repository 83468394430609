

@use '../../../../style/import' as *;

// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet

// Component variables
// None yet


//////////////////////
// ProgressiveImage //
//////////////////////

.ProgressiveImage {

	display: inline-block;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	img.thumbImg {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		filter: blur(1px);
		transition: opacity $imageTransition;
		clip-path: inset(0);
	}

	img.placeholderImg {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		transition: opacity $imageTransition;
		clip-path: inset(0);
	}
}