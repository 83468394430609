

@use '../../../../style/import' as *;

@use '/admin-constants' as *;




///////////////
// AdminPage //
///////////////


// Titan colors
$errorColor: $TITAN_ERROR_COLOR;

// Theme colors
// None yet

// General variables
$sectionMaxWidth: $ADMIN_SECTION_WIDTH;


.AdminPage {

	.pageError {
		max-width: $sectionMaxWidth;
		margin: $MARGIN_L 0;
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		color: $errorColor;
	}

	.pageBody {
		margin: $MARGIN $MARGIN_L 0 $MARGIN_L;

		.blocksWrapper {

		}
	}

	.pageActions {
		position: relative;
		margin: $MARGIN_L $MARGIN_L 2*$MARGIN_L $MARGIN_L;
		max-width: $sectionMaxWidth;
		text-align: right;

		.leftAlign {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}













