

@use '../../../../style/import' as *;
@use './header-constants' as *;




///////////////////////
// HeaderBuySellPlay //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.HeaderBuySellPlay {

	.displayWrapper {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $headerBackground;
		z-index: $zHeaderLayer;

		.logoWrapper {
			position: absolute;
			left: $headerMargin;
			top: calc(0.5 * ($headerHeight - $headerLogoHeight));
			height: $headerLogoHeight;
			width: $headerLogoWidth;
			transition: top $breakTransition, bottom $breakTransition, height $breakTransition;

			@media #{$break600} {
				left: calc(50% - #{0.5 * $headerLogoWidth});
				top: calc(0.5 * ($headerHeightMobile - $headerLogoHeightMobile));
				height: $headerLogoHeightMobile;
				width: $headerLogoWidth;
			}
		}

		.menuWrapper.buyMenu {

			.menuDropdownWrapper {
				left: 0;
				width: 1.0*$headerMenuDropdownWidth;
				height: auto;
			}
		}

		.menuWrapper.sellMenu {

			.menuDropdownWrapper {
				left: 0.333 * $headerMenuWidth;
				width: 1.0*$headerMenuDropdownWidth;
				height: auto;
			}
		}

		.menuWrapper.playMenu {

			.menuDropdownWrapper {
				left: calc(2*($headerMenuWidth / 3));
				width: 1.0*$headerMenuDropdownWidth;
				height: auto;
			}
		}

		.menuWrapper.open {

			.menuDropdownWrapper {
				opacity: 1.0;
			}
		}

		.menuWrapper {

			position: absolute;
			left: $headerLogoWidth + (2 * $headerMargin);
			top: 0;
			bottom: 0;
			width: $headerMenuWidth;


			@media #{$break1200} {
				display: none;
			}

			.primaryNavLink.current {
				.menuElementWrapper {

					.menuTli {
						border-bottom: 5px solid $headerAccentColor;
					}
				}
			}

			.active.menuElementWrapper {

				.menuTli {
					border-bottom: 5px solid $headerAccentColor;
				}
			}

			.menuElementWrapper {

				position: relative;
				display: inline-block;
				width: calc($headerMenuWidth / 3);
				height: 100%;
				cursor: pointer;

				.menuTli {
					position: absolute;
					top: calc(($headerHeight - $headerMenuHeight) / 2);
					left: 0;
					width: 100%;
					height: $headerMenuHeight;

					color: $headerColor;
					
					user-select: none;
					width: calc($headerMenuWidth / 3);
					border-bottom: 5px solid $headerBackground;
					text-align: center;
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					transition: border $hoverTransition;
				}
			}

			.menuDropdownWrapper {
				opacity: 0;
				position: absolute;
				top: 100%;
				background: $headerBackground;
				transition: opacity 1000ms linear 0ms, top $slideTransition, left $slideTransition, width $slideTransition, height $slideTransition;

				.menuDropdownTitle {
					padding: 0.5*$headerMargin 1.0*$headerMargin;
					background: $headerMenuDropdownTitleBackground;
					margin: 0;
					font-weight: 700;
					font-size: 20px;
					user-select: none;
					text-transform: uppercase;
				}

				.menuDropdownBody {
					padding: 0.5*$headerMargin 0 0 0;
				}

				.menuDropdownFooter {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: $headerMenuDropdownFooterHeight;
					background: $headerAccentColor;
				}
			}
		}

		.searchWrapper {

			position: absolute;
			left: $headerLogoWidth + $headerMenuWidth + (3 * $headerMargin);
			right: $headerCartWidth + $headerAccountWidth + (3 * $headerMargin);
			top: calc(0.5 * ($headerHeight - $headerSearchHeight));
			bottom: calc(0.5 * ($headerHeight - $headerSearchHeight));
			transition: left $breakTransition, right $breakTransition;

			@media #{$break1200} {
				left: $headerLogoWidth + (2 * $headerMargin);
				right: $headerCartWidth + $headerIconWidth + (3 * $headerMargin);
			}

			@media #{$break600} {
				display: none;
			}
		}

		.accountWrapper {
			position: absolute;
			right: $headerCartWidth + (2 * $headerMargin);
			width: $headerAccountWidth;
			top: calc(0.5 * ($headerHeight - $headerAccountHeight));
			bottom: calc(0.5 * ($headerHeight - $headerAccountHeight));

			@media #{$break1200} {
				display: none;
			}
		}

		.cartWrapper {

			position: absolute;
			top: calc(0.5 * ($headerHeight - $headerCartHeight));
			bottom: calc(0.5 * ($headerHeight - $headerCartHeight));
			right: $headerMargin;
			width: $headerCartWidth;
			cursor: pointer;

			@media #{$break600} {
				top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				right: $headerMarginMobile;
				width: $headerIconWidthMobile;
			}
		}

		.hamburgerWrapper {

			display: none;
			position: absolute;
			top: calc(0.5 * ($headerHeight - $headerIconWidth));
			bottom: calc(0.5 * ($headerHeight - $headerIconWidth));
			right: $headerCartWidth + (2 * $headerMargin);
			width: $headerIconWidth;
			cursor: pointer;

			@media #{$break1200} {
				display: block;
			}

			@media #{$break600} {
				top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
				right: unset;
				left: $headerMarginMobile;
				width: $headerIconWidthMobile;
			}
		}

		.searchDrawerWrapper {

			display: none;
			position: absolute;
			top: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
			bottom: calc(0.5 * ($headerHeightMobile - $headerIconWidthMobile));
			right: $headerIconWidthMobile + (2 * $headerMarginMobile);
			width: $headerIconWidthMobile;

			@media #{$break600} {
				display: block;
			}
		}
	}

	.userMenuWrapper {

		background: pink;

		@media #{$break1200} {
			display: none;
		}
	}
}













