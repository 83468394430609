

@use '../../../../style/import' as *;




/////////////////////////////////
// BlockTypeProductsHorizontal //
/////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$blockProductsHorizontalWidth: 12.5%;
$blockProductsHorizontalWidth2Row: 25%;
$blockProductsHorizontalWidth3Row: 50%;


.BlockTypeProductsHorizontal {

	.elementWrapper {
		display: inline-block;
		vertical-align: top;
		width: calc(#{$blockProductsHorizontalWidth} - #{$MARGIN});
		margin: 0 $MARGIN_S $MARGIN $MARGIN_S;
		transition: width $breakTransition;

		@media #{$break1024} {
			width: calc(#{$blockProductsHorizontalWidth2Row} - #{$MARGIN});
		}

		@media #{$break600} {
			width: calc(#{$blockProductsHorizontalWidth3Row} - #{$MARGIN});
		}
	}

	.noResults {
		margin: 3*$MARGIN 0 0 0;
		font-size: 24px;
		font-weight: 300;
		text-align: center;
	}
}