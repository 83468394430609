

@use '../../../../../../style/import' as *;




////////////////////////////////////////////
// ProductCategoryAutocompleteNullResults //
////////////////////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductCategoryAutocompleteNullResults {

	.nullLiner {

		text-align: center;
		padding: $MARGIN_L $MARGIN;

		.nullCopyLine {

			.noResultsCopy {
				display: inline-block;
				vertical-align: baseline;
				font-size: 18px;
				font-weight: 400;
				margin: 0 $MARGIN_S 0 0;
			}

			.noResultsNeedle {
				display: inline-block;
				vertical-align: baseline;
				font-size: 18px;
				font-weight: 700;
			}
		}

		.nullActionWrapper {
			margin: $MARGIN 0 0 0;
		}
	}
}













