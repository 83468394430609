

@use '../../../../style/import' as *;




///////////////
// Dashboard //
///////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$dashboardMargin: 10px;


.Dashboard {

	.adminBody {

		.adminGenericBlock {

			.dashboardWrapper {

				.dashboardNoPermissiosn {
					margin: 5*$dashboardMargin 0;
					font-size: 24px;
					font-weight: 100;
					text-align: center;
				}
			}
		}
	}
}