

@use '../../../../style/import' as *;




////////////////////
// HiddenDropdown //
////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.HiddenDropdown {

	position: relative; 
	display: inline-block;

	.overlay {
		cursor: pointer;
	}

	.selectElement {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
}













