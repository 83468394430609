

@use '../../../../../style/import' as *;




////////////////////////
// ProductPageDetails //
////////////////////////


// Titan colors
// None yet

// Theme colors
$backgroundColor: $THEME_BACKGROUND;
$borderTopColor: $THEME_PRIMARY_COLOR;
$borderBottomColor: $THEME_PRIMARY_COLOR_HOVER;

// General variables
$borderWidth: 1px;
$detailsMinHeight: 80px;



.ProductPageDetails {

	.detailsWrapper {
	
		margin: 0 $MARGIN $MARGIN $MARGIN;
		transition: margin $breakTransition;

		.headerWrapper {
			
		}

		.borderWrapper {

			padding: $borderWidth;
			background: linear-gradient(to bottom right, #{$borderTopColor}, #{$borderBottomColor});
			overflow: auto;

			.genericDetails {
				position: relative;
				background: $backgroundColor;
				min-height: $detailsMinHeight;
				padding: $MARGIN;
				font-size: 18px;
				line-height: 1.3;

				.ppGenericNoDesc {
					font-size: 20px;
					font-weight: 300;
				}
			}
		}
	}
}