

@use '../../../../style/import' as *;




///////////////////////////////
// BlockTypeProductsVertical //
///////////////////////////////


// Titan colors
$backgroundTitan: $TITAN_BACKGROUND;

// Theme colors
$backgroundTheme: $THEME_BACKGROUND;

// General variables
// None yet


.BlockTypeProductsVertical {

	background: $backgroundTheme;

	&.titanTheme {
		background: $backgroundTitan;
	}

	.btpvLiner {
		margin: $MARGIN;

		.errorWrapper {
			color: $errorRed;
			margin: 0 0 $MARGIN 0;
			font-weight: 700;
			text-align: center;
		}

		.elementWrapper {
			
		}

		.noResults {
			margin: 3*$MARGIN 0 0 0;
			font-size: 24px;
			font-weight: 300;
			text-align: center;
		}
	}
}
















