

@use '../../../../style/import' as *;

// Titan variables
$miniCartSliderTop: $TITAN_HEADER_HEIGHT;
$miniCartSliderTopMobile: $TITAN_HEADER_HEIGHT_MOBILE;
$miniCartSliderNoticeHeight: $TITAN_HEADER_NOTICE_HEIGHT;
$miniCartSliderNoticeHeightMobile: $TITAN_HEADER_NOTICE_HEIGHT_MOBILE;


// Theme variables
$miniCartSliderBackground: $THEME_BACKGROUND;
$miniCartButtonColor: $THEME_PRIMARY_COLOR_TEXT;
$miniCartButtonBackground: $THEME_PRIMARY_COLOR;
$miniCartButtonBackgroundHover: $THEME_PRIMARY_COLOR_HOVER;
$miniCartSliderLoadingIconColor: $THEME_PRIMARY_COLOR;
$miniCartTitleColor: $THEME_TEXT_COLOR;


// General variables
$miniCartSliderIndex: $zCartLayer;
$miniCartSliderLoadingIconWidth: 80px;
$miniCartSliderLoadingScreenOpacity: 0.7;
$miniCartSliderPadding: 20px;
$miniCartSliderWidth: 480px;
$miniCartSliderMargin: 10px;

$miniCartTitleHeight: 45px;
$miniCartTitleLineHeight: 20px;
$miniCartSummaryHeight: 120px; /* Subtotal and action heights must equal total height */
$miniCartSummarySubtotalHeight: 30px; 
$miniCartSummaryActionHeight: 90px;


// Component variables
// None yet





////////////////////////
//   MiniCartSlider   //
////////////////////////


.notified.MiniCartSlider {
	top: $miniCartSliderTop + $miniCartSliderNoticeHeight;

	@media #{$break900} {
		top: $miniCartSliderTop + $miniCartSliderNoticeHeightMobile;
		height: calc(100vh - #{$miniCartSliderTop + $miniCartSliderNoticeHeightMobile});
	}

	@media #{$break600} {
		top: $miniCartSliderTopMobile + $miniCartSliderNoticeHeightMobile;
		height: calc(100vh - #{$miniCartSliderTopMobile + $miniCartSliderNoticeHeightMobile});
	}
}


.open.MiniCartSlider {
	left: calc(100% - #{$miniCartSliderWidth});

	@media #{$break480} {
		left: 0;
	}
}


.MiniCartSlider {
	
	position: fixed;
	top: $miniCartSliderTop;
	left: 100%;
	width: $miniCartSliderWidth;
	max-width: 100vw;
	height: calc(100vh - #{$miniCartSliderTop});
	z-index: $miniCartSliderIndex;
	background: $miniCartSliderBackground;
	transition: left $slideTransition, top $breakTransition, height $breakTransition;

	@media #{$break600} {
		top: $miniCartSliderTopMobile;
		height: calc(100vh - #{$miniCartSliderTopMobile});
	}

	.miniCartSliderTitleWrapper {
		
		position: relative;
		height: $miniCartTitleHeight;
		margin: $miniCartSliderPadding $miniCartSliderPadding 0 $miniCartSliderPadding;

		.miniCartSliderTitle {
			display: inline-block;
			vertical-align: middle;
			color: $miniCartTitleColor;
			font-family: $fontLato;
			font-weight: 700;
			font-size: $miniCartTitleLineHeight;
			line-height: $miniCartTitleLineHeight;
			text-transform: uppercase;
		}

		.miniCartSliderToggle {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 calc($miniCartSliderPadding/2);
			font-size: 12px;
			font-weight: 700;
			cursor: pointer;
			color: $miniCartButtonBackground;
			transition: color $hoverTransition;

			&:hover {
				color: $miniCartButtonBackgroundHover;
			}
		}

		.miniCartClearAll {

			position: absolute;
			top: 0;
			right: 0;
			width: auto;
			height: $miniCartTitleLineHeight;
			line-height: $miniCartTitleLineHeight;
			right: 0;
			font-size: 12px;
			font-weight: 700;
			cursor: pointer;
			color: $miniCartButtonBackground;
			transition: color $hoverTransition;

			&:hover {
				color: $miniCartButtonBackgroundHover;
			}

			.miniCartClearAction {
				
			}
		}
	}

	.miniCartSliderCartWrapper {

		position: absolute;
		overflow: auto;

		top: $miniCartTitleHeight;
		left: 0;
		width: calc(100% - #{2*$miniCartSliderPadding});
		height: calc(100% - #{$miniCartSummaryHeight} - #{3*$miniCartSliderPadding} - #{$miniCartTitleHeight});

		margin: $miniCartSliderPadding 0;
		padding: 0 $miniCartSliderPadding;
	}

	.miniCartActionWrapper {
		
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: $miniCartSummaryHeight;
		background: $miniCartSliderBackground;

		.miniCartInfoWrapper {

			position: relative;
			margin: calc($miniCartSliderPadding/2) $miniCartSliderPadding;

			.mcInfoLabel {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				font-size: 20px;
				font-weight: 400;
			}

			.mcInfoCount {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin-left: calc($miniCartSliderPadding/4);
				font-size: 16px;
				font-weight: 300;
			}

			.mcInfoPriceWrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: auto;

				.mcInfoPrice {

					text-align: right;

					.mcCurrencySymbol {
						display: inline-block;
						vertical-align: top;
						width: auto;
						font-size: 12px;
						font-weight: 300;
					}

					.mcPriceValue {
						display: inline-block;
						vertical-align: top;
						width: auto;
						margin-left: calc($miniCartSliderPadding/4);
						font-size: 18px;
						font-weight: 400;
					}
				}
			}
		}

		.miniCartButtonWrapper {

			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $miniCartSummaryActionHeight;
			

			.miniCartCheckoutButton {
				cursor: pointer;
				width: calc(100% - #{2*$miniCartSliderPadding});
				height: $miniCartSummaryActionHeight - 2*$miniCartSliderPadding;
				margin: $miniCartSliderPadding;

				font-size: 18px;
				font-weight: 700;

				border: none;
				color: $miniCartButtonColor;
				background: $miniCartButtonBackground;
				transition: background $hoverTransition;

				&:hover {
					background: $miniCartButtonBackgroundHover;
				}
			}
		}
	}

	.miniCartLoadingScreen {

		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($miniCartSliderBackground, $miniCartSliderLoadingScreenOpacity);

		.miniCartLoadingScreenIcon {
			
			position: absolute;
			width: $miniCartSliderLoadingIconWidth;
			height: $miniCartSliderLoadingIconWidth;
			top: calc(40% - #{0.5*$miniCartSliderLoadingIconWidth});
			left: calc(50% - #{0.5*$miniCartSliderLoadingIconWidth});

			svg {
				fill: $miniCartSliderLoadingIconColor;
			}
		}
	}
}














