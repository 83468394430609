

@use '../../../../style/import' as *;


// Titan colors
$dropdownThemeBackground: $THEME_BACKGROUND;

// Theme colors
$dropdownBackground: $THEME_BACKGROUND;
$dropdownColor: $THEME_TEXT_COLOR;
$dropdownColorHover: $THEME_PRIMARY_COLOR;
$dropdownBorderColor: $THEME_RULE_COLOR;
$dropdownDisabledColor: $disabledColor;
$dropdownDisabledBackground: $disabledBackground;

// General variables
$dropdownBorderWidth: 1px;
$dropdownHeight: 20px;
$dropdownMargin: 10px;

// Component variables
$dropdownArrowWidth: 20px;
$dropdownArrowThickness: 2px;


.Dropdown.error {

}

.Dropdown.titanTheme {

}

.Dropdown {
  
  position: relative;
  color: $dropdownColor;
  background: $dropdownBackground;
  height: $dropdownHeight;
  border-bottom: $dropdownBorderWidth solid $dropdownBorderColor;

  &:hover {

    .dropdownArrowWrapper {

      .dropdownArrow {

        &:before {
          border-color: $dropdownColorHover;
        }
      }
    }
  }

  select {

    width: 100%;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    user-select: none;
    padding-right: 1.1*$dropdownHeight;

    &:invalid {
      color: $placeholderGrey;
    }

    &:disabled {
      border-color: $dropdownDisabledColor;
      // background: $dropdownDisabledBackground;
    }

    option {
      color: $dropdownColor;
    }
  }

  select:disabled + .dropdownButt {
    .dropdownButtBody {
      .dropdownArrowWrapper {
        .dropdownArrow {
          &:before {
            border-color: $dropdownDisabledColor;
          }
        }
      }
    }
  }

  .dropdownButt {

    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    width: 1.0*$dropdownHeight;
    pointer-events: none;

    .dropdownFade {

      display: none;

      position: absolute;
      top: 0;
      right: $dropdownHeight;
      bottom: 0;
      width: 0.5*$dropdownHeight;
      background-image: linear-gradient(to right, rgba($dropdownBackground, 0.0), rgba($dropdownBackground, 1.0));
    }

    .dropdownButtBody {

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: $dropdownHeight;
      background: $dropdownBackground;

      .dropdownArrowWrapper {

        position: absolute;
        cursor: pointer;
        pointer-events: none;
        bottom: calc(50% - #{0.25*$dropdownHeight});
        right: 0;
        width: $dropdownHeight;
        height: $dropdownHeight;

        .dropdownArrow {
          
          transform: rotate(180deg);

          &:before {
            position: relative;
            display: inline-block;
            vertical-align: top;
            content: '';

            top: calc(0.5*$dropdownArrowThickness);
            left: calc(0.5*$dropdownArrowThickness);
            height: calc(0.4*$dropdownHeight);
            width: calc(0.4*$dropdownHeight);

            border-color: $dropdownColor;
            border-style: solid;
            border-width: $dropdownArrowThickness $dropdownArrowThickness 0 0;
            
            transform: rotate(-45deg);
            transition: border $hoverTransition;
          }
        }
      }
    }
  }
}


////////////////////////
// SearchableDropdown //
////////////////////////

// Titan colors
$searchableDropdownThemeOptionHover: $TITAN_PRIMARY_ULTRALIGHT;
$searchableDropdownThemeBorderColor: $TITAN_TEXT_COLOR_LIGHT;
$searchableDropdownThemeBorderColorLight: $TITAN_RULE_COLOR;

// Theme colors
$searchableDropdownOptionHover: $THEME_PRIMARY_ULTRALIGHT;
$searchableDropdownBorderColor: $THEME_TEXT_COLOR_LIGHT;
$searchableDropdownBorderColorLight: $THEME_RULE_COLOR;

// General variables
$searchableDropdownHeight: 45px;
$searchableIconHeight: 20px;
$searchableDropdownDrawerHeight: 400px;


.SearchableDropdown.error {

}

.SearchableDropdown.titanTheme {

  .searchableDropdownLiner {

    .contentLoaded {

      .optionsWrapper {

        border-left: $dropdownBorderWidth solid $searchableDropdownThemeBorderColor;
        border-right: $dropdownBorderWidth solid $searchableDropdownThemeBorderColor;
        background: $dropdownBackground; 

        .optionsLiner {
          border-top: $dropdownBorderWidth solid $searchableDropdownThemeBorderColorLight;
          border-bottom: $dropdownBorderWidth solid $searchableDropdownThemeBorderColor;
          overflow: auto;

          .optionsList {

            .hovered.optionElementWrapper {
              background: $searchableDropdownThemeOptionHover;
            }

            .optionElementWrapper {
              background: $dropdownThemeBackground;
            }
          }
        }
      }
    }
  }
}

.SearchableDropdown {

  .searchableDropdownLiner {

    .contentLoading {
      position: relative;
      width: auto;
      height: $searchableDropdownHeight;

      .loadingLiner {

        border: $dropdownBorderWidth solid $dropdownDisabledColor;
        background: $dropdownDisabledBackground;

        .iconSection {

          position: relative;
          display: inline-block;
          vertical-align: top;
          width: $searchableDropdownHeight - 2*$dropdownBorderWidth;
          height: $searchableDropdownHeight - 2*$dropdownBorderWidth;

          .iconWrapper {
            position: absolute;
            top: 0.5 * ($searchableDropdownHeight - $searchableIconHeight - 2*$dropdownBorderWidth);
            left: 0.5 * ($searchableDropdownHeight - $searchableIconHeight - 2*$dropdownBorderWidth);
            width: $searchableIconHeight - 2*$dropdownBorderWidth;
            height: $searchableIconHeight - 2*$dropdownBorderWidth;

            svg {
              fill: $dropdownDisabledColor;
            }
          }
        }

        .copySection {

          display: inline-block;
          vertical-align: top;
          width: calc(100% - #{$searchableDropdownHeight});
          height: $searchableDropdownHeight - 2*$dropdownBorderWidth;

          .copyWrapper {
            margin: 0 0 0 0;
            height: $searchableDropdownHeight - 2*$dropdownBorderWidth;
            line-height: $searchableDropdownHeight - 2*$dropdownBorderWidth;
            color: $dropdownDisabledColor;
          }
        }
      }
    }

    .contentLoaded {

      .open.optionsWrapper {
        max-height: $searchableDropdownDrawerHeight;
        transition: max-height $slideTransition;
      }

      .optionsWrapper {
        position: absolute;
        overflow: hidden;
        left: 0;
        right: 0;
        top: calc(100% - #{$dropdownBorderWidth});
        height: auto;
        max-height: 0;
        z-index: $zUserMenuLayer;
        // No transition intentionally, should close instantly

        border-left: $dropdownBorderWidth solid $searchableDropdownBorderColor;
        border-right: $dropdownBorderWidth solid $searchableDropdownBorderColor;
        background: $dropdownBackground; 

        .optionsLiner {
          max-height: $searchableDropdownDrawerHeight;
          border-top: $dropdownBorderWidth solid $searchableDropdownBorderColorLight;
          border-bottom: $dropdownBorderWidth solid $searchableDropdownBorderColor;
          overflow: auto;

          .optionsList {

            .hovered.optionElementWrapper {
              background: $searchableDropdownOptionHover;
            }

            .optionElementWrapper {
              cursor: pointer;
              background: $dropdownBackground;
              transition: background $hoverTransition;

              .optionElement {
                padding: 0.5*$dropdownMargin $dropdownMargin;
              }
            }
          }
        }

        .optionsNull {

        }
      }
    }
  }
}



////////////////////////////
// SearchableDropdownNull //
////////////////////////////


// Titan colors
$searchableDropdownAdminLinkColor: $TITAN_PRIMARY_COLOR;
$searchableDropdownAdminLinkColorHover: $TITAN_PRIMARY_COLOR_HOVER;

// Theme colors
$searchableDropdownLinkColor: $THEME_PRIMARY_COLOR;
$searchableDropdownLinkColorHover: $THEME_PRIMARY_COLOR_HOVER;

// General variables
$searchableDropdownNullMargin: 40px;


.titanTheme.SearchableDropdownNull {

  .nullLiner {

    .addAction {
      color: $searchableDropdownAdminLinkColor;

      &:hover {
        color: $searchableDropdownAdminLinkColorHover;
      }
    }
  }
}

.SearchableDropdownNull {

  .nullLiner {

    text-align: center;
    padding: $searchableDropdownNullMargin $dropdownMargin;

    .noResultsCopy {
      font-size: 18px;
      font-weight: 300;
    }

    .noResultsNeedle {
      margin: 2*$dropdownMargin 0 0 0;
      font-size: 16px;
      font-weight: 700;
    }

    .addAction {
      margin: 2*$dropdownMargin 0 0 0;
      font-size: 14px;
      font-weight: 700;
      color: $searchableDropdownLinkColor;
      text-transform: uppercase;
      cursor: pointer;
      transition: color $hoverTransition;

      &:hover {
        color: $searchableDropdownLinkColorHover;
      }
    }
  }
}





































