

@use '../../../../style/import' as *;



$adminFormBreadcrumbAccentColor: $TITAN_PRIMARY_COLOR;
$adminFormBreadcrumbAccentColorHover: $TITAN_PRIMARY_COLOR_HOVER;
$adminFormBreadcrumbAccentTextColor: $TITAN_PRIMARY_COLOR_TEXT;

$adminFormBreadcrumbBackground: $TITAN_BACKGROUND;

$adminFormBreadcrumbColor: $TITAN_TEXT_COLOR;
$adminFormBreadcrumbColorHover: $TITAN_TEXT_COLOR_HOVER;

$adminFormBreadcrumbMargin: 10px;

$adminFormBreadcrumbSpacing: 25px;


.AdminFormBreadcrumbs {
	


	.afbWrapper {
		
		padding: 0.5*$adminFormBreadcrumbSpacing $adminFormBreadcrumbSpacing;
		background: $adminFormBreadcrumbBackground;
		text-align: center;

		.atfStepDisabled.afbStepWrapper {
			cursor: default;

			.afbStepBody {
				color: $disabledColor;
				font-weight: 300;
			}

			.afbStepUnderline {
				width: 0 !important;
			}
		}

		.currentStep.afbStepWrapper {
			cursor: default;

			.afbStepBody {
				/*color: $adminFormBreadcrumbAccentColor;*/
				font-weight: 700;
			}

			.afbStepUnderline {
				width: 100%;
			}
		}

		.afbStepWrapper {
			display: inline-block;
			vertical-align: top;
			text-transform: uppercase;
			margin: 0 $adminFormBreadcrumbMargin;
			cursor: pointer;
			transition: color $toggleTransition, font-weight $toggleTransition;

			&:hover {
				.afbStepUnderline {
					width: 100%;
				}
			}

			.afbStepBody {
				color: $adminFormBreadcrumbColor;
				font-weight: 400;
				user-select: none;
			}

			.afbStepUnderline {
				margin: 0 auto;
				height: 2px;
				width: 0;
				background: $adminFormBreadcrumbAccentColor;
				transition: width $hoverTransition;
			}
		}
	}
}