

@use '../../../../style/import' as *;




/////////////////////////
// CustomAdminTableRow //
/////////////////////////


// Titan colors
$customRowAccent: $TITAN_PRIMARY_COLOR;
$customRowAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$customRowAccentText: $TITAN_PRIMARY_COLOR_TEXT;
$customRowColor: $TITAN_TEXT_COLOR;

// Theme colors
// None yet

// General variables
$customRowMargin: 8px; // Matches the 8px from $adminTableHeaderHorizontalSpacing;
$customRowButtonIconWidth: 14px;


.CustomAdminTableRow {

}


//////////////////////
// ProductDetailRow //
//////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$pdrMediaWidth: 100px;
$pdrProductWidth: 240px;


.ProductDetailRow {

	.prLiner {

		padding: $customRowMargin $customRowMargin;

		.mediaBlock {
			display: inline-block;
			vertical-align: top;
			width: $pdrMediaWidth;
		}

		.productBlock {
			display: inline-block;
			vertical-align: top;
			width: $pdrProductWidth;
		}

		.inventoryBlock {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - #{$pdrMediaWidth} - #{$pdrProductWidth});
		}
	}
}



///////////////////////////
// ProductDetailRowMedia //
///////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductDetailRowMedia {

	.mediaLinkWrapper {

		cursor: pointer;

		&:hover {

			.mediaActionWrapper {

				.mediaAction {
					background: $customRowAccentHover;
				}
			}
		}

		.mediaWrapper {
			img {
				width: 100%;
			}
		}

		.mediaActionWrapper {

			margin: $customRowMargin 0 0 0;
			text-align: center;

			.mediaAction {

				display: inline-block;
				width: auto;
				color: $customRowAccentText;
				background: $customRowAccent;
				border-radius: none;
				padding: $customRowMargin/2 $customRowMargin;
				transition: background $hoverTransition;

				.mediaActionLiner {

					position: relative;
					height: $customRowButtonIconWidth;

					.mediaActionIcon {

						position: absolute;
						top: 0;
						left: 0;
						width: $customRowButtonIconWidth;
						height: $customRowButtonIconWidth;

						svg {
							fill: $customRowAccentText;
						}
					}

					.mediaActionLabel {
						display: inline-block;
						vertical-align: top;
						margin-left: $customRowButtonIconWidth + $customRowMargin/2;
						font-size: $customRowButtonIconWidth;
						height: $customRowButtonIconWidth;
						line-height: $customRowButtonIconWidth;
						font-weight: 700;
						text-transform: uppercase;
						user-select: none;
					}
				}
			}
		}
	}
}



/////////////////////////////
// ProductDetailRowProduct //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductDetailRowProduct {

	.productBlockLiner {

		padding: 0 $customRowMargin;

		.productBanner {

			.productPrimary {
				font-size: 16px;
				font-weight: 700;
				margin: 0 0 $customRowMargin/2 0;
			}

			.productSecondary {
				font-size: 15px;
				font-weight: 400;
			}

			.productTertiary {
				font-size: 14px;
				font-weight: 300;
			}
		}

		.productDetails {

			margin: 2*$customRowMargin 0 0 0;

			.detailLine {

				font-size: 14px;
				margin: 0 0 $customRowMargin/2 0;

				.detailTitle {
					display: inline;
					font-weight: 400;

					&:after {
						content: ': ';
					}
				}

				.detailValue {
					display: inline;
					font-weight: 300;
					font-size: 15px;
				}
			}
		}
	}
}


///////////////////////////////
// ProductDetailRowInventory //
///////////////////////////////


// Titan colors
$pdriControlAlternateBackground: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables

$pdriNameFontSize: 13px;
$pdriNameWidth: 220px;

$pdriControlAddHeight: 18px;
$pdriControlHeaderHeight: 24px;
$pdriControlRowHeight: 18px;

$pdriControlManualWidth: 45px;
$pdriControlQuantityWidth: 80px;

$pdriControlInputMaxWidth: 120px;
$pdriControlInputWidthBreak2: "(max-width : 1420px)";
$pdriControlInputWidthBreak2a: "(max-width : 1170px)";
$pdriControlInputWidthBreak1: "(max-width : 1340px)";


.ProductDetailRowInventory {

	.inventoryBlockLiner {

		.invRow {

			padding: $customRowMargin/2;

			.nameElement {

				display: inline-block;
				vertical-align: top;
				width: $pdriNameWidth;

				.nameLiner.loading {
					max-width: calc(100% - #{$pdriNameFontSize} - #{$customRowMargin/2});
				}

				.nameLiner {
					display: inline-block;
					vertical-align: top;
					font-size: $pdriNameFontSize;
					font-weight: 400;
					// text-transform: uppercase;
					line-height: $pdriControlRowHeight;
				}

				.nameLoading.loading {
					display: inline-block;
				}

				.nameLoading {
					display: none;
					vertical-align: top;
					width: $pdriNameFontSize;
					height: $pdriNameFontSize;
					margin-top: ($pdriControlRowHeight - $pdriNameFontSize) / 2;
					margin-bottom: ($pdriControlRowHeight - $pdriNameFontSize) / 2;
					margin-right: $customRowMargin/2;

					.LoadingIcon {
						width: $pdriNameFontSize;
						height: $pdriNameFontSize;
					}
				}
			}

			.controlElement {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: calc(100% - #{$pdriNameWidth});

				.controlLiner {

					.controlElement {
						position: relative;
						display: inline-block;
						vertical-align: top;

						.readOnly {
							font-size: 14px;
							font-weight: 300;
							height: $pdriControlRowHeight;
						}
					}

					.inputElement.second {
						
						@media #{$pdriControlInputWidthBreak2} {
							display: inline-block;
						}
						
						@media #{$pdriControlInputWidthBreak1} {
							display: none;
						}
						
						@media #{$break1200} {
							display: inline-block;
						}
						
						@media #{$pdriControlInputWidthBreak2a} {
							display: inline-block;
						}
					}

					.inputElement.third {
						
						@media #{$pdriControlInputWidthBreak2} {
							display: none;
						}
						
						@media #{$pdriControlInputWidthBreak1} {
							display: none;
						}
						
						@media #{$break1200} {
							display: none;
						}
						
						@media #{$pdriControlInputWidthBreak2a} {
							display: none;
						}
					}

					.inputElement.doubleElement {
						width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 3 );
						max-width: 2*$pdriControlInputMaxWidth;

						@media #{$pdriControlInputWidthBreak2} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 2 );
						}
						
						@media #{$pdriControlInputWidthBreak1} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 1 );
						}
						
						@media #{$break1200} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 2 );
						}
						
						@media #{$pdriControlInputWidthBreak2a} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 2 );
						}
					}

					.inputElement {
						width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 6 );
						max-width: $pdriControlInputMaxWidth;

						@media #{$pdriControlInputWidthBreak2} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 4 );
						}
						
						@media #{$pdriControlInputWidthBreak1} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 2 );
						}
						
						@media #{$break1200} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 4 );
						}
						
						@media #{$pdriControlInputWidthBreak2a} {
							width: calc( ( 100% - #{$pdriControlManualWidth} - #{$pdriControlQuantityWidth} ) / 4 );
						}
					}

					.manualElement {
						width: $pdriControlManualWidth;
					}

					.quantityElement {
						width: $pdriControlQuantityWidth;
					}
				}
			}

			.actionElement {
				display: none;
			}
		}

		.inventoryHeader.invRow {

			margin: 0 0 $customRowMargin/2 0;

			.headerLiner {

				.nameElement {

					.nameLiner {
						display: inline-block;
						vertical-align: middle;
						font-size: 16px;
						font-weight: 700;
						text-transform: uppercase;
						line-height: $pdriControlHeaderHeight;
					}

					.nameAction {
						position: relative;
						display: inline-block;
						vertical-align: middle;
						cursor: pointer;
						width: $pdriControlAddHeight;
						height: $pdriControlAddHeight;
						border-radius: 50%;
						background: $customRowAccent;
						margin: 0 0 0 $customRowMargin/2;
						transition: background $hoverTransition;

						.nameActionValue {
							top: -1px;
							font-size: $pdriControlAddHeight;
							line-height: $pdriControlAddHeight;
							font-weight: 900;
							color: $customRowAccentText;
							user-select: none;
						}

						&:hover {
							background: $customRowAccentHover;
						}
					}
				}

				.controlElement {

					.controlLiner {

						.controlElement {
							height: $pdriControlHeaderHeight;
							margin: 0 0 $customRowMargin/2 0;

							.headerLabel {
								font-size: $pdriControlHeaderHeight/2;
							}

							.headerSpan {
								height: $pdriControlHeaderHeight/2;
								font-weight: 700;
								font-size: $pdriControlHeaderHeight/2;
								text-align: center;
								text-transform: uppercase;
								overflow: hidden;
							}

							.headerRows {

								height: $pdriControlHeaderHeight/2;

								.headerCell {
									display: inline-block;
									vertical-align: top;
									width: 50%;
									height: $pdriControlHeaderHeight/2;
									line-height: $pdriControlHeaderHeight/2;
									font-size: $pdriControlHeaderHeight/2;
									text-align: center;
								}
							}
						}

						.inputElement {

						}

						.manualElement {

						}

						.quantityElement {

						}
					}
				}
			}
		}

		.inventoryBody {

			.rowWrapper {

				position: relative;

				&:nth-child(odd) {
					background: $pdriControlAlternateBackground;
				}
			}

			.filterNotice {

				margin: $customRowMargin/2 0 0 0;
				text-align: center;

				.filterTitle {
					font-size: 18px;
					font-weight: 400;
				}

				.filterAction {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					color: $customRowAccent;
					cursor: pointer;
					transition: color $hoverTransition;

					&:hover {
						color: $customRowAccentHover;
					}
				}
			}

			.addInventoryWrapper {

				.addInventoryBody {
					margin: $customRowMargin/2 0;
				}
			}
		}
	}
}


//////////////////////////////////
// ProductDetailRowInventoryRow //
//////////////////////////////////


// Titan colors
$pdriRowLoadingBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;

// Theme colors
// None yet

// General variables
// None yet


.ProductDetailRowInventoryRow {

	.rowLiner {

		// position: relative;

		.loadingWrapper {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-align: center;

			background: rgba($pdriRowLoadingBackground, 0.66);

			.loadingIcon {

				display: inline-block;
				vertical-align: top;
				width: $pdriControlRowHeight;
				height: $pdriControlRowHeight;

				svg {
					fill: $customRowColor;
				}
			}

			.loadingValue {
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 $customRowMargin/2;
				line-height: $pdriControlRowHeight;
				font-size: 14px;
				font-weight: 300;
			}
		}
	}
}


//////////////////////////////////
// ProductDetailRowInventoryAdd //
//////////////////////////////////


// Titan colors
$productDetailRowInventoryAddBorderColor: $TITAN_RULE_COLOR;
$productDetailRowInventoryAddInputErrorColor: $errorRed;
$productDetailRowInventoryAddInputPlaceholderColor: $placeholderGrey;

// Theme colors
// None yet

// General variables
$productDetailRowInventoryAddActionsWidth: 120px;
$productDetailRowInventoryAddInputFontSize: 14px;
$productDetailRowInventoryAddInputHeight: 20px;
$productDetailRowInventoryAddInputWidth: 100px;
$productDetailRowInventoryAddLabelFontSize: 12px;
$productDetailRowInventoryAddPromptHeight: 14px;


.ProductDetailRowInventoryAdd {

	.addLiner {

		.addFormWrapper {
			
			.addForm {

				.inlineInputs {

					display: inline-block;
					vertical-align: top;
					max-width: calc(100% - #{$productDetailRowInventoryAddActionsWidth});
					margin: 0 0 0 0;

					.inlineFieldWrapper {

						display: inline-block;
						vertical-align: top;
						margin: 0 $customRowMargin $customRowMargin 0;

						.inlineFieldLabel {
							font-size: $productDetailRowInventoryAddLabelFontSize;
							line-height: $productDetailRowInventoryAddLabelFontSize;
							margin: 0 0 $customRowMargin/4 0;
						}

						.inlineInputWrapper {

							font-size: $productDetailRowInventoryAddInputFontSize;

							input {
								border: 1px solid $productDetailRowInventoryAddBorderColor;
								padding: $customRowMargin/4 $customRowMargin/2;
								text-align: right;
								width: $productDetailRowInventoryAddInputWidth;
								height: $productDetailRowInventoryAddInputHeight - $customRowMargin/2;
							}

							.inlineDropdown {

								position: relative;

								select {
									appearance: none;
									border: 1px solid $productDetailRowInventoryAddBorderColor;
									padding: $customRowMargin/4 2*$customRowMargin $customRowMargin/4 $customRowMargin/2;
									height: $productDetailRowInventoryAddInputHeight + $customRowMargin/2 - 2px;

									&:invalid {
							    	color: $productDetailRowInventoryAddInputPlaceholderColor;
							    }
								}

								.arrow {
						    	position: absolute;
						    	top: -1*$customRowMargin/4;
						    	right: $customRowMargin/2;
						    	width: auto;
						    	height: auto;
						    	user-select: none;
						    	pointer-events: none;
						    	font-size: $productDetailRowInventoryAddInputFontSize + 2px;
						    }
							}

							.currencyWrapper {
								position: relative;

								.currencyOverlay {
									font-size: $productDetailRowInventoryAddInputFontSize;
									position: absolute;
						    	top: $customRowMargin/4;
						    	left: $customRowMargin/2;
						    	width: auto;
						    	height: auto;
								}
							}

							.inlineError {
								margin: $customRowMargin/4 0 0 0;
								font-size: $productDetailRowInventoryAddLabelFontSize - 2px;
								color: $productDetailRowInventoryAddInputErrorColor;
								font-weight: 400;
								max-width: $productDetailRowInventoryAddInputWidth + $customRowMargin;
							}
						}
					}
				}

				.inlineActions {
					display: inline-block;
					vertical-align: top;
					width: $productDetailRowInventoryAddActionsWidth;

					.actionsWrapper {
						text-align: left;

						.saveButton.addInvButton {
							color: $customRowAccentText;
							background: $customRowAccent;
							font-size: $productDetailRowInventoryAddLabelFontSize;
							font-weight: 700;
							text-transform: uppercase;
							transition: background $hoverTransition;

							&:hover {
								background: $customRowAccentHover;
							}
						}

						.cancelButton.addInvButton {
							background: none;
							margin-right: $customRowMargin/2;
							font-size: 22px;
							line-height: 22px;
							font-weight: 300;
							transition: font-weight $hoverTransition;

							&:hover {
								font-weight: 400;
							}
						}

						.addInvButton {
							cursor: pointer;
							display: inline-block;
							vertical-align: top;
							height: $productDetailRowInventoryAddInputHeight + $customRowMargin/2 - 2px;
							border: none;
							padding: 0 $customRowMargin;
							margin-top: $productDetailRowInventoryAddLabelFontSize + $customRowMargin/4;
						}
					}
				}
			}
		}

		.addPromptWrapper {

			text-align: center;

			.addPrompt {

				// This is for an add prompt that will show under the inventory list
				// Change display to inline-block if we want to re-enable
				// For now, this is not used since we added the + to the inventory title

				display: none;
				vertical-align: top;

				&:hover {

					cursor: pointer;

					.addPlus {
						background: $customRowAccentHover;
					}

					.addCopy {
						color: $customRowAccentHover;
					}
				}

				.addPlus {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: $productDetailRowInventoryAddPromptHeight;
					height: $productDetailRowInventoryAddPromptHeight;
					border-radius: 50%;
					margin: 0 $customRowMargin/2 0 0;

					color: $customRowAccentText;
					background: $customRowAccent;
					transition: color $hoverTransition, background $hoverTransition;

					.plusChar {
						font-size: $productDetailRowInventoryAddPromptHeight;
						font-weight: 700;
						line-height: $productDetailRowInventoryAddPromptHeight;
					}
				}

				.addCopy {
					display: inline-block;
					vertical-align: middle;

					font-size: $productDetailRowInventoryAddPromptHeight;
					color: $customRowAccent;
					transition: color $hoverTransition;
				}
			}
		}
	}
}


//////////////////
// ProductField //
//////////////////


// Titan colors
$productFieldBackground: $TITAN_ADMIN_BLOCK_BACKGROUND;
$productFieldBorderColor: $TITAN_RULE_COLOR;
$productFieldPendingColor: $TITAN_PRIMARY_COLOR;
$productFieldPendingBackground: $TITAN_PRIMARY_ULTRALIGHT;
$productFieldError: $errorRed;

// Theme colors
// None yet

// General variables
$productFieldCurrencyWidth: 10px;
$productFieldWidth: 60px;
$productFieldHeight: $pdriControlRowHeight;


.ProductField {

	.inputLiner {

		text-align: center;

		.readOnlyWrapper {

			text-align: center;

			.readOnlyElement {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $productFieldWidth;
				height: $productFieldHeight;

				.readOnlyValue {
					font-size: 12px;
					font-weight: 400;
				}
			}
		}

		.editableWrapper {

			text-align: center;

			.inputWrapper {

				position: relative;
				display: inline-block;
				vertical-align: top;
				width: $productFieldWidth;
				height: $productFieldHeight;
				
				input.error {
					border-color: $productFieldError;
				}

				input.pending {
					border-color: $customRowAccent;
				}

				input.currencyInput {
					padding: 0 $customRowMargin/2 0 ($productFieldCurrencyWidth);
					width: calc(100% - #{$productFieldCurrencyWidth} - #{$customRowMargin/2} - 2px);
					text-align: right;
				}

				input {
					vertical-align: top;
					border: 1px solid $productFieldBorderColor;
					border-radius: 0;
					background: $productFieldBackground;
					width: calc(100% - 2px);
					height: $productFieldHeight - 2px;
					padding: 0;
					font-size: 12px;
					text-align: center;
					transition: border $toggleTransition;

					&:focus {
						border-color: $customRowColor;
					}
				}

				input:focus + .currencyIndication {
					color: $customRowColor;
				}

				.currencyIndication {
					position: absolute;
					top: 0;
					left: 0;
					width: $productFieldCurrencyWidth;
					height: calc(#{$productFieldHeight} - 2px);
					line-height: $productFieldHeight;
					text-align: center;
					font-size: 12px;
					color: $productFieldBorderColor;
					transition: color $toggleTransition;
				}
			}
		}
	}
}


///////////////////////
// ProductFieldInput //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ProductFieldInput {

	.inputLiner {

	}
}


//////////////////////////
// ProductFieldQuantity //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$productFieldQuantityButtonWidth: 12px;


.ProductFieldQuantity {

	.inputLiner {

	}
}


////////////////////////
// ProductFieldSwitch //
////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$productFieldSwitchHeight: $pdriControlRowHeight;


.ProductFieldSwitch {

	.inputLiner {

		.switchWrapper {
			position: relative;
			margin: 0 auto;
			width: 2*$productFieldSwitchHeight;
			height: $productFieldSwitchHeight;

			.Toggle.absolute {
				width: calc(100% - 2px);
				height: calc(100% - 2px);

				.toggleWrapper {

					.toggleLabel {

						input:checked + .toggleSlider {
							border: 1px solid $customRowAccent;
						}

						.toggleSlider {
							border: 1px solid $productFieldBorderColor;
						}
					}
				}
			}
		}
	}
}













