

@use '../../../../../../style/import' as *;




/////////////////////////////
// AdminPageSectionActions //
/////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.AdminPageSectionActions {
	position: relative;
	text-align: right;
	margin: $MARGIN 0 0 0;

	button {
		margin-left: $MARGIN;
	}
}






