

@use '../../../../style/import' as *;




////////////////////
// PaymentMethods //
////////////////////


// Titan colors
$paymentMethodAccent: $TITAN_PRIMARY_COLOR;
$paymentMethodAccentHover: $TITAN_PRIMARY_COLOR_HOVER;
$paymentMethodAccentText: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
$paymentMethodThemeAccent: $THEME_PRIMARY_COLOR;
$paymentMethodThemeAccentHover: $THEME_PRIMARY_COLOR_HOVER;
$paymentMethodThemeColor: $THEME_TEXT_COLOR;
$paymentMethodThemeColorLight: $THEME_TEXT_COLOR_LIGHT;

// General variables
$paymentMethodMargin: 10px;


.PaymentMethods {

}


//////////////////////
// AddPaymentMethod //
//////////////////////


// Titan colors
$paymentMethodLoadingBackground: $TITAN_PRIMARY_ULTRALIGHT;

// Theme colors
// None yet

// General variables
$paymentMethodLoadingHeight: 40px;


.AddPaymentMethod {

	.adminBody {

		.adminForm {

			.addPaymentMethodForm {

				.noMethodNotice {
					margin: 2*$paymentMethodMargin 0;
					text-align: center;
					font-size: 20px;
					font-weight: 300;
				}

				.adminFieldWrapper {

					.paymentInputLoading {

						overflow: auto;
						position: relative;
						background: rgba($paymentMethodAccent, 0.1);

						.paymentInputLoadingIcon {
							position: absolute;
							top: $paymentMethodMargin;
							left: $paymentMethodMargin;
							width: $paymentMethodLoadingHeight - 2*$paymentMethodMargin;
							height: $paymentMethodLoadingHeight - 2*$paymentMethodMargin;

							svg {
								fill: $paymentMethodAccent;
							}
						}

						.paymentInputLoadingLabel {
							color: $paymentMethodAccent;
							height: $paymentMethodLoadingHeight;
							line-height: $paymentMethodLoadingHeight;
							margin: 0 $paymentMethodMargin 0 $paymentMethodLoadingHeight;
							text-transform: uppercase;
							font-size: 14px;
							font-weight: 700;
						}
					}

					.adminInputWrapper {

						.adminDropdownWrapper {

							.adminDropdownSelect {

								height: auto;

								.dropdownArrowWrapper {
									right: $paymentMethodMargin;
									bottom: 1.5*$paymentMethodMargin;
								}
							}
						}
					}

					.fieldSupplement {

						.addPaymentMethodNote {
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}


///////////////////////
// ViewPaymentMethod //
///////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.ViewPaymentMethod {



		
}


//////////////////////////////
// PaymentMethodDetailsView //
//////////////////////////////


// Titan colors
$paymentMethodDisabledColor: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet


.PaymentMethodDetailsView {

	.detailsMethodDisabled {
		color: $paymentMethodDisabledColor;
		text-align: center;
		margin: 2*$paymentMethodMargin 0;
		font-size: 20px;
		font-weight: 700;
	}
}




//////////////////////////////
// PaymentMethodDetailsEdit //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet


.PaymentMethodDetailsEdit {

	
}



/////////////////////////////
// PaymentMethodConfigView //
/////////////////////////////


// Titan colors
$paymentMethodConfigHr: $TITAN_TEXT_COLOR_LIGHT;

// Theme colors
// None yet

// General variables
// None yet


.PaymentMethodConfigView {

	.adminSectionBody {

		.adminSectionBodyWrapper {

			.adminViewWrapper {

				.detailBlock {

					.detailBlockWrapper {

						.present.configError {
							height: 3.5*$paymentMethodMargin;
						}

						.configError {
							color: $errorRed;
							font-weight: 700;
							font-size: 16px;
							height: 0;
							text-align: center;
							overflow: hidden;
							transition: height $toggleTransition;
						}

						.fullWidthConfigs {

							.adminViewItem {
								width: auto;
								height: auto;

								.adminViewValue.urlValue {
									font-size: 15px;
								}
							}
						}

						.configHr {
							background: $paymentMethodConfigHr;
							width: 80%;
							height: 1px;
							margin: $paymentMethodMargin auto 2*$paymentMethodMargin auto;
						}

						.halfWidthConfigs {

							.adminViewItem {

							}
						}

						.noConfigMethod {
							font-size: 20px;
							font-weight: 300;
							margin: 3*$paymentMethodMargin 0 2*$paymentMethodMargin 0;
							text-align: center;
						}
					}
				}
			}
		}
	}
}



//////////////////////////////
// PaymentMethodConfigField //
//////////////////////////////


// Titan colors
$pmcfBorderColor: $TITAN_TEXT_COLOR;
$pmcfCancelColor: $TITAN_TEXT_COLOR;
$pmcfCancelColorHover: $TITAN_TEXT_COLOR_HOVER;
$pmcfSaveIconColor: $TITAN_PRIMARY_COLOR_TEXT;

// Theme colors
// None yet

// General variables
$pmcfCancelWidth: 30px;
$pmcfInputHeight: 30px;
$pmcfSaveWidth: 50px;
$pmcfToggleWidth: 80px;


.PaymentMethodConfigField {

	height: auto !important;

	.cfEdit {

		position: relative;

		.cfEditInputWrapper {

			.cfEditInput {
				border-left: 1px solid $pmcfBorderColor;
				border-top: 1px solid $pmcfBorderColor;
				border-bottom: 1px solid $pmcfBorderColor;
				border-right: none;
		    width: calc(100% - 2*#{$paymentMethodMargin} - #{$pmcfSaveWidth} - #{$pmcfCancelWidth} - 1px);
		    font-size: 16px;
		    padding: 0 $paymentMethodMargin;
		    margin: 0;
		    height: $pmcfInputHeight - 2px;
			}
		}

		.cfEditSaveAction {

			position: absolute;
			top: 0;
			height: $pmcfInputHeight;
			right: $pmcfCancelWidth;
			width: $pmcfSaveWidth;

			cursor: pointer;
			background: $paymentMethodAccent;
			transition: background $hoverTransition;

			&:hover {
				background: $paymentMethodAccentHover;
			}

			.cfEditSaveActionIconWrapper {

				position: absolute;
				top: 10%;
				bottom: 10%;
				left: 27%;
				width: 46%;

				.cfEditSaveActionIcon {

					svg {
						fill: $pmcfSaveIconColor;
					}
				}
			}
		}

		.cfEditCancelAction {

			position: absolute;
			top: 0;
			height: $pmcfInputHeight;
			right: 0;
			width: $pmcfCancelWidth;

			.cfEditCancelActionIconWrapper {
				cursor: pointer;
				color: $pmcfCancelColor;
				font-size: 32px;
				font-weight: 100;
				transition: color $hoverTransition;
					
				&:hover {
					color: $pmcfCancelColorHover;
				}
			}
		}
	}

	.adminViewValue.configValue {

		min-height: $pmcfInputHeight;

		.adminViewValueData {
			display: inline-block;
			vertical-align: middle;
			max-width: calc(100% - #{$pmcfToggleWidth} - #{$paymentMethodMargin});
		}

		.adminViewValueToggle.noMainValue {
			margin: 0 0 0 0;
		}

		.adminViewValueToggle {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 0 $paymentMethodMargin;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			color: $paymentMethodAccent;
			line-height: $pmcfInputHeight/2;
			padding: $pmcfInputHeight/4 0;
			max-width: $pmcfToggleWidth;
			transition: color $hoverTransition;

			&:hover {
				color: $paymentMethodAccentHover;
			}
		}
	}
}




//////////////////////////
// SquareCCPaymentInput //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
$squareCheckboxWidth: 20px;
$squareCheckboxWidthMobile: 16px;



.SquareCCPaymentInput {

	.checkoutColor {
		display: none;
		color: $paymentMethodThemeColorLight;
	}

	.checkoutAccent {
		display: none;
		color: $paymentMethodThemeAccent;
	}

	.pmiLiner {

		.present.squareServerError {
			height: 3.5*$paymentMethodMargin;
		}

		.squareServerError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.squareLoading {
			height: $paymentMethodLoadingHeight;
			text-align: center;

			.iconElement {
				height: $paymentMethodLoadingHeight;
				width: $paymentMethodLoadingHeight;
				margin: 0 auto;

				svg {
					fill: $paymentMethodThemeAccent;
				}
			}
		}

		.squareLoadError {
			
			margin: 2*$paymentMethodMargin 0 5*$paymentMethodMargin 0;
			text-align: center;

			.squareLoadErrorTitle {
				font-size: 24px;
				font-weight: 300;
			}

			.squareLoadErrorLinkWrapper {
				margin: $paymentMethodMargin 0 0 0;
				font-size: 20px;
				font-weight: 700;
				text-transform: uppercase;

				a {	
					color: $paymentMethodThemeAccent;
					transition: color $hoverTransition;

					&:hover {
						color: $paymentMethodThemeAccentHover;
					}
				}
			}
		}

		.checkoutStepInputForm {

			text-align: left;

			.paymentSection {

				.checkoutFormInput.sameAsShippingWrapper {
					width: 100%;
					margin: 0 0 2*$paymentMethodMargin 0;
					padding: 0;
					transition: padding $breakTransition;

					@media #{$break600} {
						padding: $paymentMethodMargin/5 0 0 0;
					}
				}

				.checkoutFormInput {
					position: relative;
					margin: 0 0 $paymentMethodMargin 0;

					.sameAsShippingInputWrapper {
						position: absolute;
						top: $paymentMethodMargin/4;
						left: 0;
						width: $squareCheckboxWidth;
						height: $squareCheckboxWidth;
						transition: width $breakTransition, height $breakTransition;

						@media #{$break600} {
							width: $squareCheckboxWidthMobile;
							height: $squareCheckboxWidthMobile;
						}
					}

					.sameAsShippingValueWrapper {
						margin-left: $squareCheckboxWidth + $paymentMethodMargin;
						font-size: $squareCheckboxWidth;
						transition: margin $breakTransition, font-size $breakTransition;

						@media #{$break600} {
							margin-left: $squareCheckboxWidthMobile + $paymentMethodMargin/2;
							font-size: $squareCheckboxWidthMobile;
						}
					}
				}
			}

			.billingSection {

			}

			.checkoutSummaryWrapper {
				display: none;
				margin: $paymentMethodMargin 0 0 0;

				@media #{$break1024} {
					display: block;
				}

				.summaryTitle {
					position: relative;
					user-select: none;
					color: $paymentMethodThemeColor;
					font-family: $fontLato;
					font-weight: 700;
					font-size: 24px;
					margin: 0 0 $paymentMethodMargin 0;
				}
			}

			.checkoutFormAction.placeOrderAction {
				width: 100%;
				margin: 2*$paymentMethodMargin 0 0 0;
			}
		}
	}
}



//////////////////////////
// TransbankPaymentInit //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.TransbankPaymentInit {


	.pmiLiner {

		.present.transbankServerError {
			height: 3.5*$paymentMethodMargin;
		}

		.transbankServerError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.checkoutStepInputForm {

			text-align: left;

			.checkoutSummaryWrapper {
				display: none;
				margin: $paymentMethodMargin 0 0 0;

				@media #{$break1024} {
					display: block;
				}

				.summaryTitle {
					position: relative;
					user-select: none;
					color: $paymentMethodThemeColor;
					font-family: $fontLato;
					font-weight: 700;
					font-size: 24px;
					margin: 0 0 $paymentMethodMargin 0;
				}
			}

			.checkoutFormAction.placeOrderAction {
				width: 100%;
				margin: 2*$paymentMethodMargin 0 0 0;
			}
		}
	}
}



//////////////////////////
// NoPaymentPayAtPickup //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.NoPaymentPayAtPickup {


	.pmiLiner {

		.present.noPaymentServerError {
			height: 3.5*$paymentMethodMargin;
		}

		.noPaymentServerError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.checkoutStepInputForm {

			text-align: left;

			.checkoutSummaryWrapper {
				display: none;
				margin: $paymentMethodMargin 0 0 0;

				@media #{$break1024} {
					display: block;
				}

				.summaryTitle {
					position: relative;
					user-select: none;
					color: $paymentMethodThemeColor;
					font-family: $fontLato;
					font-weight: 700;
					font-size: 24px;
					margin: 0 0 $paymentMethodMargin 0;
				}
			}

			.checkoutFormAction.placeOrderAction {
				width: 100%;
				margin: 2*$paymentMethodMargin 0 0 0;
			}
		}
	}
}



//////////////////////////
// NoPaymentStoreCredit //
//////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.NoPaymentStoreCredit {


	.pmiLiner {

		.present.noPaymentServerError {
			height: 3.5*$paymentMethodMargin;
		}

		.noPaymentServerError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.checkoutStepInputForm {

			text-align: left;

			.checkoutSummaryWrapper {
				display: none;
				margin: $paymentMethodMargin 0 0 0;

				@media #{$break1024} {
					display: block;
				}

				.summaryTitle {
					position: relative;
					user-select: none;
					color: $paymentMethodThemeColor;
					font-family: $fontLato;
					font-weight: 700;
					font-size: 24px;
					margin: 0 0 $paymentMethodMargin 0;
				}
			}

			.checkoutFormAction.placeOrderAction {
				width: 100%;
				margin: 2*$paymentMethodMargin 0 0 0;
			}
		}
	}
}



//////////////////////////////
// NoPaymentExternalGeneral //
//////////////////////////////


// Titan colors
// None yet

// Theme colors
// None yet

// General variables
// None yet



.NoPaymentExternalGeneral {


	.pmiLiner {

		.present.noPaymentServerError {
			height: 3.5*$paymentMethodMargin;
		}

		.noPaymentServerError {
			color: $errorRed;
			font-weight: 700;
			font-size: 16px;
			height: 0;
			text-align: center;
			overflow: hidden;
			transition: height $toggleTransition;
		}

		.checkoutStepInputForm {

			text-align: left;

			.checkoutSummaryWrapper {
				display: none;
				margin: $paymentMethodMargin 0 0 0;

				@media #{$break1024} {
					display: block;
				}

				.summaryTitle {
					position: relative;
					user-select: none;
					color: $paymentMethodThemeColor;
					font-family: $fontLato;
					font-weight: 700;
					font-size: 24px;
					margin: 0 0 $paymentMethodMargin 0;
				}
			}

			.checkoutFormAction.placeOrderAction {
				width: 100%;
				margin: 2*$paymentMethodMargin 0 0 0;
			}
		}
	}
}



////////////////////////////////
// PaymentMethodOauthRedirect //
////////////////////////////////


// Titan colors
$paymentMethodOAuthRedirectBackground: $TITAN_BACKGROUND;

// Theme colors
// None yet

// General variables
$paymentMethodOAuthRedirectBlockMaxWidth: 420px;
$paymentMethodOAuthRedirectIconWidth: 60px;


.PaymentMethodOauthRedirect {

	.loadingBlockWrapper {

		margin: 5*$paymentMethodMargin auto;
		width: 90%;
		overflow: auto;
		max-width: $paymentMethodOAuthRedirectBlockMaxWidth;
		background: $paymentMethodOAuthRedirectBackground;

		.loadingBlock {

			padding: 3*$paymentMethodMargin $paymentMethodMargin;

			.iconWrapper {

				margin: 0 auto 2*$paymentMethodMargin auto;
				width: $paymentMethodOAuthRedirectIconWidth;
				height: $paymentMethodOAuthRedirectIconWidth;

				svg {
					fill: $paymentMethodAccent;
				}
			}

			.labelBlock {
				font-size: 24px;
				font-weight: 100;
				text-align: center;
			}
		}

		.errorBlock {

			padding: 2*$paymentMethodMargin $paymentMethodMargin;

			.errorLabel {
				font-size: 18px;
				font-weight: 700;
				margin: 0 0 $paymentMethodMargin 0;
			}

			.errorValue {
				font-weight: 300;
				margin: 0 0 2*$paymentMethodMargin 0;
			}

			.errorAction {

				text-align: center;

				.backButton {
					display: inline-block;
					vertical-align: top;
					width: auto;
					padding: $paymentMethodMargin 2*$paymentMethodMargin;
					text-decoration: none;
					color: $paymentMethodAccentText;
					background: $paymentMethodAccent;
					transition: background $hoverTransition;

					&:hover {
						background: $paymentMethodAccentHover;
					}
				}
			}
		}
	}
}




















